import React, { FC, useState, useEffect } from 'react';

import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { StaticDatePicker } from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box, Button, ButtonGroup, IconButton, Popover, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment, { Moment } from 'moment';
import { FormattedDate } from 'react-intl';

import usePopover from '../../../hooks/usePopover';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    height: 32,
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 14,
    borderRadius: 16,
  },
  dateButtonGroup: {
    background: theme.palette.grey[300],
    marginLeft: 10,
    borderRadius: 50,
  },
  arrowButton: {
    padding: 4,
  },
  dateButton: {
    padding: 0,
    borderRadius: 50,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  dateContent: {
    margin: 'auto 8px',
    paddingTop: 1,
  },
  icon: {
    margin: 'auto 12px auto 6px',
    width: 22,
  },
  popoverPaper: {
    overflow: 'visible',
    marginTop: 7,
  },
}));

interface Props {
  dateFrom: string;
  setDateFrom: (date?: string) => void;
}

const DateSelector: FC<Props> = ({
  dateFrom,
  setDateFrom,
}) => {
  const classes = useStyles();
  const { anchorEl, handlePopoverClose, handlePopoverOpen, isOpen } = usePopover();
  const [selectedDateFrom, setSelectedDateFrom] = useState(moment(dateFrom));

  useEffect(() => {
    setDateFrom(moment(selectedDateFrom).startOf('day').toISOString());
  }, [selectedDateFrom, setDateFrom]);

  const moveDateFrom = (goForward: boolean) => {
    const newDateFrom = goForward ? moment(dateFrom).add(1, 'day') : moment(dateFrom).subtract(1, 'day');
    setSelectedDateFrom(newDateFrom);
  };
  const handleLeftArrowClick = () => moveDateFrom(false);
  const handleRightArrowClick = () => moveDateFrom(true);
  const forwardDisabled = moment(dateFrom).add(1, 'day') > moment().endOf('day');

  return (
    <>
      <ButtonGroup className={classes.dateButtonGroup} color="inherit">
        <IconButton
          className={classes.arrowButton}
          data-test="decrease-day"
          onClick={handleLeftArrowClick}
          size="large">
          <KeyboardArrowLeftIcon />
        </IconButton>
        <IconButton
          className={classes.arrowButton}
          data-test="increase-day"
          disabled={forwardDisabled}
          onClick={handleRightArrowClick}
          size="large">
          <KeyboardArrowRightIcon />
        </IconButton>
        <Button
          className={classes.dateButton}
          data-test={'advanced-calendar'}
          onClick={handlePopoverOpen}
          variant="contained"
      >
          <div className={classes.wrapper}>
            <span className={classes.dateContent} data-test={'telematics-date'}>
              <FormattedDate day="2-digit" month="2-digit" value={dateFrom} year="numeric" />
            </span>
            <InsertInvitationOutlinedIcon className={classes.icon} data-test={'telematics-calendar'} />
          </div>
        </Button>
      </ButtonGroup>
      <Popover
        anchorEl={anchorEl}
        classes={{ paper: classes.popoverPaper }}
        onClose={handlePopoverClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        >
        <Box>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <StaticDatePicker
              disableFuture
              displayStaticWrapperAs="desktop"
              renderInput={() => <div />}
              value={selectedDateFrom}
              views={['day']}
              onChange={(newValue) => {
                setSelectedDateFrom(newValue as Moment);
                handlePopoverClose();
              }}
            />
          </LocalizationProvider>
        </Box>
      </Popover>
    </>
  );
};

export default DateSelector;
