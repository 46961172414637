import React, { FC } from 'react';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import WarningIconDisabled from '../../../../assets/img/icons/telematics/warning-icon-disabled.svg';
import WarningIcon from '../../../../assets/img/icons/telematics/warning-icon.svg';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  text: {
    fontSize: 12,
    lineHeight: 'normal',
    color: theme.palette.secondary.main,
    textAlign: 'left',
  },
  icon: {
    width: 24,
    height: 24,
    paddingTop: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
}
));

interface Props {
  disabled?: boolean;
  langtextCode: string;
}

const WarningMessage: FC<Props> = ({
  disabled,
  langtextCode,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <span className={classes.icon}>
        <img
          alt="warning icon"
          src={disabled ? WarningIconDisabled : WarningIcon}
        />
      </span>
      <span className={classes.text}>
        <FormattedMessage id={langtextCode} />
      </span>
    </div>
  );
};

export default WarningMessage;
