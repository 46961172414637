import { RSAA } from 'redux-api-middleware';

import { API_METHODS as methods, API_MODULES as modules } from '../../api.constants';
import * as types from './satelliteProducts.constants';

export const getSatelliteProductsApi = (parcelId: string) => ({
  [RSAA]: {
    endpoint: `products/${parcelId}?`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [types.GET_SATELLITE_PRODUCTS, types.GET_SATELLITE_PRODUCTS_SUCCESS, types.GET_SATELLITE_PRODUCTS_ERROR],
  },
});

export const resetSatelliteProductsApi = () => ({
  type: types.RESET_SATELLITE_PRODUCTS,
});
