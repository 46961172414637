import React, { FC } from 'react';

import { Theme } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';
import { FieldInputProps, FormikProps } from 'formik';

import SubtractableArea from '../SubtractableArea/SubtractableArea';

import { SubtractionResponse } from '../../../../../shared/api/agroevidence/agroevidence.types';

const useStyles = makeStyles((theme: Theme) => ({
  saRow: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

interface SubtractableAreaWithCheckboxProps {
  field: FieldInputProps<SubtractionResponse>;
  form: FormikProps<Record<string, unknown>>;
  isEditing: boolean,
}

const SubtractableAreaWithCheckbox: FC<SubtractableAreaWithCheckboxProps> = ({
  field,
  form,
  isEditing = false,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.saRow}>
      <Checkbox
        checked={field.value?.isUsed === true}
        color="primary"
        disabled={!isEditing}
        onChange={(event, value) => {
          form.setFieldValue(field.name, { ...field.value, isUsed: value });
        }}
      />
      <SubtractableArea sa={field.value} />
    </div>
  );
};

export default SubtractableAreaWithCheckbox;
