import React, { Fragment, Component } from 'react';

import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import { withStyles } from '@mui/styles';
import { Field } from 'formik';
import differenceBy from 'lodash/differenceBy';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getActionRelatedDrives } from '../../../../../shared/api/telematics/aggregations/aggregations.selectors';

import {
  fetchParcelHistorySubtractions,
  updateParcelsSubtractions,
  getAllParcelsInZone,
} from '../../actions/actions.actions';

import CfFormattedNumber from '../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import SelectionItemAccordion from '../../../../../shared/components/common/SelectionItemAccordion/SelectionItemAccordion';
import SelectionItemColumn from '../../../../../shared/components/common/SelectionItemColumn/SelectionItemColumn';
import SelectionItemHeading from '../../../../../shared/components/common/SelectionItemHeading/SelectionItemHeading';
import ValidationStatusIcon from '../../../../../shared/components/specific/ValidationStatusIcon/ValidationStatusIcon';
import withFarm from '../../../../../shared/hocs/context/withFarm';
import * as validators from '../../../../../shared/misc/validators';
import { isParcelSown } from '../../../others/helpers/others.helpers';
import ActionToTelematicsLinks from '../../components/ActionToTelematicsLinks/ActionToTelematicsLinks';
import { SplitActionsWarningMessage } from '../../components/SplitActions/SplitActionsWarningMessage';
import TotalActivityArea from '../../components/TotalActivityArea/TotalActivityArea';
import ActionsService from '../../services/Actions.service';
import ActionStateMapper from '../../services/ActionStateMapper.service';
import { mapRequestBodyParcelsSubtractionOtherAction } from '../../services/ParcelSubtractionMapper.services';
import { ActionDetailContext } from '../ActionDetail/ActionDetail';
import ActionParcelSubtractableAreas from '../ActionParcelSubractableAreas/ActionParcelSubtractableAreas';
import ParcelsZoneAutocompleteSelector from '../ParcelsZoneAutocompleteSelector/ParcelsZoneAutocompleteSelector';

const styles = theme => ({
  parcelsLabel: {
    color: theme.palette.grey[500],
  },
  totalActivityArea: {
    backgroundColor: theme.palette.grey[100],
    padding: '12px 24px',
    overflow: 'hidden',
    marginTop: 10,
  },
  totalAreaLabel: {
    color: theme.palette.grey[500],
  },
  totalAreaValue: {
    float: 'right',
  },
  parcelSelector: {
    marginBottom: 15,
  },
  warning: {
    position: 'relative',
    top: 10,
  },
  area: {
    display: 'flex',
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '12px',
  },
  errorDuplicatedParcels: {
    color: theme.palette.error.main,
    fontSize: '16px',
  },
  telematicsIconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  accordionWrapper: {
    width: '100%',
  },
});
export class ParcelsControl extends Component {
  componentDidUpdate(prevProps) {
    const { form, hasDuplicateParcelIds } = this.props;
    const { form: prevForm } = prevProps;
    const parcelsInForm = form.values.parcels;

    if (parcelsInForm && (parcelsInForm.length !== prevForm.values.parcels.length)) {
      const parcelIds = parcelsInForm.map(p => p.id);
      const parcelsAdded = differenceBy(parcelsInForm, prevForm.values.parcels, 'id');
      const fetchPromises = [];

      parcelsAdded.forEach(parcel => {
        const index = parcelsInForm.findIndex(option => option.id === parcel.id);
        const fetchPromise = this.props.fetchParcelHistorySubtractions(parcel.id, parcelIds).then(predefinedSas => {
          const mappedSubtractableAreas = ActionStateMapper.mapSubtractableAreasFrom(predefinedSas);
          parcelsInForm[index].subtractableAreas = mappedSubtractableAreas;
        });
        fetchPromises.push(fetchPromise);
      });
      form.setFieldValue('parcels', parcelsInForm);

      if (!hasDuplicateParcelIds) {
        Promise.all(fetchPromises).then(() => {
          this.updateParcelSubtractions(form);
        });
      }
    }
  }

  updateParcelSubtractions(form) {
    const parcelsInForm = form.values.parcels;
    const requestParam = mapRequestBodyParcelsSubtractionOtherAction(parcelsInForm);
    this.props.updateParcelsSubtractions(requestParam).then((updatedSubtractions) => {
      updatedSubtractions.forEach(item => {
        const index = parcelsInForm.findIndex(parcel => parcel.id === item.parcelId);
        const mappedSubtractableAreas = ActionStateMapper.mapSubtractableAreasFrom(item.subtractions);
        parcelsInForm[index].subtractableAreas = mappedSubtractableAreas;
      });
    });
    form.setFieldValue('parcels', parcelsInForm);
  }

  handleSuggestionSelected = selected => {
    const isZone = 'parcelCount' in selected;
    const { form } = this.props;
    if (isZone) {
      this.props.getAllParcelsInZone(selected).then(parcelsInZone => {
        if (parcelsInZone.length) {
          const parcelsInForm = form.values.parcels;
          const parcelsInFormIds = parcelsInForm.map(p => p.id);
          const checkedParcels = this.checkSelectedParcelsAgainstParcelsInZone(parcelsInZone, parcelsInFormIds);
          form.setFieldValue('parcels', parcelsInForm.concat(checkedParcels.map(p => this.initializeParcel(p))));
        }
      });
    } else {
      this.props.handleInsert(this.initializeParcel(selected));
    }
  };

  checkSelectedParcelsAgainstParcelsInZone = (
    parcelsInZone,
    parcelsInFormIds,
  ) => (
    parcelsInZone.filter(parcelInZone => !(parcelsInFormIds.some(id => parcelInZone.id === id)))
  );

  // call predefined subtractions when parcel is added and map them
  initializeParcel = (parcel) => ({
    ...parcel,
    subtractableAreas: this.initializeSubtractableAreas(),
    restrictedArea: 0,
    actionParcelTotalArea: parcel.area,
  });

  initializeSubtractableAreas = () => ({
    absolute: [],
    boundary: [],
    water: [],
    boundaryChecked: 0,
    waterChecked: 0,
  });

  handleItemRemove = index => {
    this.props.handleRemove(index);
  };

  render() {
    const {
      actionRelatedDrives,
      allMustBeSown,
      classes,
      form,
      formType,
      hasDuplicateParcelIds,
      isDraft,
      isEditing,
      isExisting,
      placeholder,
    } = this.props;
    const parcels = form.values.parcels;
    const parcelsArea = ActionsService.getTotalArea(parcels, formType, isExisting);
    const { errors } = form;
    const fieldError = errors.parcels;
    const isParcelSelected = parcels.length > 0;

    return (
      <ActionDetailContext.Consumer>
        {contextValue => (
          <Fragment>
            {!isEditing &&
            <div className={classes.parcelsLabel}>
              <FormattedMessage id="common.parcels" />
            </div>
        }
            {isEditing && !hasDuplicateParcelIds &&
            <div className={classes.parcelSelector}>
              <ParcelsZoneAutocompleteSelector
                formType={this.props.formType}
                label={<FormattedMessage id={placeholder} />}
                onChange={this.handleSuggestionSelected}
                parcels={parcels}
            />
            </div>
        }
            {fieldError && <div className={classes.error}><FormattedMessage id="validation.required" tagName="span" /></div>}
            {parcels.map((parcel, index) => {
              const isSown = isParcelSown(parcel);
              const actionRelatedDrivesForParcel = actionRelatedDrives && actionRelatedDrives[parcel.id];
              return (
                <Fragment key={parcel.id}>
                  <Stack direction={'row'} spacing={2} style={{ minWidth: '100%' }}>
                    {contextValue?.isSplitting &&
                    <Stack
                      alignItems="center"
                      direction="column"
                      justifyContent="center"
                      spacing={2}
                  >
                      <Checkbox
                        checked={contextValue?.checkedItems[parcel.id] || false}
                        color="primary"
                        disabled={false}
                        style={{ height: '40px' }}
                        onChange={(e) => {
                          contextValue?.handleCheckboxChange(parcel.id, e.target.checked);
                        }}
                    />
                    </Stack>
                  }
                    <div className={classes.accordionWrapper}>
                      <SelectionItemAccordion
                        editing={isEditing}
                        formType={this.props.formType}
                        isExisting={isExisting}
                        isSown={isSown}
                        key={`${parcel.id}-${index}`}
                        details={
                          <ActionParcelSubtractableAreas
                            form={form}
                            isEditing={isEditing}
                            maxValue={parcel.area}
                            name={`parcels.${index}`}
                            parcel={parcel}
                            parcelId={parcel.id}
                            parcelIds={parcels.map(p => p.id)}
                            parcelIndex={index}
                            subtractableAreas={parcel.subtractableAreas}
                />

              }
                        onRemoveItem={() => {
                          this.handleItemRemove(index);
                        }}
            >
                        <Grid container spacing={0}>
                          <Grid item sm={4} xs={12}>
                            {
                    // TODO: "a" is temporary
                    // change to "<Link to=" after remove angular and updating react-router-dom to version 6 (or higher)
                  }
                            <a href={`/farm/${this.props.farm.id}/parcels/${parcel.id}/overview`}>
                              <SelectionItemHeading
                                classes={{ subheading: this.props.classes.subheading }}
                                reversed={true}
                                subheading={parcel.blockNumber}
                    >
                                <div>{parcel.localName}</div>
                              </SelectionItemHeading>
                            </a>
                          </Grid>
                          <Grid item sm={4} xs={6}>
                            <SelectionItemColumn
                              classes={{ content: classes.area }}
                              label={<FormattedMessage id="common.area-ha" />}
                  >
                              <Fragment>
                                {(allMustBeSown && !isSown)
                                  ? <CfFormattedNumber decimalDigits={2} value={0} />
                                  : <Field
                                      name={`parcels.${index}.actionParcelTotalArea`}
                                      validate={validators.requiredAndPositiveNumber}
                        >
                                    {({
                                      field,
                                    }) => <CfFormattedNumber decimalDigits={2} value={field.value} />}
                                  </Field>
                      }
                                {' / '}
                                <CfFormattedNumber decimalDigits={2} value={parcel.area} />
                                {form.errors.parcels &&
                    form.errors.parcels[index]?.actionParcelTotalArea &&
                    <InputAdornment classes={{ positionEnd: classes.warning }} position="end">
                      <ValidationStatusIcon id={`parcels.${index}.actionParcelTotalArea`} type={'warning'}>
                        {form.errors.parcels[index]?.actionParcelTotalArea}
                      </ValidationStatusIcon>
                    </InputAdornment>}
                              </Fragment>
                            </SelectionItemColumn>
                          </Grid>
                          <Grid item sm={actionRelatedDrivesForParcel ? 3 : 4} xs={isDraft ? 4 : 6}>
                            <SelectionItemColumn label={
                    parcel.seedApplication?.type === 'CATCH_CROP' ?
                      <FormattedMessage id="ActionParcelsList.catchCrop" /> :
                      <FormattedMessage id="common.crop" />
                    }
                  >
                              <div>
                                {parcel.seedApplication?.seed.name}
                              </div>
                            </SelectionItemColumn>
                          </Grid>
                          {!!actionRelatedDrivesForParcel &&
                          <Grid className={classes.telematicsIconContainer} item sm={1} xs={2}>
                            <ActionToTelematicsLinks data={actionRelatedDrivesForParcel} />
                          </Grid>}
                        </Grid>
                      </SelectionItemAccordion>
                    </div>
                  </Stack>
                </Fragment>
              );
            })}
            <SplitActionsWarningMessage
              isSplitting={contextValue?.isSplitting}
              numberOfCheckedItems={contextValue?.numberOfCheckedItems}
              numberOfParcels={parcels?.length}
                  />
            {hasDuplicateParcelIds && isEditing &&
            <Grid alignItems="center" className={classes.errorDuplicatedParcels} container justifyContent="left">
              <FormattedMessage id="action.hasDuplicateParcelIds" />
            </Grid>
        }
            {isParcelSelected && (
            <Grid container justifyContent="center" spacing={2}>
              <Grid item lg={6} md={7} sm={8} xs={12}>
                <TotalActivityArea parcelsArea={parcelsArea} />
              </Grid>
            </Grid>
            )}
          </Fragment>
        )}
      </ActionDetailContext.Consumer>
    );
  }
}

ParcelsControl.propTypes = {
  farm: PropTypes.object.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleInsert: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  formType: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  isExisting: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  hasDuplicateParcelIds: PropTypes.bool.isRequired,
  allMustBeSown: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  fetchParcelHistorySubtractions: PropTypes.func.isRequired,
  updateParcelsSubtractions: PropTypes.func.isRequired,
  getAllParcelsInZone: PropTypes.func.isRequired,
  isDraft: PropTypes.bool,
  actionRelatedDrives: PropTypes.object,
};

ParcelsControl.defaultProps = {
  placeholder: 'ParcelZoneSelector.placeholder',
};

const mapStateToProps = state => ({
  actionRelatedDrives: getActionRelatedDrives(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllParcelsInZone,
      fetchParcelHistorySubtractions,
      updateParcelsSubtractions,
    },
    dispatch,
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFarm(),
  withStyles(styles), injectIntl)(ParcelsControl);
