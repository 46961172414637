import React, { ReactNode, useCallback, useEffect } from 'react';

import { Box, TableBody, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getIsPorReportHistoryIsLoading, getPorHistoryReport, getTotalCount } from '../../../../shared/api/agroevidence/reports/reports.selectors';

import { getPorUsageReport } from '../../actions/reports.actions';

import { GetPorUsageReportParams } from '../../../../shared/api/agroevidence/reports/reports.api';
import CfTableBodyEmpty from '../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty';
import CfTableBodyLoader from '../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader';
import CfTableWrapper from '../../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableFooter from '../../../../shared/containers/CfTableFooter/CfTableFooter';
import CfTableHead from '../../../../shared/containers/CfTableHead/CfTableHead';
import { Thunk } from '../../../../types';
import { NAMESPACE as namespace } from '../../reducer/porReportHistoryTableReducer';
import { getPorHistoryReportAdminPage, getPorHistoryReportRowsPerPage } from '../../selectors/porReportsHistorySelector';

import { PorUsageEvidenceRow } from './PorUsageEvidenceRow';

import { ReportsState } from '../../../../reducers/reports.reducer.types';
import { ReportHistoryResponse } from '../../../../shared/api/agroevidence/agroevidence.types';

interface Props {
  getPorUsageReport: (params: GetPorUsageReportParams) => void,
  isPorReportHistoryLoading: boolean,
  page: number,
  porHistoryReport: ReportHistoryResponse[],
  porTotalCount: number,
  rowsPerPage: number
}

const PorUsageEvidence = ({
  getPorUsageReport,
  isPorReportHistoryLoading,
  page,
  porHistoryReport,
  porTotalCount,
  rowsPerPage,
}: Props) => {
  const getColDesc = (align: string, sortable: boolean, label: ReactNode) => ({ align, sortable, label });

  const columns = {
    success: getColDesc('inherit', false, <FormattedMessage id="Reports.table.label.state" />),
    usagePeriod: getColDesc('inherit', false, <FormattedMessage id="Reports.table.label.usagePeriod" />),
    sent: getColDesc('inherit', false, <FormattedMessage id="Reports.table.label.sentDate" />),
    error: getColDesc('inherit', false, ''),
  };

  const getHistoryReports = useCallback(() => {
    const params = { page, rowsPerPage };
    getPorUsageReport(params);
  }, [getPorUsageReport, page, rowsPerPage]);

  useEffect(() => {
    getHistoryReports();
  }, [getHistoryReports, page, rowsPerPage]);

  return (
    <Box>
      <Typography variant="subtitle2">
        <FormattedMessage id={'Reports.history.table.header.por'} />
      </Typography>
      <CfTableWrapper>
        <CfTableHead
          columns={columns}
          count={porTotalCount}
          namespace={namespace}
        />
        {porHistoryReport.length > 0 &&
          <TableBody>
            {porHistoryReport.map((row) => (
              <PorUsageEvidenceRow key={row.id} row={row} />
            ))}
          </TableBody>
      }
        {isPorReportHistoryLoading && <CfTableBodyLoader columns={columns} />}
        {porHistoryReport.length === 0 && <CfTableBodyEmpty colLength={1} />}
        <CfTableFooter count={porTotalCount} namespace={namespace} page={page} rowsPerPage={rowsPerPage} />
      </CfTableWrapper>
    </Box>

  );
};

const mapStateToProps = (state: ReportsState) => ({
  porTotalCount: getTotalCount(state),
  rowsPerPage: getPorHistoryReportRowsPerPage(state),
  page: getPorHistoryReportAdminPage(state),
  porHistoryReport: getPorHistoryReport(state),
  isPorReportHistoryLoading: getIsPorReportHistoryIsLoading(state),
});

const mapDispatchToProps = (dispatch: Thunk<ReportsState>) =>
  bindActionCreators(
    {
      getPorUsageReport,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PorUsageEvidence);
