import { AnyAction } from 'redux';

import * as types from './telematicsCatalogues.constants';

import { TelematicsCataloguesState } from './telematicsCatalogues.types';

const initialState: TelematicsCataloguesState = {
  machines: [],
  isFetchingMachines: false,
  errorMachines: undefined,
  machinesTotalCount: 0,

  equipments: [],
  isFetchingEquipments: false,
  errorEquipments: undefined,
  equipmentsTotalCount: 0,

  drivers: [],
  isFetchingDrivers: false,
  errorDrivers: undefined,
  driversTotalCount: 0,

  productionOperations: [],
  isFetchingProductionOperations: false,
  errorProductionOperations: undefined,

  machineCategories: [],
  isFetchingMachineCategories: false,
  errorMachineCategories: undefined,

  machineGroups: [],
  isFetchingMachineGroups: false,
  errorMachineGroups: undefined,
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.GET_MACHINES_REQUEST:
      return {
        ...state,
        isFetchingMachines: true,
      };
    case types.GET_MACHINES_SUCCESS:
      return {
        ...state,
        machines: action.payload,
        machinesTotalCount: Number(action.meta.headers.get('X-Total-Count')),
        isFetchingMachines: false,
      };
    case types.GET_MACHINES_ERROR:
      return {
        ...state,
        isFetchingMachines: false,
        errorMachines: action.payload,
        machinesTotalCount: 0,
      };

    case types.GET_EQUIPMENTS_REQUEST:
      return {
        ...state,
        isFetchingEquipments: true,
      };
    case types.GET_EQUIPMENTS_SUCCESS:
      return {
        ...state,
        equipments: action.payload,
        isFetchingEquipments: false,
        equipmentsTotalCount: Number(action.meta.headers.get('X-Total-Count')),
      };
    case types.GET_EQUIPMENTS_ERROR:
      return {
        ...state,
        isFetchingEquipments: false,
        errorEquipments: action.payload,
        equipmentsTotalCount: 0,
      };
    case types.GET_DRIVERS_REQUEST:
      return {
        ...state,
        isFetchingDrivers: true,
      };
    case types.GET_DRIVERS_SUCCESS:
      return {
        ...state,
        drivers: action.payload,
        isFetchingDrivers: false,
        driversTotalCount: Number(action.meta.headers.get('X-Total-Count')),
      };
    case types.GET_DRIVERS_ERROR:
      return {
        ...state,
        isFetchingDrivers: false,
        errorDrivers: action.payload,
        driversTotalCount: 0,
      };
    case types.GET_PRODUCTION_OPERATIONS_REQUEST:
      return {
        ...state,
        isFetchingProductionOperations: true,
      };
    case types.GET_PRODUCTION_OPERATIONS_SUCCESS:
      return {
        ...state,
        productionOperations: action.payload,
        isFetchingProductionOperations: false,
      };
    case types.GET_PRODUCTION_OPERATIONS_ERROR:
      return {
        ...state,
        isFetchingProductionOperations: false,
        errorProductionOperations: action.payload,
      };

    case types.GET_MACHINES_CATEGORIES_REQUEST:
      return {
        ...state,
        isFetchingMachineCategories: true,
      };

    case types.GET_MACHINES_CATEGORIES_SUCCESS:
      return {
        ...state,
        machineCategories: action.payload,
        isFetchingMachineCategories: false,
      };

    case types.GET_MACHINES_CATEGORIES_ERROR:
      return {
        ...state,
        isFetchingMachineCategories: false,
        errorMachineCategories: action.payload,
      };

    case types.GET_MACHINES_GROUPS_REQUEST:
      return {
        ...state,
        isFetchingMachineGroups: true,
      };

    case types.GET_MACHINES_GROUPS_SUCCESS:
      return {
        ...state,
        machineGroups: action.payload,
        isFetchingMachineGroups: false,
      };

    case types.GET_MACHINES_GROUPS_ERROR:
      return {
        ...state,
        isFetchingMachineGroups: false,
        errorMachineGroups: action.payload,
      };

    default:
      return state;
  }
};
