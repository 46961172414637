import React, { FC } from 'react';

import { Form, Field, useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';

import CfFormControl from '../../../../shared/components/form/CfFormControl/CfFormControl';
import CfFormikNumericTextField from '../../../../shared/components/form/CfFormikNumericTextField/CfFormikNumericTextField';
import CfFormikTextField from '../../../../shared/components/form/CfFormikTextField/CfFormikTextField';
import TCMachineCategorySelector from '../shared/TCMachineCategorySelector';
import TCMachineGroupSelector from '../shared/TCMachineGroupSelector';
import TCProductionOperationSelector from '../shared/TCProductionOperationSelector';

import { MachineListFormValues } from './MachinesListRow';

const MachinesListEditDialogForm: FC = () => {
  const { errors, setFieldValue, values } = useFormikContext<MachineListFormValues>();

  return (
    <Form>
      <CfFormControl>
        <Field
          component={CfFormikTextField}
          disabled
          label={<FormattedMessage id="Catalogues.table.machines.dialog.gpsUnit" />}
          name="gpsUnit"
        />
      </CfFormControl>
      <TCMachineCategorySelector />
      <TCMachineGroupSelector />
      <CfFormControl>
        <Field
          component={CfFormikTextField}
          helperText={!!errors.name && <FormattedMessage id={errors.name} />}
          label={<FormattedMessage id="Catalogues.table.machines.dialog.name" />}
          name="name"
        />
      </CfFormControl>
      <CfFormControl>
        <Field
          component={CfFormikNumericTextField}
          helperText={!!errors.workingWidth && <FormattedMessage id={errors.workingWidth} />}
          label={<FormattedMessage id="Catalogues.table.machines.dialog.workingWidth" />}
          name="workingWidth"
          onBlur={() => {
            // CfFormikNumericTextField returns strings instead of numbers
            // @ts-ignore
            if (values.workingWidth === '') return;
            setFieldValue('workingWidth', Number(values.workingWidth));
          }}
        />
      </CfFormControl>
      <CfFormControl>
        <Field
          component={CfFormikTextField}
          helperText={!!errors.licencePlate && <FormattedMessage id={errors.licencePlate} />}
          label={<FormattedMessage id="Catalogues.table.machines.dialog.licencePlate" />}
          name="licencePlate"
        />
      </CfFormControl>
      <TCProductionOperationSelector />
    </Form>

  );
};

export default MachinesListEditDialogForm;
