import React, { Component } from 'react';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import CfSelector from '../../../../../shared/components/form/CfSelector/CfSelector';
import Localization from '../../../../../shared/services/Localization.service';
import UnitService from '../../../../../shared/services/Unit.service';

const styles = theme => ({
  input: {
    color: theme.palette.text.primary,
  },
});

export class StoreUnitSwitch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      units: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { unitId } = this.props;
    if (unitId && unitId !== prevProps.unitId) {
      this.setStoreUnitIds(unitId);
    }
  }

  componentDidMount() {
    const { unitId } = this.props;
    this.setStoreUnitIds(unitId);
  }

  setStoreUnitIds = unitId => {
    this.setState({ units: UnitService.getCompatibleUnits(unitId) });
  };

  render() {
    const { amount, classes, farmId, intl, item, unitId } = this.props;
    const { units } = this.state;
    const storeAmount = isNaN(parseFloat(amount)) ? '-' : Localization.num2str(amount, intl.locale, 4);

    return (
      <Grid container spacing={2}>
        <Grid item lg={5} md={5} sm={6} xs={6}>
          <TextField
            disabled
            fullWidth={true}
            label={<FormattedMessage id="Stores.on-store" />}
            name="store-current-amount"
            value={storeAmount}
            InputProps={{
              disableUnderline: true,
              classes: {
                input: classes.input,
              },
            }}
          />
        </Grid>
        <Grid item lg={6} md={5} sm={6} xs={6}>
          <CfSelector
            items={units}
            label={<FormattedMessage id="common.unit" />}
            labelPropertyFactory={option => <FormattedMessage id={`unit.${option.id}`} />}
            name="store-unit"
            input={{
              value: units.find(unit => unit.id === unitId) || '',
              onChange: unit => {
                this.props.onStoreUnitChange(item.currentBalance, item.unitId, unit.id, farmId, item.id);
              },
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

StoreUnitSwitch.propTypes = {
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  farmId: PropTypes.string.isRequired,
  item: PropTypes.object,
  amount: PropTypes.number,
  unitId: PropTypes.string,
  onStoreUnitChange: PropTypes.func.isRequired,
};

StoreUnitSwitch.defaultProps = {
  item: {},
  amount: null,
  unitId: null,
};

export default injectIntl(withStyles(styles)(StoreUnitSwitch));
