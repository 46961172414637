import { ParcelDetailTo } from '../../../shared/api/agroevidence/agroevidence.types';
import { Type, DriveDetailOperationTo, DriveDetailTo, DriveRecalculateTo, DriveUpdateTo, MachineTo, OperationTo, TelematicsOperation, DriveCreateTo, DriveValidationType, ProductionOperationTo } from '../../../shared/api/telematics/telematics.types';

export enum FORM_TYPES {
  CREATE = 'CREATE',
  EDIT = 'EDIT'
}

export interface SharedFormValues {
  bonus: number;
  cultivated: number;
  date: moment.Moment;
  distance: number;
  driverCode: string;
  equipmentCode: string;
  gpsUnit: string;
  isExternal: boolean;
  operation: TelematicsOperation;
  parcelId?: string;
  productionOperation?: DriveDetailOperationTo;
  season: number;
  workingWidth: number;
}

export interface DetailContentFormValues extends SharedFormValues {
  duration: number;
  formType: FORM_TYPES;
  id: number;
  timeFrom: moment.Moment;
  timeIntervals?: string[];
  timeTo: moment.Moment;
  type: Type;
}

export interface Props {
  approvalValidationErrors: Record<string, DriveValidationType | undefined>;
  driveDetail?: DriveDetailTo;
  handleReset: () => void;
  handleSave: (values: DriveUpdateTo | DriveCreateTo) => void;
  isEditing: boolean;
  parcelId?: string;
  refreshKey?: string,
  setParcelId: (id: string | undefined) => void;
}

export interface ConnectedProps extends Props {
  calculateArea: (driveId: number, equipment: DriveRecalculateTo, changeHandler: (area: number) => void) => void;
  getParcelApi: (parcelId: string) => void;
  isFetchingParcelItem: boolean;
  isParcelError: boolean;
  machine: MachineTo;
  operations: OperationTo[];
  parcel: ParcelDetailTo;
  productionOperations: ProductionOperationTo[];
}
