import React, { Component } from 'react';

import { withStyles } from '@mui/styles';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style as OlStyle } from 'ol/style';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './map.css';
import { getLayers } from '../../../../../shared/api/other/layers/layers.selectors';

import { editorSetSelected } from '../../../../../core/map/actions/editor/editor.actions';
import { storeInitialLayers } from '../../../../../core/map/actions/layersUI/layersUI.actions';
import { storeServiceWrapper, setMapGrabEL } from '../../../../../core/map/actions/map/map.actions';
import { refreshDefaultStyles, refreshParcelDetailStyles } from '../../../../../core/map/actions/style/style.actions';

import MapLoader from '../../../../../core/map/components/MapLoader/MapLoader';
import EventListener from '../../../../../core/map/services/EventListener.service';
import Geometry from '../../../../../core/map/services/geometry/Geometry.service';
import Layers from '../../../../../core/map/services/Layers.service';
import MapService from '../../../../../core/map/services/Map.service';
import Style from '../../../../../core/map/services/Style.service';
import { fetchLayersConfig } from '../../../../../shared/api/other/layers/layers.api';
import withConfig from '../../../../../shared/hocs/context/withConfig';
import withFarm from '../../../../../shared/hocs/context/withFarm';
import { hexToRgba } from '../../../../../shared/misc/colorLuminance';

const styles = {
  map: {
    height: '100%',
    position: 'relative',
  },
};

export class VariableFertilizationMap extends Component {
  constructor(props) {
    super(props);

    this.MAP_SRID_ID = '3857';
    this.DATA_SRID_ID = '4326';

    this.transformOptions = {
      dataProjection: `EPSG:${this.DATA_SRID_ID}`,
      featureProjection: `EPSG:${this.MAP_SRID_ID}`,
    };

    this.layer = null;
  }

  componentDidMount() {
    const {
      farm: {
        boundingBox,
        customer: { countryCode },
        id,
      },
      geometry,
    } = this.props;

    this.map = new MapService('var-fert-map', id, boundingBox, this.transformOptions);
    this.props.fetchLayersConfig(countryCode);

    if (!geometry) {
      this.zoomToFarm();
    }

    const el = new EventListener(this.map.getMap());
    this.props.storeServiceWrapper('main', this.map);
    this.props.storeServiceWrapper('el', el);
  }

  componentDidUpdate(prevProps) {
    const {
      config,
      currMapZones,
      displayMap,
      farm: {
        customer: { countryCode },
        id: farmId,
      },
      geometry,
      isFetchingGeom,
      layersConfig,
      parcel: { id: parcelId },
    } = this.props;

    const {
      currMapZones: prevCurrMapZones,
      displayMap: prevDisplayMap,
      geometry: prevGeometry,
      isFetchingGeom: prevIsFetchingGeom,
      layersConfig: prevLayerConfig,
      parcel: { id: prevParcelId },
    } = prevProps;

    if (isFetchingGeom !== prevIsFetchingGeom) {
      this.map.updateSize();
    }

    /*
     * Sets layers on the map when:
     * 1) layer config is fetched
     */

    if (!prevLayerConfig.length && layersConfig.length) {
      this.layers = new Layers(this.map.getMap(), config.api, farmId, this.map.getFarmExtent());
      const extendedNewLayersConfig = layersConfig.map(c => {
        if (c.layerId === 'parcel') {
          return {
            ...c,
            zIndex: 10,
          };
        }
        if (c.layerId === 'parcel_label') {
          return {
            ...c,
            zIndex: 11,
          };
        }
        return c;
      });

      this.layers.setInitialLayers(extendedNewLayersConfig, this.props.storeInitialLayers);
      const style = new Style(this.layers.getParcelLayer(), this.layers.getParcelLabelLayer(), countryCode);

      this.props.storeServiceWrapper('layers', this.layers);
      this.props.storeServiceWrapper('style', style);

      this.props.setMapGrabEL();

      if (currMapZones) {
        this.addGeometries(currMapZones);
      }
    }

    /*
     * Removes history potential image layer from the map;
     * Zooms to farm when:
     * 1) parcel is removed
     */
    if (prevParcelId !== parcelId && !parcelId) {
      this.props.editorSetSelected([], true);
      this.props.refreshDefaultStyles();
      this.zoomToFarm();
    }

    /*
     * Adds selected parcel style to the map;
     * Zooms to parcel when:
     * 1) parcel geometry is obtained
     */
    if (geometry && !prevGeometry) {
      this.props.editorSetSelected({ id: parcelId });
      this.props.refreshDefaultStyles();
      this.zoomToParcel(geometry);
    }

    if (!prevDisplayMap && displayMap && geometry) {
      this.map.updateSize();
      this.zoomToParcel(geometry);
    }

    /*
     * Adds or removes image layer from the map when:
     * 1) currMapZones is changed
     */
    if (prevCurrMapZones !== currMapZones) {
      if (this.layer) {
        this.removeGeometries();
      }

      if (currMapZones) {
        this.addGeometries(currMapZones);
      }
    }
  }

  addGeometries(zones) {
    if (this.layer) {
      this.removeGeometries();
    }

    this.layer = new VectorLayer({
      source: new VectorSource(),
      style: feature =>
        new OlStyle({
          fill: new Fill({
            color: hexToRgba(`#${feature.get('color')}`, feature.get('opacity') || 0.9),
          }),
          stroke: new Stroke({
            color: '#333333',
            width: 1,
          }),
        }),
    });

    zones.forEach(zone => {
      this.layer.getSource().addFeature(
        new Feature({
          geometry: Geometry.readGeometry(zone.geometry, {}),
          color: zone.color,
        }),
      );
    });

    // if (this.layer.getSource().getFeatures().length > 0) {
    //   this.map
    //     .getMap()
    //     .getView()
    //     .fit(this.layer.getSource().getExtent(), this.map.getMap().getSize());
    // }

    this.layers.addLayer(this.layer);
    this.props.refreshParcelDetailStyles({ red: 255, green: 255, blue: 255, fillAlfa: 0 });
    // this.setState({
    //   isFetching: false
    // });
  }

  removeGeometries() {
    this.layers.removeLayer(this.layer);
    this.layer = null;
    this.props.refreshDefaultStyles();
  }

  zoomToParcel = geometry => {
    this.map.zoomToGeometry(geometry);
  };

  zoomToFarm = () => {
    this.map.zoomToFarm();
  };

  render() {
    const { classes, isFetchingGeom } = this.props;
    return (
      <div className={classes.map} id="var-fert-map">
        <MapLoader isFetching={isFetchingGeom} offset={100} />
      </div>
    );
  }
}

VariableFertilizationMap.propTypes = {
  classes: PropTypes.object.isRequired,
  farm: PropTypes.object.isRequired,
  parcel: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  storeServiceWrapper: PropTypes.func.isRequired,
  storeInitialLayers: PropTypes.func.isRequired,
  layersConfig: PropTypes.array.isRequired,
  fetchLayersConfig: PropTypes.func.isRequired,
  setMapGrabEL: PropTypes.func.isRequired,
  editorSetSelected: PropTypes.func.isRequired,
  refreshDefaultStyles: PropTypes.func.isRequired,
  refreshParcelDetailStyles: PropTypes.func.isRequired,
  displayMap: PropTypes.bool.isRequired,
  isFetchingGeom: PropTypes.bool.isRequired,
  currMapZones: PropTypes.array,
  geometry: PropTypes.object,
};

VariableFertilizationMap.defaultProps = {
  currMapZones: null,
  geometry: null,
};

const mapStateToProps = state => ({
  layersConfig: getLayers(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      editorSetSelected,
      storeServiceWrapper,
      storeInitialLayers,
      fetchLayersConfig,
      setMapGrabEL,
      refreshDefaultStyles,
      refreshParcelDetailStyles,
    },
    dispatch,
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withConfig(),
  withFarm(),
  withStyles(styles),
)(VariableFertilizationMap);
