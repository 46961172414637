import React, { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import CfDialog from '../../../shared/components/common/CfDialog/CfDialog';

interface Props {
  onAccept: () => void,
  onClose: () => void,
  opened?: boolean
}

const AgDeactivationDialog: FC<Props> = ({
  onAccept,
  onClose,
  opened = false,
}) => (
  <CfDialog
    acceptText={<FormattedMessage id="Agi.deactivationDialog.acceptBtn" />}
    cancelText={<FormattedMessage id="common.cancel" />}
    onAccept={onAccept}
    onCancel={onClose}
    onClose={onClose}
    opened={opened}
    title={<FormattedMessage id="Agi.deactivationDialog.title" />}
  >
    <FormattedMessage id="Agi.deactivationDialog.text" />
  </CfDialog>
);

export default AgDeactivationDialog;
