/* eslint-disable no-nested-ternary */
import React, { useMemo, useEffect, useState, FC, useContext } from 'react';

import { Grid } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import { round } from 'lodash';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getIsFetchingParcel, getParcelError, getParcel } from '../../../shared/api/agroevidence/parcels/parcels.selectors';
import { getMachineByGpsUnit, getOperations, getProductionOperations } from '../../../shared/api/telematics/drives/drives.selectors';

import { calculateArea } from '../../actions/telematicsAggregations.actions';

import { getParcelApi } from '../../../shared/api/agroevidence/parcels/parcels.api';
import CfFormControl from '../../../shared/components/form/CfFormControl/CfFormControl';
import CfFormikTimePicker from '../../../shared/components/form/CfFormikTimePicker/CfFormikTimePicker';
import { SnackbarContext } from '../../../shared/containers/SnackbarProvider/SnackbarProvider';
import { Thunk } from '../../../types';
import { alignTimesWithDate } from '../../helpers/index';
import EmptyParcelBox from '../EmptyParcelBox/EmptyParcelBox';
import LpisBlock from '../LpisBlock/LpisBlock';
import TelematicsDetailEquipmentSelector from '../TelematicsDetailSelectors/TelematicsDetailEquipmentSelector';
import TelematicsDetailMachineSelector from '../TelematicsDetailSelectors/TelematicsDetailMachineSelector';
import TelematicsForeignWarning, { TelematicsForeignWarningType } from '../TelematicsForeignWarning/TelematicsForeignWarning';
import TelematicsParcel, { TelematicsParcelType } from '../TelematicsParcel/TelematicsParcel';
import TelematicsParcelSelector from '../TelematicsParcelSelector/TelematicsParcelSelector';

import {
  ApprovalWarning,
  OperationField,
  DateField,
  ProductionOperationField,
  CultivatedField,
  DistanceField,
  BonusField,
  DriverField,
  WorkingWidthField,
  Buttons,
} from './formComponents';
import { useTelematicsAggregationDetailContentStyles } from './styles';
import { validateContentForm } from './validators';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { ParcelTo } from '../../../shared/api/agroevidence/agroevidence.types';
import { Type, DriveUpdateTo, EquipmentTo, MachineTo, DriveCreateTo, Affiliation, ProductionOperationTo } from '../../../shared/api/telematics/telematics.types';
import { FORM_TYPES, Props, ConnectedProps, DetailContentFormValues } from './DetailContentForm.types';

const initialEmptyValues: Partial<DetailContentFormValues> = {
  date: undefined,
  operation: undefined,
  productionOperation: undefined,
  cultivated: 0,
  distance: 0,
  driverCode: '',
  bonus: 0,
  gpsUnit: '',
  equipmentCode: '',
  workingWidth: 0,
  parcelId: undefined,
  type: Type.MANUAL,
  timeFrom: moment().startOf('day'),
  timeTo: moment().startOf('day'),
  formType: FORM_TYPES.CREATE,
  season: 0,
  isExternal: false,
};

const DetailContentForm: FC<ConnectedProps> = ({
  approvalValidationErrors,
  calculateArea,
  driveDetail,
  getParcelApi,
  handleReset,
  handleSave,
  isEditing,
  isFetchingParcelItem,
  isParcelError,
  machine,
  operations,
  parcel,
  parcelId,
  productionOperations,
  setParcelId,
}) => {
  // hooks
  const showSnackbar = useContext(SnackbarContext);
  const classes = useTelematicsAggregationDetailContentStyles();
  const [selectedMachine, setSelectedMachine] = useState<MachineTo | null | undefined>();
  const [isParcelTouched, setIsParcelTouched] = useState(false);
  const [isLpisBlockTouched, setIsLpisBlockTouched] = useState(false);

  // constants

  // decide if data about parcel should be taken from telematics or agroevidence
  // (to avoid problems with parcel historization)
  let parcelData: Partial<TelematicsParcelType> | undefined;

  if (parcelId && parcel) {
    parcelData = { ...parcel };
  }

  if (driveDetail?.parcel?.localName && !isParcelTouched && !isLpisBlockTouched) {
    parcelData = {
      ...driveDetail?.parcel,
      seedApplication: driveDetail?.cropName ? { seed: { crop: { name: driveDetail?.cropName } } } : undefined,
    };
  }

  const initialValues: Partial<DetailContentFormValues> = useMemo(() => {
    if (!driveDetail) return initialEmptyValues;

    return ({
      id: driveDetail.id,
      date: moment(driveDetail.dateFrom),
      operation: driveDetail?.operation,
      productionOperation: driveDetail?.productionOperation,
      cultivated: round(driveDetail?.cultivated || 0, 4),
      distance: round(driveDetail?.distance || 0, 3),
      driverCode: driveDetail.driver?.code ?? '',
      bonus: driveDetail.driver?.bonus ?? 0,
      gpsUnit: driveDetail?.gpsUnit ?? '',
      equipmentCode: driveDetail?.equipmentCode ?? '',
      workingWidth: driveDetail?.workingWidth ?? selectedMachine?.workingWidth ?? 0,
      parcelId: driveDetail?.parcelId,
      type: driveDetail.type,
      timeFrom: moment(driveDetail?.drivePart?.[0]?.dateFrom) ?? undefined,
      timeTo: moment(driveDetail?.drivePart?.[0]?.dateTo) ?? undefined,
      duration: driveDetail.duration,
      formType: FORM_TYPES.EDIT,
      season: driveDetail?.season ?? 0,
      isExternal: driveDetail?.parcel?.affiliation === Affiliation.EXTERNAL,
    });
  }, [driveDetail, selectedMachine]);

  // lifecycle effects
  useEffect(() => {
    setSelectedMachine(machine);
  }, [machine]);

  useEffect(() => {
    if (parcelId) {
      getParcelApi(parcelId);
    }
  }, [getParcelApi, parcelId]);

  // handlers

  const handleResetFormAndParcel = () => {
    handleReset();
    setIsParcelTouched(false);
    setIsLpisBlockTouched(false);
  };

  const handleSubmit = (values: DetailContentFormValues) => {
    let data: Record<string, unknown> = {
      parcelId: parcelId || undefined,
      driver: values?.driverCode || undefined,
      gpsUnit: values.gpsUnit,
      equipment: values?.equipmentCode || undefined,
      operation: values.operation,
      productionOperation: values?.productionOperation?.code || undefined,
      workingWidth: values?.workingWidth || undefined,
      cultivated: values.cultivated === 0 ? 0 : values?.cultivated || undefined,
      distance: values?.distance || undefined,
      bonus: values.bonus,
      season: values.season > 0 ? values.season : undefined,
    };

    if (values.formType === FORM_TYPES.EDIT && values.isExternal) {
      data.parcelAffiliation = Affiliation.EXTERNAL;
    }

    if (values.type === Type.MANUAL) {
      const [timeFromAligned, timeToAligned] = alignTimesWithDate(values.date, values.timeFrom, values.timeTo);
      data = {
        ...data,
        timeFrom: timeFromAligned.toISOString(),
        timeTo: timeToAligned.toISOString(),
      };
    }
    handleSave(data as unknown as DriveUpdateTo | DriveCreateTo);
  };

  const isNew = initialValues.type === Type.MANUAL && !driveDetail;

  return (
    <>
      <Formik<Partial<DetailContentFormValues>>
        enableReinitialize
        initialValues={initialValues}
        onReset={handleResetFormAndParcel}
        onSubmit={handleSubmit}
        validate={validateContentForm}
        validateOnBlur={false}
        validateOnChange={!isNew}
        validateOnMount={!isNew}
      >
        {(formikProps: FormikProps<DetailContentFormValues>) => {
          const { errors, setFieldValue, touched, validateForm, values } = formikProps;

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            const touchedFieldsCount = Object.keys(touched).length;
            const fieldsCount = Object.keys(values).length;
            const fieldErrorsCount = Object.keys(errors).length;

            // formik sets all fields as touched on submit
            const submitted = touchedFieldsCount === fieldsCount;
            const submittedWithErrors = submitted && fieldErrorsCount > 0;

            if (submittedWithErrors) {
              // revalidate form until user fixes all errors
              validateForm();
            }
          }, [values, touched, validateForm, errors]);

          if (parcelId !== values.parcelId) {
            setFieldValue('parcelId', parcelId);
          }
          const setNewValueAfterRecalculation = (area: number) => {
            showSnackbar({ message: <FormattedMessage id="TelematicsList.areaRecalculation.success" />, autoHideDuration: 3000 });
            setFieldValue('cultivated', round(area, 4));
          };

          const handleDeleteParcel = () => {
            setParcelId(undefined);
            setIsParcelTouched(true);
            setFieldValue('season', 0);
          };

          const handleAreaRecalculationAfterValidation = (err: string) => {
            if (err) return;
            if (values.type === Type.MANUAL) return;
            if (
              values.gpsUnit &&
              values?.id
            ) {
              calculateArea(values.id, {
                gpsUnit: values.gpsUnit,
                workingWidth: values.workingWidth,
              },
              setNewValueAfterRecalculation);
            }
          };

          const handleMachineChange = (machine: MachineTo | null) => {
            setFieldValue('gpsUnit', machine?.gpsUnit ?? '');
            setSelectedMachine(machine);
            if (!values.equipmentCode) {
              setFieldValue('workingWidth', machine?.workingWidth ?? 0);
            }
            if (values.type !== Type.MANUAL && values.id) {
              setFieldValue('workingWidth', machine?.workingWidth ?? 0);

              const equipmentData = {
                gpsUnit: values.gpsUnit,
                equipment: values.equipmentCode || undefined,
                workingWidth: machine?.workingWidth ?? 0,
              };

              calculateArea(values.id, equipmentData, setNewValueAfterRecalculation);
            }
          };

          const handleProductionOperationChange = (value: ProductionOperationTo) => {
            setFieldValue('productionOperation', value);

            const operation = productionOperations.find(o => o.id === value?.id);

            setFieldValue('bonus', value?.bonus ?? operation?.bonus ?? 0);
          };

          const handleEquipmentChange = (equipment: EquipmentTo | null) => {
            let newWorkingWidth = 0;
            if (equipment) {
              newWorkingWidth = equipment?.workingWidth || 0;
            } else if (selectedMachine) {
              newWorkingWidth = selectedMachine?.workingWidth || 0;
            }
            setFieldValue('equipmentCode', equipment?.code ?? '');
            setFieldValue('workingWidth', newWorkingWidth);

            const operation = productionOperations.find(o => o.code === equipment?.operation?.id);
            setFieldValue('productionOperation', operation);

            setFieldValue('bonus', operation?.bonus ?? machine?.operation?.bonus ?? 0);

            const eqOperation = productionOperations.find(o => o.code === equipment?.operation?.id);
            const machOperation = productionOperations.find(o => o.code === machine?.operation?.id);

            if (eqOperation) {
              setFieldValue('productionOperation', eqOperation);
            } else if (machOperation) {
              setFieldValue('productionOperation', machOperation);
            } else {
              setFieldValue('productionOperation', undefined);
            }

            if (values.type === Type.MANUAL || !values.id) return;

            const equipmentData = {
              gpsUnit: values.gpsUnit,
              equipment: equipment ? equipment?.code : undefined,
              workingWidth: newWorkingWidth,
            };

            calculateArea(values.id, equipmentData, setNewValueAfterRecalculation);
          };

          const handleChangeParcel = (value: ParcelTo | null) => {
            setParcelId(value?.id);
          };

          const hasParcel = !!(parcelId && parcelData);
          const hasLpisBlockInfo =
            !hasParcel && values.isExternal;
          const hasNoParcelOrBlock = (!hasParcel && !hasLpisBlockInfo);

          // classifiers need always some date (even if user haven't selected it yet)
          // also use as valid-to date for parcels selector
          const dateForClassifiers = values.date?.toISOString() ?? moment().startOf('day').toISOString();

          const showIntervalsBar = values.type !== Type.MANUAL || (!isEditing && values.type === Type.MANUAL);

          const hasExternalDriver = driveDetail?.driver.affiliation === Affiliation.EXTERNAL;
          const hasExternalEquipment = driveDetail?.equipmentAffiliation === Affiliation.EXTERNAL;

          return (
            <Form className={classes.container}>
              <Grid container justifyContent="space-between">
                <Grid className={classes.parcelFieldWrapper} container item xs={12}>
                  <Grid
                    container
                    item
                    justifyContent="center"
                    paddingBottom={(hasNoParcelOrBlock || parcelId) ? '10px' : undefined}
                    xs={approvalValidationErrors.parcel ? 1 : 0}
                  >
                    <ApprovalWarning
                      show={!!approvalValidationErrors.parcel}
                      tooltipCode={approvalValidationErrors.parcel}
                    />
                  </Grid>
                  <Grid item xs={approvalValidationErrors.parcel ? 11 : 12}>
                    {hasNoParcelOrBlock && !isEditing && <EmptyParcelBox /> }
                    {hasLpisBlockInfo &&
                    <LpisBlock
                      area={driveDetail?.parcel?.area}
                      blockNr={driveDetail?.parcel?.blockNumber}
                      isEditing={isEditing}
                      square={driveDetail?.parcel?.square}
                      subjectId={driveDetail?.parcel?.subjectId}
                      subjectName={driveDetail?.parcel?.subjectName}
                      handleRemove={() => {
                        setIsLpisBlockTouched(true);
                        setFieldValue('isExternal', false);
                      }}
                    />}
                    {hasParcel && !hasLpisBlockInfo &&
                    <TelematicsParcel
                      handleRemoveParcel={handleDeleteParcel}
                      isEditing={isEditing}
                      isFetchingParcel={isFetchingParcelItem && !isParcelError}
                      parcel={parcelData as Partial<TelematicsParcelType>}
                      parcelId={parcelId}
                    />}
                    {hasNoParcelOrBlock && isEditing &&
                    <TelematicsParcelSelector
                      error={!!errors.parcelId}
                      isEditing={isEditing}
                      label={<FormattedMessage id="TelematicsList.selectParcel" />}
                      onChange={handleChangeParcel}
                      validToDate={dateForClassifiers}
                    />}
                  </Grid>
                </Grid>
                {errors.parcelId &&
                  <Grid className={classes.parcelValidationMessage} item xs={12}>{errors.parcelId}</Grid>
                }

                <Grid item xs={6}>
                  <OperationField disabled={!isEditing} operations={operations} />
                </Grid>
                <Grid item xs={4}>
                  <DateField disabled={!(isEditing && values.type === Type.MANUAL)} {...formikProps} />
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.fieldWrapper}>
                    <ApprovalWarning
                      show={!!approvalValidationErrors.productionOperation}
                      tooltipCode={approvalValidationErrors.productionOperation}
                    />
                    <ProductionOperationField
                      date={dateForClassifiers}
                      disabled={!isEditing}
                      handleProductionOperationChange={handleProductionOperationChange}
                      {...formikProps}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.fieldWrapper}>
                    <ApprovalWarning
                      show={!!approvalValidationErrors.cultivated}
                      tooltipCode={approvalValidationErrors.cultivated}
                    />
                    <CultivatedField isEditing={isEditing} {...formikProps} />
                  </div>
                </Grid>
                <Grid item xs={approvalValidationErrors.cultivated ? 5 : 4}>
                  <div className={classes.fieldWrapper}>
                    <ApprovalWarning
                      show={!!approvalValidationErrors.distance}
                      tooltipCode={approvalValidationErrors.distance}
                    />
                    <DistanceField isEditing={isEditing} {...formikProps} />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.fieldWrapper}>
                    <ApprovalWarning
                      show={!!approvalValidationErrors.driverCode}
                      tooltipCode={approvalValidationErrors.driverCode}
                    />
                    <DriverField
                      date={dateForClassifiers}
                      disabled={!isEditing}
                      {...formikProps}
                    />
                  </div>
                  {hasExternalDriver &&
                    <div className={classes.externalDriver}>
                      <TelematicsForeignWarning
                        text={driveDetail.driver.companyName}
                        type={TelematicsForeignWarningType.Driver}
                      />
                    </div>
                  }
                </Grid>
                <Grid item xs={4}>
                  <BonusField
                    disabled={!isEditing}
                    label={<FormattedMessage id="TelematicsList.bonus" />}
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CfFormControl>
                    <TelematicsDetailMachineSelector
                      dateFrom={dateForClassifiers}
                      dateTo={dateForClassifiers}
                      disabled={!(isEditing && values.type === Type.MANUAL)}
                      error={!!errors.gpsUnit}
                      helperText={errors.gpsUnit ?? ''}
                      label={<FormattedMessage id="TelematicsList.machine" />}
                      onChange={handleMachineChange}
                      selectedGpsUnit={values.gpsUnit}
                    />
                  </CfFormControl>
                </Grid>
                <Grid item xs={6}>
                  <CfFormControl>
                    <TelematicsDetailEquipmentSelector
                      dateFrom={dateForClassifiers}
                      dateTo={dateForClassifiers}
                      disabled={!isEditing}
                      error={false}
                      label={<FormattedMessage id="TelematicsList.additionalEquipment" />}
                      onChange={handleEquipmentChange}
                      selectedCode={values.equipmentCode}
                    />
                    {hasExternalEquipment &&
                      <div className={classes.externalEquipment}>
                        <TelematicsForeignWarning
                          text={driveDetail.equipmentCompanyName}
                          type={TelematicsForeignWarningType.AdditionalEquipment}
                        />
                      </div>
                    }
                  </CfFormControl>
                </Grid>
                <Grid item xs={4}>
                  <WorkingWidthField
                    handleAfterValidationEffects={handleAreaRecalculationAfterValidation}
                    isEditing={isEditing}
                    {...formikProps}
                  />
                </Grid>
                {!showIntervalsBar &&
                  <>
                    <Grid item xs={4}>
                      <Field
                        component={CfFormikTimePicker}
                        label={<FormattedMessage id="TelematicsAggregations.detail.timeFrom" />}
                        name="timeFrom"
                      />
                    </Grid>
                    <Grid className={classes.timeToField} item xs={4}>
                      <Field
                        component={CfFormikTimePicker}
                        label={<FormattedMessage id="TelematicsAggregations.detail.timeTo" />}
                        name="timeTo"
                      />
                    </Grid>
                  </>}
              </Grid>
              {isEditing && <Buttons />}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state: TelematicsState, props: Props) => ({
  parcel: getParcel(state),
  isFetchingParcelItem: getIsFetchingParcel(state),
  isParcelError: getParcelError(state).isError,
  operations: getOperations(state),
  machine: getMachineByGpsUnit(state, props),
  productionOperations: getProductionOperations(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      getParcelApi,
      calculateArea,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DetailContentForm);
