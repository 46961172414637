import { RSAAAction } from 'redux-api-middleware';

import { getTelematicsMachinesDateFilter, getTelematicsMachinesTextFilter } from '../selectors/telematicsMachines.selectors';

import * as types from './telematicsMachines.constants';

import { exportAggregatedDrivesApi } from '../../shared/api/telematics/aggregations/aggregations.api';
import FileService from '../../shared/services/File.service';

import { TelematicsState } from '../../reducers/telematics.reducer.types';
import { ExportPayload } from '../../shared/api/api.types';
import { ExportAggregatedDrivesApiParams } from '../../shared/api/telematics/aggregations/aggregations.types';

export const setDate = (dateFrom: string, dateTo: string) => ({
  type: types.SET_DATE,
  dateFrom,
  dateTo,
});

export const setOpenedMachineRows = (payload: number[]) => ({
  type: types.SET_OPENED_MACHINE_ROWS,
  payload,
});

export const fetchAggregatedMachinesSaga = () => ({
  type: types.FETCH_AGGREGATED_MACHINES,
});

export const exportAggregatedMachinesDrives = () =>
  (dispatch: (action: RSAAAction) => Promise<ExportPayload>, getState: () => TelematicsState) => {
    const state = getState();

    const params: ExportAggregatedDrivesApiParams = {
      dateFrom: getTelematicsMachinesDateFilter(state).dateFrom,
      dateTo: getTelematicsMachinesDateFilter(state).dateTo,
      machine: getTelematicsMachinesTextFilter(state),
    };

    dispatch(exportAggregatedDrivesApi(params)).then((res: ExportPayload) => {
      FileService.processFileResponse(res);
    });
  };
