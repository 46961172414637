import React, { FC, ReactNode, useEffect, useCallback } from 'react';

import { Chip } from '@mui/material';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getIsFetchingParcelsSuggestion, getParcelsSuggestion } from '../../../../shared/api/agroevidence/parcels/parcels.selectors';
import { getTelematicsListDateFrom, getTelematicsListDateTo } from '../../../selectors/telematicsList.selectors';

import { getParcelsSuggestionsApi, ParcelsApiParams, resetParcelsSuggestionsApi } from '../../../../shared/api/agroevidence/parcels/parcels.api';
import CfAutocomplete from '../../../../shared/components/common/CfAutocomplete/CfAutocomplete';
import { Thunk } from '../../../../types';
import useAdvancedFilterStyles from '../TelematicsAdvancedFilter.styles';

import { TelematicsState } from '../../../../reducers/telematics.reducer.types';
import { ParcelStatus, ParcelSuggestionTo } from '../../../../shared/api/agroevidence/agroevidence.types';

interface ParcelSelectorProps {
  dateFrom: string;
  dateTo: string;
  defaultValues?: ParcelSuggestionTo[];
  getParcelsSuggestionsApi(params: ParcelsApiParams): void;
  isFetching: boolean;
  label: ReactNode;
  onChange(items: ParcelSuggestionTo[]): void;
  options: ParcelSuggestionTo[];
  resetParcelsSuggestionsApi(): void;
}

const handleGetLabel = (option?: ParcelSuggestionTo | null) =>
  option?.localName || '';

const handleGetSelected = (option: ParcelSuggestionTo, value?: ParcelSuggestionTo | null) =>
  option.id === value?.id;

const handleFilterOptions = createFilterOptions({
  stringify: ({ blockNumber, localName }) => `${localName} ${blockNumber}`,
});

const ParcelSelector: FC<ParcelSelectorProps> = ({
  defaultValues = [],
  options,
  isFetching = false,
  label,
  getParcelsSuggestionsApi,
  resetParcelsSuggestionsApi,
  onChange,
  dateFrom,
  dateTo,
}) => {
  const classes = useAdvancedFilterStyles();

  const handleRenderOption = useCallback((option: ParcelSuggestionTo) => (
    <div>
      <div>{option.localName}</div>
      <div className={classes.subtext}>
        {option.blockNumber}{option.validTo && <span> (<FormattedMessage id="TelematicsList.filter.parcel.validTo" /><FormattedDate value={option.validTo} />)</span>}
      </div>
    </div>
  ), [classes]);

  const handleRenderTags = useCallback((values: ParcelSuggestionTo[], getTagProps: AutocompleteRenderGetTagProps) =>
    <>
      {values.map((value, index) => (
        <Chip
          {...getTagProps({ index })}
          key={value.id}
          label={<>{value.localName} <span className={classes.subtext}>{value.blockNumber}</span></>}
        />
      ))}
    </>, [classes]);

  const fetchParcels = useCallback(localNameBlockNr => {
    getParcelsSuggestionsApi({
      search: localNameBlockNr,
      page: 1,
      'per-page': 100,
      'valid-from': dateFrom,
      'valid-to': dateTo,
      status: [ParcelStatus.OPEN, ParcelStatus.RETIRED],
    });
  }, [dateFrom, dateTo, getParcelsSuggestionsApi]);

  useEffect(() => {
    fetchParcels('');

    return () => {
      resetParcelsSuggestionsApi();
    };
  }, [fetchParcels, resetParcelsSuggestionsApi]);

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      filterOptions={handleFilterOptions}
      getLabel={handleGetLabel}
      getSelected={handleGetSelected}
      id="parcel-selector"
      isFetching={isFetching}
      isMultiple={true}
      label={label}
      loadOptions={fetchParcels}
      onChange={onChange}
      renderOption={handleRenderOption}
      renderTags={handleRenderTags}
      suggestions={options}
      testId="parcel-filter"
    />
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  options: getParcelsSuggestion(state),
  isFetching: getIsFetchingParcelsSuggestion(state),
  dateFrom: getTelematicsListDateFrom(state),
  dateTo: getTelematicsListDateTo(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      getParcelsSuggestionsApi,
      resetParcelsSuggestionsApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ParcelSelector);
