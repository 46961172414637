import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import editorUI from '../core/map/reducer/editor.reducer';
import layersUI from '../core/map/reducer/layersUI.reducer';
import mapUI from '../core/map/reducer/map.reducer';
import actionListUI from '../evidence/actions/list/reducer/actionList.reducer';
import productsApi from '../evidence/api/products/products.reducer';
import agroevidenceActionsApi from '../shared/api/agroevidence/actions/actions.reducer';
import cropsApi from '../shared/api/agroevidence/catalogues/crops/crops.reducer';
import cataloguesEagriApi from '../shared/api/agroevidence/catalogues/eagri/eagri.reducer';
import fertilizersApi from '../shared/api/agroevidence/catalogues/fertilizers/fertilizers.reducer';
import plantProtectionApi from '../shared/api/agroevidence/catalogues/plantProtection/plantProtection.reducer';
import seedsApi from '../shared/api/agroevidence/catalogues/seeds/seeds.reducer';
// TODO po dokonceni prepisu na AGROEVIDENCE prejmenovat parcelsApiNew -> parcelsApi
import parcelsApiNew from '../shared/api/agroevidence/parcels/parcels.reducer';
import subtractableAreasApi from '../shared/api/agroevidence/subtractableAreas/subtractableAreas.reducer';
import zonesApi from '../shared/api/agroevidence/zones/zones.reducer';
import chemistryApplicationsApi from '../shared/api/core/chemistry/chemistryApplications.reducer';
import geometryApi from '../shared/api/core/geometry/geometry.reducer';
import unitsApi from '../shared/api/core/units/units.reducer';
import authReducer from '../shared/api/gateway/auth/auth.reducer';
import layersApi from '../shared/api/other/layers/layers.reducer';
import AgIntegrationStatusApi from '../shared/api/sentinel/agiStatus/agiStatus.reducer';
import managementApi from '../shared/api/sentinel/management/management.reducer';
import soilSamplesApi from '../shared/api/sentinel/soilSamples/soilSamples.reducer';
import variableApplicationApi from '../shared/api/sentinel/variableApplication/variableApplication.reducer';
import aggregationsApi from '../shared/api/telematics/aggregations/aggregations.reducer';

export default history =>
  combineReducers({
    ui: combineReducers({
      eph: combineReducers({
        additions: parcelsApiNew('additions'),
        suggestions: parcelsApiNew('suggestions'),
      }),
      vrf: combineReducers({
        additions: parcelsApiNew('additions'),
        suggestions: parcelsApiNew('suggestions'),
      }),
      vrs: combineReducers({
        additions: parcelsApiNew('additions'),
        suggestions: parcelsApiNew('suggestions'),
      }),
      other: combineReducers({
        additions: parcelsApiNew('additions'),
        suggestions: parcelsApiNew('suggestions'),
      }),
      actionList: actionListUI(),
      editor: editorUI(),
      map: mapUI(),
      layers: layersUI(),
      cache: () => (['actionList']),
    }),
    api: combineReducers({
      aggregations: aggregationsApi(),
      agroevidence: combineReducers({
        eagri: cataloguesEagriApi(),
        crops: cropsApi(),
        actions: agroevidenceActionsApi(),
        seeds: seedsApi(),
        subtractions: subtractableAreasApi(),
        fertilizers: fertilizersApi(),
        plantProtection: plantProtectionApi(),
      }),
      parcelsNew: parcelsApiNew(),
      products: productsApi(),
      zones: zonesApi(),
      chemistryApplications: chemistryApplicationsApi(),
      management: managementApi(),
      soilSamples: soilSamplesApi(),
      geometry: geometryApi(),
      layers: layersApi(),
      variableApplication: variableApplicationApi(),
      units: unitsApi(),
    }),
    router: connectRouter(history),
    form: formReducer,
    AGIStatus: AgIntegrationStatusApi(),
    auth: authReducer,
  });
