import React, { FC } from 'react';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import classnames from 'classnames';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getRelatedDrives } from '../../../selectors/telematicsAggregationDetail.selectors';

import { fetchRelatedDrives, fetchRelatedDrivesByMachine } from '../../../actions/telematicsAggregationDetail.actions';

import { Thunk } from '../../../../types';

import useLinkArrows from './useLinkArrows';

import { TelematicsState } from '../../../../reducers/telematics.reducer.types';
import { DriveDetailTo } from '../../../../shared/api/telematics/telematics.types';

interface Props {
    children: React.ReactElement;
    driveDetail: DriveDetailTo;
    fetchRelatedDrives: (driverCode: string, dateFrom: string, dateTo: string) => void;
    fetchRelatedDrivesByMachine: (machine: string, dateFrom: string, dateTo: string) => void;
    relatedDrives: number[] | null;
}

const LinkArrows: FC<Props> = ({
  children,
  driveDetail,
  fetchRelatedDrives,
  fetchRelatedDrivesByMachine,
  relatedDrives,
}) => {
  const { classes, dateFrom, driveId, driverName, externalDriver, handleBackwardClick,
    handleForwardClick, machineName, previousPage,
  } = useLinkArrows({ driveDetail, fetchRelatedDrives, fetchRelatedDrivesByMachine });

  const count = relatedDrives?.length || 0;
  const index = relatedDrives ? relatedDrives.indexOf(driveId) : -1;

  const getName = () => {
    if (previousPage === 'machines') {
      return machineName ?? '';
    } else {
      return driverName ?? <FormattedMessage id="TelematicsList.filter.withoutDriver" />;
    }
  };

  return (
    <div className={classes.linkArrowsContainer}>
      <Tooltip title={<FormattedMessage id="TelematicsAggregations.detail.previousRide" />}>
        <span>
          <IconButton
            disabled={index < 1}
            onClick={() => handleBackwardClick(relatedDrives, index)}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </span>
      </Tooltip>
      <div className={classes.linkArrowsTextContainer}>
        <div className={classnames(classes.linkArrowsText, { [classes.linkArrowsTextExternalDriver]: externalDriver })}>
          <span><FormattedDate value={dateFrom} /></span>
          {' '}
          <span>{getName()}</span>
          {' '}
          {index > -1 && <span>{`${index + 1}/${count}`}</span>}
        </div>
        {children}
      </div>
      <Tooltip title={<FormattedMessage id="TelematicsAggregations.detail.nextRide" />}>
        <span>
          <IconButton
            disabled={index >= count - 1}
            onClick={() => handleForwardClick(relatedDrives, index, count)}
        >
            <ArrowForwardIosIcon />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  relatedDrives: getRelatedDrives(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) => bindActionCreators({
  fetchRelatedDrives,
  fetchRelatedDrivesByMachine,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LinkArrows);
