import React, { FC, ReactElement, ReactNode } from 'react';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  detailRowLabel: {
    color: theme.palette.grey[500],
  },
  detailRowValue: {
    fontWeight: 500,
  },
  div: {
    marginRight: 15,
    display: 'flex',
    alignItems: 'center',
  },
  parcelDetail: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 10,
  },
}));

export interface ParcelDetailSectionProps {
  dense?: boolean,
  label?: ReactNode | string,
  translationId?: string,
  valueBold?: boolean,
  valueItem?: ReactElement | string,
}

export const ParcelDetailSection: FC<ParcelDetailSectionProps> = ({
  dense = false,
  label,
  translationId,
  valueBold = true,
  valueItem,
}) => {
  const classes = useStyles();
  const marginStyle = dense ? { marginRight: 5 } : { marginRight: 15 };
  return (
    <div className={classes.parcelDetail} data-test="parcel-detail">
      <div className={classes.div} style={marginStyle}>
        <div className={classes.detailRowLabel} data-test="label">
          {translationId ? <FormattedMessage id={translationId} /> : label}
        </div>
      </div>
      <div className={classes.div} style={marginStyle}>
        <div className={valueBold ? classes.detailRowValue : ''} data-test="value">
          {valueItem}
        </div>
      </div>
    </div>
  );
};

export default ParcelDetailSection;
