import React, { VFC } from 'react';

import { Theme, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface Props {
  [rest: string]: unknown,
  label: React.ReactNode | string;
}

const useStyles = makeStyles((theme: Theme) => ({
  tab: {
    color: theme.palette.grey[500],
    fontSize: 16,
    lineHeight: 1.25,
    '&:hover': {
      color: theme.palette.primary.main,
    },
    minWidth: 50,
    maxWidth: 300,
    [theme.breakpoints.up('sm')]: {
      padding: '6px 24px',
    },
  },
}));

const CfPrimaryTab: VFC<Props> = (props) => {
  const classes = useStyles();
  const { label, ...otherProps } = props;
  return (
    <Tab
      label={label}
      classes={{
        root: classes.tab,
      }}
      {...otherProps}
    />
  );
};

export default CfPrimaryTab;
