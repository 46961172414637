import React, { FC } from 'react';

import { Theme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import { GraphDataOrMonitoringData } from '../../containers/BioMonitoring';
import BioMonitoringImageErrorService from '../../services/BioMonitoringImageError.services';

const useStyles = makeStyles((theme: Theme) => ({
  errorWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  icon: {
    width: 38,
    height: 38,
    color: theme.palette.grey[500],
  },
  tooltip: {
    marginTop: -40,
  },
}));

export interface BioMonitoringImageErrorWrapperProps {
  children: React.ReactElement,
  isZonesImage?: boolean,
  item: GraphDataOrMonitoringData
}

const BioMonitoringImageErrorWrapper: FC<BioMonitoringImageErrorWrapperProps> = ({
  children,
  isZonesImage = false,
  item,
}) => {
  const classes = useStyles();
  const error = BioMonitoringImageErrorService.getSnapshotError(item, isZonesImage);
  const Icon = error ? error.icon : null;

  return error ? (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      title={<FormattedMessage id={`BioMonitoringImageError.status.${error.id}`} />}
    >
      <div className={classes.errorWrapper}>
        {Icon && <Icon className={classes.icon} />}
      </div>
    </Tooltip>
  ) : (
    children
  );
};

export default BioMonitoringImageErrorWrapper;
