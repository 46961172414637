import React from 'react';

import { FormikProps } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { editBatchCustomerKey } from '../../actions/telematicsAggregations.actions';

import { CfFormikErrors } from '../../../types';
import { DriveKey } from '../../components/TelematicsAggregationDetailContent/formComponents';
import { MESSAGES } from '../../components/TelematicsAggregationDetailContent/validators';

import { useCustomStyles } from './styles/useCustomStyles';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { DriveKeyTo, KeyType } from '../../../shared/api/telematics/telematics.types';

const useCustomerKey = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const customClasses = useCustomStyles();

  const selectedRides = useSelector((state: TelematicsState) => state.ui.telematicsAggregations.selectedRides);

  const initialValues: { customerKey?: DriveKeyTo } = {
    customerKey: undefined,
  };

  const validateContentForm = (values: typeof initialValues) => {
    const errors: CfFormikErrors<typeof initialValues> = {};

    if (!values.customerKey) {
      errors.customerKey = MESSAGES.required;
    }
    return errors;
  };

  const onSubmit = (values: typeof initialValues) => {
    const payload = { drive: selectedRides, customerKeyId: values.customerKey?.id ?? -1 };
    return editBatchCustomerKey(payload)(dispatch);
  };

  const component = (formikProps: FormikProps<typeof initialValues>) => (<DriveKey
    customClasses={customClasses}
    disabled={formikProps.isSubmitting}
    fieldName="customerKey"
    helperText={(formikProps.errors.customerKey as string) ?? ''}
    keyType={KeyType.CUSTOMER}
    placeholder={intl.formatMessage({ id: 'TelematicsAggregations.list.bulk.secondary.placeholder.customerKey' })}
    showApprovalWarning={false}
/>);

  return {
    initialValues,
    validateContentForm,
    onSubmit,
    component,
  };
};

export default useCustomerKey;
