import React, { FC, useEffect, useContext } from 'react';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getMachines, getIsFetchingMachines, getMachinesCount } from '../../../selectors/telematicsCatalogues.selectors';

import { fetchMachines } from '../../../actions/telematicsCatalogues.actions';

import { getSelectedNamespace, setSelectedNamespace } from '../../../reducers/namespace.reducer';

import { Thunk } from '../../../../types';
import { CataloguesContext } from '../../../containers/CataloguesWrapper/CataloguesWrapper';
import TCTable from '../shared/TCTable';
import TCTableToolbar from '../shared/TCTableToolbar';

import { columns } from './machines.columns';
import MachinesListRow from './MachinesListRow';

import { CataloguesState, CATALOGUES_NAMESPACES } from '../../../../reducers/catalogues.reducer.types';
import { MachineTo } from '../../../../shared/api/telematics/telematics.types';

interface Props {
    count: number;
    fetchMachines: () => void;
    isFetching: boolean;
    machines: MachineTo[];
    namespace: CATALOGUES_NAMESPACES;
    setNamespace: (namespace: CATALOGUES_NAMESPACES) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
}));

const MachinesList: FC<Props> = ({
  count,
  fetchMachines,
  isFetching,
  machines,
  namespace,
  setNamespace,
}) => {
  const { langId } = useContext(CataloguesContext);
  const classes = useStyles();

  useEffect(() => {
    setNamespace(CATALOGUES_NAMESPACES.MACHINES);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchMachines();
  }, [fetchMachines, langId]);

  if (namespace !== CATALOGUES_NAMESPACES.MACHINES) return null;

  return (
    <div className={classes.wrapper}>
      <TCTableToolbar
        handleOnChangeTrigger={fetchMachines}
        helperTextId="Catalogues.table.machines.search"
      />
      <TCTable
        columns={columns}
        count={count}
        handleOnChangeTrigger={fetchMachines}
        hasData={!!machines.length}
        isFetching={isFetching}
      >
        {machines.map((machine) => (
          <MachinesListRow data={machine} key={machine.id} onEditSuccess={fetchMachines} />
        ))}
      </TCTable>
    </div>
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  machines: getMachines(state),
  isFetching: getIsFetchingMachines(state),
  count: getMachinesCount(state),
  namespace: getSelectedNamespace(state),
});

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) => bindActionCreators({
  fetchMachines,
  setNamespace: setSelectedNamespace,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MachinesList);
