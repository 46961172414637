import moment from 'moment';

import { getShortDateString } from '../../../../shared/misc/timeHelpers';

import { EagriFertilizerPropertiesCreateTo, FertilizerCreateTo, FertilizerDetailTo, UnitType } from '../../../../shared/api/agroevidence/agroevidence.types';
import { FertilizerItemFormValues } from './FertilizerDetail.types';

export const initialEmptyValues: Partial<FertilizerItemFormValues> = {
  name: '',
  b: 0,
  cao: 0,
  chlorides: 0,
  combustibleContent: 0,
  cu: 0,
  description: '',
  electricConductivity: 0,
  fe: 0,
  isOrganic: false,
  k2o: 0,
  mgo: 0,
  mn: 0,
  mo: 0,
  n: 0,
  na2o: 0,
  p2o5: 0,
  phFrom: 0,
  phTo: 0,
  producerTradeName: '',
  s: 0,
  se: 0,
  unitConversionCoefficient: 0,
  zn: 0,
  validFrom: null,
  validTo: null,
  unitType: null,
  isExcrement: false,
  isEco: false,
  nitrogenCoefficient: 100,
  nitrogenCategory: undefined,
  registrationNumber: '',
  ordinance: '',
  evidenceNumber: '',
  applicantTradeName: '',
  fertilizerKind: undefined,
  registrationType: undefined,
};

export const mapInitialValues = (fertilizer: FertilizerDetailTo) => {
  const initialValues: Partial<FertilizerItemFormValues> = {
    name: fertilizer.name,
    b: fertilizer.b,
    cao: fertilizer.cao,
    chlorides: fertilizer.chlorides,
    combustibleContent: fertilizer.combustibleContent,
    cu: fertilizer.cu,
    description: fertilizer.description || '',
    electricConductivity: fertilizer.electricConductivity,
    fe: fertilizer.fe,
    isOrganic: fertilizer.isOrganic || false,
    k2o: fertilizer.k2o,
    mgo: fertilizer.mgo,
    mn: fertilizer.mn,
    mo: fertilizer.mo,
    n: fertilizer.n,
    na2o: fertilizer.na2o,
    p2o5: fertilizer.p2o5,
    phFrom: fertilizer.phFrom,
    phTo: fertilizer.phTo,
    producerTradeName: fertilizer.producerTradeName || '',
    s: fertilizer.s,
    se: fertilizer.se,
    unitConversionCoefficient: fertilizer.unitConversionCoefficient,
    zn: fertilizer.zn,
    validFrom: fertilizer.eagriProperties?.validFrom ? moment(fertilizer.eagriProperties?.validFrom).startOf('day') : null,
    validTo: fertilizer.eagriProperties?.validTo ? moment(fertilizer.eagriProperties?.validTo).startOf('day') : null,
    unitType: fertilizer.unitType,
    isExcrement: fertilizer.eagriProperties?.isExcrement || false,
    isEco: fertilizer.eagriProperties?.isEco || false,
    nitrogenCoefficient: (fertilizer.eagriProperties?.nitrogenCoefficient ?? 0) * 100,
    nitrogenCategory: fertilizer.eagriProperties?.nitrogenCategory,
    registrationNumber: fertilizer.eagriProperties?.registrationNumber || '',
    ordinance: fertilizer.eagriProperties?.ordinance || '',
    evidenceNumber: fertilizer.eagriProperties?.evidenceNumber || '',
    applicantTradeName: fertilizer.eagriProperties?.applicantTradeName || '',
    fertilizerKind: fertilizer.eagriProperties?.fertilizerKind,
    registrationType: fertilizer.eagriProperties?.registrationType,
  };
  return initialValues;
};

export const mapRequestBodyUpdateFertilizer = (values: FertilizerItemFormValues) => {
  const eagriProperties: EagriFertilizerPropertiesCreateTo = {
    registrationNumber: values.registrationNumber,
    evidenceNumber: values.evidenceNumber,
    applicantTradeName: values.applicantTradeName,
    isExcrement: values.isExcrement,
    nitrogenCategory: values.nitrogenCategory,
    nitrogenCoefficient: values.nitrogenCoefficient / 100,
    isEco: values.isEco,
    ordinance: values.ordinance,
    fertilizerKind: values.fertilizerKind,
    registrationType: values.registrationType,
  };

  if (values.validFrom !== null) {
    eagriProperties.validFrom = getShortDateString(values.validFrom);
  }

  if (values.validTo !== null) {
    eagriProperties.validTo = getShortDateString(values.validTo);
  }

  const params: FertilizerCreateTo = {
    name: values.name,
    description: values.description,
    producerTradeName: values.producerTradeName,
    isHidden: false,
    isOrganic: values.isOrganic,
    n: values.n,
    p2o5: values.p2o5,
    k2o: values.k2o,
    mgo: values.mgo,
    cao: values.cao,
    na2o: values.na2o,
    s: values.s,
    chlorides: values.chlorides,
    combustibleContent: values.combustibleContent,
    zn: values.zn,
    cu: values.cu,
    fe: values.fe,
    b: values.b,
    mn: values.mn,
    mo: values.mo,
    se: values.se,
    phFrom: values.phFrom,
    phTo: values.phTo,
    electricConductivity: values.electricConductivity,
    unitType: values.unitType !== null ? values.unitType : UnitType.WEIGHT,
    unitConversionCoefficient: values.unitConversionCoefficient,
    eagriProperties,
  };

  return params;
};
