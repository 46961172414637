import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const UpdateLpisIcon = props => (
  <SvgIcon {...props}>
    <svg height="20" viewBox="0 0 21 20" width="21" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.825 12.175C18.1 11.45 17.105 11 16 11C13.79 11 12.005 12.79 12.005 15C12.005 17.21 13.79 19 16 19C17.865 19 19.42 17.725 19.865 16H18.825C18.415 17.165 17.305 18 16 18C14.345 18 13 16.655 13 15C13 13.345 14.345 12 16 12C16.83 12 17.57 12.345 18.11 12.89L16.5 14.5H20V11L18.825 12.175Z" />
      <path clipRule="evenodd" d="M20.125 10.6982V14.625H16.1982L17.9318 12.8914C17.4228 12.4187 16.7494 12.125 16 12.125C14.414 12.125 13.125 13.414 13.125 15C13.125 16.586 14.414 17.875 16 17.875C17.2503 17.875 18.3141 17.075 18.7071 15.9585L18.7365 15.875H20.0263L19.986 16.0312C19.5273 17.8096 17.9238 19.125 16 19.125C13.7208 19.125 11.88 17.2788 11.88 15C11.88 12.7212 13.7208 10.875 16 10.875C17.0949 10.875 18.085 11.3035 18.8237 11.9995L20.125 10.6982ZM16 11.125C13.8592 11.125 12.13 12.8588 12.13 15C12.13 17.1412 13.8592 18.875 16 18.875C17.7494 18.875 19.2178 17.7167 19.7002 16.125H18.9122C18.4624 17.2947 17.3283 18.125 16 18.125C14.276 18.125 12.875 16.724 12.875 15C12.875 13.276 14.276 11.875 16 11.875C16.8658 11.875 17.6371 12.2351 18.1988 12.802L18.2864 12.8904L16.8018 14.375H19.875V11.3018L18.825 12.3518L18.7366 12.2634C18.0338 11.5606 17.0702 11.125 16 11.125Z" fillRule="evenodd" />
      <path clipRule="evenodd" d="M17.34 0.03L17.5 0C17.78 0 18 0.22 18 0.5V9.34141C17.3744 9.12031 16.7013 9 16 9C14.4633 9 13.0615 9.57771 12 10.5278V4.11L6 2V13.89L10.0073 15.2992C10.0455 16.0772 10.2319 16.8162 10.5388 17.4886L6 15.9L0.66 17.97L0.5 18C0.22 18 0 17.78 0 17.5V2.38C0 2.15 0.15 1.97 0.36 1.9L6 0L12 2.1L17.34 0.03Z" fillRule="evenodd" />
    </svg>
  </SvgIcon>
);

export default UpdateLpisIcon;
