import React, { Component, Fragment } from 'react';

import Divider from '@mui/material/Divider';
import { withStyles } from '@mui/styles';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCountries as getCountriesSuggestions } from '../../../../shared/api/core/countries/countries.selectors';

import { getCountries, resetCountries, setCountry } from '../../actions/signup.actions';

import CfAutocomplete from '../../../../shared/components/common/CfAutocomplete/CfAutocomplete';
import Countries from '../../../../shared/services/Countries.service';
import { Thunk } from '../../../../types';

import { CountryFrom, SignupState } from '../../../../reducers/signup.reducer.types';

const styles = {
  flag: {
    width: 45,
  },
};

interface Props extends WrappedComponentProps {
    classes: Record<string, string>;
    country: CountryFrom;
    error: boolean,
    getCountries: (search?: string) => void;
    langId: string;
    resetCountries: () => void;
    resetError: () => void;
    setCountry: (country: CountryFrom) => void;
    suggestions: CountryFrom[]
}

interface State {
  langChanged: boolean;
}

export class CountrySelector extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.props.getCountries();
    this.state = {
      langChanged: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { country, langId, suggestions } = this.props;
    const { langChanged } = this.state;
    if (prevProps.langId !== langId) {
      this.props.resetCountries();
      this.props.getCountries();
      this.setState({ langChanged: true });
    }
    // when language changes --> update of the selected country is needed
    if (langChanged && suggestions.length > 0) {
      const defaultOption = suggestions.find(suggestion => suggestion.code === country?.code);
      if (defaultOption) {
        this.props.setCountry(defaultOption);
        this.setState({ langChanged: false });
      }
    }
  }

  componentWillUnmount() {
    this.props.resetCountries();
  }

  onSuggestionClear = () => {
    this.props.resetCountries();
  };

  onChange = (value: CountryFrom) => {
    this.props.setCountry(value);
    this.props.resetError();
  };

  renderGetOption = (option: Record<string, string>) => (
    <Fragment>
      <span className={this.props.classes.flag}>{Countries.countryToFlag(option.code)}</span>
      {option.name}
    </Fragment>
  );

  render() {
    const { country, error, suggestions } = this.props;
    const { formatMessage } = this.props.intl;
    const label = formatMessage({ id: 'common.country' });
    return (
      <CfAutocomplete
        defaultValues={country}
        error={error}
        getSelected={(option, value) => option.code === value?.code}
        groupByOptions={option => option.fullSupport}
        id="country-selector"
        label={label}
        onChange={value => this.onChange(value)}
        renderOption={option => this.renderGetOption(option)}
        suggestions={suggestions}
        renderGroup={option => (
          <Fragment key={option.key}>
            {option.children}
            {option.key === 0 && <Divider />}
          </Fragment>
        )}
      />
    );
  }
}

const mapStateToProps = (state: SignupState) => ({
  suggestions: getCountriesSuggestions(state),
  country: state.ui.signup.country,
});

const mapDispatchToProps = (dispatch: Thunk<SignupState>) =>
  bindActionCreators(
    {
      getCountries,
      resetCountries,
      setCountry,
    },
    dispatch,
  );

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CountrySelector)));
