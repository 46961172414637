import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './cataloguesTypes.constants';

import { RsaaTypes } from '../../api.types';

export const getCataloguesTypesApi = () => ({
  [RSAA]: {
    endpoint: 'catalogues?',
    method: methods.GET,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_CATALOGUES_TYPES_REQUEST,
      types.GET_CATALOGUES_TYPES_SUCCESS,
      types.GET_CATALOGUES_TYPES_ERROR,
    ] as RsaaTypes,
  },
});

export const resetCataloguesTypesApi = () => ({
  type: types.RESET_CATALOGUES_TYPES,
});
