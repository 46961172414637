import React from 'react';

import { Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { generatePath, useHistory } from 'react-router-dom';

import { PRECISION_URLS } from '../precision.constants';

import CfTableCell from '../../../shared/components/tables/CfTableCell/CfTableCell';
import { getShortDateString } from '../../../shared/misc/timeHelpers';
import { getDuration } from '../../../telematics/helpers';

import { TaskDataListTaskTo } from '../../../shared/api/satellite/satellite.types';

const useStyles = makeStyles((theme: Theme) => ({
  operation: {
    color: theme.palette.primary.dark,
  },
  secondaryTextLight: {
    color: theme.palette.grey[400],
  },
  secondaryText: {
    color: theme.palette.grey[500],
  },
  firstCell: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 16,
  },
  columnRight: {
    textAlign: 'right',
  },
  columnLeft: {
    textAlign: 'left',
  },
  row: {
    cursor: 'pointer',
  },
}));

interface Props {
  data: TaskDataListTaskTo;
  farmId: string;
  taskDataId: number;
}

const AsAppliedTaskRow = ({
  data, farmId, taskDataId,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const rowClickRedirectionUrl = generatePath(`/farm/${farmId}/${PRECISION_URLS.asAppliedDetail()}`, {
    farmId,
    taskDataId,
    taskId: data.id,
  });

  const handleClick = () => history.push(rowClickRedirectionUrl);

  return (
    <TableRow
      className={classes.row}
      onClick={handleClick}
    >
      <CfTableCell name="name">
        <Tooltip className={classes.firstCell} title={''}>
          <div>
            {data.name ?? '-'}
          </div>
        </Tooltip>
      </CfTableCell>
      <CfTableCell name="dateFrom">
        <span>
          {data.dateFrom ? getShortDateString(data.dateFrom, 'D. M. YYYY HH:mm') : '-'}
        </span>
      </CfTableCell>
      <CfTableCell name="dateTo">
        <span>
          {data.dateTo ? getShortDateString(data.dateTo, 'D. M. YYYY HH:mm') : '-'}
        </span>
      </CfTableCell>
      <CfTableCell classes={{ root: classes.columnLeft }} name="duration">
        <span>{data.duration ? `${getDuration(data.duration)}:00` : '-' }</span>
      </CfTableCell>
      <CfTableCell name={'actions'}>
        <></>
      </CfTableCell>
    </TableRow>
  );
};

export default AsAppliedTaskRow;
