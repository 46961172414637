import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';

import CheckCircleIconFilled from '../../../../../shared/icons/CheckCircleIconFilled';
import VaMapSourceItem from '../VaMapSourceItem/VaMapSourceItem';

const styles = () => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    margin: '10px 0 0 0',
  },
  container: {
    position: 'relative',
    marginRight: 5,
    marginBottom: 10,
  },
  isLast: {
    marginRight: 0,
  },
  selection: {
    backgroundColor: 'rgba(235, 166, 7, 0)',
    zIndex: 3,
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    color: 'rgba(235, 166, 7, 1)',
    width: 45,
    height: 45,
  },
});

const ITEMS_PER_LINE = 5;

const getValId = (val = {}) => {
  const {
    chemicalElement,
    crop,
    dateFrom: from,
    dateTo: to,
    type,
  } = val;

  return `${type}_${crop?.legislativeCode || chemicalElement}_${from || ''}_${to || ''}`;
};

const VaMapSourceList = props => {
  const { classes, onItemClick, satellite, source } = props;
  return (
    <div className={`${classes.wrapper} `}>
      {source.map((val, index) => {
        const isLast = index + 1 === ITEMS_PER_LINE;
        const isSelected = satellite && getValId(satellite) === getValId(val);

        return (
          <div
            className={`${classes.container} ${isLast ? classes.isLast : ''}`}
            data-test="source-image"
            key={`${getValId(val)}`}
          >
            {isSelected && (
              <div className={classes.selection}>
                <CheckCircleIconFilled className={classes.icon} />
              </div>
            )}
            <VaMapSourceItem
              isSelected={isSelected}
              mapId={getValId(val)}
              onClick={() => onItemClick(val)}
              satellite={val}
            />
          </div>
        );
      })}
    </div>
  );
};

VaMapSourceList.propTypes = {
  classes: PropTypes.object.isRequired,
  source: PropTypes.array.isRequired,
  onItemClick: PropTypes.func.isRequired,
  satellite: PropTypes.object,
};

VaMapSourceList.defaultProps = {
  satellite: null,
};

export default compose(withStyles(styles))(VaMapSourceList);
