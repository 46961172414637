import React, { FC, useContext } from 'react';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getTelematicsAdminSelectedFarms } from '../selectors/telematicsAdmin.selectors';

import { fetchAccountFarms, resetSelectedFarms } from '../selectors/telematicsAdmin.actions';

import { UpdateFarmsPayload, updateTelematicsAccountFarmsApi } from '../../../../shared/api/telematics/admin/telematicsAdmin.api';
import CfDialog from '../../../../shared/components/common/CfDialog/CfDialog';
import { SnackbarContext } from '../../../../shared/containers/SnackbarProvider/SnackbarProvider';
import { AsyncFn, Thunk } from '../../../../types';

import useTelematicsAdminStyles from './styles/telematicsAdmin.styles';

import { AdminState } from '../../../../reducers/admin.reducer.types';
import { AccountTo } from '../../../../shared/api/telematics/telematics.types';

interface Props {
  fetchAccountFarms: () => void,
  handleClose: () => void;
  opened: boolean;
  resetSelectedFarms: () => void;
  selectedFarms: AccountTo[];
  updateFarms: (payload: UpdateFarmsPayload) => void;
}

const DeactivationDialog: FC<Props> = ({
  fetchAccountFarms,
  handleClose,
  opened,
  resetSelectedFarms,
  selectedFarms,
  updateFarms,
}) => {
  const classes = useTelematicsAdminStyles();
  const showSnackbar = useContext(SnackbarContext);

  const handleSubmit = () => {
    const payload = selectedFarms.map(({ externalId, farmId }) => ({
      farmId,
      // force type as farms for deactivation always have externalId
      externalId: (externalId as string),
      enabled: false,
      automaticActions: false,
    }));
    (updateFarms as AsyncFn<UpdateFarmsPayload>)(payload).then((res) => {
      if (res.error) {
        showSnackbar({ message: <FormattedMessage id="TelematicsAdmin.dialog.error" />, isError: true });
        return;
      }
      fetchAccountFarms();
      resetSelectedFarms();
      showSnackbar({ message: <FormattedMessage id="TelematicsAdmin.deactivate.dialog.success" values={{ count: selectedFarms.length }} />, isSuccess: true });
    });
    handleClose();
  };

  return (
    <CfDialog
      acceptText={<FormattedMessage id="TelematicsAdmin.deactivate.dialog.acceptBtn" />}
      cancelText={<FormattedMessage id="common.cancel" />}
      onAccept={handleSubmit}
      onCancel={handleClose}
      opened={opened}
      title={<FormattedMessage id="TelematicsAdmin.deactivate.dialog.title" />}
    >
      <>
        <p className={classes.dislaimer}><FormattedMessage id="TelematicsAdmin.deactivate.dialog.disclaimer" /></p>
        <div className={classes.formContainer}>
          {selectedFarms.map(({ farmId, name }) => (
            <div className={classes.formRow} key={farmId}>
              <div className={classes.farmInfo}>
                <p className={classes.farmName}>{name}</p>
                <p className={classes.farmId}>{farmId}</p>
              </div>
            </div>
          ))}
        </div>
      </>
    </CfDialog>
  );
};

const mapStateToProps = (state: AdminState) => ({
  selectedFarms: getTelematicsAdminSelectedFarms(state),
});

const mapDispatchToProps = (dispatch: Thunk<AdminState>) => bindActionCreators({
  updateFarms: updateTelematicsAccountFarmsApi,
  fetchAccountFarms,
  resetSelectedFarms,
},
dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(DeactivationDialog);
