import * as aggregations from '../../shared/constants/aggregations.constants';
import * as statistics from '../constants/statistics.constants';

import AtmosphericPressure from '../../shared/icons/sensorsFeatures/AtmosphericPressure';
import ElectricalConductivityIcon from '../../shared/icons/sensorsFeatures/ElectricalConductivityIcon';
import Evapotranspiration from '../../shared/icons/sensorsFeatures/Evapotranspiration';
import HumidityIcon from '../../shared/icons/sensorsFeatures/HumidityIcon';
import RainfallIcon from '../../shared/icons/sensorsFeatures/RainfallIcon';
import SoilMoistureIcon from '../../shared/icons/sensorsFeatures/SoilMoisture';
import SoilTemperatureIcon from '../../shared/icons/sensorsFeatures/SoilTemperatureIcon';
import SolarRadiation from '../../shared/icons/sensorsFeatures/SolarRadiation';
import SwpIcon from '../../shared/icons/sensorsFeatures/SwpIcon';
import TemperatureIcon from '../../shared/icons/sensorsFeatures/TemperatureIcon';
import WetnessIcon from '../../shared/icons/sensorsFeatures/WetnessIcon';
import WindIcon from '../../shared/icons/sensorsFeatures/WindIcon';
import ValveIcon from '../../shared/icons/ValveFilledIcon';
import Logger from '../../shared/services/Logger.service';
import BulkConductivityInfoDialog from '../components/BulkConductivityDialog/BulkConductivityInfoDialog';
import EvapotranspirationInfoDialog from '../components/EvapotranspirationInfoDialog/EvapotranspirationInfoDialog';
import SensorChartTooltip from '../components/SensorChartTooltip/SensorChartTooltip';
import SensorChartTooltipRainfall from '../components/SensorChartTooltipRainfall/SensorChartTooltipRainfall';
import SensorChartTooltipWind from '../components/SensorChartTooltipWind/SensorChartTooltipWind';
import SwpInfoDialog from '../components/SwpInfoDialog/SwpInfoDialog';
import WetnessInfoDialog from '../components/WetnessInfoDialog/WetnessInfoDialog';

import FeatureConfig, { FEATURE_TYPES, FEATURE_TYPE_PREFIX } from './FeatureConfig.service';

/* Definitions of device types */

export const DEVICE_TYPE_PREFIX = 'DEVICE_';

export const DEVICE_TYPES = {
  DEVICE_THERMO: 'DEVICE_THERMO',
  DEVICE_FIELD: 'DEVICE_FIELD',
  DEVICE_FIELD_V2: 'DEVICE_FIELD_V2',
  DEVICE_METEO: 'DEVICE_METEO',
  DEVICE_METEO_BARANI_HELIX: 'DEVICE_METEO_BARANI_HELIX',
  DEVICE_METEO_BARANI_RAIN: 'DEVICE_METEO_BARANI_RAIN',
  DEVICE_METEO_BARANI_WIND: 'DEVICE_METEO_BARANI_WIND',
  DEVICE_METEO_BARANI_WIND_FW1: 'DEVICE_METEO_BARANI_WIND_FW1',
  DEVICE_METEO_V3: 'DEVICE_METEO_V3',
  DEVICE_METEO_V4: 'DEVICE_METEO_V4',
  DEVICE_METEO_V4N: 'DEVICE_METEO_V4N',
  DEVICE_SOIL: 'DEVICE_SOIL',
  DEVICE_SOIL_PL: 'DEVICE_SOIL_PL',
  DEVICE_SOIL_V2: 'DEVICE_SOIL_V2',
  DEVICE_SOIL_V3: 'DEVICE_SOIL_V3',
  DEVICE_SOIL_V3_SK: 'DEVICE_SOIL_V3_SK',
  DEVICE_STORAGE: 'DEVICE_STORAGE',
  DEVICE_SILO_V5: 'DEVICE_SILO_V5',
  DEVICE_SILO_V7: 'DEVICE_SILO_V7',
  DEVICE_SILO_V10: 'DEVICE_SILO_V10',
};

/* Definitions of chart types */

export const CHART_TYPE_PREFIX = 'CHART_';

export const CHART_TYPES = {
  CHART_TEMPERATURE: 'CHART_TEMPERATURE',
  CHART_SOIL_TEMPERATURE: 'CHART_SOIL_TEMPERATURE',
  CHART_HUMIDITY: 'CHART_HUMIDITY',
  CHART_RAINFALL: 'CHART_RAINFALL',
  CHART_SWP: 'CHART_SWP',
  CHART_SOIL_MOISTURE: 'CHART_SOIL_MOISTURE',
  CHART_STORAGE: 'CHART_STORAGE',
  CHART_LEAF_WETNESS: 'CHART_LEAF_WETNESS',
  CHART_VALVE: 'CHART_VALVE',
  CHART_WIND: 'CHART_WIND',
  CHART_BULK_ELECTRICAL_CONDUCTIVITY: 'CHART_BULK_ELECTRICAL_CONDUCTIVITY',
  CHART_IRRADIATION: 'CHART_IRRADIATION',
  CHART_PRESSURE: 'CHART_PRESSURE',
  CHART_EVAPOTRANSPIRATION: 'CHART_EVAPOTRANSPIRATION',
};

/* Definitions of device configs */

export const DEVICE_CONFIG = {
  [DEVICE_TYPES.DEVICE_THERMO]: {
    name: DEVICE_TYPES.DEVICE_THERMO,
    charts: [CHART_TYPES.CHART_TEMPERATURE],
  },
  [DEVICE_TYPES.DEVICE_FIELD]: {
    name: DEVICE_TYPES.DEVICE_FIELD,
    charts: [CHART_TYPES.CHART_TEMPERATURE, CHART_TYPES.CHART_HUMIDITY],
  },
  [DEVICE_TYPES.DEVICE_FIELD_V2]: {
    name: DEVICE_TYPES.DEVICE_FIELD_V2,
    charts: [CHART_TYPES.CHART_TEMPERATURE, CHART_TYPES.CHART_HUMIDITY],
  },
  [DEVICE_TYPES.DEVICE_METEO]: {
    name: DEVICE_TYPES.DEVICE_METEO,
    charts: [CHART_TYPES.CHART_TEMPERATURE, CHART_TYPES.CHART_HUMIDITY, CHART_TYPES.CHART_RAINFALL],
  },
  [DEVICE_TYPES.DEVICE_METEO_V3]: {
    name: DEVICE_TYPES.DEVICE_METEO_V3,
    charts: [
      CHART_TYPES.CHART_TEMPERATURE,
      CHART_TYPES.CHART_HUMIDITY,
      CHART_TYPES.CHART_RAINFALL,
      CHART_TYPES.CHART_LEAF_WETNESS,
    ],
  },
  [DEVICE_TYPES.DEVICE_METEO_V4]: {
    name: DEVICE_TYPES.DEVICE_METEO_V4,
    charts: [
      CHART_TYPES.CHART_TEMPERATURE,
      CHART_TYPES.CHART_HUMIDITY,
      CHART_TYPES.CHART_RAINFALL,
      CHART_TYPES.CHART_LEAF_WETNESS,
      CHART_TYPES.CHART_WIND,
    ],
  },
  [DEVICE_TYPES.DEVICE_METEO_V4N]: {
    name: DEVICE_TYPES.DEVICE_METEO_V4N,
    charts: [
      CHART_TYPES.CHART_TEMPERATURE,
      CHART_TYPES.CHART_HUMIDITY,
      CHART_TYPES.CHART_RAINFALL,
      CHART_TYPES.CHART_LEAF_WETNESS,
    ],
  },
  [DEVICE_TYPES.DEVICE_SOIL]: {
    name: DEVICE_TYPES.DEVICE_SOIL,
    charts: [CHART_TYPES.CHART_TEMPERATURE, CHART_TYPES.CHART_SWP],
  },
  [DEVICE_TYPES.DEVICE_SOIL_PL]: {
    name: DEVICE_TYPES.DEVICE_SOIL,
    charts: [CHART_TYPES.CHART_TEMPERATURE, CHART_TYPES.CHART_SWP],
  },
  [DEVICE_TYPES.DEVICE_SOIL_V2]: {
    name: DEVICE_TYPES.DEVICE_SOIL,
    charts: [CHART_TYPES.CHART_TEMPERATURE, CHART_TYPES.CHART_SOIL_MOISTURE],
  },
  [DEVICE_TYPES.DEVICE_SOIL_V3]: {
    name: DEVICE_TYPES.DEVICE_SOIL_V3,
    charts: [
      CHART_TYPES.CHART_SOIL_TEMPERATURE,
      CHART_TYPES.CHART_SOIL_MOISTURE,
      CHART_TYPES.CHART_BULK_ELECTRICAL_CONDUCTIVITY],
  },
  [DEVICE_TYPES.DEVICE_SOIL_V3_SK]: {
    name: DEVICE_TYPES.DEVICE_SOIL_V3_SK,
    charts: [
      CHART_TYPES.CHART_SOIL_TEMPERATURE,
      CHART_TYPES.CHART_SOIL_MOISTURE,
      CHART_TYPES.CHART_BULK_ELECTRICAL_CONDUCTIVITY],
  },
  [DEVICE_TYPES.DEVICE_STORAGE]: {
    name: DEVICE_TYPES.DEVICE_STORAGE,
    charts: [CHART_TYPES.CHART_STORAGE],
  },
  [DEVICE_TYPES.DEVICE_SILO_V5]: {
    name: DEVICE_TYPES.DEVICE_STORAGE,
    charts: [CHART_TYPES.CHART_STORAGE],
  },
  [DEVICE_TYPES.DEVICE_SILO_V7]: {
    name: DEVICE_TYPES.DEVICE_STORAGE,
    charts: [CHART_TYPES.CHART_STORAGE],
  },
  [DEVICE_TYPES.DEVICE_SILO_V10]: {
    name: DEVICE_TYPES.DEVICE_STORAGE,
    charts: [CHART_TYPES.CHART_STORAGE],
  },
  [DEVICE_TYPES.DEVICE_METEO_BARANI_HELIX]: {
    name: DEVICE_TYPES.DEVICE_METEO_BARANI_HELIX,
    charts: [
      CHART_TYPES.CHART_TEMPERATURE,
      CHART_TYPES.CHART_HUMIDITY,
      CHART_TYPES.CHART_RAINFALL,
      CHART_TYPES.CHART_IRRADIATION,
      CHART_TYPES.CHART_PRESSURE,
      CHART_TYPES.CHART_WIND,
      CHART_TYPES.CHART_EVAPOTRANSPIRATION,
    ],
  },
  [DEVICE_TYPES.DEVICE_METEO_BARANI_RAIN]: {
    name: DEVICE_TYPES.DEVICE_METEO_BARANI_RAIN,
    charts: [
      CHART_TYPES.CHART_RAINFALL,
    ],
  },
  [DEVICE_TYPES.DEVICE_METEO_BARANI_WIND]: {
    name: DEVICE_TYPES.DEVICE_METEO_BARANI_WIND,
    charts: [
      CHART_TYPES.CHART_WIND,
    ],
  },
  [DEVICE_TYPES.DEVICE_METEO_BARANI_WIND_FW1]: {
    name: DEVICE_TYPES.DEVICE_METEO_BARANI_WIND_FW1,
    charts: [
      CHART_TYPES.CHART_WIND,
    ],
  },
};

/* Definitions of chart configs */

export const CHART_CONFIG = {
  [CHART_TYPES.CHART_TEMPERATURE]: {
    name: `${CHART_TYPES.CHART_TEMPERATURE}_${aggregations.AVG}`,
    yAxisProps: {
      domain: ['auto', 'auto'],
    },
    tooltipProps: {
      content: SensorChartTooltip,
    },
    unit: '°C',
    icon: TemperatureIcon,
    stroke: '#D01D02',
    features: [
      {
        type: FEATURE_TYPES.FEATURE_TEMPERATURE,
        aggregation: aggregations.AVG,
      },
    ],
    statistics: [
      {
        featureType: FEATURE_TYPES.FEATURE_TEMPERATURE,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.AVERAGE,
      },
      {
        featureType: FEATURE_TYPES.FEATURE_TEMPERATURE,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.MINIMUM,
      },
      {
        featureType: FEATURE_TYPES.FEATURE_TEMPERATURE,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.MAXIMUM,
      },
    ],
    style: {
      height: 150,
    },
  },
  [CHART_TYPES.CHART_SOIL_TEMPERATURE]: {
    name: `${CHART_TYPES.CHART_SOIL_TEMPERATURE}_${aggregations.AVG}`,
    yAxisProps: {
      domain: ['auto', 'auto'],
    },
    tooltipProps: {
      content: SensorChartTooltip,
    },
    unit: '°C',
    icon: SoilTemperatureIcon,
    stroke: '#DB6B35',
    features: [
      {
        type: FEATURE_TYPES.FEATURE_SOIL_TEMPERATURE,
        aggregation: aggregations.AVG,
      },
    ],
    statistics: [
      {
        featureType: FEATURE_TYPES.FEATURE_SOIL_TEMPERATURE,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.AVERAGE,
      },
      {
        featureType: FEATURE_TYPES.FEATURE_SOIL_TEMPERATURE,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.MINIMUM,
      },
      {
        featureType: FEATURE_TYPES.FEATURE_SOIL_TEMPERATURE,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.MAXIMUM,
      },
    ],
    style: {
      height: 150,
    },
  },
  [CHART_TYPES.CHART_BULK_ELECTRICAL_CONDUCTIVITY]: {
    name: `${CHART_TYPES.CHART_BULK_ELECTRICAL_CONDUCTIVITY}_${aggregations.AVG}`,
    yAxisProps: {
      domain: ['auto', 'auto'],
    },
    tooltipProps: {
      content: SensorChartTooltip,
    },
    unit: 'dS/m',
    icon: ElectricalConductivityIcon,
    stroke: '#6A00B5',
    InfoDialog: BulkConductivityInfoDialog,
    features: [
      {
        type: FEATURE_TYPES.FEATURE_BULK_ELECTRICAL_CONDUCTIVITY,
        aggregation: aggregations.AVG,
      },
    ],
    statistics: [
      {
        featureType: FEATURE_TYPES.FEATURE_BULK_ELECTRICAL_CONDUCTIVITY,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.AVERAGE,
      },
      {
        featureType: FEATURE_TYPES.FEATURE_BULK_ELECTRICAL_CONDUCTIVITY,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.MINIMUM,
      },
      {
        featureType: FEATURE_TYPES.FEATURE_BULK_ELECTRICAL_CONDUCTIVITY,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.MAXIMUM,
      },
    ],
    style: {
      height: 150,
    },
  },
  [CHART_TYPES.CHART_HUMIDITY]: {
    name: `${CHART_TYPES.CHART_HUMIDITY}_${aggregations.AVG}`,
    yAxisProps: {
      domain: [0, 100],
    },
    tooltipProps: {
      content: SensorChartTooltip,
    },
    unit: '%',
    icon: HumidityIcon,
    stroke: '#0A4D9C',
    features: [
      {
        type: FEATURE_TYPES.FEATURE_HUMIDITY,
        aggregation: aggregations.AVG,
      },
    ],
    statistics: [
      {
        featureType: FEATURE_TYPES.FEATURE_HUMIDITY,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.AVERAGE,
      },
      {
        featureType: FEATURE_TYPES.FEATURE_HUMIDITY,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.MINIMUM,
      },
      {
        featureType: FEATURE_TYPES.FEATURE_HUMIDITY,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.MAXIMUM,
      },
    ],
    style: {
      height: 150,
    },
  },
  [CHART_TYPES.CHART_RAINFALL]: {
    name: `${CHART_TYPES.CHART_RAINFALL}_${aggregations.INCREMENT}`,
    yAxisProps: {
      domain: [0, 'auto'],
    },
    tooltipProps: {
      content: SensorChartTooltipRainfall,
      filterNull: false,
      offset: 50,
      position: { y: -70 },
    },
    unit: 'mm',
    lastUpdateUnit: 'mm/h',
    icon: RainfallIcon,
    stroke: '#6AA8EC',
    features: [
      {
        type: FEATURE_TYPES.FEATURE_RAINFALL,
        aggregation: aggregations.SUM,
      },
      {
        type: FEATURE_TYPES.FEATURE_RAINFALL,
        aggregation: aggregations.INCREMENT,
      },
    ],
    style: {
      height: 150,
    },
  },
  [CHART_TYPES.CHART_SWP]: {
    name: `${CHART_TYPES.CHART_SWP}_${aggregations.AVG}`,
    yAxisProps: {
      domain: ['auto', 'auto'],
    },
    tooltipProps: {
      content: SensorChartTooltip,
    },
    unit: 'cbar',
    icon: SwpIcon,
    InfoDialog: SwpInfoDialog,
    stroke: '#0067D7',
    features: [
      {
        type: FEATURE_TYPES.FEATURE_SWP,
        aggregation: aggregations.AVG,
      },
    ],
    statistics: [
      {
        featureType: FEATURE_TYPES.FEATURE_SWP,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.MINIMUM,
      },
      {
        featureType: FEATURE_TYPES.FEATURE_SWP,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.MAXIMUM,
      },
    ],
    style: {
      height: 150,
    },
  },
  [CHART_TYPES.CHART_SOIL_MOISTURE]: {
    name: `${CHART_TYPES.CHART_SOIL_MOISTURE}_${aggregations.AVG}`,
    yAxisProps: {
      domain: ['auto', 'auto'],
    },
    tooltipProps: {
      content: SensorChartTooltip,
    },
    unit: '%',
    icon: SoilMoistureIcon,
    stroke: '#874100',
    features: [
      {
        type: FEATURE_TYPES.FEATURE_SOIL_MOISTURE,
        aggregation: aggregations.AVG,
      },
    ],
    statistics: [
      {
        featureType: FEATURE_TYPES.FEATURE_SOIL_MOISTURE,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.AVERAGE,
      },
      {
        featureType: FEATURE_TYPES.FEATURE_SOIL_MOISTURE,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.MINIMUM,
      },
      {
        featureType: FEATURE_TYPES.FEATURE_SOIL_MOISTURE,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.MAXIMUM,
      },
    ],
    style: {
      height: 150,
    },
  },
  [CHART_TYPES.CHART_STORAGE]: {
    name: `${CHART_TYPES.CHART_STORAGE}_${aggregations.AVG}`,
    yAxisProps: {
      domain: ['auto', 'auto'],
    },
    tooltipProps: {
      content: SensorChartTooltip,
    },
    unit: '°C',
    icon: TemperatureIcon,
    stroke: '#D01D02',
    strokeWidth: 2,
    fill: '#e37767',
    features: [
      {
        type: FEATURE_TYPES.FEATURE_STORAGE,
        aggregation: aggregations.AVG,
        allowedLevels: [
          'Level 1',
          'Level 2',
          'Level 3',
          'Level 4',
          'Level 5',
          'Level 6',
          'Level 7',
          'Level 8',
          'Level 9',
          'Level 10',
        ],
      },
    ],
    statistics: [
      {
        featureType: FEATURE_TYPES.FEATURE_STORAGE,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.AVERAGE,
      },
      {
        featureType: FEATURE_TYPES.FEATURE_STORAGE,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.MINIMUM,
      },
      {
        featureType: FEATURE_TYPES.FEATURE_STORAGE,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.MAXIMUM,
      },
    ],
    style: {
      height: 150,
    },
  },
  [CHART_TYPES.CHART_LEAF_WETNESS]: {
    name: `${CHART_TYPES.CHART_LEAF_WETNESS}_${aggregations.AVG}`,
    yAxisProps: {
      domain: [0, 20],
    },
    tooltipProps: {
      content: SensorChartTooltip,
    },
    unit: null,
    icon: WetnessIcon,
    InfoDialog: WetnessInfoDialog,
    stroke: '#7ED321',
    features: [
      {
        type: FEATURE_TYPES.FEATURE_LEAF_WETNESS,
        aggregation: aggregations.AVG,
      },
    ],
    statistics: [
      {
        featureType: FEATURE_TYPES.FEATURE_LEAF_WETNESS,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.AVERAGE,
      },
      {
        featureType: FEATURE_TYPES.FEATURE_LEAF_WETNESS,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.MINIMUM,
      },
      {
        featureType: FEATURE_TYPES.FEATURE_LEAF_WETNESS,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.MAXIMUM,
      },
    ],
    style: {
      height: 150,
    },
  },
  [CHART_TYPES.CHART_WIND]: {
    name: CHART_TYPES.CHART_WIND,
    yAxisProps: {
      domain: ['auto', dataMax => dataMax + 0.5],
      dataKey: `${FEATURE_TYPES.FEATURE_WIND_GUST}_${aggregations.AVG}`,
    },
    tooltipProps: {
      content: SensorChartTooltipWind,
      filterNull: false,
      offset: 50,
      position: { y: -40 },
    },
    unit: 'm/s',
    icon: WindIcon,
    stroke: '#51AF98',
    features: [
      {
        type: FEATURE_TYPES.FEATURE_WIND_SPEED,
        aggregation: aggregations.AVG,
        allowedLevels: ['1s'],
      },
      {
        type: FEATURE_TYPES.FEATURE_WIND_GUST,
        aggregation: aggregations.AVG,
        allowedLevels: ['3s'],
      },
      {
        type: FEATURE_TYPES.FEATURE_WIND_DIRECTION,
        aggregation: aggregations.MAX_COUNT,
        allowedLevels: ['1s'],
      },
    ],
    statistics: [
      {
        featureType: FEATURE_TYPES.FEATURE_WIND_SPEED,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.AVERAGE,
      },
      {
        featureType: FEATURE_TYPES.FEATURE_WIND_GUST,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.MAXIMUM,
      },
    ],
    style: {
      height: 150,
    },
  },
  [CHART_TYPES.CHART_VALVE]: {
    name: `${CHART_TYPES.CHART_VALVE}_${aggregations.AVG}`,
    yAxisProps: {
      domain: ['auto', 'auto'],
    },
    tooltipProps: {
      content: SensorChartTooltip,
    },
    unit: null,
    icon: ValveIcon,
    stroke: '#848484',
    features: [
      {
        type: FEATURE_TYPES.FEATURE_VALVE,
        aggregation: aggregations.AVG,
      },
    ],
    style: {
      height: 150,
    },
  },
  [CHART_TYPES.CHART_IRRADIATION]: {
    name: `${CHART_TYPES.CHART_IRRADIATION}_${aggregations.AVG}`,
    yAxisProps: {
      domain: ['auto', 'auto'],
    },
    tooltipProps: {
      content: SensorChartTooltip,
    },
    unit: 'W/m2',
    icon: SolarRadiation,
    stroke: '#ED0097',
    features: [
      {
        type: FEATURE_TYPES.FEATURE_IRRADIATION,
        aggregation: aggregations.AVG,
      },
    ],
    statistics: [
      {
        featureType: FEATURE_TYPES.FEATURE_IRRADIATION,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.AVERAGE,
      },
      {
        featureType: FEATURE_TYPES.FEATURE_IRRADIATION,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.MINIMUM,
      },
      {
        featureType: FEATURE_TYPES.FEATURE_IRRADIATION,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.MAXIMUM,
      },
    ],
    style: {
      height: 150,
    },
  },
  [CHART_TYPES.CHART_PRESSURE]: {
    name: `${CHART_TYPES.CHART_PRESSURE}_${aggregations.AVG}`,
    yAxisProps: {
      domain: ['auto', 'auto'],
    },
    tooltipProps: {
      content: SensorChartTooltip,
    },
    unit: 'hPa',
    icon: AtmosphericPressure,
    stroke: '#0FC396',
    features: [
      {
        type: FEATURE_TYPES.FEATURE_PRESSURE,
        aggregation: aggregations.AVG,
      },
    ],
    statistics: [
      {
        featureType: FEATURE_TYPES.FEATURE_PRESSURE,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.AVERAGE,
      },
      {
        featureType: FEATURE_TYPES.FEATURE_PRESSURE,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.MINIMUM,
      },
      {
        featureType: FEATURE_TYPES.FEATURE_PRESSURE,
        aggregation: aggregations.AVG,
        statisticsType: statistics.STATISTICS_TYPES.MAXIMUM,
      },
    ],
    style: {
      height: 150,
    },
  },
  [CHART_TYPES.CHART_EVAPOTRANSPIRATION]: {
    name: `${CHART_TYPES.CHART_EVAPOTRANSPIRATION}_${aggregations.INCREMENT}`,
    yAxisProps: {
      domain: [0, 'auto'],
    },
    tooltipProps: {
      content: SensorChartTooltipRainfall,
      filterNull: false,
      offset: 50,
      position: { y: -70 },
    },
    unit: 'mm',
    icon: Evapotranspiration,
    InfoDialog: EvapotranspirationInfoDialog,
    stroke: '#39BCDC',
    features: [
      {
        type: FEATURE_TYPES.FEATURE_EVAPOTRANSPIRATION,
        aggregation: aggregations.SUM,
      },
      {
        type: FEATURE_TYPES.FEATURE_EVAPOTRANSPIRATION,
        aggregation: aggregations.INCREMENT,
      },
    ],
    style: {
      height: 150,
    },
  },
};

export default class DeviceConfig {
  static getSupportedDevices() {
    return Object.values(DEVICE_TYPES).map(device => device.replace(DEVICE_TYPE_PREFIX, ''));
  }

  static getDeviceConfig(deviceType, sensors) {
    if (!DEVICE_CONFIG[`DEVICE_${deviceType}`]) {
      Logger.warn(`Unknown device type: ${deviceType}`);
      return { charts: [] };
    }

    const config = Object.assign({}, DEVICE_CONFIG[`DEVICE_${deviceType}`]);
    return {
      ...config,
      charts: config.charts.map(chart => ({
        ...CHART_CONFIG[chart],
        features: CHART_CONFIG[chart].features.map(feature => {
          const { aggregation, type } = feature;
          const embeddedConfig = FeatureConfig.getFeatureConfig(type, aggregation);

          // When using STORAGE type, we need to get all sensors with Temperature feature
          let transformedType = type;
          if (type === FEATURE_TYPES.FEATURE_STORAGE) {
            transformedType = FEATURE_TYPES.FEATURE_TEMPERATURE;
          }

          return {
            // Each feature can have assigned >= 1 sensor;
            sensors: sensors.filter(sensor =>
              sensor.feature.toUpperCase() === transformedType.replace(FEATURE_TYPE_PREFIX, '') &&
              (!sensor.level || !feature.allowedLevels || feature.allowedLevels.includes(sensor.level)),
            ),
            ...embeddedConfig,
          };
        }),
      })),
    };
  }

  static isChartRainfall(chartName) {
    if (chartName && chartName === CHART_CONFIG[CHART_TYPES.CHART_RAINFALL].name) return true;
    return false;
  }

  static isChartEvapotranspiration(chartName) {
    if (chartName && chartName === CHART_CONFIG[CHART_TYPES.CHART_EVAPOTRANSPIRATION].name) return true;
    return false;
  }
}
