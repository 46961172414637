import { RSAA } from 'redux-api-middleware';

import { API_METHODS as methods, API_MODULES as modules } from '../../api.constants';
import * as types from './eagriAuth.constants';

import { EagriAuthDataValidationTo } from '../agroevidence.types';

export const setEagriAuth = (eagriAuth: EagriAuthDataValidationTo) => ({
  [RSAA]: {
    endpoint: 'eagri-auth?',
    method: methods.POST,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(eagriAuth),
    types: [types.SET_EAGRI_AUTH, types.SET_EAGRI_AUTH_SUCCESS, types.SET_EAGRI_AUTH_ERROR],
  },
});

export const getEagriFarmName = () => ({
  [RSAA]: {
    endpoint: 'eagri-auth/name?',
    method: methods.GET,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_EAGRI_FARM_NAME,
      types.GET_EAGRI_FARM_NAME_SUCCESS,
      types.GET_EAGRI_FARM_NAME_FAILURE,
    ],
  },
});
