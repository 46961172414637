import { push } from 'connected-react-router';

import { getNewNode } from '../selectors/node.selectors';

import { removeLocation, fetchLocationsPoints } from './locations.actions';
import { setCoordsSelection } from './map.actions';

import * as sensorsStatus from '../../shared/constants/sensorsStatus.constants';
import * as granularity from '../constants/granularity.constants';
import * as types from './node.constants';

import MapService from '../../core/map/services/Map.service';
import {
  getNodes as getNodesApi,
  resetNodes as resetNodesApi,
  getNodesStatistics as getNodesStatisticsApi,
  resetNodesStatistics as resetNodesStatisticsApi,
  getNode as getNodeApi,
  putNode as putNodeApi,
  getNodeTypes,
} from '../../shared/api/iot/nodes/nodes.api';

export const fetchNode = id => dispatch => dispatch(getNodeApi(id));

export const activateStart = node => dispatch => {
  const coordinates = undefined;
  dispatch(setCoordsSelection(true, coordinates));
  dispatch({
    type: types.EDIT_START,
    newNode: createNodeVersion(node, coordinates),
  });
};

export const activateCancel = () => dispatch => {
  dispatch(setCoordsSelection(false));
  dispatch({
    type: types.EDIT_CANCEL,
  });
};

export const activate = (nodeId, name, geometry, farmId, locationId) => dispatch =>
  dispatch(putNodeApi(nodeId, name, geometry, sensorsStatus.ACTIVE)).then(res => {
    // angular related hack
    setTimeout(() => {
      if (res?.payload?.lastLocationId) {
        dispatch(push(`/farm/${farmId}/sensors/${res.payload.lastLocationId}`));
      }
      dispatch(removeLocation(locationId));
      dispatch(fetchLocationsPoints());
    }, 0);
  });

export const deactivate = (nodeId, name, geometry, farmId, locationId) => dispatch =>
  dispatch(putNodeApi(nodeId, name, geometry, sensorsStatus.INACTIVE)).then(res => {
    // angular related hack
    setTimeout(() => {
      if (res?.payload?.lastLocationId) {
        dispatch(push(`/farm/${farmId}/sensors/${res.payload.lastLocationId}`));
      }
      dispatch(removeLocation(locationId));
      dispatch(fetchLocationsPoints());
    }, 0);
  });

export const updateCoordsFromMap = coords => (dispatch, getState) => {
  const state = getState();
  dispatch(setCoordsSelection(true, coords));
  dispatch({
    type: types.EDIT_UPDATE,
    newNodeVersion: createNodeVersion(getNewNode(state), coords),
  });
};

export const updateCoordsFromForm = coordsWgs => (dispatch, getState) => {
  const state = getState();
  const coords = coordsWgs ? MapService.transformFromWgs(coordsWgs) : null;
  dispatch(setCoordsSelection(true, coords));
  if (coords) {
    dispatch({
      type: types.EDIT_UPDATE,
      newNodeVersion: createNodeVersion(getNewNode(state), coords),
    });
  }
};

const createNodeVersion = (node, coords) => {
  const newNodeVersion = {
    ...node,
  };

  if (coords) {
    newNodeVersion.geometry = {
      ...node.geometry,
      coordinates: coords.slice(0),
      type: 'Point',
    };
    newNodeVersion.geometryWgs = {
      ...node.geometry,
      coordinates: MapService.transformToWgs(coords),
      type: 'Point',
    };
  }

  return newNodeVersion;
};

export const setGranularity = granularity => ({
  type: types.SET_GRANULARITY,
  granularity,
});

export const setDuration = duration => ({
  type: types.SET_DURATION,
  duration,
  granularity: granularityByDuration(duration),
});

export const resetDuration = () => ({
  type: types.RESET_DURATION,
});

export const getNumberOfDays = (start, end) => {
  const date1 = new Date(start);
  const date2 = new Date(end);
  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;
  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();
  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);
  return diffInDays === 0 ? 1 : diffInDays;
};

export const granularityByDuration = duration => {
  if (duration === 1) return granularity.G30M;
  if (duration > 1 && duration <= 14) return granularity.G4H;
  if (duration > 14 && duration <= 60) return granularity.G24H;
  return granularity.G1W;
};

export const fetchNodes = () => dispatch =>
  dispatch(getNodesApi({ page: 0, rowsPerPage: 500 })).then(res => {
    if (res?.payload?.itemsIds?.length) {
      dispatch(getNodesStatisticsApi());
    }
  });

export const fetchNodesStatistics = () => dispatch => dispatch(getNodesStatisticsApi());

export const resetNodes = () => dispatch => {
  dispatch(resetNodesApi());
  dispatch(resetNodesStatisticsApi());
};

export const setDate = (dateFrom, dateTo, duration) => ({
  type: types.SET_DATE,
  dateFrom,
  dateTo,
  duration,
  granularity: granularityByDuration(duration),
});

export const fetchDeviceTypes = () => dispatch => {
  dispatch(getNodeTypes());
};
