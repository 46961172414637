import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './countries.constants';

export const getCountries = params => {
  const p = {
    search: params.search,
  };
  return {
    [RSAA]: {
      endpoint: `countries/supported?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.CORE,
      types: [types.GET_COUNTRIES, types.GET_COUNTRIES_SUCCESS, types.GET_COUNTRIES_FAILURE],
    },
  };
};

export const resetCountries = () => ({
  type: types.RESET_COUNTRIES,
});
