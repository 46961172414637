import React, { FC, useMemo, useContext } from 'react';

import { MenuItem } from '@mui/material';
import { Field, Formik, FormikErrors, Form } from 'formik';
import moment, { Moment } from 'moment';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getTelematicsAdminSelectedFarms } from '../selectors/telematicsAdmin.selectors';

import { fetchAccountFarms, resetSelectedFarms } from '../selectors/telematicsAdmin.actions';

import { UpdateFarmsPayload, updateTelematicsAccountFarmsApi } from '../../../../shared/api/telematics/admin/telematicsAdmin.api';
import CfDialog from '../../../../shared/components/common/CfDialog/CfDialog';
import CfFormikDatePicker from '../../../../shared/components/form/CfFormikDatePicker/CfFormikDatePicker';
import CfFormikSwitch from '../../../../shared/components/form/CfFormikSwitch/CfFormikSwitch';
import CfFormikTextField from '../../../../shared/components/form/CfFormikTextField/CfFormikTextField';
import { SnackbarContext } from '../../../../shared/containers/SnackbarProvider/SnackbarProvider';
import { getShortDateString } from '../../../../shared/misc/timeHelpers';
import { AsyncFn, Thunk } from '../../../../types';

import useTelematicsAdminStyles from './styles/telematicsAdmin.styles';

import { AdminState } from '../../../../reducers/admin.reducer.types';
import { AccountTo, Catalogue, EconomicSystem } from '../../../../shared/api/telematics/telematics.types';

interface ActivationFormValues {
  [farmId: string]: {
    automaticActions: boolean;
    catalogue?: Catalogue;
    economicSystem?: EconomicSystem;
    economicSystemDate?: Moment;
    externalId?: string;
    farmId: string;
    name: string;
  }
}

const validate = (values: ActivationFormValues) => {
  const errors: FormikErrors<ActivationFormValues> = {};

  Object.keys(values).forEach((farmId) => {
    if (!values[farmId]?.externalId) {
      errors[farmId] = { ...errors[farmId], externalId: 'validation.required' };
    }
    if (values[farmId]?.economicSystem && !values[farmId]?.economicSystemDate) {
      errors[farmId] = { ...errors[farmId], economicSystemDate: 'validation.required' };
    }
    if (!values[farmId]?.economicSystem && values[farmId]?.economicSystemDate) {
      errors[farmId] = { ...errors[farmId], economicSystem: 'validation.required' };
    }
    if (values[farmId]?.economicSystemDate && !values[farmId]?.economicSystemDate?.isValid()) {
      errors[farmId] = { ...errors[farmId], economicSystemDate: 'validation.required' };
    }
  });

  return errors;
};

interface Props {
  fetchAccountFarms: () => void,
  handleClose: () => void;
  opened: boolean;
  resetSelectedFarms: () => void;
  selectedFarms: AccountTo[];
  updateFarms: (payload: UpdateFarmsPayload) => void;
}

const ActivationDialog: FC<Props> = ({
  fetchAccountFarms,
  handleClose,
  opened,
  resetSelectedFarms,
  selectedFarms,
  updateFarms,
}) => {
  const classes = useTelematicsAdminStyles();
  const showSnackbar = useContext(SnackbarContext);

  const initialValues = useMemo(() => selectedFarms.reduce((acc, farm) => {
    acc[farm.farmId] = {
      name: farm.name,
      farmId: farm.farmId,
      externalId: farm?.externalId ?? '',
      catalogue: farm?.catalogue ?? '' as Catalogue,
      economicSystem: farm?.economicSystem ?? '' as EconomicSystem,
      economicSystemDate: farm?.economicSystemDate ? moment(farm.economicSystemDate) : undefined,
      automaticActions: farm.automaticActions,
    };
    return acc;
  }, {} as ActivationFormValues), [selectedFarms]);

  const handleSubmit = (values: ActivationFormValues) => {
    const farmIds = Object.keys(values);
    const payload = farmIds.map((farmId) => {
      const val = values[farmId];
      const esDate = val.economicSystemDate;

      if (!val.externalId) throw new Error('External ID is required');

      return {
        farmId: val.farmId,
        externalId: val?.externalId,
        catalogue: val?.catalogue || undefined,
        economicSystem: val?.economicSystem || undefined,
        economicSystemDate: esDate ? getShortDateString(esDate) : undefined,
        enabled: true,
        automaticActions: val.automaticActions,
      };
    });
    (updateFarms as AsyncFn<UpdateFarmsPayload>)(payload).then((res) => {
      if (res.error) {
        showSnackbar({ message: <FormattedMessage id="TelematicsAdmin.dialog.error" />, isError: true });
        return;
      }
      fetchAccountFarms();
      resetSelectedFarms();
      showSnackbar({ message: <FormattedMessage id="TelematicsAdmin.activateOrEdit.dialog.success" values={{ count: farmIds.length }} />, isSuccess: true });
    });
    handleClose();
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
     >
      {({ errors, setValues, submitForm, values }) => {
        const hasErrors = !!Object.keys(errors).length;
        return (
          <CfDialog
            acceptText={<FormattedMessage id="TelematicsAdmin.activate.dialog.acceptBtn" />}
            cancelText={<FormattedMessage id="common.cancel" />}
            maxWidth="lg"
            onAccept={submitForm}
            onCancel={handleClose}
            opened={opened}
            title={<FormattedMessage id="TelematicsAdmin.activateOrEdit.dialog.title" />}
        >
            <>
              <p className={classes.dislaimer}><FormattedMessage id="TelematicsAdmin.activateOrEdit.dialog.disclaimer" /></p>
              <div className={classes.formContainer}>
                <Form>
                  {Object.keys(values).map(item => (
                    <div className={classes.formRow} key={values[item].farmId}>
                      <div className={classes.farmInfo}>
                        <p className={classes.farmName}>{values[item].name}</p>
                        <p className={classes.farmId} data-test="farmId">{values[item].farmId}</p>
                      </div>
                      <Field
                        component={CfFormikTextField}
                        customClasses={{ root: classes.companyId }}
                        error={!!errors?.[item]?.externalId}
                        fullWidth={false}
                        id={'externalId'}
                        label={<FormattedMessage id="TelematicsAdmin.activateOrEdit.dialog.companyId" />}
                        name={`${item}.externalId`}
                      />
                      <Field
                        component={CfFormikTextField}
                        customClasses={{ root: classes.catalogue }}
                        id={'catalogue'}
                        label={<FormattedMessage id="TelematicsAdmin.activateOrEdit.dialog.catalogue" />}
                        name={`${item}.catalogue`}
                        select
                      >
                        <MenuItem classes={{ root: classes.menuItem }} value={Catalogue.WINFAS}>
                          <span>{Catalogue.WINFAS}</span>
                        </MenuItem>
                        <MenuItem classes={{ root: classes.menuItem }} value="">
                          <span>-</span>
                        </MenuItem>
                      </Field>
                      <Field
                        component={CfFormikTextField}
                        customClasses={{ root: classes.economicSystem }}
                        error={!!errors?.[item]?.economicSystem}
                        id={'economicSystem'}
                        label={<FormattedMessage id="TelematicsAdmin.activateOrEdit.dialog.economicSystem" />}
                        name={`${item}.economicSystem`}
                        select
                        onChange={(e: React.BaseSyntheticEvent) => {
                          setValues({
                            ...values,
                            [item]: {
                              ...values[item],
                              economicSystem: e.target.value,
                              economicSystemDate: e.target.value === '' ? undefined : values[item].economicSystemDate,
                            },
                          }, true);
                        }}
                      >
                        <MenuItem classes={{ root: classes.menuItem }} value={EconomicSystem.TOS}>
                          <span>{EconomicSystem.TOS}</span>
                        </MenuItem>
                        <MenuItem classes={{ root: classes.menuItem }} value="">
                          <span>-</span>
                        </MenuItem>
                      </Field>
                      <Field
                        // use key to force re-render when economicSystemDate is changed
                        className={classes.economicSystemDate}
                        component={CfFormikDatePicker}
                        error={!!errors?.[item]?.economicSystemDate}
                        helperText=""
                        key={values[item].economicSystem ?? 0}
                        label={<FormattedMessage id="TelematicsAdmin.activateOrEdit.dialog.economicSystemDate" />}
                        name={`${item}.economicSystemDate`}
                      />
                      <Field
                        component={CfFormikSwitch}
                        label={<FormattedMessage id="TelematicsAdmin.activateOrEdit.dialog.automaticActions" />}
                        name={`${item}.automaticActions`}
                      />
                    </div>
                  ))}
                </Form>
              </div>
              {hasErrors &&
                <p className={classes.errorMessage}>
                  <FormattedMessage id="TelematicsAdmin.activateOrEdit.dialog.validationError" />
                </p>
              }
            </>
          </CfDialog>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: AdminState) => ({
  selectedFarms: getTelematicsAdminSelectedFarms(state),
});

const mapDispatchToProps = (dispatch: Thunk<AdminState>) => bindActionCreators({
  updateFarms: updateTelematicsAccountFarmsApi,
  fetchAccountFarms,
  resetSelectedFarms,
},
dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ActivationDialog);
