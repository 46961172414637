import React, { useMemo } from 'react';

import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

import '../../misc/polyfills';
import { LANG_KEYS } from '../../lang/lang.constants';

import Metadata from '../../../core/metadata/Metadata';
import createCftheme from '../../../theme';
import useAppTheming from '../../hooks/useAppTheming';
import commonTranslationsCZ from '../../lang/locale-cs-CZ.json';
import commonTranslationsEL from '../../lang/locale-el-GR.json';
import commonTranslationsEN from '../../lang/locale-en-US.json';
import commonTranslationsES from '../../lang/locale-es-ES.json';
import commonTranslationsRO from '../../lang/locale-ro-RO.json';
import commonTranslationsRU from '../../lang/locale-ru-UA.json';
import commonTranslationsSR from '../../lang/locale-sr-LATN-RS.json';
import Localization from '../../services/Localization.service';
import SnackbarProvider from '../SnackbarProvider/SnackbarProvider';

const commonTranslations = {
  [LANG_KEYS.CZ]: commonTranslationsCZ,
  [LANG_KEYS.EN]: commonTranslationsEN,
  [LANG_KEYS.SR]: commonTranslationsSR,
  [LANG_KEYS.ES]: commonTranslationsES,
  [LANG_KEYS.RU]: commonTranslationsRU,
  [LANG_KEYS.EL]: commonTranslationsEL,
  [LANG_KEYS.RO]: commonTranslationsRO,
};

const BaseContainer = (props) => {
  const { children, langId, translations } = props;

  const themeVersion = useAppTheming();
  const muiTheme = createTheme(createCftheme(themeVersion));

  const mergedTranslations = useMemo(() => {
    if (translations) return Localization.mergeTranslations(commonTranslations, translations);
    return undefined;
  }, [translations]);

  return (
    <IntlProvider locale={langId} messages={mergedTranslations[langId]}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <LocalizationProvider dateAdapter={AdapterMoment} locale={langId}>
            <SnackbarProvider>
              <Metadata />
              {children}
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </IntlProvider>
  );
};

BaseContainer.propTypes = {
  children: PropTypes.node.isRequired,
  translations: PropTypes.object.isRequired,
  langId: PropTypes.string,
};

BaseContainer.defaultProps = {
  langId: '',
};

export default BaseContainer;
