import { AnyAction } from 'redux';

import * as types from './soilSamples.constants';

import { SoilSamplesAPI } from './soilSamples.types';

const initialState: SoilSamplesAPI = {
  isFetching: false,
  error: undefined,
  items: [],
};

export default () => (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.GET_SOIL_SAMPLES_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_SOIL_SAMPLES_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.payload,
      };
    case types.GET_SOIL_SAMPLES_DATA_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.RESET_SOIL_SAMPLES_DATA:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        items: [],
      };

    default:
      return state;
  }
};
1;
