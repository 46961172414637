import React, { Component } from 'react';

import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { exportLocation } from '../../actions/locations.actions';

import CfDatePicker from '../../../shared/components/common/CfDatePicker/CfDatePicker';
import CfDialog from '../../../shared/components/common/CfDialog/CfDialog';

const style = () => ({
  grid: {
    marginRight: '50px',
  },
});

class NodeLocationExportDialog extends Component {
  constructor(props) {
    super(props);
    this.startDate = moment(props.nodeLocation.from).startOf('day');
    this.endDate = moment(props.nodeLocation.to).endOf('day') || moment().endOf('day');

    this.state = {
      dateFrom: this.startDate,
      dateTo: this.endDate,
      errorProps: {
        dateFrom: {},
        dateTo: {},
        range: {},
      },
    };
  }

  isValidDate = date => moment.isMoment(date) && date.isValid();

  onDateFromChange = value => {
    this.setErrorToFalse('dateFrom');
    if (this.isValidDate(value)) {
      this.setState({
        dateFrom: value,
      });
      if (value.isAfter(moment(this.state.dateTo))) {
        this.setErrorToTrue('range', 'DatePicker.wrongDateRange');
      } else {
        this.setErrorToFalse('range');
      }
    } else {
      this.setErrorToTrue('dateFrom', 'validation.invalidDate');
    }
  };

  onDateToChange = value => {
    this.setErrorToFalse('dateTo');
    if (this.isValidDate(value)) {
      const newValue = moment(value).endOf('day');
      this.setState({
        dateTo: newValue,
      });

      if (newValue.isBefore(moment(this.state.dateFrom))) {
        this.setErrorToTrue('range', 'DatePicker.wrongDateRange');
      } else {
        this.setErrorToFalse('range');
      }
    } else {
      this.setErrorToTrue('dateTo', 'validation.invalidDate');
    }
  };

  setErrorToTrue = (field, id) => {
    this.setState((prevState) => ({
      errorProps: {
        ...prevState.errorProps,
        [field]: {
          error: true,
          helperText: <FormattedMessage id={id} />,
        },
      },
    }));
  };

  setErrorToFalse = (field) => {
    this.setState((prevState) => ({
      errorProps: {
        ...prevState.errorProps,
        [field]: {},
      },
    }));
  };

  resetDates = () => {
    this.setState({
      dateFrom: this.startDate,
      dateTo: this.endDate,
    });
    this.setErrorToFalse();
  };

  resetAndClose = () => {
    this.resetDates();
    this.props.onCancel();
  };

  exportLocation = () => {
    this.props.exportLocation(this.props.nodeLocation.id, this.state.dateFrom, this.state.dateTo);
    this.resetDates();
    this.props.onAccept();
  };

  render() {
    const { classes, nodeLocation, onCancel, opened } = this.props;
    const dialogTitle = `${this.props.intl.formatMessage({ id: 'NodeLocationExportDialog.header' })} ${nodeLocation.name}`;
    const hasAnyError =
      this.state.errorProps.dateFrom?.error ||
      this.state.errorProps.dateTo?.error ||
      this.state.errorProps.range?.error;
    return (
      <CfDialog
        acceptText={<FormattedMessage id="common.export" />}
        cancelText={<FormattedMessage id="common.cancel" />}
        data-test="node-export"
        dialogHeight={'95px'}
        disabled={hasAnyError}
        onAccept={this.exportLocation}
        onCancel={this.resetAndClose}
        onClose={onCancel}
        opened={opened}
        title={dialogTitle}
      >
        <Grid container spacing={2}>
          <Grid className={classes.grid} item xs={5}>
            <CfDatePicker
              label={<FormattedMessage id="common.date-from" />}
              maxDate={this.endDate}
              maxDateMessage={<FormattedMessage id="NodeLocationExportDialog.maxDateMessage" />}
              minDate={this.startDate}
              minDateMessage={<FormattedMessage id="NodeLocationExportDialog.minDateMessage" />}
              name="exportFrom"
              propagateInvalidDate
              input={{
                value: this.state.dateFrom,
                onChange: this.onDateFromChange,
              }}
              {...this.state.errorProps.dateFrom}
              {...this.state.errorProps.range}
            />
          </Grid>
          <Grid item xs={5}>
            <CfDatePicker
              label={<FormattedMessage id="common.date-to" />}
              maxDate={this.endDate}
              maxDateMessage={<FormattedMessage id="NodeLocationExportDialog.maxDateMessage" />}
              minDate={this.startDate}
              minDateMessage={<FormattedMessage id="NodeLocationExportDialog.minDateMessage" />}
              name="exportTo"
              propagateInvalidDate
              input={{
                value: this.state.dateTo,
                onChange: this.onDateToChange,
              }}
              {...this.state.errorProps.dateTo}
              {...this.state.errorProps.range}
            />
          </Grid>
        </Grid>
      </CfDialog>
    );
  }
}

NodeLocationExportDialog.propTypes = {
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object,
  nodeLocation: PropTypes.object.isRequired,
  opened: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  exportLocation: PropTypes.func.isRequired,
};

NodeLocationExportDialog.defaultProps = {
  classes: {},
  opened: false,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      exportLocation,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(withStyles(style)(injectIntl(NodeLocationExportDialog)));
