import { createColumns } from '../../../helpers/tableHelpers';

import { Catalogue } from '../../../../shared/api/telematics/telematics.types';

export enum COLUMN_NAMES {
    CODE = 'code',
    NAME = 'name',
    VALID_FROM= 'validFrom',
    VALID_TO= 'validTo',
}

export const getColumnsByCatalogueType = (catalogue?: string) => createColumns([
  {
    id: COLUMN_NAMES.CODE,
    primaryLabel: 'Catalogues.table.drivers.column.code',
    sortable: true,
    style: { paddingLeft: 16 },
  },
  {
    id: COLUMN_NAMES.NAME,
    primaryLabel: 'Catalogues.table.drivers.column.name',
    ...(catalogue === Catalogue.WINFAS && { secondaryLabel: 'Catalogues.table.drivers.column.personalNumber' }),
    sortable: true,
  },
  {
    id: COLUMN_NAMES.VALID_FROM,
    primaryLabel: 'Catalogues.table.drivers.column.validFrom',
    sortable: true,
  },
  {
    id: COLUMN_NAMES.VALID_TO,
    primaryLabel: 'Catalogues.table.drivers.column.validTo',
    sortable: true,
  },
]);
