import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import { change } from 'redux-form';

import { getParcelIds } from '../selectors/reports.selectors';

import * as types from './reports.constants';

import {
  getParcelsApi,
  resetParcelsApi,
  addParcels,
} from '../../../shared/api/agroevidence/parcels/parcels.api';
import { postFertilizerUsageReportApi, postPorUsageReportApi, getPorUsageReportApi, getFertilizerUsageReportApi, getSeededAreasReportApi } from '../../../shared/api/agroevidence/reports/reports.api';
import { getZonesApi, resetZonesApi } from '../../../shared/api/agroevidence/zones/zones.api';
import { getReport, getSowingPlan } from '../../../shared/api/core/reports/reports.api';
import FileService from '../../../shared/services/File.service';

export const fetchParcelsAndZonesSuggestions = (searchInput, parcelsOnly = false, zonesOnly = false, sown) =>
  dispatch => {
    if (parcelsOnly) {
      return dispatch(getParcelsApi({ context: 'suggestions', search: searchInput, sown }));
    }

    if (zonesOnly) {
      return dispatch(getZonesApi(searchInput));
    }

    dispatch(getParcelsApi({ context: 'suggestions', search: searchInput, sown }));
    return dispatch(getZonesApi(searchInput));
  };

export const clearParcelsAndZonesSuggestions = (parcelsOnly = false, zonesOnly = false) => dispatch => {
  if (parcelsOnly) {
    return dispatch(resetParcelsApi('suggestions'));
  }

  if (zonesOnly) {
    return dispatch(resetZonesApi());
  }

  dispatch(resetParcelsApi('suggestions'));
  dispatch(resetZonesApi());
};

export const addParcelOrZoneParcelsToAdd = parcelOrZone => dispatch => {
  const isZone = 'parcelCount' in parcelOrZone;
  if (isZone && parcelOrZone.parcelCount > 0) {
    dispatch(getParcelsApi({ context: 'additions', zones: parcelOrZone.id, 'per-page': 200 }));
  } else if (!isZone) {
    dispatch(addParcels('additions', parcelOrZone));
  }
};

export const clearParcelsToAdd = () => dispatch => {
  dispatch(resetParcelsApi('additions'));
};

export const updateParcelsList = (parcels, form) => dispatch => {
  dispatch(change(form, 'parcels', parcels));
};

export const getReports = (values, format) => (dispatch) => {
  let params = {
    from: values.from,
    to: values.to,
    format,
  };
  if (!values.allParcels) {
    params = {
      ...params,
      parcelIds: getParcelIds(values),
    };
  }
  const promises = [];
  if (values.categories) {
    Object.entries(values.categories).forEach(([, report]) => {
      const reportsToExport = keys(pickBy(report, r => r === true));
      reportsToExport.forEach(toExport => {
        params = {
          ...params,
          id: toExport,
        };
        const promise = exportReport(params)(dispatch);
        promises.push(promise);
      });
    });
    Promise.all(promises).then((actions) => {
      const noContentReports = actions.filter(action => action.payload.status === 204).map(action => action.payload.id);
      dispatch(setNoContentReports(noContentReports));
    });
  }
};

/*
 * This is the place to resolve discrepancies between old and new endpoints.
 */
const exportReport = _params => dispatch => {
  let call;
  const params = { ..._params };

  switch (params.id) {
    case 'seededAreas': {
      call = getSeededAreasReportApi;
      params.format = params.format === 'xls' ? 'xlsx' : params.format;
      break;
    }
    case 'fertilizerUsage': {
      call = getFertilizerUsageReportApi;
      params.format = params.format === 'xls' ? 'xlsx' : params.format;
      break;
    }
    default:
      call = getReport;
  }

  return dispatch(call(params)).then(res => {
    FileService.processFileResponse(res);
    return res;
  });
};

export const exportSowingPlan = params => dispatch =>
  dispatch(getSowingPlan(params)).then(res => {
    const noContentReports = res.payload.status === 204 ? [res.payload.id] : [];
    dispatch(setNoContentReports(noContentReports));
    FileService.processFileResponse(res);
    return res;
  });

export const setNoContentReports = noContentReports => ({
  type: types.SET_NO_CONTENT_REPORTS,
  noContentReports,
});

export const clearNoContentReport = () => ({ type: types.CLEAR_NO_CONTENT_REPORTS });

export const sendFertilizerUsageReport = (params) => dispatch =>
  dispatch(postFertilizerUsageReportApi(params));

export const sendPorUsageReport = (params) => dispatch =>
  dispatch(postPorUsageReportApi(params));

export const getPorUsageReport = (params) => dispatch => dispatch(getPorUsageReportApi(params));
