import React, { FC } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import * as indices from '../../../../../core/precision/selectors/indices';
import CfStatusPanel from '../../../../../shared/components/common/CfStatusPanel/CfStatusPanel';
import { links } from '../../../../../shared/constants/links';
import useWidth from '../../../../../shared/hooks/useWidth';
import SatelliteIcon from '../../../../../shared/icons/SatelliteIcon';

interface Props {
  langId?: string;
  // TODO add type during rewrite to TS
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  source: any;
  type: 'HIST' | 'SAMPLES' | 'LAI' | 'CAB';
}

const VaMapSourceNotAvailable: FC<Props> = ({
  langId = 'cs-CZ',
  source = null,
  type,
}) => {
  const width = useWidth();
  const intl = useIntl();

  const isPurchased = Array.isArray(source) && !source.length;
  const isSoilSamples = type === 'SAMPLES';

  let messageId;
  if (isPurchased) {
    messageId = isSoilSamples
      ? 'VaMapSourceNotAvailable.soilSamples.needToWait'
      : 'aMapSourceNotAvailable.needToWait';
  } else {
    messageId = 'VaMapSourceNotAvailable.needToBuy';
  }

  return (
    <CfStatusPanel
      dense={true}
      elevation={0}
      fullWidth={true}
      grey={true}
      horizontal={width !== 'xs'}
      icon={SatelliteIcon}
      linkHref={langId === 'cs-CZ' ? links.cs.precisionFarming : links.en.precisionFarming}
      linkText={isPurchased ? null : <FormattedMessage id="common.findOutMore" />}
      content={
        <FormattedMessage
          id={messageId}
          values={{
            type:
              type === indices.CAB || type === indices.LAI
                ? 'monitoring'
                : intl.formatMessage({ id: `VaMapSourceDialog.${type}` }).toLowerCase(),
          }}
        />
      }
    />
  );
};

export default VaMapSourceNotAvailable;
