import { createSelector } from 'reselect';

import { getPrecisionAsAppliedOrder, getPrecisionAsAppliedOrderBy, getPrecisionAsAppliedPage, getPrecisionAsAppliedRowsPerPage, getPrecisionAsAppliedTextFilter } from '../../../../core/precision/selectors/precisionAsApplied.selectors';

import { paginationArray, sortArray } from '../../../misc/arraysAction.helpers';
import ErrorService from '../../../services/Error.service';

import { PrecisionState } from '../../../../reducers/precision.reducer.types';

export const getPrecisionTaskdata = (state: PrecisionState) => state.api.precisionAsApplied.taskdata;
export const getPrecisionAsAppliedIsFetching = (state: PrecisionState) => state.api.precisionAsApplied.isFetching;
export const getError = (state: PrecisionState) => ErrorService.getResErrorDto(state.api.precisionParcels.error);

export const getPrecisionAsAppliedFiltered = createSelector(
  getPrecisionTaskdata,
  getPrecisionAsAppliedTextFilter,
  (data, textFilter) => {
    if (textFilter.length === 0) return data;
    // const textFilterLowerCase = textFilter.toLowerCase();

    const parcelsFiltered = data;
    // const parcelsFiltered = parcels.filter(parcel =>
    //   parcel.localName.toLowerCase().includes(textFilterLowerCase) ||
    //   parcel.blockNr.toLowerCase().includes(textFilterLowerCase),
    // );
    return parcelsFiltered;
  });

export const getPrecisionParcelsSortedPaginated = createSelector(
  getPrecisionAsAppliedFiltered,
  getPrecisionAsAppliedOrder,
  getPrecisionAsAppliedOrderBy,
  getPrecisionAsAppliedPage,
  getPrecisionAsAppliedRowsPerPage,
  (parcelsFiltered, order, orderBy, page, rowsPerPage) => {
    const parcelsSorted = sortArray(order, orderBy, parcelsFiltered);
    return paginationArray(page, rowsPerPage, parcelsSorted);
  });

export const getTotalCount = createSelector(
  getPrecisionTaskdata,
  getPrecisionAsAppliedTextFilter,
  getPrecisionAsAppliedFiltered,
  (parcels, textFilter, parcelsFiltered) => {
    if (textFilter.length === 0) return parcels.length;
    return parcelsFiltered.length;
  });
