import { AnyAction } from 'redux';

import * as types from '../asAppliedTaskdata/asAppliedTaskdata.constants';

import { PrecisionAsApplied } from './precision.types';

export const NAMESPACE = 'precisionAsAppliedList';

const initialState: PrecisionAsApplied = {
  error: undefined,
  isFetching: false,
  taskdata: [],
};

export default () => (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.GET_AS_APPLIED_TASKDATA_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    case types.GET_AS_APPLIED_TASKDATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        taskdata: action.payload,
      };
    case types.GET_AS_APPLIED_TASKDATA_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
