import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import CfFormattedNumber from '../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import withOpen from '../../../../../shared/hocs/withOpen';
import SubtractableAreaDialog from '../../containers/SubtractableAreaDialog/SubtractableAreaDialog';

function SubtractableAreaLink(props) {
  const { editing, formName, index, isOpen, onClose, onOpen, parcel, subtractableAreasIds } = props;

  return (
    <Fragment>
      <a data-test="subtract-area-link" href="#" onClick={onOpen}>
        <CfFormattedNumber value={parcel.area - (parcel.restrictedArea || 0)} />      </a>
      {' / '}
      <CfFormattedNumber value={parcel.area} />
      <SubtractableAreaDialog
        editing={editing}
        formName={formName}
        handleClose={onClose}
        index={index}
        onAccept={onClose}
        opened={isOpen}
        parcel={parcel}
        subtractableAreasIds={subtractableAreasIds}
      />
    </Fragment>
  );
}

SubtractableAreaLink.propTypes = {
  parcel: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  editing: PropTypes.bool,
  subtractableAreasIds: PropTypes.array,
};

SubtractableAreaLink.defaultProps = {
  editing: false,
  subtractableAreasIds: undefined,
};

export default withOpen(SubtractableAreaLink);
