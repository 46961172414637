import React, { FC, useEffect, useState } from 'react';

import { FormControlLabel, Switch, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getSectionListDateFrom, getSectionListSearch } from '../../../selectors/sectionList.selectors';

import { setSectionListDateFrom } from '../../../actions/sectionList.actions';

import { getSelectedNamespace } from '../../../reducers/namespace.reducer';

import CfTextFilter from '../../../../shared/containers/CfTextFilter/CfTextFilter';
import { useDidUpdate } from '../../../../shared/hooks/useDidUpdate';
import { getShortDateString } from '../../../../shared/misc/timeHelpers';
import { Thunk } from '../../../../types';

import { CataloguesState, CATALOGUES_NAMESPACES } from '../../../../reducers/catalogues.reducer.types';

interface Props {
    dateFrom: string;
    handleOnChangeTrigger: () => void;
    helperTextId: string;
    namespace: CATALOGUES_NAMESPACES;
    search: string;
    setDateFrom: (date: string, namespace: CATALOGUES_NAMESPACES) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  switch: {
    marginLeft: 'auto',
  },
  switchLabel: {
    fontSize: 14,
  },
}));

const TCTableToolbar: FC<Props> = ({
  dateFrom,
  handleOnChangeTrigger,
  helperTextId,
  namespace,
  search,
  setDateFrom,
}) => {
  const classes = useStyles();

  const [showHistorical, setShowHistorical] = useState(false);

  useDidUpdate(() => {
    handleOnChangeTrigger();
  }, [dateFrom, search]);

  useEffect(() => {
    const newDateFrom = showHistorical ? getShortDateString(moment('1900-01-01')) : getShortDateString();
    setDateFrom(newDateFrom, namespace);
  }, [showHistorical, setDateFrom, namespace]);

  return (
    <div className={classes.container}>
      <CfTextFilter
        customStyles={{ width: 330 }}
        initialValue={search}
        name="machines-list-text-filter"
        namespace={namespace}
        translId={helperTextId}
      />
      <FormControlLabel
        classes={{ label: classes.switchLabel }}
        className={classes.switch}
        label={<FormattedMessage id="Catalogues.table.shared.showHistory" />}
        control={
          <Switch
            checked={showHistorical}
            color="primary"
            onChange={() => setShowHistorical((prevState) => !prevState)}
          />}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) => bindActionCreators({
  setDateFrom: setSectionListDateFrom,
}, dispatch);

const mapStateToProps = (state: CataloguesState) => ({
  namespace: getSelectedNamespace(state),
  search: getSectionListSearch(state),
  dateFrom: getSectionListDateFrom(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(TCTableToolbar);
