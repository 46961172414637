// TODO delete next line after temporarily simplifying the LpisDialog will be cancelled
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, FC, useState } from 'react';

// TODO delete next line after TextField will use
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment, { Moment } from 'moment';
import { FormattedMessage } from 'react-intl';

import CfDatePicker from '../../../shared/components/common/CfDatePicker/CfDatePicker';

import { EagriUpdateMode } from '../../../shared/api/agroevidence/agroevidence.types';

const useStyles = makeStyles((theme: Theme) => ({
  dialogContainer: {
    minWidth: '300px',
  },
  contentContainer: {
    padding: '5px 0px',
  },
  textField: {
    display: 'flex',
    justifyContent: 'left',
    marginBottom: 20,
    color: theme.palette.grey[500],
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '21px',
  },
  grid: {
    marginBottom: 30,
  },
  label: {
    fontSize: 12,
    marginBottom: 5,
  },
  radioGroupWrapper: {
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  button: {
    marginLeft: 8,
  },
}
));

interface UpdateLpisDialogDialogProps {
  lastUpdateDate?: string,
  onAccept: (wsKey: string, date: string, updateType: string) => void,
  onClose: () => void,
  showDialog?: boolean
}

const UpdateLpisDialog: FC<UpdateLpisDialogDialogProps> = ({
  lastUpdateDate,
  onAccept,
  onClose,
  showDialog = false,
}) => {
  const classes = useStyles();
  const [wsKey, setWsKey] = useState<string>('');
  const [date, setDate] = useState<Moment>(moment().startOf('day'));
  const [updateType, setUpdateType] = useState<string>(EagriUpdateMode.ALL);

  const handleUpdateLPIS = () => {
    onAccept(wsKey, date.toISOString(), updateType);
  };

  // TODO delete next line after wsKey will use
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChangeWsKey = (evt: ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    setWsKey(evt.target.value);
  };

  const onDateChange = (value: Moment) => {
    const newValue = moment(value).startOf('day');
    setDate(newValue);
  };

  const handleRadioGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdateType((event.target as HTMLInputElement).value);
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogContainer }}
      data-test="update-lpis-dialog"
      open={showDialog}
    >
      <DialogTitle>
        <FormattedMessage id="UpdateLPIS.title" />
      </DialogTitle>
      <DialogContent>
        <Grid className={classes.contentContainer}>
          <Grid className={classes.grid} item>
            {/* TODO not BE endpoint for wsKey */}
            {/* <TextField
                className={classes.textField}
                label={<FormattedMessage id="UpdateLPIS.dialog.wsKey" />}
                name="wsKey"
                onChange={onChangeWsKey}
                value={wsKey}
                variant="standard"
              /> */}
          </Grid>
          <Grid className={classes.grid} item>
            <CfDatePicker
              label={<FormattedMessage id="UpdateLPIS.dialog.updateDate" />}
              maxDate={moment().endOf('day')}
              maxDateMessage=""
              minDate={lastUpdateDate ? moment(lastUpdateDate).endOf('day') : undefined}
              minDateMessage=""
              name="date"
              input={{
                value: date,
                onChange: onDateChange,
              }}
                />
          </Grid>
          <Grid className={classes.grid} item>
            <DialogContentText className={classes.label}>
              <FormattedMessage id="UpdateLPIS.dialog.updateLayers" />
            </DialogContentText>
            <RadioGroup
              aria-labelledby="controlled-radio-buttons-group"
              className={classes.radioGroupWrapper}
              name="radio-buttons-group"
              onChange={handleRadioGroupChange}
              value={updateType}
            >
              <FormControlLabel
                control={<Radio />}
                label={<FormattedMessage id="UpdateLPIS.dialog.updateLayers.notAll" />}
                value={EagriUpdateMode.BLOCKS_AND_PARCELS}
              />
              <FormControlLabel
                control={<Radio />}
                label={<FormattedMessage id="UpdateLPIS.dialog.updateLayers.all" />}
                value={EagriUpdateMode.ALL}
              />
            </RadioGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid alignItems="center" container justifyContent="flex-end" spacing={1}>
          <Button
            className={classes.button}
            id="reset"
            onClick={onClose}
            type="reset"
            variant="text"
              >
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            className={classes.button}
            color="primary"
            id="send"
            onClick={handleUpdateLPIS}
            type="submit"
            variant="text"
              >
            <FormattedMessage id="common.update" />
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateLpisDialog;
