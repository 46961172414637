import intersection from 'lodash/intersection';
import { createSelector } from 'reselect';

import { getParcels } from '../../../../shared/api/agroevidence/parcels/parcels.selectors';

import { ParcelsState } from '../../../../reducers/parcels.reducer.types';
import { ParcelTo } from '../../../../shared/api/agroevidence/agroevidence.types';

export const getParcelListPage = (state: ParcelsState) => state.ui.parcelList.page;
export const getParcelListOrder = (state: ParcelsState) => state.ui.parcelList.order;
export const getParcelListOrderBy = (state: ParcelsState) => state.ui.parcelList.orderBy;
export const getParcelListRowsPerPage = (state: ParcelsState) => state.ui.parcelList.rowsPerPage;
export const getParcelListSelected = (state: ParcelsState) => state.ui.parcelList.selected;
export const getParcelListTextFilter = (state: ParcelsState) => state.ui.parcelList.textFilter;
export const getParcelListAdvancedFilter = (state: ParcelsState) => state.ui.parcelList.advancedFilter;

export const getParcelListSelectedOnPage = createSelector(
  getParcels(),
  getParcelListSelected,
  (parcels, selected) => intersection(parcels.map((parcel: ParcelTo) => parcel.id), selected),
);
