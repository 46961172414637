import React, { FC, ReactNode, useEffect, useMemo } from 'react';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCataloguesTypes, getIsFetchingCataloguesTypes } from '../../../../../shared/api/agroevidence/catalogues/cataloguesTypes.selectors';

import { getCataloguesTypesApi, resetCataloguesTypesApi } from '../../../../../shared/api/agroevidence/catalogues/cataloguesTypes.api';
import CfAutocomplete from '../../../../../shared/components/common/CfAutocomplete/CfAutocomplete';
import { Thunk } from '../../../../../types';

import { CataloguesState } from '../../../../../reducers/catalogues.reducer.types';
import { CatalogueTo, CatalogueType } from '../../../../../shared/api/agroevidence/agroevidence.types';

interface Props {
  defaultSource?: CatalogueTo[],
  getCataloguesTypesApi: () => void,
  isCataloguesTypesFetching: boolean,
  label: ReactNode,
  onChange: (items: CatalogueTo[]) => void,
  resetCataloguesTypesApi: () => void,
  suggestions: CatalogueTo[]
}

const SourceSelector: FC<Props> = ({
  defaultSource = [],
  getCataloguesTypesApi,
  isCataloguesTypesFetching,
  label,
  onChange,
  suggestions,
  resetCataloguesTypesApi,
}) => {
  const intl = useIntl();

  useEffect(() => {
    getCataloguesTypesApi();

    return () => {
      resetCataloguesTypesApi();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const extendedeSuggestions = useMemo(() => suggestions.map(s => (
    {
      ...s,
      name: intl.formatMessage({ id: `Catalogues.table.fertilizers.column.source.${s.type === CatalogueType.EAGRI ? 'eAgri' : 'custom'}` }),
    }
  )), [suggestions, intl]);

  return (
    <CfAutocomplete
      defaultValues={defaultSource}
      id="source-filter"
      isFetching={isCataloguesTypesFetching}
      isMultiple={true}
      label={label}
      loadOptions={getCataloguesTypesApi}
      onChange={onChange}
      suggestions={extendedeSuggestions}
      testId="source-filter"
    />
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  suggestions: getCataloguesTypes(state),
  isCataloguesTypesFetching: getIsFetchingCataloguesTypes(state),
});

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      getCataloguesTypesApi,
      resetCataloguesTypesApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SourceSelector);
