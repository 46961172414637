import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getMap,
  getHoveredFeatureId,
  getSelectedFeatureId,
  getIsSelecting,
  getSelectionCoords,
  getMapColumns,
} from '../../selectors/map.selectors';

import { storeMap, storeHoveredFeatureId, setEnlargedVariant, setMapColumns } from '../../actions/map.actions';
import { updateCoordsFromMap } from '../../actions/node.actions';

import MapHintControl from '../../../core/map/components/MapHintControl/MapHintControl';
import SelectMapPositionService from '../../../shared/services/SelectMapPosition.service';
import SensorsMapService from '../../services/SensorsMap.service';
import './Map.css';

class Map extends Component {
  constructor(props) {
    super(props);
    this.handleHoverMap = this.handleHoverMap.bind(this);
    this.handleLeaveMap = this.handleLeaveMap.bind(this);
  }

  componentDidMount() {
    this.smService = new SensorsMapService(
      this.props.config.api,
      this.props.match.params.farmId,
      this.props.farmBbox,
      this.handleHoverNode,
      this.handleClickNode,
    );
    this.map = this.smService.getMap();
    this.selectionMapPositionService = new SelectMapPositionService(
      this.map,
      this.handleDrawNode,
      this.handleHoverMap);

    this.map.addLayer(this.selectionMapPositionService.getSelectionLayer());
    this.props.storeMap(this.map);
    this.smService.setFarmId(this.props.match.params.farmId);
  }

  componentDidUpdate(prevProps) {
    const {
      hoveredFeatureId,
      isList,
      isSelecting,
      mapColumns,
      nodeLocations,
      selectedFeatureId,
      selectionCoords,
    } = this.props;
    if (mapColumns !== prevProps.mapColumns) {
      this.map.updateSize();
      if (!prevProps.mapColumns && isList) {
        this.smService.fitMapViewToFarmExtent();
      }
    }

    if (isSelecting !== prevProps.isSelecting) {
      this.selectionMapPositionService.setSelectionMode(isSelecting);
      if (isSelecting) {
        if (selectionCoords) {
          this.selectionMapPositionService.setSelection(selectionCoords);
        } else {
          this.selectionMapPositionService.clearSelection();
        }
      }
    }

    if (nodeLocations.length && nodeLocations !== prevProps.nodeLocations) {
      this.smService.setNodeLocations(this.props.nodeLocations);
    }
    if (hoveredFeatureId !== prevProps.hoveredFeatureId) {
      this.smService.setHoveredFeatureId(hoveredFeatureId);
      const nodeLocationsLayer = this.smService.getNodeLocationsLayer();
      if (nodeLocationsLayer) {
        nodeLocationsLayer.changed();
      }
    }

    if (selectedFeatureId !== prevProps.selectedFeatureId) {
      this.smService.setSelectedFeatureId(selectedFeatureId);
    }
  }

  handleHoverNode = id => this.props.storeHoveredFeatureId(id);

  handleClickNode = feature => {
    const featureId = feature.get('id');
    this.props.history.push(`/farm/${this.props.match.params.farmId}/sensors/${featureId}`);
    this.props.setEnlargedVariant(false);
    if (this.props.mapColumns === 12) {
      this.props.setMapColumns(0);
    }
  };

  handleDrawNode = feature => {
    this.props.updateCoordsFromMap(feature.getGeometry().getCoordinates());
  };

  handleHoverMap = () => {
    if (this.props.isSelecting) {
      this.selectionMapPositionService.displaySelectionCursor(true);
    }
  };

  handleLeaveMap = () => {
    if (this.props.isSelecting) {
      this.selectionMapPositionService.displaySelectionCursor(false);
    }
  };

  render() {
    return (
      <div style={{ backgroundColor: '#dddddd', height: '100%' }}>
        <div id="sensors-map" onMouseLeave={this.handleLeaveMap} style={{ height: '100%', position: 'relative' }}>
          <MapHintControl onMouseEnter={this.handleLeaveMap} />
        </div>
      </div>
    );
  }
}

Map.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  hoveredFeatureId: PropTypes.string,
  selectedFeatureId: PropTypes.string,
  farmId: PropTypes.string,
  farmBbox: PropTypes.any,
  isSelecting: PropTypes.bool,
  nodeLocations: PropTypes.array,
  selectionCoords: PropTypes.array,
  config: PropTypes.object.isRequired,
  storeMap: PropTypes.func.isRequired,
  storeHoveredFeatureId: PropTypes.func.isRequired,
  updateCoordsFromMap: PropTypes.func.isRequired,
  setEnlargedVariant: PropTypes.func.isRequired,
  setMapColumns: PropTypes.func.isRequired,
  mapColumns: PropTypes.number.isRequired,
  isList: PropTypes.bool,
};

Map.defaultProps = {
  history: {},
  match: {},
  hoveredFeatureId: null,
  selectedFeatureId: null,
  farmId: '',
  farmBbox: [],
  isSelecting: false,
  nodeLocations: [],
  selectionCoords: [],
  isList: true,
};

const mapStateToProps = state => ({
  map: getMap(state),
  hoveredFeatureId: getHoveredFeatureId(state),
  selectedFeatureId: getSelectedFeatureId(state),
  isSelecting: getIsSelecting(state),
  selectionCoords: getSelectionCoords(state),
  mapColumns: getMapColumns(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      storeMap,
      storeHoveredFeatureId,
      updateCoordsFromMap,
      setEnlargedVariant,
      setMapColumns,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Map);
