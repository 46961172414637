import find from 'lodash/find';

import { TELEMATICS_PARCEL_COLOR } from '../../../shared/misc/map.helpers';

import DefaultStyles from './styles/DefaultStyles.service';
import DrawStyles from './styles/DrawStyles.service';
import MergeStyles from './styles/MergeStyles.service';
import SplitStyles from './styles/SplitStyles.service';

export default class StyleService {
  constructor(parcelLayer, parcelLabelLayer, countryCode) {
    this.countryCode = countryCode;
    this.layers = {
      parcels: parcelLayer,
      labels: parcelLabelLayer,
    };
  }

  getColorByFeatureCrop = (crops, feature) => {
    if (crops) {
      const featureCropId = feature.get('crop_id');
      let featureCrop;
      if (featureCropId) {
        featureCrop = crops[featureCropId];
      } else {
        const legCode = feature.get('crop_legislative_code');
        featureCrop = find(crops, crop => crop.legislativeCode.toString() === legCode);
      }
      return featureCrop && featureCrop.visible ? featureCrop.color : null;
    }
    return null;
  };

  refreshDefaultStyles(hoveredId, selectedId, crops) {
    if (!this.layers.parcels) return;
    this.layers.parcels.setStyle(feature => {
      const color = this.getColorByFeatureCrop(crops, feature);
      return DefaultStyles.getParcelStyle(feature, hoveredId, selectedId, color);
    });
    if (!this.layers.labels) return;
    this.layers.labels.setStyle((feature, resolution) => {
      const color = this.getColorByFeatureCrop(crops, feature);
      return DefaultStyles.getLabelStyle(feature, resolution, color);
    });
  }

  getColorByFeatureId = (sowingPlan, feature) => {
    if (!sowingPlan) return;
    const featureParcelId = feature.get('id');
    const featureSowingPlan = find(sowingPlan, item => item.id === featureParcelId);
    return featureSowingPlan ? featureSowingPlan.color : null;
  };

  refreshSowingPlanStyles(hoveredId, selectedId, sowingPlan) {
    if (!this.layers.parcels) return;
    this.layers.parcels.setStyle(feature => {
      const color = this.getColorByFeatureId(sowingPlan, feature);
      return DefaultStyles.getParcelStyle(feature, hoveredId, selectedId, color);
    });
    if (!this.layers.labels) return;
    this.layers.labels.setStyle((feature, resolution) => {
      const color = this.getColorByFeatureId(sowingPlan, feature);
      return DefaultStyles.getLabelStyle(feature, resolution, color);
    });
  }

  refreshSplitStyles(hoveredId, selectedId) {
    this.layers.parcels.setStyle(f => SplitStyles.getParcelStyle(f, hoveredId, selectedId));
    this.layers.labels.setStyle((f, r) => SplitStyles.getLabelStyle(f, r, hoveredId, selectedId));
  }

  refreshMergeStyles(hoveredId, selectedIds, availableIds, reachableIds) {
    this.layers.parcels.setStyle(f =>
      MergeStyles.getParcelStyle(f, hoveredId, selectedIds, availableIds, reachableIds, this.countryCode),
    );
    this.layers.labels.setStyle((f, r) =>
      MergeStyles.getLabelStyle(f, r, hoveredId, selectedIds, availableIds, reachableIds, this.countryCode),
    );
  }

  refreshDrawStyles() {
    this.layers.parcels.setStyle(f => DrawStyles.getParcelStyle(f));
    this.layers.labels.setStyle((f, r) => DrawStyles.getLabelStyle(f, r));
  }

  refreshParcelDetailStyles(hoveredId, selectedId, cropColor) {
    this.layers.parcels.setStyle(feature => {
      let parcelColor = null;

      if (feature.get('id') === selectedId && cropColor) {
        parcelColor = cropColor;
      }
      return DefaultStyles.getParcelStyle(feature, hoveredId, selectedId, parcelColor);
    });
  }

  refreshTelematicsStyles(hoveredId, selectedId) {
    const getColor = (feature) => {
      const featureId = feature.get('id');

      if (featureId === selectedId || featureId === hoveredId) {
        return TELEMATICS_PARCEL_COLOR;
      }

      return null;
    };

    this.layers.parcels.setStyle(feature =>
      DefaultStyles.getParcelStyle(feature, hoveredId, selectedId, getColor(feature)));
    this.layers.labels.setStyle((feature, r) => DefaultStyles.getLabelStyle(feature, r, getColor(feature)));
  }
}
