import React, { FC } from 'react';

import CloseIcon from '@mui/icons-material/Clear';
import { Dialog, Grid, IconButton, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { Geometries as BioMonitoringGeometriesType, MapImage } from '../../containers/BioMonitoring';
import BioMonitoringLegend from '../BioMonitoring/BioMonitoringLegend';
import BioMonitoringZonesLegend from '../BioMonitoring/BioMonitoringZonesLegend';
import ManagementZonesLegend from '../ManagementZones/ManagementZonesLegend';
import SoilSamplesZonesLegend from '../SoilSamples/SoilSamplesZonesLegend';

import PrecisionMap from './PrecisionMap';

import { Zone as ManagementZonesZoneType } from '../../../../shared/api/sentinel/management/management.types';
import { Sample, Zone as SoilSamplesZoneType } from '../../../../shared/api/sentinel/soilSamples/soilSamples.types';

const useStyles = makeStyles((theme: Theme) => ({
  itemContainer: {
    width: '1100px',
    padding: '15px 5px 5px 5px',
  },
  [theme.breakpoints.down('lg')]: {
    itemContainer: {
      width: '800px',
    },
  },
  [theme.breakpoints.down('md')]: {
    itemContainer: {
      width: '500px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    itemContainer: {
      width: '300px',
    },
  },
  dateHeading: {
    paddingLeft: 10,
    fontSize: 13,
    fontWeight: 600,
  },
  titelHeading: {
    paddingLeft: 10,
    fontSize: 18,
    fontWeight: 600,
  },
  closeIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  legendWrapper: {
    marginTop: 5,
    marginBottom: 10,
    paddingLeft: 10,
    fontSize: 13,
  },
}));

interface PrecisionMapModalProps {
  date?: {
    dateFrom: string,
    dateTo: string
  }
  geometries: BioMonitoringGeometriesType[] | SoilSamplesZoneType[] | ManagementZonesZoneType[] | null;
  index?: string,
  isBioMonitoring?: boolean,
  isManagementZones?: boolean,
  isSoilSamples?: boolean,
  mapImage?: MapImage | null;
  onClose: () => void;
  parcelId: string,
  points?: Sample[] | null;
  showModal: boolean
}

const PrecisionMapModal: FC<PrecisionMapModalProps> = ({
  date,
  geometries = null,
  index = '',
  isBioMonitoring = false,
  isManagementZones = false,
  isSoilSamples = false,
  mapImage = null,
  onClose,
  parcelId,
  points,
  showModal,
}) => {
  const classes = useStyles();

  const showManagementZonesLegend = !!geometries && isManagementZones;
  const showBioMonitoringLegend = !!mapImage && isBioMonitoring;
  const showBioMonitoringZoneLegend = !!geometries && isBioMonitoring;
  const showSoilSamplesZoneLegend = !!geometries && isSoilSamples;

  const isPHElement = (geometries?.[0] as SoilSamplesZoneType)?.sample === 'PH';
  const isKMGElement = (geometries?.[0] as SoilSamplesZoneType)?.sample === 'KMG';
  const isPercentageContentElement = ['COx', 'HUMUS'].includes((geometries?.[0] as SoilSamplesZoneType)?.sample);

  const lessValueLevels = ['FE', 'B', 'ZN'].includes((geometries?.[0] as SoilSamplesZoneType)?.sample);

  return (
    <Dialog
      maxWidth={false}
      onClose={onClose}
      open={showModal}
    >
      <Grid className={classes.itemContainer}>
        {isManagementZones && <Grid className={classes.titelHeading}>
          <FormattedMessage id="ManagementZones.GeneralizedZones.heading" />
        </Grid>}
        {date && <Grid className={classes.dateHeading}>
          <FormattedDate day="numeric" month="numeric" value={date.dateFrom} />
          {' \u2013 '}
          <FormattedDate day="numeric" month="numeric" value={date.dateTo} />
        </Grid>}
        {isSoilSamples && <Grid className={classes.titelHeading}>
          <FormattedMessage id={`SoilSamples.content.${(geometries?.[0] as SoilSamplesZoneType).sample}`} />
        </Grid>}
        <Grid className={classes.legendWrapper}>
          {showManagementZonesLegend && <ManagementZonesLegend zones={geometries as ManagementZonesZoneType[]} />}
          {showBioMonitoringLegend && <BioMonitoringLegend index={index} /> }
          {showBioMonitoringZoneLegend && <BioMonitoringZonesLegend index={index} />}
          {showSoilSamplesZoneLegend &&
            <SoilSamplesZonesLegend
              isKMGElement={isKMGElement}
              isPercentageContentElement={isPercentageContentElement}
              isPHElement={isPHElement}
              lessValueLevels={lessValueLevels}
            />
          }
        </Grid>
        <Grid item>
          <IconButton
            aria-label="close"
            className={classes.closeIcon}
            onClick={onClose}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid
          alignItems="baseline"
          container
          justifyContent="flex-start"
        >
          <PrecisionMap
            geometries={geometries}
            mapImage={mapImage}
            parcelId={parcelId}
            points={points}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default PrecisionMapModal;
