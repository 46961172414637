import React from 'react';

import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { Collapse, IconButton, TableCell } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import download from 'js-file-download';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getPrecisionAsAppliedOpenedDriverRows } from '../selectors/precisionAsApplied.selectors';

import { setOpenedPrecisionAsAppliedRows } from '../actions/precision.actions';

import { asAppliedDownloadFile } from '../../../shared/api/sentinel/asAppliedTaskdata/asAppliedTaskdata.api';
import CfTableCell from '../../../shared/components/tables/CfTableCell/CfTableCell';
import ExportIcon from '../../../shared/icons/ExportIcon';
import { getShortDateString } from '../../../shared/misc/timeHelpers';
import { getDuration } from '../../../telematics/helpers';
import { AsyncFn, Thunk } from '../../../types';

import AsAppliedTaskList from './AsAppliedTaskList';

import { PrecisionState } from '../../../reducers/precision.reducer.types';
import { TaskDataListTo } from '../../../shared/api/satellite/satellite.types';

const useStyles = makeStyles(() => ({
  firstColumn: {
    paddingLeft: 15,
    fontWeight: 500,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  pointer: {
    cursor: 'pointer',
  },
  columnRight: {
    textAlign: 'right',
  },
  columnLeft: {
    textAlign: 'left',
  },
  collapsedRow: {
    height: 0,
  },
  collapsedCell: {
    padding: 0,
    borderBottom: 'none',
  },
  collapseIcon: {
    marginLeft: 4,
  },
  exportIcon: {
    width: 20,
    height: 20,
  },
}));

type Props = {
    asAppliedDownloadFile: (id: string) => void;
    columnsLength: number;
    data: TaskDataListTo;
    farmId: string;
    openedRows: number[];
    rowId: number;
    setOpenedPrecisionAsAppliedRows: (payload: number[]) => void;
}

const AsAppliedRow: React.FC<Props> = ({
  asAppliedDownloadFile, columnsLength, data, farmId, openedRows, rowId, setOpenedPrecisionAsAppliedRows,
}) => {
  const classes = useStyles();

  const opened = openedRows?.includes(rowId) ?? false;
  const toggleOpened = () => {
    if (opened) {
      setOpenedPrecisionAsAppliedRows(openedRows.filter((id) => id !== rowId));
    } else {
      setOpenedPrecisionAsAppliedRows([...openedRows, rowId]);
    }
  };

  const handleDownloadClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    (asAppliedDownloadFile as AsyncFn<string>)(data.id.toString())
      .then(res => {
        if (res?.error) return;
        const { body, type } = res?.payload || {};
        body?.then((b: BodyInit | null | undefined) =>
          new Response(b).blob().then((blob) => {
            download(blob, data.filename, type);
          }),
        );
      });
  };

  return (
    <>
      <TableRow
        className={classes.pointer}
        hover
        key={data.id}
        onClick={toggleOpened}
      >
        <CfTableCell name="created">
          <span className={classes.firstColumn}>
            {data.created ? getShortDateString(data.created, 'D. M. YYYY') : '-'}
          </span>
        </CfTableCell>
        <CfTableCell name="filename">
          <span>{data.filename}</span>
        </CfTableCell>
        <CfTableCell classes={{ root: classes.columnLeft }} name="tasks.length">
          <span>{data.tasks.length}</span>
        </CfTableCell>
        <CfTableCell classes={{ root: classes.columnLeft }} name="dateFrom">
          <span>{data.dateFrom ? getShortDateString(data.dateFrom, 'D. M. YYYY') : '-'}</span>
        </CfTableCell>
        <CfTableCell classes={{ root: classes.columnLeft }} name="dateTo">
          <span>{data.dateTo ? getShortDateString(data.dateTo, 'D. M. YYYY') : '-'}</span>
        </CfTableCell>
        <CfTableCell classes={{ root: classes.columnRight }} name="duration">
          <span>{data.duration ? `${getDuration(data.duration)}:00` : '-'}</span>
        </CfTableCell>
        <CfTableCell classes={{ root: classes.actions }} name="actions">
          <IconButton
            aria-label="Download"
            onClick={handleDownloadClick}
            size="large"
            >
            <ExportIcon className={classes.exportIcon} />
          </IconButton>
          <IconButton
            aria-label="Toggle view"
            className={classes.collapseIcon}
            size="large">
            {opened ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </CfTableCell>
      </TableRow>
      <TableRow className={classes.collapsedRow}>
        <TableCell className={classes.collapsedCell} colSpan={columnsLength}>
          <Collapse in={opened}>
            <>
              {data.tasks &&
              <AsAppliedTaskList
                data={data.tasks}
                farmId={farmId}
                opened={opened}
                taskDataId={data.id}
             />}
            </>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const mapStateToProps = (state: PrecisionState) => ({
  openedRows: getPrecisionAsAppliedOpenedDriverRows(state),
});

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) => bindActionCreators({
  setOpenedPrecisionAsAppliedRows,
  asAppliedDownloadFile,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AsAppliedRow);
