import React, { Fragment, ReactNode, useState } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box, Collapse, Stack, TableBody, TableRow, Theme, Typography, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import CfTableCell from '../../../../shared/components/tables/CfTableCell/CfTableCell';
import CfTableWrapper from '../../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableHead from '../../../../shared/containers/CfTableHead/CfTableHead';

import { PorUseReportMissingDataBody } from '../../../../shared/api/agroevidence/agroevidence.types';

const useStyles = makeStyles((theme: Theme) => (
  {
    expandIcon: {
      color: theme.palette.grey[400],
    },
    sectionRow: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }
));

interface Props {
    errorDetailedMessage: PorUseReportMissingDataBody,
    index: number,
    section: keyof PorUseReportMissingDataBody
}

const getColDesc = (align: string, sortable: boolean, label: ReactNode) => ({ align, sortable, label });

const columns = {
  date: getColDesc('inherit', false, <FormattedMessage id={'Reports.common.date.label'} />),
  actionType: getColDesc('inherit', false, <FormattedMessage id={'Reports.common.actionType'} />),
  targetCrop: getColDesc('inherit', false, <FormattedMessage id={'Reports.common.targetCrop'} />),
  material: getColDesc('inherit', false, <FormattedMessage id={'Reports.common.material'} />),
  totalDose: getColDesc('inherit', false, <FormattedMessage id={'Reports.common.totalDose'} />),
  haDose: getColDesc('inherit', false, <FormattedMessage id={'Reports.common.haDose'} />),
};

export const PorUsageErrorSection = ({ errorDetailedMessage, index, section }: Props) => {
  const classes = useStyles();
  const { farmId } = useParams<{farmId: string}>();

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Box>
      <Typography onClick={() => setIsExpanded(!isExpanded)} variant="subtitle2">
        <Stack className={classes.sectionRow} direction={'row'} spacing={2}>
          <FormattedMessage id={`Reports.porUsageReport.errorDialog.section.title.${section}`} values={{ index }} />
          {isExpanded ? <ExpandLess className={classes.expandIcon} /> : <ExpandMore className={classes.expandIcon} />}
        </Stack>
      </Typography>
      <Collapse in={isExpanded}>

        <CfTableWrapper>
          <CfTableHead
            columns={columns}
          />
          {errorDetailedMessage[section]?.length > 0 && (
            <TableBody>
                {errorDetailedMessage[section].map((row, i) => (
                  <TableRow key={i}>
                    <CfTableCell name={'date'}>{<FormattedDate day={'2-digit'} month={'short'} value={row.date} year="numeric" />}</CfTableCell>

                    <CfTableCell name={'actionType'}>
                      <Link href={`/farm/${farmId}/actions/${row.actionId}`}>
                        <Typography className={classes.sectionRow} variant="subtitle2">
                          {row.actionType.name}
                        </Typography>
                      </Link>

                    </CfTableCell>

                    <CfTableCell name={'targetCrop'}>{row.targetCrop?.name ?? '-'}</CfTableCell>
                    {row.materials && row.materials.length > 0 &&
                      <Fragment>
                        <CfTableCell name={'material'}>{row?.materials.map((material) => (
                          <div key={material.name}>{material.name}</div>
                        ))}
                        </CfTableCell>
                        <CfTableCell name={'totalDose'}>{row?.materials.map((material) => (
                          <div key={material.totalDose}>{`${material.totalDose} ${material.unit}`}</div>
                        ))}
                        </CfTableCell>
                        <CfTableCell name={'haDose'}>{row?.materials.map((material) => (
                          <div key={material.dosePerHa}>{`${material.dosePerHa} ${material.unit}`}</div>
                        ))}
                        </CfTableCell>
                      </Fragment>
                    }
                  </TableRow>
                ))}
            </TableBody>
          )}
        </CfTableWrapper>
      </Collapse>
    </Box>
  );
};
