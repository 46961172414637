import React, { FC, useMemo } from 'react';

import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';

import { Buttons, DriveKey } from './formComponents';
import WinfasClient from './formComponents/WinfasClient';
import { validateKeysForm } from './validators';

import { WinfasUpdateTo, DriveKeyTo, KeyType, WinfasTo, WinfasClientTo, DriveValidationType } from '../../../shared/api/telematics/telematics.types';

interface Props {
    approvalValidationErrors: Record<string, DriveValidationType | undefined>;
    date: string;
    driveKeys: WinfasTo | null;
    handleReset: () => void;
    handleSave: (values: WinfasUpdateTo) => void;
    isEditing: boolean;
}

export interface DriveKeysFormValues {
    client?: WinfasClientTo;
    customerKey?: DriveKeyTo;
    equipmentKey?: DriveKeyTo;
    supplierKey?: DriveKeyTo;
}

const DriveKeysForm: FC<Props> = ({
  approvalValidationErrors,
  date,
  driveKeys,
  handleReset,
  handleSave,
  isEditing,
}) => {
  const initialValues = useMemo(() => ({
    supplierKey: driveKeys?.keys?.supplierKey,
    customerKey: driveKeys?.keys?.customerKey,
    equipmentKey: driveKeys?.keys?.equipmentKey,
    client: driveKeys?.client,
  }), [driveKeys]);

  const handleSubmit = (values: DriveKeysFormValues) => {
    const data = {
      keys: {
        supplierKeyId: values.supplierKey?.id,
        customerKeyId: values.customerKey?.id,
        equipmentKeyId: values.equipmentKey?.id,
      },
      client: values.client ?? undefined,
    };
    handleSave(data);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onReset={handleReset}
      onSubmit={handleSubmit}
      validate={validateKeysForm}
      validateOnBlur
      validateOnChange
      >
      {({ errors }) => (
        <Form>
          <Grid container justifyContent="space-between">
            <Grid item xs={12}>
              <DriveKey
                disabled
                fieldName="supplierKey"
                helperText={!!errors.supplierKey && <FormattedMessage id={errors.supplierKey} />}
                keyType={KeyType.SUPPLIER}
                showApprovalWarning={!!approvalValidationErrors.supplierKey}
                tooltipCode={approvalValidationErrors.supplierKey}
                variant="disabled"
            />
            </Grid>
            <Grid item xs={12}>
              <DriveKey
                disabled={!isEditing}
                fieldName="customerKey"
                helperText={!!errors.customerKey && <FormattedMessage id={errors.customerKey} />}
                keyType={KeyType.CUSTOMER}
                showApprovalWarning={!!approvalValidationErrors.customerKey}
                tooltipCode={approvalValidationErrors.customerKey}
            />
            </Grid>
            <Grid item xs={12}>
              <DriveKey
                disabled
                fieldName="equipmentKey"
                helperText={!!errors.equipmentKey && <FormattedMessage id={errors.equipmentKey} />}
                keyType={KeyType.EQUIPMENT}
                showApprovalWarning={false}
                variant="disabled"
            />
            </Grid>
            <Grid item xs={12}>
              <WinfasClient
                date={date}
                disabled={!isEditing}
                fieldName="client"
                helperText={!!errors.client && <FormattedMessage id={errors.client} />}
                showApprovalWarning={!!approvalValidationErrors.client}
                tooltipCode={approvalValidationErrors.client}
            />
            </Grid>
          </Grid>
          {isEditing && <Buttons />}
        </Form>
      )}
    </Formik>
  );
};

export default DriveKeysForm;
