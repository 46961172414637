import React, { Component } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CfErrorPage from '../../../../shared/components/common/CfErrorPage/CfErrorPage';
import EntryContainer from '../../../../shared/containers/EntryContainer/EntryContainer';
import PasswordField from '../../../signup/components/PasswordField/PasswordField';
import LoginLinks from '../../components/LoginLinks/LoginLinks';

const style = {
  paper: {
    textAlign: 'center',
    backgroundColor: 'rgba(255,255,255,0.8)',
    padding: '30px',
    position: 'relative',
  },
  textField: {
    textAlign: 'left',
    width: '100%',
    marginTop: 0,
  },
  errorText: {
    position: 'absolute',
    bottom: '-10px',
  },
  floatingLabelStyle: {
    fontWeight: 'normal',
    fontSize: '14px',
  },
  inputLabel: {
    fontWeight: 400,
    zIndex: 1,
    pointerEvents: 'none',
  },
  button: {
    width: '100%',
  },
  loginErrorHolder: {
    marginBottom: 10,
    position: 'relative',
  },
  loginError: {
    position: 'absolute',
    top: 0,
    left: 0,
    fontWeight: 'normal',
    fontSize: '14px',
    textAlign: 'center',
    width: '100%',
    color: '#DB4437',
  },
  loginContainer: {
    width: '100%',
    margin: '10px 0px',
  },
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      usernameErrorText: '',
      password: '',
      passwordErrorText: '',
      loginError: '',
      isFetching: false,
      error: {
        isError: this.props.isApiError,
        code: this.props.isApiError ? 500 : null,
      },
    };

    this.usernameRequiredError = false;
    this.passwordRequiredError = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.isApiError !== prevProps.isApiError) {
      this.setState({
        isError: this.props.isApiError,
        code: this.props.isApiError ? 500 : null,
      });
    }
  }

  onInputChange = e => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };

  onInputFocus = e => {
    const name = `${e.target.name}ErrorText`;
    this.setState({
      [name]: '',
    });
  };

  onValidateInput = (val, name) => {
    const errText = `${name}ErrorText`;
    this.setState({
      [errText]: !val ? <FormattedMessage id="Login.field-is-required" /> : '',
    });

    this[`${name}RequiredError`] = !val;
  };

  onLogin = e => {
    e.preventDefault();
    this.setState({
      loginError: '',
    });

    this.onValidateInput(this.state.username, 'username');
    this.onValidateInput(this.state.password, 'password');

    if (this.usernameRequiredError || this.passwordRequiredError) {
      return;
    }

    this.setState({
      isFetching: true,
    });
    this.props.ngLogin({ username: this.state.username, password: this.state.password }).catch(err => {
      const status = err && err.status ? err.status : null;
      switch (status) {
        case 400:
        case 401:
          this.setState({
            loginError: <FormattedMessage id="Login.incorrect-login" />,
            isFetching: false,
          });
          break;
        case 500:
        case 503:
          this.setState({
            error: {
              isError: true,
              code: err.status,
              isFetching: false,
            },
          });
          break;
        default:
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { error, isFetching } = this.state;
    return (
      <CfErrorPage error={error}>
        <EntryContainer isFetching={isFetching} onLangChange={this.props.ngChangeLanguage}>
          <form
            autoComplete="off"
            noValidate
            onSubmit={e => {
              this.onLogin(e);
            }}
          >
            <Grid className={classes.loginContainer} container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoFocus={true}
                  className={classes.textField}
                  error={this.state.usernameErrorText !== ''}
                  helperText={this.state.usernameErrorText !== '' ? this.state.usernameErrorText : ' '}
                  id="username"
                  label={<FormattedMessage id="Login.username" />}
                  margin="dense"
                  name="username"
                  onChange={this.onInputChange}
                  onFocus={this.onInputFocus}
                  type="text"
                  value={this.state.username}
                  InputLabelProps={{
                    style: style.inputLabel,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordField
                  errorText={this.state.passwordErrorText}
                  fullWidth={true}
                  id="key"
                  label={<FormattedMessage id="Login.password" />}
                  onChange={this.onInputChange}
                  onFocus={this.onInputFocus}
                  value={this.state.password}
                />
              </Grid>
              <Grid item xs={12}>
                <Button className={classes.button} color="primary" id="login" type="submit" variant="contained">
                  <FormattedMessage id="Login.log-in" />
                </Button>
              </Grid>
              <Grid className={classes.loginErrorHolder} item xs={12}>
                <div className={classes.loginError} id="incorrect-login-alert">
                  <p>{this.state.loginError}</p>
                </div>
              </Grid>
              <LoginLinks resetRedirect={this.props.ngRedirectToReset} signupRedirect={this.props.ngRedirectToSignup} />
            </Grid>
          </form>
        </EntryContainer>
      </CfErrorPage>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object,
  ngLogin: PropTypes.func,
  ngRedirectToSignup: PropTypes.func.isRequired,
  ngRedirectToReset: PropTypes.func.isRequired,
  ngChangeLanguage: PropTypes.func.isRequired,
  isApiError: PropTypes.bool.isRequired,
};

Login.defaultProps = {
  classes: {},
  ngLogin: PropTypes.func,
};

export default withStyles(style)(Login);
