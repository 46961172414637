import React, { FC, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getPrecisionParcelListTextFilter } from '../selectors/precisionParcelList.selectors';

import { fetchPrecisionAsApplied } from '../actions/precision.actions';

import { NAMESPACE as namespace } from '../reducer/precisionParcelList.reducer';

import CfErrorPage from '../../../shared/components/common/CfErrorPage/CfErrorPage';
import CfTextFilter from '../../../shared/containers/CfTextFilter/CfTextFilter';
import { RsaaApiError, Thunk } from '../../../types';

import PrecisionAsAppliedTable from './PrecisionAsAppliedTable';
import { usePrecisionListStyles } from './styles';

import { PrecisionState } from '../../../reducers/precision.reducer.types';

export interface PrecisionAsAppliedProps {
  error?: RsaaApiError;
  farmId: string;
  fetchPrecisionAsApplied: () => void;
  langId: string;
  textFilter: string;
}

export const PrecisionAsApplied: FC<PrecisionAsAppliedProps> = ({
  error,
  farmId,
  fetchPrecisionAsApplied,
  langId,
  textFilter,
}) => {
  const classes = usePrecisionListStyles();

  useEffect(() => {
    fetchPrecisionAsApplied();
  }, [fetchPrecisionAsApplied]);

  return (
    <CfErrorPage error={error}>
      <div className={classes.wrapper}>
        <div>
          <div className={classes.textFilter}>
            <CfTextFilter
              initialValue={textFilter}
              name="precision-list-text-filter"
              namespace={namespace}
              translId="PrecisionFarming.search.filter"
                  />
          </div>
          <PrecisionAsAppliedTable farmId={farmId} langId={langId} />
        </div>
      </div>
    </CfErrorPage>
  );
};

const mapStateToProps = (state: PrecisionState) => ({
  textFilter: getPrecisionParcelListTextFilter(state),
});

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) =>
  bindActionCreators(
    {
      fetchPrecisionAsApplied,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PrecisionAsApplied);
