import React, { FC, useEffect, useMemo } from 'react';

import { makeStyles } from '@mui/styles';
import { groupBy } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getMainMapTelematicsMachinesFiltered, getMainMapTelematicsMachinesGroups }
  from '../../../../shared/api/telematics/mainMapTelematics/mainMapTelematics.selectors';

import { fetchMachines, fetchMachinesGroups, setMachineSelectorFilter } from '../../actions/mainMapTelematics/mainMapTelematics.actions';

import { resetMachines } from '../../../../shared/api/telematics/mainMapTelematics/mainMapTelematics.api';
import CfTextFilter from '../../../../shared/containers/CfTextFilter/CfTextFilter';
import { Thunk } from '../../../../types';

import MainMapTelematicsMachineSelectorGroup from './MainMapTelematicsMachineSelectorGroup';

import { MainMapState } from '../../../../reducers/map.reducer.types';
import { MachineGroupTo, MachineTo } from '../../../../shared/api/telematics/telematics.types';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 400,
  },
}));

interface MainMapTelematicsMachineSelectorProps {
  fetchMachines(): void;
  fetchMachinesGroups(lang: string): void;
  langId: string;
  machines: MachineTo[];
  machinesGroups: MachineGroupTo[];
  onChangeMachineFilter(items: MachineTo[], checked: boolean): void;
  resetMachines(): void;
  selectedValues: MachineTo[];
  setMachineSelectorFilter(machineSelectorFilter: string): void
}

const MainMapTelematicsMachineSelector: FC<MainMapTelematicsMachineSelectorProps> = (
  { fetchMachines,
    fetchMachinesGroups,
    langId,
    machines,
    machinesGroups,
    onChangeMachineFilter,
    resetMachines,
    selectedValues,
    setMachineSelectorFilter,
  }) => {
  const classes = useStyles();

  useEffect(() => {
    fetchMachines();
    setMachineSelectorFilter('');
    return () => {
      resetMachines();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchMachinesGroups(langId);
  }, [fetchMachinesGroups, langId]);

  const onFilterChange = (machineSelectorFilter: string) => {
    setMachineSelectorFilter(machineSelectorFilter);
  };

  const onFilterReset = () => {
    setMachineSelectorFilter('');
  };

  const sortedMachinesByGroup = useMemo(() => {
    const groupedMachines = groupBy(machines, (machine: MachineTo) => machine.group);
    Object.keys(groupedMachines).forEach((key: string) => {
      groupedMachines[key] = groupedMachines[key].sort(({ name: a = '' }, { name: b = '' }) => a.localeCompare(b));
    });
    return groupedMachines;
  }, [machines]);

  const sortedAvailableMachinesGroups = useMemo(() => {
    const avaliableMachinesGroups = machinesGroups.filter(({ code }: MachineGroupTo) => sortedMachinesByGroup[code]);
    return avaliableMachinesGroups.sort((a, b) => a.value.localeCompare(b.value));
  }, [sortedMachinesByGroup, machinesGroups]);

  return (
    <div className={classes.container}>
      <CfTextFilter
        disableUnderline
        name="main-map-machine-text-filter"
        onFilterChange={onFilterChange}
        onFilterReset={onFilterReset}
        translId="MainMapTelematics.filter.machine"
        customStyles={{
          paddingLeft: 20,
          alignItems: 'center',
          width: '100%',
          height: 42,
        }}
        />
      <MainMapTelematicsMachineSelectorGroup
        machinesByGroup={sortedMachinesByGroup}
        machinesGroups={sortedAvailableMachinesGroups}
        onChangeMachineFilter={onChangeMachineFilter}
        selectedValues={selectedValues}
      />
    </div>
  );
};

const mapStateToProps = (state: MainMapState) => ({
  machines: getMainMapTelematicsMachinesFiltered(state),
  machinesGroups: getMainMapTelematicsMachinesGroups(state),
});

const mapDispatchToProps = (dispatch: Thunk<MainMapState>) =>
  bindActionCreators(
    {
      fetchMachines,
      fetchMachinesGroups,
      setMachineSelectorFilter,
      resetMachines,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MainMapTelematicsMachineSelector);
