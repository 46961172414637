import React, { useEffect, ReactNode, CSSProperties, FC } from 'react';

import { TableBody } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getAccountFarms, getAccountFarmsTotalCount, isFetchingAccountFarms } from '../../../../shared/api/agroevidence/farms/admin/farmsAdmin.selectors';

import { fetchAccountFarms } from '../actions/farms.actions';

import { NAMESPACE } from '../reducer/farms.reducer';

import CfTableBodyEmpty from '../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty';
import CfTableBodyLoader from '../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader';
import CfTableWrapper from '../../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableFooter from '../../../../shared/containers/CfTableFooter/CfTableFooter';
import CfTableHead from '../../../../shared/containers/CfTableHead/CfTableHead';
import DoubleLinedHeader from '../../../../telematics/components/DoubleLinedHeader/DoubleLinedHeader';
import { Thunk } from '../../../../types';
import { getFarmsAdminOrder, getFarmsAdminOrderBy, getFarmsAdminPage,
  getFarmsAdminRowsPerPage, getFarmsAdminTextFilter } from '../selectors/farms.selector';

import Row from './Row';

import { AdminState } from '../../../../reducers/admin.reducer.types';
import { AdminFarmTo } from '../../../../shared/api/agroevidence/agroevidence.types';

const getColDesc = (sortable: boolean, label: ReactNode, style?: CSSProperties) => ({
  align: 'inherit',
  sortable,
  label,
  style,
});

const COLUMNS = {
  name: getColDesc(true,
    <DoubleLinedHeader
      leftOffset
      primaryId="TelematicsAdmin.columns.farm"
      secondaryId="TelematicsAdmin.columns.id"
  />, { width: '300px' },
  ),
  countryCode: getColDesc(true, <FormattedMessage id="TelematicsAdmin.columns.country" />),
};

interface Props {
  accountFarms: AdminFarmTo[];
  fetchAccountFarms: () => void;
  isFetchingFarms: boolean,
  order: string;
  orderBy: string;
  page: number;
  rowsPerPage: number;
  textFilter: string;
  totalCount: number;
}

const FarmsAdminTable: FC<Props> = ({
  accountFarms,
  fetchAccountFarms,
  isFetchingFarms,
  order,
  orderBy,
  page,
  rowsPerPage,
  textFilter,
  totalCount,
}) => {
  useEffect(() => {
    fetchAccountFarms();
  }, [order, orderBy, page, rowsPerPage, textFilter, fetchAccountFarms]);

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={COLUMNS}
        items={accountFarms}
        namespace={NAMESPACE}
        order={order}
        orderBy={orderBy}
      />
      {isFetchingFarms && <CfTableBodyLoader columns={COLUMNS} />}

      {!isFetchingFarms && accountFarms.length ? (
        <TableBody>
          {accountFarms.map(item => <Row item={item} key={item.id} />)}
        </TableBody>
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(COLUMNS).length + 1} />
      )}

      <CfTableFooter
        count={totalCount}
        namespace={NAMESPACE}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const mapStateToProps = (state: AdminState) => ({
  accountFarms: getAccountFarms(state),
  isFetchingFarms: isFetchingAccountFarms(state),
  order: getFarmsAdminOrder(state),
  orderBy: getFarmsAdminOrderBy(state),
  page: getFarmsAdminPage(state),
  rowsPerPage: getFarmsAdminRowsPerPage(state),
  totalCount: getAccountFarmsTotalCount(state),
  textFilter: getFarmsAdminTextFilter(state),
});

const mapDispatchToProps = (dispatch: Thunk<AdminState>) => bindActionCreators({
  fetchAccountFarms,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FarmsAdminTable);
