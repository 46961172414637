import React, { FC, Fragment, useEffect, useState } from 'react';

import { Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getSoilSamplesIsFetching, getSoilSamplesError } from '../../../shared/api/sentinel/soilSamples/soilSamples.selectors';
import { getCombinedSoilSamplesData, getValuesSoilSamplesData } from '../selectors/soilSamples.selectors';

import { getSoilSamplesDateApi, resetSoilSamplesDate } from '../../../shared/api/sentinel/soilSamples/soilSamples.api';
import CfButtonPanel from '../../../shared/components/common/CfButtonPanel/CfButtonPanel';
import CfErrorPage from '../../../shared/components/common/CfErrorPage/CfErrorPage';
import CfLoader from '../../../shared/components/common/CfLoader/CfLoader';
import CfSwitcher from '../../../shared/components/common/CfSwitcher/CfSwitcher';
import { CfRadioGroup } from '../../../shared/components/form/CfReduxFormRadioGroup/CfReduxFormRadioGroup';
import useWidth from '../../../shared/hooks/useWidth';
import { getYearsObjectArray } from '../../../shared/misc/helpers';
import { RsaaApiError, Thunk } from '../../../types';
import PrecisionMapModal from '../components/PrecisionMapModal/PrecisionMapModal';
import SectionHeader from '../components/SectionHeader';
import SoilSamplesValuesTable from '../components/SoilSamples/SoilSamplesValuesTable';
import SoilSamplesZonesMap from '../components/SoilSamples/SoilSamplesZonesMap';
import ValuesInfoDialog from '../components/SoilSamples/ValuesInfoDialog/ValuesInfoDialog';

import { PrecisionState } from '../../../reducers/precision.reducer.types';
import { combinedSoilSamplesData, Sample, Zone } from '../../../shared/api/sentinel/soilSamples/soilSamples.types';

const useStyles = makeStyles(() => ({
  paper: {
    margin: '10px 5px 5px 5px',
  },
  headerBar: {
    marginBottom: 5,
  },
  cfSwitcherWrapper: {
    justifyContent: 'flex-start',
  },
  indicesPanel: {
    padding: '0px 2px',
  },
  indexRadioLabel: {
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 10,
  },
  indexRadioLabelText: {
    fontSize: '14px',
    fontWeight: 500,
  },
  indexRadio: {
    padding: 4,
  },
}));

enum SECTIONS {
    MAPS = 'MAPS',
    VALUES= 'VALUES',
}
const RADIO_BUTTONS_LIST = [SECTIONS.MAPS, SECTIONS.VALUES];

const START_YEAR = 2022;
const CURRENT_YEAR = new Date().getFullYear();
const YEARS_ARRAY = getYearsObjectArray(START_YEAR, CURRENT_YEAR);

export interface SoilSamplesProps {
    combinedSoilSamplesData: combinedSoilSamplesData[];
    error?: RsaaApiError;
    getSoilSamplesDateApi: (parcelId: string, year: string) => void;
    isFetching: boolean;
    parcelId: string;
    resetSoilSamplesDate: () => void;
    valuesSoilSamplesData: Sample[][];
}

const SoilSamples: FC<SoilSamplesProps> = ({
  combinedSoilSamplesData,
  error,
  getSoilSamplesDateApi,
  isFetching,
  parcelId,
  resetSoilSamplesDate,
  valuesSoilSamplesData,
}) => {
  const classes = useStyles();
  const width = useWidth();

  const [activeRadioButton, setActiveRadioButton] = useState(SECTIONS.MAPS);
  const [selectedYear, setSelectedYear] = useState<{id: string, year: string}>(YEARS_ARRAY[YEARS_ARRAY.length - 1]);

  useEffect(() =>
    () => {
      resetSoilSamplesDate();
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  const [geometries, setGeometries] = useState<Zone[] | null>(null);
  const [points, setPoints] = useState<Sample[] | null>(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getSoilSamplesDateApi(parcelId, selectedYear.year);
  }, [getSoilSamplesDateApi, parcelId, selectedYear]);

  const setMapForModal = (geometries: Zone[], points: Sample[]) => {
    setGeometries(geometries);
    setPoints(points);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <CfErrorPage error={error}>
      <Fragment>
        <Grid container spacing={1}>
          <Grid className={classes.headerBar} item xs={12}>
            <Grid alignItems="center" container justifyContent={width !== 'xs' ? 'flex-start' : 'center'}>
              <Grid data-test="soil-samples-switchers" item sm={'auto'} xs={12}>
                <Grid alignItems="center" container justifyContent={'center'} spacing={1}>
                  <Grid item sm={'auto'} xs={12}>
                    <CfSwitcher
                      getItemId={item => item.id}
                      getItemValue={item => item.year}
                      items={YEARS_ARRAY}
                      onMenuItemClick={setSelectedYear}
                      selectedItemId={selectedYear.id}
                      classes={{
                        wrapper: classes.cfSwitcherWrapper,
                      }}
                    />
                  </Grid>
                  <Grid item sm={'auto'} xs={12}>
                    <CfButtonPanel classes={{ root: classes.indicesPanel }} data-test="index-radio-switcher" uppercase={false}>
                      <CfRadioGroup
                        labelPropertyFactory={option => <FormattedMessage id={`SoilSamples.section.${option.value}`} />}
                        onChange={evt => setActiveRadioButton(evt.target.value)}
                        value={activeRadioButton}
                        classes={{
                          formControlLabel: classes.indexRadioLabel,
                          formControlLabelText: classes.indexRadioLabelText,
                        }}
                        options={RADIO_BUTTONS_LIST.map(item => ({
                          value: item,
                        }))}
                        RadioProps={{
                          classes: {
                            root: classes.indexRadio,
                          },
                        }}
                      />
                    </CfButtonPanel>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isFetching ? (
          <CfLoader />
        ) : (
          <Grid container spacing={1}>
            {activeRadioButton === SECTIONS.MAPS &&
              <Grid data-test="soil-samples-maps" item xs={12}>
                <Grid container justifyContent="flex-start" spacing={0}>
                  {combinedSoilSamplesData?.map((item, i) => (
                    <SoilSamplesZonesMap
                      item={item}
                      key={i}
                      onMapClick={setMapForModal}
                      order={i}
                      width={width}
                    />
                  ))}
                </Grid>
              </Grid>
            }
            {activeRadioButton === SECTIONS.VALUES &&
              <Grid data-test="soil-samples-values" item xs={12}>
                <Paper className={classes.paper}>
                  <SectionHeader
                    dialogHeadingTranslationId="SoilSamples.valuesInfoDialog.title"
                    headingTranslationId={`SoilSamples.section.${SECTIONS.VALUES}`}
                    infoDialogContent={<ValuesInfoDialog />}
                    maxDialogWidth={'md'}
                  />
                  <SoilSamplesValuesTable values={valuesSoilSamplesData} />
                </Paper>
              </Grid>
            }
          </Grid>
        )}
      </Fragment>
      {showModal &&
        <PrecisionMapModal
          geometries={geometries}
          isSoilSamples
          onClose={handleCloseModal}
          parcelId={parcelId}
          points={points}
          showModal={showModal}
        />
      }
    </CfErrorPage>
  );
};

const mapStateToProps = (state: PrecisionState) => ({
  valuesSoilSamplesData: getValuesSoilSamplesData(state),
  combinedSoilSamplesData: getCombinedSoilSamplesData(state),
  isFetching: getSoilSamplesIsFetching(state),
  error: getSoilSamplesError(state),
});

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) =>
  bindActionCreators(
    {
      getSoilSamplesDateApi,
      resetSoilSamplesDate,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SoilSamples);
