import * as types from './sectionList.constants';

import { CATALOGUES_NAMESPACES } from '../../reducers/catalogues.reducer.types';

export const setSectionListDateFrom = (dateFrom: string, namespace: CATALOGUES_NAMESPACES) => ({
  type: types.SET_DATE_FROM,
  payload: dateFrom,
  namespace,
});

export const setSectionListDateTo = (dateTo: string, namespace: CATALOGUES_NAMESPACES) => ({
  type: types.SET_DATE_TO,
  payload: dateTo,
  namespace,
});
