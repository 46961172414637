import ErrorService from '../../../../services/Error.service';

import { ActionsState } from '../../../../../reducers/actions.reducer.types';
import { CataloguesState } from '../../../../../reducers/catalogues.reducer.types';
import { StoresState } from '../../../../../reducers/stores.reducer.types';

export const getFertilizers = (state: ActionsState | StoresState) => state.api.agroevidence.fertilizers.items.map(item => ({ materialTypeId: 'FR', ...item }));

export const getFertilizersV2 = (state: CataloguesState) => state.api.agroevidence.fertilizers.itemsV2;
export const getIsFetchingFertilizers = (state: CataloguesState) => state.api.agroevidence.fertilizers.isFetching;
export const getFertilizersCount = (state: CataloguesState) => state.api.agroevidence.fertilizers.totalCount;
export const getFertilizersError = (state: CataloguesState) =>
  ErrorService.getResErrorDto(state.api.agroevidence.fertilizers.error);

export const getFertilizer = (state: CataloguesState) => state.api.agroevidence.fertilizers.itemV2;

export const getFertilizerDose = (state: ActionsState) => state.api.agroevidence.fertilizers.dose;
export const getIsFetchingFertilizerDose = (state: ActionsState) => state.api.agroevidence.fertilizers.isFetchingDose;
