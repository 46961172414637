import React, { Fragment } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { isActionButtonEnabled, isSensorsTabActive, isActionButtonHidden } from '../../selectors/manager.selectors';

import { exportNodes as exportNodesApi } from '../../../sensors/actions/sensors.actions';
import { setDialogOpen } from '../../actions/manager.actions';

import ExportButton from '../../../../../shared/components/common/ExportButton/ExportButton';
import PageHeader from '../../../../../shared/components/common/PageHeader/PageHeader';
import PageHeading from '../../../../../shared/components/common/PageHeading/PageHeading';
import FarmsAdmin from '../../../farms/Farms';
import IrrigationAdmin from '../../../irrigation/containers/IrrigationAdmin/IrrigationAdmin';
import NotificationsAdmin from '../../../notifications/containers/NotificationsAdmin/NotificationsAdmin';
import SensorsAdmin from '../../../sensors/containers/SensorsAdmin/SensorsAdmin';
import SentinelAdmin from '../../../sentinel/containers/SentinelAdmin/SentinelAdmin';
import TelematicsAdmin from '../../../telematics/TelematicsAdmin';
import AdminFabButton from '../../components/AdminFabButton/AdminFabButton';
import AdminManagerTabs from '../../components/AdminManagerTabs/AdminManagerTabs';

const styles = theme => ({
  headerWithTabs: {
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  headerWrapper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  adminButtons: {
    float: 'right',
    display: 'flex',
    alignItems: 'center',
  },
  exportButton: {
    marginRight: theme.spacing(1),
    color: 'white',
  },
  contentWrapper: {
    padding: theme.spacing(2),
  },
});

export function AdminManager(props) {
  const {
    actionButtonEnabled,
    actionButtonHidden,
    classes,
    history,
    langId,
    location,
    match,
    sensorsTabActive,
  } = props;

  return (
    <div>
      <div className={classes.headerWithTabs}>
        <div className={classes.headerWrapper}>
          <PageHeader
            heading={<PageHeading dataTest="admin-heading" value="Admin" />}
            actionButtons={
              <Fragment>
                {sensorsTabActive && (
                  <div className={classes.exportButton}>
                    <ExportButton aria-label="export" handleClick={props.exportNodesApi} id="export-sensor" />
                  </div>
                )}
                {!actionButtonHidden && (
                  <AdminFabButton enabled={actionButtonEnabled} handleDialogOpen={props.setDialogOpen} />
                )}
              </Fragment>
            }
          />
        </div>
        <AdminManagerTabs history={history} location={location} match={match} />
      </div>
      <div className={classes.contentWrapper}>
        <Switch>
          <Route exact path="/farm/:farmId/admin/farms" render={() => <FarmsAdmin />} />
          <Route
            exact
            path="/farm/:farmId/admin/sensors"
            render={() => <SensorsAdmin langId={langId} ngImpersActivate={props.ngImpersActivate} />}
          />
          <Route exact path="/farm/:farmId/admin/notifications" render={() => <NotificationsAdmin langId={langId} />} />
          <Route exact path="/farm/:farmId/admin/sentinel" render={() => <SentinelAdmin langId={langId} />} />
          <Route exact path="/farm/:farmId/admin/irrigation" render={() => <IrrigationAdmin langId={langId} />} />
          <Route exact path="/farm/:farmId/admin/telematics" render={() => <TelematicsAdmin langId={langId} />} />
        </Switch>
      </div>
    </div>
  );
}

AdminManager.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  langId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
  actionButtonEnabled: PropTypes.bool.isRequired,
  actionButtonHidden: PropTypes.bool.isRequired,
  sensorsTabActive: PropTypes.bool.isRequired,
  exportNodesApi: PropTypes.func.isRequired,
  ngImpersActivate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  actionButtonEnabled: isActionButtonEnabled(state),
  actionButtonHidden: isActionButtonHidden(state),
  sensorsTabActive: isSensorsTabActive(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setDialogOpen,
      exportNodesApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminManager));
