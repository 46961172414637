import React, { FC, ReactNode, useMemo } from 'react';

import { useIntl } from 'react-intl';

import CfAutocomplete from '../../../../../shared/components/common/CfAutocomplete/CfAutocomplete';

import { isOrganicType } from '../../../../reducers/sectionList.types';

interface Props {
  defaultIsOrganic: isOrganicType,
  label: ReactNode,
  onChange: (items: isOrganicType) => void;
}

export const IS_ORGANIC = ['yes', 'no'];

const IsOrganicSelector: FC<Props> = ({
  defaultIsOrganic,
  label,
  onChange,
}) => {
  const intl = useIntl();

  const suggestions = useMemo(() => IS_ORGANIC.map(s => (
    {
      state: s === 'yes',
      label: intl.formatMessage({ id: `common.${s}` }),
    }
  )), [intl]);

  return (
    <CfAutocomplete
      defaultValues={defaultIsOrganic}
      id="is-organic-filter"
      label={label}
      onChange={onChange}
      suggestions={suggestions}
      testId="is-organic-filter"
    />
  );
};

export default IsOrganicSelector;
