import React, { useState, FC, useContext } from 'react';

import Grid from '@mui/material/Grid';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { RSAAResultAction } from 'redux-api-middleware';

import { TELEMATICS_URLS } from '../../telematics.constants';

import { createAggregatedDriveApi } from '../../../shared/api/telematics/aggregations/aggregations.api';
import CfBackButton from '../../../shared/components/common/CfBackButton/CfBackButton';
import PageHeader from '../../../shared/components/common/PageHeader/PageHeader';
import PageHeading from '../../../shared/components/common/PageHeading/PageHeading';
import ShowHideMap from '../../../shared/components/common/ShowHideMap/ShowHideMap';
import { SnackbarContext } from '../../../shared/containers/SnackbarProvider/SnackbarProvider';
import useWidth from '../../../shared/hooks/useWidth';
import { AsyncFn, Thunk } from '../../../types';
import DetailContentForm from '../../components/TelematicsAggregationDetailContent/DetailContentForm';
import EditBox from '../../components/TelematicsAggregationDetailContent/EditBox';
import TelematicsDetailMap from '../../components/TelematicsDetailMap/TelematicsDetailMap';
import { TelematicsContext } from '../Telematics/Telematics';

import { useTelematicsAggregationDetailStyles } from './styles';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { DriveCreateTo } from '../../../shared/api/telematics/telematics.types';

interface Props {
  createDrive: (data: DriveCreateTo) => void;
}

const TelematicsAggregationNew: FC<Props> = ({ createDrive }) => {
  const classes = useTelematicsAggregationDetailStyles();
  const width = useWidth();
  const history = useHistory();
  const { farmId } = useContext(TelematicsContext);
  const showSnackbar = useContext(SnackbarContext);

  const [displayMap, setDisplayMap] = useState(true);
  const [parcelId, setParcelId] = useState<string|undefined>();

  const isDesktop = width !== 'xs' && width !== 'sm';

  const handleToggleMap = () => setDisplayMap(prevState => !prevState);

  const handleResponse = (res: RSAAResultAction<{id: number}>) => {
    if (!res.error) {
      history.push(`/farm/${farmId}/${TELEMATICS_URLS.drivers}/${res.payload.id}`);
    } else {
      showSnackbar({
        message: <FormattedMessage id="TelematicsDetail.createRideError" />,
        isError: true,
      });
    }
  };

  const handleSave = (data: DriveCreateTo) => {
    (createDrive as AsyncFn<DriveCreateTo>)(data)
      .then((res: RSAAResultAction<{id: number}>) => handleResponse(res));
  };

  const handleGoBack = () => history.push(`/farm/${farmId}/${TELEMATICS_URLS.drivers}`);

  return (
    <Grid className={classes.wrapper} container>
      <Grid className={classes.bodyWrapper} item md={displayMap ? 6 : 12} xs={12}>
        <Scrollbars>
          <div className={classes.body}>
            <div className={classes.headerWithTabs}>
              <div className={classes.headerWrapper}>
                <PageHeader
                  backButton={
                    <CfBackButton onClick={handleGoBack} />
                  }
                  heading={
                    <PageHeading value={<FormattedMessage id="TelematicsAggregations.detail.new" />} />
                  }
                />
              </div>
            </div>
            <EditBox
              disabled={false}
              headingId="TelematicsAggregations.detail.section.operation"
              isApproved={false}
              isEditing={true}
              visible={true}
            >
              <DetailContentForm
                approvalValidationErrors={{}}
                handleReset={handleGoBack}
                handleSave={handleSave}
                isEditing={true}
                parcelId={parcelId}
                refreshKey={`${displayMap}`}
                setParcelId={setParcelId}
                />
            </EditBox>
          </div>
        </Scrollbars>
        <ShowHideMap handleClick={handleToggleMap} isMapHidden={!displayMap} />
      </Grid>
      <Grid className={classes.mapWrapper} item md={6} style={{ display: displayMap ? 'block' : 'none' }} xs={12}>
        {!isDesktop && <ShowHideMap handleClick={handleToggleMap} isMapHidden={false} />}
        <TelematicsDetailMap
          displayMap={false}
          parcelId={parcelId}
        />
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) => bindActionCreators({
  createDrive: createAggregatedDriveApi,
}, dispatch);

export default connect(null, mapDispatchToProps)(TelematicsAggregationNew);
