import React, { FC, useState, useEffect, useCallback } from 'react';

import { makeStyles } from '@mui/styles';
import OlFeature from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './TelematicsDetailMap.css';
import { getLayersConfig } from '../../../core/map/selectors/layers.selectors';
import { getParcel } from '../../../shared/api/agroevidence/parcels/parcels.selectors';

import { editorSetSelected, editorSetHoveredId } from '../../../core/map/actions/editor/editor.actions';
import { storeInitialLayers } from '../../../core/map/actions/layersUI/layersUI.actions';
import { storeServiceWrapper, setMapGrabEL, resetMap } from '../../../core/map/actions/map/map.actions';
import { refreshTelematicsStyles } from '../../../core/map/actions/style/style.actions';

import HomeControl from '../../../core/map/components/HomeControl/HomeControl';
import LayersCropsControl from '../../../core/map/components/LayersCropsControl/LayersCropsControl';
import EventListener from '../../../core/map/services/EventListener.service';
import Geometry from '../../../core/map/services/geometry/Geometry.service';
import Layers from '../../../core/map/services/Layers.service';
import MapService from '../../../core/map/services/Map.service';
import Style from '../../../core/map/services/Style.service';
import { getParcelApi, resetParcelApi } from '../../../shared/api/agroevidence/parcels/parcels.api';
import { getLpisLayerName } from '../../../shared/api/api.helpers';
import { fetchLayersConfig, resetLayers } from '../../../shared/api/other/layers/layers.api';
import SectionWrapper from '../../../shared/components/specific/SectionWrapper/SectionWrapper';
import withConfig from '../../../shared/hocs/context/withConfig';
import withFarm from '../../../shared/hocs/context/withFarm';
import { transformOptions, transformWithValidation } from '../../../shared/misc/map.helpers';
import { PRIVATE_LPIS_CONFIG, PUBLIC_LPIS_CONFIG } from '../../../shared/services/LayersConfig.service';
import { Thunk } from '../../../types';

import { FeatureNames, getBoundaryPoints, getStyledVectorLayer } from './helpers';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { DeprecatedFarmTo } from '../../../shared/api/agroevidence/agroevidence.types';
import { Geometry as GeometryType } from '../../../shared/api/core/geometry/geometry.types';
import { Layer } from '../../../shared/api/other/layers/layers.types';
import { TelematicsOperation } from '../../../shared/api/telematics/telematics.types';
import { GeoJSON } from '../../telematics.types';

const useStyles = makeStyles({
  map: {
    height: '100%',
    position: 'relative',
  },
});

type GeometryLayersState = {
  geometry?: VectorLayer<VectorSource>;
  geometryPerDay?: VectorLayer<VectorSource>;
}

interface TelematicsDetailMapProps {
  config: {
    api: {
      bing_key: string, geoserverUrl: string; geoserverWorkspaceCore: string; lpisLayerName: string;
    },
    environment: string;
  };
  displayMap: boolean;
  editorSetHoveredId(hoveredId?: string | null): void;
  editorSetSelected(selected: { id?: string }, rewrite?: boolean): void;
  farm: DeprecatedFarmTo;
  fetchLayersConfig(countryCode: string): void;
  geometry?: GeoJSON;
  geometryPerDay?: GeoJSON;
  getParcelApi(id: string): void;
  layersConfig: Layer[];
  operation: TelematicsOperation;
  parcelGeometry?: GeometryType;
  parcelId?: string;
  refreshTelematicsStyles(): void;
  resetLayers(): void;
  resetMap(): void;
  resetParcelApi(): void;
  setMapGrabEL(): void;
  startAzimuth?: number;
  storeInitialLayers(layers: Layer[]): void;
  storeServiceWrapper(key: string, value: unknown): void;
}

const TelematicsDetailMap: FC<TelematicsDetailMapProps> = ({
  config,
  displayMap,
  editorSetHoveredId,
  editorSetSelected,
  farm,
  fetchLayersConfig,
  geometry,
  geometryPerDay,
  getParcelApi,
  layersConfig,
  operation,
  parcelGeometry,
  parcelId,
  refreshTelematicsStyles,
  resetLayers,
  resetMap,
  resetParcelApi,
  setMapGrabEL,
  startAzimuth,
  storeInitialLayers,
  storeServiceWrapper,
}) => {
  const classes = useStyles();

  const [map, setMap] = useState<MapService | undefined>();
  const [layers, setLayers] = useState<Layers | undefined>();
  const [geometryLayers, setGeometryLayers] = useState<GeometryLayersState>({});

  const [initialized, setInitialized] = useState(false);

  const removeLayer = useCallback((selectedLayer) => {
    layers?.removeLayer(selectedLayer);
  }, [layers]);

  const removeGeometryLayer = useCallback((selectedLayer: keyof GeometryLayersState) => {
    const gl = { ...geometryLayers };
    delete gl[selectedLayer];
    setGeometryLayers({ ...gl });
  }, [geometryLayers]);

  const addGeometry = useCallback((geometry: GeoJSON) => {
    if (!layers) return;
    if (!geometry) return;
    if (geometryLayers?.geometry) {
      removeLayer(geometryLayers.geometry);
      removeGeometryLayer('geometry');
    }

    const newLayer = getStyledVectorLayer();

    newLayer.getSource()?.addFeature(
      new OlFeature({
        geometry: Geometry.readGeometry(geometry, transformOptions),
      }),
    );

    if (geometry.coordinates?.length) {
      // will be fixed with CFD-1012
      // @ts-ignore
      const [geomStartPoint, geomEndPoint] = getBoundaryPoints(geometry);

      const [tStart] = transformWithValidation(geomStartPoint, 'EPSG:4326', 'EPSG:3857', true);
      const [tEnd] = transformWithValidation(geomEndPoint, 'EPSG:4326', 'EPSG:3857', true);

      newLayer.getSource()?.addFeature(
        new OlFeature({
          name: FeatureNames.START,
          geometry: new Point(tStart),
          startAzimuth,
        }),
      );

      newLayer.getSource()?.addFeature(
        new OlFeature({
          name: FeatureNames.END,
          geometry: new Point(tEnd),
        }),
      );
    }

    layers.addLayer(newLayer, 'telematics-drive-geometry');
    setGeometryLayers({ ...geometryLayers, geometry: newLayer });
  }, [geometryLayers, layers, removeGeometryLayer, removeLayer, startAzimuth]);

  const addGeometryPerDay = useCallback((geometryPerDay: GeoJSON) => {
    if (!layers) return;
    if (geometryLayers?.geometryPerDay) {
      removeLayer(geometryLayers.geometryPerDay);
      removeGeometryLayer('geometryPerDay');
    }

    const newLayer = getStyledVectorLayer();

    newLayer.getSource()?.addFeature(
      new OlFeature({
        name: FeatureNames.GEOMETRY_PER_DAY,
        geometry: Geometry.readGeometry(geometryPerDay, transformOptions),
      }),
    );

    layers.addLayer(newLayer, 'telematics-drive-geometry-per-day');
    setGeometryLayers({ ...geometryLayers, geometryPerDay: newLayer });
  }, [geometryLayers, layers, removeGeometryLayer, removeLayer]);

  const zoomToGeometry = useCallback(() => {
    if (parcelGeometry?.geometry && operation !== TelematicsOperation.TRANSFER) {
      map?.zoomToGeometry(parcelGeometry.geometry, undefined);
    } else if (geometry) {
      map?.zoomToGeometry(geometry, undefined);
    } else {
      map?.zoomToFarm();
    }
  }, [geometry, map, operation, parcelGeometry?.geometry]);

  // clean old stored values and create new 'map' and 'el' on mount
  useEffect(() => {
    if (!map) {
      storeServiceWrapper('main', undefined);
      storeServiceWrapper('el', undefined);
      storeServiceWrapper('layers', undefined);
      storeServiceWrapper('style', undefined);

      resetMap();
      resetLayers();
      resetParcelApi();

      setMap(() => new MapService('telematics-map', farm.id, farm.boundingBox, transformOptions));

      setInitialized(true);
      return;
    }

    fetchLayersConfig(farm.customer.countryCode);
    const el = new EventListener(map.getMap());
    storeServiceWrapper('main', map);
    storeServiceWrapper('el', el);

    return () => {
      setMap(undefined);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  // init layers and styles after 'layersConfig' is loaded and 'map' created
  useEffect(() => {
    if (!layersConfig || !map || !initialized) return;

    const updatedConfig = { ...config };
    updatedConfig.api.lpisLayerName = getLpisLayerName(farm.customer.countryCode, config.environment);

    const layers = new Layers(map.getMap(), updatedConfig.api, farm.id, map.getFarmExtent());

    // adding zIndex to the parcels layer
    const extendedNewLayersConfig = layersConfig.map(c => {
      if (c.layerId === PUBLIC_LPIS_CONFIG.LAYER_ID) {
        return {
          ...c,
          visible: true,
        };
      }
      if (c.layerId === PRIVATE_LPIS_CONFIG.LAYER_ID) {
        return {
          ...c,
          visible: true,
        };
      }
      return c;
    });
    layers.setInitialLayers(extendedNewLayersConfig, storeInitialLayers);

    const style = new Style(
      layers.getParcelLayer(),
      layers.getParcelLabelLayer(),
      farm.customer.countryCode,
    );

    storeServiceWrapper('layers', layers);
    storeServiceWrapper('style', style);

    setMapGrabEL();

    setLayers(layers);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layersConfig, map, initialized]);

  useEffect(() => {
    if (!layersConfig.length || !initialized) return;

    editorSetSelected({ id: parcelId });
    editorSetHoveredId(null);
    refreshTelematicsStyles();
    if (parcelId) {
      getParcelApi(parcelId);
    } else {
      resetParcelApi();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcelId, layersConfig.length]);

  useEffect(() => {
    zoomToGeometry();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcelGeometry, geometry, initialized]);

  useEffect(() => {
    if (!layers) return;
    if (geometry) {
      const geometryPerDayRecords = geometryPerDay?.coordinates || geometryPerDay?.geometries;
      const geometryRecords = geometry?.coordinates;
      const hasValidGeometryPerDay =
      geometryPerDayRecords &&
      geometryRecords &&
      geometryPerDayRecords.length > geometryRecords?.length;

      if (hasValidGeometryPerDay) {
        addGeometryPerDay(geometryPerDay);
      }
      addGeometry(geometry);
    } else {
      setGeometryLayers({});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geometry, layers]);

  useEffect(() => {
    if (!displayMap || !map) return;
    map.updateSize();
    zoomToGeometry();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayMap, map]);

  return (
    <div className={classes.map} id="telematics-map">
      <HomeControl zoomToHome={zoomToGeometry} />
      <SectionWrapper left={14} top={17}>
        <LayersCropsControl withCrops={false} />
      </SectionWrapper>
    </div>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  parcelGeometry: getParcel(state),
  layersConfig: getLayersConfig(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      getParcelApi,
      resetParcelApi,
      storeServiceWrapper,
      storeInitialLayers,
      fetchLayersConfig,
      setMapGrabEL,
      editorSetSelected,
      editorSetHoveredId,
      refreshTelematicsStyles,
      resetMap,
      resetLayers,
    },
    dispatch,
  );

export default
connect(mapStateToProps, mapDispatchToProps)(
  withFarm()(
    withConfig()(
      TelematicsDetailMap,
    ),
  ),
);
