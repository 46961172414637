import React, { FC } from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

import { Sample } from '../../../../shared/api/sentinel/soilSamples/soilSamples.types';

const useStyles = makeStyles(() => ({
  table: {
    margin: '0px 0px 15px 15px',
    width: 'auto',
  },
  cell: {
    width: '75px',
    border: '1px solid',
    textAlign: 'center',
  },
  text: {
    margin: 0,
  },
  secondaryLabel: {
    fontWeight: 400,
    fontSize: 10,
  },
}));

const table: { [key: string]: string } = {
  PH: 'pH',
  P: 'P',
  K: 'K',
  MG: 'Mg',
  CA: 'Ca',
  KMG: 'K/Mg',
  S: 'S',
  FE: 'Fe',
  ZN: 'Zn',
  B: 'B',
  HUMUS: 'Humus',
  COx: 'COx',
};

export interface SoilSamplesValuesTableProps {
    values: Sample[][],
}

const SoilSamplesValuesTable: FC<SoilSamplesValuesTableProps> = ({
  values,
}) => {
  const classes = useStyles();
  return (
    <TableContainer>
      <Table classes={{ root: classes.table }}>
        <TableHead>
          <TableRow>
            {values[0]?.map((value, i) => (
              <TableCell classes={{ root: classes.cell }} key={i} size="small">
                <div>
                  <p className={classes.text}>
                    {table[value.sample]}
                  </p>
                  <p className={classNames(classes.text, classes.secondaryLabel)}>
                    {value.sample === 'COx' || value.sample === 'HUMUS' ?
                      '%' :
                      value.sample !== 'PH' && value.sample !== 'KMG' && 'mg/kg'}
                  </p>
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {values?.map((value, i) => (
            <TableRow key={i}>
              {value.map((item, i) => (
                <TableCell classes={{ root: classes.cell }} key={i} size="small">
                  {item.value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SoilSamplesValuesTable;
