import React, { FC } from 'react';

import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import PageHeading from '../../../shared/components/common/PageHeading/PageHeading';
import CfTextBadge from '../../../shared/components/misc/CfTextBadge/CfTextBadge';
import { getDriveBadgeProps } from '../../helpers';
import TelematicsPartialDriveWarning from '../TelematicsPartialDriveWarning/TelematicsPartialDriveWarning';

import { useTelematicsAggregationDetailContentStyles } from './styles';

import { Type } from '../../../shared/api/telematics/telematics.types';

interface Props {
    handleApproveClick: () => void;
    isApproved: boolean;
    isComplete: boolean;
    isEditing: boolean;
    isPostponed: boolean,
    isSentToTos: boolean;
    type: Type;
}

const Heading: FC<Props> = ({ handleApproveClick, isApproved,
  isComplete, isEditing, isPostponed, isSentToTos, type }) => {
  const classes = useTelematicsAggregationDetailContentStyles();
  const intl = useIntl();

  const approvalChecboxLabelId = isSentToTos ?
    'TelematicsAggregations.approvals.thirdPartyAcknowledged' : 'TelematicsAggregations.approvals.approved';

  const badgeProps = getDriveBadgeProps(type, intl);

  return (
    <div className={classes.heading}>
      <CfTextBadge
        backgroundColor={badgeProps.backgroundColor}
        color={badgeProps.color}
        fontSize={badgeProps.fontSize}
        text={badgeProps.text}
        tooltipText={badgeProps.tooltip}>
        <PageHeading
          value={<FormattedMessage id="TelematicsAggregations.detail.title" />}
        />
      </CfTextBadge>
      <div className={classes.checkboxLabel} data-test={'approve-heading-section'}>
        {!isComplete && !isPostponed && <TelematicsPartialDriveWarning tooltipId="TelematicsAggregations.incompleteRide.ride.short" />}
        <FormControlLabel
          className={classnames(isSentToTos ? classes.sentToTosFormControl : classes.formControl)}
          control={
            !isSentToTos && !isPostponed ?
              <Tooltip title={!isComplete ? <FormattedMessage id="TelematicsAggregations.incompleteRide.ride.long" /> : ''}>
                <span>
                  <Checkbox
                    checked={isApproved}
                    classes={{ root: classes.checkbox }}
                    disabled={!isComplete || isEditing}
                    onClick={handleApproveClick}
                  />
                </span>
              </Tooltip> :
              <span />
            }
          label={
            isPostponed ?
              <Tooltip title={<FormattedMessage id="TelematicsAggregations.incompleteRide.ride.postponed" />}>
                <span className={classes.postponedLabel}>
                  <FormattedMessage id="TelematicsAggregations.approvals.postponed" />
                </span>
              </Tooltip> :
              <FormattedMessage id={approvalChecboxLabelId} />
          }
        />
      </div>
    </div>
  );
};

export default Heading;
