import get from 'lodash/get';

import ErrorService from '../../../services/Error.service';

import { ActionsState } from '../../../../reducers/actions.reducer.types';
import { ParcelsState } from '../../../../reducers/parcels.reducer.types';
import { PrecisionState } from '../../../../reducers/precision.reducer.types';
import { TelematicsState } from '../../../../reducers/telematics.reducer.types';

const DEFAULT_PATH = 'api.parcelsNew';

const resolveStatePath = (state: ParcelsState | TelematicsState, path: string | undefined) =>
  get(state, path || DEFAULT_PATH);

export const getParcels = (path?: string) => (state: ParcelsState | TelematicsState) =>
  resolveStatePath(state, path)?.items || [];
export const getIsFetchingParcels = (path?: string) => (state: ParcelsState | TelematicsState) =>
  resolveStatePath(state, path)?.isFetchingItems;
export const getParcelsError = (state: ParcelsState) => ErrorService.getResErrorDto(state.api.parcelsNew.itemsError);
export const getTotalCount = (state: ParcelsState| TelematicsState) => state.api.parcelsNew.totalCount;

export const getParcelsSuggestion = (state: ParcelsState | TelematicsState) => state.api.parcelsNew.itemsSuggestion;
export const getIsFetchingParcelsSuggestion = (state: ParcelsState | TelematicsState) =>
  state.api.parcelsNew.isFetchingItemsSuggestion;

export const getParcelsStatistics = (state: ParcelsState) => state.api.parcelsNew.statistics;
export const getIsFetchingParcelsStatistics = (state: ParcelsState) => state.api.parcelsNew.isFetchingStatistics;

export const getParcelsLandUse = (state: ParcelsState) => state.api.parcelsNew.landUse;
export const getIsFetchingParcelsLandUse = (state: ParcelsState) => state.api.parcelsNew.isFetchingLandUse;

export const getCenters = (state: ParcelsState) => state.api.parcelsNew.centers;
export const getIsFetchingCenters = (state: ParcelsState) => state.api.parcelsNew.isFetchingCenters;

export const getParcel = (state: ParcelsState | TelematicsState | PrecisionState | ActionsState) =>
  state.api.parcelsNew.item;
export const getIsFetchingParcel = (state: ParcelsState | TelematicsState | PrecisionState | ActionsState) =>
  state.api.parcelsNew.isFetchingItem;
export const getParcelError = (state: ParcelsState | TelematicsState | PrecisionState | ActionsState) =>
  ErrorService.getResErrorDto(state.api.parcelsNew.itemError);

export const getParcelSeedApplication = (state: ParcelsState) => state.api.parcelsNew.itemSeedApplication;
export const getIsFetchingParcelSeedApplication = (state: ParcelsState) =>
  state.api.parcelsNew.isFetchingItemSeedApplication;

export const getParcelPredecessors = (state: ParcelsState) => state.api.parcelsNew.itemPredecessors;
export const getIsFetchingParcelPredecessors = (state: ParcelsState) =>
  state.api.parcelsNew.isFetchingItemPredecessors;

export const getParcelEagriRestrictions = (state: ParcelsState) => state.api.parcelsNew.itemEagriRestrictions;
export const getIsFetchinParcelEagriRestrictions = (state: ParcelsState) =>
  state.api.parcelsNew.isFetchingItemEagriRestrictions;

export const getParcelSubtractionsGeometry = (state: ActionsState) =>
  state.api.parcelsNew.itemSubtractionsGeometry;
export const getIsFetchinParcelSubtractionsGeometry = (state: ActionsState) =>
  state.api.parcelsNew.isFetchingItemSubtractionsGeometry;

export const getIsExportingData = (state: ParcelsState) => state.api.parcelsNew.isExportingData;
