import { AnyAction } from 'redux';

import * as types from './farms.constants';

import { FarmState } from './farms.types';

const initialState: FarmState = {
  isFetching: false,
  error: null,
  items: [],
  item: null,
  countries: [],
  isFetchingCountries: false,
  errorCountries: null,
  isFetchingUpdateLPISResults: false,
  updateLPISResults: [],
};

export default () => (
  state = initialState,
  action: AnyAction,
) => {
  switch (action.type) {
    case types.GET_FARMS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_FARMS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.payload,
      };
    case types.GET_FARMS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.RESET_FARMS:
      return {
        ...state,
        items: [],
        error: {},
      };

    case types.GET_FARM_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_FARM_SUCCESS:
      return {
        ...state,
        isFetching: false,
        item: action.payload,
      };
    case types.GET_FARM_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.RESET_FARM:
      return {
        ...state,
        farm: {},
      };

    case types.GET_COUNTRIES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        isFetchingCountries: false,
        countries: action.payload,
      };
    case types.GET_COUNTRIES_ERROR:
      return {
        ...state,
        isFetchingCountries: false,
        errorCountries: action.payload,
      };
    case types.RESET_COUNTRIES:
      return {
        ...state,
        countries: [],
        errorCountries: {},
        isFetchigFarm: false,
        farm: action.payload,
      };

    case types.GET_LPIS_UPDATE_RESULTS_REQUEST:
      return {
        ...state,
        isFetchingUpdateLPISResults: true,
        updateLPISResults: [],
      };
    case types.GET_LPIS_UPDATE_RESULTS_SUCCESS:
      return {
        ...state,
        isFetchingUpdateLPISResults: false,
        updateLPISResults: action.payload,
      };
    case types.GET_LPIS_UPDATE_RESULTS_ERROR:
      return {
        ...state,
        isFetchingUpdateLPISResults: false,
        updateLPISResults: [],
      };
    case types.RESET_LPIS_UPDATE_RESULTS:
      return {
        ...state,
        isFetchingUpdateLPISResults: false,
        updateLPISResults: [],
      };

    default:
      return state;
  }
};
