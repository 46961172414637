const PREFIX = 'DRIVES_IMPORT_HISTORY';

export const GET_IMPORT_HISTORY_REQUEST = `${PREFIX}/GET_IMPORT_HISTORY_REQUEST`;
export const GET_IMPORT_HISTORY_SUCCESS = `${PREFIX}/GET_IMPORT_HISTORY_SUCCESS`;
export const GET_IMPORT_HISTORY_ERROR = `${PREFIX}/GET_IMPORT_HISTORY_ERROR`;
export const RESET_IMPORT_HISTORY = `${PREFIX}/RESET_IMPORT_HISTORY`;

export const GET_IMPORT_HISTORY_STATE_REQUEST = `${PREFIX}/GET_IMPORT_HISTORY_STATE_REQUEST`;
export const GET_IMPORT_HISTORY_STATE_SUCCESS = `${PREFIX}/GET_IMPORT_HISTORY_STATE_SUCCESS`;
export const GET_IMPORT_HISTORY_STATE_ERROR = `${PREFIX}/GET_IMPORT_HISTORY_STATE_ERROR`;

export const GET_IMPORT_FILES_REQUEST = `${PREFIX}/GET_IMPORT_FILES_REQUEST`;
export const GET_IMPORT_FILES_SUCCESS = `${PREFIX}/GET_IMPORT_FILES_SUCCESS`;
export const GET_IMPORT_FILES_ERROR = `${PREFIX}/GET_IMPORT_FILES_ERROR`;

export const GET_VALIDATE_FILES_REQUEST = `${PREFIX}/GET_VALIDATE_FILES_REQUEST`;
export const GET_VALIDATE_FILES_SUCCESS = `${PREFIX}/GET_VALIDATE_FILES_SUCCESS`;
export const GET_VALIDATE_FILES_ERROR = `${PREFIX}/GET_VALIDATE_FILES_ERROR`;
