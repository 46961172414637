import React, { FC, useEffect, useContext } from 'react';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getEquipments, getIsFetchingEquipments, getEquipmentsCount } from '../../../selectors/telematicsCatalogues.selectors';

import { fetchEquipments } from '../../../actions/telematicsCatalogues.actions';

import { getSelectedNamespace, setSelectedNamespace } from '../../../reducers/namespace.reducer';

import { Thunk } from '../../../../types';
import { CataloguesContext } from '../../../containers/CataloguesWrapper/CataloguesWrapper';
import TCTable from '../shared/TCTable';
import TCTableToolbar from '../shared/TCTableToolbar';

import { columns } from './equipment.columns';
import EquipmentListRow from './EquipmentsListRow';

import { CataloguesState, CATALOGUES_NAMESPACES } from '../../../../reducers/catalogues.reducer.types';
import { EquipmentTo } from '../../../../shared/api/telematics/telematics.types';

interface Props {
    count: number;
    equipments: EquipmentTo[];
    fetchEquipments: () => void;
    isFetching: boolean;
    namespace: CATALOGUES_NAMESPACES;
    setNamespace: (namespace: CATALOGUES_NAMESPACES) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
}));

const EquipmentsList: FC<Props> = ({
  count,
  equipments,
  fetchEquipments,
  isFetching,
  namespace,
  setNamespace,
}) => {
  const { langId } = useContext(CataloguesContext);
  const classes = useStyles();

  useEffect(() => {
    setNamespace(CATALOGUES_NAMESPACES.EQUIPMENT);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchEquipments();
  }, [fetchEquipments, langId]);

  if (namespace !== CATALOGUES_NAMESPACES.EQUIPMENT) return null;

  return (
    <div className={classes.wrapper}>
      <TCTableToolbar
        handleOnChangeTrigger={fetchEquipments}
        helperTextId="Catalogues.table.equipments.search"
      />
      <TCTable
        columns={columns}
        count={count}
        handleOnChangeTrigger={fetchEquipments}
        hasData={!!equipments.length}
        isFetching={isFetching}
      >
        {equipments.map((equipment) => (
          <EquipmentListRow data={equipment} key={equipment.id} onEditSuccess={fetchEquipments} />
        ))}
      </TCTable>
    </div>
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  equipments: getEquipments(state),
  isFetching: getIsFetchingEquipments(state),
  count: getEquipmentsCount(state),
  namespace: getSelectedNamespace(state),
});

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) => bindActionCreators({
  fetchEquipments,
  setNamespace: setSelectedNamespace,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentsList);
