import React, { FC } from 'react';

import DoneIcon from '@mui/icons-material/Done';
import { Button, Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';

import TelematicsPartialDriveWarning from '../TelematicsPartialDriveWarning/TelematicsPartialDriveWarning';

import { DriveValidationType, State } from '../../../shared/api/telematics/telematics.types';

interface Props {
    complete: boolean;
    handleSubmit: () => void;
    iconTooltipId?: string;
    state: State;
    tooltipId: string;
    validationErrors?: DriveValidationType[];
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    width: 200,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  notApprovedButton: {
    '&.Mui-disabled': {
      color: '#fff',
      backgroundColor: 'rgba(0, 161, 121, 1)',
      opacity: 0.5,
    },
  },
  approvedButton: {
    '&.Mui-disabled': {
      color: theme.palette.primary.main,
      backgroundColor: 'inherit',
    },
  },
  acknowledgedButton: {
    '&.Mui-disabled': {
      color: theme.palette.grey[500],
    },
  },
  warning: {
    height: 24,
    marginRight: 12,
  },
}));

const ApprovalButton: FC<Props> = ({
  complete,
  handleSubmit,
  iconTooltipId = 'TelematicsAggregations.incompleteRide.ride.short',
  state,
  tooltipId,
  validationErrors,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const handleEventPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (state === State.NOT_APPROVED) {
      e.stopPropagation();
    }
    // other states do not behave button-like
  };

  const getIconTooltip = () => {
    if (!validationErrors) return intl.formatMessage({ id: iconTooltipId });
    return (
      `${intl.formatMessage({ id: 'TelematicsAggregations.incompleteRide.short.intro' })}
       ${validationErrors.map((error) =>
        `- ${intl.formatMessage({ id: `TelematicsAggregations.incompleteRide.short.${error}` })}`).join('\n')}`
    );
  };

  return (
    <div className={classes.container} onClick={handleEventPropagation}>
      {state === State.NOT_APPROVED && !complete &&
        <TelematicsPartialDriveWarning
          classes={classes.warning}
          customTooltipTitle={<span style={{ whiteSpace: 'pre-line' }}>{getIconTooltip()}</span>}
        />}
      {state === State.DEFERRED && !complete &&
        <TelematicsPartialDriveWarning
          classes={classes.warning}
          customTooltipTitle={<span style={{ whiteSpace: 'pre-line' }} />}
          isPostponedRide
        />}

      {state === State.NOT_APPROVED &&
        <Tooltip title={!complete ? <FormattedMessage id={tooltipId} /> : ''}>
          <span>
            <Button
              aria-label="Approved ride"
              classes={{ root: classes.notApprovedButton }}
              color="primary"
              disabled={!complete}
              onClick={handleSubmit}
              variant="contained"
            >
              <FormattedMessage id="TelematicsAggregations.approvals.notApproved" />
            </Button>
          </span>
        </Tooltip>
      }

      {state === State.DEFERRED &&
        <Tooltip title={<FormattedMessage id="TelematicsAggregations.incompleteRide.ride.postponed" />}>
          <span>
            <Button disabled variant="contained">
              <FormattedMessage id="TelematicsAggregations.approvals.notApproved" />
            </Button>
          </span>
        </Tooltip>
      }

      {state === State.APPROVED &&
        <Button
          classes={{ root: classes.approvedButton }}
          color="primary"
          disabled
          startIcon={<DoneIcon />}
          variant="contained"
        >
          <FormattedMessage id="TelematicsAggregations.approvals.approved" />
        </Button>
      }

      {state === State.THIRD_PARTY_ACKNOWLEDGED &&
        <Button
          classes={{ root: classes.acknowledgedButton }}
          disabled
          variant="text"
        >
          <FormattedMessage id="TelematicsAggregations.approvals.thirdPartyAcknowledged" />
        </Button>
        }

    </div>
  );
};

export default ApprovalButton;
