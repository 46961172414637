import React, { useEffect, useState, useContext, useMemo } from 'react';

import { Grid } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import CfFormControl from '../../../../../shared/components/form/CfFormControl/CfFormControl';
import CfFormikTextField from '../../../../../shared/components/form/CfFormikTextField/CfFormikTextField';
import * as validators from '../../../../../shared/misc/validators';
import Localization from '../../../../../shared/services/Localization.service';
import ActionsService from '../../../shared/services/Actions.service';
import { FormContext } from '../../actionOther.context';

function Revenues({ isSecondaryHarvestProduct = false }) {
  const [totalArea, setTotalArea] = useState(0);
  const [dirtyInput, setDirtyInput] = useState('');
  const { setFieldValue, values: { parcels, secondaryTotalGain, totalGain } } = useFormikContext();
  const { isEditing } = useContext(FormContext);
  const intl = useIntl();

  const updateFormValues = (value) => {
    // this is triggered on value change.
    // Computation is skipped for all values that cannot be properly converted to numbers.

    const isValidValue = Localization.checkValidStr(value, intl.locale);

    if (!isValidValue) {
      setFieldValue(dirtyInput, value);
      return;
    }

    // convert localization-formatted value strings to numbers
    const normalizedVal = Localization.str2numNonFixed(value, intl.locale);
    const normalizedTotalArea = Localization.str2numNonFixed(totalArea, intl.locale);

    let primaryField;
    let secondaryField;
    let secondaryFieldValue;

    switch (dirtyInput) {
      case 'totalGain':
        primaryField = 'totalGain';
        secondaryField = 'hectarGain';
        secondaryFieldValue = normalizedVal / normalizedTotalArea;
        break;
      case 'hectarGain':
        primaryField = 'hectarGain';
        secondaryField = 'totalGain';
        secondaryFieldValue = normalizedVal * normalizedTotalArea;
        break;
      case 'secondaryTotalGain':
        primaryField = 'secondaryTotalGain';
        secondaryField = 'secondaryHectarGain';
        secondaryFieldValue = normalizedVal / normalizedTotalArea;
        break;
      case 'secondaryHectarGain':
        primaryField = 'secondaryHectarGain';
        secondaryField = 'secondaryTotalGain';
        secondaryFieldValue = normalizedVal * normalizedTotalArea;
        break;
      default:
        break;
    }

    setFieldValue(primaryField, Localization.num2strNonFixed(normalizedVal, intl.locale));
    setFieldValue(secondaryField, Localization.num2str(secondaryFieldValue, intl.locale, 3));
  };

  const onChangeValue = (e) => {
    const value = e.currentTarget.value;
    updateFormValues(value);
  };

  const onFocus = (e) => {
    setDirtyInput(e.currentTarget.name);
  };

  useEffect(() => {
    if (parcels && parcels.length) {
      const area = ActionsService.getTotalArea(parcels);
      setTotalArea(area.toFixed(2));
    }
  }, [parcels]);

  useEffect(() => {
    if (totalArea) {
      const normalizedTotalArea = Localization.str2numNonFixed(totalArea, intl.locale);

      if (totalGain && !isSecondaryHarvestProduct) {
        const normalizedTotalGain = Localization.str2numNonFixed(totalGain, intl.locale);
        const hectarGain = normalizedTotalGain / normalizedTotalArea;
        setFieldValue('hectarGain', Localization.num2str(hectarGain, intl.locale, 3));
      }
      if (secondaryTotalGain && isSecondaryHarvestProduct) {
        const normalizedSecondaryTotalGain = Localization.str2numNonFixed(secondaryTotalGain, intl.locale);
        const secondaryHectarGain = normalizedSecondaryTotalGain / normalizedTotalArea;
        setFieldValue('secondaryHectarGain', Localization.num2str(secondaryHectarGain, intl.locale, 3));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, totalArea, intl.locale]);

  const localizedNumberValidator = useMemo(() => validators.localizedNumber(intl.locale), [intl.locale]);

  return (
    <Grid alignItems="center" container justifyContent="space-between" spacing={1}>
      <Grid item xs={10}>
        <CfFormControl>
          <Field
            component={CfFormikTextField}
            disabled={!totalArea || !isEditing}
            label={isSecondaryHarvestProduct ? <FormattedMessage id="action.secondaryTotalGain" /> : <FormattedMessage id="action.totalGain" />}
            name={isSecondaryHarvestProduct ? 'secondaryTotalGain' : 'totalGain'}
            onChange={onChangeValue}
            onFocus={onFocus}
            shrinkLabel={!!totalArea}
            validate={localizedNumberValidator}
            validateOnBlur
          />
        </CfFormControl>
      </Grid>
      <Grid item xs={2}>
        <span>t</span>
      </Grid>
      <Grid item xs={10}>
        <CfFormControl>
          <Field
            component={CfFormikTextField}
            disabled={!totalArea || !isEditing}
            label={isSecondaryHarvestProduct ? <FormattedMessage id="action.secondaryGainPerHectare" /> : <FormattedMessage id="action.gainPerHectare" />}
            name={isSecondaryHarvestProduct ? 'secondaryHectarGain' : 'hectarGain'}
            onChange={onChangeValue}
            onFocus={onFocus}
            shrinkLabel={!!totalArea}
          />
        </CfFormControl>
      </Grid>
      <Grid item xs={2}>
        <span>t/ha</span>
      </Grid>
    </Grid>
  );
}

export default Revenues;
