import React, { Component } from 'react';

import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import CfDatePicker from '../../../../../shared/components/common/CfDatePicker/CfDatePicker';
import CfDialog from '../../../../../shared/components/common/CfDialog/CfDialog';
import CfFormattedField from '../../../../../shared/components/form/CfFormattedField/CfFormattedField';
import CfFormattedTextField from '../../../../../shared/components/form/CfFormattedTextField/CfFormattedTextField';
import CfFormControl from '../../../../../shared/components/form/CfFormControl/CfFormControl';
import CfSelector from '../../../../../shared/components/form/CfSelector/CfSelector';
import CfTextField from '../../../../../shared/components/form/CfTextField/CfTextField';
import * as validators from '../../../../../shared/misc/validators';
import UnitService from '../../../../../shared/services/Unit.service';
import TransactionTypeSelector from '../../components/TransactionTypeSelector/TransactionTypeSelector';
import StoresService from '../../services/StoresService/StoresService';

const FORM_NAME = 'transaction';

class TransactionDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      units: [],
    };
  }

  onSubmit = this.props.handleSubmit(values => {
    this.setState({ units: [] });
    const transaction = this.props.transactionItem || undefined;
    this.props.onAccept(StoresService.getTransactionDto(values, transaction));
  });

  setCompatibleUnits = compatible => {
    this.setState({
      units: compatible,
    });
  };

  getCurrentUnit = (compatible, storeItem) => compatible.find(unit => unit.id === storeItem.unitId) || null;

  initialize = () => {
    const store = this.props.storeItem;
    const transaction = this.props.transactionItem || {};

    const compatible = UnitService.getCompatibleUnits(store.unitId);
    const unit = this.getCurrentUnit(compatible, store);
    this.setCompatibleUnits(compatible);

    this.props.initialize({
      // store related props
      expense: store.material,
      currAmount: store.currentBalance,
      currUnit: unit,
      unit,
      // transaction related props
      id: transaction.id || null,
      type: StoresService.getTransactionType(transaction.type),
      date: moment(transaction.date) || moment(),
      amount: Math.abs(transaction.amount) || null,
      note: transaction.note || null,
    });
  };

  render() {
    const { onClose, opened, storeItem, transactionId, transactionType } = this.props;
    return (
      <CfDialog
        acceptText={<FormattedMessage id={transactionId ? 'common.edit' : 'Stores.stock'} />}
        cancelText={<FormattedMessage id="common.cancel" />}
        dialogHeight={'420px'}
        onAccept={this.onSubmit}
        onCancel={onClose}
        onClose={onClose}
        onEnter={this.initialize}
        opened={opened}
        title={
          <FormattedMessage
            id="Stores.transaction"
            values={{
              material: storeItem && storeItem.material ? ` - ${storeItem.material.name}` : '',
            }}
          />
        }
      >
        <form>
          <CfFormControl>
            <Grid container spacing={2}>
              <Grid item sm={8} xs={6}>
                <CfFormattedField
                  component={CfFormattedTextField}
                  disabled={true}
                  formatDecimal={4}
                  fullWidth
                  label={<FormattedMessage id="Stores.currently-on-store" />}
                  name="currAmount"
                  type={'text'}
                  validate={[validators.number]}
                />
              </Grid>
              <Grid item sm={4} xs={6}>
                <Field
                  component={CfSelector}
                  disabled={true}
                  items={this.state.units}
                  label={<FormattedMessage id="common.unit" />}
                  labelPropertyFactory={option => <FormattedMessage id={`unit.${option.id}`} />}
                  name="currUnit"
                  validate={validators.required}
                />
              </Grid>
            </Grid>
          </CfFormControl>
          <CfFormControl>
            <Field component={TransactionTypeSelector} name="type" validate={validators.required} />
          </CfFormControl>
          <CfFormControl>
            <Grid container spacing={2}>
              <Grid item sm={8} xs={6}>
                <CfFormattedField
                  component={CfFormattedTextField}
                  formatDecimal={4}
                  fullWidth
                  label={<FormattedMessage id="common.amount" />}
                  name="amount"
                  normalize={value => value && Math.abs(value)}
                  type={'text'}
                  validate={[validators.required, validators.number]}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{transactionType ? transactionType.sign : ''}</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item sm={4} xs={6}>
                <Field
                  component={CfSelector}
                  items={this.state.units}
                  label={<FormattedMessage id="common.unit" />}
                  labelPropertyFactory={option => <FormattedMessage id={`unit.${option.id}`} />}
                  name="unit"
                  validate={validators.required}
                />
              </Grid>
            </Grid>
          </CfFormControl>
          <CfFormControl>
            <Field
              component={CfDatePicker}
              data-test="date"
              label={<FormattedMessage id="Stores.store-date" />}
              name="date"
              propagateInvalidDate
              validate={[validators.required, validators.isValidDate]}
            />
          </CfFormControl>
          <CfFormControl>
            <Field
              component={CfTextField}
              fullWidth
              label={<FormattedMessage id="common.note" />}
              multiline
              name="note"
              type="text"
            />
          </CfFormControl>
        </form>
      </CfDialog>
    );
  }
}

TransactionDialog.propTypes = {
  storeItem: PropTypes.object,
  transactionItem: PropTypes.object,
  transactionType: PropTypes.object,
  transactionId: PropTypes.string,
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

TransactionDialog.defaultProps = {
  storeItem: null,
  transactionItem: null,
  transactionType: null,
  transactionId: null,
  opened: false,
};

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = state => ({
  transactionId: selector(state, 'id'),
  transactionType: selector(state, 'type'),
});

export default connect(
  mapStateToProps,
  null,
)(
  reduxForm({
    form: FORM_NAME,
  })(TransactionDialog),
);
