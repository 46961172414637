import React, { CSSProperties, FC, ReactNode } from 'react';

import Grid from '@mui/material/Grid';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';

import { NAMESPACE as namespace } from '../../../shared/reducer/parcelDetail.reducer';

import CfTableBodyEmpty from '../../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty';
import CfTableCell from '../../../../../../shared/components/tables/CfTableCell/CfTableCell';
import CfTableWrapper from '../../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableHead from '../../../../../../shared/containers/CfTableHead/CfTableHead';
import Localization from '../../../../../../shared/services/Localization.service';
import ParcelDetailSection from '../ParcelDetailSection/ParcelDetailSection';

import { ParcelSeedApplicationTo } from '../../../../../../shared/api/agroevidence/agroevidence.types';

const useStyles = makeStyles(() => ({
  firstColumn: {
    fontWeight: 500,
    paddingLeft: 15,
  },
}));

const getColDesc = (label: ReactNode, style?: CSSProperties) => ({
  align: 'inherit',
  sortable: false,
  label,
  style,
});

const columns = {
  crop: getColDesc(
    <span style={{ paddingLeft: 15 }}>
      <FormattedMessage id="common.crop" />
    </span>,
  ),
  variety: getColDesc(<FormattedMessage id="common.variety" />),
  period: getColDesc(<FormattedMessage id="ParcelDetailOverview.crops.period" />),
  application: getColDesc(<FormattedMessage id="ParcelDetailOverview.crops.applied" />),
  yield: getColDesc(<FormattedMessage id="common.yield" values={{ unit: '(t/ha)' }} />),
};

export interface ParcelDetailCropTableProps {
  langId: string,
  parcelSeedApplication: ParcelSeedApplicationTo[],
}

export const ParcelDetailCropTable: FC<ParcelDetailCropTableProps> = ({
  langId,
  parcelSeedApplication,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={columns}
        count={parcelSeedApplication.length}
        langId={langId}
        namespace={namespace}
        selectable={false}
      />
      {parcelSeedApplication.length ? (
        <TableBody>
          {parcelSeedApplication.map(item => {
            const fertilization = item.fertilization;
            return (
              <TableRow key={item.id}>
                <CfTableCell name={'name'}>
                  <span className={classes.firstColumn}>{item.seed?.crop.name}</span>
                </CfTableCell>
                <CfTableCell name={'variety'}>
                  {item.seed?.varietyName ?? '-'}
                </CfTableCell>
                <CfTableCell name={'date'}>
                  {item.startDate ? <FormattedDate value={item.startDate} /> : ''}
                  {' \u2013 '}
                  {item.endDate ? <FormattedDate value={item.endDate} /> : ''}
                </CfTableCell>
                <CfTableCell name={'applications'}>
                  {fertilization ? (
                    <Grid container>
                      <Grid item xs={4}>
                        <ParcelDetailSection
                          dense={true}
                          label={'N'}
                          valueBold={false}
                          valueItem={Localization.num2str(fertilization.accountableNitrogen, intl.locale)}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <ParcelDetailSection
                          dense={true}
                          valueBold={false}
                          valueItem={Localization.num2str(fertilization.p2o5, intl.locale)}
                          label={
                            <span>
                              P<sub>2</sub>O<sub>5</sub>
                            </span>
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <ParcelDetailSection
                          dense={true}
                          valueBold={false}
                          valueItem={Localization.num2str(fertilization.k2o, intl.locale)}
                          label={
                            <span>
                              K<sub>2</sub>O
                            </span>
                          }
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <span />
                  )}
                </CfTableCell>
                <CfTableCell name={'yield'}>
                  {item.primaryRevenueHa ? Localization.num2str(item.primaryRevenueHa, intl.locale) : '-'}
                </CfTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      )}
    </CfTableWrapper>
  );
};

export default ParcelDetailCropTable;
