import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './zones.constants';

import { RsaaMethods, RsaaTypes } from '../../api.types';

export type CreateUpdateZoneParams = {
    name: string,
}

export const getZonesApi = (search?: string) => ({
  [RSAA]: {
    endpoint: `parcels/zones?${search ? `search=${search}&` : ''}`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_ZONES_REQUEST,
      types.GET_ZONES_SUCCESS,
      types.GET_ZONES_ERROR,
    ] as RsaaTypes,
  },
});

export const resetZonesApi = () => ({
  type: types.RESET_ZONES,
});

export const createZoneApi = (params: CreateUpdateZoneParams) => ({
  [RSAA]: {
    endpoint: 'parcels/zones?',
    method: methods.POST as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.POST_ZONE_REQUEST,
      types.POST_ZONE_SUCCESS,
      types.POST_ZONE_ERROR,
    ] as RsaaTypes,
  },
});

export const updateZoneApi = (zoneId: string, params: CreateUpdateZoneParams) => ({
  [RSAA]: {
    endpoint: `parcels/zones/${zoneId}?`,
    method: methods.PATCH as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.PATCH_ZONE_REQUEST,
      types.PATCH_ZONE_SUCCESS,
      types.PATCH_ZONE_ERROR,
    ] as RsaaTypes,
  },
});

export const deleteZoneApi = (zoneId: string) => ({
  [RSAA]: {
    endpoint: `parcels/zones/${zoneId}?`,
    method: methods.DELETE as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.DELETE_ZONE_REQUEST,
      types.DELETE_ZONE_SUCCESS,
      types.DELETE_ZONE_ERROR,
    ] as RsaaTypes,
  },
});
