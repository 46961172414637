import React, { useEffect, useContext, useCallback, Fragment } from 'react';

import { Grid, Button, Card, Icon, Typography, Switch, FormControlLabel } from '@mui/material';
import { Form, Field, FieldArray, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getSeeds, getIsFetchingSeeds } from '../../../../../shared/api/agroevidence/catalogues/seeds/seeds.selectors';
// TODO docasne komentovani kodu, dalsi krok odstranit nebud prepsani
// import { getValidationFailure } from '../../../../../shared/api/core/actions/actions.selectors';

import { fetchCropUsage, fetchHarvestProduct, fetchOtherActionTypes } from '../../../shared/actions/actions.actions';

import { FORM_TYPES, OTHER_ACTION_HARVEST_CODE, OTHER_ACTION_MOWING_CODE, OTHER_ACTION_SOWING_CODE } from '../../actionOther.constants';

import { resetOtherActionTypesApi } from '../../../../../shared/api/agroevidence/actions/actions.api';
import { resetCropUsageApi, resetHarvestProductsApi } from '../../../../../shared/api/agroevidence/catalogues/eagri/eagri.api';
import CfFormControl from '../../../../../shared/components/form/CfFormControl/CfFormControl';
import CfFormikDatePicker from '../../../../../shared/components/form/CfFormikDatePicker/CfFormikDatePicker';
import CfFormikTextField from '../../../../../shared/components/form/CfFormikTextField/CfFormikTextField';
import * as validators from '../../../../../shared/misc/validators';
import { ActionButtons } from '../../../shared/components/ActionButtons/ActionButtons';
import { ActionDetailContext } from '../../../shared/containers/ActionDetail/ActionDetail';
import ParcelsControl from '../../../shared/containers/ParcelsControl/ParcelsControl';
// TODO docasne komentovani kodu, dalsi krok odstranit nebud prepsani
// import { validateAction, resetValidationFailure } from '../../../../../shared/api/core/actions/actions.api';
// import ActionRestrictionsInfo from '../../../shared/components/ActionRestrictionsInfo/ActionRestrictionsInfo';
// import { mapRequestBodyOtherActionTo } from '../../../shared/services/ActionToMapper.services';
import { FormContext } from '../../actionOther.context';
import Revenues from '../../components/ActionHarvest/Revenues';
import ActionSelector from '../../components/ActionOther/ActionSelector';
import AmountSelector from '../../components/ActionOther/AmountSelector';
import CropUsageSelector from '../../components/ActionOther/CropUsageSelector';
import HarvestProductSelector from '../../components/ActionOther/HarvestProductSelector';
import ProductSelector from '../../components/ActionOther/ProductSelector';
import SeedSelector from '../../components/ActionOther/SeedSelector';
import { isAnyParcelNotSown, isAnyParcelSown } from '../../helpers/others.helpers';

function ActionOther({
  classes,
  countryCode,
  fetchCropUsage,
  fetchHarvestProduct,
  fetchOtherActionTypes,
  isFetchingSeeds,
  resetCropUsageApi,
  resetHarvestProductsApi,
  resetOtherActionTypesApi,
  seeds,
  // TODO docasne komentovani kodu, dalsi krok odstranit nebud prepsani
  // validateAction,
  // resetValidationFailure,
  // validationFailure,
}) {
  const {
    // TODO docasne komentovani kodu, dalsi krok odstranit nebud prepsani
    // isValid, isValidating,
    resetForm, setFieldValue, values } = useFormikContext();
  const {
    formType,
    handleLsReset,
    handleSaveToLs,
    isDraft,
    isEditing,
    isExisting,
    onEditingEnd,
    resetFormValues,
  } = useContext(FormContext);
  // TODO docasne komentovani kodu, dalsi krok odstranit nebud prepsani
  // const timeout = useRef(null);
  const intl = useIntl();

  useEffect(() =>
  // TODO docasne komentovani kodu, dalsi krok odstranit nebud prepsani
  // resetValidationFailure();

    () => {
      // TODO docasne komentovani kodu, dalsi krok odstranit nebud prepsani
      // if (timeout.current) {
      //   // eslint-disable-next-line react-hooks/exhaustive-deps
      //   clearTimeout(timeout.current);
      // }
      resetCropUsageApi();
      resetHarvestProductsApi();
      resetOtherActionTypesApi();
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  useEffect(() => {
    if (isExisting) return;
    handleSaveToLs(values);

    // TODO fix this
    if (formType === FORM_TYPES.HARVEST) {
      setFieldValue('actionType', OTHER_ACTION_HARVEST_CODE);
    } else if (formType === FORM_TYPES.MOWING) {
      setFieldValue('actionType', OTHER_ACTION_MOWING_CODE);
    } else if (formType === FORM_TYPES.SOWING) {
      setFieldValue('actionType', OTHER_ACTION_SOWING_CODE);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  // TODO docasne komentovani kodu, dalsi krok odstranit nebud prepsani
  // const apiValidation = () => {
  //   const data = mapRequestBodyOtherActionTo(values, intl.locale);
  //   validateAction(data);
  // };

  // useEffect(() => {
  //   if (timeout.current) {
  //     clearTimeout(timeout.current);
  //   }

  //   if (isValidating || !isValid) {
  //     return;
  //   }

  //   if (formType !== FORM_TYPES.SOWING) {
  //     return;
  //   }

  //   if (!values.seedId) {
  //     return;
  //   }

  //   timeout.current = setTimeout(apiValidation, 1000);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [values, isValidating, isValid]);

  useEffect(() => {
    if (values.seedId && seeds.length) {
      const selectedSeed = seeds.filter(seed => seed.id === values.seedId);
      // stop in case the selected seed is not found in the seeds classifier (will be fixed in the future)
      if (!selectedSeed?.length) return;
      let legislativeCodeforFetch = selectedSeed?.[0]?.crop?.legislativeCode;
      if (!legislativeCodeforFetch) {
        const selectedCrop = seeds.map(seed => seed.crop).filter(crop => crop['@id'] === selectedSeed[0].crop['@ref']);
        legislativeCodeforFetch = selectedCrop[0].legislativeCode;
      }
      fetchCropUsage(legislativeCodeforFetch).then((res) => {
        if (!res.payload.length) {
          setFieldValue('cropUseType', '');
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seeds, values.seedId]);

  useEffect(() => {
    if (formType === FORM_TYPES.HARVEST && values.parcels.length) {
      // TODO docasny fix do prepisu actions endpointu na sluzbu agroevidence

      // let cropLegislativeCode;
      // if (isExisting) {
      //   cropLegislativeCode = values.parcels[0].currentSeedApplication.seedingActionSeed.crop.legislativeCode;
      // } else {
      //   cropLegislativeCode = values.parcels[0].currentSeedApplication.seed.crop.legislativeCode;
      // }
      // fetchHarvestProduct(cropLegislativeCode);
      fetchHarvestProduct();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.parcels, formType]);

  useEffect(() => {
    if (formType === FORM_TYPES.OTHER) {
      fetchOtherActionTypes();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formType, intl.locale]);

  useEffect(() => {
    if (values.secondaryHarvestProductId === 0) {
      setFieldValue('secondaryTotalGain', 0);
      setFieldValue('secondaryHectarGain', 0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.secondaryHarvestProductId]);

  const handleReset = useCallback(
    () => {
      if (!isExisting) {
        handleLsReset();
        resetFormValues();
      } else {
        onEditingEnd();
        resetForm();
      }
    },
    [handleLsReset, isExisting, onEditingEnd, resetForm, resetFormValues],
  );

  const handleHarvestCropChange = (e, value) => {
    setFieldValue('harvestCrop', value);
  };

  const handleIsCatchCropChange = (e, value) => {
    setFieldValue('isCatchCrop', value);
  };

  const handleSeedChange = (e, value) => {
    setFieldValue('seedId', value.id);
  };

  const isNewHarvestForm = formType === FORM_TYPES.HARVEST && !isExisting;
  const isNewMowingForm = formType === FORM_TYPES.MOWING && !isExisting;
  const allMustBeSown = isNewHarvestForm || isNewMowingForm;

  const hasUnsownParcels = isAnyParcelNotSown(values.parcels);
  const isParcelSelected = values.parcels.length > 0;
  const showWarningMessage = allMustBeSown && hasUnsownParcels && isParcelSelected;

  const hasAllUnsownParcels = !isAnyParcelSown(values.parcels);

  const hasDuplicateParcelIds = values.parcels.map(p => p.id)
    .filter((value, index, self) => self.indexOf(value) !== index)
    .length > 0;

  let warningMessage = '';
  if (showWarningMessage) {
    if (formType === FORM_TYPES.HARVEST) {
      warningMessage = hasAllUnsownParcels ? 'action.harvest.catchAllParcelsNotSown' : 'action.harvest.catchSomeParcelsNotSown';
    } else if (formType === FORM_TYPES.MOWING) {
      warningMessage = hasAllUnsownParcels ? 'action.mowing.catchAllParcelsNotSown' : 'action.mowing.catchSomeParcelsNotSown';
    }
  }

  const isMainHarvestProductSelected = values.mainHarvestProductId !== 0;
  const isSecondaryHarvestProductSelected = values.secondaryHarvestProductId !== 0;
  const czechFarm = countryCode === 'CZ';

  return (
    <ActionDetailContext.Consumer>
      {contextValue => (
        <Fragment>
          <Form>
            <Grid alignItems="center" container justifyContent="center" spacing={1}>
              <Grid item lg={8} md={10} xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <FieldArray name="parcels">
                      {({ form, push, remove }) => (
                        <ParcelsControl
                          allMustBeSown={allMustBeSown}
                          form={form}
                          formType={formType}
                          handleInsert={push}
                          handleRemove={remove}
                          hasDuplicateParcelIds={hasDuplicateParcelIds}
                          isDraft={isDraft}
                          isEditing={isEditing}
                          isExisting={isExisting}
                  />
                      )}
                    </FieldArray>
                  </Grid>
                </Grid>
                <Grid alignItems="center" className={classes.date} container justifyContent="center" spacing={1}>
                  <Grid item md={5} sm={7} xl={4} xs={10}>
                    <CfFormControl>
                      <Field
                        component={CfFormikDatePicker}
                        disabled={!isEditing}
                        label={<FormattedMessage id="common.date" />}
                        name="date"
                        validate={validators.formikDateValidRequired}
                />
                    </CfFormControl>
                  </Grid>
                </Grid>

                {formType === FORM_TYPES.MOWING && (
                <Grid alignItems="center" container justifyContent="center" spacing={1}>
                  <Grid item md={5} sm={7} xl={4} xs={10}>
                    <ProductSelector isEditing={isEditing} />
                  </Grid>
                </Grid>
                )}

                {(formType === FORM_TYPES.HARVEST && czechFarm) && (
                <Grid alignItems="center" container justifyContent="center" spacing={1}>
                  <Grid item md={5} sm={7} xl={4} xs={10}>
                    <HarvestProductSelector isEditing={isEditing} />
                  </Grid>
                </Grid>
                )}

                {(formType === FORM_TYPES.HARVEST || formType === FORM_TYPES.MOWING) && (
                <Grid alignItems="center" container justifyContent="center" spacing={1}>
                  <Grid item md={5} sm={7} xl={4} xs={10}>
                    <Revenues />
                  </Grid>
                </Grid>
                )}

                {(formType === FORM_TYPES.HARVEST && isMainHarvestProductSelected && czechFarm) && (
                <>
                  <Grid alignItems="center" container justifyContent="center" spacing={1}>
                    <Grid item md={5} sm={7} xl={4} xs={10}>
                      <HarvestProductSelector isEditing={isEditing} isSecondaryHarvestProduct />
                    </Grid>
                  </Grid>
                  {isSecondaryHarvestProductSelected && (
                  <Grid alignItems="center" container justifyContent="center" spacing={1}>
                    <Grid item md={5} sm={7} xl={4} xs={10}>
                      <Revenues isSecondaryHarvestProduct />
                    </Grid>
                  </Grid>
                  )}
                </>
                )}

                {formType === FORM_TYPES.OTHER && (
                <Grid alignItems="center" container justifyContent="center" spacing={1}>
                  <Grid item md={5} sm={7} xl={4} xs={10}>
                    <ActionSelector isEditing={isEditing} />
                  </Grid>
                </Grid>
                )}

                {
            // todo: temporary hide switch, remove "false"
          }
                {false && formType === FORM_TYPES.MOWING && (
                <Grid alignItems="center" container justifyContent="center" spacing={1}>
                  <Grid item md={5} sm={7} xl={4} xs={10}>
                    <FormControlLabel
                      className={classes.checkboxContainer}
                      disabled={!isEditing}
                      label={<FormattedMessage id="action.harvestCrop" />}
                      labelPlacement="start"
                      control={
                        <Switch
                          checked={values.harvestCrop}
                          color="primary"
                          id="harvestCrop"
                          onChange={handleHarvestCropChange}
                    />
                  }
                />
                  </Grid>
                </Grid>
                )}

                {formType === FORM_TYPES.SOWING && (
                <Grid alignItems="center" container justifyContent="center" spacing={1}>
                  <Grid item md={5} sm={7} xl={4} xs={10}>
                    <SeedSelector
                      isEditing={isEditing}
                      isFetchingSeeds={isFetchingSeeds}
                      onChange={handleSeedChange}
                      seeds={seeds}
                />
                  </Grid>
                </Grid>
                )}

                {(formType === FORM_TYPES.SOWING && czechFarm) && (
                <Grid alignItems="center" container justifyContent="center" spacing={1}>
                  <Grid item md={5} sm={7} xl={4} xs={10}>
                    <CropUsageSelector isEditing={isEditing} />
                  </Grid>
                </Grid>
                )}

                {formType === FORM_TYPES.SOWING && (
                <Grid alignItems="center" container justifyContent="center" spacing={1}>
                  <Grid item md={5} sm={7} xl={4} xs={10}>
                    <AmountSelector />
                  </Grid>
                </Grid>
                )}

                {formType === FORM_TYPES.SOWING && (
                <Grid alignItems="center" container justifyContent="center" spacing={1}>
                  <Grid item md={5} sm={7} xl={4} xs={10}>
                    <FormControlLabel
                      className={classes.checkboxContainer}
                      disabled={!isEditing}
                      label={<FormattedMessage id="action.isCatchCrop" />}
                      labelPlacement="start"
                      control={
                        <Switch
                          checked={values.isCatchCrop}
                          color="primary"
                          id="isCatchCrop"
                          onChange={handleIsCatchCropChange}
                    />
                  }
                />
                  </Grid>
                </Grid>
                )}

                <Grid alignItems="center" container justifyContent="center" spacing={1}>
                  <Grid item md={5} sm={7} xl={4} xs={10}>
                    <CfFormControl>
                      <Field
                        component={CfFormikTextField}
                        disabled={!isEditing}
                        label={<FormattedMessage id="common.note" />}
                        name="description"
                />
                    </CfFormControl>
                  </Grid>
                </Grid>
                {showWarningMessage && (
                <Grid alignItems="center" className={classes.actionInfo} container justifyContent="center">
                  <Card className={classes.card}>
                    <div className={classes.header}>
                      <Icon>warning</Icon>
                    </div>
                    <Typography className={classes.content} component="p">
                      <FormattedMessage id={warningMessage} />
                    </Typography>
                  </Card>
                </Grid>)}
                {/* TODO docasne komentovani kodu, dalsi krok odstranit nebud prepsani */}
                {/* <Grid item xs={12}>
            <Grid alignItems="center" container justifyContent="center" spacing={0}>
              <ActionRestrictionsInfo validationDetails={validationFailure} />
            </Grid>
          </Grid> */}
                {isEditing && (
                <Grid item xs={12}>
                  <Grid alignItems="center" className={classes.formButtons} container justifyContent="center" spacing={0}>
                    <Button className={classes.button} disabled={!isEditing} id="reset" onClick={handleReset} type="reset" variant="contained">
                      <FormattedMessage id={isExisting ? 'common.cancel' : 'common.reset'} />
                    </Button>
                    <Button
                      className={classes.button}
                      color="primary"
                      disabled={showWarningMessage || !isEditing || hasDuplicateParcelIds}
                      id="create"
                      type="submit"
                      variant="contained"
                >
                      <FormattedMessage id={isExisting ? 'common.save' : 'common.create'} />
                    </Button>
                  </Grid>
                </Grid>
                )}
              </Grid>
            </Grid>
          </Form>
          {contextValue?.isSplitting &&
            <ActionButtons
              isDisabled={false}
              onCancel={contextValue?.handleCancelIsSplitting}
              onConfirm={contextValue?.splitActionHandler}
            />
          }
        </Fragment>
      )}
    </ActionDetailContext.Consumer>
  );
}

ActionOther.propTypes = {
  classes: PropTypes.object.isRequired,
  countryCode: PropTypes.string.isRequired,
  // TODO docasne komentovani kodu, dalsi krok odstranit nebud prepsani
  // validateAction: PropTypes.func.isRequired,
  // resetValidationFailure: PropTypes.func.isRequired,
  // validationFailure: PropTypes.object,
  fetchCropUsage: PropTypes.func.isRequired,
  fetchHarvestProduct: PropTypes.func.isRequired,
  fetchOtherActionTypes: PropTypes.func.isRequired,
  resetCropUsageApi: PropTypes.func.isRequired,
  resetHarvestProductsApi: PropTypes.func.isRequired,
  resetOtherActionTypesApi: PropTypes.func.isRequired,
  seeds: PropTypes.array.isRequired,
  isFetchingSeeds: PropTypes.bool.isRequired,
};

// TODO docasne komentovani kodu, dalsi krok odstranit nebud prepsani
// ActionOther.defaultProps = {
//   validationFailure: null,
// };

const mapStateToProps = (state) => ({
  // TODO docasne komentovani kodu, dalsi krok odstranit nebud prepsani
  // validationFailure: getValidationFailure(state),
  seeds: getSeeds(state),
  isFetchingSeeds: getIsFetchingSeeds(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      // TODO docasne komentovani kodu, dalsi krok odstranit nebud prepsani
      // validateAction,
      // resetValidationFailure,
      fetchCropUsage,
      fetchHarvestProduct,
      fetchOtherActionTypes,
      resetCropUsageApi,
      resetHarvestProductsApi,
      resetOtherActionTypesApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ActionOther);
