import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const FIELD_MARGIN = 16;

export default makeStyles((theme: Theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 300,
    overflow: 'auto',
  },
  dislaimer: {
    marginBottom: 24,
  },
  formRow: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignContent: 'flex-end',
    marginBottom: 16,
  },
  farmInfo: {
    height: 'fit-content',
    alignSelf: 'center',
    fontSize: 13,
    marginRight: 'auto',
  },
  farmName: {
    fontWeight: 500,
    margin: 0,
  },
  farmId: {
    color: theme.palette.grey[400],
    margin: 0,
  },
  companyId: {
    maxWidth: 120,
    marginRight: FIELD_MARGIN,
    '& .MuiInput-root': {
      fontSize: '13px',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'scale(0.8)',
    },
  },
  catalogue: {
    width: 120,
    marginRight: FIELD_MARGIN,
    '& .MuiInput-root': {
      fontSize: '13px !important',
    },
  },
  economicSystem: {
    marginRight: FIELD_MARGIN,
    width: 180,
    '& .MuiInput-root': {
      fontSize: '13px !important',
    },
  },
  economicSystemDate: {
    width: 180,
    '& .MuiInput-root': {
      fontSize: '13px !important',
    },
  },
  menuItem: {
    fontSize: '13px !important',
  },
  errorMessage: {
    fontSize: 12,
    lineHeight: '18px',
    color: theme.palette.error.main,
    margin: '6px 0',
  },
}));
