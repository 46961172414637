import { AnyAction } from 'redux';

import * as types from './monitoring.constants';

export const setMonitoringInterval = (
  dateFrom: string,
  dateTo: string,
) => (
  dispatch: (action: AnyAction) => void,
) =>
  dispatch({
    type: types.SET_MONITORING_INTERVAL,
    dateFrom,
    dateTo,
  });

export const resetMonitoringInterval = () => (
  dispatch: (action: AnyAction) => void,
) =>
  dispatch({
    type: types.RESET_MONITORING_INTERVAL,
  });

export const setMonitoringIndex = (index: string) => (
  dispatch: (action: AnyAction) => void,
) =>
  dispatch({
    type: types.SET_MONITORING_INDEX,
    index,
  });
