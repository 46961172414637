import React, { FC, ReactNode, ReactChild, useState, useContext } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { Theme } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';

import CfDialog from '../../../../shared/components/common/CfDialog/CfDialog';
import CfTableRowToolButton from '../../../../shared/components/tables/CfTableRowToolButton/CfTableRowToolButton';
import CfTableRowTools from '../../../../shared/components/tables/CfTableRowTools/CfTableRowTools';
import { SnackbarContext } from '../../../../shared/containers/SnackbarProvider/SnackbarProvider';
import { CataloguesContext } from '../../../containers/CataloguesWrapper/CataloguesWrapper';

import { Catalogue } from '../../../../shared/api/telematics/telematics.types';

interface Props {
    children: ReactNode;
    dialogContent: ReactChild;
    dialogTitle: string;
    isHistorical?: boolean;
}

type SuccessResolve = {
  handleSuccessFn: () => void;
}

type ErrorResolve = {
  messageId: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    transform: 'scale(1)',
    '&:hover $editButton': {
      visibility: 'visible',
    },
    '& td': {
      color: (isHistorical: boolean) => isHistorical && `${theme.palette.error.main} !important`,
    },
    '& td:first-child': {
      paddingLeft: theme.spacing(2),
    },
  },
  editButton: {
    visibility: 'hidden',
    '& svg': {
      color: (isHistorical: boolean) => isHistorical && theme.palette.grey[400],
    },
  },
}));

const TCRow: FC<Props> = ({
  children,
  dialogContent,
  dialogTitle,
  isHistorical = false,
}) => {
  const classes = useStyles(isHistorical);
  const [showEditDialog, setEditDialog] = useState(false);
  const { errors, resetForm, submitForm } = useFormikContext();
  const { catalogueType } = useContext(CataloguesContext);
  const showSnackbar = useContext(SnackbarContext);

  const tooltipId = isHistorical ? 'Catalogues.table.shared.editTooltip' : 'Catalogues.table.shared.disabledEditTooltip';
  const allowEditing = catalogueType !== Catalogue.WINFAS;

  const handleSubmitSuccess = ({ handleSuccessFn }: SuccessResolve) => {
    handleSuccessFn();
    setEditDialog(false);
  };
  const handleSubmitError = ({ messageId = 'Catalogues.table.shared.editError' }: ErrorResolve) => {
    showSnackbar({ message: <FormattedMessage id={messageId} />, isError: true });
  };

  return (
    <TableRow className={classes.row}>
      {children}
      {allowEditing &&
        <CfTableRowTools
          toolsClass={classes.editButton}
        >
          <CfTableRowToolButton
            icon={<EditIcon />}
            onClick={() => !isHistorical && setEditDialog(true)}
            tooltipMessage={<FormattedMessage id={tooltipId} />}
          />
        </CfTableRowTools>
      }
      <CfDialog
        acceptText={<FormattedMessage id="common.save" />}
        cancelText={<FormattedMessage id="common.cancel" />}
        opened={showEditDialog}
        title={<FormattedMessage id={dialogTitle} />}
        onAccept={() => {
          if (!Object.keys(errors).length) {
            // @ts-ignore
            submitForm().then(handleSubmitSuccess, handleSubmitError);
          }
        }}
        onCancel={() => {
          resetForm();
          setEditDialog(false);
        }}
      >
        {dialogContent}
      </CfDialog>
    </TableRow>
  );
};

export default TCRow;
