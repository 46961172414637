import React, { Suspense } from 'react';

import PropTypes from 'prop-types';

import { LANG_KEYS } from '../../../../shared/lang/lang.constants';

import CfLoader from '../../../../shared/components/common/CfLoader/CfLoader';
import BaseContainer from '../../../../shared/containers/BaseContainer/BaseContainer';
import translationsCZ from '../../lang/locale-cs-CZ.json';
import translationsEL from '../../lang/locale-el-GR.json';
import translationsEN from '../../lang/locale-en-US.json';
import translationsES from '../../lang/locale-es-ES.json';
import translationsRO from '../../lang/locale-ro-RO.json';
import translationsRU from '../../lang/locale-ru-UA.json';
import translationsSR from '../../lang/locale-sr-LATN-RS.json';
import Signup from '../Signup/Signup';

const translations = {
  [LANG_KEYS.CZ]: translationsCZ,
  [LANG_KEYS.EN]: translationsEN,
  [LANG_KEYS.SR]: translationsSR,
  [LANG_KEYS.ES]: translationsES,
  [LANG_KEYS.RU]: translationsRU,
  [LANG_KEYS.EL]: translationsEL,
  [LANG_KEYS.RO]: translationsRO,
};

const SignupWrapper = props => (
  <BaseContainer langId={props.langId} translations={translations}>
    <Suspense fallback={<CfLoader />}>
      <Signup {...props} />
    </Suspense>
  </BaseContainer>
);

SignupWrapper.propTypes = {
  langId: PropTypes.string.isRequired,
};

export default SignupWrapper;
