import React, { FC } from 'react';

interface ManagementZoneIconProps {
    color: string,
    display?: string,
    rx?: number,
    size?: number,
    useStroke?: boolean
  }

const ManagementZoneIcon: FC<ManagementZoneIconProps> = ({
  color,
  display = 'inline',
  rx = 2,
  size = 10,
  useStroke = false,
}) => (
  <svg
    display={display}
    fill="none"
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill={`#${color}`} height={size} rx={rx} stroke="#E0E0E0" strokeWidth={useStroke ? 1 : 0} width={size} />
  </svg>
);

export default ManagementZoneIcon;
