import React, { FC, useState, useCallback } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { TableRow } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import CfTableCell from '../../../../shared/components/tables/CfTableCell/CfTableCell';
import CfTableRowToolButton from '../../../../shared/components/tables/CfTableRowToolButton/CfTableRowToolButton';
import CfTableRowTools from '../../../../shared/components/tables/CfTableRowTools/CfTableRowTools';
import Countries from '../../../../shared/services/Countries.service';

import EditDialog from './EditDialog';

import { AdminFarmTo } from '../../../../shared/api/agroevidence/agroevidence.types';

interface Props {
    item: AdminFarmTo;
}

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    transform: 'scale(1)',
    '&:hover $editButton': {
      visibility: 'visible',
    },
  },
  cellMultipleRows: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 16,
  },
  farmName: {
    fontWeight: 500,
  },
  greyText: {
    color: theme.palette.grey[400],
  },
  editButton: {
    visibility: 'hidden',
  },
  flag: {
    fontSize: 20,
  },
}));

const Row: FC<Props> = ({ item }) => {
  const classes = useStyles();
  const [showEditDialog, setEditDialog] = useState(false);

  const handleEditDialogOpen = useCallback(() => setEditDialog(true), [setEditDialog]);
  const handleEditDialogClose = useCallback(() => setEditDialog(false), [setEditDialog]);

  return (
    <TableRow className={classes.row}>
      <CfTableCell name="companyName">
        <div className={classes.cellMultipleRows}>
          <span className={classes.farmName}>{item.name}</span>
          <span className={classes.greyText}>{item.id}</span>
        </div>
      </CfTableCell>
      <CfTableCell classes={{ root: classes.flag }} name="countryCode">{Countries.countryToFlag(item.countryCode)}</CfTableCell>
      <CfTableRowTools toolsClass={classes.editButton}>
        <CfTableRowToolButton
          icon={<EditIcon />}
          onClick={handleEditDialogOpen}
          tooltipMessage={<FormattedMessage id="TelematicsFarmsAdmin.edit.tooltip" />}
        />
      </CfTableRowTools>
      <EditDialog
        farm={item}
        handleClose={handleEditDialogClose}
        opened={showEditDialog}
      />
    </TableRow>
  );
};

export default Row;
