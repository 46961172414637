import React from 'react';

const useToggle = (initialState = false) => {
  const [on, _setOn] = React.useState(initialState);

  const reset = React.useCallback(() => {
    _setOn(initialState);
  }, [initialState]);

  const setOff = React.useCallback(() => {
    _setOn(false);
  }, []);

  const setOn = React.useCallback(() => {
    _setOn(true);
  }, []);

  const toggle = React.useCallback(() => {
    _setOn(cur => !cur);
  }, []);

  return {
    on,
    reset,
    setOff,
    setOn,
    toggle,
  };
};

export { useToggle };
