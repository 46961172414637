/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from 'react';

export const useDidUpdate = (callback: () => void, deps: any[]) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) callback();
    else didMountRef.current = true;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
