import React, { useState } from 'react';

import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getTelematicsAggregationsSelectedRides } from '../../selectors/telematicsAggregations.selectors';

import { Thunk } from '../../../types';

import BulkMainSelector from './BulkMainSelector';
import SecondarySelector from './SecondarySelector';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';

const useStyles = makeStyles(() => ({
  filters: {
    display: 'flex',
    alignItems: 'flex-start',
    minHeight: 68,
  },
}));

type Props = {
  selectedRecords: string[];
}

const TableBulkFilters: React.FC<Props> = ({ selectedRecords }) => {
  const classes = useStyles();

  const [mainSelected, setMainSelected] = useState<number>(0);

  return (
    <div className={classes.filters}>
      <BulkMainSelector
        selected={mainSelected}
        selectedRecords={selectedRecords}
        setMainSelected={setMainSelected}
      />
      <SecondarySelector mainSelected={mainSelected} />
    </div>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  selectedRecords: getTelematicsAggregationsSelectedRides(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {

    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TableBulkFilters);
