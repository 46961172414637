import { ReportsState } from '../../../../reducers/reports.reducer.types';

export const getErrorInfo = (state: ReportsState) =>
  state.api.eagriReport.errorFertilizerUsageReport?.error;
export const getErrorDetailedMessage = (state: ReportsState) =>
  state.api.eagriReport.errorFertilizerUsageReport?.detailedMessage;
export const getIsFertilizerUsageReport = (state: ReportsState) => state.api.eagriReport.isFertilizerUsageReport;
export const getPorErrorInfo = (state: ReportsState) =>
  state.api.eagriReport.errorPorUsageReport?.error;
export const getPorErrorDetailMessage = (state: ReportsState) =>
  state.api.eagriReport.errorPorUsageReport?.detailedMessage;
export const getIsPorUsageReportLoading = (state: ReportsState) =>
  state.api.eagriReport.isPorUsageReportLoading;
export const getPorHistoryReport = (state: ReportsState) => state.api.eagriReport.porHistoryReport;
export const getTotalCount = (state: ReportsState) => state.api.eagriReport.porTotalCount;
export const getIsPorReportHistoryIsLoading = (state: ReportsState) => state.api.eagriReport.isPorUsageReportLoading;
