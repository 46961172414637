import React from 'react';

import { Table, TableBody, TableContainer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';

import TableBodyRow from './TableBodyRow';
import TableBodyRowKMG from './TableBodyRowKMG';
import TableBodyRowPercentage from './TableBodyRowPercentage';
import TableBodyRowPH from './TableBodyRowPH';
import TableHeader from './TableHeader';
import TableHeaderKMG from './TableHeaderKMG';
import TableHeaderPercentage from './TableHeaderPercentage';
import TableHeaderPH from './TableHeaderPH';

const useStyles = makeStyles(() => ({
  table: {
    marginBottom: 20,
    width: 'auto',
  },
}));

const createDataTable = (
  name: string,
  low: string,
  compliant: string | null,
  good: string,
  high: string | null,
  veryHigh: string,
) => ({ name, low, compliant, good, high, veryHigh });

const createPHDataTable = (
  name: string,
  extremly_acid: string,
  strongly_acid: string,
  medium_acid: string,
  slightly_acid: string,
  neutral: string,
  alkaline: string,
  strongly_alkaline: string,
) => ({ name, extremly_acid, strongly_acid, medium_acid, slightly_acid, neutral, alkaline, strongly_alkaline });

const createPercentageDataTable = (
  name: string,
  veryLow: string,
  low: string,
  medium: string,
  high: string,
  veryHigh: string,
) => ({ name, veryLow, low, medium, high, veryHigh });

const createKMgDataTable = (
  name: string,
  nonCompliant: string,
  compliant: string,
  good: string,
) => ({ name, nonCompliant, compliant, good });

const ValuesInfoDialog = () => {
  const classes = useStyles();
  const intl = useIntl();

  const rowsFirstTable = [
    createDataTable(`Ca – ${intl.formatMessage({ id: 'SoilSamples.CA' })}`, '< 900', '901–1400', '1401–2100', '2101–3000', '> 3001'),
    createDataTable(`K – ${intl.formatMessage({ id: 'SoilSamples.K' })}`, '< 105', '106–170', '171–310', '311–420', '> 420'),
    createDataTable(`Mg – ${intl.formatMessage({ id: 'SoilSamples.MG' })}`, '< 105', '106–160', '161–265', '266–330', '> 330'),
    createDataTable(`P – ${intl.formatMessage({ id: 'SoilSamples.P' })}`, '< 55', '56–85', '86–125', '126–200', '> 200'),
    createDataTable(`S – ${intl.formatMessage({ id: 'SoilSamples.S' })}`, '< 10', '11–20', '21–30', '31–40', '> 40'),
  ];

  const rowsSecondTable = [
    createDataTable(`B – ${intl.formatMessage({ id: 'SoilSamples.B' })}`, '< 0,70', null, '0,71–1,00', null, '> 1,00'),
    createDataTable(`Fe – ${intl.formatMessage({ id: 'SoilSamples.FE' })}`, '< 60,0', null, '60,01–420,0', null, '> 420,00'),
    createDataTable(`Zn – ${intl.formatMessage({ id: 'SoilSamples.ZN' })}`, '< 2,20', null, '2,21–5,00', null, '> 5,00'),
  ];

  const rowsPHTable = [
    createPHDataTable('pH', '< 4,5', '4,6–5,00', '5,1–5,5', '5,6–6,5', '6,6–7,2', '7,3–7,7', '> 7,7'),
  ];

  const rowsPercentageTable = [
    createPercentageDataTable(`${intl.formatMessage({ id: 'SoilSamples.COx' })}`, '< 0,6', '0,6–1,1', '1,2–1,7', '1,8–2,9', '> 2,9'),
    createPercentageDataTable(`${intl.formatMessage({ id: 'SoilSamples.HUMUS' })}`, '< 1,0', '1,0–2,0', '2,1–3,0', '3,1–5,0', '> 5,0'),
  ];

  const rowsKMgTable = [
    createKMgDataTable(`${intl.formatMessage({ id: 'SoilSamples.KMG' })}`, '> 3,2', '1,6–3,2', '< 1,6'),
  ];

  return (
    <TableContainer>
      <Table classes={{ root: classes.table }}>
        <TableHeaderPH />
        <TableBody>
          {rowsPHTable.map((row, i) => (
            <TableBodyRowPH index={i} item={row} key={i} />
          ))}
        </TableBody>
      </Table>
      <Table classes={{ root: classes.table }}>
        <TableHeaderPercentage />
        <TableBody>
          {rowsPercentageTable.map((row, i) => (
            <TableBodyRowPercentage index={i} item={row} key={i} />
          ))}
        </TableBody>
      </Table>
      <Table classes={{ root: classes.table }}>
        <TableHeader />
        <TableBody>
          {rowsFirstTable.map((row, i) => (
            <TableBodyRow index={i} item={row} key={i} />
          ))}
        </TableBody>
      </Table>
      <Table classes={{ root: classes.table }}>
        <TableHeader lessValueLevels />
        <TableBody>
          {rowsSecondTable.map((row, i) => (
            <TableBodyRow index={i} item={row} key={i} />
          ))}
        </TableBody>
      </Table>
      <Table classes={{ root: classes.table }}>
        <TableHeaderKMG />
        <TableBody>
          {rowsKMgTable.map((row, i) => (
            <TableBodyRowKMG index={i} item={row} key={i} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ValuesInfoDialog;
