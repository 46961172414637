import React, { FC, useEffect, useContext } from 'react';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getDrivers, getIsFetchingDrivers, getDriversCount } from '../../../selectors/telematicsCatalogues.selectors';

import { fetchDrivers } from '../../../actions/telematicsCatalogues.actions';

import { getSelectedNamespace, setSelectedNamespace } from '../../../reducers/namespace.reducer';

import { Thunk } from '../../../../types';
import { CataloguesContext } from '../../../containers/CataloguesWrapper/CataloguesWrapper';
import TCTable from '../shared/TCTable';
import TCTableToolbar from '../shared/TCTableToolbar';

import { getColumnsByCatalogueType } from './drivers.columns';
import DriversListRow from './DriversListRow';

import { CataloguesState, CATALOGUES_NAMESPACES } from '../../../../reducers/catalogues.reducer.types';
import { DriverTo } from '../../../../shared/api/telematics/telematics.types';

interface Props {
    count: number;
    drivers: DriverTo[];
    fetchDrivers: () => void;
    isFetching: boolean;
    namespace: CATALOGUES_NAMESPACES;
    setNamespace: (namespace: CATALOGUES_NAMESPACES) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
}));

const DriversList: FC<Props> = ({
  count,
  drivers,
  fetchDrivers,
  isFetching,
  namespace,
  setNamespace,
}) => {
  const { catalogueType, langId } = useContext(CataloguesContext);
  const classes = useStyles();

  useEffect(() => {
    setNamespace(CATALOGUES_NAMESPACES.DRIVERS);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDrivers();
  }, [fetchDrivers, langId]);

  if (namespace !== CATALOGUES_NAMESPACES.DRIVERS) return null;

  return (
    <div className={classes.wrapper}>
      <TCTableToolbar
        handleOnChangeTrigger={fetchDrivers}
        helperTextId="Catalogues.table.drivers.search"
      />
      <TCTable
        columns={getColumnsByCatalogueType(catalogueType)}
        count={count}
        handleOnChangeTrigger={fetchDrivers}
        hasData={!!drivers.length}
        isFetching={isFetching}
      >
        {drivers.map((driver) => (
          <DriversListRow data={driver} key={driver.id} onEditSuccess={fetchDrivers} />
        ))}
      </TCTable>
    </div>
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  drivers: getDrivers(state),
  isFetching: getIsFetchingDrivers(state),
  count: getDriversCount(state),
  namespace: getSelectedNamespace(state),
});

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) => bindActionCreators({
  fetchDrivers,
  setNamespace: setSelectedNamespace,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DriversList);
