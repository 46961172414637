/* eslint-disable no-console */
// eslint-disable-next-line no-console

import isEqual from 'lodash/isEqual';

import LocalStorage from './LocalStorage.service';

const key = 'UI_CACHE';
const isDevelopment = process.env.NODE_ENV === 'development';

type Cache = Record<string, unknown>;

interface CacheStructure {
  [farmId: string]: {
    [sectionName: string]: Cache;
  }
}

export default class UiCacheService {
  private static farmId: string;

  static initialize(farmId: string) {
    this.farmId = farmId;
  }

  static storeSettings(sectionName: string, data: Cache): void {
    const oldData = UiCacheService.getCache() || {};
    const newData: CacheStructure = {
      ...oldData,
      [this.farmId]: {
        ...oldData[this.farmId],
        [sectionName]: {
          ...data,
        },
      },
    };

    LocalStorage.saveToLocalStorage(newData, key);
  }

  static getCache(): CacheStructure | null {
    return LocalStorage.loadFromLocalStorage(key);
  }

  static getCachePerSection(sectionName: string): Cache | null {
    const data = UiCacheService.getCache();
    if (!data) return null;
    const farmData = data[this.farmId];
    if (!farmData) return null;
    return farmData[sectionName] ? farmData[sectionName] : null;
  }

  static isCacheExpired(sectionName: string, newData: Cache): boolean {
    const cachedData = UiCacheService.getCachePerSection(sectionName);
    return !isEqual(newData, cachedData);
  }

  /** ******************************************************************************* */

  static deleteParams(): void {
    if (isDevelopment) console.info('deleting cache');
    LocalStorage.removeFromLocalStorage(key);
  }

  static storeParams(sectionName: string, params: Cache): void {
    if (isDevelopment) console.info('storing cache, key:', sectionName, params);
    if (UiCacheService.isCacheExpired(sectionName, params)) {
      UiCacheService.storeSettings(sectionName, params);
    }
  }

  static retrieveParams(sectionName: string): Cache | null {
    return UiCacheService.getCachePerSection(sectionName);
  }
}
