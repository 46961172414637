import React, { FC } from 'react';

import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { PRECISION_URLS } from '../precision.constants';

import { PRECISION_TABS } from '../containers/PrecisionDetail';

import { PrecisionParcel } from '../../../shared/api/sentinel/precision/precision.types';

export const PRECISION_SERVICES_BUTTON = [
  {
    code: 'historyPotential',
    tab: 'management',
    label: 'PrecisionTableService.management',
    activeColor: '#EBA63F',
    hoverColor: '#E49218',
  },
  {
    code: 'biomonitoring',
    tab: 'monitoring',
    label: 'PrecisionTableService.monitoring',
    activeColor: '#4CAC84',
    hoverColor: '#3F8E6D',
  },
  {
    code: 'soilSamples',
    tab: 'soilSamples',
    label: 'PrecisionTableService.soilSamples',
    activeColor: '#A16A00',
    hoverColor: '#734C00',
  },
  {
    code: 'yieldMaps',
    tab: 'yieldMaps',
    label: 'PrecisionTableService.yieldMaps',
    activeColor: '#8100A1',
    hoverColor: '#5C0073',
  },
];

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'start',
    marginLeft: '15px',
  },
  button: {
    height: '21px',
    marginRight: '6px',
    fontSize: '14px',
    fontWeight: 400,
    whiteSpace: 'nowrap',
    textTransform: 'none',
    padding: '6px 4px',
  },
}));

export interface PrecisionTableServiceIndicatorsProps {
  farmId: string,
  forceReloadOnClick?: boolean,
  parcel: PrecisionParcel
}

export const PrecisionTableServiceIndicators: FC<PrecisionTableServiceIndicatorsProps> = ({
  farmId,
  forceReloadOnClick = false,
  parcel,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleGoToServiceTab = (tab: string, evt: React.MouseEvent) => {
    evt.stopPropagation();
    history.push(`/farm/${farmId}/${PRECISION_URLS.services()}/${parcel.id}/${PRECISION_TABS[tab].path}`);
    if (forceReloadOnClick) {
      history.go(0);
    }
  };

  return (
    <Box className={classes.container}>
      {PRECISION_SERVICES_BUTTON.map(button => {
        const isActive = parcel[button.code as keyof PrecisionParcel];
        return (
          <Button
            className={classes.button}
            disabled={!isActive}
            key={button.code}
            name={button.code}
            onClick={(evt: React.MouseEvent) => handleGoToServiceTab(button.tab, evt)}
            sx={{
              backgroundColor: isActive ? button.activeColor : '#E0E0E0',
              color: isActive ? '#FFFFFF' : '#848484',
              '&:hover': {
                backgroundColor: button.hoverColor,
                color: '#FFFFFF',
              },
            }}
          >
            <FormattedMessage id={button.label} />
          </Button>
        );
      },
      )}
    </Box>
  );
};

export default PrecisionTableServiceIndicators;
