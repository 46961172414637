const PREFIX = 'TELEMATICS_ADMIN';

export const GET_ACCOUNT_FARMS_REQUEST = `${PREFIX}/GET_ACCOUNT_FARMS_REQUEST`;
export const GET_ACCOUNT_FARMS_SUCCESS = `${PREFIX}/GET_ACCOUNT_FARMS_SUCCESS `;
export const GET_ACCOUNT_FARMS_ERROR = `${PREFIX}/GET_ACCOUNT_FARMS_ERROR`;

export const GET_COUNTRY_CODES = `${PREFIX}/GET_COUNTRY_CODES_REQUEST`;
export const GET_COUNTRY_CODES_SUCCESS = `${PREFIX}/GET_COUNTRY_CODES_SUCCESS `;
export const GET_COUNTRY_CODES_ERROR = `${PREFIX}/GET_COUNTRY_CODES_ERROR`;

export const UPDATE_ACCOUNT_FARMS_REQUEST = `${PREFIX}/UPDATE_ACCOUNT_FARMS_REQUEST`;
export const UPDATE_ACCOUNT_FARMS_SUCCESS = `${PREFIX}/UPDATE_ACCOUNT_FARMS_SUCCESS `;
export const UPDATE_ACCOUNT_FARMS_ERROR = `${PREFIX}/UPDATE_ACCOUNT_FARMS_ERROR`;
