import React from 'react';

import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import Localization from '../services/Localization.service';

export const formikDateValidRequired = (value) => required(value) || isValidDate(value);

export const formikTextFieldPositiveNumberRequired = (value) => ((value === '' || (Number(value) < 0)) ? <FormattedMessage id="validation.required" /> : undefined);

export const isValidDate = value => ((moment.isMoment(value) && value.isValid()) ? undefined : <FormattedMessage id="validation.invalidDate" />);

export const required = value => (value ? undefined : <FormattedMessage id="validation.required" />);

export const maxLength = max => value =>
  (value && value.length > max ? <FormattedMessage id="validation.maxCharacters" values={{ value: max }} /> : undefined);

export const maxLength8 = maxLength(8);

export const minLength = min => value =>
  (value && value.length < min ? <FormattedMessage id="validation.minCharacters" values={{ value: min }} /> : undefined);

export const minLength2 = minLength(2);
export const minLength6 = minLength(6);

export const minValue = min => value =>
  (value && value < min ? <FormattedMessage id="validation.minValue" values={{ value: min }} /> : undefined);

export const minValue18 = minValue(18);

export const number = value =>
  (typeof value !== 'number' ? <FormattedMessage id="validation.number" /> : undefined);

export const localizedNumber = locale => value => {
  const normalizedValue = Localization.str2numNonFixed(value, locale);
  return number(normalizedValue);
};

export const localizedNumberRequired = locale => value => {
  const hasNoValue = required(value);
  if (hasNoValue) {
    return hasNoValue;
  }

  const normalizedValue = Localization.str2numNonFixed(value, locale);
  return number(normalizedValue);
};

export const requiredNumber = value => ((!value && value !== 0) || isNaN(Number(value)) ? <FormattedMessage id="validation.required" /> : undefined);

export const positiveNumber = value =>
  (value && (isNaN(Number(value)) || Number(value) <= 0) ? (
    <FormattedMessage id="validation.positiveNumber" />
  ) : (
    undefined
  ));

export const requiredAndPositiveNumber = value =>
  (value && Number(value) > 0 && !isNaN(Number(value)) ? undefined : <FormattedMessage id="validation.positiveNumber" />);

export const zeroPositiveNumber = value =>
  (value && (isNaN(Number(value)) || Number(value) < 0) ? (
    <FormattedMessage id="validation.positiveNumber" />
  ) : (
    undefined
  ));

export const requiredAndZeroPositiveNumber = value =>
  ((!value && value !== 0) || (value && (isNaN(Number(value)) || Number(value) < 0)) ? (
    <FormattedMessage id="validation.positiveNumber" />
  ) : (
    undefined
  ));

export const email = value =>
  (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? (
    <FormattedMessage id="validation.email" />
  ) : (
    undefined
  ));

export const alphaNumeric = value =>
  (value && /[^a-zA-Z0-9 ]/i.test(value) ? <FormattedMessage id="validation.alphanumeric" /> : undefined);

export const noWhitespace = value =>
  (value && !/^\S+$/i.test(value) ? <FormattedMessage id="validation.noWhitespace" /> : undefined);

export const requiredAndEmail = value => {
  const requiredResult = required(value);
  if (!requiredResult) {
    return email(value);
  }
  return requiredResult;
};
