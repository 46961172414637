import React, { CSSProperties, FC, ReactNode } from 'react';

import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getIsFetchingPrecisionParcels, getPrecisionParcelsSortedPaginated, getTotalCount } from '../../../shared/api/sentinel/precision/precision.selectors';
import { getPrecisionParcelListOrder, getPrecisionParcelListOrderBy, getPrecisionParcelListPage, getPrecisionParcelListRowsPerPage } from '../selectors/precisionParcelList.selectors';

import { PRECISION_URLS } from '../precision.constants';

import { NAMESPACE as namespace } from '../reducer/precisionParcelList.reducer';

import CfFormattedNumber from '../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import CfTableBodyEmpty from '../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty';
import CfTableBodyLoader from '../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader';
import CfTableCell from '../../../shared/components/tables/CfTableCell/CfTableCell';
import CfTableWrapper from '../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableFooter from '../../../shared/containers/CfTableFooter/CfTableFooter';
import CfTableHead from '../../../shared/containers/CfTableHead/CfTableHead';
import PrecisionTableServiceIndicators from '../components/PrecisionTableServiceIndicators';

import { PRECISION_TABS } from './PrecisionDetail';

import { PrecisionState } from '../../../reducers/precision.reducer.types';
import { PrecisionParcel } from '../../../shared/api/sentinel/precision/precision.types';

const getColDesc = (sortable: boolean, label: ReactNode, style?: CSSProperties, rightPadding?: boolean) => ({
  align: 'inherit',
  sortable,
  label,
  style,
  rightPadding,
});

const columns = {
  localName: getColDesc(
    true,
    <FormattedMessage id="common.name" />,
    { paddingLeft: 21 },
  ),
  blockNr: getColDesc(
    false,
    <FormattedMessage id="common.short-code" />,
    { textAlign: 'right' }),
  area: getColDesc(
    false,
    <span>
      <FormattedMessage id="common.area" /> (ha)
    </span>,
    { textAlign: 'right' }),
  services: getColDesc(false, undefined),
};

const useStyles = makeStyles(() => ({
  name: {
    paddingLeft: 15,
    fontWeight: 500,
  },
  pointer: {
    cursor: 'pointer',
  },
  column: {
    textAlign: 'right',
  },
}));

export interface PrecisionTableProps {
  count: number;
  farmId: string;
  isFetching: boolean;
  langId: string,
  order: string;
  orderBy: string;
  page: number;
  parcels: PrecisionParcel[],
  rowsPerPage: number;
}

export const PrecisionServicesTable: FC<PrecisionTableProps> = ({
  count,
  farmId,
  isFetching,
  langId,
  order,
  orderBy,
  page,
  parcels,
  rowsPerPage,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleRowClick = (parcel: PrecisionParcel) => {
    let tabPath;
    if (parcel.historyPotential) {
      tabPath = PRECISION_TABS.management.path;
    } else if (parcel.biomonitoring) {
      tabPath = PRECISION_TABS.monitoring.path;
    } else if (parcel.soilSamples) {
      tabPath = PRECISION_TABS.soilSamples.path;
    } else if (parcel.yieldMaps) {
      tabPath = PRECISION_TABS.yieldMaps.path;
    }

    history.push(`/farm/${farmId}/${PRECISION_URLS.services()}/${parcel.id}/${tabPath}`);
  };

  return (
    <CfTableWrapper testId="precision-services-list">
      <CfTableHead
        columns={columns}
        items={parcels}
        langId={langId}
        namespace={namespace}
        order={order}
        orderBy={orderBy}
      />
      {isFetching && <CfTableBodyLoader columns={columns} />}

      {parcels.length ? (
        <TableBody>
          {parcels.map((parcel) => (
            <TableRow
              className={classes.pointer}
              hover
              key={parcel.id}
              onClick={() => handleRowClick(parcel)}
            >
              <CfTableCell name={'name'}>
                <span className={classes.name}>{parcel.localName}</span>
              </CfTableCell>
              <CfTableCell classes={{ root: classes.column }} name={'blockNr'}>
                <span>{parcel.blockNr}</span>
              </CfTableCell>
              <CfTableCell classes={{ root: classes.column }} name={'area'}>
                <span>
                  <CfFormattedNumber value={parcel.area || 0} />
                </span>
              </CfTableCell>
              <CfTableCell name={'services'}>
                <PrecisionTableServiceIndicators
                  farmId={farmId}
                  parcel={parcel}
                />
              </CfTableCell>
            </TableRow>
          ))}
        </TableBody>
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      )}
      <CfTableFooter count={count} namespace={namespace} page={page} rowsPerPage={rowsPerPage} />
    </CfTableWrapper>
  );
};

const mapStateToProps = (state: PrecisionState) => ({
  parcels: getPrecisionParcelsSortedPaginated(state),
  isFetching: getIsFetchingPrecisionParcels(state),
  page: getPrecisionParcelListPage(state),
  order: getPrecisionParcelListOrder(state),
  orderBy: getPrecisionParcelListOrderBy(state),
  rowsPerPage: getPrecisionParcelListRowsPerPage(state),
  count: getTotalCount(state),
});

export default connect(mapStateToProps)(PrecisionServicesTable);
