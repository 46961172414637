import React, { Fragment, useState } from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Button, TableRow, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedDate, FormattedMessage } from 'react-intl';

import CfTableCell from '../../../../shared/components/tables/CfTableCell/CfTableCell';
import { getShortDateString } from '../../../../shared/misc/timeHelpers';
import { PorUsageReportErrorDialog } from '../PorUsageReport/PorUsageReportErrorDialog';

import { PorUseReportMissingDataBody, ReportHistoryResponse, ReportType } from '../../../../shared/api/agroevidence/agroevidence.types';

const useStyles = makeStyles((theme: Theme) => (
  {
    successIcon: {
      color: theme.palette.primary.main,
    },
    errorIcon: {
      color: theme.palette.error.main,
    },
    pendingIcon: {
      color: theme.palette.grey[500],
    },
    tableRow: {
      paddingLeft: '20px',
    },
    tableErrorColumn: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }
));

interface Props {
    row: ReportHistoryResponse
}

export const PorUsageEvidenceRow = ({ row }: Props) => {
  const classes = useStyles();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const handleErrorDialogClose = () => {
    setIsErrorDialogOpen(false);
  };

  const handleErrorDialogOpen = () => {
    setIsErrorDialogOpen(true);
  };

  function isPorUseReport(response: ReportHistoryResponse):
    response is ReportHistoryResponse & { jsonNote: PorUseReportMissingDataBody } {
    return response.type === ReportType.POR_USE;
  }

  return (
    <Fragment>
      <TableRow className={classes.tableRow} key={row.id}>
        <CfTableCell name={'success'}>
          {
            <Fragment>
              {row.success && <DoneIcon className={classes.successIcon} />}
              {row.success === false && <ErrorOutlineIcon className={classes.errorIcon} />}
              {row.success === undefined && <AccessTimeIcon className={classes.pendingIcon} />}
            </Fragment>
          }
        </CfTableCell>
        <CfTableCell name={'usagePeriod'}>{<FormattedDate month={'long'} value={row.periodTo} year="numeric" />}</CfTableCell>
        <CfTableCell name={'sent'}>{<FormattedDate day={'2-digit'} month={'short'} value={row.started} year="numeric" />}</CfTableCell>
        <CfTableCell name={'error'}>
          <Box className={classes.tableErrorColumn}>
            {(row.note || row.jsonNote) ? <Button
              color="error"
              onClick={handleErrorDialogOpen}
              size="small"
              variant="text"
              >
              <FormattedMessage id="Reports.porUsageReport.errorList.ErrorButton" />
            </Button> : ''
            }
          </Box>
        </CfTableCell>
      </TableRow>

      {isErrorDialogOpen && isPorUseReport(row) &&
        <PorUsageReportErrorDialog
          date={getShortDateString(row.started, 'DD.MM.yyyy')}
          errorDetailedMessage={row.jsonNote}
          onClose={handleErrorDialogClose}
          showErrorDialog={isErrorDialogOpen}
        />}
    </Fragment>
  );
};
