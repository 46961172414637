/* eslint-disable react/no-danger */
import React, { FC } from 'react';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import CfDialog from '../../../../../shared/components/common/CfDialog/CfDialog';

import { ValidationResultTo, ViolationTo } from '../../../../../shared/api/agroevidence/agroevidence.types';

const useStyles = makeStyles((theme: Theme) => ({
  errorWrapper: {
    marginBottom: '20px',
    lineHeight: 1.2,
  },
  errorMessage: {
    color: theme.palette.secondary.main,
    marginBottom: '10px',
  },
  errorText: {
    fontSize: 14,
  },
}));

interface Props {
  actionRestrictions?: ValidationResultTo[],
  onBack: () => void,
  opened?: boolean
}

export const ActionRestrictionsDetailsDialog: FC<Props> = ({
  opened = false,
  actionRestrictions = [],
  onBack,
}) => {
  const classes = useStyles();

  return (
    <CfDialog
      acceptText={<FormattedMessage id="common.close" />}
      onAccept={onBack}
      opened={opened}
      title={<FormattedMessage id="ActionRestrictionDetailsDialog.problems" />}
    >
      <div>
        {actionRestrictions.map((restriction: ValidationResultTo) => (
          restriction.violations.map((violation: ViolationTo, index: number) => (
            <div className={classes.errorWrapper} data-test="restriction-item" key={violation.code}>
              <div className={classes.errorMessage}>
                {index + 1}) {violation.message}
              </div>
              <div className={classes.errorText} dangerouslySetInnerHTML={{ __html: violation.text }} />
            </div>
          ))
        ))}
      </div>
    </CfDialog>
  );
};

export default ActionRestrictionsDetailsDialog;
