import React, { Fragment } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage } from 'react-intl';

import CfFormattedNumber from '../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import { FEATURE_TYPE_PREFIX } from '../../services/FeatureConfig.service';

const styles = theme => ({
  wrapper: {
    fontSize: 13,
    marginLeft: 13,
    marginRight: 13,
  },
  itemLabel: {
    color: theme.palette.grey[500],
  },
  itemValue: {
    marginLeft: 3,
    fontWeight: 500,
  },
});

function SensorChartCumulativeRainfall(props) {
  const { chartProps, classes, dateFrom, dateTo } = props;
  const item = chartProps.statistics[0];

  return (
    <div className={classes.wrapper}>
      {chartProps && item && (
        <span>
          <span className={classes.itemLabel}>
            <FormattedMessage id={`NodeFeature.${item.featureName.replace(FEATURE_TYPE_PREFIX, '')}`} />{' '}
            {dateFrom && dateTo && (
              <Fragment>
                {'('}
                <FormattedDate day="numeric" hour="numeric" minute="numeric" month="numeric" value={dateFrom} year="numeric" />
                {' – '}
                <FormattedDate day="numeric" hour="numeric" minute="numeric" month="numeric" value={dateTo} year="numeric" />
                {')'}
              </Fragment>
            )}
          </span>
          <span className={classes.itemValue} style={{ color: chartProps.stroke }}>
            <CfFormattedNumber decimalDigits={1} value={item.value || 0} /> {chartProps.unit}
          </span>
        </span>
      )}
    </div>
  );
}

SensorChartCumulativeRainfall.propTypes = {
  classes: PropTypes.object.isRequired,
  chartProps: PropTypes.object,
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string.isRequired,
};

SensorChartCumulativeRainfall.defaultProps = {
  chartProps: {},
};

export default withStyles(styles)(SensorChartCumulativeRainfall);
