import React, { FC } from 'react';

import { Field, FormikProps } from 'formik';
import { round } from 'lodash';
import { FormattedMessage } from 'react-intl';

import CfFormControl from '../../../../shared/components/form/CfFormControl/CfFormControl';
import CfFormikNumericTextField from '../../../../shared/components/form/CfFormikNumericTextField/CfFormikNumericTextField';

import { DetailContentFormValues } from '../DetailContentForm.types';

interface Props extends FormikProps<DetailContentFormValues> {
  isEditing: boolean;
}

const DistanceField: FC<Props> = ({ isEditing, setFieldValue, values }) => {
  const handleBlur = () => {
    if (!(Number(values.distance) >= 0)) {
      setFieldValue('distance', undefined);
    } else {
      setFieldValue('distance', round(values.distance, 3));
    }
  };

  return (
    <CfFormControl>
      <Field
        component={CfFormikNumericTextField}
        disabled={!isEditing}
        label={<FormattedMessage id="TelematicsList.operationDistance" />}
        name="distance"
        onBlur={handleBlur}
    />
    </CfFormControl>
  );
};

export default DistanceField;
