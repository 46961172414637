import React, { FC, Fragment, useEffect, useState } from 'react';

import { Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getIsExportingData, getParcelsError } from '../../../../../shared/api/agroevidence/parcels/parcels.selectors';
import { getParcelListTextFilter, getParcelListSelectedOnPage } from '../../selectors/parcelList.selectors';

import { setTableSelected } from '../../../../../shared/actions/table.actions';
import { assignZone, assignCenter } from '../../../shared/actions/parcels.actions';
import { exportParcels } from '../../actions/parcelList.actions';

import { NAMESPACE as namespace } from '../../reducer/parcelList.reducer';

import { CreateUpdateZoneParams, updateZoneApi, createZoneApi, deleteZoneApi } from '../../../../../shared/api/agroevidence/zones/zones.api';
import CfErrorPage from '../../../../../shared/components/common/CfErrorPage/CfErrorPage';
import ExportButton from '../../../../../shared/components/common/ExportButton/ExportButton';
import PageHeader from '../../../../../shared/components/common/PageHeader/PageHeader';
import PageHeading from '../../../../../shared/components/common/PageHeading/PageHeading';
import withWidth from '../../../../../shared/hocs/withWidth';
import { AsyncFn, RsaaApiError, Thunk } from '../../../../../types';
import ParcelListActions from '../../components/ParcelListActions/ParcelListActions';
import ParcelListTextFilter from '../../components/ParcelListTextFilter/ParcelListTextFilter';
import ParcelAdvancedFilter from '../ParcelAdvancedFilter/ParcelAdvancedFilter';
import ParcelTable from '../ParcelTable/ParcelTable';
import ParcelZoneStatistics from '../ParcelZoneStatistics/ParcelZoneStatistics';

import { ParcelsState } from '../../../../../reducers/parcels.reducer.types';
import { ZoneTo } from '../../../../../shared/api/agroevidence/agroevidence.types';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
  textFilter: {
    maxWidth: 400,
    flexGrow: 1,
    order: 2,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
      width: '100%',
      order: 1,
    },
  },
  advancedFilter: {
    marginLeft: 8,
    order: 3,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginBottom: 8,
    },
  },
  listActionsItem: {
    order: 1,
  },
  filtersHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'baseline',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
    },
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

export interface ParcelListProps {
  assignCenter: (centerId: string, parcelId: string) => Promise<void>,
  assignZone: (zoneId: string, parcelId: string) => Promise<void>,
  createZoneApi: (params: CreateUpdateZoneParams) => void,
  deleteZoneApi: (zoneId: string) => void,
  error?: RsaaApiError,
  exportParcels: () => void,
  farmId: string;
  isExporting: boolean,
  langId: string,
  ngRedirectToEph: (selectedOnPage: string[]) => void,
  ngRedirectToHarvest: (selectedOnPage: string[]) => void,
  ngRedirectToMowing: (selectedOnPage: string[]) => void,
  ngRedirectToOtherActionsNew: (selectedOnPage: string[]) => void,
  ngRedirectToSowing: (selectedOnPage: string[]) => void,
  selectedOnPage: string[],
  setTableSelected: (selected: string[], namespace: string) => void,
  textFilter: string,
  updateZoneApi: (zoneId: string, params: CreateUpdateZoneParams) => void
}

export const ParcelList: FC<ParcelListProps> = ({
  assignCenter,
  assignZone,
  createZoneApi,
  deleteZoneApi,
  error,
  exportParcels,
  farmId,
  isExporting,
  langId,
  ngRedirectToEph,
  ngRedirectToHarvest,
  ngRedirectToMowing,
  ngRedirectToOtherActionsNew,
  ngRedirectToSowing,
  selectedOnPage,
  setTableSelected,
  textFilter,
  updateZoneApi,
}) => {
  const classes = useStyles();
  const [shouldReloadData, setShouldReloadData] = useState(false);

  useEffect(() =>
    () => {
      setTableSelected([], namespace);
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  const onAssignZone = (zoneId: string, parcelIds: string[], bulk: boolean) => {
    const promises = [] as Promise<void>[];
    parcelIds.forEach((parcelId: string) => {
      promises.push(assignZone(zoneId, parcelId));
    });
    Promise.all(promises).then(() => {
      setShouldReloadData(prevState => !prevState);
      if (bulk) {
        setTableSelected([], namespace);
      }
    });
  };

  const onAssignCenter = (centerId: string, parcelIds: string[], bulk: boolean) => {
    const promises = [] as Promise<void>[];
    parcelIds.forEach((parcelId: string) => {
      promises.push(assignCenter(centerId, parcelId));
    });
    Promise.all(promises).then(() => {
      setShouldReloadData(prevState => !prevState);
      if (bulk) {
        setTableSelected([], namespace);
      }
    });
  };

  const onCreateZone = (zone: ZoneTo) => {
    const params: CreateUpdateZoneParams = {
      name: zone.name,
    };
    (createZoneApi as AsyncFn<CreateUpdateZoneParams>)(params)
      .then(() => setShouldReloadData(prevState => !prevState));
  };

  const onUpdateZone = (zone: ZoneTo) => {
    const params: CreateUpdateZoneParams = {
      name: zone.name,
    };
    (updateZoneApi as AsyncFn<string, CreateUpdateZoneParams>)(zone.id, params)
      .then(() => setShouldReloadData(prevState => !prevState));
  };

  const onDeleteZone = (zone: ZoneTo) => {
    (deleteZoneApi as AsyncFn<string>)(zone.id)
      .then(() => setShouldReloadData(prevState => !prevState));
  };

  return (
    <CfErrorPage error={error}>
      <div className={classes.wrapper}>
        <PageHeader
          heading={<PageHeading dataTest="parcelDetail-heading" value={<FormattedMessage id="common.parcels" />} />}
          actionButtons={
            <Fragment>
              <div className={classes.exportButton}>
                <ExportButton aria-label="export" handleClick={exportParcels} isLoading={isExporting} />
              </div>
            </Fragment>
          }
          classes={{
            header: classes.header,
          }}
        />
        <Grid container spacing={1}>
          <Grid item lg={9} md={8} xs={12}>
            <Grid container spacing={0}>
              <Grid className={classes.filtersHeader} item xs={12}>
                <div className={classes.listActionsItem}>
                  <ParcelListActions
                    ngRedirectToEph={() => ngRedirectToEph(selectedOnPage)}
                    ngRedirectToHarvest={() => ngRedirectToHarvest(selectedOnPage)}
                    ngRedirectToMowing={() => ngRedirectToMowing(selectedOnPage)}
                    ngRedirectToOtherActionsNew={() => ngRedirectToOtherActionsNew(selectedOnPage)}
                    ngRedirectToSowing={() => ngRedirectToSowing(selectedOnPage)}
                    onAssignCenter={onAssignCenter}
                    onAssignZone={onAssignZone}
                    selected={selectedOnPage}
                    />
                </div>
                <div className={classes.textFilter}>
                  <ParcelListTextFilter namespace={namespace} textFilter={textFilter} />
                </div>
                <div className={classes.advancedFilter}>
                  <ParcelAdvancedFilter langId={langId} namespace={namespace} />
                </div>
              </Grid>
              <Grid item xs={12}>
                <ParcelTable
                  farmId={farmId}
                  langId={langId}
                  onAssignCenter={onAssignCenter}
                  onAssignZone={onAssignZone}
                  shouldReloadData={shouldReloadData}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={3} md={4} xs={12}>
            <Grid container spacing={0}>
              <ParcelZoneStatistics
                onCreateZone={onCreateZone}
                onDeleteZone={onDeleteZone}
                onUpdateZone={onUpdateZone}
                shouldReloadData={shouldReloadData}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </CfErrorPage>
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  textFilter: getParcelListTextFilter(state),
  selectedOnPage: getParcelListSelectedOnPage(state),
  error: getParcelsError(state),
  isExporting: getIsExportingData(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      createZoneApi,
      updateZoneApi,
      deleteZoneApi,
      assignZone,
      assignCenter,
      setTableSelected,
      exportParcels,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(ParcelList));
