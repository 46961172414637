import React from 'react';

import moment from 'moment';
import ReactDOM from 'react-dom';

import reducer from '../../reducers/precision.reducer';

import CfApp from '../../shared/containers/CfApp/CfApp';

import PrecisionWrapper from './containers/PrecisionWrapper';

PrecisionController.$inject = [
  '$element',
  '$scope',
  '$state',
  '$rootScope',
  '$transitions',
  'Localization',
  'BACKEND_OPTIONS',
  'ENVIRONMENT',
  'Auth',
  'ResponseErrorInterceptor',
];

export default function PrecisionController(
  $element,
  $scope,
  $state,
  $rootScope,
  $transitions,
  Localization,
  BACKEND_OPTIONS,
  ENVIRONMENT,
  Auth,
  ResponseErrorInterceptor,
) {
  const props = {
    langId: null,
    farm: this.farm,
    farmId: this.farm.id,
    ngRedirectToSowing,
    ngRedirectToVrf,
    ngRedirectToVrs,
    reducer,
    config: { api: BACKEND_OPTIONS, environment: ENVIRONMENT },
    apiProps: {
      langId: null,
      farmId: this.farm.id,
      beOpts: BACKEND_OPTIONS,
      logout: Auth.logout,
      handleError: ResponseErrorInterceptor.handleRSAAErrorResponse,
      displayErrorModal: false,
    },
    cacheKey: 'precision',
  };

  const unlistenTranslateChange = $rootScope.$on('$translateChangeSuccess', () => {
    renderWithLang();
  });

  const deregisterOnTransitionSuccess = $transitions.onBefore({}, transition => {
    // hack because of behaviour of ui-router vs react-router
    // we need to block transition inside parcels - when we want to switch farm, it is superseded
    const precisionState = 'farm.active.precision';
    const transitionFrom = transition.from();
    const transitionTo = transition.to();
    const transitionParams = transition.params();
    if (
      transitionFrom.name.startsWith(precisionState) &&
      transitionTo.name.startsWith(precisionState) &&
      transitionParams.farmId === this.farm.id
    ) {
      transition.abort();
    }
  });

  $scope.$on('$destroy', () => {
    unlistenTranslateChange();
    deregisterOnTransitionSuccess();
    ReactDOM.unmountComponentAtNode($element[0]);
  });

  this.$onInit = function () {
    setTimeout(() => {
      renderWithLang();
    }, 0);
  };

  const renderWithLang = () => {
    Localization.getCurrentLangIdAsync().then(langId => {
      props.langId = langId;
      props.apiProps.langId = langId;
      moment.locale(langId);
      render();
    });
  };

  function ngRedirectToSowing(parcelIds) {
    $state.go('farm.active.actions.sowing', { parcelIds });
  }

  function ngRedirectToVrf(parcelIds) {
    return $state.go('farm.active.actions.vrf', { parcelIds });
  }

  function ngRedirectToVrs(parcelIds) {
    return $state.go('farm.active.actions.vrs', { parcelIds });
  }

  const render = () => {
    $element[0].click();
    ReactDOM.render(<CfApp {...props} render={p => <PrecisionWrapper {...p} />} />, $element[0]);
  };
}
