const PREFIX = 'AGROEVIDENCE_ACTIONS';

export const POST_SUBTRACTABLE_AREA_REQUEST = `${PREFIX}/POST_SUBTRACTABLE_AREA_REQUEST`;
export const POST_SUBTRACTABLE_AREA_SUCCESS = `${PREFIX}/POST_SUBTRACTABLE_AREA_SUCCESS`;
export const POST_SUBTRACTABLE_AREA_ERROR = `${PREFIX}/POST_SUBTRACTABLE_AREA_ERROR`;

export const UPDATE_PARCELS_SUBTRACTIONS_REQUEST = `${PREFIX}/UPDATE_PARCELS_SUBTRACTIONS_REQUEST`;
export const UPDATE_PARCELS_SUBTRACTIONS_SUCCESS = `${PREFIX}/UPDATE_PARCELS_SUBTRACTIONS_SUCCESS`;
export const UPDATE_PARCELS_SUBTRACTIONS_ERROR = `${PREFIX}/UPDATE_PARCELS_SUBTRACTIONS_ERROR`;

export const GET_PARCEL_HISTORY_SUBTRACTIONS_REQUEST = `${PREFIX}/GET_PARCEL_HISTORY_SUBTRACTIONS_REQUEST`;
export const GET_PARCEL_HISTORY_SUBTRACTIONS_SUCCESS = `${PREFIX}/GET_PARCEL_HISTORY_SUBTRACTIONS_SUCCESS`;
export const GET_PARCEL_HISTORY_SUBTRACTIONS_ERROR = `${PREFIX}/GET_PARCEL_HISTORY_SUBTRACTIONS_ERROR`;
export const RESET_PARCEL_HISTORY_SUBTRACTIONS = `${PREFIX}/RESET_PARCEL_HISTORY_SUBTRACTIONS`;
