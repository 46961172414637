import React, { FC, Fragment, useMemo } from 'react';

import { Radio, Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import RadioGroup from '@mui/material/RadioGroup';
import { makeStyles } from '@mui/styles';
import { FieldInputProps } from 'formik';
import { FormattedMessage } from 'react-intl';

import SubtractableArea from '../SubtractableArea/SubtractableArea';

import { SubtractionResponse } from '../../../../../shared/api/agroevidence/agroevidence.types';

const useStyles = makeStyles((theme: Theme) => ({
  saRow: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

interface SubtractableAreaWithRadioButtonProps {
  field: FieldInputProps<SubtractionResponse>;
  isEditing: boolean,
  options: SubtractionResponse[],
}

const SubtractableAreaWithRadioButton: FC<SubtractableAreaWithRadioButtonProps> = ({
  field,
  isEditing = false,
  options,
}) => {
  const classes = useStyles();

  const optionsWithNone = useMemo(() => ([
    {
      type: 'none',
      value: 0,
      area: 0,
    },
    ...options,
  ]), [options]);

  return (
    <Fragment>
      <RadioGroup data-test={'area-subtraction-list'} {...field} name={field.name}>
        <Grid container>
          {optionsWithNone.map(option => (
            <Grid item key={`radio-${option.type}-${option.value}`} md={3} sm={4} xs={12}>
              <span
                className={classes.saRow}
                data-test={`${option.type.toLowerCase()}-radio-subtraction-item`}
              >
                <Radio
                  checked={field.value?.toString() === option.value?.toString()}
                  color="primary"
                  disabled={!isEditing}
                  value={option.value}
                />
                {option.value === 0 ?
                  <FormattedMessage id="SubtractableArea.none" /> :
                  <SubtractableArea sa={option} />}
              </span>
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </Fragment>
  );
};

export default SubtractableAreaWithRadioButton;
