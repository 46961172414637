import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './drivesImportHistory.constants';

import { RsaaMethods, RsaaTypes } from '../../api.types';

export type GetImportHistoryApiParams = {
  language: string;
  page: number;
  'per-page': number;
}

export type GetImportHistoryStateApiParams = {
  language: string;
}

export const getImportHistoryApi = (params: GetImportHistoryApiParams) => ({
  [RSAA]: {
    endpoint: `import?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_IMPORT_HISTORY_REQUEST,
      types.GET_IMPORT_HISTORY_SUCCESS,
      types.GET_IMPORT_HISTORY_ERROR,
    ] as RsaaTypes,
  },
});

export const resetImportHistoryApi = () => ({
  type: types.RESET_IMPORT_HISTORY,
});

export const getImportHistoryStateApi = (params: GetImportHistoryStateApiParams) => ({
  [RSAA]: {
    endpoint: `import/state?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_IMPORT_HISTORY_STATE_REQUEST,
      types.GET_IMPORT_HISTORY_STATE_SUCCESS,
      types.GET_IMPORT_HISTORY_STATE_ERROR,
    ] as RsaaTypes,
  },
});

export type ImportFilesApiType = {
  files: File[];
}

export const importFilesApi = (params: ImportFilesApiType) => {
  const formData = new FormData();

  params.files.forEach((file) => {
    formData.append('file', file);
  });

  return {
    [RSAA]: {
      endpoint: 'import/isoxml?',
      method: methods.POST as RsaaMethods,
      module: modules.TELEMATICS,
      noContentType: true,
      body: formData,
      types: [
        types.GET_IMPORT_FILES_REQUEST,
        types.GET_IMPORT_FILES_SUCCESS,
        types.GET_IMPORT_FILES_ERROR,
      ] as RsaaTypes,
    },
  };
};

export type ValidateFileApiType = {
  file: File;
}

export const validateFileApi = (params: ValidateFileApiType) => {
  const formData = new FormData();

  formData.append('file', params.file);

  return {
    [RSAA]: {
      endpoint: 'import/isoxml/validate?',
      method: methods.POST as RsaaMethods,
      module: modules.TELEMATICS,
      noContentType: true,
      body: formData,
      types: [
        types.GET_VALIDATE_FILES_REQUEST,
        types.GET_VALIDATE_FILES_SUCCESS,
        types.GET_VALIDATE_FILES_ERROR,
      ] as RsaaTypes,
    },
  };
};
