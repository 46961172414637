import { AnyAction } from 'redux';

import * as types from './agiStatus.constants';

import { AgIntegrationStatus } from './agiStatus.types';

const initialState = {
  email: undefined,
  enabled: false,
};

export default () => (
  state: AgIntegrationStatus = initialState,
  action: AnyAction,
) => {
  switch (action.type) {
    case types.GET_AGINTEGRATION_SUCCESS:
      return {
        ...action.payload,
      };
    case types.POST_AGINTEGRATION_SUCCESS:
      return {
        ...action.payload,
      };
    case types.DELETE_AGINTEGRATION_SUCCESS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
