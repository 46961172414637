import React, { FC, Fragment, useEffect, useState } from 'react';

import { Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getManagementZonesIsFetching,
  getManagementZonesError as getError,
  getManagementZonesIsFetchingActivate,
  getManagementZonesActivated,
  getManagementZonesErrorActivating,
} from '../../../shared/api/sentinel/management/management.selectors';
import {
  getCropQuality,
  getCrops,
  getCropVariability,
  getCropZones,
  getHistorySnaps,
  getManagementZonesStatus,
} from '../selectors/management.selectors';

import { setCropLegislativeCode } from '../actions/management.actions';

import * as satelliteProductsTypes from '../../../shared/constants/satelliteProductsTypes.constants';
import * as services from '../../../shared/constants/services.constants';

import {
  getManagementZones,
  resetManagementZones,
  activateManagementZones,
} from '../../../shared/api/sentinel/management/management.api';
import CfErrorPage from '../../../shared/components/common/CfErrorPage/CfErrorPage';
import CfLoader from '../../../shared/components/common/CfLoader/CfLoader';
import CfStatusPanel from '../../../shared/components/common/CfStatusPanel/CfStatusPanel';
import CfSwitcher from '../../../shared/components/common/CfSwitcher/CfSwitcher';
import ServiceNotPurchased from '../../../shared/components/common/ServiceNotPurchased/ServiceNotPurchased';
import { links } from '../../../shared/constants/links';
import useWidth from '../../../shared/hooks/useWidth';
import SatelliteIcon from '../../../shared/icons/SatelliteIcon';
import { RsaaApiError, Thunk } from '../../../types';
import CropQualityGraph from '../components/ManagementZones/CropQualityGraph';
import CropVariabilityGraph from '../components/ManagementZones/CropVariabilityGraph';
import ManagementHistoryDialog from '../components/ManagementZones/ManagementHistoryDialog';
import ManagementHistoryImage from '../components/ManagementZones/ManagementHistoryImage';
import ManagementZonesMap from '../components/ManagementZones/ManagementZonesMap';
import PrecisionMapModal from '../components/PrecisionMapModal/PrecisionMapModal';
import SectionHeader from '../components/SectionHeader';

import { PrecisionState } from '../../../reducers/precision.reducer.types';
import { Crop, HistorySnap, Zone } from '../../../shared/api/sentinel/management/management.types';

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    fontSize: '18px',
    margin: 0,
  },
  wrapper: {
    marginBottom: '10px',
  },
  headerBar: {
    marginBottom: 5,
  },
  cfSwitcherWrapper: {
    justifyContent: 'flex-start',
  },
  [theme.breakpoints.down('xs')]: {
    heading: {
      textAlign: 'center',
      marginBottom: 10,
    },
  },
}));

export interface CropQualityData {
  zoneColor: string,
  zoneNumber: number,
  zoneValue: number,
}

export interface CropQuality {
  average: number,
  data: CropQualityData[];
}

export interface CropVariability {
  cropName: string,
  date: string,
  involvementImgPath: string,
  managementZoneImgPath: string,
  max: number,
  median: number,
  min: number,
  q1: number,
  q3: number,
}

interface CropZones extends Zone {
  opacity: number,
}

export interface ManagementZonesProps {
  activateManagementZones: (parcelId: string) => void,
  activated: boolean,
  cropQuality: CropQuality,
  cropVariability: CropVariability[],
  cropZones: CropZones[],
  crops: Crop[],
  error?: RsaaApiError,
  errorActivating?: RsaaApiError,
  getManagementZones: (parcelId: string) => void,
  historySnaps: HistorySnap[],
  isFetching: boolean,
  isFetchingActivate: boolean,
  langId: string,
  managementZonesStatus: string,
  parcelId: string,
  resetManagementZones: () => void,
  setCropLegislativeCode: (crop: Crop) => void
}

const ManagementZones: FC<ManagementZonesProps> = ({
  activateManagementZones,
  activated,
  cropQuality,
  cropVariability,
  cropZones,
  crops,
  error,
  errorActivating,
  getManagementZones,
  historySnaps,
  isFetching,
  isFetchingActivate,
  langId,
  managementZonesStatus,
  parcelId,
  resetManagementZones,
  setCropLegislativeCode,
}) => {
  const classes = useStyles();
  const width = useWidth();

  const [geometries, setGeometries] = useState<Zone[] | null>(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() =>
    () => {
      resetManagementZones();
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  useEffect(() => {
    if (crops.length) {
      setCropLegislativeCode(crops[0]);
    }
  }, [crops, setCropLegislativeCode]);

  useEffect(() => {
    getManagementZones(parcelId);
  }, [langId, activated, getManagementZones, parcelId]);

  const setMapForModal = (geometries: Zone[]) => {
    setGeometries(geometries);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <CfErrorPage error={error}>
      {(managementZonesStatus === satelliteProductsTypes.INACTIVE ||
          managementZonesStatus === satelliteProductsTypes.HISTORICAL) && (
          <CfStatusPanel
            customContent={<ServiceNotPurchased serviceId={services.MANAGEMENT_ZONES} />}
            icon={SatelliteIcon}
            linkHref={langId === 'cs-CZ' ? links.cs.precisionFarming : links.en.precisionFarming}
            linkText={<FormattedMessage id="common.findOutMore" />}
            title={<FormattedMessage id="ManagementZones.notPurchased.title" />}
            titleWithIcon={true}
          />
      )}
      {managementZonesStatus === satelliteProductsTypes.AWAITING_USER && (
      <CfStatusPanel
        activated={activated}
        errorText={errorActivating?.isError ? <FormattedMessage id="ManagementZones.cropsHistoryMissing" /> : null}
        icon={SatelliteIcon}
        isFetching={isFetchingActivate}
        linkText={<FormattedMessage id="ManagementZones.activateManagementZones" />}
        onLinkClick={() => activateManagementZones(parcelId)}
        title={<FormattedMessage id="ManagementZones.available" />}
        content={
          <FormattedMessage
            id="ManagementZones.availableExplanation"
            values={{
              b: (chunks: string) => <b>{chunks}</b>,
            }}
          />
        }
      />
      )}
      {managementZonesStatus === satelliteProductsTypes.AWAITING_DATA && (
      <CfStatusPanel
        content={<FormattedMessage id="ManagementZones.triggeredExplanation" />}
        icon={SatelliteIcon}
        title={<FormattedMessage id="ManagementZones.triggered" />}
      />
      )}
      {managementZonesStatus === satelliteProductsTypes.ACTIVE && (
      <Fragment>
        {isFetching ? (
          <CfLoader />
        ) : (
          <Grid className={classes.wrapper} container spacing={1}>
            <Grid className={classes.headerBar} item xs={12}>
              <Grid alignItems="center" container justifyContent={'flex-start'}>
                <Grid item xs={12}>
                  <CfSwitcher
                    getItemId={(item: Crop) => item.legislativeCode}
                    getItemValue={(item: Crop) => item.name}
                    items={crops}
                    onMenuItemClick={setCropLegislativeCode}
                    classes={{
                      wrapper: classes.cfSwitcherWrapper,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid data-test="generalized-zones" item lg={4} md={3} sm={6} xs={12}>
              <Paper>
                <SectionHeader
                  headingTranslationId={'ManagementZones.GeneralizedZones.heading'}
                  hintTranslationId={'ManagementZones.GeneralizedZones.hint'}
                />
                <ManagementZonesMap
                  onMapClick={setMapForModal}
                  zones={cropZones}
                />
              </Paper>
            </Grid>

            <Grid data-test="crop-quality" item lg={2} md={3} sm={6} xs={12}>
              <Paper>
                <SectionHeader
                  headingTranslationId={'ManagementZones.CropQuality.heading'}
                  hintTranslationId={'ManagementZones.CropQuality.hint'}
                />
                <CropQualityGraph isFetching={isFetching} zonesData={cropQuality} />
              </Paper>
            </Grid>

            <Grid data-test="crop-variability" item lg={6} md={6} sm={12} xs={12}>
              <Paper>
                <SectionHeader
                  headingTranslationId={'ManagementZones.CropVariability.heading'}
                  hintTranslationId={'ManagementZones.CropVariability.hint'}
                />
                <CropVariabilityGraph data={cropVariability} isFetching={isFetching} />
              </Paper>
            </Grid>

            <Grid data-test="history" item xs={12}>
              <Paper style={{ display: 'flex', flexWrap: 'wrap' }}>
                <SectionHeader
                  headingTranslationId={'common.history'}
                  infoDialogContent={<ManagementHistoryDialog item={historySnaps[0]} />}
                  maxDialogWidth={'md'}
                />
                {historySnaps.map(item => (
                  <ManagementHistoryImage item={item} key={item.date} width={width} />
                ))}
              </Paper>
            </Grid>
          </Grid>
        )}
      </Fragment>
      )}
      {showModal &&
        <PrecisionMapModal
          geometries={geometries}
          isManagementZones
          onClose={handleCloseModal}
          parcelId={parcelId}
          showModal={showModal}
        />
      }
    </CfErrorPage>
  );
};

const mapStateToProps = (state: PrecisionState) => ({
  crops: getCrops(state),
  historySnaps: getHistorySnaps(state),
  cropQuality: getCropQuality(state),
  cropVariability: getCropVariability(state),
  cropZones: getCropZones(state),
  isFetching: getManagementZonesIsFetching(state),
  error: getError(state),
  isFetchingActivate: getManagementZonesIsFetchingActivate(state),
  activated: getManagementZonesActivated(state),
  errorActivating: getManagementZonesErrorActivating(state),
  managementZonesStatus: getManagementZonesStatus(state),
});

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) =>
  bindActionCreators(
    {
      setCropLegislativeCode,
      getManagementZones,
      resetManagementZones,
      activateManagementZones,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ManagementZones);
