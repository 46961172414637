import { throwNamespaceError } from './common.actions';

import * as types from './table.constants';

export const setTablePage = (page, namespace) => {
  if (!namespace) throwNamespaceError();

  return {
    type: types.SET_TABLE_PAGE,
    page,
    namespace,
  };
};

export const setTableRowsPerPage = (rowsPerPage, namespace) => {
  if (!namespace) throwNamespaceError();

  return {
    type: types.SET_TABLE_ROWS_PER_PAGE,
    rowsPerPage,
    namespace,
  };
};

export const setTableOrder = (order, namespace) => {
  if (!namespace) throwNamespaceError();

  return {
    type: types.SET_TABLE_ORDER,
    order,
    namespace,
  };
};

export const setTableOrderBy = (orderBy, namespace) => {
  if (!namespace) throwNamespaceError();

  return {
    type: types.SET_TABLE_ORDER_BY,
    orderBy,
    namespace,
  };
};

export const setTableSelected = (selected, namespace) => {
  if (!namespace) throwNamespaceError();

  return {
    type: types.SET_TABLE_SELECTED,
    selected,
    namespace,
  };
};

export const setTableSubrowSelected = (selected, namespace) => {
  if (!namespace) throwNamespaceError();

  return {
    type: types.SET_TABLE_SUBROW_SELECTED,
    selected,
    namespace,
  };
};

export const setTableSorting = (order, orderBy, namespace) => dispatch => {
  if (!namespace) throwNamespaceError();

  dispatch(setTableOrder(order, namespace));
  dispatch(setTableOrderBy(orderBy, namespace));
};
