import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';

import signupUI from '../core/signup/reducer/signup.reducer';
import eagriAuthApi from '../shared/api/agroevidence/eagriAuth/eagriAuth.reducer';
import farmsApi from '../shared/api/agroevidence/farms/farms.reducer';
import countriesApi from '../shared/api/core/countries/countries.reducer';
import accountsApi from '../shared/api/gateway/accounts/accounts.reducer';
import authReducer from '../shared/api/gateway/auth/auth.reducer';

export default (history: History) =>
  combineReducers({
    ui: combineReducers({
      signup: signupUI(),
    }),
    api: combineReducers({
      countries: countriesApi(),
      eagriAuth: eagriAuthApi(),
      farms: farmsApi(),
      accounts: accountsApi(),
    }),
    router: connectRouter(history),
    auth: authReducer,
  });
