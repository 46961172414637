import { AnyAction } from 'redux';

import * as types from '../actions/monitoring.constants';

import { LAI } from '../selectors/indices';

// LAI is default index, because if monitoring does not contain LAI, we can consider it not fully active
const defaultIndex = LAI;

const initialState = {
  dateFrom: '',
  dateTo: '',
  index: defaultIndex,
};

export default () => (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.SET_MONITORING_INTERVAL:
      return {
        ...state,
        dateFrom: action.dateFrom,
        dateTo: action.dateTo,
      };
    case types.RESET_MONITORING_INTERVAL:
      return {
        ...state,
        dateFrom: '',
        dateTo: '',
      };
    case types.SET_MONITORING_INDEX:
      return {
        ...state,
        index: action.index,
      };
    default:
      return state;
  }
};
