import React, { FC, ReactNode, useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getIsFetchingNitrogenCategory, getNitrogenCategory } from '../../../../../shared/api/agroevidence/catalogues/eagri/eagri.selectors';

import { getFertilizerNitrogenCategoriesApi, resetFertilizerNitrogenCategoriesApi } from '../../../../../shared/api/agroevidence/catalogues/eagri/eagri.api';
import CfAutocomplete from '../../../../../shared/components/common/CfAutocomplete/CfAutocomplete';
import { Thunk } from '../../../../../types';

import { CataloguesState } from '../../../../../reducers/catalogues.reducer.types';
import { EagriFertilizerNitrogenCategoryTo } from '../../../../../shared/api/agroevidence/agroevidence.types';

interface Props {
  defaultValues?: EagriFertilizerNitrogenCategoryTo | EagriFertilizerNitrogenCategoryTo[],
  disabled?: boolean,
  error?: boolean;
  getFertilizerNitrogenCategoriesApi: (language?: string) => void,
  isMultiple?: boolean,
  isNitrogenCategoryFetching: boolean,
  label: ReactNode,
  langId: string,
  nitrogenCategory: EagriFertilizerNitrogenCategoryTo[],
  onChange: (items: EagriFertilizerNitrogenCategoryTo | EagriFertilizerNitrogenCategoryTo[]) => void,
  resetFertilizerNitrogenCategoriesApi: () => void
}

const NitrogenCategorySelector: FC<Props> = ({
  langId,
  isMultiple = false,
  disabled = false,
  error = false,
  defaultValues = [],
  getFertilizerNitrogenCategoriesApi,
  isNitrogenCategoryFetching,
  label,
  onChange,
  nitrogenCategory,
  resetFertilizerNitrogenCategoriesApi,
}) => {
  useEffect(() => {
    getFertilizerNitrogenCategoriesApi(langId);

    return () => {
      resetFertilizerNitrogenCategoriesApi();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      disabled={disabled}
      error={error}
      helperText={error ? <FormattedMessage id="validation.required" /> : ''}
      id="nitrogen-categories-selector"
      isFetching={isNitrogenCategoryFetching}
      isMultiple={isMultiple}
      label={label}
      loadOptions={getFertilizerNitrogenCategoriesApi}
      onChange={onChange}
      suggestions={nitrogenCategory}
      testId="nitrogen-categories-selector"
    />
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  nitrogenCategory: getNitrogenCategory(state),
  isNitrogenCategoryFetching: getIsFetchingNitrogenCategory(state),
});

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      getFertilizerNitrogenCategoriesApi,
      resetFertilizerNitrogenCategoriesApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(NitrogenCategorySelector);
