import moment from 'moment';

export const HISTORICAL_PARCEL_STATUS = 'RETIRED';

export const SHOWN_NUMBER_SOWING_SEASONS = 4;
export const START_YEAR_FIRST_SOWING_SEASONS = moment().year() - 1;

export default class ParcelsService {
  static isParcelHistorical(parcel) {
    return parcel && parcel.status === HISTORICAL_PARCEL_STATUS;
  }
}
