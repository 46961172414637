import { useEffect, useState } from 'react';

import { Location } from 'history';
import { useDispatch } from 'react-redux';

import { setVisitedTab } from '../../actions/telematicsTabs.actions';

import { API_MODULES } from '../../../shared/api/api.constants';

import UiCacheService from '../../../shared/services/UiCache.service';

import { TABS } from './TelematicsTabs';

export const getPreviousTelematicsPage = (location: Location, TELEMATICS_URLS: {[key: string]: string;}) => {
  if (location.pathname.includes(TELEMATICS_URLS.drivers)) {
    return 'drivers';
  } else if (location.pathname.includes(TELEMATICS_URLS.machines)) {
    return 'machines';
  }
};

export const saveTelematicsTabToCache = (tab: TABS) => {
  const oldCopy = UiCacheService.getCachePerSection(API_MODULES.TELEMATICS);
  const newCopy = { ...oldCopy, telematicsTabs: { lastVisitedTab: tab } };
  UiCacheService.storeSettings(API_MODULES.TELEMATICS, newCopy);
};

export const useTelematicsLogger = (tab: TABS) => {
  const dispatch = useDispatch();

  const [init, setInit] = useState(false);

  useEffect(() => {
    if (init) {
      saveTelematicsTabToCache(tab);
      dispatch(setVisitedTab(tab));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, init]);

  useEffect(() => {
    setInit(true);
  }, []);
};
