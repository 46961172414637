import { useState } from 'react';

export function useDialog() {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return {
    isOpen,
    handleOpen,
    handleClose,
  };
}
