import React, { Component, Fragment } from 'react';

import { Paper } from '@mui/material';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getDeviceIntervals, getIsFetchingIntervals } from '../../../shared/api/irrigation/devices/devices.selectors';

import { fetchDeviceIntervals } from '../../actions/devices.actions';

import CfLoader from '../../../shared/components/common/CfLoader/CfLoader';
import DateSelector from '../../../shared/components/common/DateSelector/DateSelector';
import TimeIntervalsBar from '../../../shared/components/misc/TimeIntervalsBar/TimeIntervalsBar';

const styles = theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0px',
  },
  timeLabel: {
    fontSize: 13,
    color: theme.palette.grey[500],
  },
  paper: {
    padding: '12px',
  },
  timeLabels: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '5px',
  },
  valveLabel: {
    fontSize: 13,
    fontWeight: 500,
  },
  valveChart: {
    marginTop: '10px',
  },
});

const CHART_LABELS_COUNT = 5;

export class ValvesHistory extends Component {
  constructor(props) {
    super(props);
    this.currentTime = moment().toISOString();
    this.state = {
      dateFrom: moment().startOf('day').toISOString(),
      dateTo: moment().endOf('day').toISOString(),
      deviceIntervals: [],
    };
  }

  componentDidMount() {
    const { dateFrom, dateTo } = this.state;
    this.props.fetchDeviceIntervals(this.props.deviceId, dateFrom, dateTo);
  }

  componentDidUpdate(prevProps) {
    const { deviceIntervals, isFetchingIntervals } = this.props;
    if (prevProps.isFetchingIntervals && !isFetchingIntervals) {
      this.setState({
        deviceIntervals,
      });
    }
  }

  getLabels() {
    const { dateFrom } = this.state;
    const dateTimeLabels = [dateFrom];

    for (let i = 0; i < CHART_LABELS_COUNT - 1; i += 1) {
      dateTimeLabels.push(
        moment(dateTimeLabels[i])
          .subtract(24 / (CHART_LABELS_COUNT - 1), 'hours')
          .toISOString(),
      );
    }

    return dateTimeLabels;
  }

  setDateFrom = dateFrom => {
    const dateTo = moment(dateFrom).endOf('day').toISOString();
    this.setState({
      dateFrom,
      dateTo,
    });
    this.props.fetchDeviceIntervals(this.props.deviceId, dateFrom, dateTo);
  };

  renderInterval(interval, datetimeStart, datetimeEnd) {
    const { classes } = this.props;
    return (
      <div className={classes.valveChart} data-test="valve-history-chart-item" key={interval.valve}>
        <span className={classes.valveLabel}>
          <FormattedMessage id={'IrrigationList.valve'} /> {interval.valve + 1}
        </span>
        <TimeIntervalsBar
          customColor="#296AF6"
          datetimeEnd={datetimeEnd}
          datetimeStart={datetimeStart}
          intervals={interval.intervals}
          intervalsShape="rounded"
          tooltipMessage="ValveIntervals.irrigated" />
      </div>
    );
  }

  render() {
    const { classes, isFetchingIntervals } = this.props;
    const { dateFrom, dateTo, deviceIntervals } = this.state;

    return (
      <div>
        <div className={classes.header}>
          <FormattedMessage id="IrrigationDetail.irrigationHistory" />
          {dateFrom && (
            <DateSelector
              dateFrom={dateFrom}
              setDateFrom={this.setDateFrom}
            />
          )}
        </div>
        <Paper className={classes.paper}>
          {isFetchingIntervals ? (
            <CfLoader />
          ) : (
            <Fragment>
              <div data-test="valve-history-chart">
                {deviceIntervals.map(interval => this.renderInterval(interval, dateFrom, dateTo))}
              </div>
              <div className={classes.timeLabels}>
                {this.getLabels()
                  .reverse()
                  .map(time => (
                    <span className={classes.timeLabel} key={time}>
                      <FormattedTime value={time} />
                    </span>
                  ))}
              </div>
            </Fragment>
          )}
        </Paper>
      </div>
    );
  }
}

ValvesHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  deviceId: PropTypes.string.isRequired,
  deviceIntervals: PropTypes.array.isRequired,
  fetchDeviceIntervals: PropTypes.func.isRequired,
  isFetchingIntervals: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  deviceIntervals: getDeviceIntervals(state),
  isFetchingIntervals: getIsFetchingIntervals(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDeviceIntervals,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ValvesHistory));
