import { AnyAction } from 'redux';

import * as commonTableTypes from '../../../shared/actions/table.constants';
import { ROWS_PER_PAGE } from '../../../shared/constants/rowsPerPage.constants';

export const NAMESPACE = 'por_report_history';

const initialState = {
  page: 0,
  rowsPerPage: ROWS_PER_PAGE,
};

export default (reducerNamespace = NAMESPACE) => (
  state = initialState,
  action: AnyAction,
) => {
  const { namespace } = action;
  if (namespace && namespace !== reducerNamespace) return state;

  switch (action.type) {
    case commonTableTypes.SET_TABLE_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case commonTableTypes.SET_TABLE_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
        page: 0,
      };

    default:
      return state;
  }
};
