import React, { useEffect, FC, useState, useContext } from 'react';

import { Badge, IconButton, Tooltip, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getTosStatus } from '../../../shared/api/telematics/aggregations/aggregations.selectors';
import { getTelematicsAggregationsBulkEditMode } from '../../selectors/telematicsAggregations.selectors';

import { fetchAggregationsSaga } from '../../actions/telematicsAggregations.actions';

import TosIcon from '../../../assets/img/icons/telematics/tos.svg';
import { getTosStatusApi, sendToTosApi } from '../../../shared/api/telematics/aggregations/aggregations.api';
import CfDialog from '../../../shared/components/common/CfDialog/CfDialog';
import { SnackbarContext } from '../../../shared/containers/SnackbarProvider/SnackbarProvider';
import { AsyncFn, Thunk } from '../../../types';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { TosStatsTo } from '../../../shared/api/telematics/telematics.types';

interface Props {
    bulkEditMode: boolean;
    fetchAggregations: () => void;
    getTosStatus: () => void;
    sendToTos: () => void;
    tosStatus: TosStatsTo;
}

const useStyles = makeStyles({
  bold: {
    fontWeight: 500,
  },
  badge: {
    top: 7,
    right: 11,
  },
  iconActive: {
    cursor: 'pointer',
  },
  iconInactive: {
    opacity: 0.5,
  },
  tooltip: {
    maxWidth: 340,
  },
});

const TosSendButton: FC<Props> = ({
  bulkEditMode, fetchAggregations, getTosStatus, sendToTos, tosStatus,
}) => {
  const [opened, setOpened] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const classes = useStyles();
  const showSnackbar = useContext(SnackbarContext);

  useEffect(() => {
    getTosStatus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAccept = () => {
    setOpened(false);
    // using local state because the loading state is dependent on two async calls
    setIsSending(true);
    (sendToTos as AsyncFn)().then((res) => {
      if (!res.error) {
        (getTosStatus as AsyncFn)().then(() => setIsSending(false));
        fetchAggregations();
      } else {
        showSnackbar({
          message: <FormattedMessage id="TelematicsExport.error" />,
          isError: true,
        });
        setIsSending(false);
      }
    });
  };

  if (!tosStatus) return null;
  if (tosStatus.count === 0) return <img alt="send to Tos" className={classes.iconInactive} src={TosIcon} />;

  return (
    <>
      {isSending &&
        <IconButton
          aria-label="Send to tos"
          disabled={bulkEditMode}>
          <CircularProgress size={18} />
        </IconButton>}
      {!isSending &&
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          placement="bottom"
          title={<FormattedMessage id="TelematicsAggregations.tos.tooltip" />}
        >
          <Badge
            badgeContent={tosStatus.count}
            classes={{ badge: classes.badge }}
            className={bulkEditMode ? classes.iconInactive : classes.iconActive}
            color="error"
            onClick={bulkEditMode ? undefined : () => setOpened(true)}
        >
            <IconButton
              aria-label="Send to tos"
              disabled={bulkEditMode}
             >
              <img alt="send to Tos" src={TosIcon} />
            </IconButton>
          </Badge>
        </Tooltip>}
      <CfDialog
        acceptText={<FormattedMessage id="common.confirm" />}
        cancelText={<FormattedMessage id="common.cancel" />}
        onAccept={handleAccept}
        onCancel={() => setOpened(false)}
        opened={opened}
        title={<FormattedMessage id="TelematicsAggregations.tos.confirmationDialog.title" />}
        >
        <div>
          <p><FormattedMessage id="TelematicsAggregations.tos.confirmationDialog.line1" values={{ count: tosStatus.count, b: (chunks: React.ReactNode) => <b>{chunks}</b> }} /></p>
          <p>
            <FormattedMessage id="TelematicsAggregations.tos.confirmationDialog.line2" />
            <span className={classes.bold}>
              <FormattedDate value={tosStatus.dateFrom} />
              {' - '}
              <FormattedDate value={tosStatus.dateTo} />
            </span>
          </p>
        </div>
      </CfDialog>
    </>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  tosStatus: getTosStatus(state),
  bulkEditMode: getTelematicsAggregationsBulkEditMode(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) => bindActionCreators({
  getTosStatus: getTosStatusApi,
  sendToTos: sendToTosApi,
  fetchAggregations: fetchAggregationsSaga,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TosSendButton);
