import React, { FC, useState, useContext, useEffect, useMemo } from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getAggregatedExportError, isExportingAggregatedData } from '../../../shared/api/telematics/aggregations/aggregations.selectors';
import { isExportingData, getExportError } from '../../../shared/api/telematics/drives/drives.selectors';
import { getAggregatedMachinesExportError, isExportingAggregatedMachinesData } from '../../../shared/api/telematics/machines/machines.selectors';
import { getTelematicsAggregationsBulkEditMode } from '../../selectors/telematicsAggregations.selectors';

import { exportAggregatedDrives, exportTosData } from '../../actions/telematicsAggregations.actions';
import { exportDrives } from '../../actions/telematicsList.actions';
import { exportAggregatedMachinesDrives } from '../../actions/telematicsMachines.actions';

import { resetAggregatedExportError } from '../../../shared/api/telematics/aggregations/aggregations.api';
import { resetExportError } from '../../../shared/api/telematics/drives/drives.api';
import ExportButton from '../../../shared/components/common/ExportButton/ExportButton';
import { SnackbarContext } from '../../../shared/containers/SnackbarProvider/SnackbarProvider';
import { RsaaApiError, Thunk } from '../../../types';
import { TelematicsContext } from '../../containers/Telematics/Telematics';
import { TABS } from '../../containers/TelematicsTabs/TelematicsTabs';

import { useTelematicsExportState } from './useTelematicsExportState';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { EconomicSystem } from '../../../shared/api/telematics/telematics.types';

const useStyles = makeStyles({
  menuPaper: {
    marginLeft: 15,
  },
});

interface TelematicsExportProps {
    bulkEditMode: boolean;
    driversAggregatedExportError: RsaaApiError;
    exportAggregatedDrives: () => void;
    exportAggregatedMachinesDrives: () => void;
    exportDrives: () => void;
    exportError: RsaaApiError;
    exportTosData: () => void;
    isExporting: boolean;
    isExportingDriversAggregated: boolean;
    isExportingMachinesAggregated: boolean;
    machinesAggregatedExportError: RsaaApiError;
    resetAggregatedExportError: () => void;
    resetExportError: () => void;
    selectedTelematicsTab: TABS;
}

const TelematicsExport: FC<TelematicsExportProps> = ({
  bulkEditMode,
  driversAggregatedExportError,
  exportAggregatedDrives,
  exportAggregatedMachinesDrives,
  exportDrives,
  exportError,
  exportTosData,
  isExporting,
  isExportingDriversAggregated,
  isExportingMachinesAggregated,
  machinesAggregatedExportError,
  resetAggregatedExportError,
  resetExportError,
  selectedTelematicsTab,
}) => {
  const classes = useStyles();
  const showSnackbar = useContext(SnackbarContext);
  const { economicSystem } = useContext(TelematicsContext);

  const {
    exportTabDrives,
    tabExportError,
    tabIsExporting,
  } = useTelematicsExportState({
    driversAggregatedExportError,
    isExportingDriversAggregated,
    isExportingMachinesAggregated,
    machinesAggregatedExportError,
    exportAggregatedDrives,
    exportAggregatedMachinesDrives,
    exportDrives,
    selectedTelematicsTab,
  });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const menuItems = useMemo(() => {
    const handleClick = (handler: () => void) => () => {
      handler();
      setAnchorEl(null);
    };

    return [
      {
        intlId: 'TelematicsExport.rides',
        dataTest: 'export-aggregated-xlsx',
        onClick: handleClick(exportTabDrives),
        visible: selectedTelematicsTab === TABS.DRIVERS || selectedTelematicsTab === TABS.MACHINE,
      }, {
        intlId: 'TelematicsExport.rides',
        dataTest: 'export-xlsx',
        onClick: handleClick(exportTabDrives),
        visible: selectedTelematicsTab === TABS.LOGBOOK,
      },
      {
        intlId: 'TelematicsExport.tos',
        dataTest: 'export-tos',
        onClick: handleClick(exportTosData),
        visible: selectedTelematicsTab === TABS.DRIVERS && economicSystem === EconomicSystem.TOS,
      },
    ];
  }, [economicSystem, exportTabDrives, exportTosData, selectedTelematicsTab]);

  useEffect(() => {
    if (exportError.isError) {
      resetExportError();
    }
    if (tabExportError.isError) {
      resetAggregatedExportError();
    }
    if (exportError.isError || tabExportError.isError) {
      showSnackbar({
        message: <FormattedMessage id="TelematicsExport.error" />,
        isError: true,
      });
    }
  }, [tabExportError, exportError, resetAggregatedExportError, resetExportError, showSnackbar]);

  return (
    <div>
      <ExportButton
        handleClick={handlePopoverOpen}
        isDisabled={bulkEditMode}
        isLoading={isExporting || tabIsExporting}
      />
      <Menu
        anchorEl={anchorEl}
        className={classes.menuPaper}
        disableScrollLock={true}
        onClose={handlePopoverClose}
        open={!!anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuItems.map(({ dataTest, intlId, onClick, visible }, i) => visible && (
          <MenuItem
            data-test={dataTest}
            key={i}
            onClick={onClick}
          >
            <FormattedMessage id={intlId} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  isExportingDriversAggregated: isExportingAggregatedData(state),
  isExportingMachinesAggregated: isExportingAggregatedMachinesData(state),
  driversAggregatedExportError: getAggregatedExportError(state),
  machinesAggregatedExportError: getAggregatedMachinesExportError(state),
  isExporting: isExportingData(state),
  exportError: getExportError(state),
  bulkEditMode: getTelematicsAggregationsBulkEditMode(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      exportAggregatedDrives,
      exportDrives,
      exportTosData,
      resetAggregatedExportError,
      resetExportError,
      exportAggregatedMachinesDrives,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TelematicsExport);
