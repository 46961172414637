import React, { FC, ReactNode } from 'react';

import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import CfInfoDialog from '../../../shared/components/common/CfInfoDialog/CfInfoDialog';
import CfSectionHeading from '../../../shared/components/common/CfSectionHeading/CfSectionHeading';
import useWidth from '../../../shared/hooks/useWidth';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    minHeight: '48px',
  },
  upperHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  heading: {
    marginLeft: 15,
    lineHeight: 1.25,
  },
  childrenWrapper: {
    flexGrow: 1,
  },
}));

export interface SectionHeaderProps {
  children?: ReactNode,
  dialogHeadingTranslationId?: string,
  headingTranslationId: string,
  hintTranslationId?: string,
  infoDialogContent?: ReactNode,
  maxDialogWidth?: string,
  testId?: string
}

const SectionHeader: FC<SectionHeaderProps> = ({
  children,
  dialogHeadingTranslationId,
  headingTranslationId,
  hintTranslationId,
  infoDialogContent,
  maxDialogWidth = 'sm',
  testId,
}) => {
  const classes = useStyles();
  const width = useWidth();

  return (
    <div className={classes.wrapper} data-test={testId}>
      <div className={classes.upperHeading}>
        <div className={classes.heading}>
          <CfSectionHeading>
            <FormattedMessage id={headingTranslationId} />
          </CfSectionHeading>
        </div>
        {(hintTranslationId || infoDialogContent) && (
          <CfInfoDialog
            heading={<FormattedMessage id={dialogHeadingTranslationId || headingTranslationId} />}
            maxWidth={maxDialogWidth}
          >
            <>
              {hintTranslationId && (
              <Grid alignItems="flex-start" container>
                <Grid item xs={12}>
                  <Typography>
                    <FormattedMessage id={hintTranslationId} />
                  </Typography>
                </Grid>
              </Grid>
              )}
              {infoDialogContent}
            </>
          </CfInfoDialog>
        )}
        {width !== 'xs' && <div className={classes.childrenWrapper}>{children}</div>}
      </div>
      {width === 'xs' && <div className={classes.childrenWrapper}>{children}</div>}
    </div>
  );
};

export default SectionHeader;
