import React, { Fragment, Component } from 'react';

import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import differenceBy from 'lodash/differenceBy';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getActionRelatedDrives } from '../../../../../shared/api/telematics/aggregations/aggregations.selectors';
import { getParcelsAndZonesSuggestions, getParcelsToAdd } from '../../selectors/actions.selectors';

import {
  addInitParcelsToAdd,
  addInitZoneParcelsToAdd,
  addParcelOrZoneParcelsToAdd,
  clearParcelsAndZonesSuggestions,
  clearParcelsToAdd,
  fetchParcelsAndZonesSuggestions,
  fetchParcelHistorySubtractions,
  updateParcelsList,
  updateParcelsSubtractions,
} from '../../actions/actions.actions';

import CfFormattedNumber from '../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import ListSelectorError from '../../../../../shared/components/form/ListSelectorError/ListSelectorError';
import ActionParcelsList from '../../components/ActionParcelsList/ActionParcelsList';
import ParcelZoneSelector from '../../components/ParcelZoneSelector/ParcelZoneSelector';
import { SplitActionsWarningMessage } from '../../components/SplitActions/SplitActionsWarningMessage';
import { mapRequestBodyParcelsSubtraction } from '../../services/ParcelSubtractionMapper.services';

const styles = theme => ({
  parcelsLabel: {
    color: theme.palette.grey[500],
  },
  totalActivityArea: {
    backgroundColor: theme.palette.grey[100],
    padding: '12px 24px',
    overflow: 'hidden',
    marginTop: 10,
  },
  totalAreaLabel: {
    color: theme.palette.grey[500],
  },
  totalAreaValue: {
    float: 'right',
  },
  messageError: {
    color: theme.palette.error.main,
  },
  messageWarning: {
    color: theme.palette.secondary.main,
  },
});

class ActionParcelsControl extends Component {
  componentDidMount() {
    const { initParcelIds, initZoneIds } = this.props;

    if (Array.isArray(initParcelIds) && initParcelIds.length) {
      this.props.addInitParcelsToAdd(initParcelIds);
    }

    if (Array.isArray(initZoneIds) && initZoneIds.length) {
      this.props.addInitZoneParcelsToAdd(initZoneIds);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      expenses: prevExpenses,
      fields: prevFields,
      parcelsToAdd: prevParcelsToAdd,
    } = prevProps;

    const {
      expenses: newExpenses,
      fields: newFields,
      formName,
      parcelsToAdd: newParcelsToAdd,
      targetCrop: newTargetCrop,
    } = this.props;

    const prevParcels = prevFields.getAll();
    const newParcels = newFields.getAll();

    /*
     * Adds parcels from the external prop parcelsToAdd,
     * which is the user interaction, but parcels can be
     * added also thru the form initialization (like from the local storage)
     */
    if (newParcelsToAdd.length > prevParcelsToAdd.length) {
      this.addParcels(differenceBy(newParcelsToAdd, prevParcelsToAdd, 'id'));
      this.props.clearParcelsToAdd();
    }

    if (newParcels && prevParcels && (newParcels.length !== prevParcels.length)) {
      const parcelIds = newParcels.map(p => p.id);
      const parcelsAdded = differenceBy(newParcels, prevParcels, 'id');
      const fetchPromises = [];

      parcelsAdded.forEach(parcel => {
        const index = newParcels.findIndex(option => option.id === parcel.id);
        const fetchPromise = this.props.fetchParcelHistorySubtractions(parcel.id, parcelIds).then(predefinedSas => {
          newParcels[index].subtractableAreas = predefinedSas;
        });
        fetchPromises.push(fetchPromise);
      });

      this.props.updateParcelsList(newParcels, formName);

      if (fetchPromises.length) {
        Promise.all(fetchPromises).then(() => {
          this.updateParcelSubtractions(newParcels, newExpenses, newTargetCrop, formName);
        });
      }
    }

    if (prevExpenses && newExpenses.length !== prevExpenses.length) {
      this.updateParcelSubtractions(newParcels, newExpenses, newTargetCrop, formName);
    }
  }

  updateParcelSubtractions(parcels, expenses, targetCrop, formName) {
    const parcelsInForm = parcels;

    let requestParam;
    if (formName === 'eph') {
      const plantProtectionsExpenses = expenses.filter(expens =>
        expens.material.materialTypeId === 'CH');
      requestParam = mapRequestBodyParcelsSubtraction(parcels, plantProtectionsExpenses, targetCrop);
    } else {
      requestParam = mapRequestBodyParcelsSubtraction(parcels);
    }

    this.props.updateParcelsSubtractions(requestParam).then((updatedSubtractions) => {
      updatedSubtractions.forEach(item => {
        const index = parcels.findIndex(parcel => parcel.id === item.parcelId);
        const checkedSubtractableAreas = item.subtractions.filter(sa => sa.isUsed);
        const calculatedRestrictedArea = checkedSubtractableAreas.reduce((sum, area) => sum + area.area, 0);
        parcelsInForm[index].subtractableAreas = item.subtractions;
        parcelsInForm[index].restrictedArea = calculatedRestrictedArea;
      });
    });

    this.props.updateParcelsList(parcelsInForm, formName);
  }

  getSuggestions = searchInput => {
    const { parcelsOnly, zonesOnly } = this.props;
    this.props.fetchParcelsAndZonesSuggestions(searchInput, parcelsOnly, zonesOnly);
  };

  clearSuggestions = () => {
    const { parcelsOnly, zonesOnly } = this.props;
    this.props.clearParcelsAndZonesSuggestions(parcelsOnly, zonesOnly);
  };

  addParcels(parcels) {
    this.props.updateParcelsList(
      [
        ...this.props.fields.getAll(),
        ...parcels
          .filter(parcel => !this.isParcelInList(parcel))
          .map(p => ({
            ...p,
            subtractableAreas: [],
            restrictedArea: 0,
            actionParcelTotalArea: p.area,
          })),
      ],
      this.props.formName,
    );
    return parcels;
  }

  isParcelInList = parcelToCheck => find(this.props.fields.getAll(), ['id', parcelToCheck.id]) !== undefined;

  handleSuggestionSelected = suggestion => {
    this.props.addParcelOrZoneParcelsToAdd(suggestion);
  };

  handleItemRemove = index => {
    this.props.fields.remove(index);
  };

  render() {
    const {
      actionRelatedDrives,
      checkedItems,
      classes,
      expenses,
      fields,
      formName,
      handleCheckboxChange,
      intl: { formatMessage },
      isDraft,
      isEditing,
      isSplitting,
      maxParcelCount,
      meta: { error, submitFailed },
      numberOfCheckedItems,
      parcelsArea,
      placeholder,
      subtractableAreasIds,
      suggestions,
      targetCrop,
    } = this.props;

    return (
      <Fragment>
        {isEditing && (maxParcelCount ? fields.length < maxParcelCount : true) ? (
          <Fragment>
            <ParcelZoneSelector
              autoFocus={true}
              inputRef={ref => ref}
              onSuggestionsClearRequested={this.clearSuggestions}
              onSuggestionSelected={this.handleSuggestionSelected}
              onSuggestionsFetchRequested={this.getSuggestions}
              placeholder={placeholder}
              suggestions={suggestions.map(sugg => ({ ...sugg, title: formatMessage({ id: sugg.title }) }))}
            />
            {submitFailed && error && <ListSelectorError error={error} />}
          </Fragment>
        ) : (
          <div className={classes.parcelsLabel}>
            <FormattedMessage id="common.parcels" />:
          </div>
        )}
        <ActionParcelsList
          actionRelatedDrives={actionRelatedDrives}
          checkedItems={checkedItems}
          expenses={expenses}
          fields={fields}
          formName={formName}
          handleCheckboxChange={handleCheckboxChange}
          isDraft={isDraft}
          isEditing={isEditing}
          isSplitting={isSplitting}
          onItemRemove={this.handleItemRemove}
          subtractableAreasIds={subtractableAreasIds}
          targetCrop={targetCrop}
        />
        <SplitActionsWarningMessage
          isSplitting={isSplitting}
          numberOfCheckedItems={numberOfCheckedItems}
          numberOfParcels={fields.getAll()?.length}
        />
        {parcelsArea > 0 && (
          <Grid container justifyContent="center" spacing={2}>
            <Grid item lg={6} md={7} sm={8} xs={12}>
              <div className={classes.totalActivityArea}>
                <span className={classes.totalAreaLabel}>
                  <FormattedMessage id="ParcelZoneSelector.totalActivityArea" />:{' '}
                </span>
                <span className={classes.totalAreaValue}>{<CfFormattedNumber value={parcelsArea} />} ha</span>
              </div>
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  }
}

ActionParcelsControl.propTypes = {
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  fetchParcelsAndZonesSuggestions: PropTypes.func.isRequired,
  clearParcelsAndZonesSuggestions: PropTypes.func.isRequired,
  addParcelOrZoneParcelsToAdd: PropTypes.func.isRequired,
  clearParcelsToAdd: PropTypes.func.isRequired,
  addInitParcelsToAdd: PropTypes.func.isRequired,
  addInitZoneParcelsToAdd: PropTypes.func.isRequired,
  fetchParcelHistorySubtractions: PropTypes.func.isRequired,
  updateParcelsSubtractions: PropTypes.func.isRequired,
  updateParcelsList: PropTypes.func.isRequired,
  // eslint-disable-next-line
  driftClass: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  // eslint-disable-next-line
  expenses: PropTypes.array.isRequired,
  parcelsArea: PropTypes.number.isRequired,
  targetCrop: PropTypes.object,
  suggestions: PropTypes.array.isRequired,
  parcelsToAdd: PropTypes.array,
  isEditing: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  initParcelIds: PropTypes.array,
  initZoneIds: PropTypes.array,
  maxParcelCount: PropTypes.number,
  parcelsOnly: PropTypes.bool,
  zonesOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  subtractableAreasIds: PropTypes.array,
  isDraft: PropTypes.bool,
  actionRelatedDrives: PropTypes.object,
  handleCheckboxChange: PropTypes.func,
  checkedItems: PropTypes.object,
  isSplitting: PropTypes.bool,
  numberOfCheckedItems: PropTypes.number,
};

ActionParcelsControl.defaultProps = {
  parcelsToAdd: [],
  initParcelIds: null,
  initZoneIds: null,
  maxParcelCount: null,
  parcelsOnly: false,
  zonesOnly: false,
  placeholder: 'ParcelZoneSelector.placeholder',
  subtractableAreasIds: undefined,
  isDraft: false,
  targetCrop: null,
  isSplitting: false,
  numberOfCheckedItems: 0,
};

const mapStateToProps = (state, props) => {
  const { filter, formName } = props;

  return {
    suggestions: getParcelsAndZonesSuggestions(i => (filter?.length ? filter.includes(i.id) : true), formName)(state),
    parcelsToAdd: getParcelsToAdd(formName, state),
    actionRelatedDrives: getActionRelatedDrives(state),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchParcelsAndZonesSuggestions,
      clearParcelsAndZonesSuggestions,
      addParcelOrZoneParcelsToAdd,
      clearParcelsToAdd,
      addInitParcelsToAdd,
      addInitZoneParcelsToAdd,
      fetchParcelHistorySubtractions,
      updateParcelsSubtractions,
      updateParcelsList,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(ActionParcelsControl)));
