import React, { FC, Fragment, useContext, useState } from 'react';

import { Stack, Theme } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import { PorUsageReportParams } from '../../../../shared/api/agroevidence/reports/reports.api';
import { SnackbarContext } from '../../../../shared/containers/SnackbarProvider/SnackbarProvider';
import { AsyncFn } from '../../../../types';
import { FarmerPortalHeading } from '../shared';

import { PorUsageReportDialog } from './PorUsageReportDialog';
import { PorUsageReportErrorDialog } from './PorUsageReportErrorDialog';

import { PorUseReportMissingDataBody } from '../../../../shared/api/agroevidence/agroevidence.types';

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
    marginBottom: 10,
    fontSize: 24,
    fontWeight: 500,
  },
  info: {
    fontSize: 13,
    color: theme.palette.grey[500],
    marginBottom: 20,
    textAlign: 'center',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 30,
  },
  errorButton: {
    marginLeft: 30,
  },
}));

interface PorUsageReportProps {
  errorDetailedMessage: PorUseReportMissingDataBody,
  sendPorUsageReportToEagri: (params: PorUsageReportParams) => void
}

export const PorUsageReport: FC<PorUsageReportProps> = ({
  errorDetailedMessage,
  sendPorUsageReportToEagri,
}) => {
  const classes = useStyles();
  const showSnackbar = useContext(SnackbarContext);

  const [showDialog, setShowDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const handleDialogAccept = (toDate: string) => {
    const params = {
      toDate,
    };
    (sendPorUsageReportToEagri as AsyncFn<PorUsageReportParams>)(params).then((res) => {
      if (res.error) {
        showSnackbar({ message:
  <Stack alignItems="center" direction={'row'}>
    <FormattedMessage id="Reports.porUsageReport.error" />
    <Button
      className={classes.errorButton}
      onClick={handleErrorDialogOpen}
      size="small"
      variant="text"
    >
      <FormattedMessage id="Reports.porUsageReport.errorList.ErrorButton" />
    </Button>
  </Stack>,
        isError: true });
        return;
      }
      showSnackbar({ message: <FormattedMessage id="Reports.porUsageReport.success" />, isSuccess: true });
    });
    handleDialogClose();
  };

  const handleDialogOpen = () => {
    setShowDialog(true);
  };
  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleErrorDialogOpen = () => {
    setShowErrorDialog(true);
  };
  const handleErrorDialogClose = () => {
    setShowErrorDialog(false);
  };

  return (
    <Fragment>
      {showDialog &&
      <PorUsageReportDialog
        onAccept={handleDialogAccept}
        onClose={handleDialogClose}
        showDialog={showDialog}
        />
      }
      {showErrorDialog &&
        <PorUsageReportErrorDialog
          date={moment().format('DD.MM.yyyy')}
          errorDetailedMessage={errorDetailedMessage}
          onClose={handleErrorDialogClose}
          showErrorDialog={showErrorDialog}
        />
      }
      <FarmerPortalHeading titleTranslateString="Reports.porUsageReport.Heading" />
      <Grid className={classes.info} container justifyContent="center">
        <Grid item sm={2} xs={1} />
        <Grid item sm={8} xs={12}>
          <FormattedMessage id="Reports.porUsageReport.Info" values={{ date: moment().endOf('month').format('D.M.yyyy') }} />
        </Grid>
        <Grid item sm={2} xs={1} />
      </Grid>
      <div className={classes.button}>
        <Button color="primary" onClick={handleDialogOpen} variant="contained">
          <FormattedMessage id="Reports.sendData.Button" />
        </Button>
        {errorDetailedMessage &&
          <Button
            className={classes.errorButton}
            color="error"
            onClick={handleErrorDialogOpen}
            variant="contained">
            <FormattedMessage id="Reports.porUsageReport.ErrorButton" />
          </Button>
        }
      </div>
    </Fragment>
  );
};
