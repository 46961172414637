import React, { FC } from 'react';

import TelematicsPartialDriveWarning from '../../TelematicsPartialDriveWarning/TelematicsPartialDriveWarning';
import { useTelematicsAggregationDetailContentStyles } from '../styles';

import { DriveValidationType } from '../../../../shared/api/telematics/telematics.types';

interface Props {
    show: boolean;
    tooltipCode?: DriveValidationType | 'default';
}

const ApprovalWarning: FC<Props> = ({ show, tooltipCode = 'default' }) => {
  const classes = useTelematicsAggregationDetailContentStyles();
  if (!show) return null;

  return (
    <TelematicsPartialDriveWarning
      classes={classes.warningIcon}
      tooltipId={`TelematicsAggregations.incompleteRide.long.${tooltipCode}`}
    />
  );
};

export default ApprovalWarning;
