/**
 * Created by ondrejzvara on 2.11.15.
 */

/* @ngInject */
export default function FarmsResource(Api, ResourceUtils, FarmsStorage) {
  const baseFarmAccountAll = Api.setAgroevidenceBaseAll('farms');

  const service = {
    getFarm,
    getFarmBoundingBox,
    getFarms,
    getTelematicsStatus
  };
  return service;

  // ///////////////////////

  function getFarm(farmId) {
    const baseOne = Api.setAgroevidenceBaseOne('deprecated/farms', farmId);
    return Api.getItem(baseOne);
  }

  function getFarmBoundingBox(farmId) {
    const baseOne = Api.setAgroevidenceBaseOne('deprecated/farms', farmId);
    return Api.getItem(baseOne).then(result => result.boundingBox);
  }

  function getFarms() {
    return Api.getItems(baseFarmAccountAll, {}).then((result) => {
      FarmsStorage.setFarms(result.data.plain());
      return result.data.plain();
    });
  }

  function getTelematicsStatus(farmId){
    const baseOne = Api.setTelematicsBaseOne('account/status');
    return Api.getItem(baseOne, { farmIds:farmId }).then(result => result);
  }
}
