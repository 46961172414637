import * as types from '../actions/sowingPlan/sowingPlan.constants';

export default () => (
  state = {
    parcels: {},
    selectedSeasonId: '0',
    selectedSeasonYears: '0',
  },
  action,
) => {
  switch (action.type) {
    case types.STORE_INITIAL_SEASON:
      return {
        ...state,
        parcels: action.parcels,
      };
    case types.STORE_RESET_SEASON:
      return {
        ...state,
        parcels: {},
      };

    case types.SET_SELECTED_SEASON:
      return {
        ...state,
        selectedSeasonId: action.selectedSeasonId,
        selectedSeasonYears: action.selectedSeasonYears,
      };

    default:
      return state;
  }
};
