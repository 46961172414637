import React, { FC, useContext, useEffect, useMemo, useState } from 'react';

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getDriveOverlapsApi } from '../../../shared/api/telematics/aggregations/aggregations.api';
import { SnackbarContext } from '../../../shared/containers/SnackbarProvider/SnackbarProvider';
import { getShortDateString } from '../../../shared/misc/timeHelpers';
import { AsyncFn, Thunk } from '../../../types';
import { BE_SEPARATE_INTERVAL_ERROR_MIN_LENGTH } from '../../containers/TelematicsAggregationDetail/TelematicsAggregationDetail';
import { getSplitTime } from '../../helpers';

import TimelineForm from './TimelineForm';
import { useTelematicsAggDetailTimelineStyles } from './timelineStyles';
import { validateCollisionForm, validateSplitTimeForm } from './validators';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { DriveValidationType, DriveSeparationTimeTo, DriveDetailTo, DriveDetailOverlapTo,
  DriveSeparationIntervalsTo, DriveSeparationIntervalTo } from '../../../shared/api/telematics/telematics.types';

export interface SplitTimeFormValues {
    time?: moment.Moment;
  }

export interface CollisionsFormValues {
    checkedIds: number[];
    validIntervals: boolean;
  }

export enum TimelineFormEditEnum {
    COLLISION='collision',
    IN_TIME='in-time'
  }

interface Props {
  approvalValidationErrors: Record<string, DriveValidationType | undefined>;
  driveDetail: DriveDetailTo;
  getDriveOverlapsApi: (id: number) => void;
  handleReset: () => void;
  handleSaveCollisions: (values: DriveSeparationIntervalsTo) => Promise<void>;
  handleSaveTimeSplitting: (values: DriveSeparationTimeTo) => void;
  isEditing: boolean;
  refreshKey?: string;
  showIntervalsBar: boolean;
}

const TimelineContainer: FC<Props> = ({
  approvalValidationErrors,
  driveDetail,
  getDriveOverlapsApi,
  handleReset,
  handleSaveCollisions,
  handleSaveTimeSplitting,
  isEditing,
  refreshKey,
  showIntervalsBar,
}) => {
  const classes = useTelematicsAggDetailTimelineStyles();
  const [editType, setEditType] = useState<TimelineFormEditEnum>(TimelineFormEditEnum.IN_TIME);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [overlaps, setOverlaps] = useState<DriveDetailOverlapTo[]>([]);
  const showSnackbar = useContext(SnackbarContext);

  const resetSettings = () => {
    setHasSubmitted(false);
    setEditType(TimelineFormEditEnum.IN_TIME);
  };

  const fetchOverlaps = () => {
    (getDriveOverlapsApi as AsyncFn<number>)(driveDetail?.id)
      .then(res => {
        if (!res.error) {
          setOverlaps(res.payload);
        } else {
          showSnackbar({
            message: <FormattedMessage id="TelematicsDetail.editRideError" />,
            isError: true,
          });
        }
      });
  };

  useEffect(() => {
    if (approvalValidationErrors.duration) {
      fetchOverlaps();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driveDetail?.id]);

  useEffect(() => {
    if (!isEditing) {
      resetSettings();
    }
    return () => resetSettings();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  const splitTimeInitValues: SplitTimeFormValues = useMemo(() => ({
    time: moment(driveDetail.dateFrom).startOf('day'),
  }), [driveDetail.dateFrom]);

  const collisionsInitValues: CollisionsFormValues = useMemo(() => ({
    checkedIds: [],
    validIntervals: true,
  }), []);

  const handleSubmitTimeSplitting = (values: SplitTimeFormValues) => {
    const data: DriveSeparationTimeTo = {
      time: getShortDateString(values.time, 'HH:mm'),
    };
    handleSaveTimeSplitting(data);
  };

  const getRequestCollisionsData = (selectedOverlapIds: number[], overlaps: DriveDetailOverlapTo[]) => {
    const selected = overlaps.filter((o, i) => selectedOverlapIds.includes(i));
    const payload: DriveSeparationIntervalTo[] = selected.map((s) => {
      const { dateFrom, dateTo } = s;
      return {
        timeFrom: getShortDateString(dateFrom, 'HH:mm:ss'),
        timeTo: getShortDateString(dateTo, 'HH:mm:ss'),
      };
    });

    const data: DriveSeparationIntervalsTo = {
      intervals: payload,
    };
    return data;
  };

  const handleSubmitCollisions = (values: CollisionsFormValues, actions: FormikHelpers<CollisionsFormValues>) => {
    const data = getRequestCollisionsData(
      (values as CollisionsFormValues).checkedIds, overlaps);

    handleSaveCollisions(data)
      .then(() => fetchOverlaps())
      .catch(errorType => {
        if (errorType === BE_SEPARATE_INTERVAL_ERROR_MIN_LENGTH) {
          actions.setFieldValue('validIntervals', false);
        }
      });
  };

  const handleRadioGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditType((event.target as HTMLInputElement).value as TimelineFormEditEnum);
    setHasSubmitted(false);
  };

  const validateTimeSplitting = (values: SplitTimeFormValues) =>
    validateSplitTimeForm(values, getSplitTime(driveDetail.drivePart, values.time));

  const validateCollisions = (values: CollisionsFormValues) =>
    validateCollisionForm({
      checkedIds: values.checkedIds,
      validIntervals: values.validIntervals,
    });

  const inTimeMode = isEditing && editType === TimelineFormEditEnum.IN_TIME;
  const collisionMode = isEditing && editType === TimelineFormEditEnum.COLLISION;

  return (
    <>
      {isEditing && (
        <RadioGroup
          aria-labelledby="controlled-radio-buttons-group"
          className={classes.radioGroup}
          name="radio-buttons-group"
          onChange={handleRadioGroupChange}
          value={editType}
                >
          <FormControlLabel
            control={<Radio size="small" />}
            label={<FormattedMessage id="TelematicsAggregations.detail.splitting.inTime" />}
            value={TimelineFormEditEnum.IN_TIME}
                />
          <FormControlLabel
            control={<Radio disabled={overlaps.length === 0} size="small" />}
            label={<FormattedMessage id="TelematicsAggregations.detail.splitting.collision" />}
            value={TimelineFormEditEnum.COLLISION}
                />
        </RadioGroup>
      )}

      <Formik
        enableReinitialize
        initialValues={inTimeMode ? splitTimeInitValues : collisionsInitValues}
        validateOnBlur={hasSubmitted}
        validateOnChange={hasSubmitted}
        onSubmit={inTimeMode
          ? (values) => handleSubmitTimeSplitting(values as SplitTimeFormValues)
          : (values, actions) => handleSubmitCollisions(
            values as CollisionsFormValues, actions as FormikHelpers<CollisionsFormValues>)}
        validate={inTimeMode
          ? (values) => validateTimeSplitting(values as SplitTimeFormValues)
          : (values) => validateCollisions(values as CollisionsFormValues)
        }
      >
        {formikProps => (
          <TimelineForm
            approvalValidationErrors={approvalValidationErrors}
            collisionMode={collisionMode}
            driveDetail={driveDetail}
            formikProps={formikProps}
            handleReset={handleReset}
            hasSubmitted={hasSubmitted}
            inTimeMode={inTimeMode}
            isEditing={isEditing}
            overlaps={overlaps}
            refreshKey={refreshKey}
            setHasSubmitted={setHasSubmitted}
            showIntervalsBar={showIntervalsBar}
          />
        )}
      </Formik>
    </>
  );
};

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      getDriveOverlapsApi,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(TimelineContainer);
