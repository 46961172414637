import { createColumns } from '../../helpers/tableHelpers';

export enum COLUMN_NAMES {
    IS_ORGANIC = 'isOrganic',
    K2O = 'k2o',
    NAME = 'name',
    NITROGEN = 'n',
    NITROGEN_CATEGORY = 'nitrogenCategory',
    P2O5 = 'p2o5',
    SOURCE = 'source',
    VALID_TO= 'validTo',
}

export const columns = createColumns([
  {
    id: COLUMN_NAMES.NAME,
    primaryLabel: 'Catalogues.table.fertilizers.column.name',
    sortable: true,
    style: { paddingLeft: 16 },
  },
  {
    id: COLUMN_NAMES.IS_ORGANIC,
    primaryLabel: 'Catalogues.table.fertilizers.column.isOrganic',
  },
  {
    id: COLUMN_NAMES.NITROGEN_CATEGORY,
    primaryLabel: 'Catalogues.table.fertilizers.column.nitrogenCategory',
  },
  {
    id: COLUMN_NAMES.SOURCE,
    primaryLabel: 'Catalogues.table.fertilizers.column.source',
  },
  {
    id: COLUMN_NAMES.NITROGEN,
    primaryLabel: 'Catalogues.table.fertilizers.column.n',
    sortable: true,
  },
  {
    id: COLUMN_NAMES.P2O5,
    primaryLabel: 'Catalogues.table.fertilizers.column.p2o5',
    sortable: true,
  },
  {
    id: COLUMN_NAMES.K2O,
    primaryLabel: 'Catalogues.table.fertilizers.column.k2o',
    sortable: true,
  },
  {
    id: COLUMN_NAMES.VALID_TO,
    primaryLabel: 'Catalogues.table.equipments.column.validTo',
    sortable: true,
  },
]);
