import ErrorService from '../../../services/Error.service';

import { PrecisionState } from '../../../../reducers/precision.reducer.types';

export const getManagementZones = (state: PrecisionState) => state.api.management.item;
export const getManagementZonesIsFetching = (state: PrecisionState) => state.api.management.isFetching;
export const getManagementZonesError = (state: PrecisionState) =>
  ErrorService.getResErrorDto(state.api.management.error);
export const getManagementZonesIsFetchingActivate = (state: PrecisionState) => state.api.management.isFetchingActivate;
export const getManagementZonesActivated = (state: PrecisionState) => state.api.management.activated;
export const getManagementZonesErrorActivating = (state: PrecisionState) =>
  ErrorService.getResErrorDto(state.api.management.errorActivating);
