import { AnyAction } from 'redux';
import { RSAAAction, RSAAResultAction } from 'redux-api-middleware';

import * as types from './telematicsAggregationDetail.constants';

import { getDriverAggregationsApi } from '../../shared/api/telematics/aggregations/aggregations.api';
import { getMachineAggregationsApi } from '../../shared/api/telematics/machines/machines.api';

import { LogbookAggregatedTo } from '../../shared/api/telematics/telematics.types';

export const saveRelatedDrives = (payload: string[]) => ({
  type: types.SAVE_RELATED_DRIVES,
  payload,
});

export const fetchRelatedDrives = (driver: string, dateFrom: string, dateTo: string) =>
  (dispatch: (action: AnyAction | RSAAAction) => unknown) => {
    const params = {
      driver,
      dateFrom,
      dateTo,
    };
    // @ts-ignore
    dispatch(getDriverAggregationsApi(params, false, true)).then((res: RSAAResultAction<LogbookAggregatedTo[]>) => {
      let result: string[] = [];
      if (!res.error && res.payload?.length > 0) {
        result = res.payload[0].drives.map((drive) => drive.id);
      }
      dispatch(saveRelatedDrives(result));
    });
  };

export const fetchRelatedDrivesByMachine = (machine: string, dateFrom: string, dateTo: string) =>
  (dispatch: (action: AnyAction | RSAAAction) => unknown) => {
    const params = {
      machine,
      dateFrom,
      dateTo,
    };
    // @ts-ignore
    dispatch(getMachineAggregationsApi(params, false, true)).then((res: RSAAResultAction<LogbookAggregatedTo[]>) => {
      let result: string[] = [];
      if (!res.error && res.payload?.length > 0) {
        result = res.payload[0].drives.map((drive) => drive.id);
      }
      dispatch(saveRelatedDrives(result));
    });
  };
