import get from 'lodash/get';
import head from 'lodash/head';
import keyBy from 'lodash/keyBy';
import last from 'lodash/last';
import max from 'lodash/max';
import min from 'lodash/min';
import sum from 'lodash/sum';
import moment from 'moment';

import { STATISTICS_TYPES } from '../constants/statistics.constants';

export default class FeatureData {
  // When all sensors included in all features have property `isFetching` set to false
  // Property `isFetching` is stored in sensor.data[AGGREGATION] in order to distinguish
  // fetching state for different aggregations.
  static isDataFetched(features) {
    return (
      features &&
      features.length &&
      features.every(
        feature =>
          feature.sensors.length &&
          feature.sensors.every(sensor => get(sensor, `data[0][${feature.aggregation}].isFetching`, null) === false),
      )
    );
  }

  static featuresHasSensors(features) {
    return features && features.length && features.every(feature => feature.sensors.length);
  }

  static mergeFeatureData(features) {
    const merged = [];
    const data = features.map(feature => FeatureData.formatFeatureData(feature));

    head(data).forEach((val, index) => {
      const tuples = FeatureData.getTuples(data, index);
      if (!tuples.length) {
        return [];
      }

      merged.push(Object.assign(...tuples));
    });

    return merged;
  }

  static formatFeatureData(feature) {
    const { aggregation, name, sensors } = feature;
    // Each feature can have >= 1 number of sensors;
    if (sensors.length === 1) {
      const {
        data: [data],
        level,
      } = head(sensors);
      return FeatureData.formatDataPerAggregation(data, level, aggregation, name);
    }

    // Features with > 1 sensors should return array of min - max values
    const formattedSensorsData = sensors.map(sensor => {
      const {
        data: [data],
        level,
      } = sensor;
      return FeatureData.formatDataPerAggregation(data, level, aggregation, name);
    });

    // Filter data to have only values with corresponding datetime for every sensor
    const filteredFormattedSensorsData = this.getFilteredSensorsData(formattedSensorsData);

    return head(filteredFormattedSensorsData).map((val, index) => {
      const tuples = this.getTuples(filteredFormattedSensorsData, index);
      if (!tuples.length) {
        throw new Error('Different length of data arrays. Corrupted data.');
      }

      return {
        dateTime: head(tuples).dateTime,
        time: head(tuples).time,
        tuples,
        [name]: FeatureData.getMinMaxValues(tuples, name),
      };
    });
  }

  static formatDataPerAggregation(data, level, aggregation, name) {
    return Object.values(data[aggregation].data).map(({ time, value }) => ({
      [name]: value,
      dateTime: time,
      // unix time is necessary because of 'time' scale type of chart
      time: moment(time).unix(),
      level,
    }));
  }

  static timeTickFormatter(time, fDate, fTime, duration) {
    if (duration > 1 && duration < 62) {
      return fDate(time, { day: 'numeric', month: 'numeric' });
    }
    if (duration >= 62) {
      return fDate(time, { day: 'numeric', month: 'numeric', year: 'numeric' });
    }
    return fTime(time, { hour: 'numeric', minute: 'numeric' });
  }

  static getTuples(data, index) {
    let tuples = [];
    data.every(item => {
      if (!item[index]) {
        tuples = [];
        return false;
      }

      tuples.push(item[index]);
      return true;
    });
    return tuples;
  }

  static getFilteredSensorsData(sensorsData) {
    const sensorsDataByDateTime = sensorsData.map(sensorData => keyBy(sensorData, item => item.dateTime));

    return sensorsData.map(sensorData => {
      const filteredSensorData = [];
      sensorData.forEach(item => {
        if (sensorsDataByDateTime.every(sensorDataByDateTime => !!sensorDataByDateTime[item.dateTime])) {
          filteredSensorData.push(item);
        }
      });
      return filteredSensorData;
    });
  }

  static getMinMaxValues(tuples, attr) {
    const maxValue = max(tuples.map(featureValue => featureValue[attr]));
    const minValue = min(tuples.map(featureValue => featureValue[attr]));

    return [minValue, maxValue];
  }

  static processFeatureStatistics(features, statistics) {
    const newStatistics = [];
    if (statistics) {
      statistics.forEach(item => {
        const featureName = `${item.featureType}_${item.aggregation}`;
        const foundFeature = features.find(feature => feature.name === featureName);
        if (foundFeature) {
          const { aggregation, sensors, stroke, tooltipStroke, unit } = foundFeature;
          let featureStatisticsValue;
          if (sensors.length === 1) {
            const {
              data: [data],
            } = head(sensors);
            featureStatisticsValue = data[aggregation].statistics[item.statisticsType.name];
          } else if (sensors.length > 1) {
            const sensorsStatisticsValues = sensors.map(sensor => {
              const {
                data: [data],
              } = sensor;
              return data[aggregation].statistics[item.statisticsType.name];
            });
            const filteredValues = sensorsStatisticsValues.filter(value => !isNaN(parseFloat(value)));
            if (item.statisticsType.id === STATISTICS_TYPES.MINIMUM.id) {
              featureStatisticsValue = min(filteredValues);
            } else if (item.statisticsType.id === STATISTICS_TYPES.MAXIMUM.id) {
              featureStatisticsValue = max(filteredValues);
            } else {
              featureStatisticsValue = sum(filteredValues) / filteredValues.length;
            }
          }
          const newItem = {};
          newItem.featureName = featureName;
          newItem.value = featureStatisticsValue;
          newItem.type = item.statisticsType;
          newItem.strokeColor = tooltipStroke || stroke;
          newItem.unit = unit;
          newStatistics.push(newItem);
        }
      });
    } else {
      features.forEach(item => {
        if (item.name.startsWith('FEATURE_RAINFALL_SUM')) {
          const newItem = {};
          newItem.featureName = item.name;
          newItem.value = item.sensors[0].data[0].SUM.statistics.maxValue;
          newStatistics.push(newItem);
        }
      });
    }
    return newStatistics;
  }

  // should return first and last datetime of sensor data
  static getDataDateRange(config) {
    if (config && config.charts) {
      const chart = head(config.charts);
      if (chart && chart.features) {
        const feature = head(chart.features);
        if (feature && feature.sensors) {
          const sensor = head(feature.sensors);
          const sensorData = get(sensor, `data[0][${feature.aggregation}].data`);
          if (sensorData) {
            return [head(sensorData).time, last(sensorData).time];
          }
        }
      }
    }
    return [];
  }
}
