import { RSAAAction } from 'redux-api-middleware';

import { getSectionListDateFrom, getSectionListDateTo, getSectionListSearch, getSectionListPage, getSectionListRowsPerPage, getSectionListOrder, getSectionListOrderBy } from '../selectors/sectionList.selectors';

import { getSelectedNamespace } from '../reducers/namespace.reducer';

import { getMachinesApi, getProductionOperationsApi, editMachineApi, getEquipmentsApi, editEquipmentApi, getDriversApi, editDriverApi } from '../../shared/api/telematics/catalogues/telematicsCatalogues.api';
import { getShortDateString } from '../../shared/misc/timeHelpers';
import { DriversListFormValues } from '../components/telematics/drivers/DriversListRow';
import { EquipmentsListFormValues } from '../components/telematics/equipments/EquipmentsListRow';
import { MachineListFormValues } from '../components/telematics/machines/MachinesListRow';

import { CataloguesState } from '../../reducers/catalogues.reducer.types';
import { MachineCategoryCode } from '../../shared/api/telematics/telematics.types';

export const fetchMachines = () =>
  (dispatch: (action: RSAAAction) => void, getState: () => CataloguesState) => {
    const state = getState();
    const selectedNamespace = getSelectedNamespace(state);
    if (!selectedNamespace) return;

    const params = {
      dateTo: getSectionListDateTo(state),
      dateFrom: getSectionListDateFrom(state),
      search: getSectionListSearch(state),
      page: getSectionListPage(state) + 1,
      'per-page': getSectionListRowsPerPage(state),
      'sort-col': getSectionListOrderBy(state),
      'sort-dir': getSectionListOrder(state),
    };
    dispatch(getMachinesApi(params));
  };

export const fetchEquipments = () =>
  (dispatch: (action: RSAAAction) => void, getState: () => CataloguesState) => {
    const state = getState();
    const selectedNamespace = getSelectedNamespace(state);
    if (!selectedNamespace) return;

    const params = {
      dateTo: getSectionListDateTo(state),
      dateFrom: getSectionListDateFrom(state),
      search: getSectionListSearch(state),
      page: getSectionListPage(state) + 1,
      'per-page': getSectionListRowsPerPage(state),
      'sort-col': getSectionListOrderBy(state),
      'sort-dir': getSectionListOrder(state),
    };
    dispatch(getEquipmentsApi(params));
  };

export const fetchDrivers = () =>
  (dispatch: (action: RSAAAction) => void, getState: () => CataloguesState) => {
    const state = getState();
    const selectedNamespace = getSelectedNamespace(state);
    if (!selectedNamespace) return;

    const params = {
      dateTo: getSectionListDateTo(state),
      dateFrom: getSectionListDateFrom(state),
      search: getSectionListSearch(state),
      page: getSectionListPage(state) + 1,
      'per-page': getSectionListRowsPerPage(state),
      'sort-col': getSectionListOrderBy(state),
      'sort-dir': getSectionListOrder(state),
    };
    dispatch(getDriversApi(params));
  };

export const fetchProductionOperations = () =>
  (dispatch: (action: RSAAAction) => void) => {
    const params = {
      dateFrom: getShortDateString(),
      dateTo: getShortDateString(),
    };

    dispatch(getProductionOperationsApi(params));
  };

export const editMachine = (id: number, data: MachineListFormValues) =>
  (dispatch: (action: RSAAAction) => void) => {
    if (!data.productionOperation?.code) return;

    const payload = {
      name: data.name,
      licencePlate: data.licencePlate,
      workingWidth: data.workingWidth ?? 0,
      category: data.machineCategory.code as MachineCategoryCode,
      operationCode: data.productionOperation.code,
    };

    return dispatch(editMachineApi(id, payload));
  };

export const editEquipment = (id: number, data: EquipmentsListFormValues) =>
  (dispatch: (action: RSAAAction) => void) => {
    if (!data.productionOperation?.code) return;

    const payload = {
      name: data.name,
      workingWidth: data.workingWidth ?? 0,
      operationCode: data.productionOperation.code,
    };

    return dispatch(editEquipmentApi(id, payload));
  };

export const editDriver = (id: number, data: DriversListFormValues) =>
  (dispatch: (action: RSAAAction) => void) => {
    const payload = {
      name: data.name,
    };

    return dispatch(editDriverApi(id, payload));
  };
