import moment, { Moment } from 'moment';

export const getShortDateString = (date: string | Moment = moment(), format = 'YYYY-MM-DD') => {
  if (typeof date === 'string') {
    return moment(date).format(format);
  }
  return date.format(format);
};

export const isToday = (date?: string): boolean => {
  const today = moment().startOf('day');
  const movementDate = moment(date).startOf('day');
  return today.isSame(movementDate);
};

export const stringDateToMoment = (date?: string): moment.Moment | undefined => {
  if (date) {
    const momentDate = moment(date);
    if (momentDate.isValid()) {
      return momentDate;
    }
  }
  return undefined;
};
