import angular from 'angular';

import login from './login/login.module';
import map from './map/map.module';
import navbar from './navbar/navbar.module';
import parcels from './parcels/parcels.module';
import precision from './precision/precision.module';
import reset from './reset/reset.module';
import signup from './signup/signup.module';
import user from './user/user.module';

export default angular.module('app.core', [login, signup, reset, map, navbar, parcels, precision, user]).name;
