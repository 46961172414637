import React, { FC, useContext, useMemo } from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { IconButton, Collapse, TableCell, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import moment from 'moment';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { getTelematicsAggregationsBulkEditMode, getTelematicsAggregationsOpenedDriverRows, getTelematicsAggregationsSelected, getTelematicsAggregationsSelectedRides } from '../../selectors/telematicsAggregations.selectors';

import { setTableSubrowSelected } from '../../../shared/actions/table.actions';
import { refetchAggregationsSaga, setOpenedDriverRows } from '../../actions/telematicsAggregations.actions';

import { TELEMATICS } from '../../../core/map/constants/contexts.constants';
import { DUPLICATED_TAB_KEY } from '../../../core/map/constants/localStorage.constants';

import { NAMESPACE as namespace } from '../../reducer/telematicsAggregations.reducer';

import showOnMapIcon from '../../../assets/img/icons/telematics/showOnMap.svg';
import { MAP_CONTEXT_LS_KEY } from '../../../saga/mainMap.saga';
import { changeDrivesStateApi, getTosStatusApi } from '../../../shared/api/telematics/aggregations/aggregations.api';
import CfFormattedNumber from '../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import TimeIntervalsBar from '../../../shared/components/misc/TimeIntervalsBar/TimeIntervalsBar';
import CfTableCell from '../../../shared/components/tables/CfTableCell/CfTableCell';
import CfTableCheckbox from '../../../shared/containers/CfTableCheckbox/CfTableCheckbox';
import LocalStorage from '../../../shared/services/LocalStorage.service';
import { AsyncFn, Thunk } from '../../../types';
import { TelematicsContext, TelematicsNgProps } from '../../containers/Telematics/Telematics';
import { TABS } from '../../containers/TelematicsTabs/TelematicsTabs';
import { saveTelematicsTabToCache } from '../../containers/TelematicsTabs/useTelematicsLogger';
import { getDuration } from '../../helpers';
import TelematicsForeignWarning, { TelematicsForeignWarningType } from '../TelematicsForeignWarning/TelematicsForeignWarning';

import AggregatedRidesList from './AggregatedRidesList';
import ApprovalButton from './ApprovalButton';
import { getIsDisabled } from './RideRow';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { LogbookAggregatedTo, Affiliation, State, EconomicSystem, Type } from '../../../shared/api/telematics/telematics.types';

interface Props extends TelematicsNgProps {
    bulkEditMode: boolean,
    changeState: (ids: string[], state: State) => void;
    columnsLength: number;
    data: LogbookAggregatedTo;
    dateFilter: {
      dateFrom: string;
      dateTo: string;
    };
    logbookFilterHandler: (dateFrom: string, dateTo: string, driver: Record<'code'|'name', string>) => void,
    openedRows: number[];
    refetchAggregationsSaga: () => void;
    refetchTosStatus: () => void;
    rowId: number;
    selected: string[],
    selectedRides: string[],
    setOpenedRows: (driverIds: number[]) => void;
    setTableSubrowSelected: (selected: string[], namespace: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  collapsedRow: {
    height: 0,
  },
  collapsedCell: {
    padding: 0,
    borderBottom: 'none',
  },
  cellMultipleRowsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  timeGraph: {
    display: 'flex',
    alignItems: 'center',
    width: '48vw',
  },
  times: {
    marginRight: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  lastCelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  greyText: {
    color: theme.palette.grey[400],
  },
  withLeftOffset: {
    marginLeft: 16,
  },
  icons: {
    display: 'flex',
    width: 300,
  },
  collapseIcon: {
    marginLeft: 4,
  },
  editCollapseIcon: {
    marginLeft: 'auto',
  },
}));

const DriverRow: FC<Props> = ({
  bulkEditMode,
  changeState,
  columnsLength,
  data,
  dateFilter,
  logbookFilterHandler,
  ngRedirectToMainMapWithFilters,
  openedRows,
  refetchAggregationsSaga,
  refetchTosStatus,
  rowId,
  selected,
  selectedRides,
  setOpenedRows,
  setTableSubrowSelected,
}) => {
  const opened = openedRows?.includes(rowId) ?? false;
  const toggleOpened = () => {
    if (opened) {
      setOpenedRows(openedRows.filter((id) => id !== rowId));
    } else {
      setOpenedRows([...openedRows, rowId]);
    }
  };
  const classes = useStyles();
  const history = useHistory();
  const { farmId } = useParams<{farmId: string}>();
  const intl = useIntl();
  const { economicSystem, economicSystemDate } = useContext(TelematicsContext);

  const hasTosEconomicSystem = (economicSystem === EconomicSystem.TOS) && moment(economicSystemDate).isBefore(moment());

  const timeIntervals = useMemo(() =>
    data.times
      .filter(i => i.type !== Type.HANDWORK)
      .map(({ dateFrom, dateTo }) => `${dateFrom}/${dateTo}`), [data.times],
  );
  const handworkIntervals = useMemo(() =>
    data.times
      .filter(i => i.type === Type.HANDWORK)
      .map(({ dateFrom, dateTo }) => `${dateFrom}/${dateTo}`), [data.times],
  );

  const ridesStates = useMemo(() => new Set(data.drives.map(({ state }) => state)), [data.drives]);
  const ridesValidations = useMemo(() =>
    new Set(data.drives.filter((drive) => drive.state !== 'DEFERRED')
      .map(({ validation }) => validation.isComplete)), [data.drives]);

  const ridesIds = useMemo(() => data.drives
    .filter((drive) => drive.state === State.NOT_APPROVED)
    .map(({ id }) => id), [data.drives]);

  let driverState = State.THIRD_PARTY_ACKNOWLEDGED;
  if (ridesStates.has(State.NOT_APPROVED)) {
    driverState = State.NOT_APPROVED;
  } else if (ridesStates.has(State.APPROVED)) {
    driverState = State.APPROVED;
  } else if (ridesStates.size === 1 && ridesStates.has(State.DEFERRED)) {
    driverState = State.DEFERRED;
  }
  const areAllDrivesValid = !ridesValidations.has(false);

  const handleRefetch = () => {
    refetchAggregationsSaga();
    if (hasTosEconomicSystem) {
      refetchTosStatus();
    }
  };

  const handleApproveAllClick = () =>
    (changeState as AsyncFn<string[], State>)([...ridesIds], State.APPROVED).then(() => handleRefetch());

  const handleShowOnMapClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, openInNewPage?: boolean) => {
    e.stopPropagation();
    let driver = { ...data.driver };
    if (!data.driver) {
      driver = {
        code: 'nodriver',
        name: intl.formatMessage({ id: 'TelematicsList.filter.withoutDriver' }),
      };
    }

    if (openInNewPage) {
      LocalStorage.saveToLocalStorage({
        duplicatedTab: {
          filters: {
            driver,
            dateFrom: dateFilter.dateFrom,
            dateTo: dateFilter.dateTo,
          },
        },
      }, DUPLICATED_TAB_KEY);
    }
    // change map context before redirection
    LocalStorage.saveToLocalStorage(TELEMATICS, MAP_CONTEXT_LS_KEY);
    ngRedirectToMainMapWithFilters(dateFilter.dateFrom, dateFilter.dateTo, undefined, [driver], openInNewPage);
  };

  const handleShowOnMapMouseDown = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e.button === 1) {
      handleShowOnMapClick(e, true);
    }
  };

  const handleFilterInRidesClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, openInNewPage?: boolean) => {
    e.stopPropagation();
    let driverFilter = {
      name: intl.formatMessage({ id: 'TelematicsList.filter.withoutDriver' }),
      code: 'nodriver',
    };

    if (data.driver?.name && data.driver?.code) {
      driverFilter = {
        name: data.driver.name,
        code: data.driver.code,
      };
    }

    const urlToRedirect = `/farm/${farmId}/telematics/logbook`;

    if (openInNewPage) {
      LocalStorage.saveToLocalStorage({
        duplicatedTab: {
          filters: {
            driver: driverFilter,
            dateFrom: dateFilter.dateFrom,
            dateTo: dateFilter.dateTo,
          },
        },
      }, DUPLICATED_TAB_KEY);
      saveTelematicsTabToCache(TABS.LOGBOOK);
      window.open(urlToRedirect, '_blank');
    } else {
      logbookFilterHandler(dateFilter.dateFrom, dateFilter.dateTo, driverFilter);
      history.push(urlToRedirect);
    }
  };

  const handleFilterInRidesMouseDown = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e.button === 1) {
      handleFilterInRidesClick(e, true);
    }
  };

  return (
    <>
      <TableRow data-test={'wrapped-row'} onClick={toggleOpened}>
        {bulkEditMode && (
          <CfTableCheckbox
            disabled={!data.drives.some(d => !getIsDisabled(d))}
            id={data.drives[0].id}
            name="id"
            namespace={namespace}
            selected={selected}
            onCheck={() => {
              const toAdd = data.drives
                .filter(d => !getIsDisabled(d))
                .map(d => d.id);
              const uniqueToAdd = toAdd.filter((val) => !selectedRides.includes(val));
              const res = [...selectedRides, ...uniqueToAdd];
              setTableSubrowSelected(res, namespace);
            }}
            onUncheck={() => {
              const toRemove = data.drives.map(d => d.id);
              const res = selectedRides.filter((val) => !toRemove.includes(val));
              setTableSubrowSelected(res, namespace);
            }}
        />
        )}
        <CfTableCell name="duration">
          <div className={classnames(classes.cellMultipleRowsContainer, classes.withLeftOffset)}>
            <div><FormattedDate value={data.dateFrom} /></div>
            <div className={classes.greyText}>{getDuration(data.duration)}</div>
          </div>
        </CfTableCell>
        <CfTableCell name="driver.name">
          <div className={classes.cellMultipleRowsContainer}>
            <div>{data.driver?.name ?? '-'}</div>
            {data.driver?.affiliation === Affiliation.EXTERNAL &&
              <TelematicsForeignWarning
                text={data.driver?.companyName}
                type={TelematicsForeignWarningType.Driver} />}
            {data.driver?.affiliation !== Affiliation.EXTERNAL &&
            <div className={classes.greyText}>{data.driver?.code ?? '-'}</div>}
          </div>

        </CfTableCell>
        <CfTableCell name="cultivated">
          <div>{data.cultivated ?
            <span><CfFormattedNumber decimalDigits={2} value={data.cultivated} />{' ha'}</span> : '-' }
          </div>
        </CfTableCell>
        <CfTableCell name="distance">
          <span><CfFormattedNumber decimalDigits={2} value={data.distance} /> {data.distance >= 0 ? ' km' : ''}</span>
        </CfTableCell>
        <CfTableCell name="time">
          <div className={classes.lastCelContainer}>
            <div className={classes.timeGraph}>
              <div className={classes.times}>{moment(data.dateFrom).format('HH:mm')}{' - '} {moment(data.dateTo).format('HH:mm')}</div>
              <TimeIntervalsBar
                datetimeEnd={moment(data.dateFrom).endOf('day').toISOString()}
                datetimeStart={moment(data.dateFrom).startOf('day').toISOString()}
                handworkIntervals={handworkIntervals}
                intervals={timeIntervals}
            />
            </div>
            <div className={classes.icons}>
              {!bulkEditMode && (
              <>
                <Tooltip title={<FormattedMessage id="TelematicsAggregations.list.showOnMap" />}>
                  <IconButton
                    aria-label="show on map"
                    onClick={handleShowOnMapClick}
                    onMouseDown={handleShowOnMapMouseDown}
                  >
                    <img alt="show on map" src={showOnMapIcon} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="TelematicsAggregations.list.filterInRides" />}>
                  <IconButton
                    onClick={handleFilterInRidesClick}
                    onMouseDown={handleFilterInRidesMouseDown}
                  >
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
                <ApprovalButton
                  complete={areAllDrivesValid}
                  handleSubmit={handleApproveAllClick}
                  iconTooltipId="TelematicsAggregations.incompleteRide.driver.short"
                  state={driverState}
                  tooltipId="TelematicsAggregations.incompleteRide.driver.long"
                />
              </>
              )}
              <IconButton
                aria-label="Toggle view"
                className={bulkEditMode ? classes.editCollapseIcon : classes.collapseIcon}
                size="large">
                {opened ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </div>
          </div>

        </CfTableCell>
      </TableRow>
      <TableRow className={classes.collapsedRow}>
        <TableCell className={classes.collapsedCell} colSpan={columnsLength}>
          <Collapse in={opened}>
            {data.drives && <AggregatedRidesList data={data.drives} mainRowData={data} opened={opened} />}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  openedRows: getTelematicsAggregationsOpenedDriverRows(state),
  selected: getTelematicsAggregationsSelected(state),
  selectedRides: getTelematicsAggregationsSelectedRides(state),
  bulkEditMode: getTelematicsAggregationsBulkEditMode(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) => bindActionCreators({
  changeState: changeDrivesStateApi,
  refetchAggregationsSaga,
  refetchTosStatus: getTosStatusApi,
  setOpenedRows: setOpenedDriverRows,
  setTableSubrowSelected,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DriverRow);
