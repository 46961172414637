import React, { FC, useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getIsFetchingFertilizerKind, getFertilizerKind } from '../../../../../shared/api/agroevidence/catalogues/eagri/eagri.selectors';

import { getFertilizerKindsApi, resetFertilizerKindApi } from '../../../../../shared/api/agroevidence/catalogues/eagri/eagri.api';
import CfAutocomplete from '../../../../../shared/components/common/CfAutocomplete/CfAutocomplete';
import { Thunk } from '../../../../../types';

import { CataloguesState } from '../../../../../reducers/catalogues.reducer.types';
import { EagriFertilizerKindTo } from '../../../../../shared/api/agroevidence/agroevidence.types';

interface Props {
    defaultValues: EagriFertilizerKindTo,
    disabled?: boolean,
    error?: boolean;
    fertilizerKind: EagriFertilizerKindTo[],
    getFertilizerKindsApi: () => void,
    isFertilizerKindFetching: boolean,
    onChange: (items: EagriFertilizerKindTo) => void,
    resetFertilizerKindApi: () => void
}

const FertilizerKindSelector: FC<Props> = ({
  defaultValues,
  disabled = false,
  error = false,
  fertilizerKind,
  getFertilizerKindsApi,
  isFertilizerKindFetching,
  onChange,
  resetFertilizerKindApi,
}) => {
  useEffect(() => {
    getFertilizerKindsApi();

    return () => {
      resetFertilizerKindApi();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      disabled={disabled}
      error={error}
      helperText={error ? <FormattedMessage id="validation.required" /> : ''}
      id="fertilizer-kind-selector"
      isFetching={isFertilizerKindFetching}
      isMultiple={false}
      label={<FormattedMessage id="Catalogues.fertilizers.detail.fertilizerKind" />}
      loadOptions={getFertilizerKindsApi}
      onChange={onChange}
      suggestions={fertilizerKind}
      testId="fertilizer-kind-selector"
    />
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  fertilizerKind: getFertilizerKind(state),
  isFertilizerKindFetching: getIsFetchingFertilizerKind(state),
});

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      getFertilizerKindsApi,
      resetFertilizerKindApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(FertilizerKindSelector);
