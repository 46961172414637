import React, { FC, ReactNode, useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import {
  getIsFetchingParcelActivities,
  getParcelActivitiesOrder,
  getParcelActivitiesOrderBy,
  getParcelActivitiesPage,
  getParcelActivitiesTotalCount,
  getParcelActivitiesRowsPerPage,
  getParcelActivitiesSelected,
  getSelectedOnPage,
  getParcelActivitiesDateFilter,
  getParcelActivitiesAdvancedFilter,
  getProcessedActivities,
} from '../../selectors/parcelActivities.selectors';

import { fetchParcelActivities, resetParcelActivities } from '../../actions/parcelActivities.actions';

import { NAMESPACE as namespace } from '../../reducer/parcelActivities.reducer';

import CfTableBody from '../../../../../../shared/components/tables/CfTableBody/CfTableBody';
import CfTableBodyEmpty from '../../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty';
import CfTableBodyLoader from '../../../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader';
import CfTableWrapper from '../../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableFooter from '../../../../../../shared/containers/CfTableFooter/CfTableFooter';
import CfTableHead from '../../../../../../shared/containers/CfTableHead/CfTableHead';
import { COLOR_GREY } from '../../../../../../theme';
import { Thunk } from '../../../../../../types';

import { ActionsState } from '../../../../../../reducers/actions.reducer.types';
import { ActionOverviewTo } from '../../../../../../shared/api/agroevidence/agroevidence.types';
import { ParcelActivitiesListAdvancedFilter } from '../../../../../../shared/api/agroevidence/parcels/parcels.types';

const getColDesc = (align: string, sortable: boolean, label: ReactNode) => ({ align, sortable, label });

const ELEMENT_UNIT = '(kg/ha)   ';

const columns = {
  'dateStart,id': getColDesc('inherit', true, <FormattedMessage id="common.date" />),
  'actionType.localizedNames.value': getColDesc('inherit', false, <FormattedMessage id="common.action-name" />),
  source: getColDesc('inherit', false, <FormattedMessage id="common.source" />),
  activityCrop: getColDesc('inherit', false, <FormattedMessage id="common.crop" />),
  area: getColDesc(
    'right',
    false,
    <span>
      <div>
        <FormattedMessage id="common.activityArea" />
      </div>
      <div style={{ color: COLOR_GREY[400] }}>
        <FormattedMessage id="common.parcelArea" />
      </div>
    </span>),
  material: getColDesc('right', false, <FormattedMessage id="common.material" />),
  dose: getColDesc('right', false, <FormattedMessage id="common.totalDose" />),
  dosePerHectare: getColDesc('right',
    false, <FormattedMessage id="common.dosePerHectare" />),
  n: getColDesc(
    'right',
    false,
    <span>
      <FormattedMessage id="common.accountableN" />
      {` ${ELEMENT_UNIT}`}
    </span>),
  p2o5: getColDesc(
    'right',
    false,
    <span>
      P<sub>2</sub>O<sub>5</sub>
      {` ${ELEMENT_UNIT}`}
    </span>),
  k2o: getColDesc(
    'right',
    false,
    <span>
      K<sub>2</sub>O{` ${ELEMENT_UNIT}`}
    </span>),
  mgo: getColDesc('right', false, `MgO ${ELEMENT_UNIT}`),
  cao: getColDesc('right', false, `CaO ${ELEMENT_UNIT}`),
  s: getColDesc('right', false, `S ${ELEMENT_UNIT}`),
};

export interface ParcelActivitiesTableProps {
  activities: ActionOverviewTo[];
  advancedFilter: ParcelActivitiesListAdvancedFilter;
  count: number;
  dateFilter: string;
  farmId: string;
  fetchParcelActivities: (parcelId: string, isDraft?: boolean) => void;
  isFetching: boolean;
  langId: string;
  order: string;
  orderBy: string;
  page: number;
  parcelId: string;
  resetParcelActivities: () => void,
  rowsPerPage: number;
  selected: string[];
  selectedOnPage: string[];
}

export const ParcelActivitiesTable: FC<ParcelActivitiesTableProps> = ({
  advancedFilter = {},
  activities,
  count,
  dateFilter,
  farmId,
  fetchParcelActivities,
  isFetching,
  langId,
  order,
  orderBy,
  page,
  parcelId,
  resetParcelActivities,
  rowsPerPage,
  selected,
  selectedOnPage,
}) => {
  const history = useHistory();

  useEffect(() => () => {
    resetParcelActivities();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  useEffect(() => {
    fetchParcelActivities(parcelId, false);
  // eslint-disable-next-line max-len
  }, [page, rowsPerPage, order, orderBy, dateFilter, parcelId, fetchParcelActivities, advancedFilter]);

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={columns}
        items={activities}
        langId={langId}
        namespace={namespace}
        order={order}
        orderBy={orderBy}
        selected={selected}
        selectedOnPage={selectedOnPage}
        />
      {isFetching && <CfTableBodyLoader columns={columns} />}
      {activities.length && !isFetching ? (
        activities.map(row => (
          <CfTableBody
            columns={columns}
            hover
            key={row.id}
            namespace={namespace}
            row={row}
            selected={selected}
            onClick={() => {
              history.push(`/farm/${farmId}/actions/${row.id}`);
              history.go(0);
            }}
          />
        ))
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      )}
      <CfTableFooter count={count} namespace={namespace} page={page} rowsPerPage={rowsPerPage} />
    </CfTableWrapper>
  );
};

const mapStateToProps = (state: ActionsState) => ({
  activities: getProcessedActivities(state),
  isFetching: getIsFetchingParcelActivities(state),
  count: getParcelActivitiesTotalCount(state),
  page: getParcelActivitiesPage(state),
  order: getParcelActivitiesOrder(state),
  orderBy: getParcelActivitiesOrderBy(state),
  rowsPerPage: getParcelActivitiesRowsPerPage(state),
  selected: getParcelActivitiesSelected(state),
  selectedOnPage: getSelectedOnPage(state),
  dateFilter: getParcelActivitiesDateFilter(state),
  advancedFilter: getParcelActivitiesAdvancedFilter(state),
});

const mapDispatchToProps = (dispatch: Thunk<ActionsState>) =>
  bindActionCreators(
    {
      fetchParcelActivities,
      resetParcelActivities,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ParcelActivitiesTable);
