import React, { CSSProperties, FC, ReactNode } from 'react';

import { Button, Switch, TableBody, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import CfTableCell from '../../../shared/components/tables/CfTableCell/CfTableCell';
import CfTableWrapper from '../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableHead from '../../../shared/containers/CfTableHead/CfTableHead';

import { AgIntegrationStatus } from '../../../shared/api/sentinel/agiStatus/agiStatus.types';

const getColDesc = (sortable: boolean, label: ReactNode, style?: CSSProperties) => ({
  align: 'inherit',
  sortable,
  label,
  style,
});

const columns = {
  status: getColDesc(false, <FormattedMessage id="common.state" />, { width: '80px', alignContent: 'center' }),
  service: getColDesc(false, <FormattedMessage id="common.service" />),
  user_account: getColDesc(false, <FormattedMessage id="common.userAccount" />),
  description: getColDesc(false, <FormattedMessage id="common.description" />, { maxWidth: '200px' }),
};

const useStyles = makeStyles(() => ({
  tableHeaderContainer: {
    paddingBottom: 8,
    alignItems: 'center',
  },
  lastCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '20px',
  },
  button: {
    fontWeight: '500',
    fontSize: 14,
    margin: '5px 0px',
  },
}));

interface Props {
  item: AgIntegrationStatus,
  openAgActivationDialog: () => void,
  openAgDeactivationDialog: () => void,
  setAgIntegrationSetting: () => void
}

const IntegrationsTable: FC<Props> = ({
  item,
  openAgActivationDialog,
  openAgDeactivationDialog,
  setAgIntegrationSetting,
}) => {
  const classes = useStyles();

  return (
    <CfTableWrapper>
      <CfTableHead columns={columns} />
      <TableBody>
        <TableRow key={1}>
          <CfTableCell name={'status'}>
            <Switch
              checked={item.enabled ?? false}
              color="primary"
              data-test={'integration-toggle'}
              name="enabled"
              onClick={openAgDeactivationDialog}
            />
          </CfTableCell>
          <CfTableCell name={'service'}>
            <FormattedMessage id="Agi.service" />
          </CfTableCell>
          <CfTableCell name={'user_account'}>{item.email ?? '-'}</CfTableCell>
          <CfTableCell classes={{ root: classes.lastCell }} name={'description'}>
            <FormattedMessage id="Agi.description" />
            <Button
              className={classes.button}
              color="primary"
              onClick={item.enabled ? setAgIntegrationSetting : openAgActivationDialog}
              type="submit"
              variant={item.enabled ? 'text' : 'contained'}
            >
              <FormattedMessage id={item.enabled ? 'IntegrationSettings.setConnection' : 'IntegrationSettings.connect'} />
            </Button>
          </CfTableCell>
        </TableRow>
      </TableBody>
    </CfTableWrapper>
  );
};

export default IntegrationsTable;
