import React, { FC, useState, useMemo, useRef } from 'react';

import { IconButton, Tooltip } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import RedirectToActionIcon from '../../../assets/img/icons/telematics/redirectToAction.svg';

import { DriveDetailActionTo } from '../../../shared/api/telematics/telematics.types';

const useStyles = makeStyles({
  menuPaper: {
    marginLeft: 15,
  },
});

interface RedirectToActionProps {
  actions: DriveDetailActionTo[],
  ngGoToAction: (actionId?: string) => void;
}

const RedirectToAction: FC<RedirectToActionProps> = ({
  actions,
  ngGoToAction,
}) => {
  const classes = useStyles();
  const anchorEl = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handlePopoverOpen = () => setIsOpen(true);

  const handlePopoverClose = () => setIsOpen(false);

  const menuItems = useMemo(() => actions.map(action => (
    {
      intlId: `common.${action.actionCode}`,
      actionId: action.actionId,
    })), [actions]);

  const oneAction = actions.length === 1;

  return (
    <div>
      {actions.length > 0 && <Tooltip
        disableInteractive
        title={
          <span>
            <FormattedMessage id="TelematicsAggregations.detail.redirectToAction.tooltip" />
          </span>
        }
      >
        <IconButton
          data-test={'redirect-to-actions'}
          onClick={oneAction ? () => ngGoToAction(actions[0].actionId) : handlePopoverOpen}
          ref={anchorEl}
          size="large"
        >
          <img alt="view telematic ride" src={RedirectToActionIcon} />
        </IconButton>
      </Tooltip>}

      <Menu
        anchorEl={anchorEl.current}
        className={classes.menuPaper}
        disableScrollLock={true}
        onClose={handlePopoverClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuItems.map(({ actionId, intlId }, i) =>
          <MenuItem
            key={i}
            onClick={() => ngGoToAction(actionId)}
          >
            <FormattedMessage id={intlId} />
          </MenuItem>,
        )}
      </Menu>
    </div>
  );
};

export default RedirectToAction;
