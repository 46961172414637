import { Component, createElement } from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Field } from 'redux-form';

import Localization from '../../../services/Localization.service';

class CfFormattedField extends Component {
  // view to model
  parse = val => Localization.str2num(val, this.props.intl.locale, this.props.formatDecimal);

  render() {
    const { formatDecimal, ...props } = this.props;
    return createElement(Field, {
      parse: this.parse,
      decimals: formatDecimal,
      ...props,
    });
  }
}

CfFormattedField.propTypes = {
  intl: PropTypes.object.isRequired,
  formatDecimal: PropTypes.number,
};

CfFormattedField.defaultProps = {
  formatDecimal: 2,
};

export default injectIntl(CfFormattedField);
