import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import editorUI from '../core/map/reducer/editor.reducer';
import layersUI from '../core/map/reducer/layersUI.reducer';
import mapUI from '../core/map/reducer/map.reducer';
// TODO po dokonceni prepisu na AGROEVIDENCE prejmenovat parcelsApiNew -> parcelsApi
import parcelsApiNew from '../shared/api/agroevidence/parcels/parcels.reducer';
import sowingPlanReducer from '../shared/api/agroevidence/sowingPlan/sowingPlan.reducer';
import geometryApi from '../shared/api/core/geometry/geometry.reducer';
import authReducer from '../shared/api/gateway/auth/auth.reducer';
import layersApi from '../shared/api/other/layers/layers.reducer';
import aggregationsApi from '../shared/api/telematics/aggregations/aggregations.reducer';
import drivesApi from '../shared/api/telematics/drives/drives.reducer';
import drivesImportHistoryApi from '../shared/api/telematics/drivesImportHistory/drivesImportHistory.reducer';
import machinesApi from '../shared/api/telematics/machines/machines.reducer';
import SectionStatusUIReducer from '../shared/api/telematics/sectionStatus/sectionStatus.reducer';
import drivesImportHistoryUI from '../telematics/reducer/drivesImportHistory.reducer';
import telematicsAggregationDetailReducer from '../telematics/reducer/telematicsAggregationDetail.reducer';
import telematicsAggregationsReducer from '../telematics/reducer/telematicsAggregations.reducer';
import telematicsDetailReducer from '../telematics/reducer/telematicsDetail.reducer';
import telematicsListReducer from '../telematics/reducer/telematicsList.reducer';
import telematicsMachinesReducer, { NAMESPACE as telematicsMachinesUINamespace } from '../telematics/reducer/telematicsMachines.reducer';
import telematicsTabsReducer, { NAMESPACE as telematicsTabsUINamespace } from '../telematics/reducer/telematicsTabs.reducer';

export default history =>
  combineReducers({
    ui: combineReducers({
      telematicsTabs: telematicsTabsReducer(),
      telematicsList: telematicsListReducer(),
      telematicsDetail: telematicsDetailReducer(),
      telematicsAggregations: telematicsAggregationsReducer(),
      telematicsAggregationsMachinesUI: telematicsMachinesReducer(),
      telematicsAggregationDetail: telematicsAggregationDetailReducer(),
      drivesImportHistory: drivesImportHistoryUI(),
      map: mapUI(),
      editor: editorUI(),
      layers: layersUI(),
      cache: () => (['telematicsList', 'telematicsAggregations', telematicsMachinesUINamespace, telematicsTabsUINamespace]),
    }),
    api: combineReducers({
      drives: drivesApi(),
      aggregations: aggregationsApi(),
      parcelsNew: parcelsApiNew(),
      sowingPlan: sowingPlanReducer(),
      geometry: geometryApi(),
      layers: layersApi(),
      drivesImportHistory: drivesImportHistoryApi(),
      machines: machinesApi(),
    }),
    router: connectRouter(history),
    form: formReducer,
    auth: authReducer,
    telematicsStatus: SectionStatusUIReducer(),
  });
