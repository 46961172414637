import React, { Suspense } from 'react';

import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import { LANG_KEYS } from '../../../../../shared/lang/lang.constants';

import CfLoader from '../../../../../shared/components/common/CfLoader/CfLoader';
import BaseContainer from '../../../../../shared/containers/BaseContainer/BaseContainer';
import IntegrationsList from '../../../integrations/IntegrationsList';
import PasswordChange from '../../../password/containers/PasswordChange/PasswordChange';
import Profile from '../../../profile/containers/Profile/Profile';
import UpdateLpis from '../../../updateLpis/UpdateLpis';
import translationsCZ from '../../lang/locale-cs-CZ.json';
import translationsEL from '../../lang/locale-el-GR.json';
import translationsEN from '../../lang/locale-en-US.json';
import translationsES from '../../lang/locale-es-ES.json';
import translationsRO from '../../lang/locale-ro-RO.json';
import translationsRU from '../../lang/locale-ru-UA.json';
import translationsSR from '../../lang/locale-sr-LATN-RS.json';

const translations = {
  [LANG_KEYS.CZ]: translationsCZ,
  [LANG_KEYS.EN]: translationsEN,
  [LANG_KEYS.SR]: translationsSR,
  [LANG_KEYS.ES]: translationsES,
  [LANG_KEYS.RU]: translationsRU,
  [LANG_KEYS.EL]: translationsEL,
  [LANG_KEYS.RO]: translationsRO,
};

const UserWrapper = props => (
  <BaseContainer langId={props.langId} translations={translations}>
    <Suspense fallback={<CfLoader />}>
      <Switch>
        <Route
          path="/farm/:farmId/user/integrations"
          render={routerProps => <IntegrationsList {...props} {...routerProps} />}
        />
        <Route path="/farm/:farmId/user/profile" render={routerProps => <Profile {...props} {...routerProps} />} />
        <Route
          path="/farm/:farmId/user/updatelpis"
          render={routerProps => <UpdateLpis farmId={props.farm.id} {...props} {...routerProps} />}
        />
        <Route
          path="/farm/:farmId/user/password"
          render={routerProps => <PasswordChange {...props} {...routerProps} />}
        />
      </Switch>
    </Suspense>
  </BaseContainer>
);

UserWrapper.propTypes = {
  langId: PropTypes.string.isRequired,
};

export default UserWrapper;
