import moment from 'moment';

import * as granularity from '../../sensors/constants/granularity.constants';

export default class DateTime {
  static dateObject = value => new Date(value);

  static endDateTimeByGranularity = (gran, dateTime) => {
    if (dateTime) {
      const dateTimeMoment = moment(dateTime);
      if (dateTimeMoment.isValid()) {
        switch (gran) {
          case granularity.G30M:
            return dateTimeMoment.add(30, 'm').toDate();
          case granularity.G1H:
            return dateTimeMoment.add(1, 'h').toDate();
          case granularity.G24H:
            return dateTimeMoment.add(24, 'h').toDate();
          case granularity.G4H:
            return dateTimeMoment.add(4, 'h').toDate();
          case granularity.G1W:
            return dateTimeMoment.add(1, 'w').toDate();
          default:
            return dateTimeMoment.toDate();
        }
      }
      return null;
    }
    return null;
  };

  static endDateTimeByNumberOfDays = (dayCount, value) =>
    moment(DateTime.dateObject(value))
      .add(24 * dayCount, 'h')
      .toDate();
}
