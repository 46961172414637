import React, { FC } from 'react';

import { Dialog, DialogContent, DialogTitle, Grid, Link, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import { FertilizerUseReportMissingDataBody } from '../../../../shared/api/agroevidence/agroevidence.types';

const useStyles = makeStyles(() => ({
  dialogContainer: {
    minWidth: '450px',
  },
  itemContainer: {
    padding: '5px 0px',
  },
  label: {
    fontSize: 16,
    fontWeight: 400,
    marginTop: 10,
    marginBottom: 5,
  },
}
));

interface FertilizerUsageReportErrorDialogProps {
  errorDetailedMessage: FertilizerUseReportMissingDataBody,
  farmId: string,
  onClose: () => void,
  showErrorDialog?: boolean
}

const FertilizerUsageReportErrorDialog: FC<FertilizerUsageReportErrorDialogProps> = ({
  errorDetailedMessage,
  farmId,
  onClose,
  showErrorDialog = false,
}) => {
  const classes = useStyles();

  const countSowingCropUsageMissing = errorDetailedMessage.actionIdsMissingUsage.length;
  const countHarvestProductsMissing = errorDetailedMessage.actionIdsMissingProduct.length;

  const linksSowingActions = errorDetailedMessage.actionIdsMissingUsage.map(actionId =>
    `/farm/${farmId}/actions/${actionId}`);
  const linksHarvestActions = errorDetailedMessage.actionIdsMissingProduct.map(actionId =>
    `/farm/${farmId}/actions/${actionId}`);

  return (
    <Dialog
      classes={{ paper: classes.dialogContainer }}
      data-test="report-fertilizers-dialog"
      onClose={onClose}
      open={showErrorDialog}
    >
      <DialogTitle>
        <FormattedMessage id="Reports.fertilizerUsageReport.errorDialogTitle" />
      </DialogTitle>
      <DialogContent>
        <Grid className={classes.itemContainer}>
          <div className={classes.label}>
            <FormattedMessage id="Reports.fertilizerUsageReport.errorDialogSowingLabel" values={{ countSowingCropUsageMissing }} />
          </div>
          <Table>
            <TableBody>
              {linksSowingActions.map((link, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Link href={link} target="_blank" underline="hover">{link}</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <div className={classes.label}>
            <FormattedMessage id="Reports.fertilizerUsageReport.errorDialogHarvestLabel" values={{ countHarvestProductsMissing }} />
          </div>
          <Table>
            <TableBody>
              {linksHarvestActions.map((link, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Link href={link} target="_blank" underline="hover">{link}</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default FertilizerUsageReportErrorDialog;
