import React, { useMemo, useState, ReactNode, CSSProperties, useEffect, FC } from 'react';

import { Checkbox, FormControlLabel, FormGroup, Theme } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { getAgiStatusEnabled } from '../../../../../shared/api/sentinel/agiStatus/agiStatus.selectors';
import { getAgiJobsByApplicationId, getAgiJobsStatusByApplicationId, getAgiMachines } from '../../../../../shared/api/sentinel/variableApplication/variableApplication.selectors';

import { getExpensesExportsTableId } from '../../actions/actions.actions';

import { getAgIntegrationStatusApi } from '../../../../../shared/api/sentinel/agiStatus/agiStatus.api';
import { deleteAGIntegratedJob, downloadAGIntegratedFile, getAGIConnectedMachines, getAGIntegratedJobs, sendExportToMachines } from '../../../../../shared/api/sentinel/variableApplication/variableApplication.api';
import CfDialog from '../../../../../shared/components/common/CfDialog/CfDialog';
import CfTableBodyEmpty from '../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty';
import CfTableWrapper from '../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableHead from '../../../../../shared/containers/CfTableHead/CfTableHead';
import FileService from '../../../../../shared/services/File.service';
import { AsyncFn } from '../../../../../types';

import ExportJob from './ExportJob';
import { getUploadJobsByParentId } from './helpers';
import { MachineFrom, MachineTo, UploaderJob, WriterJob } from './types';

const getColDesc = (sortable: boolean, label: ReactNode, style?: CSSProperties) => ({
  align: 'inherit',
  sortable,
  label,
  style,
});

const COLUMNS = {
  format: getColDesc(false, <span style={{ marginLeft: 5 }}><FormattedMessage id="VaExports.cols.status" /></span>, { width: '6%' }),
  name: getColDesc(false, <FormattedMessage id="VaExports.cols.format" />, { width: '44%' }),
  date: getColDesc(false, <FormattedMessage id="VaExports.cols.date" />),
  icons: {},
};

export const COLUMNS_COUNT = Object.keys(COLUMNS).length;

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    color: theme.palette.grey[500],
    marginBottom: 12,
  },
  dialogList: {
    width: '100%',
    maxHeight: 350,
    marginBottom: 18,
    overflow: 'auto',
  },
  dialogListHint: {
    color: theme.palette.grey[500],
    fontSize: 12,
    fontWeight: 400,
  },
  dialogError: {
    fontSize: 12,
    color: theme.palette.error.main,
  },
}));

let cron: ReturnType<typeof setInterval>;

interface OwnProps {
  applicationId: number;
}
interface ConnectedProps extends OwnProps {
  AGIMachines: MachineFrom[],
  deleteJob: (id: number) => void;
  downloadJobFile: (applicationId: number, jobId: number) => void;
  getAGImachines: () => void;
  getAGIstatus: () => void;
  getJobs: (applicationId: number) => void;
  isAGIEnabled: boolean,
  isFetching: boolean;
  jobs?: {
      uploader: UploaderJob[];
      writer: WriterJob[];
  },
  sendFileToMachines: (applicationId: number, jobId: number, payload: MachineTo) => void;
}

const ExportsList: FC<ConnectedProps> = ({
  AGIMachines,
  applicationId,
  deleteJob,
  downloadJobFile,
  getAGImachines,
  getAGIstatus,
  getJobs,
  isAGIEnabled,
  isFetching,
  jobs,
  sendFileToMachines,
}) => {
  useEffect(() => {
    if (!applicationId) return;
    getJobs(applicationId);
    getAGImachines();
    getAGIstatus();
    cron = setInterval(() => getJobs(applicationId), 30 * 1000);
    return () => { clearInterval(cron); };
  }, [applicationId, getJobs, getAGImachines, getAGIstatus]);

  const classes = useStyles();

  const [selectedJob, setSelectedJob] = useState(0);
  const [selectedMachines, setSelectedMachines] = useState<MachineFrom[]>([]);
  const [dialogError, setDialogError] = useState(false);
  const [openedJobs, setOpenedJobs] = useState<number[]>([]);

  const uploadJobsByParents = useMemo(() => {
    if (!jobs?.writer) return;
    return getUploadJobsByParentId(jobs.writer, jobs.uploader);
  }, [jobs]);

  const handleMachineSelection = (machine: MachineFrom, checked: boolean) => {
    setDialogError(false);
    if (checked) {
      setSelectedMachines([...selectedMachines, machine]);
    } else {
      const newArray = [...selectedMachines.filter((sm) => sm.id !== machine.id)];
      setSelectedMachines(newArray);
    }
  };

  const handleDownloadJobFile = (jobId: number) => () => {
    (downloadJobFile as AsyncFn<number, number>)(applicationId, jobId)
      .then(res => FileService.processFileResponse(res));
  };

  const handleDeleteJobFileFactory = (jobId: number) => () => {
    (deleteJob as AsyncFn<number>)(jobId).then(() => getJobs(applicationId));
  };

  const handleToggleOpen = (jobId: number) => () => {
    if (openedJobs.some(id => id === jobId)) {
      const newOpenedJobs = [...openedJobs.filter(ojId => ojId !== jobId)];
      setOpenedJobs(newOpenedJobs);
    } else {
      setOpenedJobs([...openedJobs, jobId]);
    }
  };

  const handleDialogSubmit = () => {
    if (!selectedMachines.length) {
      setDialogError(true);
    } else {
      (sendFileToMachines as AsyncFn<number, number, MachineTo>)(
        applicationId,
        selectedJob,
        { machines: selectedMachines },
      ).then(() => getJobs(applicationId))
        .then(() => setOpenedJobs([...openedJobs, selectedJob]));
      setSelectedJob(0);
      setSelectedMachines([]);
    }
  };

  const handleDialogCancel = () => {
    setSelectedJob(0);
    setSelectedMachines([]);
  };

  if (!applicationId || !jobs) return null;

  const hasData = jobs?.writer?.length > 0;
  const hasNodata = !isFetching && !hasData;

  return (
    <div id={getExpensesExportsTableId(applicationId)}>
      <div className={classes.heading}><FormattedMessage id="VaExports.heading" /></div>
      <CfTableWrapper>
        <CfTableHead
          columns={COLUMNS}
          namespace="EXPORTS"
    />
        {hasNodata && <CfTableBodyEmpty colLength={Object.keys(COLUMNS).length + 1} translId="VaExports.nodata" />}
        {hasData &&
        <>
          <CfDialog
            acceptText={<FormattedMessage id="VaExports.dialog.accept" />}
            cancelText={<FormattedMessage id="common.cancel" />}
            maxWidth="xs"
            onAccept={handleDialogSubmit}
            onCancel={handleDialogCancel}
            onClose={() => setSelectedJob(0)}
            opened={!!selectedJob}
            title={<FormattedMessage id="VaExports.dialog.title" />}
          >
            <>
              <p className={classes.dialogListHint}><FormattedMessage id="VaExports.dialog.hint" /></p>
              <div className={classes.dialogList}>
                <FormGroup>
                  {AGIMachines && AGIMachines.map((machine) =>
                    <FormControlLabel
                      key={machine.id}
                      label={machine.name}
                      control={
                        <Checkbox onChange={(e) => handleMachineSelection(machine, e.currentTarget.checked)} />
                      }
                    />,
                  )}
                </FormGroup>
                {dialogError && <span className={classes.dialogError}><FormattedMessage id="VaExports.dialog.error" /></span>}
              </div>
            </>
          </CfDialog>
          <TableBody>
            {jobs.writer.map(jw => (
              <ExportJob
                childrenUploadJobs={uploadJobsByParents?.[jw.jobId]}
                enableUpload={isAGIEnabled && !!AGIMachines.length}
                handleDeleteFactory={handleDeleteJobFileFactory}
                handleDownload={handleDownloadJobFile(jw.jobId)}
                handleToggle={handleToggleOpen(jw.jobId)}
                handleUpload={() => setSelectedJob(jw.jobId)}
                job={jw}
                key={jw.jobId}
                opened={openedJobs.some(oj => oj === jw.jobId)}
            />
            ))}
          </TableBody>
          </>
        }
      </CfTableWrapper>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any, ownProps: OwnProps) => ({
  // @ts-ignore
  jobs: getAgiJobsByApplicationId(state, ownProps),
  // @ts-ignore
  isFetching: getAgiJobsStatusByApplicationId(state, ownProps),
  AGIMachines: getAgiMachines(state),
  isAGIEnabled: getAgiStatusEnabled(state),
});

const mapDispatchToProps = {
  getJobs: getAGIntegratedJobs,
  downloadJobFile: downloadAGIntegratedFile,
  deleteJob: deleteAGIntegratedJob,
  getAGImachines: getAGIConnectedMachines,
  sendFileToMachines: sendExportToMachines,
  getAGIstatus: getAgIntegrationStatusApi,
};
export default connect(mapStateToProps, mapDispatchToProps)(ExportsList);
