import { FORM_TYPES, NOT_SOWN_PARCEL_SEED_NAME } from '../actionOther.constants';

export const getFormType = (action) => {
  switch (action.actionType) {
    case FORM_TYPES.HARVEST:
      return action.actionType;
    case FORM_TYPES.SOWING:
      return action.actionType;
    case FORM_TYPES.MOWING:
      return action.actionType;
    default:
      return FORM_TYPES.OTHER;
  }
};

export const isOtherAction = (action) => {
  if (!action || !action.id) return false;
  const formType = getFormType(action);
  return !!formType;
};

export const isParcelSown = (parcel) => {
  if (parcel?.seedApplication) {
    return parcel?.seedApplication?.seed.crop.name !== NOT_SOWN_PARCEL_SEED_NAME;
  }
  return false;
};

export const isAnyParcelSown = (parcels) => {
  if (parcels && parcels.length) {
    return parcels.some(parcel => isParcelSown(parcel));
  }
};

export const isAnyParcelNotSown = (parcels) => {
  if (parcels && parcels.length) {
    return parcels.some(parcel => !isParcelSown(parcel));
  }
};

export const filterOnlySownParcels = (parcels) => {
  if (parcels && parcels.length) {
    return parcels.filter(parcel => isParcelSown(parcel));
  }
};
