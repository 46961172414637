export const PRECISION_URLS = {
  asApplied() { return `${this.main}/as-applied` as const; },
  asAppliedDetail() {
    return `${this.main}/as-applied/:taskDataId/:taskId`;
  },
  main: 'precision-farming',
  services() { return `${this.main}/services` as const; },
  servicesDetail() {
    return `${this.services()}/:parcelId` as const;
  },
} as const;
