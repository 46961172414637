import React from 'react';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import PageHeading from '../../../../shared/components/common/PageHeading/PageHeading';

const useStyles = makeStyles((theme: Theme) => (
  {
    heading: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 30,
      marginBottom: 10,
      fontSize: 24,
      fontWeight: 500,
    },
    heading2: {
      color: theme.palette.grey[500],
    },
  }
));

  interface Props {
      titleTranslateString: string
  }

export const FarmerPortalHeading = ({ titleTranslateString }: Props) => {
  const classes = useStyles();

  return (
    <span className={classes.heading}>
      <span>
        <PageHeading dataTest="report=csv-heading" translationId={titleTranslateString} />
      </span>
      <span className={classes.heading2}>
        <PageHeading dataTest="report=csv-heading" translationId="Reports.sowingPlanHeading.part2" />
      </span>
    </span>
  );
};
