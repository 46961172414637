import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './agiStatus.constants';

import { RsaaMethods, RsaaTypes } from '../../api.types';
import { getCreateAgIntegrationStatusApiParams } from './agiStatus.types';

export const getAgIntegrationStatusApi = () => ({
  [RSAA]: {
    endpoint: 'variable-applications/agintegrated?',
    method: methods.GET as RsaaMethods,
    module: modules.SENTINEL,
    types: [
      types.GET_AGINTEGRATION_REQUEST,
      types.GET_AGINTEGRATION_SUCCESS,
      types.GET_AGINTEGRATION_ERROR,
    ] as RsaaTypes,
  },
});

export const createAgIntegrationApi = (param: getCreateAgIntegrationStatusApiParams) => ({
  [RSAA]: {
    endpoint: 'variable-applications/agintegrated?',
    method: methods.POST as RsaaMethods,
    body: JSON.stringify(param),
    module: modules.SENTINEL,
    types: [
      types.POST_AGINTEGRATION_REQUEST,
      types.POST_AGINTEGRATION_SUCCESS,
      types.POST_AGINTEGRATION_ERROR,
    ] as RsaaTypes,
  },
});

export const putAgIntegrationApi = () => ({
  [RSAA]: {
    endpoint: 'variable-applications/agintegrated?',
    method: methods.PUT as RsaaMethods,
    module: modules.SENTINEL,
    types: [
      types.PUT_AGINTEGRATION_REQUEST,
      types.PUT_AGINTEGRATION_SUCCESS,
      types.PUT_AGINTEGRATION_ERROR,
    ] as RsaaTypes,
  },
});

export const deleteAgIntegrationApi = () => ({
  [RSAA]: {
    endpoint: 'variable-applications/agintegrated?',
    method: methods.DELETE as RsaaMethods,
    module: modules.SENTINEL,
    types: [
      types.DELETE_AGINTEGRATION_REQUEST,
      types.DELETE_AGINTEGRATION_SUCCESS,
      types.DELETE_AGINTEGRATION_ERROR,
    ] as RsaaTypes,
  },
});

export const getAgIntegrationSettingsApi = () => ({
  [RSAA]: {
    endpoint: 'variable-applications/agintegrated/settings?',
    method: methods.GET as RsaaMethods,
    module: modules.SENTINEL,
    types: [
      types.GET_AGINTEGRATION_SETTINGS_REQUEST,
      types.GET_AGINTEGRATION_SETTINGS_SUCCESS,
      types.GET_AGINTEGRATION_SETTINGS_ERROR,
    ] as RsaaTypes,
  },
});
