export const GET_VARIABLE_FERTILIZATION_PARCELS = 'GET_VARIABLE_FERTILIZATION_PARCELS';
export const GET_VARIABLE_FERTILIZATION_PARCELS_SUCCESS = 'GET_VARIABLE_FERTILIZATION_PARCELS_SUCCESS';
export const GET_VARIABLE_FERTILIZATION_PARCELS_FAILURE = 'GET_VARIABLE_FERTILIZATION_PARCELS_FAILURE';
export const RESET_VARIABLE_FERTILIZATION_PARCELS = 'RESET_VARIABLE_FERTILIZATION_PARCELS';

export const GET_VARIABLE_FERTILIZATION_ACTIONS = 'GET_VARIABLE_FERTILIZATION_ACTIONS';
export const GET_VARIABLE_FERTILIZATION_ACTIONS_SUCCESS = 'GET_VARIABLE_FERTILIZATION_ACTIONS_SUCCESS';
export const GET_VARIABLE_FERTILIZATION_ACTIONS_FAILURE = 'GET_VARIABLE_FERTILIZATION_ACTIONS_FAILURE';
export const RESET_VARIABLE_FERTILIZATION_ACTIONS = 'RESET_VARIABLE_FERTILIZATION_ACTIONS';

export const POST_VARIABLE_FERTILIZATION_EXPENSE = 'POST_VARIABLE_FERTILIZATION_EXPENSE';
export const POST_VARIABLE_FERTILIZATION_EXPENSE_SUCCESS = 'POST_VARIABLE_FERTILIZATION_EXPENSE_SUCCESS';
export const POST_VARIABLE_FERTILIZATION_EXPENSE_FAILURE = 'POST_VARIABLE_FERTILIZATION_EXPENSE_FAILURE';
export const RESET_VARIABLE_FERTILIZATION_EXPENSE = 'RESET_VARIABLE_FERTILIZATION_EXPENSE';

export const PUT_VARIABLE_FERTILIZATION_EXPENSE = 'PUT_VARIABLE_FERTILIZATION_EXPENSE';
export const PUT_VARIABLE_FERTILIZATION_EXPENSE_SUCCESS = 'PUT_VARIABLE_FERTILIZATION_EXPENSE_SUCCESS';
export const PUT_VARIABLE_FERTILIZATION_EXPENSE_FAILURE = 'PUT_VARIABLE_FERTILIZATION_EXPENSE_FAILURE';

export const DELETE_VARIABLE_FERTILIZATION_EXPENSE = 'DELETE_VARIABLE_FERTILIZATION_EXPENSE';
export const DELETE_VARIABLE_FERTILIZATION_EXPENSE_SUCCESS = 'DELETE_VARIABLE_FERTILIZATION_EXPENSE_SUCCESS';
export const DELETE_VARIABLE_FERTILIZATION_EXPENSE_FAILURE = 'DELETE_VARIABLE_FERTILIZATION_EXPENSE_FAILURE';

export const GET_VARIABLE_FERTILIZATION_EXPENSES = 'GET_VARIABLE_FERTILIZATION_EXPENSES';
export const GET_VARIABLE_FERTILIZATION_EXPENSES_SUCCESS = 'GET_VARIABLE_FERTILIZATION_EXPENSES_SUCCESS';
export const GET_VARIABLE_FERTILIZATION_EXPENSES_FAILURE = 'GET_VARIABLE_FERTILIZATION_EXPENSES_FAILURE';
export const RESET_VARIABLE_FERTILIZATION_EXPENSES = 'RESET_VARIABLE_FERTILIZATION_EXPENSES';

export const EXPORT_VARIABLE_APPLICATION = 'EXPORT_VARIABLE_APPLICATION';
export const EXPORT_VARIABLE_APPLICATION_SUCCESS = 'EXPORT_VARIABLE_APPLICATION_SUCCESS';
export const EXPORT_VARIABLE_APPLICATION_ERROR = 'EXPORT_VARIABLE_APPLICATION_ERROR';

export const EXPORT_VARIABLE_APPLICATION_LEGACY = 'EXPORT_VARIABLE_APPLICATION_LEGACY';
export const EXPORT_VARIABLE_APPLICATION_LEGACY_SUCCESS = 'EXPORT_VARIABLE_APPLICATION_LEGACY_SUCCESS';
export const EXPORT_VARIABLE_APPLICATION_LEGACY_ERROR = 'EXPORT_VARIABLE_APPLICATION_LEGACY_ERROR';

export const GET_AGI_JOBS = 'GET_AGI_JOBS';
export const GET_AGI_JOBS_SUCCESS = 'GET_AGI_JOBS_SUCCESS';
export const GET_AGI_JOBS_ERROR = 'GET_AGI_JOBS_ERROR';

export const GET_AGI_MACHINES = 'GET_AGI_MACHINES';
export const GET_AGI_MACHINES_SUCCESS = 'GET_AGI_MACHINES_SUCCESS';
export const GET_AGI_MACHINES_ERROR = 'GET_AGI_MACHINES_ERROR';

export const POST_FILE_TO_MACHINES = 'POST_FILE_TO_MACHINES';
export const POST_FILE_TO_MACHINES_SUCCESS = 'POST_FILE_TO_MACHINES_SUCCESS';
export const POST_FILE_TO_MACHINES_ERROR = 'POST_FILE_TO_MACHINES_ERROR';

export const DELETE_AGI_JOB = 'DELETE_AGI_JOB';
export const DELETE_AGI_JOB_SUCCESS = 'DELETE_AGI_JOB_SUCCESS';
export const DELETE_AGI_JOB_ERROR = 'DELETE_AGI_JOB_ERROR';

export const DOWNLOAD_AGI_FILE = 'DOWNLOAD_AGI_FILE';
export const DOWNLOAD_AGI_FILE_SUCCESS = 'DOWNLOAD_AGI_FILE_SUCCESS';
export const DOWNLOAD_AGI_FILE_ERROR = 'DOWNLOAD_AGI_FILE_ERROR';
