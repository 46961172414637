import React, { FC, ReactNode, useEffect } from 'react';

import { Theme } from '@mui/material';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getIsFetchingProductionOperations, getProductionOperations } from '../../../shared/api/telematics/drives/drives.selectors';

import { fetchProductionOperations } from '../../actions/telematicsList.actions';

import CfAutocomplete from '../../../shared/components/common/CfAutocomplete/CfAutocomplete';
import { Thunk } from '../../../types';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { ProductionOperationTo } from '../../../shared/api/telematics/telematics.types';

const useStyles = makeStyles((theme: Theme) => ({
  id: {
    color: theme.palette.grey[500],
    marginTop: '-5px',
  },
}));

interface Props {
  customClasses?: Record<string, string>;
  dateFrom: string;
  dateTo: string;
  defaultValue?: ProductionOperationTo,
  disabled?: boolean;
  error: boolean;
  fetchProductionOperations: (dFrom: string, dTo: string, handwork?: boolean) => void;
  handwork?: boolean;
  helperText: React.ReactElement | string;
  isFetching: boolean;
  label: ReactNode;
  onChange: (value: ProductionOperationTo) => void;
  placeholder?: string;
  productionOperations: ProductionOperationTo[];
}

const handleFilterOptions = createFilterOptions({
  stringify: ({ code, name }) => `${name} (${code})`,
});

const ProductionOperationSelector: FC<Props> = ({
  customClasses,
  dateFrom,
  dateTo,
  defaultValue,
  disabled,
  error,
  fetchProductionOperations,
  handwork,
  helperText,
  isFetching,
  label,
  onChange,
  placeholder,
  productionOperations,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (!dateFrom || !dateTo) return;
    fetchProductionOperations(dateFrom, dateTo, handwork);
  }, [fetchProductionOperations, dateFrom, dateTo, handwork]);

  const handleRenderOption = (option: ProductionOperationTo) => (
    <div>
      <div>{option.name}</div>
      <div className={classes.id}>{option.code}</div>
    </div>
  );

  return (
    <CfAutocomplete
      classes={customClasses}
      defaultValues={defaultValue}
      disabled={disabled}
      error={error}
      filterOptions={handleFilterOptions}
      helperText={helperText}
      id="telematics-dialog-operation-selector"
      isFetching={isFetching}
      isMultiple={false}
      label={label}
      onChange={onChange}
      placeholder={placeholder}
      renderOption={handleRenderOption}
      suggestions={productionOperations}
      testId="telematics-dialog-operation-selector"
    />
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  isFetching: getIsFetchingProductionOperations(state),
  productionOperations: getProductionOperations(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) => bindActionCreators({
  fetchProductionOperations,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductionOperationSelector);
