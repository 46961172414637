import { AnyAction } from 'redux';

import * as types from './telematicsAdmin.constants';

const initialState = {
  farms: [],
  countryCodes: [],
  isFetching: false,
  isFetchingCountryCodes: false,
  totalCount: 0,
};

const TelematicsReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.GET_ACCOUNT_FARMS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_ACCOUNT_FARMS_SUCCESS:
      return {
        ...state,
        farms: action.payload,
        isFetching: false,
        totalCount: Number(action.meta.headers.get('X-Total-Count')),
      };
    case types.GET_COUNTRY_CODES:
      return {
        ...state,
        isFetchingCountryCodes: true,
      };

    case types.GET_COUNTRY_CODES_SUCCESS:
      return {
        ...state,
        countryCodes: action.payload,
        isFetchingCountryCodes: false,
      };
    default:
      return state;
  }
};

export default TelematicsReducer;
