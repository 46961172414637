import React, { useEffect, FC } from 'react';

import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setActionButtonHidden } from '../shared/actions/manager.actions';

import { NAMESPACE } from './reducer/farms.reducer';

import CfTextFilter from '../../../shared/containers/CfTextFilter/CfTextFilter';
import { Thunk } from '../../../types';

import FarmsAdminTable from './components/FarmsAdminTable';

import { AdminState } from '../../../reducers/admin.reducer.types';

interface Props {
  setActionButtonHidden: (bool: boolean) => void;
}

const useStyles = makeStyles({
  filterBarContainer: {
    marginBottom: 6,
    marginLeft: 10,
  },
});

const Farms: FC<Props> = ({ setActionButtonHidden }) => {
  const classes = useStyles();

  useEffect(() => {
    setActionButtonHidden(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Grid className={classes.filterBarContainer} container spacing={0}>
        <Grid item lg={4} md={3} sm={6} xs={12}>
          <CfTextFilter name="farms-admin-text-filter" namespace={NAMESPACE} translId="common.farmNameAndID" />
        </Grid>
      </Grid>
      <FarmsAdminTable />
    </div>
  );
};

const mapDispatchToProps = (dispatch: Thunk<AdminState>) =>
  bindActionCreators(
    {
      setActionButtonHidden,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(Farms);
