import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TractorIcon = ({ height = 21, viewBox = '0 0 21 21', width = 21, ...rest }: SvgIconProps) => (
  <SvgIcon {...rest} style={{ width, height, verticalAlign: 'middle' }} viewBox={viewBox}>
    <path d="M18.5334 12.5V8.23333C18.5334 7.36667 17.8668 6.7 17.0001 6.7H15.6668V3.03333H13.4001V6.63333H11.6668L8.73344 1.36667C8.46677 0.833333 7.86677 0.5 7.26677 0.5H6.26677H3.06677C2.20011 0.566667 1.46677 1.3 1.46677 2.16667V8.23333V9.5C0.600106 10.4333 0.0667725 11.7 0.0667725 13.1C0.0667725 16.0333 2.46677 18.4333 5.40011 18.4333C7.46677 18.4333 9.33344 17.2333 10.2001 15.4333H12.4668C12.7334 17.1 14.2001 18.4333 15.9334 18.4333C17.8668 18.4333 19.4668 16.8333 19.4668 14.9C19.5334 13.9667 19.1334 13.1667 18.5334 12.5ZM5.46677 16.1667C3.80011 16.1667 2.40011 14.7667 2.40011 13.1C2.40011 11.3667 3.80011 10.0333 5.46677 10.0333C7.13344 10.0333 8.53344 11.4333 8.53344 13.1C8.53344 14.7667 7.13344 16.1667 5.46677 16.1667ZM16.0001 16.1667C15.3334 16.1667 14.7334 15.6333 14.7334 14.9C14.7334 14.2333 15.2668 13.6333 16.0001 13.6333C16.6668 13.6333 17.2668 14.1667 17.2668 14.9C17.2001 15.6333 16.6668 16.1667 16.0001 16.1667Z" />
  </SvgIcon>
);

export default TractorIcon;
