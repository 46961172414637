import React from 'react';

import moment from 'moment';
import ReactDOM from 'react-dom';

import reducer from '../../reducers/map.reducer';

import CfApp from '../../shared/containers/CfApp/CfApp';

import MapWrapper from './containers/MapWrapper/MapWrapper';

MapController.$inject = [
  '$element',
  '$scope',
  '$state',
  '$rootScope',
  '$transitions',
  'BACKEND_OPTIONS',
  'ENVIRONMENT',
  'Localization',
  'Auth',
  'ResponseErrorInterceptor',
];

export default function MapController(
  $element,
  $scope,
  $state,
  $rootScope,
  $transitions,
  BACKEND_OPTIONS,
  ENVIRONMENT,
  Localization,
  Auth,
  ResponseErrorInterceptor,
) {
  const props = {
    farm: this.farm,
    farmId: this.farm.id,
    farmBbox: this.farm.boundingBox,
    countryCode: this.farm.customer.countryCode,
    config: { api: BACKEND_OPTIONS, environment: ENVIRONMENT },
    parcelId: this.parcelId,
    ngRedirectToTelematics,
    initDateFrom: this.dateFrom,
    initDateTo: this.dateTo,
    initMachineFilter: this.machine,
    initDriverFilter: this.driver,
    reducer,
    apiProps: {
      farmId: this.farm.id,
      beOpts: BACKEND_OPTIONS,
      logout: Auth.logout,
      handleError: ResponseErrorInterceptor.handleRSAAErrorResponse,
      displayErrorModal: false,
      langId: null,
    },
  };

  const unlistenTranslateChange = $rootScope.$on('$translateChangeSuccess', () => {
    renderWithLang();
  });

  $scope.$on('$destroy', () => {
    unlistenTranslateChange();
    ReactDOM.unmountComponentAtNode($element[0]);
  });

  this.$onInit = function () {
    setTimeout(() => {
      renderWithLang();
    }, 0);
  };

  function renderWithLang() {
    Localization.getCurrentLangIdAsync().then(langId => {
      props.langId = langId;
      props.apiProps.langId = langId;
      moment.locale(langId);
      render();
    });
  }

  function ngRedirectToTelematics(dateFrom, dateTo, machine) {
    return $state.go('farm.active.telematics.logbook', { dateFrom, dateTo, machine });
  }

  const render = () => {
    // hack for root element focus causing inputs losing focus after initial render
    $element[0].click();
    ReactDOM.render(<CfApp {...props} render={p => <MapWrapper {...p} />} />, $element[0]);
  };
}
