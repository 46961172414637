import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { FormattedDate, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getDateFrom, getDuration, getGranularity } from '../../selectors/node.selectors';

import CfChartTooltipDaytime from '../../../shared/components/charts/CfChartTooltipDaytime/CfChartTooltipDaytime';
import CfChartTooltipLabel from '../../../shared/components/charts/CfChartTooltipLabel/CfChartTooltipLabel';
import CfChartTooltipLabelWrapper from '../../../shared/components/charts/CfChartTooltipLabelWrapper/CfChartTooltipLabelWrapper';
import CfChartTooltipWrapper from '../../../shared/components/charts/CfChartTooltipWrapper/CfChartTooltipWrapper';
import DateTime from '../../../shared/services/DateTime.service';
import SensorChartTooltipValueItem from '../SensorChartTooltipValueItem/SensorChartTooltipValueItem';

class SensorChartTooltipRainfall extends Component {
  render() {
    const {
      active,
      payload,
      granularity,
      dateFrom,
      duration,
      features: [sum, increment],
    } = this.props;
    if (active && payload && payload[0]) {
      const sumPayload = payload.find(item => item.name === sum.name);
      const incrementPayload = payload.find(item => item.name === increment.name);
      const label = payload[0].payload?.dateTime ?? '';
      const endDateTime = DateTime.endDateTimeByGranularity(this.props.granularity, label);

      return (
        <CfChartTooltipWrapper>
          <CfChartTooltipLabelWrapper>
            <CfChartTooltipDaytime granularity={granularity} label={label} />
          </CfChartTooltipLabelWrapper>

          <SensorChartTooltipValueItem feature={increment} payload={incrementPayload} />

          <CfChartTooltipLabelWrapper>
            <CfChartTooltipLabel>
              {duration > 365 ?
                <>
                  <FormattedDate day="2-digit" hour="numeric" minute="numeric" month="2-digit" value={dateFrom} year="numeric" />
                  {' – '}
                  <FormattedDate day="2-digit" hour="numeric" minute="numeric" month="2-digit" value={endDateTime} year="numeric" />
                </> :
                <>
                  <FormattedDate day="2-digit" hour="numeric" minute="numeric" month="2-digit" value={dateFrom} />
                  {' – '}
                  <FormattedDate day="2-digit" hour="numeric" minute="numeric" month="2-digit" value={endDateTime} />
                </>
              }
            </CfChartTooltipLabel>
          </CfChartTooltipLabelWrapper>

          <SensorChartTooltipValueItem feature={sum} payload={sumPayload} />
        </CfChartTooltipWrapper>
      );
    }
    return null;
  }
}

SensorChartTooltipRainfall.propTypes = {
  granularity: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired,
  payload: PropTypes.array,
  active: PropTypes.bool,
  dateFrom: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
};

SensorChartTooltipRainfall.defaultProps = {
  payload: [],
  active: false,
  classes: {},
};

const mapStateToProps = state => ({
  dateFrom: getDateFrom(state),
  duration: getDuration(state),
  granularity: getGranularity(state),
});

export default connect(mapStateToProps, null)(injectIntl(SensorChartTooltipRainfall));
