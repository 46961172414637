import React, { FC } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import CfBackButton from '../../../shared/components/common/CfBackButton/CfBackButton';
import PageHeader from '../../../shared/components/common/PageHeader/PageHeader';
import PageHeading from '../../../shared/components/common/PageHeading/PageHeading';
import CfTextBadge from '../../../shared/components/misc/CfTextBadge/CfTextBadge';
import { getDriveBadgeProps } from '../../helpers';

import { useTelematicsRideDetailStyles } from './TelematicsRideDetail';

import { DrivePartDetailTo, Type } from '../../../shared/api/telematics/telematics.types';

interface Props {
  driveDetail?: DrivePartDetailTo;
  handleGoBack: () => void;
  isNew: boolean;
}

const TelematicsRideDetailHeader: FC<Props> = ({
  driveDetail, handleGoBack, isNew,
}) => {
  const classes = useTelematicsRideDetailStyles();
  const intl = useIntl();

  const badgeProps = getDriveBadgeProps(driveDetail?.type ?? Type.AUTOMATIC, intl);

  return (
    <div className={classes.headerWithTabs}>
      <div className={classes.headerWrapper}>
        <PageHeader
          actionButtons={null}
          backButton={
            <CfBackButton onClick={handleGoBack} />
          }
          heading={
            !isNew ?
              <CfTextBadge
                backgroundColor={badgeProps.backgroundColor}
                color={badgeProps.color}
                customClasses={{ container: classes.badge }}
                fontSize={badgeProps.fontSize}
                text={badgeProps.text}
                tooltipText={badgeProps.tooltip}
                          >
                <PageHeading
                  value={
                    <FormattedMessage id="TelematicsList.detailTitle" />
                  }
                />
              </CfTextBadge>
              :
              <PageHeading
                value={<FormattedMessage id="TelematicsList.detailNewTitle" />}
              />
          }
        />
      </div>
    </div>
  );
};

export default TelematicsRideDetailHeader;
