import orderBy from 'lodash/orderBy';
import { RSAA } from 'redux-api-middleware';

import * as sortOrder from '../../../constants/sortOrder.constants';
import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './variableApplication.constants';

// ziskanie existujucich variabilnych davok
export const getVariableActionExpenses = actionId => ({
  [RSAA]: {
    endpoint: `variable-applications/actions/${actionId}?`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.GET_VARIABLE_FERTILIZATION_EXPENSES,
      types.GET_VARIABLE_FERTILIZATION_EXPENSES_SUCCESS,
      types.GET_VARIABLE_FERTILIZATION_EXPENSES_FAILURE,
    ],
  },
});

export const resetVariableActionExpenses = context => ({
  type: types.RESET_VARIABLE_FERTILIZATION_EXPENSES,
  meta: {
    context,
  },
});

// ziskani actionIds ktore su VA
export const getVariableActionIds = () => ({
  [RSAA]: {
    endpoint: 'variable-applications/actions?',
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.GET_VARIABLE_FERTILIZATION_ACTIONS,
      types.GET_VARIABLE_FERTILIZATION_ACTIONS_SUCCESS,
      types.GET_VARIABLE_FERTILIZATION_ACTIONS_FAILURE,
    ],
  },
});

export const resetVariableActionIds = context => ({
  type: types.RESET_VARIABLE_FERTILIZATION_ACTIONS,
  meta: {
    context,
  },
});

// ziskani parcelIds na kterych je povoleny variabilni aplikace
export const getVariableParcelIds = () => ({
  [RSAA]: {
    endpoint: 'variable-applications/parcels?',
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.GET_VARIABLE_FERTILIZATION_PARCELS,
      types.GET_VARIABLE_FERTILIZATION_PARCELS_SUCCESS,
      types.GET_VARIABLE_FERTILIZATION_PARCELS_FAILURE,
    ],
  },
});

export const resetVariableParcelIds = context => ({
  type: types.RESET_VARIABLE_FERTILIZATION_PARCELS,
  meta: {
    context,
  },
});

export const createVariableExpense = ({
  applicationZones,
  averageDoseHa,
  chemicalElement,
  cropLegCode,
  dateFrom,
  dateTo,
  geometry,
  indexType,
  materialId,
  maxDoseHa,
  minDoseHa,
  parcelId,
  subtractionGeometry,
  type,
  unit,
}) => {
  const body = {
    parcelId,
    materialId,
    cropLegCode,
    geometry,
    subtractionGeometry,
    type: applicationZones ? 'RECOMPUTE' : type,
    index: {
      type: indexType,
      chemicalElement,
      dateFrom,
      dateTo,
    },
    unit,
    ...(averageDoseHa ? { averageDoseHa } : {}),
    ...(minDoseHa ? { minDoseHa } : {}),
    ...(maxDoseHa ? { maxDoseHa } : {}),
    ...(applicationZones ? { applicationZones } : {}),
  };

  return {
    [RSAA]: {
      endpoint: 'variable-applications?',
      method: methods.POST,
      module: modules.SENTINEL,
      body: JSON.stringify(body),
      types: [
        {
          type: types.POST_VARIABLE_FERTILIZATION_EXPENSE,
        },
        {
          type: types.POST_VARIABLE_FERTILIZATION_EXPENSE_SUCCESS,
          payload: (action, state, res) =>
            res.json().then(data => ({
              ...data,
              applicationZones: orderBy(data.applicationZones, 'quality', sortOrder.DESC),
              type,
            })),
        },
        {
          type: types.POST_VARIABLE_FERTILIZATION_EXPENSE_FAILURE,
        },
      ],
    },
  };
};

export const saveVariableExpense = ({ actionId, variableExpenseDTO }) => ({
  [RSAA]: {
    endpoint: `variable-applications/actions/${actionId}?`,
    method: methods.PUT,
    module: modules.SENTINEL,
    body: JSON.stringify(variableExpenseDTO),
    types: [
      types.PUT_VARIABLE_FERTILIZATION_EXPENSE,
      types.PUT_VARIABLE_FERTILIZATION_EXPENSE_SUCCESS,
      types.PUT_VARIABLE_FERTILIZATION_EXPENSE_FAILURE,
    ],
  },
});

export const resetVariableExpense = context => ({
  type: types.RESET_VARIABLE_FERTILIZATION_EXPENSE,
  meta: {
    context,
  },
});

export const deleteVariableExpenses = actionId => ({
  [RSAA]: {
    endpoint: `variable-applications/actions/${actionId}?`,
    method: methods.DELETE,
    module: modules.SENTINEL,
    types: [
      types.DELETE_VARIABLE_FERTILIZATION_EXPENSE,
      types.DELETE_VARIABLE_FERTILIZATION_EXPENSE_SUCCESS,
      types.DELETE_VARIABLE_FERTILIZATION_EXPENSE_FAILURE,
    ],
  },
});

export const exportVariableApplication = (applicationId, exportType, material, parcel, parcelNumber) => ({
  [RSAA]: {
    endpoint:
    // eslint-disable-next-line max-len
    `variable-applications/${applicationId}/export?export-type=${exportType}&parcel-name=${parcel}&material-name=${material}&parcel-number=${parcelNumber}&`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.EXPORT_VARIABLE_APPLICATION,
      types.EXPORT_VARIABLE_APPLICATION_SUCCESS,
      types.EXPORT_VARIABLE_APPLICATION_ERROR,
    ],
  },
});

export const exportVariableApplicationLegacy = (applicationId, exportType) => ({
  [RSAA]: {
    endpoint: `variable-applications/${applicationId}/export/legacy?export-type=${exportType}&`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.EXPORT_VARIABLE_APPLICATION_LEGACY,
      { type: types.EXPORT_VARIABLE_APPLICATION_LEGACY_SUCCESS,
        payload: (action, state, res) => ({
          body: res.blob(),
          disposition: res.headers.get('Content-Disposition'),
          type: res.headers.get('Content-Type'),
        }),
      },
      types.EXPORT_VARIABLE_APPLICATION_LEGACY_ERROR,
    ],
  },
});

export const getAGIntegratedJobs = (applicationId) => ({
  [RSAA]: {
    endpoint: `variable-applications/${applicationId}/agintegrated-jobs?`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      { type: types.GET_AGI_JOBS,
        payload: () => ({
          applicationId,
        }),
      },
      {
        type: types.GET_AGI_JOBS_SUCCESS,
        payload: (action, state, res) => res.json().then(data => ({
          applicationId,
          jobs: [...data],
        })),
      },
      types.GET_AGI_JOBS_ERROR,
    ],
  },
});

export const getAGIConnectedMachines = () => ({
  [RSAA]: {
    endpoint: 'variable-applications/agintegrated/machine?',
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.GET_AGI_MACHINES,
      types.GET_AGI_MACHINES_SUCCESS,
      types.GET_AGI_JOBS_ERROR,
    ],
  },
});

export const deleteAGIntegratedJob = (jobId) => ({
  [RSAA]: {
    endpoint: `variable-applications/agintegrated-jobs/${jobId}?`,
    method: methods.DELETE,
    module: modules.SENTINEL,
    types: [
      types.DELETE_AGI_JOB,
      types.DELETE_AGI_JOB_SUCCESS,
      types.DELETE_AGI_JOB_ERROR,
    ],
  },
});

export const downloadAGIntegratedFile = (applicationId, jobId) => ({
  [RSAA]: {
    endpoint: `variable-applications/${applicationId}/download-file/${jobId}?`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.DOWNLOAD_AGI_FILE,
      { type: types.DOWNLOAD_AGI_FILE_SUCCESS,
        payload: (action, state, res) => ({
          body: res.blob(),
          disposition: res.headers.get('Content-Disposition'),
          type: res.headers.get('Content-Type'),
        }),
      },
      types.DOWNLOAD_AGI_FILE_ERROR,
    ],
  },
});

export const sendExportToMachines = (applicationId, jobId, machines) => ({
  [RSAA]: {
    endpoint: `variable-applications/${applicationId}/agintegrated-jobs/${jobId}/machine?`,
    method: methods.POST,
    module: modules.SENTINEL,
    body: JSON.stringify(machines),
    types: [
      types.POST_FILE_TO_MACHINES,
      types.POST_FILE_TO_MACHINES_SUCCESS,
      types.POST_FILE_TO_MACHINES_ERROR,
    ],
  },
});
