import React, { useEffect, ReactNode, CSSProperties, FC } from 'react';

import { TableBody } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getAccountFarms, getAccountFarmsTotalCount, isFetchingAccountFarms } from '../../../../shared/api/telematics/admin/telematicsAdmin.selectors';
import { getTelematicsAdminAdvancedFilter, getTelematicsAdminOrder, getTelematicsAdminOrderBy, getTelematicsAdminPage, getTelematicsAdminRowsPerPage, getTelematicsAdminSelected, getTelematicsAdminSelectedOnPage, getTelematicsAdminTextFilter } from '../selectors/telematicsAdmin.selectors';

import { fetchAccountFarms } from '../selectors/telematicsAdmin.actions';

import { NAMESPACE } from '../reducer/telematicsAdmin.reducer';

import CfTableBodyEmpty from '../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty';
import CfTableBodyLoader from '../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader';
import CfTableWrapper from '../../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableFooter from '../../../../shared/containers/CfTableFooter/CfTableFooter';
import CfTableHead from '../../../../shared/containers/CfTableHead/CfTableHead';
import { Thunk } from '../../../../types';

import Row from './Row';

import { AdminState, TelematicsAdvancedFilter } from '../../../../reducers/admin.reducer.types';
import { AccountTo } from '../../../../shared/api/telematics/telematics.types';

const getColDesc = (sortable: boolean, label: ReactNode, style?: CSSProperties) => ({
  align: 'inherit',
  sortable,
  label,
  style,
});

const colStyles = {
  doubleLined: {
    display: 'flex',
    flexDirection: 'column' as const,
  },
  farm: {
    fontWeight: 500,
  },
};

const COLUMNS = {
  name: getColDesc(true,
    <div style={{ ...colStyles.doubleLined }}>
      <span style={{ ...colStyles.farm }}><FormattedMessage id="TelematicsAdmin.columns.farm" /></span>
      <span><FormattedMessage id="TelematicsAdmin.columns.id" /></span>
    </div>,
  ),
  externalId: getColDesc(true, <FormattedMessage id="TelematicsAdmin.columns.ico" />),
  countryCode: getColDesc(true, <FormattedMessage id="TelematicsAdmin.columns.country" />),
  subholding: getColDesc(false, <FormattedMessage id="TelematicsAdmin.columns.subholding" />),
  holding: getColDesc(false, <FormattedMessage id="TelematicsAdmin.columns.holding" />),
  catalogue: getColDesc(false, <FormattedMessage id="TelematicsAdmin.columns.catalogue" />),
  economicSystem: getColDesc(false, <FormattedMessage id="TelematicsAdmin.columns.economicSystem" />),
  economicSystemDate: getColDesc(false,
    <div style={{ ...colStyles.doubleLined }}>
      <span><FormattedMessage id="TelematicsAdmin.columns.economicSystemDate1" /></span>
      <span><FormattedMessage id="TelematicsAdmin.columns.economicSystemDate2" /></span>
    </div>,
  ),
  automaticActions: getColDesc(false,
    <div style={{ ...colStyles.doubleLined }}>
      <span><FormattedMessage id="TelematicsAdmin.columns.automaticActions1" /></span>
      <span><FormattedMessage id="TelematicsAdmin.columns.automaticActions2" /></span>
    </div>,
  ),
  enabled: getColDesc(true, <FormattedMessage id="TelematicsAdmin.columns.state" />),
  activated: getColDesc(true, <FormattedMessage id="TelematicsAdmin.columns.activationDate" />),
  deactivated: getColDesc(true, <FormattedMessage id="TelematicsAdmin.columns.deactivationDate" />),
};

interface Props {
  accountFarms: AccountTo[];
  advancedFilter: TelematicsAdvancedFilter;
  fetchAccountFarms: () => void;
  isFetchingFarms: boolean,
  order: string;
  orderBy: string;
  page: number;
  rowsPerPage: number;
  selected: string[];
  selectedOnPage: string[];
  textFilter: string;
  totalCount: number;
}

const TelematicsAdminTable: FC<Props> = ({
  accountFarms,
  advancedFilter,
  fetchAccountFarms,
  isFetchingFarms,
  order,
  orderBy,
  page,
  rowsPerPage,
  selected,
  selectedOnPage,
  textFilter,
  totalCount,
}) => {
  useEffect(() => {
    fetchAccountFarms();
  }, [order, orderBy, page, rowsPerPage, advancedFilter, textFilter, fetchAccountFarms]);

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={COLUMNS}
        itemId="farmId"
        items={accountFarms}
        namespace={NAMESPACE}
        order={order}
        orderBy={orderBy}
        selected={selected}
        selectedOnPage={selectedOnPage}
      />
      {isFetchingFarms && <CfTableBodyLoader columns={COLUMNS} />}

      {!isFetchingFarms && accountFarms.length ? (
        <TableBody>
          {accountFarms.map(item => <Row item={item} key={item.farmId} namespace={NAMESPACE} selected={selected} />)}
        </TableBody>
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(COLUMNS).length + 1} />
      )}

      <CfTableFooter
        count={totalCount}
        namespace={NAMESPACE}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const mapStateToProps = (state: AdminState) => ({
  accountFarms: getAccountFarms(state),
  isFetchingFarms: isFetchingAccountFarms(state),
  order: getTelematicsAdminOrder(state),
  orderBy: getTelematicsAdminOrderBy(state),
  selected: getTelematicsAdminSelected(state),
  selectedOnPage: getTelematicsAdminSelectedOnPage(state),
  page: getTelematicsAdminPage(state),
  rowsPerPage: getTelematicsAdminRowsPerPage(state),
  totalCount: getAccountFarmsTotalCount(state),
  advancedFilter: getTelematicsAdminAdvancedFilter(state),
  textFilter: getTelematicsAdminTextFilter(state),
});

const mapDispatchToProps = (dispatch: Thunk<AdminState>) => bindActionCreators({
  fetchAccountFarms,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TelematicsAdminTable);
