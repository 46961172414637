import React, { FC, ReactNode, useEffect, useCallback, useMemo } from 'react';

import { Chip } from '@mui/material';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getDriveSubjects, getIsFetchingDriveSubjects } from '../../../../shared/api/telematics/drives/drives.selectors';

import { fetchDriveSubject } from '../../../actions/telematicsList.actions';

import { resetDriveSubject } from '../../../../shared/api/telematics/drives/drives.api';
import { getFarmExternalId } from '../../../../shared/api/telematics/sectionStatus/sectionStatus.selector';
import CfAutocomplete from '../../../../shared/components/common/CfAutocomplete/CfAutocomplete';
import { Thunk } from '../../../../types';
import useAdvancedFilterStyles from '../TelematicsAdvancedFilter.styles';

import { TelematicsState } from '../../../../reducers/telematics.reducer.types';
import { DriveSubjectTo } from '../../../../shared/api/telematics/telematics.types';

interface ParcelSubjectSelectorProps {
  defaultValues?: DriveSubjectTo[];
  externalId: string;
  fetchDriveSubject: () => void;
  isFetching: boolean;
  label: ReactNode;
  onChange: (items: DriveSubjectTo[]) => void;
  options: DriveSubjectTo[];
  resetDriveSubject: () => void;
}

const defaultDefaultValues: DriveSubjectTo[] = [];

const handleGetSelected = (option: DriveSubjectTo, value?: DriveSubjectTo | null) =>
  option.id === value?.id;

const handleFilterOptions = createFilterOptions({
  stringify: ({ id, name }) => `${name} ${id}`,
});

const ParcelSubjectSelector: FC<ParcelSubjectSelectorProps> = (
  { defaultValues = defaultDefaultValues,
    options,
    isFetching,
    label,
    fetchDriveSubject,
    resetDriveSubject,
    onChange,
    externalId,
  }) => {
  const classes = useAdvancedFilterStyles();
  const intl = useIntl();

  useEffect(() => {
    fetchDriveSubject();
    return () => {
      resetDriveSubject();
    };
  }, [fetchDriveSubject, resetDriveSubject]);

  const extendedeOptions = useMemo(() => ([
    {
      id: externalId,
      name: intl.formatMessage({ id: 'TelematicsList.filter.parcelUser.ownFarm' }),
    },
    ...options,
  ]), [externalId, intl, options]);

  const handleRenderOption = useCallback((option: DriveSubjectTo) => (
    <div>
      <div>{option.name}</div>
      <div className={classes.subtext}>{option.id}</div>
    </div>
  ), [classes]);

  const handleRenderTags = useCallback((values: DriveSubjectTo[], getTagProps: AutocompleteRenderGetTagProps) =>
    <>
      {values.map((value, index) => (
        <Chip
          {...getTagProps({ index })}
          key={value.id}
          label={
            <>
              {value.name}
              <span className={classes.subtext}>{value.id}</span>
            </>
          }
        />
      ))}
    </>, [classes]);

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      filterOptions={handleFilterOptions}
      getSelected={handleGetSelected}
      id="drive-subject-selector"
      isFetching={isFetching}
      isMultiple={true}
      label={label}
      onChange={onChange}
      renderOption={handleRenderOption}
      renderTags={handleRenderTags}
      suggestions={extendedeOptions}
      testId="drive-subject-filter"
    />
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  options: getDriveSubjects(state),
  isFetching: getIsFetchingDriveSubjects(state),
  externalId: getFarmExternalId(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      fetchDriveSubject,
      resetDriveSubject,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ParcelSubjectSelector);
