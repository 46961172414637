import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';

import { COLOR_GREY } from '../../../theme';
import { FileLoadItem } from '../CreateRidesButton/importRidesDialog';

import FileItem from './FileItem';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
  },
  body: {
    background: COLOR_GREY[100],
    padding: 0,
    position: 'relative',
  },
  bodyIsLoading: {
    opacity: 0.5,
  },
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'transparent',
    display: 'grid',
    placeItems: 'center',
  },
  bottomContainer: {
    padding: '0 16px 16px 16px',

    display: 'flex',
    flexDirection: 'column',
  },
  loadedFiles: {
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    padding: 0,
  },
  dropzone: {
    display: 'grid',
    placeItems: 'center',
    padding: '32px 27px 32px 27px',
    cursor: 'pointer',
    '&.Mui-disabled': {
      cursor: 'not-allowed',
    },
  },
  'dropzone-title': {
    fontSize: 16,
    fontWeight: 400,
  },
  'dropzone-formats': {
    fontSize: 13,
    color: COLOR_GREY[500],
    textTransform: 'uppercase',
  },
  importButton: {
    alignSelf: 'flex-end',
    '&.Mui-disabled': {
      color: theme.palette.primary.main,
      backgroundColor: 'inherit',
    },
  },
}));

type Props = {
  allFiles: FileLoadItem[];
  handleFilesValidationApi: (newFiles: FileLoadItem[]) => void;
  loading: boolean;
  setAllFiles: React.Dispatch<React.SetStateAction<FileLoadItem[]>>;
}

const FileUploader: React.FC<Props> = ({
  allFiles, handleFilesValidationApi, loading, setAllFiles }) => {
  const classes = useStyles();

  const { getInputProps, getRootProps } = useDropzone({
    accept: {
      'application/zip': ['.zip'],
      // 'application/xml': ['.xml'],
      // 'text/csv': ['.csv'],
    },
    onDrop: (accepted) => {
      const newFiles = [...accepted] as File[];
      const newFilesToSave: FileLoadItem[] = newFiles.map(file => ({
        file,
        uniqueId: _.uniqueId(),
        status: 'new',
      } as FileLoadItem));
      setAllFiles((prevState) => [...prevState, ...newFilesToSave]);
      handleFilesValidationApi(newFilesToSave);
    },
    disabled: loading,
  });

  const handleRemoveFile = (idToRemove?: string) => {
    setAllFiles((prevFiles) => prevFiles.filter((file) => file.uniqueId !== idToRemove));
  };

  return (
    <section className={classes.container}>
      <div className={classNames(classes.body, { [classes.bodyIsLoading]: loading })}>
        <div {...getRootProps({ className: classes.dropzone })}>
          <input {...getInputProps()} />
          <div className={classes['dropzone-title']}>
            <FormattedMessage id="FileUploader.title" />
          </div>
          {!allFiles.length && (
          <div className={classes['dropzone-formats']}>
            <FormattedMessage id="FileUploader.formats" />
          </div>
          )}
        </div>
        {allFiles.length > 0 && (
        <aside className={classes.bottomContainer}>
          <ul className={classes.loadedFiles}>
            {allFiles.map((item) => (
              <FileItem item={item} key={item.uniqueId} onRemove={handleRemoveFile} />
            ))}
          </ul>
        </aside>
        )}
      </div>
      {loading && <div className={classes.loader}>
        <CircularProgress />
        </div>}
    </section>
  );
};

export default FileUploader;
