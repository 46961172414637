import React, { Component } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Popover from '@mui/material/Popover';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getIsFetching } from '../../../../shared/api/agroevidence/farms/farms.selectors';
import { getFarmsSuggestions } from '../../../manager/shared/selectors/manager.selectors';

import { getFarmsByName, resetFarms } from '../../../manager/shared/actions/manager.actions';
import { getUsersByFarm } from '../../actions/impersonation.actions';

import impersonateFarmSvg from '../../../../assets/img/impersonate-farm.svg';
import withPopover from '../../../../shared/hocs/withPopover';
import AdminFarmSelector from '../../../shared/components/AdminFarmSelector/AdminFarmSelector';
import ImpersonationButton from '../../components/ImpersonationButton/ImpersonationButton';

const styles = theme => ({
  panel: {
    position: 'fixed',
    bottom: 15,
    right: 15,
    zIndex: 5147483000,
    background: theme.palette.background.paper,
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    borderRadius: 2,
  },
  image: {
    marginRight: theme.spacing(1),
    position: 'relative',
    top: '-2px',
  },
  buttonFarmSelector: {
    borderRadius: '2px 0px 0px 2px',
  },
  buttonCancelImpersonation: {
    borderRadius: '0px 2px 2px 0px',
    minWidth: 56,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
    position: 'relative',
    top: -1,
  },
  clearIcon: {
    color: theme.palette.grey[400],
  },
  clearIconActive: {
    color: theme.palette.background.paper,
  },
  popoverPaper: {
    width: 350,
    height: 350,
    overflow: 'hidden',
  },
  modalRoot: {
    zIndex: 5147483002,
  },
});

export class ImpersonationPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      farm: null,
    };

    this.hasStartedDragging = false;
  }

  componentDidMount() {
    const { farm, ngIsImpersActive } = this.props;
    if (ngIsImpersActive() && farm) {
      this.setInitFarm(farm);
    }
  }

  onDrag = () => {
    this.hasStartedDragging = true;
  };

  onSuggestionSelect = farm => {
    this.props.getUsersByFarm(farm.id).then(res => {
      this.setState({ farm });
      this.props.handlePopoverClose();
      const { payload } = res;
      if (Array.isArray(payload) && payload[0]) {
        this.props.ngImpersActivate(farm, payload[0].userId);
      }
    });
  };

  onSuggestionClear = () => {
    this.props.handlePopoverClose();
    this.props.resetFarms();
  };

  setInitFarm = farm => {
    this.setState({ farm });
  };

  // select farm and stay in impersonation mode
  startSelectingFarms = evt => {
    if (this.hasStartedDragging) {
      this.hasStartedDragging = false;
      return;
    }

    // dont allow to continue in impersonation if already impersonated
    if (this.props.ngIsImpersActive()) {
      return;
    }

    this.props.handlePopoverOpen(evt);
    this.props.getFarmsByName(null, { rowsPerPage: 50 });
  };

  // clear selected farm and stay in impersonation mode
  clearSelectedFarm = () => {
    this.setState({ farm: null });
    this.props.handlePopoverClose();
    this.props.ngImpersDeactivate();
  };

  // clear selected farm and leave impersonation mode
  impersDisable = () => {
    this.props.ngImpersDisable();
  };

  render() {
    const { farm } = this.state;
    const { anchorEl, classes, isOpen, ngIsImpersActive, suggestions } = this.props;
    const isImpersActive = ngIsImpersActive();

    return (
      <Draggable bounds="html" disabled={isOpen} onDrag={this.onDrag}>
        <div className={classes.panel}>
          <ImpersonationButton
            callback={this.startSelectingFarms}
            disabled={isImpersActive}
            farm={farm}
            testId="select-farm"
            classes={{
              root: classes.buttonFarmSelector,
            }}
          >
            <img alt="Impersonate farm" className={classes.image} src={impersonateFarmSvg} />
            {farm ? <span>{farm.name}</span> : <FormattedMessage id="Impersonation.impersonate-farm" />}
            {!isImpersActive && <ArrowDropDownIcon className={classes.rightIcon} />}
          </ImpersonationButton>
          {farm ? (
            <ImpersonationButton
              callback={this.clearSelectedFarm}
              farm={farm}
              testId="close"
              classes={{
                root: classes.buttonCancelImpersonation,
              }}
            >
              <ClearIcon className={`${classes.clearIcon} ${farm ? classes.clearIconActive : ''}`} />
            </ImpersonationButton>
          ) : (
            <ImpersonationButton
              callback={this.impersDisable}
              farm={farm}
              testId="cancel"
              classes={{
                root: classes.buttonCancelImpersonation,
              }}
            >
              <ExitToAppIcon className={`${classes.clearIcon} ${farm ? classes.clearIconActive : ''}`} />
            </ImpersonationButton>
          )}

          <Popover
            anchorEl={anchorEl}
            classes={{ paper: classes.popoverPaper }}
            className={classes.modalRoot}
            onClose={this.onSuggestionClear}
            open={isOpen}
            action={actions => {
              this.popoverActions = actions;
            }}
            PaperProps={{
              'data-test': 'farm-list',
            }}
            transformOrigin={{
              horizontal: 'left',
              vertical: 'bottom',
            }}
          >
            <AdminFarmSelector
              getFarmsByName={this.props.getFarmsByName}
              isFetching={this.props.isFetchingFarms}
              onSuggestionClear={this.onSuggestionClear}
              onSuggestionSelect={this.onSuggestionSelect}
              suggestions={suggestions}
            />
          </Popover>
        </div>
      </Draggable>
    );
  }
}

ImpersonationPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  farm: PropTypes.object,
  suggestions: PropTypes.array,
  getFarmsByName: PropTypes.func.isRequired,
  resetFarms: PropTypes.func.isRequired,
  getUsersByFarm: PropTypes.func.isRequired,
  ngImpersDisable: PropTypes.func,
  ngIsImpersActive: PropTypes.func,
  ngImpersActivate: PropTypes.func,
  ngImpersDeactivate: PropTypes.func,
  handlePopoverOpen: PropTypes.func.isRequired,
  handlePopoverClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.any,
  isFetchingFarms: PropTypes.bool.isRequired,
};

ImpersonationPanel.defaultProps = {
  suggestions: [],
  ngImpersDisable: () => {},
  ngIsImpersActive: () => {},
  ngImpersActivate: () => {},
  ngImpersDeactivate: () => {},
  farm: {},
  anchorEl: null,
};

const mapStateToProps = state => ({
  suggestions: getFarmsSuggestions(state),
  isFetchingFarms: getIsFetching(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getFarmsByName,
      resetFarms,
      getUsersByFarm,
    },
    dispatch,
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withPopover,
)(ImpersonationPanel);
