import React, { Component } from 'react';

import { FormControlLabel, Radio, RadioGroup, Theme } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { getFarmName, getIsAuthenticated } from '../../../../shared/api/agroevidence/eagriAuth/eagriAuth.selectors';

import { setFarmName, setFetch } from '../../actions/signup.actions';

import { getEagriFarmName } from '../../../../shared/api/agroevidence/eagriAuth/eagriAuth.api';
import { AsyncFn } from '../../../../types';
import CountrySelector from '../CountrySelector/CountrySelector';
import EAgriImport from '../EAgriImport/EAgriImport';
import ShapeFileUpload from '../ShapeFileUpload/ShapeFileUpload';

import { CountryFrom, SignupState } from '../../../../reducers/signup.reducer.types';

const style = (theme: Theme) => ({
  h5: {
    fontWeight: 500,
    fontSize: 24,
  },
  h5Desc: {
    fontSize: 16,
  },
  textField: {
    textAlign: 'left' as const,
  },
  countryControl: {
    textAlign: 'left' as const,
    width: '100%',
    marginTop: 10,
    marginBottom: 20,
  },
  menuItem: {
    fontSize: 16,
  },
  orLabel: {
    marginTop: 15,
    marginBottom: 15,
  },
  button: {
    width: '100%',
    margin: '32px 0 5px',
  },
  radioGroup: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  radioItem: {
    textTransform: 'uppercase' as const,
    fontWeight: 500,
  },
  radioLabel: {
    marginBottom: 0,
  },
  shpError: {
    marginTop: 6,
    color: theme.palette.error.main,
    fontSize: 12,
  },
});

enum IMPORT_TYPES {
  EAGRI= 'eagri',
  MANUAL= 'manual',
  SHAPEFILE= 'shapefile'
}

interface State {
  errors: {
    farmCountry: boolean;
    farmName: boolean;
    shapeFile: boolean;
  },
  importType: IMPORT_TYPES;
  shapeFile?: File ;
}

interface Props {
  classes: Record<string, string>,
  country: CountryFrom;
  eagriAuthenticated: boolean;
  eagriFarmName: string | null;
  farmName: string;
  farmShpValidationResult: number;
  getEagriFarmName: () => void;
  langId: string;
  onCreateEagriFarm: (name: string) => void;
  onEmptyFarmCreate: (countryCode: string, farmName: string) => void;
  onImportShapefile: (farmName: string, countryCode: string, shpFile: File) => void;
  onSetEagriAuthData: (username: string, wsKey: string, szrid: string) => void;
  onValidateShapefile: (countryCode: string, shpFile: File) => void;
  setFarmName: (name: string) => void;
  setFetch: (bool: boolean) => void;
}

export class FarmImport extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      importType: IMPORT_TYPES.SHAPEFILE,
      shapeFile: undefined,
      errors: {
        farmCountry: false,
        farmName: false,
        shapeFile: false,
      },
    };
  }
  componentDidMount() {
    const { eagriFarmName, getEagriFarmName, setFetch } = this.props;
    if (!eagriFarmName) {
      setFetch(true);
      (getEagriFarmName as AsyncFn)().then(() => {
        setFetch(false);
      });
    }
  }
  componentDidUpdate(prevProps: Props) {
    const prevCountryCode = prevProps.country?.code;
    const countryCode = this.props.country?.code;
    const prevEagriFarmName = prevProps.eagriFarmName;
    const { eagriAuthenticated, eagriFarmName, farmShpValidationResult, setFarmName } = this.props;
    const { errors, importType } = this.state;

    if (eagriAuthenticated && eagriFarmName) {
      if (prevEagriFarmName !== eagriFarmName) {
        setFarmName(eagriFarmName);
      }
    }

    if (prevCountryCode !== countryCode) {
      if (countryCode === 'CZ') {
        this.setState({
          ...this.state,
          importType: IMPORT_TYPES.EAGRI,
        });
      } else {
        this.setState({
          ...this.state,
          importType: IMPORT_TYPES.SHAPEFILE,
        });
      }
    }

    if (errors.shapeFile && farmShpValidationResult === 1) {
      this.resetValidation('shapeFile');
    }
    if (errors.shapeFile && importType !== IMPORT_TYPES.SHAPEFILE) {
      this.resetValidation('shapeFile');
    }
  }
  onImportShapefile = (farmName: string, file: File) => {
    this.props.onImportShapefile(farmName, this.props.country.code, file);
  };

  onValidateShapefile = (file: File) => {
    this.props.onValidateShapefile(this.props.country.code, file);
  };

  onUploadShapeFile = (shapeFile: File) => {
    this.setState({
      ...this.state,
      shapeFile,
    });
  };

  onSetEagriAuthData = (username: string, wsKey: string, szrid: string) =>
    this.props.onSetEagriAuthData(username, wsKey, szrid);

  onCreateEagriFarm = (farmName: string) => this.props.onCreateEagriFarm(farmName);

  onRadioChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ ...this.state, importType: e.currentTarget.value as IMPORT_TYPES });

  onSubmit = () => {
    const { importType } = this.state;
    const { country, farmName, farmShpValidationResult, onEmptyFarmCreate, onImportShapefile } = this.props;
    const { shapeFile } = this.state;
    const { MANUAL, SHAPEFILE } = IMPORT_TYPES;

    const hasValidName = this.validateFarmName(farmName);
    const hasValidShpFile = farmShpValidationResult === 1;

    if (!country) {
      this.setValidationError('farmCountry');
    }
    if (!hasValidName) {
      this.setValidationError('farmName');
    }
    if (!hasValidShpFile && importType === SHAPEFILE) {
      this.setValidationError('shapeFile');
    }

    if (hasValidName && country) {
      if (importType === MANUAL) {
        onEmptyFarmCreate(country.code, farmName);
      } else if (importType === SHAPEFILE && hasValidShpFile && shapeFile) {
        onImportShapefile(farmName, country.code, shapeFile);
      }
    }
  };

  validateFarmName = (value: string) => {
    const isValidName = value && value.trim().length > 0;
    if (!isValidName) {
      this.setValidationError('farmName');
      return false;
    }
    return true;
  };

  setValidationError = (fieldName: string) => {
    this.setState((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [fieldName]: true,
      },
    }));
  };

  resetValidation = (fieldName: string) => {
    this.setState((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [fieldName]: false,
      },
    }));
  };

  render() {
    const { classes, country, eagriAuthenticated, farmName, farmShpValidationResult, langId, setFarmName } = this.props;
    const { errors, importType } = this.state;
    const countryCode = country?.code;
    return (
      <div>
        <div>
          <h5 className={classes.h5}>
            <FormattedMessage id="FarmImport.heading" />
          </h5>
          <p className={classes.h5Desc}>
            <FormattedMessage id="FarmImport.desc" />
          </p>
        </div>
        {!eagriAuthenticated && (
          <>
            <FormControl className={classes.countryControl}>
              <CountrySelector
                error={errors.farmCountry}
                langId={langId}
                resetError={() => this.resetValidation('farmCountry')}
              />
            </FormControl>
            <RadioGroup className={classes.radioGroup} value={this.state.importType}>
              {countryCode === 'CZ' && <FormControlLabel classes={{ label: classes.radioItem }} className={classes.radioLabel} control={<Radio />} id="radio-eagri" label={<FormattedMessage id="FarmImport.typesDescription.eagri" />} onChange={this.onRadioChange} value={IMPORT_TYPES.EAGRI} />}
              <FormControlLabel classes={{ label: classes.radioItem }} className={classes.radioLabel} control={<Radio />} id="radio-shpfile" label={<FormattedMessage id="FarmImport.typesDescription.shapefile" />} onChange={this.onRadioChange} value={IMPORT_TYPES.SHAPEFILE} />
              <FormControlLabel classes={{ label: classes.radioItem }} className={classes.radioLabel} control={<Radio />} id="radio-manual" label={<FormattedMessage id="FarmImport.typesDescription.manual" />} onChange={this.onRadioChange} value={IMPORT_TYPES.MANUAL} />
            </RadioGroup>
          </>)}
        {(importType === IMPORT_TYPES.EAGRI && countryCode === 'CZ' || eagriAuthenticated) &&
          <EAgriImport
            country={countryCode}
            eagriAuthenticated={eagriAuthenticated}
            farmname={this.props.farmName}
            onCreateEagriFarm={this.onCreateEagriFarm}
            onSetEagriAuthData={this.onSetEagriAuthData}
        />}
        {importType !== IMPORT_TYPES.EAGRI && !eagriAuthenticated &&
        <>
          <TextField
            error={errors.farmName}
            fullWidth
            helperText={errors.farmName && <FormattedMessage id="validation.required" />}
            id="file-farm-name"
            label={<FormattedMessage id="common.farmName" />}
            onBlur={(e) => this.validateFarmName(e.currentTarget.value)}
            onChange={e => setFarmName(e.currentTarget.value)}
            onFocus={() => this.resetValidation('farmName')}
            value={farmName}
            InputLabelProps={{
              style: {
                fontWeight: 400,
                zIndex: 1,
                pointerEvents: 'none',
              },
            }}
          />
          {importType === IMPORT_TYPES.SHAPEFILE &&
            <ShapeFileUpload
              country={countryCode}
              farmName={farmName}
              farmShpValidationResult={farmShpValidationResult}
              onUploadShapeFile={this.onUploadShapeFile}
              onValidateShapefile={this.onValidateShapefile}
            />
          }
          {errors.shapeFile && <p className={classes.shpError}><FormattedMessage id="FarmImport.errors.incorrectShpFile" /></p>}
          <Button className={classes.button} color="primary" id="create-farm" onClick={this.onSubmit} variant="contained">
            <FormattedMessage id="common.loadAndEnter" />
          </Button>
        </>
        }
      </div>
    );
  }
}

const mapStateToProps = (state: SignupState) => ({
  country: state.ui.signup.country,
  eagriAuthenticated: getIsAuthenticated(state),
  farmName: state.ui.signup.farmName,
  eagriFarmName: getFarmName(state),
});

const mapDispatchToProps = ({
  setFarmName,
  getEagriFarmName,
  setFetch,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(FarmImport));
