import chroma from 'chroma-js';
import _, { isEqual } from 'lodash';
import { Extent } from 'ol/extent';
import Feature from 'ol/Feature';
import { Point as OlPoint } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import { transform } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Fill, Style as OlStyle } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import invariant from 'tiny-invariant';

import ErrorService from '../../../shared/services/Error.service';
import { MAX_COLOR, MIN_COLOR, fallbackStyle } from '../components/AsApplied/AsAppliedDetailMap.config';

import { PrecisionState } from '../../../reducers/precision.reducer.types';
import { TaskDataTimelineTo } from '../../../shared/api/satellite/satellite.types';

type Point = Exclude<ReturnType<typeof selectPoints>, null>[number];
type PointWithAttribute = Exclude<Point, 'attribute'> & { attribute: Exclude<Point['attribute'], undefined> };

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const selectIsFetchingPoints = (state: PrecisionState) => state.api.asAppliedDetail.isFetchingPoints;

const selectIsFetchingItem = (state: PrecisionState) => state.api.asAppliedDetail.isFetchingItem;

const selectItemError = (state: PrecisionState) => ErrorService.getResErrorDto(state.api.asAppliedDetail.itemError);

const selectPoints = (state: PrecisionState) => state.api.asAppliedDetail.points;

// eslint-disable-next-line max-len
const selectPointsWithAttribute = (state: PrecisionState): PointWithAttribute[] => (state.api.asAppliedDetail.points ?? [])
  .filter((point): point is PointWithAttribute => !!point.attribute);

const selectAttributeUnit = createSelector(selectPointsWithAttribute, pointsWithAttribute => {
  if (pointsWithAttribute.length === 0) {
    return;
  }

  const pointWithAttributeUnit = pointsWithAttribute.find(point => point.attribute.unit);

  if (!pointWithAttributeUnit) {
    return;
  }

  return pointWithAttributeUnit.attribute.unit;
});

const selectMinMaxValues = createSelector(selectPointsWithAttribute, pointsWithAttribute => {
  const interval = getInterval(pointsWithAttribute);

  return interval;
});

const selectMapData = createSelector([
  selectPoints,
  selectMinMaxValues,
], (points, interval) => {
  if (!points) {
    return;
  }

  const layer = getVectorLayer(points);
  const styledLayer = interval
    ? getVectorLayerWithValues(layer, interval)
    : getVectorLayerWithNoValues(layer);

  return styledLayer;
});

const _selectExtent = createSelector([selectMapData], layer => layer?.getSource()?.getExtent());

const selectExtent = createDeepEqualSelector([_selectExtent], extent => (isInvalidExtent(extent) ? undefined : extent));

const isInvalidExtent = (extent?: Extent) => extent?.find(corner => Math.abs(corner) === Infinity);

const getVectorLayer = (points: TaskDataTimelineTo[]) => {
  const source = new VectorSource<OlPoint>();
  const features = points.map(point => {
    const geometry = new OlPoint(transform([point.longitude, point.latitude], 'EPSG:4326', 'EPSG:3857'));
    const feature = new Feature({
      geometry,
    });
    feature.setProperties({
      time: point.time,
      attribute: point.attribute,
    });

    return feature;
  });

  source.addFeatures(features);

  const layer = new VectorLayer<VectorSource<OlPoint>>({
    source,
    zIndex: 50,
  });

  return layer;
};

const getVectorLayerWithNoValues = (layer: VectorLayer<VectorSource<OlPoint>>) => {
  layer.setStyle(fallbackStyle);
  return layer;
};

const getVectorLayerWithValues = (layer: VectorLayer<VectorSource<OlPoint>>, interval: [number, number]) => {
  layer.setStyle(feature => {
    const { attribute } = feature.getProperties();
    const style = attribute
      ? new OlStyle({
        image: new CircleStyle({
          radius: 3,
          fill: new Fill({
            color: interpolateColor(attribute.value, interval),
          }),
        }),
      })
      : fallbackStyle;

    return style;
  });

  return layer;
};

const getInterval = (points: PointWithAttribute[]): [number, number] | undefined => {
  if (points.length === 0) {
    return;
  }
  const min = _.minBy(points, point => point.attribute.value)?.attribute.value;
  const max = _.maxBy(points, point => point.attribute.value)?.attribute.value;
  invariant(min !== undefined, 'Min value should be defined by now');
  invariant(max !== undefined, 'Max value should be defined by now');

  return [min, max];
};

const interpolateColor = (value: number, interval: [number, number]) => {
  const scale = chroma.scale([MIN_COLOR, MAX_COLOR]).domain(interval);
  return scale(value).hex('rgb');
};

export {
  selectAttributeUnit,
  selectIsFetchingItem,
  selectIsFetchingPoints,
  selectItemError,
  selectMapData,
  selectExtent,
  selectMinMaxValues,
};
