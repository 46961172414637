import React, { FC } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import { getCropUsage, getIsFetchingCropUsage } from '../../../../shared/api/agroevidence/catalogues/eagri/eagri.selectors';

import CfFormControl from '../../../../shared/components/form/CfFormControl/CfFormControl';
import CfSelector from '../../../../shared/components/form/CfSelector/CfSelector';
import * as validators from '../../../../shared/misc/validators';

import { ActionsState } from '../../../../reducers/actions.reducer.types';
import { CropUseTypeTo } from '../../../../shared/api/agroevidence/agroevidence.types';

interface Props {
  cropUsage: CropUseTypeTo[],
  isEditing: boolean,
  isFetchingCropUsage: boolean,
}

const VrsCropUsageSelector: FC<Props> = ({
  cropUsage,
  isEditing,
  isFetchingCropUsage,
}) => {
  if (isFetchingCropUsage) {
    return (
      <LinearProgress color="secondary" />
    );
  }
  const isDisable = (cropUsage.length === 0) || !isEditing;

  return (
    <CfFormControl>
      <Field
        component={CfSelector}
        disabled={isDisable}
        idPropertyName="legislativeCode"
        items={cropUsage}
        label={<FormattedMessage id="action.cropUsage" />}
        labelPropertyFactory={(option: CropUseTypeTo) => option.description}
        name="cropUseType"
        validate={isDisable ? undefined : validators.required}
      />
    </CfFormControl>
  );
};

const mapStateToProps = (state: ActionsState) => ({
  cropUsage: getCropUsage(state),
  isFetchingCropUsage: getIsFetchingCropUsage(state),
});

export default connect(mapStateToProps)(VrsCropUsageSelector);
