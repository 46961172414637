/* eslint-disable no-nested-ternary */
import React, { useMemo, useEffect, FC, useState } from 'react';

import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RSAAResultAction } from 'redux-api-middleware';

import { getOperations, getProductionOperations } from '../../../shared/api/telematics/drives/drives.selectors';
import { getTelematicsAggregationsDateFilter } from '../../selectors/telematicsAggregations.selectors';

import { calculateArea } from '../../actions/telematicsAggregations.actions';

import InfoBlueFaded from '../../../assets/img/icons/info_blue-faded.svg';
import { getAggregatedOverlapsApi } from '../../../shared/api/telematics/aggregations/aggregations.api';
import CfFormikTimePicker from '../../../shared/components/form/CfFormikTimePicker/CfFormikTimePicker';
import { COLORS } from '../../../shared/components/misc/CfTextBadge/CfTextBadge';
import TimeIntervalsBar from '../../../shared/components/misc/TimeIntervalsBar/TimeIntervalsBar';
import { getShortDateString } from '../../../shared/misc/timeHelpers';
import { AsyncFn, Thunk } from '../../../types';
import { TABS } from '../../containers/TelematicsTabs/TelematicsTabs';
import { alignTimesWithDate, getDuration, getNotOverlapedTime } from '../../helpers/index';
import { DriveKeysFormValues } from '../TelematicsAggregationDetailContent/DriveKeysForm';
import { ApprovalWarning, BonusField, Buttons, DateField, DriveKey, DriverField, OperationField, ProductionOperationField } from '../TelematicsAggregationDetailContent/formComponents';
import WinfasClient from '../TelematicsAggregationDetailContent/formComponents/WinfasClient';
import { useTelematicsAggregationDetailContentStyles } from '../TelematicsAggregationDetailContent/styles';
import { validateHandworkForm } from '../TelematicsAggregationDetailContent/validators';
import TelematicsForeignWarning, { TelematicsForeignWarningType } from '../TelematicsForeignWarning/TelematicsForeignWarning';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { Type, Affiliation, TelematicsOperation, KeyType, HandworkCreateTo, DriveOverlapRequestTo, DriveDetailOverlapTo, DriverTo } from '../../../shared/api/telematics/telematics.types';
import { ConnectedProps, TelematicsHandworkFormValues, FORM_TYPES } from './TelematicsHandworkForm.types';

export const useNewHandworkFormStyles = makeStyles(() => ({
  timeContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  form: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  sectionHeading: {
    fontSize: 18,
    fontWeight: 400,
    marginTop: 6,
  },
  timelineNotification: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 8,
    marginBottom: 16,
  },
  timelineNotificationText: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    color: '#9B9B9B',
    marginTop: 5,
    marginLeft: 4,
  },
  intervalsTime: {
    color: COLORS.VIOLET_PLUM,
  },
  intervalsHeaderLabel: {
    fontSize: 14,
    color: '#B3DBD1',
  },
  container: {
    marginTop: 4,
  },
}));

export type CreateHandworkType = TelematicsHandworkFormValues & DriveKeysFormValues;

const TelematicsHandworkForm: FC<ConnectedProps> = ({
  approvalValidationErrors,
  dateFilter,
  driveDetail,
  driveKeys,
  formPath,
  getAggregatedOverlapsApi,
  handleReset,
  handleSave,
  operations,
  productionOperations,
}) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [overlaps, setOverlaps] = useState<DriveDetailOverlapTo[]>([]);
  const [selectedDriver, setSelectedDriver] = useState<DriverTo | undefined | null>(undefined);
  // hooks
  const classes = useNewHandworkFormStyles();
  const detailContentClasses = useTelematicsAggregationDetailContentStyles();

  const initialEmptyValues: Partial<CreateHandworkType> = {
    date: dateFilter?.dateFrom ? moment(dateFilter?.dateFrom) : undefined,
    operation: TelematicsOperation.HANDWORK,
    productionOperation: productionOperations.find(op => op.code === '0030-VO'),
    driverCode: '',
    bonus: productionOperations.find(op => op.code === '0030-VO')?.bonus ?? 0,
    type: Type.HANDWORK,
    timeFrom: dateFilter?.dateFrom ? moment(dateFilter?.dateFrom).startOf('day') : undefined,
    timeTo: dateFilter?.dateFrom ? moment(dateFilter?.dateFrom).startOf('day') : undefined,
    formType: FORM_TYPES.CREATE,
    duration: undefined,
    supplierKey: undefined,
    customerKey: undefined,
    client: undefined,
  };

  const isNew = !driveDetail;

  const initialValues: Partial<CreateHandworkType> = useMemo(() => {
    if (isNew) return initialEmptyValues;

    let dateFrom;
    let dateTo;
    let date;
    if (formPath === TABS.DRIVERS) {
      date = driveDetail.dateFrom ? moment(driveDetail.dateFrom) : undefined;
      dateFrom = dateFilter?.dateFrom ? moment(dateFilter?.dateFrom).startOf('day') : undefined;
      dateTo = dateFilter?.dateFrom ? moment(dateFilter?.dateFrom).startOf('day') : undefined;
    } else if (formPath === TABS.LOGBOOK) {
      date = driveDetail.timeFrom ? moment(driveDetail.timeFrom) : undefined;
      dateFrom = driveDetail.timeFrom ? moment(driveDetail.timeFrom) : undefined;
      dateTo = driveDetail.timeTo ? moment(driveDetail.timeTo) : undefined;
    }

    return ({
      date,
      operation: driveDetail?.operation,
      productionOperation: driveDetail?.productionOperation,
      driverCode: driveDetail.driver?.code ?? '',
      bonus: driveDetail.driver?.bonus ?? 0,
      type: driveDetail.type,
      timeFrom: dateFrom,
      timeTo: dateTo,
      formType: FORM_TYPES.EDIT,
      duration: driveDetail.duration,
      supplierKey: driveKeys?.keys?.supplierKey,
      customerKey: driveKeys?.keys?.customerKey,
      client: driveKeys?.client,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driveDetail, driveKeys, initialEmptyValues]);

  const handleSubmit = (values: CreateHandworkType) => {
    let data: HandworkCreateTo = {
      driver: values?.driverCode || undefined,
      productionOperation: values?.productionOperation?.code || undefined,
      bonus: values.bonus,
      timeFrom: getShortDateString(values.timeFrom, 'HH:mm'),
      timeTo: getShortDateString(values.timeTo, 'HH:mm'),
      winfas: {
        keys: {
          supplierKeyId: values.supplierKey?.id,
          customerKeyId: values.customerKey?.id,
        },
        client: values.client ?? undefined,
      },
    };

    if (values.type === Type.HANDWORK) {
      const [timeFromAligned, timeToAligned] = alignTimesWithDate(values.date, values.timeFrom, values.timeTo);
      data = {
        ...data,
        timeFrom: timeFromAligned.toISOString(),
        timeTo: timeToAligned.toISOString(),
      };
    }
    if (handleSave) {
      handleSave(data);
    }
  };

  // const isNew = initialValues.type === Type.MANUAL && !driveDetail;

  const handworkInterval = (timeFrom?: moment.Moment | string, timeTo?: moment.Moment | string) => {
    const from = moment(timeFrom).toISOString();
    const to = moment(timeTo).toISOString();
    return `${from}/${to}`;
  };

  return (
    <>
      <Formik<Partial<CreateHandworkType>>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validateHandworkForm}
        validateOnBlur={hasSubmitted}
        validateOnChange={hasSubmitted}
        validateOnMount={false}
      >
        {(formikProps: FormikProps<CreateHandworkType>) => {
          const { errors, setFieldValue, touched, validateForm, values } = formikProps;

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            const touchedFieldsCount = Object.keys(touched).length;
            const fieldsCount = Object.keys(values).length;
            const fieldErrorsCount = Object.keys(errors).length;

            // formik sets all fields as touched on submit
            const submitted = touchedFieldsCount === fieldsCount;
            const submittedWithErrors = submitted && fieldErrorsCount > 0;

            if (submittedWithErrors) {
              // revalidate form until user fixes all errors
              validateForm();
            }
          }, [values, touched, validateForm, errors]);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (isNew) {
              if (values.date) {
                setFieldValue('timeFrom', values.date.startOf('day'));
                setFieldValue('timeTo', values.date.startOf('day'));
              }
              if (values.date && values.driverCode) {
                (getAggregatedOverlapsApi as AsyncFn<DriveOverlapRequestTo>)({
                  date: getShortDateString(values.date),
                  driver: values.driverCode,
                })
                  .then((res: RSAAResultAction<DriveDetailOverlapTo[]>) => {
                    if (!res.error) {
                      setFieldValue(
                        'timeIntervals',
                        res?.payload.map(({ dateFrom, dateTo }) => `${dateFrom}/${dateTo}`),
                      );
                      setOverlaps(res?.payload);
                    }
                  });
              }
            }
          // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [values.date, values.driverCode]);

          // classifiers need always some date (even if user haven't selected it yet)
          // also use as valid-to date for parcels selector
          const dateForClassifiers = values.date?.toISOString() ?? moment().startOf('day').toISOString();

          const hasExternalDriver = driveDetail?.driver?.affiliation === Affiliation.EXTERNAL;

          const notOverlapedTime = getNotOverlapedTime(overlaps, {
            dateFrom: values.timeFrom,
            dateTo: values.timeTo,
          });

          const formattedNotOverlapedTime = notOverlapedTime ? getShortDateString(notOverlapedTime, 'HH:mm') : '00:00';

          const getTimeIntervals = () => {
            if (isNew) {
              return values.timeIntervals as string[];
            }
            return [];
          };

          const getHandworkIntervals = () => {
            if (isNew) {
              return [handworkInterval(values.timeFrom, values.timeTo)];
            } else if (formPath === TABS.LOGBOOK) {
              return [handworkInterval(values.timeFrom, values.timeTo)];
            } else if (formPath === TABS.DRIVERS) {
              return driveDetail?.drivePart?.map(({ dateFrom, dateTo }) =>
                handworkInterval(dateFrom, dateTo)) ?? [];
            }
            return values.timeIntervals as string[];
          };

          return (
            <Form className={classnames(classes.container, detailContentClasses.container)}>
              <Grid container justifyContent="space-between">
                <Grid item xs={6}>
                  <OperationField disabled={true} operations={operations} />
                </Grid>
                <Grid item xs={4}>
                  <DateField
                    disabled={!isNew}
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className={detailContentClasses.fieldWrapper}>
                    <ApprovalWarning
                      show={!!approvalValidationErrors.productionOperation}
                      tooltipCode={approvalValidationErrors.productionOperation}
                    />
                    <ProductionOperationField
                      date={dateForClassifiers}
                      disabled={!isNew}
                      handwork
                      handleProductionOperationChange={(value) => {
                        setFieldValue('productionOperation', value);

                        const operation = productionOperations.find(o => o.id === value?.id);

                        setFieldValue('bonus', value?.bonus ?? operation?.bonus ?? 0);
                      }}
                      {...formikProps}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={detailContentClasses.fieldWrapper}>
                    <ApprovalWarning
                      show={!!approvalValidationErrors.driverCode}
                      tooltipCode={approvalValidationErrors.driverCode}
                    />
                    <DriverField
                      date={dateForClassifiers}
                      disabled={!isNew}
                      placeholderId={'Telematics.handwork.worker'}
                      setSelectedDriver={setSelectedDriver}
                      {...formikProps}
                    />
                  </div>
                  {hasExternalDriver &&
                    <div className={detailContentClasses.externalDriver}>
                      <TelematicsForeignWarning
                        text={driveDetail?.driver?.companyName}
                        type={TelematicsForeignWarningType.Driver}
                      />
                    </div>
                  }
                </Grid>
                <Grid item xs={4}>
                  <BonusField
                    disabled={!isNew}
                    label={<FormattedMessage id="Telematics.handwork.bonus" />}
                    {...formikProps}
                  />
                </Grid>

              </Grid>
              <Grid className={classnames(detailContentClasses.intervals, classes.timeContainer)} container justifyContent="center">
                {isNew && (
                  <Grid container gap={4}>
                    <Grid item>
                      <Field
                        component={CfFormikTimePicker}
                        label={<FormattedMessage id="TelematicsAggregations.detail.timeFrom" />}
                        name="timeFrom"
                        style={{ width: 58 }}
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        component={CfFormikTimePicker}
                        label={<FormattedMessage id="TelematicsAggregations.detail.timeTo" />}
                        name="timeTo"
                        style={{ width: 58 }}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid container>
                  <Grid
                    className={detailContentClasses.intervalWarningIcon}
                    item
                    xs={approvalValidationErrors.duration ? 1 : 0}
                  >
                    <ApprovalWarning
                      show={!!approvalValidationErrors.duration}
                      tooltipCode={approvalValidationErrors.duration}
                    />
                  </Grid>
                  <Grid item xs={approvalValidationErrors.duration ? 11 : 12}>
                    <div className={detailContentClasses.intervalsHeader}>
                      {isNew && (
                        <>
                          <span className={classes.intervalsHeaderLabel}>
                            {`${values.date ? getShortDateString(values.date, 'DD.M.YYYY') : ''} ${selectedDriver?.name ?? ''}`}
                          </span>
                          <span
                            data-test="duration"
                            className={classnames(
                              detailContentClasses.intervalsTime,
                              notOverlapedTime ? classes.intervalsTime : undefined,
                            )}>
                            {formattedNotOverlapedTime}
                            {' '} <FormattedMessage id="TelematicsAggregations.detail.intervals.hours" />
                          </span>
                        </>
                      )}
                      {!isNew && (
                      <>
                        <span className={detailContentClasses.intervalsHeaderLabel}><FormattedMessage id="TelematicsAggregations.detail.intervals" />:</span>
                        <span className={isNew ? classes.intervalsTime : detailContentClasses.intervalsTime} data-test="duration">
                          {formPath === TABS.LOGBOOK
                            ? getDuration(values.timeTo.diff(values.timeFrom, 'milliseconds') / 1000)
                            : getDuration(values.duration)
                             }
                          {' '}
                          <FormattedMessage id="TelematicsAggregations.detail.intervals.hours" />
                        </span>
                      </>
                      )}
                    </div>
                    <TimeIntervalsBar
                      datetimeEnd={moment(values.date).endOf('day').toISOString()}
                      datetimeStart={moment(values.date).startOf('day').toISOString()}
                      handworkIntervals={getHandworkIntervals()}
                      intervals={getTimeIntervals()}
                      key={'refreshKey'}
                      withTimeAxis
                    />
                    {isNew && (
                      <div className={classes.timelineNotification}>
                        <img alt="started" src={InfoBlueFaded} />
                        <span className={classes.timelineNotificationText}>
                          <FormattedMessage id="Telematics.handwork.timeline.notification" />
                        </span>
                      </div>
                    )}
                  </Grid>
                </Grid>
                {formPath !== TABS.LOGBOOK &&
                  <Grid container justifyContent="space-between">
                    <Grid item xs={12}>
                      <h3 className={classes.sectionHeading}>
                        <FormattedMessage id="TelematicsAggregations.detail.section.keys" />
                      </h3>
                    </Grid>
                    <Grid item xs={12}>
                      <DriveKey
                        disabled={!isNew}
                        fieldName="supplierKey"
                        helperText={!!errors.supplierKey && <FormattedMessage id={errors.supplierKey} />}
                        keyType={KeyType.SUPPLIER}
                        showApprovalWarning={!!approvalValidationErrors.supplierKey}
                        tooltipCode={approvalValidationErrors.supplierKey}
                        // variant="disabled"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DriveKey
                        disabled={!isNew}
                        fieldName="customerKey"
                        helperText={!!errors.customerKey && <FormattedMessage id={errors.customerKey} />}
                        keyType={KeyType.CUSTOMER}
                        showApprovalWarning={!!approvalValidationErrors.customerKey}
                        tooltipCode={approvalValidationErrors.customerKey}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <WinfasClient
                        date={dateForClassifiers}
                        disabled={!isNew}
                        fieldName="client"
                        helperText={!!errors.client && <FormattedMessage id={errors.client} />}
                        showApprovalWarning={!!approvalValidationErrors.client}
                        tooltipCode={approvalValidationErrors.client}
                      />
                    </Grid>
                  </Grid>
                }
                {isNew && <Buttons
                  onCancelClick={() => handleReset()}
                  onSubmitClick={() => setHasSubmitted(true)}
                />}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  operations: getOperations(state),
  productionOperations: getProductionOperations(state),
  dateFilter: getTelematicsAggregationsDateFilter(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      calculateArea,
      getAggregatedOverlapsApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TelematicsHandworkForm);
