import React from 'react';

import moment from 'moment';
import ReactDOM from 'react-dom';

import reducer from '../reducers/notifications.reducer';

import CfApp from '../shared/containers/CfApp/CfApp';

import NotificationsWrapper from './containers/NotificationsWrapper/NotificationsWrapper';

NotificationsController.$inject = [
  '$element',
  '$scope',
  '$rootScope',
  '$transitions',
  'Localization',
  'BACKEND_OPTIONS',
  'ENVIRONMENT',
  'Auth',
  'ResponseErrorInterceptor',
];

export default function NotificationsController(
  $element,
  $scope,
  $rootScope,
  $transitions,
  Localization,
  BACKEND_OPTIONS,
  ENVIRONMENT,
  Auth,
  ResponseErrorInterceptor,
) {
  const props = {
    farmId: this.farmId,
    farm: this.farm,
    reducer,
    config: { api: BACKEND_OPTIONS, environment: ENVIRONMENT },
    apiProps: {
      farmId: this.farmId,
      beOpts: BACKEND_OPTIONS,
      logout: Auth.logout,
      handleError: ResponseErrorInterceptor.handleRSAAErrorResponse,
      displayErrorModal: false,
      langId: null,
    },
    cacheKey: 'notifications',
  };

  const unlistenTranslateChange = $rootScope.$on('$translateChangeSuccess', () => {
    renderWithLang();
  });

  const deregisterOnTransitionSuccess = $transitions.onBefore({}, transition => {
    // hack because of behaviour of ui-router vs react-router
    // we need to block transition inside sensors - when we want to switch farm, it is superseded
    const notificationsState = 'farm.active.notifications';
    const transitionFrom = transition.from();
    const transitionTo = transition.to();
    const transitionParams = transition.params();
    if (
      transitionFrom.name.startsWith(notificationsState) &&
      transitionTo.name.startsWith(notificationsState) &&
      transitionParams.farmId === props.farmId
    ) {
      transition.abort();
    }
  });

  $scope.$on('$destroy', () => {
    unlistenTranslateChange();
    deregisterOnTransitionSuccess();
    ReactDOM.unmountComponentAtNode($element[0]);
  });

  this.$onInit = function () {
    setTimeout(() => {
      renderWithLang();
    }, 0);
  };

  function renderWithLang() {
    Localization.getCurrentLangIdAsync().then(langId => {
      props.langId = langId;
      props.apiProps.langId = langId;
      moment.locale(langId);
      render();
    });
  }

  function render() {
    // hack for root element focus causing inputs losing focus after initial render
    $element[0].click();
    ReactDOM.render(<CfApp {...props} render={p => <NotificationsWrapper {...p} />} />, $element[0]);
  }
}
