/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip, Cell } from 'recharts';

import CfChartWrapper from '../../../../shared/components/charts/CfChartWrapper/CfChartWrapper';
import { colorLuminance } from '../../../../shared/misc/colorLuminance';
import { CropVariability } from '../../containers/ManagementZones';
import XAxisLabel from '../XAxisLabel';

import CropVariabilityTooltip from './CropVariabilityTooltip';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '250px',
  },
}));

const StickBar = (props: any) => {
  const { stroke, width, x, y } = props;
  return <line stroke={stroke} x1={x + width / 2 - 5} x2={x + width / 2 + 5} y1={y} y2={y} />;
};

const LineBar = (props: any) => {
  const { height, stroke, width, x, y } = props;
  return <line stroke={stroke} strokeDasharray="4 3" x1={x + width / 2} x2={x + width / 2} y1={y} y2={y + height} />;
};

const EllipseBar = (props: any) => {
  const { fill, height, width, x, y } = props;
  return <rect fill={fill} height={height} rx="2" ry="2" width={20} x={x + width / 2 - 10} y={y} />;
};

const yAxisProps = {
  ticks: [],
  tickLine: false,
  tick: { fontSize: 12 },
  allowDataOverflow: true,
  padding: { top: 1, bottom: 1 },
  width: 40,
};

const xAxisProps = {
  dataKey: 'date',
  tickLine: false,
  domain: ['dataMin', 'dataMax'],
  interval: 0,
};

const innerChartProps = {
  isAnimationActive: false,
  fillOpacity: 1,
  strokeWidth: 2,
};

const tooltipProps = {
  filterNull: false,
  cursor: false,
};

export interface CropVariabilityGraphProps {
  data: CropVariability[],
  isFetching: boolean,
}

const CropVariabilityGraph: FC<CropVariabilityGraphProps> = ({
  data,
  isFetching,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [hoverIndex, setHoverIndex] = useState<number>();

  const formatDateTick = (value: string) => intl.formatDate(value, { day: 'numeric', month: 'numeric', year: 'numeric' });

  const min = Math.min(...data.map(i => i.min));

  return (
    <CfChartWrapper
      data={data}
      isFetching={isFetching}
      classes={{
        wrapper: classes.wrapper,
      }}
      >
      <ResponsiveContainer height="100%" width="100%">
        <BarChart
          data={data}
          margin={{ top: 0, right: 20, left: 0, bottom: 40 }}
          onMouseMove={bar => {
            if (bar.isTooltipActive) {
              setHoverIndex(bar?.activeTooltipIndex);
            } else {
              setHoverIndex(undefined);
            }
          }}
        >
          <Tooltip {...tooltipProps} content={<CropVariabilityTooltip />} />

          <XAxis
            tick={
              <XAxisLabel
                formatter={formatDateTick}
                rotation={-45}
                textAnchor={'end'}
              />
            }
            {...xAxisProps}
          />
          <YAxis {...yAxisProps} domain={[() => min, 'auto']} />
          <Bar dataKey="min" shape={<StickBar />} stackId="a" stroke="#BFBFBF" {...innerChartProps} />
          <Bar dataKey="q1" shape={<LineBar />} stackId="a" stroke="#BFBFBF" {...innerChartProps} />
          <Bar dataKey="median" shape={<EllipseBar />} stackId="a" {...innerChartProps}>
            {data &&
                data.map((entry, index) => (
                  <Cell
                    fill={hoverIndex === index ? colorLuminance('#EBA607', -0.1) : '#EBA607'}
                    key={entry.date}
                  />
                ))
            }
          </Bar>
          <Bar dataKey="q3" shape={<LineBar />} stackId="a" stroke="#BFBFBF" {...innerChartProps} />
          <Bar dataKey="max" shape={<StickBar />} stackId="a" stroke="#BFBFBF" {...innerChartProps} />
        </BarChart>
      </ResponsiveContainer>
    </CfChartWrapper>
  );
};

export default CropVariabilityGraph;
