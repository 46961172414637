import { CataloguesState } from '../../../../reducers/catalogues.reducer.types';
import { MainMapState } from '../../../../reducers/map.reducer.types';
import { TelematicsState } from '../../../../reducers/telematics.reducer.types';

export const isTelematicSectionAllowed =
    (state: MainMapState | TelematicsState | CataloguesState) => state.telematicsStatus.telematics;
export const isTelematicSectionVerified =
    (state: MainMapState | TelematicsState | CataloguesState) => state.telematicsStatus.verified;
export const getCatalogueType =
    (state: MainMapState | TelematicsState | CataloguesState) => state.telematicsStatus.catalogue;
export const getEconomicSystem =
    (state: MainMapState | TelematicsState | CataloguesState) => state.telematicsStatus.economicSystem;
export const getEconomicSystemDate =
    (state: MainMapState | TelematicsState | CataloguesState) => state.telematicsStatus.economicSystemDate;
export const getFarmExternalId =
    (state: MainMapState | TelematicsState | CataloguesState) => state.telematicsStatus.externalId;
