import React, { FC, useRef, useState, useContext } from 'react';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import showOnMapIcon from '../../../../../assets/img/icons/telematics/showOnMap.svg';
import { ActionDetailContext } from '../../containers/ActionDetail/ActionDetail';

import { ActionDriveTo } from '../../../../../shared/api/telematics/telematics.types';

interface Props {
  data: ActionDriveTo[],
}

const useStyles = makeStyles({
  menuItem: { fontSize: 14 },
});

const ActionToTelematicsLinks: FC<Props> = ({ data }) => {
  const classes = useStyles();
  const anchorEl = useRef(null);
  const [opened, setOpened] = useState(false);
  const { ngGoToTelematicDrive } = useContext(ActionDetailContext);

  if (!data) return null;

  const multipleItems = data.length > 1;
  const createClickHandler = (driveId: number) => () => { ngGoToTelematicDrive(driveId); };

  return (
    <Tooltip title={<FormattedMessage id="ActionDetail.clickToTelematics" />}>
      <>
        <IconButton
          aria-label="view telematic ride"
          ref={anchorEl}
          onClick={(e) => {
            e.stopPropagation();
            multipleItems ? setOpened(true) : createClickHandler(data[0].driveId)();
          }}
        >
          <img alt="view telematic ride" src={showOnMapIcon} />
        </IconButton>
        <Menu
          anchorEl={anchorEl.current}
          disableScrollLock={true}
          onClose={() => setOpened(false)}
          open={opened}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {data.map(({ driveId, machineName }, i) =>
            <MenuItem
              className={classes.menuItem}
              key={i}
              onClick={createClickHandler(driveId)}
            >
              {machineName}
            </MenuItem>,
          )}
        </Menu>
      </>
    </Tooltip>
  );
};

export default ActionToTelematicsLinks;
