import React from 'react';

import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid } from '@mui/material';
import classNames from 'classnames';
import { Field, Form, FormikErrors, FormikProps } from 'formik';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import CfFormikTimePicker from '../../../shared/components/form/CfFormikTimePicker/CfFormikTimePicker';
import TimeIntervalsBar from '../../../shared/components/misc/TimeIntervalsBar/TimeIntervalsBar';
import { getShortDateString } from '../../../shared/misc/timeHelpers';
import { getDuration, getSplitTime } from '../../helpers';

import { ApprovalWarning, Buttons } from './formComponents';
import WarningMessage from './formComponents/WarningMessage';
import { CollisionsFormValues, SplitTimeFormValues } from './TimelineContainer';
import { useTelematicsAggDetailTimelineStyles } from './timelineStyles';
import { isBelowMinute } from './validators';

import { DriveValidationType, DriveDetailTo, DriveDetailOverlapTo } from '../../../shared/api/telematics/telematics.types';

interface Props {
  approvalValidationErrors: Record<string, DriveValidationType | undefined>;
  driveDetail: DriveDetailTo;
  handleReset: () => void;
  isEditing: boolean;
  refreshKey?: string;
  showIntervalsBar: boolean;
}

interface ParentStateProps {
  collisionMode: boolean;
  formikProps: FormikProps<SplitTimeFormValues | CollisionsFormValues>;
  hasSubmitted: boolean;
  inTimeMode: boolean;
  overlaps: DriveDetailOverlapTo[];
  setHasSubmitted: (val: boolean) => void;
}

const TimelineForm: React.FC<Props & ParentStateProps> = ({
  approvalValidationErrors,
  collisionMode,
  driveDetail,
  formikProps,
  handleReset,
  hasSubmitted,
  inTimeMode,
  isEditing,
  overlaps,
  refreshKey,
  setHasSubmitted,
  showIntervalsBar,
}) => {
  const classes = useTelematicsAggDetailTimelineStyles();
  const checkedIds = (formikProps.values as CollisionsFormValues).checkedIds ?? [];

  const getSelectedOverlapIds = (id: number) => {
    const ids = checkedIds;
    let res = ids;
    if (ids.includes(id)) {
      res = ids.filter((overlapId) => overlapId !== id);
    } else {
      res = [...ids, id];
    }
    return res;
  };

  const showDurationWarningIcon = !isEditing && approvalValidationErrors.duration;

  const { errors, setFieldValue, setValues, values } = formikProps;

  let splitTime = 0;
  let remainingTime = 0;
  if (inTimeMode) {
    const { remainingPart, selectedPart }
    = getSplitTime(driveDetail.drivePart, (values as SplitTimeFormValues).time);
    remainingTime = remainingPart;
    splitTime = selectedPart;
  }

  const renderErrors = <T extends SplitTimeFormValues | CollisionsFormValues>(
    errors: FormikErrors<T>,
  ) => Object.keys(errors).map((key) => (
    <div key={key}>
      {errors[key as keyof T]}
    </div>
    ));

  return (
    <Form className={classes.form}>
      <div>
        {inTimeMode && (
          <Field
            className={classes.timeSplit}
            component={CfFormikTimePicker}
            label={<FormattedMessage id="TelematicsAggregations.detail.splitting.inTime" />}
            name="time"
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        {collisionMode && (
        <FormControl component="fieldset">
          <FormLabel className={classes.legend} component="legend">
            <FormattedMessage id="TelematicsAggregations.detail.collision.divideDrives" />
          </FormLabel>
          <FormGroup className={classes.overlapCheckboxGroup}>
            {overlaps.map(({ dateFrom, dateTo }, i) =>
              <FormControlLabel
                key={i}
                label={`${getShortDateString(dateFrom, 'H:mm')}-${getShortDateString(dateTo, 'H:mm')}`}
                control={
                  <Field
                    checked={checkedIds.includes(i)}
                    className={classes.overlapCheckbox}
                    component={Checkbox}
                    onClick={() => {
                      const res = getSelectedOverlapIds(i);

                      if (hasSubmitted) {
                        setValues({
                          checkedIds: res,
                          validIntervals: true,
                        });
                      } else {
                        setFieldValue('checkedIds', res);
                      }
                    }}
              />}
            />,
            )}
          </FormGroup>
          <div className={classes.error}>
            {renderErrors(errors)}
          </div>
        </FormControl>
        )}
      </div>
      <Grid className={classes.intervals} container>
        {driveDetail && showIntervalsBar &&
        <>
          {showDurationWarningIcon && (
          <Grid className={classes.intervalWarningIcon} item xs={1}>
            <ApprovalWarning
              show={!!approvalValidationErrors.duration}
              tooltipCode={approvalValidationErrors.duration}
          />
          </Grid>
          )}
          <Grid item xs={showDurationWarningIcon ? 11 : 12}>
            <div className={classes.intervalsHeader}>
              {!isEditing && (
                <span className={classes.intervalsTime} data-test="duration">
                  {getDuration(driveDetail.duration)} {' '} <FormattedMessage id="TelematicsAggregations.detail.intervals.hours" />
                </span>
              )}
              {inTimeMode && (
                <span
                  data-test="duration"
                  className={classNames(
                    classes.intervalsTime,
                    { [classes.splitTimeActive]: !(isBelowMinute(splitTime) || isBelowMinute(remainingTime)) },
                  )}>
                  {getDuration(splitTime)} {' '} <FormattedMessage id="TelematicsAggregations.detail.intervals.hours" />
                  <span className={classes.timeSeparator}>{' | '}</span>
                  {getDuration(remainingTime)} {' '} <FormattedMessage id="TelematicsAggregations.detail.intervals.hours" />
                </span>
              )}
            </div>
            <TimeIntervalsBar
              datetimeEnd={moment(driveDetail.dateFrom).endOf('day').toISOString()}
              datetimeStart={moment(driveDetail.dateFrom).startOf('day').toISOString()}
              isEditing={isEditing}
              key={refreshKey}
              selectedOverlapIds={checkedIds}
              withTimeAxis
              datetimeSplitter={inTimeMode ?
                moment((values as SplitTimeFormValues).time).toISOString() : undefined}
              intervals={driveDetail.drivePart.map(
                ({ dateFrom, dateTo }) => `${dateFrom}/${dateTo}`) as string[]}
              overlaps={inTimeMode ? [] :
            overlaps.map(({ dateFrom, dateTo }) => `${dateFrom}/${dateTo}`) as string[]}
        />
          </Grid>
          {inTimeMode && (
          <Grid className={classes.intervalWarningIcon} item>
            <WarningMessage
              langtextCode="TelematicsAggregations.detail.splitting.warning"
          />
          </Grid>
          )}
          {collisionMode && (
          <Grid className={classes.intervalWarningIcon} item>
            <WarningMessage
              langtextCode="TelematicsAggregations.detail.collision.warning"
          />
          </Grid>
          )}
        </>}
      </Grid>
      {isEditing && <Buttons
        onCancelClick={() => handleReset()}
        onSubmitClick={() => setHasSubmitted(true)}
    />}
    </Form>
  );
};

export default TimelineForm;
