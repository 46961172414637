import React from 'react';

import { Field, FieldArray } from 'formik';
import PropTypes from 'prop-types';

import ActionParcelSubtractableAreasSection from '../ActionParcelSubtractableAreasSection/ActionParcelSubtractableAreasSection';
import SubtractableAreaWithRadioButton from '../SubtractableAreaWithRadioButton/SubtractableAreaWithRadioButton';

function BoundaryParcelSubtractableAreas(props) {
  const { isEditing, maxValue, parcelId, parcelIds, parcelIndex } = props;
  return (
    <FieldArray name={`parcels.${parcelIndex}.subtractableAreas.boundary`}>
      {({ form, push }) => (
        <ActionParcelSubtractableAreasSection
          isEditing={isEditing}
          maxValue={maxValue}
          parcelId={parcelId}
          parcelIds={parcelIds}
          subtractableAreas={form.values.parcels[parcelIndex].subtractableAreas.boundary}
          subtractableAreaType={'Boundary'}
          handleAdd={(sa) => {
            push(sa);
            form.setFieldValue(
              `parcels.${props.parcelIndex}.subtractableAreas.boundaryChecked`, sa.value);
          }}
        >
          <Field
            component={SubtractableAreaWithRadioButton}
            isEditing={isEditing}
            name={`parcels.${parcelIndex}.subtractableAreas.boundaryChecked`}
            options={form.values.parcels[parcelIndex].subtractableAreas.boundary}
            value={form.values.parcels[parcelIndex].subtractableAreas.boundaryChecked}
          />
        </ActionParcelSubtractableAreasSection>
      )}
    </FieldArray>
  );
}

BoundaryParcelSubtractableAreas.propTypes = {
  isEditing: PropTypes.bool,
  maxValue: PropTypes.number.isRequired,
  parcelId: PropTypes.string.isRequired,
  parcelIds: PropTypes.array.isRequired,
  parcelIndex: PropTypes.number.isRequired,
};

BoundaryParcelSubtractableAreas.defaultProps = {
  isEditing: false,
};

export default BoundaryParcelSubtractableAreas;
