import React, { FC } from 'react';

import Tabs from '@mui/material/Tabs';

interface Props {
  [rest: string]: unknown,
  onChange?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, value?: unknown) => void
  tabValue: number | string;
}
const CfPrimaryTabs: FC<Props> = (props) => {
  const { children, onChange, tabValue, ...otherProps } = props;
  return (
    <Tabs indicatorColor="primary" onChange={onChange} textColor="primary" value={tabValue} {...otherProps}>
      {children}
    </Tabs>
  );
};

export default CfPrimaryTabs;
