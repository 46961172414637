import React, { Component } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import * as granularity from '../../../../sensors/constants/granularity.constants';

import CfSwitcher from '../CfSwitcher/CfSwitcher';

const styles = {
  wrapper: {
    display: 'inline',
  },
};

const options = [
  {
    value: '30MIN',
    primaryText: <FormattedMessage id="common.30min" />,
    id: 'agg-30min',
    granularity: granularity.G30M,
    minDuration: 1,
    maxDuration: 1,
  },
  {
    value: 'HOUR',
    primaryText: <FormattedMessage id="common.hour" />,
    id: 'agg-hour',
    granularity: granularity.G1H,
    minDuration: 1,
    maxDuration: 14,
  },
  {
    value: '4HOURS',
    primaryText: <FormattedMessage id="common.4hours" />,
    id: 'agg-4hours',
    granularity: granularity.G4H,
    minDuration: 1,
    maxDuration: 14,
  },
  {
    value: 'DAY',
    primaryText: <FormattedMessage id="common.day" />,
    id: 'agg-day',
    granularity: granularity.G24H,
    minDuration: 2,
    maxDuration: 366, // leap year has 366 days
  },
  {
    value: 'WEEK',
    primaryText: <FormattedMessage id="common.week" />,
    id: 'agg-week',
    granularity: granularity.G1W,
    minDuration: 15,
    maxDuration: 1000,
  },
];

class PeriodSwitcher extends Component {
  render() {
    const { classes, duration, granularity } = this.props;

    const optionsByDuration = options.filter(option =>
      (duration <= option.maxDuration && duration >= option.minDuration));

    let granularityId = optionsByDuration[0].id;
    const granularityOption = optionsByDuration.find(option => option.granularity === granularity);
    if (granularityOption) {
      granularityId = granularityOption.id;
    }
    return (
      <span>
        <CfSwitcher
          getItemId={item => item.id}
          getItemValue={item => item.value}
          getItemViewValue={item => item.primaryText}
          items={optionsByDuration}
          selectedItemId={granularityId}
          classes={{
            wrapper: classes.wrapper,
          }}
          onMenuItemClick={(item) => {
            this.props.setGranularity(item.granularity);
          }}
        />
      </span>
    );
  }
}

PeriodSwitcher.propTypes = {
  classes: PropTypes.object,
  duration: PropTypes.number.isRequired,
  granularity: PropTypes.string.isRequired,
  setGranularity: PropTypes.func.isRequired,
};

PeriodSwitcher.defaultProps = {
  actions: {},
  classes: {},
};

export default withStyles(styles)(PeriodSwitcher);
