import { Coordinate } from 'ol/coordinate';
import { Extent, getSize as getExtentSize } from 'ol/extent';
import { ProjectionLike, transform } from 'ol/proj';

export const MAP_SRID_ID = '3857';
export const DATA_SRID_ID = '4326';
export const transformOptions = {
  dataProjection: `EPSG:${DATA_SRID_ID}`,
  featureProjection: `EPSG:${MAP_SRID_ID}`,
};

export const TELEMATICS_PARCEL_COLOR = { red: 235, green: 166, blue: 7, fillAlfa: 0.2 };
export const TELEMATICS_GEOMETRY_COLOR = '#07FFD2FF';
export const TELEMATICS_GEOMETRY_PER_DAY_COLOR = '#0D154480';
export const TELEMATICS_GEOMETRY_WIDTH = 3;

export const getZoomedExtent = (extent: Extent, zoomFactor: number) => {
  if (zoomFactor) {
    const extentSize = getExtentSize(extent);
    const extraWidth = extentSize[0] / zoomFactor - extentSize[0];
    const extraHeight = extentSize[1] / zoomFactor - extentSize[1];
    const newExtent = [
      extent[0] - extraWidth / 2,
      extent[1] - extraHeight / 2,
      extent[2] + extraWidth / 2,
      extent[3] + extraHeight / 2,
    ];
    return newExtent;
  }
  return extent;
};

export const transformWithValidation = (
  coordinates: Coordinate,
  dataProjection: ProjectionLike,
  featureProjection: ProjectionLike,
  logResult = false,
): [Coordinate, boolean] => {
  const result = transform(coordinates, dataProjection, featureProjection);
  const isValid = !result.some(v => isNaN(v));

  if (!isValid && logResult) {
    // eslint-disable-next-line no-console
    console.error(
      'Error in the map - invalid transform result \n[source:]',
      coordinates,
      '\n[result:]',
      result,
    );
  }
  return [result, isValid];
};
