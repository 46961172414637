import React from 'react';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import moment from 'moment';
import PropTypes from 'prop-types';

function CfFormikTimePicker(props) {
  const {
    children,
    customDate,
    customSeconds,
    field,
    form,
    format,
    maxDateMessage,
    minDateMessage,
    validateOnBlur,
    ...restProps } = props;

  const error = form.errors[field.name];

  const handleChange = (value) => form.setFieldValue(field.name, value);

  return (
    <LocalizationProvider adapterLocale={moment().locale()} dateAdapter={AdapterMoment}>
      <TimeField
        error={!!error}
        format={format}
        helperText={error}
        name={field.name}
        onBlur={e => field.onBlur(e)}
        onChange={handleChange}
        placeholder="09:00"
        value={field.value}
        {...restProps}
    />
    </LocalizationProvider>
  );
}

CfFormikTimePicker.propTypes = {
  children: PropTypes.node,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  validateOnBlur: PropTypes.bool,
  format: PropTypes.string,
  minDateMessage: PropTypes.node,
  maxDateMessage: PropTypes.node,
  customDate: PropTypes.object,
  customSeconds: PropTypes.number,
};

CfFormikTimePicker.defaultProps = {
  children: null,
  validateOnBlur: false,
  format: 'HH:mm',
  minDateMessage: null,
  maxDateMessage: null,
  customSeconds: 0,
};

export default CfFormikTimePicker;
