import * as listSelectors from '../selectors/actionList.selectors';

import { setTableSelected } from '../../../../shared/actions/table.actions';

import * as types from './actionList.constants';

import { NAMESPACE as namespace } from '../reducer/actionList.reducer';

import { getActionsApi, deleteActionsApi } from '../../../../shared/api/agroevidence/actions/actions.api';
import { deleteVariableExpenses as deleteVariableExpensesApi } from '../../../../shared/api/sentinel/variableApplication/variableApplication.api';

export const fetchActions = () => (dispatch, getState) => {
  const state = getState();
  const page = listSelectors.getPage(state);
  const rowsPerPage = listSelectors.getRowsPerPage(state);
  const order = listSelectors.getOrder(state);
  const orderBy = listSelectors.getOrderBy(state);
  const textFilter = listSelectors.getTextFilter(state);
  const dateFilter = listSelectors.getDateFilter(state);
  const isDraft = listSelectors.getDraftsOnly(state);

  const p = {
    page: page + 1,
    'per-page': rowsPerPage,
    'sort-dir': order,
    'sort-col': orderBy,
    search: textFilter || undefined,
    from: dateFilter || undefined,
    'is-draft': isDraft || undefined,
  };

  dispatch(getActionsApi(p));
};

export const deleteActions = (actionIds, variableActionIds) => (dispatch) => {
  dispatch(deleteActionsApi(actionIds))
    .then(() => {
      const promises = [];
      if (variableActionIds?.length) {
        variableActionIds.forEach(variableActionId => {
          if (actionIds.includes(variableActionId)) {
            promises.push(dispatch(deleteVariableExpensesApi(variableActionId)));
          }
        });
      }

      return Promise.all(promises).then(() => {
        dispatch(fetchActions());
      });
    })
    .finally(() => {
      dispatch(setTableSelected([], namespace));
    });
};

export const setDateFilter = dateFilter => ({
  type: types.SET_ACTIONS_LIST_DATE_FILTER,
  dateFilter,
});

export const toggleDraftsView = () => ({
  type: types.TOGGLE_DRAFTS_VIEW,
});
