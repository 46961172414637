import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './machines.constants';

import { RsaaMethods, RsaaTypes } from '../../api.types';
import { AggregatedMachineApiParams } from './machines.types';

export const getMachineAggregationsApi = (params: AggregatedMachineApiParams, refetch = false, skipReducer = false) => {
  let rsaaTypes: RsaaTypes = [
    { type: types.GET_MACHINE_AGGREGATIONS_REQUEST,
      payload: () => ({ refetch }),
    },
    types.GET_MACHINE_AGGREGATIONS_SUCCESS,
    types.GET_MACHINE_AGGREGATIONS_ERROR,
  ];
    // for direct API call when saving data is not desired
  if (skipReducer) {
    rsaaTypes = [
      types.GET_MACHINE_AGGREGATIONS_REQUEST_NOREDUCER,
      types.GET_MACHINE_AGGREGATIONS_SUCCESS_NOREDUCER,
      types.GET_MACHINE_AGGREGATIONS_ERROR_NOREDUCER,
    ];
  }
  return ({
    [RSAA]: {
      endpoint: `logbook/aggregated/machine?${queryString.stringify(params)}&`,
      method: methods.GET as RsaaMethods,
      module: modules.TELEMATICS,
      types: rsaaTypes,
    },
  });
};

export const resetApiError = () => ({
  type: types.RESET_API_ERROR,
});
