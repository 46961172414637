import { alpha, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useCustomStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiInput-underline:before': {
      borderBottomColor: alpha(theme.palette.common.black, 0.12),
    },
  },
}));
