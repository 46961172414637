import React, { Fragment } from 'react';

import WarningIcon from '@mui/icons-material/Warning';
import { Stack } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

interface Props {
    isSplitting: boolean;
    numberOfCheckedItems: number;
    numberOfParcels: number
}

const useStyles = makeStyles((theme: Theme) => ({
  messageError: {
    color: theme.palette.error.main,
  },
  messageWarning: {
    color: theme.palette.secondary.main,
  },
}));

export function SplitActionsWarningMessage({ isSplitting, numberOfCheckedItems, numberOfParcels }: Props) {
  const classes = useStyles();
  return (
    <Fragment>
      {isSplitting && numberOfCheckedItems > 0 && numberOfCheckedItems !== numberOfParcels &&
        <Stack className={classes.messageWarning} direction="row" spacing={4}>
          <WarningIcon color="secondary" />
          <FormattedMessage id={'ActionsSplit.warningMessage.selected'} />
        </Stack>
          }
      {isSplitting && numberOfCheckedItems === numberOfParcels &&
      <div className={classes.messageError}><FormattedMessage id={'ActionsSplit.warningMessage.allSelected'} /></div>
          }
      {isSplitting && numberOfCheckedItems === 0 &&
      <div className={classes.messageError}><FormattedMessage id={'ActionsSplit.warningMessage.noSelection'} /></div>
          }
    </Fragment>
  );
}
