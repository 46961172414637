import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getChemistryAndFertilizersSuggestions } from '../../selectors/actions.selectors';

import { getChemistryAndFertilizersByName, clearChemistryAndFertilizers } from '../../actions/actions.actions';

import CfAutosuggest from '../../../../../shared/components/common/CfAutosuggest/CfAutosuggest';

export const CHEM_DIALOD_TYPE = 'CH';
export const FERT_DIALOD_TYPE = 'FR';

class ChemFertSelector extends Component {
  onSuggestionSelected = suggestion => {
    const { chemAdditionalProps, fertAdditionalProps } = this.props;

    const expense = {
      material: suggestion,
      ...(suggestion.materialTypeId === CHEM_DIALOD_TYPE ? chemAdditionalProps : {}),
      ...(suggestion.materialTypeId === FERT_DIALOD_TYPE ? fertAdditionalProps : {}),
    };

    this.props.onSuggestionSelected(expense);
  };

  getSuggestionValue = sugg =>
    (sugg.materialTypeId === 'CH'
      ? [sugg.name, sugg.authorizationHolder, sugg.registrationNumber, sugg.allowed ? 'P' : 'Z']
      : [sugg.name, sugg.producer])
      .filter(item => item)
      .join(', ');

  getSuggestions = searchInput => {
    const { chemOnly, fertOnly } = this.props;
    this.props.getChemistryAndFertilizersByName(searchInput, chemOnly, fertOnly);
  };

  clearSuggestions = () => {
    const { chemOnly, fertOnly } = this.props;
    this.props.clearChemistryAndFertilizers(chemOnly, fertOnly);
  };

  render() {
    const {
      disabled,
      intl: { formatMessage },
      placeholder,
      suggestions,
    } = this.props;

    return (
      <CfAutosuggest
        clearSuggestions={this.clearSuggestions}
        disabled={disabled}
        getSuggestions={this.getSuggestions}
        getSuggestionValue={this.getSuggestionValue}
        isDebounced
        onSuggestionSelected={this.onSuggestionSelected}
        placeholder={formatMessage({ id: placeholder })}
        requiredLength={1}
        suggestions={suggestions.map(sugg => ({ ...sugg, title: formatMessage({ id: sugg.title }) }))}
        testData="chem-fert-selector"
      />
    );
  }
}

const mapStateToProps = state => ({
  suggestions: getChemistryAndFertilizersSuggestions(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getChemistryAndFertilizersByName,
      clearChemistryAndFertilizers,
    },
    dispatch,
  );

ChemFertSelector.propTypes = {
  intl: PropTypes.object.isRequired,
  suggestions: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  clearChemistryAndFertilizers: PropTypes.func.isRequired,
  getChemistryAndFertilizersByName: PropTypes.func.isRequired,
  fertAdditionalProps: PropTypes.object,
  chemAdditionalProps: PropTypes.object,
  disabled: PropTypes.bool,
  chemOnly: PropTypes.bool,
  fertOnly: PropTypes.bool,
};

ChemFertSelector.defaultProps = {
  disabled: false,
  chemOnly: false,
  fertOnly: false,
  fertAdditionalProps: {},
  chemAdditionalProps: {},
};

export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)(ChemFertSelector);
