import React, { FC } from 'react';

import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { Line, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis, CartesianGrid } from 'recharts';

import CfChartWrapper from '../../../../shared/components/charts/CfChartWrapper/CfChartWrapper';
import { GraphDataOrMonitoringData } from '../../containers/BioMonitoring';
import * as indices from '../../selectors/indices';
import { indexZoneColors, zonesColors } from '../../services/monitoringZonesColors';
import XAxisLabel from '../XAxisLabel';

import BioMonitoringTooltip from './BioMonitoringTooltip';

// used for stroke and tooltip
const MAIN_COLOR_INDEX = 5;

const getDomainByIndex = (indexType: string) => {
  switch (indexType) {
    case indices.CAB:
      return [2.0, 80.0];
    case indices.CW:
      return [0.0005, 0.07];
    case indices.LAI:
      return [0.1, 10];
    default:
      return [1.0, 100.0];
  }
};

const getTicksByIndex = (indexType: string) => {
  switch (indexType) {
    case indices.CAB:
      return [2.0, 21.5, 41.0, 60.5, 80.0];
    case indices.CW:
      return [0.0005, 0.0174, 0.0353, 0.0527, 0.07];
    case indices.LAI:
      return [0.1, 2.55, 5.0, 7.5, 10.0];
    default:
      return [1.0, 100.0];
  }
};

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '250px',
  },
}));

export interface BioMonitoringGraphProps {
  data: GraphDataOrMonitoringData[],
  index: string,
  isFetching: boolean,
}

const BioMonitoringGraph: FC<BioMonitoringGraphProps> = ({
  data,
  index,
  isFetching,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const yAxisProps = {
    ticks: getTicksByIndex(index),
    tickLine: false,
    tick: { fontSize: 12 },
    allowDataOverflow: true,
    padding: { top: 1, bottom: 1 },
    domain: getDomainByIndex(index),
    width: 40,
  };

  const xAxisProps = {
    dataKey: 'dateFrom',
    tickLine: false,
    domain: ['dataMin', 'dataMax'],
  };

  const defaultCartesianGridProps = {
    strokeDasharray: '2 4',
    strokeWidth: 1,
    stroke: '#CACACA',
  };

  const innerChartProps = {
    isAnimationActive: false,
    dataKey: 'value',
    fill: '#e37767',
    fillOpacity: 1,
    stroke: zonesColors[index as indexZoneColors][MAIN_COLOR_INDEX].color,
    strokeWidth: 2,
  };

  const tooltipProps = {
    filterNull: false,
    offset: 50,
  };

  const formatDate = (value: string) => `${intl.formatDate(value)}`;

  return (
    <CfChartWrapper
      data={data}
      isFetching={isFetching}
      classes={{
        wrapper: classes.wrapper,
      }}
      >
      <ResponsiveContainer height="100%" width="100%">
        <ComposedChart data={data} margin={{ top: 0, right: 40, left: 15, bottom: 10 }}>
          <Tooltip
            {...tooltipProps}
            wrapperStyle={{ zIndex: 1 }}
            content={
              <BioMonitoringTooltip
                tooltipValueColor={zonesColors[index as indexZoneColors][MAIN_COLOR_INDEX].color}
              />}
            />

          <XAxis
            tick={
              <XAxisLabel
                formatter={formatDate}
                textAnchor={'middle'}
              />
            }
            {...xAxisProps}
          />
          <YAxis {...yAxisProps} />
          <Line {...innerChartProps} r={0.5} type="monotoneX" />
          <CartesianGrid {...defaultCartesianGridProps} strokeLinecap="round" />
        </ComposedChart>
      </ResponsiveContainer>
    </CfChartWrapper>
  );
};

export default BioMonitoringGraph;
