export enum SECTIONS {
  DRIVERS='drivers',
  EQUIPMENT='equipment',
  FERTILIZERS='fertilizers',
  FERTILIZERS_REACT='fertilizersReact',
  MACHINES='machines',
  PORS='pors',
  SEEDS='seeds',
}

export const LEGACY_SECTIONS = [
  SECTIONS.SEEDS,
  SECTIONS.FERTILIZERS,
  SECTIONS.PORS,
];

export const CATALOGUES_URLS = {
  [SECTIONS.FERTILIZERS_REACT]: 'catalogues/fertilizersReact',
  [SECTIONS.DRIVERS]: 'catalogues/drivers',
  [SECTIONS.MACHINES]: 'catalogues/machines',
  [SECTIONS.EQUIPMENT]: 'catalogues/equipment',
  [SECTIONS.FERTILIZERS]: 'classifiers/private/fertilizers',
  [SECTIONS.SEEDS]: 'classifiers/private/seeds',
  [SECTIONS.PORS]: 'classifiers/private/pors',
};
