import React, { FC } from 'react';

import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

interface Props {
    // eslint-disable-next-line react/no-unused-prop-types
    leftOffset?: boolean;
    primaryId: string;
    secondaryId: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    margin: 0,
  },
  secondaryLabel: {
    color: theme.palette.grey[400],
  },
  offsetCol: {
    marginLeft: (props: Props) => (props?.leftOffset ? theme.spacing(2) : 0),
  },
}));

const DoubleLinedHeader: FC<Props> = (props) => {
  const { primaryId, secondaryId } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.offsetCol}>
      <p className={classes.text}>
        <FormattedMessage id={primaryId} />
      </p>
      <p className={classnames(classes.text, classes.secondaryLabel)}>
        <FormattedMessage id={secondaryId} />
      </p>
    </div>
  );
};

export default DoubleLinedHeader;
