import { AnyAction } from 'redux';

import * as types from './plantProtection.constants';

const initialState = {
  items: [],
  isFetching: false,
};

export default () => (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.GET_PLANT_PROTECTION_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_PLANT_PROTECTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.payload,
      };
    case types.GET_PLANT_PROTECTION_ERROR:
      return {
        ...state,
        isFetching: false,
      };

    case types.RESET_PLANT_PROTECTION:
      return {
        ...state,
        items: [],
      };

    default:
      return state;
  }
};
