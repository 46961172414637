import React, { FC } from 'react';

import { Theme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import CfFormattedNumber from '../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import SelectionItem from '../../../shared/components/common/SelectionItem/SelectionItem';
import SelectionItemColumn from '../../../shared/components/common/SelectionItemColumn/SelectionItemColumn';
import SelectionItemHeading from '../../../shared/components/common/SelectionItemHeading/SelectionItemHeading';
import TelematicsSeasonSelector from '../TelematicsSeasonSelector/TelematicsSeasonSelector';

import { GeoJson } from '../../../shared/api/agroevidence/agroevidence.types';
import { Geometry } from '../../../shared/api/telematics/telematics.types';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
  },
  label: {
    color: theme.palette.grey[500],
  },
  parcel: {
    fontWeight: 'normal',
  },
}));

export interface TelematicsParcelType {
  area: number;
  blockNumber: string;
  geometry: Geometry | GeoJson;
  localName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  seedApplication: Record<string, any>;
}

interface Props {
  handleRemoveParcel: () => void;
  isEditing: boolean;
  isFetchingParcel: boolean;
  parcel: Partial<TelematicsParcelType>;
  parcelId?: string;
}

const TelematicsParcel: FC<Props> = ({
  handleRemoveParcel,
  isEditing,
  isFetchingParcel,
  parcel,
  parcelId,
}) => {
  const classes = useStyles();

  const getCropLabel = () => {
    const seedApplication = parcel?.seedApplication;
    return seedApplication?.type === 'CATCH_CROP' ? 'common.intercrop' : 'common.crop';
  };

  if (isFetchingParcel) {
    return (
      <div className={classes.container}>
        <div className={classes.label}>
          <FormattedMessage id="common.parcel" />:
        </div>
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.label}>
        <FormattedMessage id="common.parcel" />:
      </div>
      <SelectionItem
        editing={isEditing}
        onRemoveItem={handleRemoveParcel}
        >
        <Grid container spacing={0}>
          <Grid item sm={6} xs={12}>
            <SelectionItemHeading
              reversed={true}
              subheading={parcel.blockNumber}
              >
              <span className={classes.parcel}>{parcel.localName}</span>
            </SelectionItemHeading>
          </Grid>
          <Grid item sm={6} xs={4}>
            <SelectionItemColumn label={<FormattedMessage id="common.area-ha" />}>
              <div>
                <CfFormattedNumber value={parcel.area ?? 0} />
              </div>
            </SelectionItemColumn>
          </Grid>
          <TelematicsSeasonSelector
            cropLabel={getCropLabel()}
            currentCropName={parcel.seedApplication?.seed?.crop?.name}
            disabled={!isEditing}
            parcelId={parcelId} />
        </Grid>
      </SelectionItem>
    </div>
  );
};

export default TelematicsParcel;
