import download from 'js-file-download';

export default class FileService {
  static processFileResponse = (res) => {
    if (res?.error) return;
    const { body, disposition, type } = res?.payload || {};
    body?.then(b =>
      new Response(b).blob().then((blob) => {
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = regex.exec(disposition);

          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, '');
            download(blob, filename, type);
          }
        }
      }),
    );
  };
}
