import React, { Fragment } from 'react';

import pointOnFeature from '@turf/point-on-feature';
import ReactDOM from 'react-dom';

import { getLangId } from '../../../../shared/selectors/lang.selectors';
import { getOverlay, getMainMap } from '../../selectors/map.selectors';

import CfFormattedNumber from '../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import BaseContainer from '../../../../shared/containers/BaseContainer/BaseContainer';
import Geometry from '../../services/geometry/Geometry.service';

export const removeOverlay = key => (_, getState) => {
  const overlay = getOverlay(getState());
  overlay.removeOverlay(key);
};

export const removeOverlays = () => (_, getState) => {
  const overlays = getOverlay(getState());
  overlays?.removeOverlays();
};

export const updateOverlayPosition = (key, coordinates) => (_, getState) => {
  const overlay = getOverlay(getState());
  overlay.updateOverlayPosition(key, coordinates);
};

/** ************ */

export const addAreaOverlay = (data, elementId, key) => (_, getState) => {
  const state = getState();
  const overlay = getOverlay(state);
  const map = getMainMap(state);
  const { area, geometry, localName } = data;

  const center = pointOnFeature(geometry);
  const position = Geometry.readGeometry(center.geometry, map.getTransformOptions()).getCoordinates();
  const langId = getLangId(state);

  const element = overlay.getOverlayElement(elementId);
  ReactDOM.render(
    <BaseContainer langId={langId} translations={{}}>
      <div>
        <p>{localName || ''}</p>
        <p>
          <CfFormattedNumber value={area} /> ha
        </p>
      </div>
    </BaseContainer>,
    element,
  );

  overlay.addOverlay(overlay.createOverlay(element, position), key);
};

export const addMeasurementOverlay = (data, elementId, key) => (_, getState) => {
  const state = getState();
  const overlay = getOverlay(state);
  const { area, geometry, length } = data;

  const langId = getLangId(state);
  let tooltipPosition;
  let tooltipOffset = [0, 0];
  if (area) {
    const pointCoordinates = geometry.getInteriorPoint().getCoordinates();
    tooltipPosition = [pointCoordinates[0], pointCoordinates[1]];
  } else {
    tooltipPosition = geometry.getLastCoordinate();
    tooltipOffset = [0, -20];
  }

  const element = overlay.getOverlayElement(elementId);
  ReactDOM.render(
    <BaseContainer langId={langId} translations={{}}>
      <Fragment>
        <div className="measurement-info-result">
          {(length || length === 0) && (
            <p>
              <CfFormattedNumber decimalDigits={length > 1000 ? 0 : 1} value={length} /> m
            </p>
          )}
          {(area || area === 0) && (
            <p>
              <CfFormattedNumber decimalDigits={area > 1000 ? 1 : 3} value={area} /> ha
            </p>
          )}
        </div>
        <div className="measurement-info-tick" />
      </Fragment>
    </BaseContainer>,
    element,
  );

  overlay.removeOverlay(key);
  overlay.addOverlay(
    overlay.createOverlay(element, tooltipPosition, { offset: tooltipOffset, positioning: 'bottom-center' }),
    key,
  );
};

export const addMapHintOverlay = (evt, elementId, key) => (_, getState) => {
  const overlay = getOverlay(getState());
  const element = overlay.getOverlayElement(elementId);
  overlay.addOverlay(overlay.createOverlay(element, evt.coordinate), key);
};
