import React, { FC, useCallback, useEffect, useState } from 'react';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import OlFeature from 'ol/Feature';
import { Point } from 'ol/geom';
import ImageLayer from 'ol/layer/Image';
import VectorLayer from 'ol/layer/Vector';
import Projection from 'ol/proj/Projection';
import Static from 'ol/source/ImageStatic';
import VectorSource from 'ol/source/Vector';
import { Circle, Fill, Stroke, Style as OlStyle, Text } from 'ol/style';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './PrecisionMap.css';
import { getParcel } from '../../../../shared/api/agroevidence/parcels/parcels.selectors';
import { getLayersConfig } from '../../../map/selectors/layers.selectors';

import { storeInitialLayers } from '../../../map/actions/layersUI/layersUI.actions';
import { storeServiceWrapper, setMapGrabEL, resetMap } from '../../../map/actions/map/map.actions';

import { getParcelApi } from '../../../../shared/api/agroevidence/parcels/parcels.api';
import { fetchLayersConfig, resetLayers } from '../../../../shared/api/other/layers/layers.api';
import SectionWrapper from '../../../../shared/components/specific/SectionWrapper/SectionWrapper';
import withConfig from '../../../../shared/hocs/context/withConfig';
import withFarm from '../../../../shared/hocs/context/withFarm';
import { hexToRgba } from '../../../../shared/misc/colorLuminance';
import { Thunk } from '../../../../types';
import HomeControl from '../../../map/components/HomeControl/HomeControl';
import LayersCropsControl from '../../../map/components/LayersCropsControl/LayersCropsControl';
import EventListener from '../../../map/services/EventListener.service';
import Geometry from '../../../map/services/geometry/Geometry.service';
import Layers from '../../../map/services/Layers.service';
import MapService from '../../../map/services/Map.service';
import Style from '../../../map/services/Style.service';
import { Geometries, MapImage } from '../../containers/BioMonitoring';

import { PrecisionState } from '../../../../reducers/precision.reducer.types';
import { DeprecatedFarmTo } from '../../../../shared/api/agroevidence/agroevidence.types';
import { Geometry as GeometryType } from '../../../../shared/api/core/geometry/geometry.types';
import { Layer } from '../../../../shared/api/other/layers/layers.types';
import { Sample } from '../../../../shared/api/sentinel/soilSamples/soilSamples.types';

const MAP_SRID_ID = '3857';
const DATA_SRID_ID = '4326';

const transformOptions = {
  dataProjection: `EPSG:${DATA_SRID_ID}`,
  featureProjection: `EPSG:${MAP_SRID_ID}`,
};

const useStyles = makeStyles((theme: Theme) => ({
  map: {
    height: 600,
    width: '100%',
    position: 'relative',
  },
  [theme.breakpoints.down('sm')]: {
    map: {
      height: 450,
    },
  },
}));

interface PrecisionMapProps {
  config: { api: { bing_key: string, geoserverUrl: string; geoserverWorkspaceCore: string; } };
  farm: DeprecatedFarmTo;
  fetchLayersConfig(countryCode: string): void;
  geometries?: Geometries[] | null,
  getParcelApi(id: string): void;
  layersConfig: Layer[];
  mapImage?: MapImage | null,
  parcelGeometry?: GeometryType;
  parcelId: string;
  points?: Sample[] | null;
  resetLayers(): void;
  resetMap(): void;
  setMapGrabEL(): void;
  storeInitialLayers(layers: Layer[]): void;
  storeServiceWrapper(key: string, value: unknown): void;
}

const PrecisionMap: FC<PrecisionMapProps> = ({
  config,
  farm,
  fetchLayersConfig,
  geometries = null,
  getParcelApi,
  layersConfig,
  mapImage = null,
  parcelGeometry,
  parcelId,
  points = null,
  resetLayers,
  resetMap,
  setMapGrabEL,
  storeInitialLayers,
  storeServiceWrapper,
}) => {
  const classes = useStyles();

  const [map, setMap] = useState<MapService | undefined>();
  const [layers, setLayers] = useState<Layers | undefined>();

  const addPoints = useCallback((points: Sample[]) => {
    const newLayer = new VectorLayer({
      source: new VectorSource(),
      zIndex: 11,
      style: feature =>
        new OlStyle({
          image: new Circle({
            radius: 4,
            fill: new Fill({ color: '#333333' }),
            stroke: new Stroke({ color: '#FFFFFF', width: 2 }),
          }),
          text: new Text({
            text: feature.get('name'),
            font: 'bold 12px Roboto',
            offsetX: 12,
            offsetY: 12,
            fill: new Fill({ color: '#333333' }),
            stroke: new Stroke({ color: '#FFFFFF', width: 4 }),
            textBaseline: 'middle',
          }),
        }),
    });

    points?.forEach(point => {
      newLayer.getSource()?.addFeature(
        new OlFeature({
          geometry: new Point(point.geometry.coordinates),
          name: point.value.toString(),
        }),
      );
    });

    layers?.addLayer(newLayer);
  }, [layers]);

  const addGeometries = useCallback((geometries: Geometries[]) => {
    const newLayer = new VectorLayer({
      source: new VectorSource(),
      zIndex: 5,
      style: feature =>
        new OlStyle({
          fill: new Fill({
            color: hexToRgba(`#${feature.get('color')}`, feature.get('opacity') || 0.9),
          }),
          stroke: new Stroke({ color: '#333333', width: 1 }),
        }),
    });

    geometries?.forEach(zone => {
      newLayer.getSource()?.addFeature(
        new OlFeature({
          geometry: Geometry.readGeometry(zone.geometry, {}),
          color: zone.color,
        }),
      );
    });

    layers?.addLayer(newLayer);
  }, [layers]);

  const addImage = useCallback((image: MapImage) => {
    const { extent, url } = image;
    const newLayer = new ImageLayer({
      source: new Static({
        url,
        projection: new Projection({
          code: 'xkcd-image',
          units: 'pixels',
          extent,
        }),
        imageExtent: extent,
      }),
    });

    layers?.addLayer(newLayer);
  }, [layers]);

  const zoomToParcel = useCallback(() => {
    if (parcelGeometry?.geometry) {
      map?.zoomToGeometry(parcelGeometry.geometry);
    }
  }, [parcelGeometry?.geometry, map]);

  useEffect(() => {
    if (!map) {
      storeServiceWrapper('main', undefined);
      storeServiceWrapper('el', undefined);
      storeServiceWrapper('layers', undefined);
      storeServiceWrapper('style', undefined);

      resetMap();
      resetLayers();

      setMap(() => new MapService('parcel-map', farm.id, farm.boundingBox, transformOptions));
      return;
    }
    getParcelApi(parcelId);

    fetchLayersConfig(farm.customer.countryCode);
    const el = new EventListener(map.getMap());
    storeServiceWrapper('main', map);
    storeServiceWrapper('el', el);

    return () => {
      setMap(undefined);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    zoomToParcel();
  }, [parcelGeometry, zoomToParcel]);

  // init layers and styles after 'layersConfig' is loaded and 'map' created
  useEffect(() => {
    if (!layersConfig || !map) return;
    const layers = new Layers(map.getMap(), config.api, farm.id, map.getFarmExtent());

    // adding zIndex to the parcels layer
    const extendedNewLayersConfig = layersConfig.map(c => {
      if (c.layerId === 'parcel') {
        return {
          ...c,
          zIndex: 10,
        };
      }
      if (c.layerId === 'parcel_label') {
        return {
          ...c,
          zIndex: 11,
        };
      }
      return c;
    });
    layers.setInitialLayers(extendedNewLayersConfig, storeInitialLayers);

    const style = new Style(layers.getParcelLayer(), layers.getParcelLabelLayer(), farm.customer.countryCode);

    storeServiceWrapper('layers', layers);
    storeServiceWrapper('style', style);

    setMapGrabEL();

    setLayers(layers);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layersConfig]);

  useEffect(() => {
    if (geometries && !mapImage) {
      addGeometries(geometries);
      if (points) {
        addPoints(points);
      }
    }
    if (mapImage && !geometries) {
      addImage(mapImage);
    }
  }, [layers, addGeometries, geometries, mapImage, addImage, points, addPoints]);

  return (
    <div className={classes.map} id="parcel-map">
      <HomeControl moveToTop={false} zoomToHome={zoomToParcel} />
      <SectionWrapper left={14} top={17}>
        <LayersCropsControl withCrops={false} />
      </SectionWrapper>
    </div>
  );
};

const mapStateToProps = (state: PrecisionState) => ({
  parcelGeometry: getParcel(state),
  layersConfig: getLayersConfig(state),
});

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) =>
  bindActionCreators(
    {
      getParcelApi,
      storeServiceWrapper,
      storeInitialLayers,
      fetchLayersConfig,
      setMapGrabEL,
      resetMap,
      resetLayers,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withFarm()(withConfig()(PrecisionMap)));
