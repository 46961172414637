import React from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Icon, IconButton, AccordionSummary, Accordion, AccordionDetails, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { FORM_TYPES } from '../../../../evidence/actions/others/actionOther.constants';

const styles = theme => ({
  content: {
    flexGrow: 100,
    margin: 0,
    padding: 6,
    '&$expanded': {
      margin: 0,
    },
  },
  removeButton: {
    alignSelf: 'center',
    height: 36,
    width: 36,
    padding: 0,
    marginLeft: 6,
  },
  iconWrapper: {
    alignSelf: 'center',
    width: 24,
    height: 24,
  },
  infoIcon: {
    color: theme.palette.secondary.main,
  },
  accordion: {
    margin: '0px 0px 12px 0px',
    '&$expanded': {
      margin: '0px 0px 12px 0px',
    },
  },
  root: {
    backgroundColor: theme.palette.grey[100],
    minHeight: 48,
    fontSize: 16,
    '&$expanded': {
      minHeight: 48,
    },
  },
  expandIcon: {
    padding: 6,
    order: -1,
  },
  expanded: {
  },
  details: {
    display: 'block',
    padding: '8px 25px 8px',
  },
});

function SelectionItemAccordion(props) {
  const { classes, details, formType, isExisting, isSown } = props;

  const isHarvestForm = formType === FORM_TYPES.HARVEST;
  const isMowingForm = formType === FORM_TYPES.MOWING;
  const mustBeSown = isHarvestForm || isMowingForm;

  const isNewNotSownParcel = !isSown && !isExisting;

  let warningMessage = '';
  if (isNewNotSownParcel) {
    if (isHarvestForm) {
      warningMessage = 'action.harvest.catchParcelNotSown';
    } else if (isMowingForm) {
      warningMessage = 'action.mowing.catchParcelNotSown';
    }
  }

  return (
    <Accordion classes={{ root: classes.accordion, expanded: classes.expanded }} id="action-parcel-list">
      <AccordionSummary
        // eslint-disable-next-line max-len
        expandIcon={(mustBeSown && isNewNotSownParcel) ? <div className={classes.iconWrapper} /> : <ExpandMoreIcon data-test={'expand-item'} />}
        classes={{ root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          expandIconWrapper: classes.expandIcon }}
      >
        <div className={classes.content}>
          {props.children}
        </div>
        {mustBeSown &&
          (<div className={classes.iconWrapper}>
            {isNewNotSownParcel && (
              <Tooltip title={<FormattedMessage id={warningMessage} />}>
                <Icon className={classes.infoIcon}>warning</Icon>
              </Tooltip>
            )}
          </div>
          )}
        <IconButton
          aria-label="Remove item"
          className={classes.removeButton}
          disabled={!props.editing}
          onClick={props.onRemoveItem}
          size="large">
          <ClearIcon />
        </IconButton>
      </AccordionSummary>
      {!(mustBeSown && isNewNotSownParcel)
        && <AccordionDetails className={classes.details}>
          {details}
        </AccordionDetails>}
    </Accordion>
  );
}

SelectionItemAccordion.propTypes = {
  children: PropTypes.object,
  classes: PropTypes.object,
  onRemoveItem: PropTypes.func,
  editing: PropTypes.bool,
  details: PropTypes.node.isRequired,
  isSown: PropTypes.bool,
  isExisting: PropTypes.bool,
  formType: PropTypes.string,
};

SelectionItemAccordion.defaultProps = {
  children: {},
  classes: {},
  onRemoveItem: () => {},
  isSown: false,
  isExisting: false,
  editing: false,
  formType: '',
};

export default withStyles(styles)(SelectionItemAccordion);
