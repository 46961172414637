/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC } from 'react';

import TableBody from '@mui/material/TableBody';
import { connect } from 'react-redux';

import { getSectionListOrder, getSectionListOrderBy, getSectionListPage, getSectionListRowsPerPage } from '../../../selectors/sectionList.selectors';

import { getSelectedNamespace } from '../../../reducers/namespace.reducer';

import CfTableBodyEmpty from '../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty';
import CfTableBodyLoader from '../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader';
import CfTableWrapper from '../../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableFooter from '../../../../shared/containers/CfTableFooter/CfTableFooter';
import CfTableHead from '../../../../shared/containers/CfTableHead/CfTableHead';
import { useDidUpdate } from '../../../../shared/hooks/useDidUpdate';
import { ColumnData } from '../../../helpers/tableHelpers';

import { CataloguesState, CATALOGUES_NAMESPACES } from '../../../../reducers/catalogues.reducer.types';

interface Props {
    children: React.ReactNode;
    columns: Record<string, ColumnData> ;
    count: number;
    handleOnChangeTrigger: () => void;
    hasData: boolean;
    isFetching: boolean;
    namespace: CATALOGUES_NAMESPACES ;
    order: string;
    orderBy: string;
    page: number;
    rowsPerPage: number;
}

const TCTable: FC<Props> = ({
  children,
  columns,
  count,
  handleOnChangeTrigger,
  hasData,
  isFetching,
  namespace,
  order,
  orderBy,
  page,
  rowsPerPage,
}) => {
  useDidUpdate(() => {
    handleOnChangeTrigger();
  },
  [order, orderBy, page, rowsPerPage]);

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={columns}
        namespace={namespace}
        order={order}
        orderBy={orderBy}
      />
      {isFetching && <CfTableBodyLoader columns={columns} />}
      {!isFetching && hasData && (
        <TableBody>
          {children}
        </TableBody>
      )}
      {!isFetching && !hasData &&
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      }
      <CfTableFooter
        count={count}
        namespace={namespace}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  namespace: getSelectedNamespace(state),
  order: getSectionListOrder(state),
  orderBy: getSectionListOrderBy(state),
  page: getSectionListPage(state),
  rowsPerPage: getSectionListRowsPerPage(state),
});

export default connect(mapStateToProps)(TCTable);
