import React, { FC, useState } from 'react';

import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { getParcelActivitiesAdvancedFilter } from '../../selectors/parcelActivities.selectors';

import CfFilter from '../../../../../../shared/containers/CfFilter/CfFilter';

import ActivitiesSelector from './ActivitiesSelector/ActivitiesSelector';

import { ParcelsState } from '../../../../../../reducers/parcels.reducer.types';
import { ActivitiesState, ParcelActivitiesListAdvancedFilter } from '../../../../../../shared/api/agroevidence/parcels/parcels.types';

const useStyles = makeStyles({
  container: {
    padding: 20,
    minWidth: 300,
    maxWidth: 600,
  },
  select: {
    marginBottom: 10,
  },
});

export interface ParcelActivitiesAdvancedFilterProps {
  advancedFilter: ParcelActivitiesListAdvancedFilter,
  langId: string;
  namespace: string;
}

export const ParcelActivitiesAdvancedFilter: FC<ParcelActivitiesAdvancedFilterProps> = ({
  advancedFilter,
  langId,
  namespace,
}) => {
  const classes = useStyles();

  const [advancedFilterState, setAdvancedFilterState] = useState<ParcelActivitiesListAdvancedFilter>(advancedFilter);

  const handleChangeActivityName = (items: ActivitiesState[]) => {
    setAdvancedFilterState({ ...advancedFilterState, activities: items });
  };

  return (
    <CfFilter
      filterState={advancedFilterState}
      langId={langId}
      namespace={namespace}
      setAdvancedFilterState={setAdvancedFilterState}
    >
      <div className={classes.container}>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <ActivitiesSelector
              defaultActivities={advancedFilter.activities}
              label={<FormattedMessage id="ParcelActivities.text-filter-placeholder" />}
              onChange={handleChangeActivityName}
            />
          </Grid>
        </Grid>
      </div>
    </CfFilter>
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  advancedFilter: getParcelActivitiesAdvancedFilter(state),
});

export default connect(mapStateToProps)(ParcelActivitiesAdvancedFilter);
