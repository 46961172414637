import React, { FC, useMemo, useContext } from 'react';

import { Field, Formik, Form, FieldProps } from 'formik';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchAccountFarms } from '../actions/farms.actions';

import { UpdateAdminFarmApiParams, updateAdminFarmApi } from '../../../../shared/api/agroevidence/farms/admin/farmsAdmin.api';
import CfDialog from '../../../../shared/components/common/CfDialog/CfDialog';
import CfFormikTextField from '../../../../shared/components/form/CfFormikTextField/CfFormikTextField';
import { SnackbarContext } from '../../../../shared/containers/SnackbarProvider/SnackbarProvider';
import { AsyncFn, Thunk } from '../../../../types';

import useEditDialogStyles from './styles/editDialog.styles';

import { AdminState } from '../../../../reducers/admin.reducer.types';
import { AdminFarmTo } from '../../../../shared/api/agroevidence/agroevidence.types';

interface EditFormValues {
    farmId: string;
    farmName: string;
}

const validate = (values: EditFormValues) => {
  const errors: Record<string, string | React.ReactNode> = {};
  if (!values?.farmName) {
    errors.farmName = <FormattedMessage id="TelematicsAdmin.edit.dialog.requiredField" />;
  }
  return errors;
};

// in global scope to avoid redeclaration (prevent losing focus on input)
const renderExternalIdField = (fieldProps: FieldProps) => <CfFormikTextField {...fieldProps} useDefaultHelperText />;

interface Props {
  farm: AdminFarmTo;
  fetchAccountFarms: () => void,
  handleClose: () => void;
  opened: boolean;
  updateFarms: (payload: UpdateAdminFarmApiParams) => void;
}

const EditDialog: FC<Props> = ({
  farm,
  fetchAccountFarms,
  handleClose,
  opened,
  updateFarms,
}) => {
  const classes = useEditDialogStyles();
  const showSnackbar = useContext(SnackbarContext);

  const initialValues = useMemo(() => ({
    farmId: farm.id,
    farmName: farm.name,
  }), [farm]);

  const handleSubmit = ({
    farmId,
    farmName,
  }: EditFormValues) => {
    const payload = {
      farmId,
      farmName,
    };

    (updateFarms as AsyncFn<UpdateAdminFarmApiParams>)(payload).then((res) => {
      if (res.error) {
        showSnackbar({ message: <FormattedMessage id="TelematicsAdmin.dialog.error" />, isError: true });
        return;
      }
      fetchAccountFarms();
      showSnackbar({ message: <FormattedMessage id="TelematicsAdmin.edit.dialog.success" values={{ count: 1 }} />, isSuccess: true });
    });
    handleClose();
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
     >
      {({ resetForm, submitForm }) => {
        const { id } = farm;
        const closeForm = () => {
          resetForm();
          handleClose();
        };

        return (
          <CfDialog
            acceptText={<FormattedMessage id="common.rename" />}
            cancelText={<FormattedMessage id="common.cancel" />}
            maxWidth="xs"
            onAccept={submitForm}
            onCancel={closeForm}
            opened={opened}
            title={<FormattedMessage id="TelematicsFarmsAdmin.edit.dialog.title" />}
        >
            <Form className={classes.form}>
              <Field
                component={renderExternalIdField}
                customClasses={{ root: classes.field }}
                fullWidth
                id={'farmName'}
                label={<FormattedMessage id="TelematicsFarmsAdmin.edit.dialog.name" />}
                name="farmName"
              />
              <div className={classes.farmInfo}>
                <span className={classes.farmIdLabel}>
                  <FormattedMessage id="TelematicsFarmsAdmin.edit.dialog.id" />
                </span>
                <p className={classes.farmId}>{id}</p>
              </div>
            </Form>
          </CfDialog>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch: Thunk<AdminState>) => bindActionCreators({
  updateFarms: updateAdminFarmApi,
  fetchAccountFarms,
},
dispatch,
);

export default connect(null, mapDispatchToProps)(EditDialog);
