import React, { FC, ReactNode, useContext, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Box, Fab, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { CATALOGUES_URLS, SECTIONS, LEGACY_SECTIONS } from '../../catalogues.constants';

import CfPrimaryTab from '../../../shared/components/common/CfPrimaryTab/CfPrimaryTab';
import CfPrimaryTabs from '../../../shared/components/common/CfPrimaryTabs/CfPrimaryTabs';
import PageHeader from '../../../shared/components/common/PageHeader/PageHeader';
import PageHeading from '../../../shared/components/common/PageHeading/PageHeading';
import useToggles from '../../../shared/toggles/toggles';
import TOGGLES from '../../../shared/toggles/toggles.const';
import { CataloguesContext } from '../CataloguesWrapper/CataloguesWrapper';

const useStyles = makeStyles((theme: Theme) => ({

  actionButton: {
    marginRight: theme.spacing(2),
  },
  header: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  tabsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  fabContainer: {
    position: 'absolute',
    paddingRight: 15,
  },
  fab: {
    color: '#fff',
  },
}));

interface Props extends RouteComponentProps<{farmId: string}> {
  activeTab: SECTIONS,
  children: ReactNode
}

const CataloguesTabs: FC<Props> = ({ activeTab, children, history }) => {
  const { farmId, isTelematicSectionAllowed } = useContext(CataloguesContext);
  const classes = useStyles();
  const [, , isToggleActive] = useToggles();

  // TODO delete this trick after all tabs will rewrite to React.
  // Need wait till isTelematicSectionAllowed will initialized
  const [showComponent, setShowComponent] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowComponent(true);
    }, 200);
    return () => clearTimeout(timeout);
  }, []);

  const handleClick = (target: SECTIONS) => () => {
    history.push(`/farm/${farmId}/${CATALOGUES_URLS[target]}`);
    if (LEGACY_SECTIONS.includes(target)) {
      history.go(0);
    }
  };

  const handleCreateFertilizer = () => {
    history.push(`/farm/${farmId}/${CATALOGUES_URLS.fertilizersReact}/new`);
  };

  const isFertilizerTab = activeTab === SECTIONS.FERTILIZERS_REACT;

  return (
    <div>
      {showComponent && <>
        <div className={classes.container}>
          <PageHeader
            actionButtons={
            isFertilizerTab && <Box className={classes.fabContainer}>
              <Fab aria-label="add" className={classes.fab} color="secondary" onClick={handleCreateFertilizer}>
                <AddIcon />
              </Fab>
            </Box>
            }
            classes={{
              header: classes.header,
            }}
            heading={
              <PageHeading dataTest="catalogues-heading" value={<FormattedMessage id="Catalogues.main.title" />} />
            }
          />
          <div className={classes.tabsContainer}>
            <CfPrimaryTabs
              centered
              tabValue={activeTab}>
              <CfPrimaryTab
                data-test={SECTIONS.SEEDS}
                label={<FormattedMessage id="Catalogues.section.seeds" />}
                onClick={handleClick(SECTIONS.SEEDS)}
                value={SECTIONS.SEEDS}
              />
              <CfPrimaryTab
                data-test={SECTIONS.FERTILIZERS}
                label={<FormattedMessage id="Catalogues.section.fertilizers" />}
                onClick={handleClick(SECTIONS.FERTILIZERS)}
                value={SECTIONS.FERTILIZERS}
              />
              <CfPrimaryTab
                data-test={SECTIONS.PORS}
                label={<FormattedMessage id="Catalogues.section.pors" />}
                onClick={handleClick(SECTIONS.PORS)}
                value={SECTIONS.PORS}
              />
              {isToggleActive(TOGGLES.FERTILIZERS.name) && <CfPrimaryTab
                data-test={SECTIONS.FERTILIZERS_REACT}
                label={<FormattedMessage id="Catalogues.section.fertilizers" />}
                onClick={handleClick(SECTIONS.FERTILIZERS_REACT)}
                value={SECTIONS.FERTILIZERS_REACT}
              />}
              {isTelematicSectionAllowed && <CfPrimaryTab
                data-test={SECTIONS.DRIVERS}
                label={<FormattedMessage id="Catalogues.section.drivers" />}
                onClick={handleClick(SECTIONS.DRIVERS)}
                value={SECTIONS.DRIVERS}
              />}
              {isTelematicSectionAllowed && <CfPrimaryTab
                data-test={SECTIONS.MACHINES}
                label={<FormattedMessage id="Catalogues.section.machines" />}
                onClick={handleClick(SECTIONS.MACHINES)}
                value={SECTIONS.MACHINES}
              />}
              {isTelematicSectionAllowed && <CfPrimaryTab
                data-test={SECTIONS.EQUIPMENT}
                label={<FormattedMessage id="Catalogues.section.equipment" />}
                onClick={handleClick(SECTIONS.EQUIPMENT)}
                value={SECTIONS.EQUIPMENT}
              />}
            </CfPrimaryTabs>
          </div>
        </div>
        {children}
      </>}
    </div>
  );
};
export default CataloguesTabs;
