import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';

import editorUI from '../core/map/reducer/editor.reducer';
import layersUI from '../core/map/reducer/layersUI.reducer';
import mapUI from '../core/map/reducer/map.reducer';
import managementUI from '../core/precision/reducer/management.reducer';
import monitoringUI from '../core/precision/reducer/monitoring.reducer';
import precisionAsAppliedUI from '../core/precision/reducer/precisionAsApplied.reducer';
import precisionParcelListUI from '../core/precision/reducer/precisionParcelList.reducer';
import parcelsApiNew from '../shared/api/agroevidence/parcels/parcels.reducer';
import geometryApi from '../shared/api/core/geometry/geometry.reducer';
import authReducer from '../shared/api/gateway/auth/auth.reducer';
import layersApi from '../shared/api/other/layers/layers.reducer';
import managementApi from '../shared/api/sentinel/management/management.reducer';
import monitoringApi from '../shared/api/sentinel/monitoring/monitoring.reducer';
import asAppliedDetailApi from '../shared/api/sentinel/precision/asAppliedDetail.reducer';
import precisionParcelsApi from '../shared/api/sentinel/precision/precision.reducer';
import precisionAsAppliedListApi from '../shared/api/sentinel/precision/precisionAsApplied.reducer';
import satelliteProductsApi from '../shared/api/sentinel/satelliteProducts/satelliteProducts.reducer';
import soilSamplesApi from '../shared/api/sentinel/soilSamples/soilSamples.reducer';

const reducer = (history: History) =>
  combineReducers({
    ui: combineReducers({
      precisionParcelsList: precisionParcelListUI(),
      precisionAsApplied: precisionAsAppliedUI(),
      monitoring: monitoringUI(),
      management: managementUI(),
      map: mapUI(),
      editor: editorUI(),
      layers: layersUI(),
      cache: () => (['precisionParcelsList']),
    }),
    api: combineReducers({
      asAppliedDetail: asAppliedDetailApi(),
      precisionParcels: precisionParcelsApi(),
      precisionAsApplied: precisionAsAppliedListApi(),
      parcelsNew: parcelsApiNew(),
      monitoring: monitoringApi(),
      management: managementApi(),
      soilSamples: soilSamplesApi(),
      satelliteProducts: satelliteProductsApi(),
      geometry: geometryApi(),
      layers: layersApi(),
    }),
    router: connectRouter(history),
    auth: authReducer,
  });

export default reducer;
