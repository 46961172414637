import { forEach, groupBy } from 'lodash';
import { EventsKey } from 'ol/events';
import { boundingExtent } from 'ol/extent';
import Feature, { FeatureLike } from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import { unByKey } from 'ol/Observable';
import { Cluster } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import {
  Circle as CircleStyle,
  Fill,
  Stroke,
  Style,
  Text,
  Icon,
} from 'ol/style';

import combineIcon from '../../../assets/img/icons/telematics/combine.svg';
import loaderStrokeIcon from '../../../assets/img/icons/telematics/loader-stroke.svg';
import loaderIcon from '../../../assets/img/icons/telematics/loader.svg';
import otherStrokeIcon from '../../../assets/img/icons/telematics/other-stroke.svg';
import otherIcon from '../../../assets/img/icons/telematics/other.svg';
import passengerStrokeIcon from '../../../assets/img/icons/telematics/passenger-stroke.svg';
import passengerIcon from '../../../assets/img/icons/telematics/passenger.svg';
import tractorStrokeIcon from '../../../assets/img/icons/telematics/tractor-stroke.svg';
import tractorIcon from '../../../assets/img/icons/telematics/tractor.svg';
import truckStrokeIcon from '../../../assets/img/icons/telematics/truck-stroke.svg';
import truckIcon from '../../../assets/img/icons/telematics/truck.svg';
import { TELEMATICS_GEOMETRY_COLOR, TELEMATICS_GEOMETRY_WIDTH, transformWithValidation } from '../../../shared/misc/map.helpers';
import { COLOR_FONT, COLOR_SECONDARY } from '../../../theme';

import Geometry from './geometry/Geometry.service';

import { DailyPositionTo, MachineGroupCode, PositionDetailTo, PositionDriverTo, PositionMachineTo } from '../../../shared/api/telematics/telematics.types';

const MAIN_MAP_TELEMATICS_TEXT_COLOR = COLOR_FONT.main;
const MAIN_MAP_TELEMATICS_MACHINE_CLUSTER_CIRCLE_RADIUS = 15;
const MAIN_MAP_TELEMATICS_MACHINE_CLUSTER_STROKE_WIDTH = 4;
const MAIN_MAP_TELEMATICS_MACHINE_CLUSTERS_COLORS = {
  COMBINE: '#FCC401',
  LOADER: '#5BD510',
  OTHER: '#F89500',
  PASSENGER: '#D2D6D5',
  TRACTOR: '#E40101',
  TRUCK: '#00ACE7',
  DEFAULT: COLOR_SECONDARY.main,
};

export default class MainMapTelematics {
  map: Map;
  transformOptions: { dataProjection: string; featureProjection: string; };
  machinePositionsLayer?: VectorLayer<VectorSource> | null;
  machineDrivesHistoryLayer?: VectorLayer<VectorSource> | null;
  selectedMachineGpsUnit?: string;
  onSelectedMachineGpsUnitChange: (gpsUnit?: string) => void;
  hoverEvent: EventsKey | null
  clickEvent: EventsKey | null

  constructor(
    map: Map,
    transformOptions: { dataProjection: string; featureProjection: string; },
    onSelectedMachineGpsUnitChange: (gpsUnit?: string) => void,
  ) {
    this.map = map;
    this.transformOptions = transformOptions;
    this.selectedMachineGpsUnit = undefined;
    this.onSelectedMachineGpsUnitChange = onSelectedMachineGpsUnitChange;
    this.hoverEvent = null;
    this.clickEvent = null;
  }

  getMap = () => this.map;

  setMachinePositionsLayer = () => {
    const clusterSource = new Cluster({
      distance: 50,
      source: new VectorSource(),
    });

    this.machinePositionsLayer = new VectorLayer({
      source: clusterSource,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style: this.getMachinePositionsStyle,
      zIndex: 6,
      // hack to get above the decluttered parcel labels
      // https://github.com/openlayers/openlayers/issues/10096
      className: 'telematics',
    });

    this.map.addLayer(this.machinePositionsLayer);

    this.setClickEvent();
    this.setHoverEvent();
  }

  unsetMachinePositionsLayer = () => {
    if (!this.machinePositionsLayer) return;

    this.map.removeLayer(this.machinePositionsLayer);
    this.machinePositionsLayer = null;
  }

  setMachinePositions = (machinePositions: PositionDetailTo[]) => {
    if (!this.machinePositionsLayer) return;

    const vectorSource = (this.machinePositionsLayer.getSource() as Cluster).getSource();

    vectorSource?.clear();

    machinePositions.forEach((x) => {
      const coords = x.location.coordinates?.[0];
      if (!coords) return;

      const [transformedPosition, isValid] = transformWithValidation(
        coords,
        this.transformOptions.dataProjection,
        this.transformOptions.featureProjection,
        true,
      );

      if (!isValid) {
        return;
      }

      const feature = new Feature({
        geometry: new Point(transformedPosition),
      });

      feature.set('machinePosition', x);
      feature.set('group', x.machine?.group);

      vectorSource?.addFeature(feature);
    });
  }

  getMachineClusterStrokeColor(group: string) {
    switch (group) {
      case MachineGroupCode.COMBINE:
        return MAIN_MAP_TELEMATICS_MACHINE_CLUSTERS_COLORS.COMBINE;
      case MachineGroupCode.LOADER:
        return MAIN_MAP_TELEMATICS_MACHINE_CLUSTERS_COLORS.LOADER;
      case MachineGroupCode.OTHER:
        return MAIN_MAP_TELEMATICS_MACHINE_CLUSTERS_COLORS.OTHER;
      case MachineGroupCode.PASSENGER:
        return MAIN_MAP_TELEMATICS_MACHINE_CLUSTERS_COLORS.PASSENGER;
      case MachineGroupCode.TRACTOR:
        return MAIN_MAP_TELEMATICS_MACHINE_CLUSTERS_COLORS.TRACTOR;
      case MachineGroupCode.TRUCK:
        return MAIN_MAP_TELEMATICS_MACHINE_CLUSTERS_COLORS.TRUCK;
      default:
        return MAIN_MAP_TELEMATICS_MACHINE_CLUSTERS_COLORS.DEFAULT;
    }
  }

  getMachineClusterStrokesStyles(features: Feature[]) {
    const featureGroups = groupBy(features, feature => feature.get('group'));
    const strokesStyles: Style[] = [];
    let offset = 0;
    const CIRCUMFERENCE = Math.ceil(MAIN_MAP_TELEMATICS_MACHINE_CLUSTER_CIRCLE_RADIUS * 2 * Math.PI);
    forEach(featureGroups, (value, key) => {
      const circleArcRatio = value.length / features.length;
      strokesStyles.push(
        new Style({
          image: new CircleStyle({
            stroke: new Stroke({
              color: this.getMachineClusterStrokeColor(key),
              width: MAIN_MAP_TELEMATICS_MACHINE_CLUSTER_STROKE_WIDTH,
              lineDash: [Math.ceil(circleArcRatio * CIRCUMFERENCE), Math.ceil((1 - circleArcRatio) * CIRCUMFERENCE)],
              lineCap: 'butt',
              lineDashOffset: -Math.round(offset * CIRCUMFERENCE),
            }),
            radius: MAIN_MAP_TELEMATICS_MACHINE_CLUSTER_CIRCLE_RADIUS,
          }),
        }),
      );
      offset += circleArcRatio;
    });
    return strokesStyles;
  }

  getMachinePositionsStyle = (feature: Feature, resolution: number) => {
    const features = feature.get('features');
    const size = features.length;

    if (size > 1) {
      const strokesStyles = this.getMachineClusterStrokesStyles(features);

      const machineClusterStyle = [
        new Style({
          image: new CircleStyle({
            radius: MAIN_MAP_TELEMATICS_MACHINE_CLUSTER_CIRCLE_RADIUS - 1,
            stroke: new Stroke({
              color: MAIN_MAP_TELEMATICS_MACHINE_CLUSTERS_COLORS.DEFAULT,
              width: MAIN_MAP_TELEMATICS_MACHINE_CLUSTER_STROKE_WIDTH,
            }),
            fill: new Fill({
              color: '#ffffff',
            }),
          }),
          text: new Text({
            text: size.toString(),
            font: 'bold 16px Arial',
            fill: new Fill({
              color: MAIN_MAP_TELEMATICS_TEXT_COLOR,
            }),
            offsetY: 1,
          }),
        }),
        ...strokesStyles,
      ];

      return machineClusterStyle;
    }

    const machinePosition: PositionDetailTo = feature.get('features')[0].get('machinePosition');

    const machine: PositionMachineTo | undefined = machinePosition.machine;
    const driver: PositionDriverTo | undefined = machinePosition.driver;

    const hasNeededResolution = resolution < 5;

    // https://openlayers.org/en/latest/examples/rich-text-labels.html
    const machineText = [
      `${machine?.name ?? machine?.gpsUnit ?? ''}`, 'bold 14px Roboto',
      ` ${machine?.licencePlate ?? machine?.gpsUnit ?? ''}`, '',
    ];
    const driverText = [
      '\n', '',
      `${driver?.name ?? ''}`, 'bold 14px Roboto',
    ];
    const isSelected = this.selectedMachineGpsUnit === machine?.gpsUnit;

    const singleMachineStyle = new Style({
      image: new Icon({
        src: isSelected ? this.getMachineStrokeIcon(machine?.group) : this.getMachineIcon(machine?.group),
        scale: 1.25,
        rotation: machinePosition.azimuth,
      }),
      text: hasNeededResolution ? new Text({
        text: driver ? [...machineText, ...driverText] : machineText,
        fill: new Fill({
          color: MAIN_MAP_TELEMATICS_TEXT_COLOR,
        }),
        font: 'normal 14px Roboto',
        placement: 'point',
        textAlign: 'left',
        backgroundFill: new Fill({ color: '#fff' }),
        padding: [10, 10, 6, 10],
        offsetX: 40,
      }) : undefined,
    });

    return singleMachineStyle;
  }

  getMachineIcon = (group?: MachineGroupCode) => {
    switch (group) {
      case MachineGroupCode.COMBINE:
        return combineIcon;
      case MachineGroupCode.LOADER:
        return loaderIcon;
      case MachineGroupCode.OTHER:
        return otherIcon;
      case MachineGroupCode.PASSENGER:
        return passengerIcon;
      case MachineGroupCode.TRACTOR:
        return tractorIcon;
      case MachineGroupCode.TRUCK:
        return truckIcon;
      default:
        return null;
    }
  };

  getMachineStrokeIcon = (group?: MachineGroupCode) => {
    switch (group) {
      case MachineGroupCode.COMBINE:
        return combineIcon;
      case MachineGroupCode.LOADER:
        return loaderStrokeIcon;
      case MachineGroupCode.OTHER:
        return otherStrokeIcon;
      case MachineGroupCode.PASSENGER:
        return passengerStrokeIcon;
      case MachineGroupCode.TRACTOR:
        return tractorStrokeIcon;
      case MachineGroupCode.TRUCK:
        return truckStrokeIcon;
      default:
        return null;
    }
  };

  setMachineDrivesHistoryLayer = () => {
    this.machineDrivesHistoryLayer = new VectorLayer({
      source: new VectorSource(),
      style: this.getMachineDrivesHistoryStyle,
      // hack to get above the decluttered parcel labels
      // https://github.com/openlayers/openlayers/issues/10096
      className: 'telematics',
      zIndex: 5,
    });

    this.map.addLayer(this.machineDrivesHistoryLayer);
  }

  getMachineDrivesHistoryStyle = () => new Style({
    stroke: new Stroke({
      color: TELEMATICS_GEOMETRY_COLOR,
      width: TELEMATICS_GEOMETRY_WIDTH,
    }),
  });

  unsetMachineDrivesHistoryLayer = () => {
    if (!this.machineDrivesHistoryLayer) return;

    this.map.removeLayer(this.machineDrivesHistoryLayer);
    this.machineDrivesHistoryLayer = null;
  }

  setMachineDrivesHistory = (machineDrivesHistory: DailyPositionTo[], withZooming?: boolean) => {
    if (!this.machineDrivesHistoryLayer) return;

    const vectorSource = this.machineDrivesHistoryLayer.getSource();

    if (!vectorSource) return;

    vectorSource?.clear();

    machineDrivesHistory.forEach((x) => {
      if (!x?.geometry?.coordinates) return;
      const feature = new Feature({
        geometry: Geometry.readGeometry(x.geometry, this.transformOptions),
      });
      feature.set('machineDriveHistory', x);

      vectorSource?.addFeature(feature);
    });

    if (!machineDrivesHistory.length) return;

    if (withZooming) {
      const newExtent = vectorSource.getExtent();
      this.map.getView().fit(newExtent, {
        duration: 250,
      });
    }
  }

  setSelectedMachineGpsUnit = (gpsUnit?: string) => {
    this.selectedMachineGpsUnit = gpsUnit;
    this.machinePositionsLayer?.changed();
  }

  setClickEvent = () => {
    this.clickEvent = this.map.on('singleclick', evt => {
      let cluster: FeatureLike | undefined;
      this.map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
        if (layer === this.machinePositionsLayer) {
          cluster = feature;
        }
      });

      const features = cluster?.get('features') as Feature[] | undefined;
      if (!features) {
        if (this.selectedMachineGpsUnit) {
          this.onSelectedMachineGpsUnitChange(undefined);
        }
        return;
      }

      if (features.length === 1) {
        this.onSelectedMachineGpsUnitChange(features[0].get('machinePosition').machine.gpsUnit);
      } else {
        const extent = boundingExtent(
          features.map((r) => (r.getGeometry() as Point).getCoordinates()),
        );
        this.map.getView().fit(extent, { duration: 500, padding: [100, 100, 100, 100] });
      }
    });
  };

  setHoverEvent = () => {
    this.hoverEvent = this.map.on('pointermove', evt => {
      const hit = this.map.forEachFeatureAtPixel(evt.pixel, (_feature, layer) => {
        if (layer === this.machinePositionsLayer) {
          return true;
        }
      }) || false;

      if (hit) {
        this.map.getTargetElement().style.cursor = 'pointer';
      } else {
        this.map.getTargetElement().style.cursor = '';
      }
    });
  }

  unSetEvents = () => {
    if (this.clickEvent) {
      unByKey(this.clickEvent);
    }
    if (this.hoverEvent) {
      unByKey(this.hoverEvent);
    }
  }
}
