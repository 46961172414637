import React, { CSSProperties, FC, ReactNode, useEffect } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { TableCell } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getIrrigationAdminDevices,
  getIrrigationAdminIsFetching,
  getIrrigationAdminTotalCount,
} from '../../../../../shared/api/irrigation/devices/devices.selectors';

import { fetchIrrigationAdminDevices } from '../../actions/admin.devices.actions';

import { NAMESPACE as namespace } from '../../reducer/admin.devices.reducer';

import CfTableBodyEmpty from '../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty';
import CfTableBodyLoader from '../../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader';
import CfTableCell from '../../../../../shared/components/tables/CfTableCell/CfTableCell';
import CfTableRowToolButton from '../../../../../shared/components/tables/CfTableRowToolButton/CfTableRowToolButton';
import CfTableRowTools from '../../../../../shared/components/tables/CfTableRowTools/CfTableRowTools';
import CfTableWrapper from '../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableCheckbox from '../../../../../shared/containers/CfTableCheckbox/CfTableCheckbox';
import CfTableFooter from '../../../../../shared/containers/CfTableFooter/CfTableFooter';
import CfTableHead from '../../../../../shared/containers/CfTableHead/CfTableHead';
import { Thunk } from '../../../../../types';
import DeviceStatus from '../../components/DeviceStatus/DeviceStatus';
import {
  getIrrigationAdminPage,
  getIrrigationAdminOrder,
  getIrrigationAdminOrderBy,
  getIrrigationAdminRowsPerPage,
  getIrrigationAdminTextFilter,
  getIrrigationAdminSelected,
  getIrrigationAdminSelectedOnPage,
  getIrrigationAdminAdvancedFilter,
  getIrrigationAdminFarmFilter,
  getIrrigationAdminCountryFilter,
} from '../../selectors/admin.devices.selector';
import IrrigationAdminAssignFarm from '../IrrigationAdminAssignFarm/IrrigationAdminAssignFarm';

import useIrrigationAdminTableStyles from './useIrrigationAdminTable.style';

import {
  AdminFarm,
  IrrigationAdminAdvancedFilter,
  IrrigationAdminDevice,
  IrrigationAdminState,
} from '../../admin.irrigation.types';

const getColDesc = (sortable: boolean, label: ReactNode, style?: CSSProperties) => ({
  align: 'inherit',
  sortable,
  label,
  style,
});

const columns = {
  id: getColDesc(true, <FormattedMessage id="common.id" />, { width: '80px' }),
  external_id: getColDesc(false, <FormattedMessage id="SensorsAdmin.external_id" />),
  farm: getColDesc(false, <FormattedMessage id="common.farm" />),
  device_name: getColDesc(false, <FormattedMessage id="IrrigationAdmin.deviceName" />),
  device_type: getColDesc(false, <FormattedMessage id="common.type" />),
  status: getColDesc(false, <FormattedMessage id="common.state" />, { maxWidth: '200px' }),
};

interface Props {
  adminDevices: IrrigationAdminDevice[];
  advancedFilter: IrrigationAdminAdvancedFilter;
  count: number;
  country: {
    code: string;
  };
  farm: AdminFarm;
  fetchIrrigationAdminDevices: () => void;
  isFetching: boolean;
  onAssignDevicesToFarm: (deviceIds: string[], farm: AdminFarm) => void;
  openDeviceEditDialog: (adminDevice: IrrigationAdminDevice) => void;
  order: string;
  orderBy: string;
  page: number;
  rowsPerPage: number,
  selected: string[];
  selectedOnPage: string[];
  textFilter: string;
}

const IrrigationAdminTable: FC<Props> = ({
  adminDevices,
  advancedFilter,
  count,
  country,
  farm,
  fetchIrrigationAdminDevices,
  isFetching,
  onAssignDevicesToFarm,
  openDeviceEditDialog,
  order,
  orderBy,
  page,
  rowsPerPage,
  selected,
  selectedOnPage,
  textFilter,
}) => {
  const classes = useIrrigationAdminTableStyles();

  useEffect(() => {
    fetchIrrigationAdminDevices();
  }, [page, rowsPerPage, order, orderBy, textFilter, advancedFilter, farm, country, fetchIrrigationAdminDevices]);

  const handleAssignDevicesToFarm = (
    deviceIds: string[],
    farm: AdminFarm,
  ) => {
    onAssignDevicesToFarm(deviceIds, farm);
  };

  const onOpenDeviceEditDialod = (adminDevice: IrrigationAdminDevice) => {
    openDeviceEditDialog(adminDevice);
  };

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={columns}
        items={adminDevices}
        namespace={namespace}
        order={order}
        orderBy={orderBy}
        selected={selected}
        selectedOnPage={selectedOnPage}
      />
      {isFetching && <CfTableBodyLoader columns={columns} />}

      {!adminDevices.length ? (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      ) : (
        <TableBody>
          {adminDevices.map(adminDevice => (
            <TableRow className={classes.tableRow} key={adminDevice.id}>
              {adminDevice.allowTenantUpdate ?
                <CfTableCheckbox
                  id={adminDevice.id}
                  namespace={namespace}
                  selected={selected}
                /> :
                <TableCell />
              }
              <CfTableCell name={'id'}>{adminDevice.id}</CfTableCell>
              <CfTableCell name={'external_id'}>{adminDevice.externalId}</CfTableCell>
              <CfTableCell name={'farm'}>
                <IrrigationAdminAssignFarm
                  disabled={!adminDevice.allowTenantUpdate}
                  isSingleAssigned
                  item={adminDevice}
                  onAssignDevicesToFarm={(farm: AdminFarm) => handleAssignDevicesToFarm([adminDevice.id], farm)}
                />
              </CfTableCell>
              <CfTableCell name={'device_name'}>{adminDevice.name}</CfTableCell>
              <CfTableCell name={'device_type'}>{adminDevice.type}</CfTableCell>
              <CfTableCell name={'status'}>
                <DeviceStatus device={adminDevice} />
              </CfTableCell>
              <CfTableRowTools toolsClass={classes.tableRowTools}>
                {adminDevice.allowTenantUpdate && <CfTableRowToolButton
                  icon={<EditIcon />}
                  onClick={() => onOpenDeviceEditDialod(adminDevice)}
                  tooltipMessage={<FormattedMessage id="IrrigationAdmin.editDevice" />}
                />}
              </CfTableRowTools>
            </TableRow>
          ))}
        </TableBody>
      )}
      <CfTableFooter count={count} namespace={namespace} page={page} rowsPerPage={rowsPerPage} />
    </CfTableWrapper>
  );
};

const mapStateToProps = (state: IrrigationAdminState) => ({
  adminDevices: getIrrigationAdminDevices(state),
  page: getIrrigationAdminPage(state),
  order: getIrrigationAdminOrder(state),
  orderBy: getIrrigationAdminOrderBy(state),
  rowsPerPage: getIrrigationAdminRowsPerPage(state),
  textFilter: getIrrigationAdminTextFilter(state),
  isFetching: getIrrigationAdminIsFetching(state),
  count: getIrrigationAdminTotalCount(state),
  selected: getIrrigationAdminSelected(state),
  selectedOnPage: getIrrigationAdminSelectedOnPage(state),
  advancedFilter: getIrrigationAdminAdvancedFilter(state),
  farm: getIrrigationAdminFarmFilter(state),
  country: getIrrigationAdminCountryFilter(state),
});

const mapDispatchToProps = (dispatch: Thunk<IrrigationAdminState>) =>
  bindActionCreators(
    {
      fetchIrrigationAdminDevices,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(IrrigationAdminTable);
