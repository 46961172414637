import { AnyAction } from 'redux';

import * as types from './precision.constants';

import { PrecisionState } from '../../../../reducers/precision.reducer.types';

const initialState: PrecisionState['api']['asAppliedDetail'] = {
  itemError: {},
  isFetchingItem: false,
  item: null,
  pointsError: {},
  points: null,
  isFetchingPoints: false,
};

const asAppliedDetailApi = () => (state = initialState, action: AnyAction): PrecisionState['api']['asAppliedDetail'] => {
  switch (action.type) {
    case types.GET_PRECISION_TASK_DATA_REQUEST:
      return {
        ...state,
        isFetchingItem: true,
        itemError: {},
      };
    case types.GET_PRECISION_TASK_DATA_SUCCESS:
      return {
        ...state,
        itemError: {},
        isFetchingItem: false,
        item: action.payload,
      };
    case types.GET_PRECISION_TASK_DATA_ERROR:
      return {
        ...state,
        isFetchingItem: false,
        item: null,
        itemError: action.payload,
      };
    case types.GET_PRECISION_TASK_TIMELINE_DATA_REQUEST:
      return {
        ...state,
        isFetchingPoints: true,
        points: null,
        pointsError: {},
      };
    case types.GET_PRECISION_TASK_TIMELINE_DATA_SUCCESS:
      return {
        ...state,
        isFetchingPoints: false,
        points: action.payload,
        pointsError: {},
      };
    case types.GET_PRECISION_TASK_TIMELINE_DATA_ERROR:
      return {
        ...state,
        points: null,
        isFetchingPoints: false,
        pointsError: action.payload,
      };
    case types.RESET_PRECISION_TASK_TIMELINE_DATA:
      return {
        ...state,
        points: null,
        isFetchingPoints: false,
        pointsError: {},
      };
    default:
      return state;
  }
};

export default asAppliedDetailApi;
