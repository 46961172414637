import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../../api.constants';
import * as types from './seeds.constants';

import { RsaaTypes } from '../../../api.types';
import { getSeedsParams } from './seeds.types';

export const getSeedsApi = (params: getSeedsParams) => ({
  [RSAA]: {
    endpoint: `catalogues/seeds?${queryString.stringify(params)}&`,
    method: methods.GET,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_SEEDS_REQUEST,
      types.GET_SEEDS_SUCCESS,
      types.GET_SEEDS_ERROR,
    ] as RsaaTypes,
  },
});

export const resetSeedsApi = () => ({
  type: types.RESET_SEEDS,
});
