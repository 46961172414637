import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './telematicsAdmin.constants';

import { RsaaMethods, RsaaTypes } from '../../api.types';
import { AccountFilterCriteriaTo, Catalogue, EconomicSystem } from '../telematics.types';

interface AccountApiParams extends AccountFilterCriteriaTo {
  page: number;
  'per-page': number;
  'sort-col': string;
  'sort-dir': string;
}

export const getTelematicsAccountFarmsApi = (params: AccountApiParams) => ({
  [RSAA]: {
    endpoint: `admin/account?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [types.GET_ACCOUNT_FARMS_REQUEST, types.GET_ACCOUNT_FARMS_SUCCESS,
      types.GET_ACCOUNT_FARMS_ERROR] as RsaaTypes,
  },
});

export const getCountryCodesApi = () => ({
  [RSAA]: {
    endpoint: 'admin/account/country?&',
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [types.GET_COUNTRY_CODES, types.GET_COUNTRY_CODES_SUCCESS, types.GET_COUNTRY_CODES_ERROR] as RsaaTypes,
  },
});

export type UpdateFarmsPayload = {
  automaticActions: boolean;
  catalogue?: Catalogue;
  economicSystem?: EconomicSystem;
  economicSystemDate?: string;
  enabled: boolean;
  externalId: string;
  farmId: string;
}[]

export const updateTelematicsAccountFarmsApi = (payload: UpdateFarmsPayload) => ({
  [RSAA]: {
    endpoint: 'admin/account?',
    method: methods.PATCH as RsaaMethods,
    body: JSON.stringify(payload),
    module: modules.TELEMATICS,
    types: [
      types.UPDATE_ACCOUNT_FARMS_REQUEST,
      types.UPDATE_ACCOUNT_FARMS_SUCCESS,
      types.UPDATE_ACCOUNT_FARMS_ERROR,
    ] as RsaaTypes,
  },
});
