import React, { Component } from 'react';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector, SubmissionError } from 'redux-form';

import { getSubtractableArea } from '../../../../../shared/api/agroevidence/subtractableAreas/subtractableAreas.selectors';

import { calculateSubtractableAreaApi } from '../../../../../shared/api/agroevidence/subtractableAreas/subtractableAreas.api';
import CfFormattedField from '../../../../../shared/components/form/CfFormattedField/CfFormattedField';
import CfFormattedTextField from '../../../../../shared/components/form/CfFormattedTextField/CfFormattedTextField';
import CfReduxFormSelect from '../../../../../shared/components/form/CfReduxFormSelect/CfReduxFormSelect';
import InputActionButton from '../../../../../shared/components/misc/InputActionButton/InputActionButton';
import * as validators from '../../../../../shared/misc/validators';

const subtractableAreasTypes = [
  {
    id: 'Absolute',
    name: 'absolute',
  },
  {
    id: 'Boundary',
    name: 'boundary',
  },
  {
    id: 'Water',
    name: 'water',
  },
];

const styles = {
  subtractableAreaForm: {
    margin: '5px 0px 30px 0px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  typeField: {
    display: 'inherit',
  },
  inputLabel: {
    fontWeight: 'inherit',
  },
  helperText: {
    position: 'absolute',
    top: 45,
  },
  positionStart: {
    position: 'absolute',
  },
  rowContainer: {
    alignItems: 'center',
  },
};

class NewSubtractableAreaForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableInput: false,
      changingType: false,
    };
    this.areaToAdd = {};
  }

  componentDidUpdate(prevProps) {
    const { subtractableArea } = this.props;
    if (subtractableArea && subtractableArea !== prevProps.subtractableArea && !this.state.changingType) {
      this.props.change('newSubtractableArea', Math.round(subtractableArea.area * 100) / 100);
      if (this.areaToAdd.type === 'Absolute') {
        this.addSA(subtractableArea);
      }
    }
  }

  onAddType = restrictions => {
    this.props.change('newRestrictionValue', '');
    this.props.change('newSubtractableArea', '');
    this.props.untouch('newRestrictionValue');
    this.props.untouch('newSubtractableArea');
    this.setState({ changingType: true });
    if (restrictions[0]) {
      switch (restrictions[0]) {
        case 'A':
          this.setState({ disableInput: true });
          break;
        case 'B':
          this.setState({ disableInput: false });
          break;
        case 'W':
          this.setState({ disableInput: false });
          break;
        default:
          this.setState({ disableInput: false });
      }
    }
  };

  onAddValue = () => {
    this.setState({ changingType: false });
    const type = this.props.formValues.newRestrictionType;
    const value = parseFloat(this.props.formValues.newRestrictionValue);
    if (type && value) {
      const p = {
        type,
        value,
      };
      this.props.calculateSubtractableAreaApi(this.props.parcelId, p);
    }
  };

  onAddArea = () => {
    this.setState({ changingType: false });
    const type = this.props.formValues.newRestrictionType;
    if (type === 'Absolute') {
      const value = parseFloat(this.props.formValues.newSubtractableArea);
      const p = {
        type,
        value,
      };
      this.props.calculateSubtractableAreaApi(this.props.parcelId, p);
    }
  };

  onSubmit = values => {
    const type = values.newRestrictionType;
    const area = parseFloat(values.newSubtractableArea);
    let value = parseFloat(values.newRestrictionValue);
    if (type === 'Absolute') {
      value = area;
    }
    this.areaToAdd = { type, value, area };

    const sa = this.props.subtractableArea;
    if (this.saAlreadyInResult(this.areaToAdd)) {
      throw new SubmissionError({
        newRestrictionValue: <FormattedMessage id="SubtractableAreaDialog.areaAlreadyInResult" />,
      });
    }
    if (area > this.props.maxArea) {
      throw new SubmissionError({
        newSubtractableArea: <FormattedMessage id="validation.tooBig" />,
      });
    } else {
      this.addSA(sa);
    }
  };

  addSA = sa => {
    if (this.areaToAdd.area > this.props.maxArea) {
      return new SubmissionError({
        newSubtractableArea: <FormattedMessage id="validation.tooBig" />,
      });
    } else if (
      this.areaToAdd.type === sa.type &&
      this.areaToAdd.value === sa.value
    ) {
      this.props.onNewTypeAdd(sa);
      this.props.reset();
      this.areaToAdd = {};
    }
    return sa;
  };

  saAlreadyInResult = sa => {
    let saAlreadyInResult = false;
    this.props.subtractableAreas.forEach(addedArea => {
      if (
        addedArea.type !== 'Absolute' &&
        addedArea.type === sa.type &&
        addedArea.value === sa.value
      ) {
        saAlreadyInResult = true;
      }
    });
    return saAlreadyInResult;
  };

  render() {
    const restrictionType = '';
    const { classes, formValues, handleSubmit, subtractableAreasIds } = this.props;

    return (
      <FormControl className={classes.subtractableAreaForm} fullWidth={true}>
        <Grid className={classes.rowContainer} container spacing={2}>
          <Grid item xs={5}>
            <FormControl fullWidth={true}>
              <InputLabel className={classes.inputLabel}>
                <FormattedMessage id="SubtractableAreaDialog.restrictionType" />
              </InputLabel>
              <Field
                component={CfReduxFormSelect}
                name="newRestrictionType"
                onChange={e => this.onAddType(e)}
                value={restrictionType}
              >
                {subtractableAreasTypes
                  .filter(sa => subtractableAreasIds.includes(sa.id))
                  .map(type => (
                    <MenuItem className={classes.menuItem} key={type.id} value={type.id}>
                      <FormattedMessage id={`SubtractableAreaDialog.${type.name}`} />
                    </MenuItem>
                  ))}
              </Field>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            {formValues.newRestrictionType !== 'Absolute' && (
              <CfFormattedField
                component={CfFormattedTextField}
                disabled={this.state.disableInput}
                FormHelperTextProps={{ className: classes.helperText }}
                fullWidth
                label="m"
                name="newRestrictionValue"
                onBlur={this.onAddValue}
                type="text"
                InputLabelProps={{
                  style: styles.inputLabel,
                }}
                validate={
                  formValues.newRestrictionType !== 'Absolute'
                    ? [validators.required, validators.positiveNumber]
                    : undefined
                }
              />
            )}
          </Grid>
          <Grid item xs={3}>
            <CfFormattedField
              component={CfFormattedTextField}
              disabled={!this.state.disableInput}
              FormHelperTextProps={{ className: classes.helperText }}
              fullWidth
              label="ha"
              name="newSubtractableArea"
              onBlur={this.onAddArea}
              type="text"
              InputLabelProps={{
                style: styles.inputLabel,
              }}
              validate={
                formValues.newRestrictionType === 'Absolute'
                  ? [validators.requiredAndPositiveNumber]
                  : validators.zeroPositiveNumber
              }
            />
          </Grid>
          <Grid item xs={1}>
            <InputAdornment classes={{ positionStart: classes.positionStart }} position="start">
              <InputActionButton id="add-subtracted-area" onClick={handleSubmit(this.onSubmit)} />
            </InputAdornment>
          </Grid>
        </Grid>
      </FormControl>
    );
  }
}

NewSubtractableAreaForm.propTypes = {
  classes: PropTypes.object,
  formValues: PropTypes.object,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onNewTypeAdd: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  maxArea: PropTypes.number,
  parcelId: PropTypes.string,
  subtractableArea: PropTypes.object,
  calculateSubtractableAreaApi: PropTypes.func.isRequired,
  subtractableAreasIds: PropTypes.array,
  subtractableAreas: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

NewSubtractableAreaForm.defaultProps = {
  classes: {},
  formValues: {},
  maxArea: 0,
  parcelId: '',
  subtractableArea: {},
  subtractableAreasIds: subtractableAreasTypes.map(sa => sa.id),
};

const mapStateToProps = state => ({
  subtractableArea: getSubtractableArea(state),
  formValues: formValueSelector('restrictions')(
    state,
    'newRestrictionValue',
    'newSubtractableArea',
    'newRestrictionType',
  ),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      calculateSubtractableAreaApi,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'restrictions',
  })(withStyles(styles)(injectIntl(NewSubtractableAreaForm))),
);
