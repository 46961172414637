import React, { FC, Fragment, ReactElement } from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, FormattedDate, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getParcelSeedApplication, getParcelPredecessors, getParcelEagriRestrictions } from '../../../../../../shared/api/agroevidence/parcels/parcels.selectors';
import { getIsFetchingParcelDetails } from '../../selectors/parcelDetailOverview.selectors';

import { assignZone, assignCenter, assignCrop } from '../../../../shared/actions/parcels.actions';
import { updateParcelNote, updateLpisYieldGrade } from '../../actions/parcelDetailOverview.actions';

import { getParcelApi } from '../../../../../../shared/api/agroevidence/parcels/parcels.api';
import { getParcelSowingPlanApi } from '../../../../../../shared/api/agroevidence/sowingPlan/sowingPlan.api';
import CfLoader from '../../../../../../shared/components/common/CfLoader/CfLoader';
import Localization from '../../../../../../shared/services/Localization.service';
import { AsyncFn, Thunk } from '../../../../../../types';
import PrecisionTableServiceIndicators from '../../../../../precision/components/PrecisionTableServiceIndicators';
import ParcelCenterAssign from '../../../../shared/containers/ParcelCenterAssign/ParcelCenterAssign';
import ParcelZoneAssign from '../../../../shared/containers/ParcelZoneAssign/ParcelZoneAssign';
import ParcelsService, { SHOWN_NUMBER_SOWING_SEASONS, START_YEAR_FIRST_SOWING_SEASONS } from '../../../../shared/services/Parcels.service';
import EditableNote from '../../components/EditableNote/EditableNote';
import HistoricalParcelsPanel from '../../components/HistoricalParcelsPanel/HistoricalParcelsPanel';
import ParcelDetailCropTable from '../../components/ParcelDetailCropTable/ParcelDetailCropTable';
import ParcelDetailRestrictions from '../../components/ParcelDetailRestrictions/ParcelDetailRestrictions';
import ParcelDetailSection from '../../components/ParcelDetailSection/ParcelDetailSection';
import ParcelSowingPlanTable from '../../components/ParcelSowingPlanTable';
import YieldGradeSelector from '../../components/YieldGradeSelector/YieldGradeSelector';

import { ParcelsState } from '../../../../../../reducers/parcels.reducer.types';
import { ParcelDetailTo, ParcelEagriInfoTo, ParcelPredecessorTo, ParcelSeedApplicationTo, SowingPlanSeasonCropTo } from '../../../../../../shared/api/agroevidence/agroevidence.types';
import { PrecisionParcel } from '../../../../../../shared/api/sentinel/precision/precision.types';

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    marginBottom: 10,
    fontSize: 18,
  },
  paper: {
    padding: '10px 15px',
    fontSize: '13px',
  },
  precisionPaper: {
    padding: 15,
    fontSize: '13px',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 32,
  },
  panelItem: {
    marginBottom: 20,
  },
  overviewPanelItem: {
    marginBottom: 10,
  },
  errorNotificationContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  errorNotification: {
    color: theme.palette.error.main,
  },
}));

export interface ParcelDetailOverviewProps {
  assignCenter: (centerId: string, parcelId: string) => void,
  assignCrop: (cropId: string, seasonId: string, parcelId: string) => void,
  assignZone: (zoneId: string, parcelId: string) => void,
  countryCode: string;
  displayedMap: boolean,
  farmId: string;
  getParcelApi: (parcelId: string) => void,
  getParcelSowingPlanApi: (parcelId: string, startYear: number, size: number) => void,
  goToParcel: (parcel: ParcelPredecessorTo) => void,
  isFetchingParcelDetails: boolean,
  langId: string;
  parcel: ParcelDetailTo,
  parcelPredecessors: ParcelPredecessorTo[],
  parcelRestrictions: ParcelEagriInfoTo,
  parcelSeedApplication: ParcelSeedApplicationTo[],
  parcelSowingPlan: SowingPlanSeasonCropTo[],
  precisionParcel: PrecisionParcel,
  updateLpisYieldGrade: (parcelId: string, chosenYieldGrade: 'I' | 'II' | 'III') => void,
  updateParcelNote: (parcelId: string, newNote: string) => void,
}

export const ParcelDetailOverview: FC<ParcelDetailOverviewProps> = ({
  assignCenter,
  assignCrop,
  assignZone,
  countryCode,
  displayedMap,
  farmId,
  getParcelApi,
  getParcelSowingPlanApi,
  goToParcel,
  isFetchingParcelDetails,
  langId,
  parcel,
  parcelPredecessors,
  parcelRestrictions,
  parcelSeedApplication,
  parcelSowingPlan,
  precisionParcel,
  updateLpisYieldGrade,
  updateParcelNote,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const confirmEditingNote = (newNote: string) => updateParcelNote(parcel.id, newNote);

  const onAssignCenter = (centerId: string, parcelId: string) => {
    (assignCenter as AsyncFn<string, string>)(centerId, parcelId).then(() => {
      getParcelApi(parcelId);
    });
  };

  const onAssignZone = (zoneId: string, parcelId: string) => {
    (assignZone as AsyncFn<string, string>)(zoneId, parcelId).then(() => {
      getParcelApi(parcelId);
    });
  };

  const onAssignCrop = (cropId: string, seasonId: string, parcelId: string) => {
    (assignCrop as AsyncFn<string, string, string>)(cropId, seasonId, parcelId).then(() => {
      getParcelSowingPlanApi(parcelId, START_YEAR_FIRST_SOWING_SEASONS, SHOWN_NUMBER_SOWING_SEASONS);
    });
  };

  const renderHeading = (messageId: string) => (
    <Grid data-test="heading" item xs={12}>
      <Typography className={classes.heading} variant="h5">
        <FormattedMessage id={messageId} />
      </Typography>
    </Grid>
  );

  const renderErrorNotification = (notificationString: string) => (
    <div className={classes.errorNotificationContainer}>
      <span className={classes.errorNotification}><FormattedMessage id={notificationString} /></span>
    </div>
  );

  const renderParcelDetailSection = (translationId: string, value: ReactElement | string) => {
    const gridItem = displayedMap ? 4 : 3;
    return (
      <Grid className={classes.gridItem} item md={gridItem} sm={4} xs={6}>
        <ParcelDetailSection translationId={translationId} valueItem={value} />
      </Grid>
    );
  };

  const renderOverview = () => (
    <Grid className={classes.overviewPanelItem} data-test="parcel-overview" item xs={12}>
      <Paper className={classes.paper}>
        <Grid container>
          {renderParcelDetailSection('common.area', `${Localization.num2str(parcel.area, intl.locale)} ha`)}
          {renderParcelDetailSection('common.crop',
            parcel.seedApplication?.seed.crop.name ?? <FormattedMessage id="common.noCrop" />,
          )}
          {renderParcelDetailSection('common.zone',
            <ParcelZoneAssign
              onZoneChange={onAssignZone}
              parcel={parcel}
          />,
          )}
          {renderParcelDetailSection('common.culture', parcel.lpisBlock.landUseName)}
          {renderParcelDetailSection('common.variety', parcel.seedApplication?.seed?.varietyName ?? '-')}
          {renderParcelDetailSection('common.center',
            <ParcelCenterAssign
              onCenterChange={onAssignCenter}
              parcel={parcel}
            />,
          )}
          {renderParcelDetailSection('common.validity',
            <span>
              <FormattedDate value={parcel.validFrom} />
              {' \u2013 '}
              {parcel.validTo ? <FormattedDate value={parcel.validTo} /> : ''}
            </span>,
          )}
        </Grid>
      </Paper>
    </Grid>
  );

  const renderSowingPlan = () => (
    <Grid className={classes.panelItem} data-test="sowing-plan" item xs={12}>
      <Paper className={classes.paper}>
        <ParcelSowingPlanTable
          onCropChange={onAssignCrop}
          parcel={parcel}
          parcelSowingPlan={parcelSowingPlan}
        />
      </Paper>
    </Grid>
  );

  const renderCzechSpecifics = () => {
    const { altitude, applicationZone, slope, userYieldGrade, yieldGrade } = parcel.lpisBlock;
    return (
      <Grid className={classes.panelItem} data-test="czech-specific-overview" item xs={12}>
        <div>
          <Paper className={classes.paper}>
            <Grid container>
              {renderParcelDetailSection(
                'term.NSA',
                <FormattedMessage id={`common.${parcel.nitrateVulnerable ? 'yes' : 'no'}`} />,
              )}
              {renderParcelDetailSection(
                'common.yieldGrade',
                parcel.nitrateVulnerable
                  ? renderEditableYieldGrade(yieldGrade, userYieldGrade)
                  : '-',
              )}
              {renderParcelDetailSection('common.applicationZone', applicationZone || '-')}
              {renderParcelDetailSection(
                'common.altitude',
                `${Localization.num2str(altitude, intl.locale)} m`,
              )}
              {renderParcelDetailSection(
                'common.slope',
                `${Localization.num2str(slope, intl.locale)}\xB0`,
              )}
              {!parcelRestrictions?.nitrate && renderErrorNotification('ParcelDetailOverview.restrictions.error')}
              {parcelRestrictions?.nitrate && renderParcelDetailSection(
                'ParcelDetailOverview.restrictions',
                parcelRestrictions.nitrate?.length > 0 || parcelRestrictions?.aeko?.length > 0 ? (
                  <ParcelDetailRestrictions restrictions={parcelRestrictions} />
                ) : (
                  '-'
                ),
              )}
            </Grid>
          </Paper>
        </div>
      </Grid>
    );
  };

  const renderCropsTable = () => (
    <Grid className={classes.panelItem} data-test="crops-table" item xs={12}>
      <ParcelDetailCropTable langId={langId} parcelSeedApplication={parcelSeedApplication} />
    </Grid>
  );

  const renderPrecisionFarming = () => (
    <Grid className={classes.panelItem} data-test="precision-farming" item xs={12}>
      <Paper className={classes.precisionPaper}>
        <PrecisionTableServiceIndicators farmId={farmId} forceReloadOnClick parcel={precisionParcel} />
      </Paper>
    </Grid>
  );

  const renderHistoricalParcels = () => (
    <Grid className={classes.panelItem} data-test="historical-parcels" item xs={12}>
      <HistoricalParcelsPanel goToParcel={goToParcel} historicalParcels={parcelPredecessors} />
    </Grid>
  );

  const renderNote = () => (
    <Grid className={classes.panelItem} data-test="note" item lg={6} md={9} xs={12}>
      <EditableNote
        defaultValue={intl.formatMessage({ id: 'ParcelDetailOverview.noNote' })}
        initialValue={parcel.notes ? parcel.notes : ''}
        inputTestId="parcel-note"
        onConfirmEditing={(newValue: string) => confirmEditingNote(newValue)}
      />
    </Grid>
  );

  const renderEditableYieldGrade = (yieldGrade?: string, userYieldGrade?: string) => {
    const isHistorical = ParcelsService.isParcelHistorical(parcel);
    return (
      <YieldGradeSelector
        isHistorical={isHistorical}
        updateParcelYieldGrade={(chosenYieldGrade: 'I' | 'II' | 'III') => updateLpisYieldGrade(parcel.id, chosenYieldGrade)}
        userYieldGrade={userYieldGrade}
        yieldGrade={yieldGrade}
      />
    );
  };

  const czechFarm = countryCode === 'CZ';
  const hasPrecisionService = precisionParcel !== null;

  return (
    <Fragment>
      {isFetchingParcelDetails ? (
        <CfLoader />
      ) : (
        <Fragment>
          <Grid container>
            {renderHeading('ParcelDetailOverview.basicOverview')}
            {renderOverview()}
            {czechFarm && renderCzechSpecifics()}
            {renderHeading('ParcelDetailOverview.sowingPlan')}
            {renderSowingPlan()}
            {renderHeading('ParcelDetailOverview.crops')}
            {renderCropsTable()}
            {hasPrecisionService && renderHeading('ParcelDetailOverview.precisionFarming')}
            {hasPrecisionService && renderPrecisionFarming()}
            {renderHeading('ParcelDetailOverview.previousHistoricalParcels')}
            {renderHistoricalParcels()}
            {renderHeading('common.note')}
            {renderNote()}
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  parcelSeedApplication: getParcelSeedApplication(state),
  parcelPredecessors: getParcelPredecessors(state),
  isFetchingParcelDetails: getIsFetchingParcelDetails(state),
  parcelRestrictions: getParcelEagriRestrictions(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      updateParcelNote,
      assignZone,
      assignCenter,
      assignCrop,
      getParcelApi,
      getParcelSowingPlanApi,
      updateLpisYieldGrade,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ParcelDetailOverview);
