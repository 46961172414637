import { AnyAction } from 'redux';

import * as types from '../actions/telematicsTabs.constants';

import { TelematicsTabsUI } from '../telematics.types';

export const NAMESPACE = 'telematicsTabs';

export default (reducerNamespace = NAMESPACE) => (
  state: TelematicsTabsUI = {
    lastVisitedTab: undefined,
  },
  action: AnyAction,
) => {
  const { namespace } = action;
  if (namespace && namespace !== reducerNamespace) return state;

  switch (action.type) {
    case types.SET_VISITED_TAB: {
      return {
        ...state,
        lastVisitedTab: action.payload,
      };
    }
    default:
      return state;
  }
};
