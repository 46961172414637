import React, { Component } from 'react';

import Grid from '@mui/material/Grid';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { Field, FieldArray, formValueSelector } from 'redux-form';

import { getActionRestrictions } from '../../../../../shared/api/agroevidence/actions/actions.selectors';
import { getParcelsArea, getDriftClass } from '../../../shared/selectors/actions.selectors';

import CfCheckbox from '../../../../../shared/components/form/CfCheckbox/CfCheckbox';
import CfCheckboxLabel from '../../../../../shared/components/form/CfCheckbox/CfCheckboxLabel';
import unitsMock from '../../../../../shared/mocks/units.mock.json';
import { ActionButtons } from '../../../shared/components/ActionButtons/ActionButtons';
import ActionDate from '../../../shared/components/ActionDate/ActionDate';
import ActionFormButtons from '../../../shared/components/ActionFormButtons/ActionFormButtons';
import ActionFormHeader from '../../../shared/components/ActionFormHeader/ActionFormHeader';
import ActionNote from '../../../shared/components/ActionNote/ActionNote';
import ActionRestrictionsInfo from '../../../shared/components/ActionRestrictionsInfo/ActionRestrictionsInfo';
import ActionTargetCrop from '../../../shared/components/ActionTargetCrop/ActionTargetCrop';
import { ActionDetailContext } from '../../../shared/containers/ActionDetail/ActionDetail';
import ActionParcelsControl from '../../../shared/containers/ActionParcelsControl/ActionParcelsControl';
import ChemFertSelector from '../../../shared/containers/ChemFertSelector/ChemFertSelector';
import actionForm from '../../../shared/hocs/actionForm';
import {
  targetCropChanged,
  parcelsCountChanged,
  resolveTargetCrop,
} from '../../../shared/misc/action.helpers';
import { mapRequestBodyEphActionTo } from '../../../shared/services/ActionToMapper.services';
import { isValidCatchCropApplication, getInitialValues, validate, warn } from '../../misc/eph.helpers';
import EphExpensesControl from '../EphExpensesControl/EphExpensesControl';

const FORM_NAME = 'eph';
const defaultUnit = unitsMock.units[1];

/*
 * KEEP THE COMMENTS UP TO DATE!
 */
export class Eph extends Component {
  componentDidUpdate(prevProps) {
    const {
      existingAction,
      isCatchCrop: prevIsCatchCrop,
      parcels: prevParcels,
      targetCrop: prevTargetCrop,
    } = prevProps;

    const {
      fieldsManuallyChanged,
      parcels: newParcels,
      targetCrop: newTargetCrop,
    } = this.props;

    // Comparison booleans to detect the change
    const targetCropDiff = targetCropChanged(prevTargetCrop, newTargetCrop);
    const targetCropManualDiff = Boolean(fieldsManuallyChanged.targetCrop);
    const parcelsCountDiff = parcelsCountChanged(prevParcels, newParcels);

    // Values calculated based on new inputs
    const resolvedTargetCrop = resolveTargetCrop(newParcels);
    const resolvedCatchCrop = isValidCatchCropApplication(newParcels, newTargetCrop);

    /*
     * Touch target crop in order to get validated. Do when:
     * 1) prev target crop was not set
     * 2) new target crop is set
     * 3) action is already existing
     */
    if (existingAction && !prevTargetCrop && prevTargetCrop !== newTargetCrop) {
      this.props.touch('targetCrop');
    }

    /*
     * Update catch crop when:
     * 1) target crop changed
     * 2) or parcels count changed
     * 3) and resolved target crop doest match current catch crop
     */
    if (targetCropDiff || parcelsCountDiff) {
      if (isValidCatchCropApplication(newParcels, newTargetCrop) !== prevIsCatchCrop) {
        this.props.change('isCatchCrop', resolvedCatchCrop);
      }
    }

    /*
     * Update target crop when:
     * 1) parcels count changed
     * 3) and new target crop is not set or is not manually edited yet
     */
    if (!targetCropManualDiff && !newTargetCrop && parcelsCountDiff && newParcels.length) {
      this.props.change('targetCrop', resolvedTargetCrop);
    }

    /*
     * Set target crop to null when:
     * 1) parcels count changed
     * 2) and there are no parcels
     * 3) and target crop was not changed manually yet
     */
    if (parcelsCountDiff && !newParcels.length && !targetCropManualDiff) {
      this.props.change('targetCrop', null);
      this.props.untouch('targetCrop');
    }
  }

  render() {
    const {
      actionDate,
      actionRestriction,
      driftClass,
      existingAction,
      expenses,
      farmId,
      formErrors: { targetCrop: invalidCatchCropError },
      formName,
      handleSubmit,
      initParcelIds,
      initZoneIds,
      isCatchCrop,
      isEditing,
      isExisting,
      isPristine,
      isSubmitting,
      ngGoToActions,
      onCancel,
      onEditingStart,
      onFieldManualChange,
      onReset,
      parcels,
      parcelsArea,
      splitActionHandler,
      targetCrop,
    } = this.props;

    return (
      <ActionDetailContext.Consumer>
        {contextValue => (
          <div>
            <ActionFormHeader
              farmId={farmId}
              handleStartIsSplitting={contextValue?.handleStartIsSplitting}
              isDisabled={isEditing}
              isDraft={!!existingAction?.isDraft}
              isExisting={isExisting}
              ngGoToActions={ngGoToActions}
              onClick={onEditingStart}
              title={`${startCase(formName).replace(/\s/g, '')}.title`}
        />
            <Grid alignItems="center" container justifyContent="center" spacing={0}>
              <Grid item lg={8} md={10} xs={11}>
                <form onSubmit={handleSubmit}>
                  <Grid container justifyContent="center" spacing={5}>
                    <Grid item xs={12}>
                      <FieldArray
                        actionDate={actionDate}
                        checkedItems={contextValue?.checkedItems}
                        component={ActionParcelsControl}
                        driftClass={driftClass}
                        expenses={expenses}
                        farmId={farmId}
                        formName={FORM_NAME}
                        handleCheckboxChange={contextValue?.handleCheckboxChange}
                        initParcelIds={initParcelIds}
                        initZoneIds={initZoneIds}
                        isDraft={!!existingAction?.isDraft}
                        isEditing={isEditing}
                        isSplitting={contextValue?.isSplitting}
                        name="parcels"
                        numberOfCheckedItems={contextValue?.numberOfCheckedItems}
                        parcelsArea={parcelsArea}
                        splitActionHandler={splitActionHandler}
                        targetCrop={targetCrop}
                  />
                    </Grid>
                    {/*  */}
                    <Grid item md={5} sm={7} xl={4} xs={10}>
                      <ActionDate disabled={!isEditing} />
                      <ActionTargetCrop disabled={!isEditing} onChange={() => onFieldManualChange('targetCrop')} />
                      {!invalidCatchCropError && parcels.length > 0 && (
                      <div>
                        {
                          <CfCheckboxLabel
                            style={isEditing ? { color: 'inherit' } : {}}
                            control={
                              <Field
                                component={CfCheckbox}
                                disabled={isCatchCrop ? true : !isEditing}
                                name={isCatchCrop ? 'isCatchCrop' : 'isStrawDecay'}
                            />
                          }
                            label={
                              <FormattedMessage
                                id={`Eph.${isCatchCrop ? 'catchCropApplication' : 'strawDecayApplication'}`}
                            />
                          }
                        />
                      }
                      </div>
                      )}
                      <ActionNote disabled={!isEditing} />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldArray
                        component={EphExpensesControl}
                        driftClass={driftClass}
                        expenses={expenses}
                        formName={formName}
                        isEditing={isEditing}
                        name="expenses"
                        parcels={parcels}
                        parcelsArea={parcelsArea}
                        targetCrop={targetCrop}
                        SelectorProps={{
                          component: ChemFertSelector,
                          placeholder: 'ChemFertSelector.placeholder',
                          fertAdditionalProps: {
                            incorporationDay: 'NO',
                            n: 0,
                            p2o5: 0,
                            k2o: 0,
                            doseUnit: defaultUnit,
                            dosageUnit: defaultUnit,
                          },
                          chemAdditionalProps: {
                            pests: [],
                            driftClass: 'DRIFT_NONE',
                            doseUnit: defaultUnit,
                            dosageUnit: defaultUnit,
                          },
                        }}
                  />
                    </Grid>
                    <ActionRestrictionsInfo validationDetails={actionRestriction} />
                  </Grid>
                </form>
              </Grid>
            </Grid>
            {contextValue?.isSplitting &&
            <ActionButtons
              isDisabled={false}
              onCancel={contextValue?.handleCancelIsSplitting}
              onConfirm={contextValue?.splitActionHandler}
            />
            }
            {isEditing && (
            <ActionFormButtons
              formName={formName}
              isExisting={isExisting}
              isPristine={isPristine}
              isSubmitting={isSubmitting}
              onCancel={onCancel}
              onReset={onReset}
              withExport={false}
          />
            )}
          </div>
        )}
      </ActionDetailContext.Consumer>
    );
  }
}

Eph.propTypes = {
  formErrors: PropTypes.object.isRequired,
  targetCrop: PropTypes.object,
  actionDate: PropTypes.object,
  actionRestriction: PropTypes.object.isRequired,
  existingAction: PropTypes.object,
  isEditing: PropTypes.bool.isRequired,
  parcelsArea: PropTypes.number.isRequired,
  formName: PropTypes.string.isRequired,
  isCatchCrop: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  onEditingStart: PropTypes.func.isRequired,
  expenses: PropTypes.array,
  parcels: PropTypes.array,
  driftClass: PropTypes.string,
  initParcelIds: PropTypes.array,
  initZoneIds: PropTypes.array,
  farmId: PropTypes.string.isRequired,
  isExisting: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isPristine: PropTypes.bool.isRequired,
  onFieldManualChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  // eslint-disable-next-line
  fieldsManuallyChanged: PropTypes.object.isRequired,
  ngGoToActions: PropTypes.func.isRequired,
  splitActionHandler: PropTypes.func,
};

Eph.defaultProps = {
  existingAction: null,
  initParcelIds: [],
  initZoneIds: [],
  targetCrop: null,
  parcels: [],
  expenses: [],
  driftClass: '',
  actionDate: {},
  isCatchCrop: false,
};

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = state => ({
  actionDate: selector(state, 'actionDate'),
  parcels: selector(state, 'parcels'),
  expenses: selector(state, 'expenses'),
  targetCrop: selector(state, 'targetCrop'),
  isCatchCrop: selector(state, 'isCatchCrop'),
  parcelsArea: getParcelsArea(FORM_NAME, state),
  driftClass: getDriftClass(FORM_NAME, state),
  actionRestriction: getActionRestrictions(state),
});

export default compose(
  actionForm({
    formName: FORM_NAME,
    validate,
    warn,
    mapRequestBodyEphActionTo,
    initialValues: getInitialValues(),
  }),
  connect(mapStateToProps),
)(Eph);
