import React, { FC, useState, useMemo } from 'react';

import { Theme } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import CfTableWrapper from '../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableHead from '../../../shared/containers/CfTableHead/CfTableHead';
import { getColDesc } from '../../../telematics/helpers';

import AsAppliedTaskRow from './AsAppliedTaskRow';

import { TaskDataListTaskTo } from '../../../shared/api/satellite/satellite.types';

interface Props {
  data: TaskDataListTaskTo[]
  farmId: string;
  opened: boolean;
  taskDataId: number;
}

const columns = {
  name: getColDesc(
    false,
    <FormattedMessage id="PrecisionFarming.asApplied.tasks.column.name" />,
    { paddingLeft: 21 },
  ),
  dateFrom: getColDesc(
    false,
    <FormattedMessage id="PrecisionFarming.asApplied.tasks.column.dateFrom" />,
  ),
  dateTo: getColDesc(
    false,
    <span>
      <FormattedMessage id="PrecisionFarming.asApplied.tasks.column.dateTo" />
    </span>,
  ),
  duration: getColDesc(
    false,
    <span>
      <FormattedMessage id="PrecisionFarming.asApplied.tasks.column.duration" />
    </span>,
  ),
  actions: getColDesc(
    false,
    <span />,
    { width: '140px' },
  ),
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: (opened) => opened && theme.palette.grey[100],
  },
  paper: {
    boxShadow: 'none',
    borderBotton: 'none',
  },
}));

const AsAppliedTaskList: FC<Props> = ({ data, farmId, opened, taskDataId }) => {
  const [orderBy, setOrderBy] = useState('');
  const [ascOrdered, setAscOrdered] = useState(true);

  const classes = useStyles(opened);

  const handleSortClick = (colId: string) => () => {
    if (colId === orderBy) {
      setAscOrdered(prevState => !prevState);
    } else {
      setOrderBy(colId);
      setAscOrdered(true);
    }
  };

  const sortedData = useMemo(() => {
    if (!orderBy) return data;
    return _.orderBy(data, orderBy, ascOrdered ? 'asc' : 'desc');
  }, [data, ascOrdered, orderBy]);

  return (
    <CfTableWrapper
      customPaperStyles={classes.paper}
      customTableStyles={classes.container}
      testId={'aggregated-rides-detail-table'}
    >
      <CfTableHead
        columns={columns}
        customSortHandler={handleSortClick}
        items={sortedData}
        order={ascOrdered ? 'asc' : 'desc'}
        orderBy={orderBy}
      />
      <TableBody className={classes.container}>
        {sortedData.map(drive =>
          <AsAppliedTaskRow data={drive} farmId={farmId} key={drive.id} taskDataId={taskDataId} />)}
      </TableBody>
    </CfTableWrapper>
  );
};

export default AsAppliedTaskList;
