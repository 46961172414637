import * as types from './transactions.constants';

export default () => (
  state = {
    isFetching: false,
    error: {},
    items: [],
    totalCount: 0,
  },
  action,
) => {
  switch (action.type) {
    case types.GET_TRANSACTIONS:
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    case types.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: {},
        items: action.payload,
        totalCount: Number(action.meta.headers.get('X-Total-Count')),
      };
    case types.GET_TRANSACTIONS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        totalCount: 0,
      };
    case types.RESET_TRANSACTIONS:
      return {
        ...state,
        items: [],
        error: {},
      };

    default:
      return state;
  }
};
