import { createSelector } from 'reselect';

import ErrorService from '../../../services/Error.service';

import { TelematicsState } from '../../../../reducers/telematics.reducer.types';

export const getItems = (state: TelematicsState) => state.api.drives.items;
export const getTotalCount = (state: TelematicsState) => state.api.drives.totalCount;
export const getIsFetchingDrives = (state: TelematicsState) => state.api.drives.isFetching;
export const getErrorDrives = (state: TelematicsState) => ErrorService.getResErrorDto(state.api.drives.error);

export const getOperations = (state: TelematicsState) => state.api.drives.operations;
export const getIsFetchingOperations = (state: TelematicsState) => state.api.drives.isFetchingOperations;

export const getProductionOperations = (state: TelematicsState) => state.api.drives.productionOperations;
export const getIsFetchingProductionOperations = (state: TelematicsState) =>
  state.api.drives.isFetchingProductionOperations;

export const getDrivers = (state: TelematicsState) => state.api.drives.drivers;
export const getIsFetchingDrivers = (state: TelematicsState) => state.api.drives.isFetchingDrivers;

export const getMachines = (state: TelematicsState) => state.api.drives.machines;
export const getIsFetchingMachines = (state: TelematicsState) => state.api.drives.isFetchingMachines;

export const getEquipments = (state: TelematicsState) => state.api.drives.equipments;
export const getIsFetchingEquipments = (state: TelematicsState) => state.api.drives.isFetchingEquipments;

export const getDriveSubjects = (state: TelematicsState) => state.api.drives.parcelSubject;
export const getIsFetchingDriveSubjects = (state: TelematicsState) => state.api.drives.isFetchingParcelSubject;

export const getDriveDetail = (state: TelematicsState) => state.api.drives.driveDetail;
export const getIsFetchingDriveDetail = (state: TelematicsState) => state.api.drives.isFetchingDriveDetail;

export const isExportingData = (state: TelematicsState) => state.api.drives.isExportingData;

export const getExportError = (state: TelematicsState) =>
  ErrorService.getResErrorDto(state.api.drives.exportError);

type OwnProps= {
  driveDetail?: {
    gpsUnit?: string;
  }
}
export const getMachineByGpsUnit = createSelector(
  getMachines,
  (_: never, props: OwnProps) => props?.driveDetail?.gpsUnit,
  (machines, gpsUnit) => {
    if (!gpsUnit) return;
    return machines.find(machine => machine.gpsUnit === gpsUnit);
  });
