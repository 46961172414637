import { AnyAction } from 'redux';

import * as types from './drivesImportHistory.constants';

import { DrivesImportHistoryStateApi } from '../../../../telematics/reducer/drivesImportHistory.types';

export const NAMESPACE = 'drives-import-history-api';

const initialState: DrivesImportHistoryStateApi = {
  items: [],
  isFetching: false,
  stateIsFetching: false,
  error: undefined,
  totalCount: 0,
  lastImportStateError: undefined,
  lastImportState: undefined,
};

export default () => (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.GET_IMPORT_HISTORY_REQUEST:
      return { ...state,
        isFetching: true,
        error: undefined,
      };
    case types.GET_IMPORT_HISTORY_SUCCESS:
      return { ...state,
        isFetching: false,
        items: action.payload,
        totalCount: Number(action.meta.headers.get('X-Total-Count')),
      };
    case types.GET_IMPORT_HISTORY_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        totalCount: 0,
      };
    case types.RESET_IMPORT_HISTORY:
      return { ...state,
        isFetching: false,
        items: [],
        error: {},
        totalCount: 0,
      };

    case types.GET_IMPORT_HISTORY_STATE_REQUEST:
      return { ...state,
        stateIsFetching: true,
        lastImportStateError: undefined,
      };
    case types.GET_IMPORT_HISTORY_STATE_SUCCESS:
      return { ...state,
        stateIsFetching: false,
        lastImportState: action.payload,
      };
    case types.GET_IMPORT_HISTORY_STATE_ERROR:
      return { ...state,
        stateIsFetching: false,
        lastImportStateError: action.payload,
      };

    default:
      return state;
  }
};
