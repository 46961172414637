import React, { FC } from 'react';

import { FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import { FieldProps } from 'formik';

interface Props extends FieldProps {
  label: React.ReactElement;
}

const useStyles = makeStyles({
  label: {
    fontSize: 12,
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.6)',
    marginBottom: '-6px',
  },
  switch: {
    marginLeft: '-12px',
  },
  root: {
    alignItems: 'flex-start',
  },
});

const CfFormikSwitch: FC<Props> = (props) => {
  const { field, form, label } = props;
  const classes = useStyles();
  return (
    <FormControlLabel
      classes={{ label: classes.label, root: classes.root }}
      label={label}
      labelPlacement="top"
      control={
        <Switch
          checked={field.value}
          className={classes.switch}
          onChange={() => form.setFieldValue(field.name, !field.value)} />}
      />
  );
};

export default CfFormikSwitch;
