import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import * as sortOrder from '../../../constants/sortOrder.constants';
import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './transactions.constants';

export const getTransactions = (storeId, params) => {
  const p = {
    page: params.page + 1,
    'per-page': params.rowsPerPage,
    unit: params.unitId,
    'sort-col': 'date',
    'sort-dir': sortOrder.ASC,
  };

  if (params.from) {
    p.from = params.from;
  }

  if (params.to) {
    p.to = params.to;
  }

  return {
    [RSAA]: {
      endpoint: `items/${storeId}/transactions?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.STORES,
      types: [types.GET_TRANSACTIONS, types.GET_TRANSACTIONS_SUCCESS, types.GET_TRANSACTIONS_ERROR],
    },
  };
};

export const createTransaction = dto => ({
  [RSAA]: {
    endpoint: 'transactions?',
    method: methods.POST,
    module: modules.STORES,
    body: JSON.stringify(dto),
    types: [types.CREATE_TRANSACTION, types.CREATE_TRANSACTION_SUCCESS, types.CREATE_TRANSACTION_ERROR],
  },
});

export const updateTransaction = dto => {
  const body = {
    ...dto,
  };
  delete body.id;
  delete body.material;
  return {
    [RSAA]: {
      endpoint: `transactions/${dto.id}?`,
      method: methods.PATCH,
      module: modules.STORES,
      body: JSON.stringify(body),
      types: [types.UPDATE_TRANSACTION, types.UPDATE_TRANSACTION_SUCCESS, types.UPDATE_TRANSACTION_ERROR],
    },
  };
};

export const deleteTransaction = itemId => ({
  [RSAA]: {
    endpoint: `transactions/${itemId}?`,
    method: methods.DELETE,
    module: modules.STORES,
    types: [types.DELETE_TRANSACTION, types.DELETE_TRANSACTION_SUCCESS, types.DELETE_TRANSACTION_ERROR],
  },
});

export const resetTransactions = () => ({
  type: types.RESET_TRANSACTIONS,
});
