import React, { FC } from 'react';

import Tooltip from '@mui/material/Tooltip';
import { FormattedMessage } from 'react-intl';

import WarningIconDisabled from '../../../assets/img/icons/telematics/warning-icon-disabled.svg';
import WarningIcon from '../../../assets/img/icons/telematics/warning-icon.svg';

interface Props {
  classes?: string;
  customTooltipTitle?: React.ReactNode ;
  isPostponedRide?: boolean;
  tooltipId?: string;
}

const TelematicsPartialDriveWarning: FC<Props> = ({
  classes,
  customTooltipTitle,
  isPostponedRide = false,
  tooltipId = 'TelematicsList.partialDrive',
}) => (
  <span>
    {isPostponedRide ? (
      <span className={classes}>
        <img alt="warning icon" src={WarningIconDisabled} />
      </span>
    ) : (
      <Tooltip title={customTooltipTitle ?? <FormattedMessage id={tooltipId} />}>
        <span className={classes}>
          <img alt="warning icon" src={WarningIcon} />
        </span>
      </Tooltip>
    )}
  </span>
);

export default TelematicsPartialDriveWarning;
