import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../../api.constants';
import * as types from './eagri.constants';

import { RsaaTypes } from '../../../api.types';

export const getCropUsageApi = (legislativeCode?: number) => ({
  [RSAA]: {
    endpoint: `catalogues/eagri/crop-use?${legislativeCode ? `crop-legislative-code=${legislativeCode}&` : ''}`,
    method: methods.GET,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_CROP_USAGE_REQUEST,
      types.GET_CROP_USAGE_SUCCESS,
      types.GET_CROP_USAGE_ERROR,
    ] as RsaaTypes,
  },
});

export const resetCropUsageApi = () => ({
  type: types.RESET_CROP_USAGE,
});

export const getHarvestProductsApi = (legislativeCode?: number) => ({
  [RSAA]: {
    endpoint: `catalogues/eagri/harvest-products?${legislativeCode ? `crop-legislative-code=${legislativeCode}&` : ''}`,
    method: methods.GET,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_HARVEST_PRODUCTS_REQUEST,
      types.GET_HARVEST_PRODUCTS_SUCCESS,
      types.GET_HARVEST_PRODUCTS_ERROR,
    ] as RsaaTypes,
  },
});

export const resetHarvestProductsApi = () => ({
  type: types.RESET_HARVEST_PRODUCTS,
});

export const getFertilizerNitrogenCategoriesApi = (language?: string) => ({
  [RSAA]: {
    endpoint: `catalogues/eagri/fertilizer-nitrogen-categories?${language ? `language=${language}&` : ''}`,
    method: methods.GET,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_FERTILIZER_NITROGEN_CATEGORIES_REQUEST,
      types.GET_FERTILIZER_NITROGEN_CATEGORIES_SUCCESS,
      types.GET_FERTILIZER_NITROGEN_CATEGORIES_ERROR,
    ] as RsaaTypes,
  },
});

export const resetFertilizerNitrogenCategoriesApi = () => ({
  type: types.RESET_FERTILIZER_NITROGEN_CATEGORIES,
});

export const getFertilizerRegistrationTypeApi = (language?: string) => ({
  [RSAA]: {
    endpoint: `catalogues/eagri/fertilizer-registration-type?${language ? `language=${language}&` : ''}`,
    method: methods.GET,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_FERTILIZER_REGISTRATION_TYPE_REQUEST,
      types.GET_FERTILIZER_REGISTRATION_TYPE_SUCCESS,
      types.GET_FERTILIZER_REGISTRATION_TYPE_ERROR,
    ] as RsaaTypes,
  },
});

export const resetFertilizerRegistrationTypeApi = () => ({
  type: types.RESET_FERTILIZER_REGISTRATION_TYPE,
});

export const getFertilizerKindsApi = () => ({
  [RSAA]: {
    endpoint: 'catalogues/eagri/fertilizer-kind?',
    method: methods.GET,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_FERTILIZER_KIND_REQUEST,
      types.GET_FERTILIZER_KIND_SUCCESS,
      types.GET_FERTILIZER_KIND_ERROR,
    ] as RsaaTypes,
  },
});

export const resetFertilizerKindApi = () => ({
  type: types.RESET_FERTILIZER_KIND,
});
