import OlFeature from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import { IRRIGATION_URLS } from '../irrigation.constants';

import Layers from '../../core/map/services/Layers.service';
import MapService from '../../core/map/services/Map.service';
import Style from '../../core/map/services/Style.service';
import { transformOptions, transformWithValidation } from '../../shared/misc/map.helpers';

import IrrigationStyleService from './IrrigationMapStyles.service';

export default class IrrigationMapService {
  constructor(
    config,
    farm,
    setHoveredId,
    history,
    setEnlargedVariant,
    setDefaultExtent,
  ) {
    this.config = config;
    this.customer = farm.customer;
    this.farm = farm;
    this.history = history;
    this.setEnlargedVariant = setEnlargedVariant;
    this.setDefaultExtent = setDefaultExtent;
    this.setHoveredId = setHoveredId;
    this.isSelecting = false;
    this.isAreasList = false;

    this.map = new MapService('irrigation-map', farm.id, farm.boundingBox, transformOptions);
    this.pointsLayer = this.setPointsLayer();
    this.layers = new Layers(this.map.getMap(), this.config.api, this.farm.id, this.map.getFarmExtent());
    this.setClickEvent();
    this.setHoverEvent();
  }

  getMap() {
    return this.map;
  }

  getLayers() {
    return this.layers;
  }

  setLayersWithConfig(newLayersConfig, storeInitialLayers) {
    this.layers.setInitialLayers(newLayersConfig, storeInitialLayers);
  }

  getStyle() {
    return new Style(this.layers.getParcelLayer(), this.layers.getParcelLabelLayer(), this.customer.countryCode);
  }

  setIsSelecting(bool) {
    this.isSelecting = bool;
  }

  setPointsToLayer(points, isAreasList) {
    this.isAreasList = isAreasList;

    const transformedPoints = points.map(p => {
      const [t] = transformWithValidation(p.geometry.coordinates, 'EPSG:4326', 'EPSG:3857', true);
      const geom = new Point(t);
      const feature = new OlFeature({
        id: p.id,
        areaId: p.areaId,
        geometry: geom,
        name: isAreasList ? p.areaName : p.name,
        status: p.status,
      });
      feature.setId(p.id);
      return feature;
    });

    const pointsSource = this.pointsLayer.getSource();
    pointsSource.clear(true);
    pointsSource.addFeatures(transformedPoints);

    const pointsExtent = this.map.getExtentFromLayer(this.pointsLayer);
    if (pointsExtent?.length) {
      this.setDefaultExtent(pointsExtent);
    }
  }

  setPointsLayer() {
    return new VectorLayer({
      source: new VectorSource(),
      style: IrrigationStyleService.setDefaultIrrigationStyle(),
    });
  }

  getClickDestination = (featureId, areaId, isAreasList) => {
    const deviceDetailPath = `/farm/${this.farm.id}/${IRRIGATION_URLS.DEVICE}/${featureId}`;
    const areaDetailPath = `/farm/${this.farm.id}/${IRRIGATION_URLS.AREA}/${areaId}/plan`;
    if (isAreasList && areaId) {
      return areaDetailPath;
    }
    return deviceDetailPath;
  };

  setHoverEvent = () => {
    const map = this.map.getMap();
    let prevHovered;

    map.on('pointermove', evt => {
      if (this.isSelecting) return;
      const featuresAtPixel = map.getFeaturesAtPixel(evt.pixel, { layerFilter: (layer) => layer === this.pointsLayer });
      if (featuresAtPixel.length) {
        const featureId = featuresAtPixel[0].get('id');
        if (prevHovered !== featureId) {
          prevHovered = featureId;
          this.setHoveredId(featureId);
        }
      } else if (prevHovered) {
        this.setHoveredId(null);
        prevHovered = null;
      }
    });
  };

  setClickEvent = () => {
    const map = this.map.getMap();
    map.on('singleclick', evt => {
      if (this.isSelecting) return;
      const featuresAtPixel = map.getFeaturesAtPixel(evt.pixel, { layerFilter: (layer) => layer === this.pointsLayer });
      if (featuresAtPixel.length) {
        const featureId = featuresAtPixel[0].get('id');
        const areaId = featuresAtPixel[0].get('areaId');
        this.history.push(this.getClickDestination(featureId, areaId, this.isAreasList));
        this.setEnlargedVariant(false);
      }
    });
  };
}
