import React, { FC, useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getFertilizerRegistrationType, getIsFetchingFertilizerRegistrationType } from '../../../../../shared/api/agroevidence/catalogues/eagri/eagri.selectors';

import { getFertilizerRegistrationTypeApi, resetFertilizerRegistrationTypeApi } from '../../../../../shared/api/agroevidence/catalogues/eagri/eagri.api';
import CfAutocomplete from '../../../../../shared/components/common/CfAutocomplete/CfAutocomplete';
import { Thunk } from '../../../../../types';

import { CataloguesState } from '../../../../../reducers/catalogues.reducer.types';
import { EagriFertilizerRegistrationType, EagriFertilizerRegistrationTypeTo } from '../../../../../shared/api/agroevidence/agroevidence.types';

interface Props {
    defaultValues: EagriFertilizerRegistrationType | null,
    disabled?: boolean,
    error?: boolean;
    getFertilizerRegistrationTypeApi: (language?: string) => void,
    isFetchingRegistrationType: boolean,
    langId: string,
    onChange: (items: EagriFertilizerRegistrationTypeTo) => void,
    registrationType: EagriFertilizerRegistrationTypeTo[],
    resetFertilizerRegistrationTypeApi: () => void
}

const RegistrationTypeSelector: FC<Props> = ({
  defaultValues,
  disabled = false,
  error = false,
  getFertilizerRegistrationTypeApi,
  isFetchingRegistrationType,
  langId,
  onChange,
  registrationType,
  resetFertilizerRegistrationTypeApi,
}) => {
  useEffect(() => {
    getFertilizerRegistrationTypeApi(langId);

    return () => {
      resetFertilizerRegistrationTypeApi();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      disabled={disabled}
      error={error}
      helperText={error ? <FormattedMessage id="validation.required" /> : ''}
      id="registration-type-selector"
      isFetching={isFetchingRegistrationType}
      isMultiple={false}
      label={<FormattedMessage id="Catalogues.fertilizers.detail.registrationType" />}
      loadOptions={getFertilizerRegistrationTypeApi}
      onChange={onChange}
      suggestions={registrationType}
      testId="registration-type-selector"
    />
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  registrationType: getFertilizerRegistrationType(state),
  isFetchingRegistrationType: getIsFetchingFertilizerRegistrationType(state),
});

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      getFertilizerRegistrationTypeApi,
      resetFertilizerRegistrationTypeApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationTypeSelector);
