import queryString from 'query-string';
import { RSAA, RSAAAction } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './aggregations.constants';

import { TelematicsState } from '../../../../reducers/telematics.reducer.types';
import { ExportPayload, RsaaMethods, RsaaTypes } from '../../api.types';
import { DriveCreateTo, DriveOverlapRequestTo, DriveRecalculateTo, DriveSeparationIntervalsTo, DriveSeparationTimeTo, DriveUpdateTo, HandworkCreateTo, KeyType, State, WinfasUpdateTo } from '../telematics.types';
import { GetAggregatedDriversParams, ExportAggregatedDrivesApiParams, WinfasClientParams } from './aggregations.types';

export const getDriverAggregationsApi = (params: GetAggregatedDriversParams, refetch = false, skipReducer = false) => {
  let rsaaTypes: RsaaTypes = [
    { type: types.GET_DRIVER_AGGREGATIONS_REQUEST,
      payload: () => ({ refetch }),
    },
    types.GET_DRIVER_AGGREGATIONS_SUCCESS,
    types.GET_DRIVER_AGGREGATIONS_ERROR,
  ];
  // for direct API call when saving data is not desired
  if (skipReducer) {
    rsaaTypes = [
      types.GET_DRIVER_AGGREGATIONS_REQUEST_NOREDUCER,
      types.GET_DRIVER_AGGREGATIONS_SUCCESS_NOREDUCER,
      types.GET_DRIVER_AGGREGATIONS_ERROR_NOREDUCER,
    ];
  }
  return ({
    [RSAA]: {
      endpoint: `logbook/aggregated?${queryString.stringify(params)}&`,
      method: methods.GET as RsaaMethods,
      module: modules.TELEMATICS,
      types: rsaaTypes,
    },
  });
};

export const exportAggregatedDrivesApi = (params: ExportAggregatedDrivesApiParams) => ({
  [RSAA]: {
    endpoint: `logbook/export/aggregated/excel?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.EXPORT_AGGREGATED_DRIVES_REQUEST,
      {
        type: types.EXPORT_AGGREGATED_DRIVES_SUCCESS,
        payload: (
          _action: RSAAAction,
          _state: TelematicsState,
          res: Response,
        ) => ({
          body: res.blob(),
          type: res.headers.get('Content-Type'),
          disposition: res.headers.get('Content-Disposition'),
        } as ExportPayload),
      },
      types.EXPORT_AGGREGATED_DRIVES_ERROR,
    ] as RsaaTypes,
  },
});

export const editAggregatedDriveApi = (id: number, data: DriveUpdateTo) => ({
  [RSAA]: {
    endpoint: `logbook/aggregated/${id}?`,
    method: methods.POST as RsaaMethods,
    module: modules.TELEMATICS,
    body: JSON.stringify(data),
    types: [
      types.EDIT_AGGREGATED_DRIVE_REQUEST,
      types.EDIT_AGGREGATED_DRIVE_SUCCESS,
      types.EDIT_AGGREGATED_DRIVE_ERROR,
    ] as RsaaTypes,
  },
});

export const deleteAggregatedDriveApi = (id: number) => ({
  [RSAA]: {
    endpoint: `logbook/aggregated/${id}?`,
    method: methods.DELETE as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.DELETE_AGGREGATED_DRIVE_REQUEST,
      types.DELETE_AGGREGATED_DRIVE_SUCCESS,
      types.DELETE_AGGREGATED_DRIVE_ERROR,
    ] as RsaaTypes,
  },
});

export const createAggregatedDriveApi = (data: DriveCreateTo) => ({
  [RSAA]: {
    endpoint: 'logbook/aggregated?',
    method: methods.POST as RsaaMethods,
    module: modules.TELEMATICS,
    body: JSON.stringify(data),
    types: [
      types.CREATE_AGGREGATED_DRIVE_REQUEST,
      types.CREATE_AGGREGATED_DRIVE_SUCCESS,
      types.CREATE_AGGREGATED_DRIVE_ERROR,
    ] as RsaaTypes,
  },
});

export const createHandworkApi = (data: HandworkCreateTo) => ({
  [RSAA]: {
    endpoint: 'logbook/aggregated/handwork?',
    method: methods.POST as RsaaMethods,
    module: modules.TELEMATICS,
    body: JSON.stringify(data),
    types: [
      types.CREATE_HANDWORK_REQUEST,
      types.CREATE_HANDWORK_SUCCESS,
      types.CREATE_HANDWORK_ERROR,
    ] as RsaaTypes,
  },
});

export const getAggregatedOverlapsApi = (data: DriveOverlapRequestTo) => ({
  [RSAA]: {
    endpoint: 'logbook/aggregated/overlaps?',
    method: methods.POST as RsaaMethods,
    module: modules.TELEMATICS,
    body: JSON.stringify(data),
    types: [
      types.GET_AGG_OVERLAPS_REQUEST,
      types.GET_AGG_OVERLAPS_SUCCESS,
      types.GET_AGG_OVERLAPS_ERROR,
    ] as RsaaTypes,
  },
});

export const exportTosDataApi = () => ({
  [RSAA]: {
    endpoint: 'logbook/tos/excel?',
    method: methods.POST as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.EXPORT_TOS_DATA_REQUEST,
      {
        type: types.EXPORT_TOS_DATA_SUCCESS,
        payload: (
          _action: RSAAAction,
          _state: TelematicsState,
          res: Response,
        ) => ({
          body: res.blob(),
          type: res.headers.get('Content-Type'),
          disposition: res.headers.get('Content-Disposition'),
        } as ExportPayload),
      },
      types.EXPORT_TOS_DATA_ERROR,
    ] as RsaaTypes,
  },
});

export const getDriverAggregationDetailApi = (id: string) => ({
  [RSAA]: {
    endpoint: `logbook/aggregated/${id}?`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_AGGREGATED_DRIVE_REQUEST,
      types.GET_AGGREGATED_DRIVE_SUCCESS,
      types.GET_AGGREGATED_DRIVE_ERROR] as RsaaTypes,
  },
});

export const changeDrivesStateApi = (ids: string[], state: State) => ({
  [RSAA]: {
    endpoint: 'logbook/aggregated/state?',
    method: methods.POST as RsaaMethods,
    module: modules.TELEMATICS,
    body: JSON.stringify({ drive: ids, state }),
    types: [
      types.SET_DRIVES_STATE_REQUEST,
      types.SET_DRIVES_STATE_SUCCESS,
      types.SET_DRIVES_STATE_ERROR] as RsaaTypes,
  },
});

export const calculateAreaFromEquipment = (driveId: number, equipment: DriveRecalculateTo) => ({
  [RSAA]: {
    endpoint: `logbook/aggregated/${driveId}/area?`,
    method: methods.POST as RsaaMethods,
    body: JSON.stringify(equipment),
    module: modules.TELEMATICS,
    types: [
      types.CALCULATE_AREA_REQUEST,
      types.CALCULATE_AREA_SUCCESS,
      types.CALCULATE_AREA_ERROR,
    ] as RsaaTypes,
  },
});

export const sendToTosApi = () => ({
  [RSAA]: {
    endpoint: 'logbook/tos?',
    method: methods.POST as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.SEND_TO_TOS_REQUEST,
      types.SEND_TO_TOS_SUCCESS,
      types.SEND_TO_TOS_ERROR,
    ] as RsaaTypes,
  },
});

export const getTosStatusApi = () => ({
  [RSAA]: {
    endpoint: 'logbook/tos/status?',
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_TOS_STATUS_REQUEST,
      types.GET_TOS_STATUS_SUCCESS,
      types.GET_TOS_STATUS_ERROR,
    ] as RsaaTypes,
  },
});

export const resetDriverAggregationDetail = () => ({
  type: types.RESET_AGGREGATED_DRIVE,
});

export const resetApiError = () => ({
  type: types.RESET_API_ERROR,
});

export const resetAggregatedExportError = () => ({
  type: types.RESET_EXPORT_ERROR,
});

export const getAggregatedDriveKeysApi = (id: string) => ({
  [RSAA]: {
    endpoint: `logbook/aggregated/${id}/winfas?`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_AGGREGATED_DRIVE_KEYS_REQUEST,
      types.GET_AGGREGATED_DRIVE_KEYS_SUCCESS,
      types.GET_AGGREGATED_DRIVE_KEYS_ERROR,
    ] as RsaaTypes,
  },
});

export const separateDriveApi = (id: number, payload: DriveSeparationTimeTo) => ({
  [RSAA]: {
    endpoint: `logbook/aggregated/${id}/separation/time?`,
    method: methods.POST as RsaaMethods,
    module: modules.TELEMATICS,
    body: JSON.stringify(payload),
    types: [
      types.SEPARATE_DRIVE_REQUEST,
      types.SEPARATE_DRIVE_SUCCESS,
      types.SEPARATE_DRIVE_ERROR,
    ] as RsaaTypes,
  },
});

export const separateIntervalApi = (id: number, payload: DriveSeparationIntervalsTo) => ({
  [RSAA]: {
    endpoint: `logbook/aggregated/${id}/separation/interval?`,
    method: methods.POST as RsaaMethods,
    module: modules.TELEMATICS,
    body: JSON.stringify(payload),
    types: [
      types.SEPARATE_INTERVAL_REQUEST,
      types.SEPARATE_INTERVAL_SUCCESS,
      types.SEPARATE_INTERVAL_ERROR,
    ] as RsaaTypes,
  },
});

export const getDriveOverlapsApi = (id: number) => ({
  [RSAA]: {
    endpoint: `logbook/aggregated/${id}/overlaps?${queryString.stringify({ id })}&`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_DRIVE_OVERLAPS_REQUEST,
      types.GET_DRIVE_OVERLAPS_SUCCESS,
      types.GET_DRIVE_OVERLAPS_ERROR,
    ] as RsaaTypes,
  },
});

export const editAggregatedDriveKeysApi = (id: string, payload: WinfasUpdateTo) => ({
  [RSAA]: {
    endpoint: `logbook/aggregated/${id}/winfas?`,
    method: methods.PUT as RsaaMethods,
    module: modules.TELEMATICS,
    body: JSON.stringify(payload),
    types: [
      types.EDIT_AGGREGATED_DRIVE_KEYS_REQUEST,
      types.EDIT_AGGREGATED_DRIVE_KEYS_SUCCESS,
      types.EDIT_AGGREGATED_DRIVE_KEYS_ERROR,
    ] as RsaaTypes,
  },
});

export type EditBatchDriverType = {
  drive: string[],
  driver: number
};

export const editBatchDriverApi = (params: EditBatchDriverType) => ({
  [RSAA]: {
    endpoint: 'logbook/aggregated/batch/driver?',
    method: methods.POST as RsaaMethods,
    body: JSON.stringify(params),
    module: modules.TELEMATICS,
    types: [
      types.EDIT_BATCH_DRIVER_REQUEST,
      types.EDIT_BATCH_DRIVER_SUCCESS,
      types.EDIT_BATCH_DRIVER_ERROR,
    ] as RsaaTypes,
  },
});

export type EditBatchEquipmentType = {
  drive: string[],
  equipment: number
};

export const editBatchEquipmentApi = (params: EditBatchEquipmentType) => ({
  [RSAA]: {
    endpoint: 'logbook/aggregated/batch/equipment?',
    method: methods.POST as RsaaMethods,
    body: JSON.stringify(params),
    module: modules.TELEMATICS,
    types: [
      types.EDIT_BATCH_EQUIPMENT_REQUEST,
      types.EDIT_BATCH_EQUIPMENT_SUCCESS,
      types.EDIT_BATCH_EQUIPMENT_ERROR,
    ] as RsaaTypes,
  },
});

export type EditBatchProductionOperationType = {
  drive: string[],
  productionOperation: number
};

export const editBatchProductionOperationApi = (params: EditBatchProductionOperationType) => ({
  [RSAA]: {
    endpoint: 'logbook/aggregated/batch/operation?',
    method: methods.POST as RsaaMethods,
    body: JSON.stringify(params),
    module: modules.TELEMATICS,
    types: [
      types.EDIT_BATCH_PRODUCTION_OPERATION_REQUEST,
      types.EDIT_BATCH_PRODUCTION_OPERATION_SUCCESS,
      types.EDIT_BATCH_PRODUCTION_OPERATION_ERROR,
    ] as RsaaTypes,
  },
});

export type EditBatchBonusType = {
  bonus: number,
  drive: string[]
};

export const editBatchBonusApi = (params: EditBatchBonusType) => ({
  [RSAA]: {
    endpoint: 'logbook/aggregated/batch/bonus?',
    method: methods.POST as RsaaMethods,
    body: JSON.stringify(params),
    module: modules.TELEMATICS,
    types: [
      types.EDIT_BATCH_BONUS_REQUEST,
      types.EDIT_BATCH_BONUS_SUCCESS,
      types.EDIT_BATCH_BONUS_ERROR,
    ] as RsaaTypes,
  },
});

export type EditBatchSubjectType = {
  drive: string[],
  subject: string
};

export const editBatchSubjectApi = (params: EditBatchSubjectType) => ({
  [RSAA]: {
    endpoint: 'logbook/aggregated/batch/subject?',
    method: methods.POST as RsaaMethods,
    body: JSON.stringify(params),
    module: modules.TELEMATICS,
    types: [
      types.EDIT_BATCH_SUBJECT_REQUEST,
      types.EDIT_BATCH_SUBJECT_SUCCESS,
      types.EDIT_BATCH_SUBJECT_ERROR,
    ] as RsaaTypes,
  },
});

export type EditBatchCustomerKeyType = {
  customerKeyId: number,
  drive: string[]
};

export const editBatchCustomerKeyApi = (params: EditBatchCustomerKeyType) => ({
  [RSAA]: {
    endpoint: 'logbook/aggregated/batch/key/customer?',
    method: methods.POST as RsaaMethods,
    body: JSON.stringify(params),
    module: modules.TELEMATICS,
    types: [
      types.EDIT_BATCH_CUSTOMER_KEY_REQUEST,
      types.EDIT_BATCH_CUSTOMER_KEY_SUCCESS,
      types.EDIT_BATCH_CUSTOMER_KEY_ERROR,
    ] as RsaaTypes,
  },
});

export const getKeyCombinationsForDriveApi = (key: KeyType) => ({
  [RSAA]: {
    endpoint: `winfas/key/combinations?type=${key}&`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_KEY_COMBINATIONS_REQUEST,
      { type: types.GET_KEY_COMBINATIONS_SUCCESS,
        payload: (_action: RSAAAction, _state: TelematicsState, res: Response) => res.json().then(data => ({
          key,
          data,
        })),
      },
      types.GET_KEY_COMBINATIONS_ERROR,
    ] as RsaaTypes,
  },
});

export const getWinfasClientCombinationsForDriveApi = (params: WinfasClientParams) => ({
  [RSAA]: {
    endpoint: `winfas/clients?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_WINFAS_CLIENT_COMBINATIONS_REQUEST,
      types.GET_WINFAS_CLIENT_COMBINATIONS_SUCCESS,
      types.GET_WINFAS_CLIENT_COMBINATIONS_ERROR,
    ] as RsaaTypes,
  },
});

export const getActionRelatedDrivesApi = (id: string) => ({
  [RSAA]: {
    endpoint: `logbook/action/${id}/drive?`,
    method: methods.GET as RsaaMethods,
    module: modules.TELEMATICS,
    types: [
      types.GET_ACTION_RELATED_DRIVES_REQUEST,
      types.GET_ACTION_RELATED_DRIVES_SUCCESS,
      types.GET_ACTION_RELATED_DRIVES_ERROR,
    ] as RsaaTypes,
  },
});
