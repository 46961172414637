import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './asAppliedTaskdata.constants';

import { RsaaMethods, RsaaTypes } from '../../api.types';
import { TaskDataImportResponseTo } from '../../satellite/satellite.types';

export interface GetTaskdataApiParams {
  page?: number;
  'per-page'?: number;
  'sort-dir'?: string;
}

export const getTaskdataApi = (params: GetTaskdataApiParams) => ({
  [RSAA]: {
    endpoint: `taskdata?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.SENTINEL,
    types: [
      types.GET_AS_APPLIED_TASKDATA_REQUEST,
      types.GET_AS_APPLIED_TASKDATA_SUCCESS,
      types.GET_AS_APPLIED_TASKDATA_ERROR,
    ] as RsaaTypes,
  },
});

export const loadTaskdataApi = (params: TaskDataImportResponseTo) => ({
  [RSAA]: {
    endpoint: 'taskdata?',
    method: methods.POST as RsaaMethods,
    module: modules.SENTINEL,
    body: JSON.stringify(params),
    types: [
      types.LOAD_AS_APPLIED_TASKDATA_REQUEST,
      types.LOAD_AS_APPLIED_TASKDATA_SUCCESS,
      types.LOAD_AS_APPLIED_TASKDATA_ERROR,
    ] as RsaaTypes,
  },
});

export type ImportFilesApiType = {
  files: File[];
}

export const importFilesApi = (params: ImportFilesApiType) => {
  const formData = new FormData();

  params.files.forEach((file) => {
    formData.append('file', file);
  });

  return {
    [RSAA]: {
      endpoint: 'taskdata?',
      method: methods.POST as RsaaMethods,
      module: modules.SENTINEL,
      noContentType: true,
      body: formData,
      types: [
        types.AS_APPLIED_IMPORT_FILES_REQUEST,
        types.AS_APPLIED_IMPORT_FILES_SUCCESS,
        types.AS_APPLIED_IMPORT_FILES_ERROR,
      ] as RsaaTypes,
    },
  };
};

export type ValidateFileApiType = {
  file: File;
}

export const validateFileApi = (params: ValidateFileApiType) => {
  const formData = new FormData();

  formData.append('file', params.file);

  return {
    [RSAA]: {
      endpoint: 'taskdata/validate?',
      method: methods.POST as RsaaMethods,
      module: modules.SENTINEL,
      noContentType: true,
      body: formData,
      types: [
        types.AS_APPLIED_VALIDATE_FILES_REQUEST,
        types.AS_APPLIED_VALIDATE_FILES_SUCCESS,
        types.AS_APPLIED_VALIDATE_FILES_ERROR,
      ] as RsaaTypes,
    },
  };
};

export const asAppliedDownloadFile = (id: string) => ({
  [RSAA]: {
    endpoint: `taskdata/${id}/download?`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.AS_APPLIED_DOWNLOAD_FILE_REQUEST,
      { type: types.AS_APPLIED_DOWNLOAD_FILE_SUCCESS,
        payload: (action: unknown, state: unknown, res: Response) => ({
          body: res.blob(),
          disposition: res.headers.get('Content-Disposition'),
          type: res.headers.get('Content-Type'),
        }),
      },
      types.AS_APPLIED_DOWNLOAD_FILE_ERROR,
    ],
  },
});
