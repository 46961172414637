import { defaults as defaultControls } from 'ol/control';
import { click as clickCondition, pointerMove as pointerMoveCondition } from 'ol/events/condition';
import { getCenter as getExtentCenter } from 'ol/extent';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import Point from 'ol/geom/Point';
import { Select } from 'ol/interaction';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import Cluster from 'ol/source/Cluster';
import VectorSource from 'ol/source/Vector';
import View from 'ol/View';

import * as sensorsStatus from '../../shared/constants/sensorsStatus.constants';

import { getZoomedExtent, transformWithValidation } from '../../shared/misc/map.helpers';
import LayersConfigService from '../../shared/services/LayersConfig.service';
import SensorsService from '../../shared/services/Sensors.service';
import layersConfig from '../misc/layers.config.json';

import SensorsMapStylesService from './SensorsMapStyles.service';

const SRID_ID_BBOX = 'EPSG:4326';
const SRID_ID_MAP = 'EPSG:3857';

export default class SensorsMapService {
  constructor(apiConfig, farmId, farmBbox, hoverCallback, clickCallback) {
    this.bingKey = apiConfig.bing_key;
    this.farmId = farmId;
    this.geoserverUrl = apiConfig.geoserverUrl;
    this.geoserverWorkspaceCore = apiConfig.geoserverWorkspaceCore;
    this.geoserverWorkspaceIot = apiConfig.geoserverWorkspaceIot;
    this.smStyles = new SensorsMapStylesService();

    // get farm BBOX in global srid;
    this.farmExtent = SensorsMapService.getFarmExtent(farmBbox);

    const { bingKey, farmExtent, geoserverUrl, geoserverWorkspaceCore } = this;
    const layersConfigService = new LayersConfigService(
      geoserverWorkspaceCore,
      geoserverUrl,
      farmId,
      farmExtent,
      bingKey,
    );
    this.baseMapLayers = layersConfig.map(layerConfig => {
      const layerStyle = layerConfig.type === 'vector_tile' ? this.smStyles.setParcelsStyle() : null;
      return layersConfigService.getMapLayerFromConfig(layerConfig, layerStyle);
    });
    this.nodeLocationsLayer = this.setNodeLocationsLayer();

    this.map = this.setMap();

    this.fitMapViewToFarmExtent();
    this.setHoverInteraction(hoverCallback);
    this.setClickInteraction(clickCallback);

    this.isSelectionMode = false;
  }

  setFarmId(id) {
    this.farmId = id;
  }

  setHoveredFeatureId(id) {
    this.smStyles.setHoveredFeatureId(id);
  }

  setSelectedFeatureId(id) {
    this.smStyles.setSelectedFeatureId(id);
  }

  static getFarmExtent(farmBbox) {
    const options = {
      dataProjection: SRID_ID_BBOX,
      featureProjection: SRID_ID_MAP,
    };

    const bboxExtent = new GeoJSON().readGeometry(farmBbox, options).getExtent();

    // we want farm to be a little zoomed out
    return getZoomedExtent(bboxExtent, 0.9);
  }

  static getGeometryCenter(geometry) {
    return getExtentCenter(new GeoJSON().readGeometry(geometry).getExtent());
  }

  setMap() {
    const map = new Map({
      target: 'sensors-map',
      layers: [...this.baseMapLayers, this.nodeLocationsLayer],
      loadTilesWhileInteracting: true,
      view: new View({
        minZoom: 2,
        maxZoom: 21,
      }),
      controls: defaultControls({
        attributionOptions: {
          collapsible: false,
        },
      }),
    });
    return map;
  }

  setNodeLocationsLayer() {
    const clusterSource = new Cluster({
      distance: 50,
      source: new VectorSource(),
    });

    return new VectorLayer({
      source: clusterSource,
      style: this.smStyles.setNodesStyle,
    });
  }

  fitMapViewToFarmExtent() {
    this.map.getView().fit(this.farmExtent);
  }

  getNodeLocationsLayer() {
    return this.nodeLocationsLayer;
  }

  getValidNodeLocations(nodeLocations) {
    return nodeLocations.filter(nodeLocation => !SensorsService.hasZeroCoordinates(nodeLocation));
  }

  setNodeLocations(nodeLocations) {
    const filteredNodeLocations = this.getValidNodeLocations(nodeLocations);
    const nodesFeatures = filteredNodeLocations.map(nodeLocation => {
      const [t] = transformWithValidation(nodeLocation.geometry.coordinates, 'EPSG:4326', 'EPSG:3857', true);
      const nodeGeometry = new Point(t);
      const nodeLocationFeature = new Feature({
        id: nodeLocation.locationId,
        status: nodeLocation.historic ? sensorsStatus.HISTORICAL : nodeLocation.status,
        geometry: nodeGeometry,
        name: nodeLocation.name,
      });
      nodeLocationFeature.setId(nodeLocation.id);
      return nodeLocationFeature;
    });

    const nodesSource = this.nodeLocationsLayer.getSource().getSource();
    nodesSource.clear(true);
    nodesSource.addFeatures(nodesFeatures);
  }

  setClickInteraction(clickCallback) {
    const select = new Select({
      condition: clickCondition,
      layers: [this.nodeLocationsLayer],
      style: this.smStyles.setNodesStyle,
    });

    this.map.addInteraction(select);

    select.on('select', evt => {
      if (evt.selected.length) {
        const wrappedFeatures = evt.selected[0].get('features');
        if (wrappedFeatures.length === 1) {
          clickCallback(wrappedFeatures[0]);
          this.nodeLocationsLayer.changed();
        } else {
          const view = this.map.getView();
          const currentZoom = view.getZoom();
          view.animate({
            center: evt.selected[0].getGeometry().getCoordinates(),
            zoom: currentZoom + 2,
            duration: 500,
          });
        }
      }
    });
  }

  setHoverInteraction(hoverCallback) {
    const select = new Select({
      condition: pointerMoveCondition,
      layers: [this.nodeLocationsLayer],
      style: this.smStyles.setNodesStyle,
    });

    this.map.addInteraction(select);

    select.on('select', evt => {
      if (evt.deselected.length) {
        hoverCallback(null);
        this.map.getTargetElement().style.cursor = '';
      }

      if (evt.selected.length) {
        const wrappedFeatures = evt.selected[0].get('features');
        hoverCallback(wrappedFeatures[0].get('id'));
        this.map.getTargetElement().style.cursor = 'pointer';
      }

      this.nodeLocationsLayer.changed();
    });
  }

  getMap() {
    return this.map;
  }
}
