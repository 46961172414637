import {
  getParcelActivitiesDateFilter,
  getParcelActivitiesOrder,
  getParcelActivitiesOrderBy,
  getParcelActivitiesPage,
  getParcelActivitiesRowsPerPage,
  getParcelActivitiesAdvancedFilter,
} from '../selectors/parcelActivities.selectors';

import { setTableSelected } from '../../../../../shared/actions/table.actions';

import * as types from './parcelActivities.constants';

import { NAMESPACE as namespace } from '../reducer/parcelActivities.reducer';

import { getActionsApi, resetActionsApi, deleteActionsApi } from '../../../../../shared/api/agroevidence/actions/actions.api';
import { getParcelSeedApplicationApi } from '../../../../../shared/api/agroevidence/parcels/parcels.api';
import { deleteVariableExpenses as deleteVariableExpensesApi } from '../../../../../shared/api/sentinel/variableApplication/variableApplication.api';

export const fetchParcelActivities = (parcelId, isDraft) => (dispatch, getState) => {
  const state = getState();
  const p = {
    page: getParcelActivitiesPage(state) + 1,
    'per-page': getParcelActivitiesRowsPerPage(state),
    'sort-dir': getParcelActivitiesOrder(state),
    'sort-col': getParcelActivitiesOrderBy(state),
    from: getParcelActivitiesDateFilter(state) || undefined,
    parcels: [parcelId],
    'with-fertilizer-detail': true,
    type: getParcelActivitiesAdvancedFilter(state).activities?.map(activity => activity.name)?.join(',') || undefined,
    'is-draft': isDraft,
  };

  dispatch(getActionsApi(p));
};

export const resetParcelActivities = () => dispatch => dispatch(resetActionsApi());

export const setDateFilter = dateFilter => ({
  type: types.SET_PARCEL_ACTIVITIES_DATE_FILTER,
  dateFilter,
});

export const deleteActivities = (parcelId, actionIds, variableActionIds) => (dispatch) => {
  dispatch(deleteActionsApi(actionIds))
    .then(() => {
      const promises = [];
      if (variableActionIds?.length) {
        variableActionIds.forEach(variableActionId => {
          if (actionIds.includes(variableActionId)) {
            promises.push(dispatch(deleteVariableExpensesApi(variableActionId)));
          }
        });
      }

      return Promise.all(promises).then(() => {
        dispatch(fetchParcelActivities(parcelId));
        dispatch(getParcelSeedApplicationApi(parcelId, true));
      });
    })
    .finally(() => {
      dispatch(setTableSelected([], namespace));
    });
};
