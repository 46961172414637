import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import farmsApi from '../shared/api/agroevidence/farms/farms.reducer';
import authReducer from '../shared/api/gateway/auth/auth.reducer';

export default (history) => combineReducers({
  api: combineReducers({
    farms: farmsApi(),
  }),
  router: connectRouter(history),
  auth: authReducer,
});
