/**
 * Created by ondrejzvara on 22.11.16.
 */

/* @ngInject */
export default function cfTabset(Tabs, $state, $transitions) {
  const directive = {
    restrict: 'E',
    template: require('./cf-tabset.html'),
    scope: {
      tabsetId: '@',
      showToggled:'=',
      showFertilizer:'=',
    },
    link(scope) {
      scope.active = 0;
      scope.tabs = Tabs.getTabsTpl(scope.tabsetId, scope.showToggled, scope.showFertilizer);
      scope.setActiveTab = setActiveTab;

      updateTabsStatus();

      const deregisterOnTransitionSuccess = $transitions.onSuccess({}, () => {
        updateTabsStatus();
      });

      function setActiveTab(i) {
        scope.active = i;
      }

      function updateTabsStatus() {
        scope.tabs.forEach((tab) => {
          if ($state.is(tab.state)) {
            scope.active = tab.index;
          }
        });
      }

      scope.$watch("showToggled",(newVal,oldVal)=>{
        if(newVal === oldVal) return;
        scope.tabs = Tabs.getTabsTpl(scope.tabsetId, scope.showToggled, scope.showFertilizer);
        updateTabsStatus();
      })

      scope.$watch("showFertilizer",(newVal,oldVal)=>{
        if(newVal === oldVal) return;
        scope.tabs = Tabs.getTabsTpl(scope.tabsetId, scope.showToggled, scope.showFertilizer);
        updateTabsStatus();
      })
      
      scope.$on('$destroy', () => {
        deregisterOnTransitionSuccess();
      });
    }
  };
  return directive;
}
