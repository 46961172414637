import React, { FC } from 'react';

import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FormattedMessage } from 'react-intl';

import { useFertilizerDetailStyles } from '../FertilizerDetail.styles';

interface Props {
  isNew: boolean,
}

const Buttons: FC<Props> = ({ isNew }) => {
  const classes = useFertilizerDetailStyles();
  return (
    <Grid
      alignItems="center"
      className={classes.buttons}
      container
      justifyContent="center"
      spacing={1}
    >
      <Grid item>
        <Button type="reset" variant="contained">
          <FormattedMessage id="common.cancel" />
        </Button>
      </Grid>
      <Grid item>
        <LoadingButton color="primary" type="submit" variant="contained">
          <FormattedMessage id={isNew ? 'common.create' : 'common.save'} />
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default Buttons;
