import StatusError from '../../../../../assets/img/icons/satellite/statusError.svg';
import StatusPending from '../../../../../assets/img/icons/satellite/statusPending.svg';
import StatusSuccess from '../../../../../assets/img/icons/satellite/statusSuccess.svg';

import { EXPORT_TYPES, STATUSES, UploaderJob, WriterJob } from './types';

export const getUploadJobsByParentId = (writerJobs: WriterJob[], uploaderJobs: UploaderJob[]) =>
  writerJobs.reduce((acc, wj) => {
    const childrenJobs = uploaderJobs.filter(uj => uj.parentId === wj.jobId);
    acc[wj.jobId] = [...childrenJobs];
    return acc;
  },
  {} as {[jobId: number]: UploaderJob[]});

export const getStatusText = (status: STATUSES, type: EXPORT_TYPES) => {
  switch (status) {
    case STATUSES.COMPLETE:
      return `VaExports.${type}.status.complete`;
    case STATUSES.ERROR:
      return `VaExports.${type}.status.error`;
    case STATUSES.PENDING:
      return `VaExports.${type}.status.pending`;
    case STATUSES.RUNNING:
      return `VaExports.${type}.status.running`;
    default:
      return '';
  }
};

export const getStatusIcon = (status: STATUSES) => {
  switch (status) {
    case STATUSES.COMPLETE:
      return StatusSuccess;
    case STATUSES.ERROR:
      return StatusError;
    case STATUSES.PENDING:
      return StatusPending;
    case STATUSES.RUNNING:
      return StatusPending;
    default:
      return StatusPending;
  }
};
