import React, { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import CfDialog from '../../../../shared/components/common/CfDialog/CfDialog';

interface SowingPlanResetDialogProps {
  onAccept: () => void,
  onClose: () => void,
  opened?: boolean,
  selectedSeasonYears: string,
}

const SowingPlanResetDialog: FC<SowingPlanResetDialogProps> = ({
  onAccept,
  onClose,
  opened = false,
  selectedSeasonYears,
}) => (
  <CfDialog
    acceptText={<FormattedMessage id="common.reset" />}
    cancelText={<FormattedMessage id="common.cancel" />}
    onAccept={onAccept}
    onCancel={onClose}
    onClose={onClose}
    opened={opened}
    title={<FormattedMessage id="SowingPlan.resetDialog.title" values={{ selectedSeasonYears }} />}
  >
    <FormattedMessage id="SowingPlan.resetDialog.text" />
  </CfDialog>
);

export default SowingPlanResetDialog;
