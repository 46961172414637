import React, { Component, Fragment } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Popover, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';

import ToolbarSection from '../../../../shared/components/specific/ToolbarSection/ToolbarSection';
import withPopover from '../../../../shared/hocs/withPopover';
import ToolbarHoverStopper from '../ToolbarHoverStopper/ToolbarHoverStopper';
import ToolbarParcelItem from '../ToolbarParcelItem/ToolbarParcelItem';
import ToolbarParcelSelector from '../ToolbarParcelSelector/ToolbarParcelSelector';

const styles = theme => ({
  parcelFormWrapper: {
    display: 'flex',
  },
  parcelListWrapper: {
    display: 'flex',
    height: '28px',
    borderRadius: '20px 0 0 20px',
  },
  buttonRoot: {
    color: theme.palette.grey[500],
    padding: '0 8px',
    minHeight: '28px',
    minWidth: 'auto',
    height: '28px',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    borderRadius: 20,
  },
  buttonLabel: {
    position: 'relative',
    textTransform: 'none',
    marginRight: '15px',
  },
  rightIcon: {
    right: '-21px',
    position: 'absolute',
  },
});

export class SelectParcelForm extends Component {
  constructor(props) {
    super(props);

    this.anchor = null;
    this.initFetchPromise = null;
  }

  componentDidMount() {
    this.initFetchPromise = this.props.onSuggestionFetch();
  }

  componentDidUpdate(prevProps) {
    const { langId } = this.props;
    if (prevProps.langId && prevProps.langId !== langId) {
      this.props.onSuggestionsReset();
      this.props.onSuggestionFetch();
    }
  }

  componentWillUnmount() {
    this.props.onSuggestionsReset();
  }

  onDropdownClick = () => {
    const promise = this.initFetchPromise || Promise.resolve();
    promise.then(() => {
      this.props.handlePopoverOpen({ currentTarget: this.anchor });
      this.initFetchPromise = null;
    });
  };

  onSuggestionSelect = suggestion => {
    this.props.onParcelSelect(suggestion);
    this.onSuggestionClear();
  };

  onSuggestionClear = () => {
    this.props.handlePopoverClose();
    this.props.onSuggestionClear();
    this.initFetchPromise = this.props.onSuggestionFetch();
  };

  onParcelRemove = parcel => {
    this.props.onParcelRemove(parcel);
  };

  getParcels = (textFilter, reason) => {
    if (!textFilter && reason === 'input-focused') {
      return Promise.resolve();
    }

    this.props.onSuggestionFetch(textFilter);
  };

  render() {
    const { anchorEl, classes, disabled, isOpen, parcels, suggestions } = this.props;

    return (
      <Fragment>
        {parcels.length > 0 &&
          parcels.map(parcel => <ToolbarParcelItem key={parcel.id} onCancel={this.onParcelRemove} parcel={parcel} />)}
        {!disabled && (
          <ToolbarSection>
            <ToolbarHoverStopper />
            <Button
              data-test={'parcel-select'}
              disableFocusRipple={true}
              onClick={this.onDropdownClick}
              // buttonRef={el => {
              //   this.anchor = el;
              // }}
              classes={{
                root: classes.buttonRoot,
                label: classes.buttonLabel,
              }}
            >
              <FormattedMessage id="DrawParcelForm.select-parcel" />
              <ArrowDropDownIcon className={classes.rightIcon} />
            </Button>
          </ToolbarSection>
        )}

        <Popover
          anchorEl={anchorEl}
          onClose={this.onSuggestionClear}
          open={isOpen}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom',
          }}
          PaperProps={{
            'data-test': 'parcel-list-selector',
          }}
          transformOrigin={{
            vertical: -5,
            horizontal: 0,
          }}
        >
          <ToolbarParcelSelector
            getSuggestions={this.getParcels}
            onSuggestionClear={this.onSuggestionClear}
            onSuggestionSelect={this.onSuggestionSelect}
            suggestions={suggestions}
          />
        </Popover>
      </Fragment>
    );
  }
}

SelectParcelForm.propTypes = {
  classes: PropTypes.object.isRequired,
  langId: PropTypes.string.isRequired,
  suggestions: PropTypes.array.isRequired,
  parcels: PropTypes.array.isRequired,
  onSuggestionFetch: PropTypes.func.isRequired,
  onSuggestionsReset: PropTypes.func.isRequired,
  onSuggestionClear: PropTypes.func.isRequired,
  onParcelRemove: PropTypes.func.isRequired,
  onParcelSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  handlePopoverClose: PropTypes.func.isRequired,
  handlePopoverOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.any,
};

SelectParcelForm.defaultProps = {
  disabled: false,
  anchorEl: null,
};

export default compose(withStyles(styles), withPopover)(SelectParcelForm);
