import { createSelector } from 'reselect';

import {
  getIsFetchingParcelSeedApplication,
  getIsFetchingParcelPredecessors,
  getIsFetchinParcelEagriRestrictions,
} from '../../../../../shared/api/agroevidence/parcels/parcels.selectors';

export const getIsFetchingParcelDetails = createSelector(
  getIsFetchinParcelEagriRestrictions,
  getIsFetchingParcelSeedApplication,
  getIsFetchingParcelPredecessors,
  (isFetchinEagriRestrictions, isFetchingSeedApplication, isFetchingPredecessors) =>
    isFetchinEagriRestrictions || isFetchingSeedApplication || isFetchingPredecessors);
