import React, { FC } from 'react';

import MenuItem from '@mui/material/MenuItem';
import { Field } from 'formik';
import { FormattedMessage } from 'react-intl';

import CfFormikTextField from '../../../../shared/components/form/CfFormikTextField/CfFormikTextField';
import * as validators from '../../../../shared/misc/validators';
import { useTelematicsAggregationDetailContentStyles } from '../styles';

import { OperationTo } from '../../../../shared/api/telematics/telematics.types';

interface Props {
    disabled?: boolean;
    operations: OperationTo[];
}

const OperationField: FC<Props> = ({ disabled, operations }) => {
  const classes = useTelematicsAggregationDetailContentStyles();

  return (
    <Field
      className={classes.fullWidth}
      component={CfFormikTextField}
      disabled={disabled}
      label={<FormattedMessage id="TelematicsList.operation" />}
      name="operation"
      select
      validate={validators.required}
    >
      {operations.map((operation) =>
        <MenuItem key={operation.code} value={operation.code}>{operation.name}</MenuItem>,
      )}
    </Field>
  );
};

export default OperationField;
