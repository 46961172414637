const PREFIX = 'AGROEVIDENCE_SOWING_PLAN';

export const GET_PARCEL_PLAN_REQUEST = `${PREFIX}/GET_PARCEL_PLAN_REQUEST`;
export const GET_PARCEL_PLAN_SUCCESS = `${PREFIX}/GET_PARCEL_PLAN_SUCCESS`;
export const GET_PARCEL_PLAN_ERROR = `${PREFIX}/GET_PARCEL_PLAN_ERROR`;
export const RESET_PARCEL_PLAN = `${PREFIX}/RESET_PARCEL_PLAN`;

export const PUT_PARCEL_PLAN_REQUEST = `${PREFIX}/PUT_PARCEL_PLAN_REQUEST`;
export const PUT_PARCEL_PLAN_SUCCESS = `${PREFIX}/PUT_PARCEL_PLAN_SUCCESS`;
export const PUT_PARCEL_PLAN_ERROR = `${PREFIX}/PUT_PARCEL_PLAN_ERROR`;

export const GET_SEASONS_REQUEST = `${PREFIX}/GET_SEASONS_REQUEST`;
export const GET_SEASONS_SUCCESS = `${PREFIX}/GET_SEASONS_SUCCESS`;
export const GET_SEASONS_ERROR = `${PREFIX}/GET_SEASONS_ERROR`;
export const RESET_SEASONS = `${PREFIX}/RESET_SEASONS`;

export const POST_SEASONS_REQUEST = `${PREFIX}/POST_SEASONS_REQUEST`;
export const POST_SEASONS_SUCCESS = `${PREFIX}/POST_SEASONS_SUCCESS`;
export const POST_SEASONS_ERROR = `${PREFIX}/POST_SEASONS_ERROR`;

export const GET_SEASON_DETAIL_REQUEST = `${PREFIX}/GET_SEASON_DETAIL_REQUEST`;
export const GET_SEASON_DETAIL_SUCCESS = `${PREFIX}/GET_SEASON_DETAIL_SUCCESS`;
export const GET_SEASON_DETAIL_ERROR = `${PREFIX}/GET_SEASON_DETAIL_ERROR`;
export const RESET_SEASON_DETAIL = `${PREFIX}/RESET_SEASON_DETAIL`;

export const EXPORT_SEASONS_REQUEST = `${PREFIX}/EXPORT_SEASONS_REQUEST`;
export const EXPORT_SEASONS_SUCCESS = `${PREFIX}/EXPORT_SEASONS_SUCCESS`;
export const EXPORT_SEASONS_ERROR = `${PREFIX}/EXPORT_SEASONS_ERROR`;
export const RESET_EXPORT_SEASONS = `${PREFIX}/RESET_EXPORT_SEASONS`;

export const RESET_SEASON_PLAN_REQUEST = `${PREFIX}/RESET_SEASON_PLAN_REQUEST`;
export const RESET_SEASON_PLAN_SUCCESS = `${PREFIX}/RESET_SEASON_PLAN_SUCCESS`;
export const RESET_SEASON_PLAN_ERROR = `${PREFIX}/RESET_SEASON_PLAN_ERROR`;
