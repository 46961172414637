import React, { FC, useEffect, useMemo } from 'react';

import { Theme } from '@mui/material';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import { makeStyles } from '@mui/styles';
import { useFormikContext } from 'formik';
import { ReactNodeLike } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getKeyTypeCombinations, isFetchingKeyTypes } from '../../../../shared/api/telematics/aggregations/aggregations.selectors';

import { getKeyCombinationsForDriveApi } from '../../../../shared/api/telematics/aggregations/aggregations.api';
import CfAutocomplete from '../../../../shared/components/common/CfAutocomplete/CfAutocomplete';
import CfFormControl from '../../../../shared/components/form/CfFormControl/CfFormControl';
import { Thunk } from '../../../../types';
import { DriveKeysFormValues } from '../DriveKeysForm';

import ApprovalWarning from './ApprovalWarning';

import { TelematicsState } from '../../../../reducers/telematics.reducer.types';
import { DriveKeyTo, DriveValidationType, KeyType } from '../../../../shared/api/telematics/telematics.types';

type Variant = 'active' | 'disabled';

interface Props {
    customClasses?: Record<string, string>;
    disabled?: boolean;
    fetchSuggestions: (key: KeyType) => void;
    fieldName: keyof DriveKeysFormValues;
    getSuggestionsByKey: (key: KeyType) => DriveKeyTo[] | null;
    helperText?: NonNullable<ReactNodeLike> | null;
    isFetching: boolean;
    keyType: KeyType;
    placeholder?: string;
    showApprovalWarning?: boolean;
    tooltipCode?: DriveValidationType;
    variant?: Variant;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiAutocomplete-endAdornment': {
      display: ({ variant }: {variant: Variant}) => (variant === 'disabled' ? 'none' : 'initial'),
    },
  },
  secondaryText: {
    color: theme.palette.grey[500],
  },
}));

const DriveKey: FC<Props> = ({
  customClasses,
  disabled = false,
  fetchSuggestions,
  fieldName,
  getSuggestionsByKey,
  helperText,
  isFetching,
  keyType,
  placeholder,
  showApprovalWarning = false,
  tooltipCode,
  variant = 'active',
}) => {
  const { errors, setFieldValue, values } = useFormikContext<DriveKeysFormValues>();
  const classes = useStyles({ variant });

  useEffect(() => {
    if (disabled) return;
    fetchSuggestions(keyType);
  }, [fetchSuggestions, keyType, disabled]);

  const suggestions = useMemo(() => getSuggestionsByKey(keyType), [getSuggestionsByKey, keyType]);

  const handleFilterOptions = createFilterOptions({
    stringify: ({ key, name }) => `${name} (${key})`,
  });

  return (
    <div className={classes.container}>
      <ApprovalWarning show={showApprovalWarning} tooltipCode={tooltipCode} />
      <CfFormControl>
        <CfAutocomplete
          classes={customClasses}
          defaultValues={values[fieldName]}
          disabled={disabled}
          error={!!errors[fieldName]}
          filterOptions={handleFilterOptions}
          getLabel={(option: DriveKeyTo) => (option?.key ? `${option.key}\n${option.name ?? '-'}` : option.name)}
          helperText={helperText}
          isFetching={isFetching}
          isMultiple={false}
          label={placeholder ? undefined : <FormattedMessage id={`TelematicsAggregations.detail.keys.${fieldName}`} />}
          multilineInput
          onChange={(value) => setFieldValue(fieldName, value)}
          placeholder={placeholder}
          suggestions={suggestions || []}
          renderOption={(option: DriveKeyTo) =>
            <div>
              <div>{option.key ?? '-'}</div>
              <div className={classes.secondaryText}>{option.name ?? '-'}</div>
            </div>}
        />
      </CfFormControl>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) => bindActionCreators({
  fetchSuggestions: getKeyCombinationsForDriveApi,
}, dispatch);

const mapStateToProps = (state: TelematicsState) => ({
  isFetching: isFetchingKeyTypes(state),
  getSuggestionsByKey: getKeyTypeCombinations(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(DriveKey);
