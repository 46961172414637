import React from 'react';

import { Switch, Route } from 'react-router-dom';

import { LANG_KEYS } from '../../../../../shared/lang/lang.constants';
import { FORM_TYPES } from '../../../others/actionOther.constants';

import BaseContainer from '../../../../../shared/containers/BaseContainer/BaseContainer';
import Localization from '../../../../../shared/services/Localization.service';
import Eph from '../../../eph/containers/Eph/Eph';
import ephTranslationsCZ from '../../../eph/lang/locale-cs-CZ.json';
import ephTranslationsEL from '../../../eph/lang/locale-el-GR.json';
import ephTranslationsEN from '../../../eph/lang/locale-en-US.json';
import ephTranslationsES from '../../../eph/lang/locale-es-ES.json';
import ephTranslationsRO from '../../../eph/lang/locale-ro-RO.json';
import ephTranslationsRU from '../../../eph/lang/locale-ru-UA.json';
import ephTranslationsSR from '../../../eph/lang/locale-sr-LATN-RS.json';
import ActionsList from '../../../list/containers/ActionsList/ActionsList';
import actionsListTranslationsCZ from '../../../list/lang/locale-cs-CZ.json';
import actionsListTranslationsEL from '../../../list/lang/locale-el-GR.json';
import actionsListTranslationsEN from '../../../list/lang/locale-en-US.json';
import actionsListTranslationsES from '../../../list/lang/locale-es-ES.json';
import actionsListTranslationsRO from '../../../list/lang/locale-ro-RO.json';
import actionsListTranslationsRU from '../../../list/lang/locale-ru-UA.json';
import actionsListTranslationsSR from '../../../list/lang/locale-sr-LATN-RS.json';
import ActionOtherDetail from '../../../others/containers/ActionOtherDetail/ActionOtherDetail';
import otherActionsTranslationsCZ from '../../../others/lang/locale-cs-CZ.json';
import otherActionsTranslationsEL from '../../../others/lang/locale-el-GR.json';
import otherActionsTranslationsEN from '../../../others/lang/locale-en-US.json';
import otherActionsTranslationsES from '../../../others/lang/locale-es-ES.json';
import otherActionsTranslationsRO from '../../../others/lang/locale-ro-RO.json';
import otherActionsTranslationsRU from '../../../others/lang/locale-ru-UA.json';
import otherActionsTranslationsSR from '../../../others/lang/locale-sr-LATN-RS.json';
import Vrf from '../../../vrf/containers/Vrf/Vrf';
import vFertTranslationsCZ from '../../../vrf/lang/locale-cs-CZ.json';
import vFertTranslationsEL from '../../../vrf/lang/locale-el-GR.json';
import vFertTranslationsEN from '../../../vrf/lang/locale-en-US.json';
import vFertTranslationsES from '../../../vrf/lang/locale-es-ES.json';
import vFertTranslationsRO from '../../../vrf/lang/locale-ro-RO.json';
import vFertTranslationsRU from '../../../vrf/lang/locale-ru-UA.json';
import vFertTranslationsSR from '../../../vrf/lang/locale-sr-LATN-RS.json';
import Vrs from '../../../vrs/containers/Vrs/Vrs';
import vSeedingTranslationsCZ from '../../../vrs/lang/locale-cs-CZ.json';
import vSeedingTranslationsEL from '../../../vrs/lang/locale-el-GR.json';
import vSeedingTranslationsEN from '../../../vrs/lang/locale-en-US.json';
import vSeedingTranslationsES from '../../../vrs/lang/locale-es-ES.json';
import vSeedingTranslationsRO from '../../../vrs/lang/locale-ro-RO.json';
import vSeedingTranslationsRU from '../../../vrs/lang/locale-ru-UA.json';
import vSeedingTranslationsSR from '../../../vrs/lang/locale-sr-LATN-RS.json';
import sharedActionTranslationsCZ from '../../lang/locale-cs-CZ.json';
import sharedActionTranslationsEL from '../../lang/locale-el-GR.json';
import sharedActionTranslationsEN from '../../lang/locale-en-US.json';
import sharedActionTranslationsES from '../../lang/locale-es-ES.json';
import sharedActionTranslationsRO from '../../lang/locale-ro-RO.json';
import sharedActionTranslationsRU from '../../lang/locale-ru-UA.json';
import sharedActionTranslationsSR from '../../lang/locale-sr-LATN-RS.json';
import ActionDetail from '../ActionDetail/ActionDetail';

const actionsListTranslations = {
  [LANG_KEYS.CZ]: actionsListTranslationsCZ,
  [LANG_KEYS.EN]: actionsListTranslationsEN,
  [LANG_KEYS.SR]: actionsListTranslationsSR,
  [LANG_KEYS.ES]: actionsListTranslationsES,
  [LANG_KEYS.RU]: actionsListTranslationsRU,
  [LANG_KEYS.EL]: actionsListTranslationsEL,
  [LANG_KEYS.RO]: actionsListTranslationsRO,
};

const ephTranslations = {
  [LANG_KEYS.CZ]: ephTranslationsCZ,
  [LANG_KEYS.EN]: ephTranslationsEN,
  [LANG_KEYS.SR]: ephTranslationsSR,
  [LANG_KEYS.ES]: ephTranslationsES,
  [LANG_KEYS.RU]: ephTranslationsRU,
  [LANG_KEYS.EL]: ephTranslationsEL,
  [LANG_KEYS.RO]: ephTranslationsRO,
};

const otherActionsTranslations = {
  [LANG_KEYS.CZ]: otherActionsTranslationsCZ,
  [LANG_KEYS.EN]: otherActionsTranslationsEN,
  [LANG_KEYS.SR]: otherActionsTranslationsSR,
  [LANG_KEYS.ES]: otherActionsTranslationsES,
  [LANG_KEYS.RU]: otherActionsTranslationsRU,
  [LANG_KEYS.EL]: otherActionsTranslationsEL,
  [LANG_KEYS.RO]: otherActionsTranslationsRO,
};

const sharedActionTranslations = {
  [LANG_KEYS.CZ]: sharedActionTranslationsCZ,
  [LANG_KEYS.EN]: sharedActionTranslationsEN,
  [LANG_KEYS.SR]: sharedActionTranslationsSR,
  [LANG_KEYS.ES]: sharedActionTranslationsES,
  [LANG_KEYS.RU]: sharedActionTranslationsRU,
  [LANG_KEYS.EL]: sharedActionTranslationsEL,
  [LANG_KEYS.RO]: sharedActionTranslationsRO,
};

const vrfTranslations = {
  [LANG_KEYS.CZ]: vFertTranslationsCZ,
  [LANG_KEYS.EN]: vFertTranslationsEN,
  [LANG_KEYS.SR]: vFertTranslationsSR,
  [LANG_KEYS.ES]: vFertTranslationsES,
  [LANG_KEYS.RU]: vFertTranslationsRU,
  [LANG_KEYS.EL]: vFertTranslationsEL,
  [LANG_KEYS.RO]: vFertTranslationsRO,
};

const vrsTranslations = {
  [LANG_KEYS.CZ]: vSeedingTranslationsCZ,
  [LANG_KEYS.EN]: vSeedingTranslationsEN,
  [LANG_KEYS.SR]: vSeedingTranslationsSR,
  [LANG_KEYS.ES]: vSeedingTranslationsES,
  [LANG_KEYS.RU]: vSeedingTranslationsRU,
  [LANG_KEYS.EL]: vSeedingTranslationsEL,
  [LANG_KEYS.RO]: vSeedingTranslationsRO,
};

function Actions({ ...props }) {
  return (
    <BaseContainer
      langId={props.langId}
      translations={Localization.mergeTranslations(
        actionsListTranslations,
        ephTranslations,
        vrfTranslations,
        vrsTranslations,
        otherActionsTranslations,
        sharedActionTranslations,
      )}
    >
      <Switch>
        <Route
          exact
          path="/farm/:farmId/actions"
          render={routerProps =>
            <ActionsList {...props} {...routerProps} />
        }
       />
        <Route
          path="/farm/:farmId/actions/eph"
          render={routerProps => (
            <Eph {...props} {...routerProps} />
          )}
        />
        <Route
          path="/farm/:farmId/actions/variable-fertilization"
          render={routerProps => (
            <Vrf {...props} {...routerProps} />
          )}
        />
        <Route
          path="/farm/:farmId/actions/variable-seeding"
          render={routerProps => (
            <Vrs {...props} {...routerProps} />
          )}
        />
        <Route
          path="/farm/:farmId/actions/other"
          render={routerProps => (
            <ActionOtherDetail
              {...props}
              {...routerProps}
              formType={FORM_TYPES.OTHER}
              isEditing
            />
          )}
        />
        <Route
          path="/farm/:farmId/actions/mowing"
          render={routerProps => (
            <ActionOtherDetail
              {...props}
              {...routerProps}
              formType={FORM_TYPES.MOWING}
              isEditing
            />
          )}
        />
        <Route
          path="/farm/:farmId/actions/sowing"
          render={routerProps => (
            <ActionOtherDetail
              {...props}
              {...routerProps}
              formType={FORM_TYPES.SOWING}
              isEditing
            />
          )}
      />
        <Route
          path="/farm/:farmId/actions/harvest"
          render={routerProps => (
            <ActionOtherDetail
              {...props}
              {...routerProps}
              formType={FORM_TYPES.HARVEST}
              isEditing
            />
          )}
        />
        <Route
          path="/farm/:farmId/actions/:actionId"
          render={routerProps => (
            <ActionDetail {...props} {...routerProps} />
          )}
        />
      </Switch>
    </BaseContainer>
  );
}

export default Actions;
