import React, { Component } from 'react';

import { Theme, alpha } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { withStyles, ClassNameMap } from '@mui/styles';
import classNames from 'classnames';
import moment from 'moment';
import { FormattedMessage, FormattedTime } from 'react-intl';

import { COLORS } from '../CfTextBadge/CfTextBadge';

import { getIntervalPositionInPixels } from './helpers';

const TIME_AXIS = {
  0: '0:00',
  1: '2:00',
  2: '4:00',
  3: '6:00',
  4: '8:00',
  5: '10:00',
  6: '12:00',
  7: '14:00',
  8: '16:00',
  9: '18:00',
  10: '20:00',
  11: '22:00',
  12: '24:00',
};

const TIME_AXIS_GRANULARITY = 2;
const TIME_AXIS_OFFSET = 1;

const HOVER_BUFFER = 4;
const GRAPH_HEIGHT = 14;
const AXIS_LABEL_WIDTH = 25;
const AXIS_LABEL_HEIGHT = 12;

const INTERVAL_MIN_WIDTH = 4;
const SPLITTER_WIDTH = 2;

type Classes = keyof ReturnType<typeof styles>
interface Props {
  classes?: Partial<ClassNameMap<Classes>>
  // eslint-disable-next-line react/no-unused-prop-types
  customColor?: string;
  datetimeEnd: string;
  datetimeSplitter?: string;
  datetimeStart: string;
  handworkIntervals?: string[];
  intervals: string[];
  // eslint-disable-next-line react/no-unused-prop-types
  intervalsShape?: 'sharp' | 'rounded',
  isEditing?: boolean;
  overlaps?: string[],
  selectedOverlapIds?: number[],
  tooltipMessage?: string;
  withTimeAxis?: boolean;
}

interface State {
  graphWidth: number;
}

const styles = (theme: Theme) => ({
  wrapper: {
    width: '100%',
    position: 'relative' as const,
  },
  overlap: {
    backgroundColor: theme.palette.secondary.main,
    border: 'unset',
  },
  selectedOverlap: {
    backgroundColor: theme.palette.primary.main,
  },
  handworkInterval: {
    backgroundColor: COLORS.VIOLET_PLUM,
  },
  handworkTooltip: {
    color: COLORS.VIOLET_PLUM,
  },
  intervalsContainer: {
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
    backgroundColor: theme.palette.grey[300],
    width: '100%',
    height: '14px',
    borderRadius: '10px',
    position: 'relative' as const,
    overflow: 'hidden',
  },
  isEditingContainer: {
    backgroundColor: alpha(theme.palette.grey[300], 0.5),
  },
  interval: {
    position: 'absolute' as const,
    top: HOVER_BUFFER,
    left: HOVER_BUFFER,
    height: GRAPH_HEIGHT,
    borderRadius: (props: Props) => (props.intervalsShape === 'rounded' ? '10px' : 0),
  },
  ride: {
    backgroundColor: (props: Props) => props.customColor ?? theme.palette.primary.main,
    borderWidth: '0 1px',
    border: `solid ${theme.palette.grey[300]}`,
  },
  isEditingRide: {
    backgroundColor: (props: Props) => (
      props.customColor ? alpha(props.customColor, 0.25) : alpha(theme.palette.primary.main, 0.25)),
  },
  splitter: {
    position: 'absolute' as const,
    backgroundColor: () => theme.palette.error.main,
    borderWidth: '0 1px',
    border: `solid ${theme.palette.error.main}`,
    top: '-4px',
    left: 0,
    height: GRAPH_HEIGHT + 4,
  },
  hoverInterval: {
    height: GRAPH_HEIGHT + HOVER_BUFFER * 2,
    position: 'absolute' as const,
    top: -HOVER_BUFFER,
    visible: false,
  },
  timeAxisContainer: {
    width: '100%',
    height: AXIS_LABEL_HEIGHT,
    position: 'relative' as const,
    marginTop: 8,
  },
  timeAxisSlot: {
    position: 'absolute' as const,
    top: 0,
    display: 'inline-block',
    textAlign: 'center' as const,
    width: AXIS_LABEL_WIDTH,
    fontSize: AXIS_LABEL_HEIGHT,
    lineHeight: 1,
    color: theme.palette.grey[500],
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: (props: Props) => props.customColor ?? theme.palette.primary.main,
    borderRadius: 3,
    '-webkit-border-radius': 3,
    '-moz-border-radius': 3,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    '-webkit-box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.25)',
    '-moz-box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.25)',
  },
  tooltipTitleLabel: {
    fontWeight: 400,
    marginRight: 5,
  },
  tooltipTitleTime: {
    color: (props: Props) => props.customColor ?? theme.palette.primary.main,
  },
  overlapTooltip: {
    color: theme.palette.secondary.main,
  },
});

class TimeIntervalsBar extends Component<Props, State> {
  graphWrapperRef

  constructor(props: Props) {
    super(props);
    this.state = {
      graphWidth: 0,
    };
    this.graphWrapperRef = React.createRef<HTMLDivElement>();
  }

  componentDidMount() {
    this.updateGraphWidth();
    window.addEventListener('resize', this.updateGraphWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateGraphWidth);
  }

  updateGraphWidth = () => {
    this.setState({
      graphWidth: this.graphWrapperRef.current?.offsetWidth || 0,
    });
  };

  renderTimeAxis = () => {
    const { classes = {} } = this.props;
    const { graphWidth } = this.state;
    const slotCount = 24 / TIME_AXIS_GRANULARITY;
    const slotWidth = graphWidth / slotCount;
    const slotComponents = [];

    type index = keyof typeof TIME_AXIS

    for (let i = TIME_AXIS_OFFSET; i < slotCount; i += TIME_AXIS_GRANULARITY) {
      const slotPosition = (i * slotWidth) - (AXIS_LABEL_WIDTH / 2);
      slotComponents.push(
        <span className={classes.timeAxisSlot} key={i} style={{ left: slotPosition }}>{TIME_AXIS[i as index]}</span>,
      );
    }

    return slotComponents;
  }

  isIntervalOverMidnight = (interval: string) => {
    const [start, end] = interval.split('/');
    return moment(end).isAfter(start, 'day');
  };

  renderSplitter = () => {
    const { classes = {}, datetimeSplitter, datetimeEnd, datetimeStart } = this.props;
    const { graphWidth } = this.state;

    if (!datetimeSplitter) return;

    const startInPixels = getIntervalPositionInPixels(
      datetimeSplitter,
      graphWidth,
      datetimeStart,
      datetimeEnd,
    );
    const width = SPLITTER_WIDTH;

    const left = Math.round(startInPixels);

    return (
      <span
        className={classes.splitter}
        style={{
          width,
          left: left - (SPLITTER_WIDTH / 2),
        }}
      />
    );
  }

  renderInterval = (interval: string, type: 'ride' | 'overlap' | 'handwork', id: number) => {
    const { classes = {}, datetimeEnd, datetimeStart, tooltipMessage, isEditing,
      selectedOverlapIds, intervalsShape = 'sharp' } = this.props;
    const { graphWidth } = this.state;

    const isOverMidnight = this.isIntervalOverMidnight(interval);

    const startEnd = interval.split('/');

    if (startEnd[0] === startEnd[1]) {
      return null;
    }

    const startInPixels = getIntervalPositionInPixels(
      startEnd[0],
      graphWidth,
      datetimeStart,
      datetimeEnd,
    );
    const endInPixels = getIntervalPositionInPixels(
      startEnd[1],
      graphWidth,
      datetimeStart,
      datetimeEnd,
    );

    let width = Math.floor(endInPixels - startInPixels);
    if (width < 1 && Math.ceil(endInPixels - startInPixels) > 0) {
      width = 1;
    }
    if (isNaN(width) || width === 0 || width < 0) {
      return null;
    }

    if (width < INTERVAL_MIN_WIDTH) {
      width = INTERVAL_MIN_WIDTH;
    }

    const left = Math.round(startInPixels);

    // make intervals on the ends rounded even for the sharp shape
    const position = {
      start: left >= 0 && left < 5,
      end: left + width > graphWidth - 5,
    };

    const isBorderPosition = position.start || position.end;
    const isFullWidth = position.start && position.end;

    let borderRadius = '0';

    if (intervalsShape === 'sharp') {
      if (isBorderPosition && !isFullWidth) {
        borderRadius = position.start ? '10px 0 0 10px' : '0 10px 10px 0';
      }
      if (isFullWidth) {
        borderRadius = '10px';
      }
    }

    return (
      <Tooltip
        key={`${startEnd[0]}-${startEnd[1]}`}
        classes={{
          tooltip: classes.tooltip,
        }}
        title={
          <span>
            {tooltipMessage && <span className={classes.tooltipTitleLabel}>
              <FormattedMessage id={tooltipMessage} />
            </span>}
            <span className={classNames(
              type === 'ride' ? classes.tooltipTitleTime : undefined,
              type === 'overlap' ? classes.overlapTooltip : undefined,
              type === 'handwork' ? classes.handworkTooltip : undefined)}>
              <FormattedTime value={startEnd[0]} />
              &ndash;
              <FormattedTime value={startEnd[1]} />
            </span>
          </span>
        }
      >
        <span
          className={classes.hoverInterval}
          style={{
            width: width + HOVER_BUFFER * 2,
            left: isOverMidnight ? 'unset' : left - HOVER_BUFFER,
            right: isOverMidnight ? '-4px' : 'unset',
          }}>
          <span
            className={classNames(
              classes.interval,
              type === 'handwork' ? classes.handworkInterval : undefined,
              type === 'ride' && !isEditing ? classes.ride : undefined,
              type === 'ride' && isEditing ? classes.isEditingRide : undefined,
              type === 'overlap' ? classes.overlap : undefined,
              type === 'overlap' && isEditing && selectedOverlapIds?.includes(id)
                ? classes.selectedOverlap : undefined,
            )}
            style={{
              width,
              borderRadius,
            }}
          />
        </span>
      </Tooltip>
    );
  };

  render() {
    const {
      classes = {}, intervals = [], withTimeAxis, overlaps = [], isEditing,
      handworkIntervals = [] } = this.props;

    return (
      <div className={classes.wrapper} ref={this.graphWrapperRef}>
        <div className={classNames(classes.intervalsContainer,
          isEditing ? classes.isEditingContainer : undefined,
        )}>
          {handworkIntervals.map((int, i) => this.renderInterval(int, 'handwork', i))}
          {intervals.map((int, i) => this.renderInterval(int, 'ride', i))}
          {overlaps.map((overlap, i) => this.renderInterval(overlap, 'overlap', i))}
        </div>
        {withTimeAxis && <div className={classes.timeAxisContainer}>
          {this.renderTimeAxis()}
        </div>}
        {this.renderSplitter()}
      </div>

    );
  }
}

export default withStyles(styles)(TimeIntervalsBar);
