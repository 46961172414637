import React, { FC, useContext, useEffect, useState } from 'react';

import { Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getAgiStatus } from '../../../shared/api/sentinel/agiStatus/agiStatus.selectors';

import { createAgIntegrationApi, getAgIntegrationSettingsApi, deleteAgIntegrationApi, getAgIntegrationStatusApi } from '../../../shared/api/sentinel/agiStatus/agiStatus.api';
import { SnackbarContext } from '../../../shared/containers/SnackbarProvider/SnackbarProvider';
import { AsyncFn, Thunk } from '../../../types';

import AgActivationDialog from './AgActivationDialog';
import AgDeactivationDialog from './AgDeactivationDialog';
import IntegrationsTable from './IntegrationsTable';

import { UserState } from '../../../reducers/user.reducer.types';
import { AgIntegrationStatus, getCreateAgIntegrationStatusApiParams } from '../../../shared/api/sentinel/agiStatus/agiStatus.types';

const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 500,
    margin: '10px 0px 15px 0px',
  },
  headerDescription: {
    fontSize: 13,
    fontWeight: 400,
    marginBottom: 20,
  },
  contentWrapper: {
    padding: theme.spacing(2),
  },
}));

interface Props {
  agiStatus: AgIntegrationStatus,
  createAgIntegrationApi: (param: getCreateAgIntegrationStatusApiParams) => void,
  deleteAgIntegrationApi: () => void,
  getAgIntegrationSettingsApi: () => void,
  getAgIntegrationStatusApi: () => void,
}

const IntegrationsList: FC<Props> = ({
  agiStatus,
  createAgIntegrationApi,
  deleteAgIntegrationApi,
  getAgIntegrationSettingsApi,
  getAgIntegrationStatusApi,
}) => {
  const classes = useStyles();
  const showSnackbar = useContext(SnackbarContext);

  const [showDeactivationDialog, setShowDeactivationDialog] = useState(false);
  const [showActivationDialog, setShowActivationDialog] = useState(false);

  useEffect(() => {
    getAgIntegrationStatusApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAgDeactivationDialogOpen = () => {
    if (!agiStatus.enabled) return;
    setShowDeactivationDialog(true);
  };

  const handleAgDeactivationDialogClose = () => {
    setShowDeactivationDialog(false);
  };

  const handleAgDeactivationDialogAccept = () => {
    deleteAgIntegrationApi();
    handleAgDeactivationDialogClose();
  };

  const handleAgActivationDialogAccept = (name: string, email: string) => {
    const param: getCreateAgIntegrationStatusApiParams = {
      email,
      name,
    };
    (createAgIntegrationApi as AsyncFn<getCreateAgIntegrationStatusApiParams>)(param).then((res) => {
      if (res.error) {
        showSnackbar({ message: <FormattedMessage id="Agi.activationDialog.error" values={{ email: param.email }} />, isError: true });
        return;
      }
      showSnackbar({ message: <FormattedMessage id="Agi.activationDialog.success" />, isSuccess: true });
    });
    setShowActivationDialog(false);
  };

  const handleAgIntegrationSetting = () => {
    (getAgIntegrationSettingsApi as AsyncFn)().then((res) => window.open(res.payload.url, '_blank'));
  };

  return (
    <>
      <div className={classes.headerWrapper}>
        <Grid className={classes.header} container>
          <Grid className={classes.headerTitle} item>
            <FormattedMessage id="IntegrationSettings.title" />
          </Grid>
          <Grid className={classes.headerDescription} item>
            <FormattedMessage id="IntegrationSettings.description" />
          </Grid>
        </Grid>
      </div>
      <div className={classes.contentWrapper}>
        <Grid alignItems="center" container spacing={2}>
          {showActivationDialog &&
            <AgActivationDialog
              onAccept={handleAgActivationDialogAccept}
              onClose={() => setShowActivationDialog(false)}
              opened={showActivationDialog}
            />}
          {showDeactivationDialog &&
            <AgDeactivationDialog
              onAccept={handleAgDeactivationDialogAccept}
              onClose={handleAgDeactivationDialogClose}
              opened={showDeactivationDialog}
            />}
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <IntegrationsTable
                  item={agiStatus}
                  openAgActivationDialog={() => setShowActivationDialog(true)}
                  openAgDeactivationDialog={handleAgDeactivationDialogOpen}
                  setAgIntegrationSetting={handleAgIntegrationSetting}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const mapStateToProps = (state: UserState) => ({
  agiStatus: getAgiStatus(state),
});

const mapDispatchToProps = (dispatch: Thunk<UserState>) =>
  bindActionCreators(
    {
      createAgIntegrationApi,
      deleteAgIntegrationApi,
      getAgIntegrationStatusApi,
      getAgIntegrationSettingsApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationsList);
