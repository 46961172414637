import React, { FC, useMemo } from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { IconButton, Collapse, TableCell, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import moment from 'moment';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { getTelematicsMachinesOpenedRows } from '../../selectors/telematicsMachines.selectors';

import { setOpenedMachineRows } from '../../actions/telematicsMachines.actions';

import { TELEMATICS } from '../../../core/map/constants/contexts.constants';
import { DUPLICATED_TAB_KEY } from '../../../core/map/constants/localStorage.constants';

import showOnMapIcon from '../../../assets/img/icons/telematics/showOnMap.svg';
import { MAP_CONTEXT_LS_KEY } from '../../../saga/mainMap.saga';
import CfFormattedNumber from '../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import TimeIntervalsBar from '../../../shared/components/misc/TimeIntervalsBar/TimeIntervalsBar';
import CfTableCell from '../../../shared/components/tables/CfTableCell/CfTableCell';
import LocalStorage from '../../../shared/services/LocalStorage.service';
import { Thunk } from '../../../types';
import { TelematicsNgProps } from '../../containers/Telematics/Telematics';
import { TABS } from '../../containers/TelematicsTabs/TelematicsTabs';
import { saveTelematicsTabToCache } from '../../containers/TelematicsTabs/useTelematicsLogger';
import { getDuration } from '../../helpers';

import MachineRidesList from './MachineRidesList';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { LogbookAggregatedMachineTo, MachineCategoryCode, MachineGroupCode, MachineTo } from '../../../shared/api/telematics/telematics.types';

interface Props extends TelematicsNgProps {
    data: LogbookAggregatedMachineTo;
    dateFilter: {
      dateFrom: string;
      dateTo: string;
    };
    logbookFilterHandler: (dateFrom: string, dateTo: string, machine: Record<'code'|'name'|'gpsUnit', string>) => void,
    openedRows: number[];
    rowId: number;
    setOpenedRows: (machineIds: number[]) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  collapsedRow: {
    height: 0,
  },
  collapsedCell: {
    padding: 0,
    borderBottom: 'none',
  },
  cellMultipleRowsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  timeGraph: {
    display: 'flex',
    alignItems: 'center',
    width: '48vw',
  },
  times: {
    marginRight: 10,
    whiteSpace: 'nowrap',
  },
  lastCelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  greyText: {
    color: theme.palette.grey[400],
  },
  withLeftOffset: {
    marginLeft: 16,
  },
  icons: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      flex: 1,
      paddingLeft: 16,
    },
    '& >:last-child': {
      marginLeft: 'auto',
    },
  },
}));

const MachineRow: FC<Props> = ({
  data,
  dateFilter,
  logbookFilterHandler,
  ngRedirectToMainMapWithFilters,
  openedRows,
  rowId,
  setOpenedRows,
}) => {
  const opened = openedRows?.includes(rowId) ?? false;
  const toggleOpened = () => {
    if (opened) {
      setOpenedRows(openedRows.filter((id) => id !== rowId));
    } else {
      setOpenedRows([...openedRows, rowId]);
    }
  };
  const classes = useStyles();
  const history = useHistory();
  const { farmId } = useParams<{farmId: string}>();
  const intl = useIntl();

  const timeIntervals = useMemo(() => data.times.map(({ dateFrom, dateTo }) => `${dateFrom}/${dateTo}`), [data.times]);

  const handleShowOnMapClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, openInNewPage?: boolean) => {
    e.stopPropagation();

    const machine: MachineTo = {
      validFrom: '', // required prop
      group: MachineGroupCode.TRUCK, // required prop
      category: MachineCategoryCode.AJE, // required prop
      ...data.machine,
    };

    if (openInNewPage) {
      LocalStorage.saveToLocalStorage({
        duplicatedTab: {
          filters: {
            machine,
            dateFrom: dateFilter.dateFrom,
            dateTo: dateFilter.dateTo,
          },
        },
      }, DUPLICATED_TAB_KEY);
    }

    // change map context before redirection
    LocalStorage.saveToLocalStorage(TELEMATICS, MAP_CONTEXT_LS_KEY);
    ngRedirectToMainMapWithFilters(dateFilter.dateFrom, dateFilter.dateTo, [machine], undefined, openInNewPage);
  };

  const handleShowOnMapMouseDown = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e.button === 1) {
      handleShowOnMapClick(e, true);
    }
  };

  const handleFilterInRidesClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, openInNewPage?: boolean) => {
    e.stopPropagation();
    let machineFilter = {
      name: intl.formatMessage({ id: 'TelematicsList.filter.withoutMachine' }),
      code: 'nomachine',
      gpsUnit: '',
    };

    if (data.machine) {
      machineFilter = {
        name: data.machine.name ?? '',
        code: data.machine.machineCode ?? '',
        gpsUnit: data.machine.gpsUnit ?? '',
      };
    }

    const urlToRedirect = `/farm/${farmId}/telematics/logbook`;

    if (openInNewPage) {
      LocalStorage.saveToLocalStorage({
        duplicatedTab: {
          filters: {
            machine: machineFilter,
            dateFrom: dateFilter.dateFrom,
            dateTo: dateFilter.dateTo,
          },
        },
      }, DUPLICATED_TAB_KEY);
      saveTelematicsTabToCache(TABS.LOGBOOK);
      window.open(urlToRedirect, '_blank');
    } else {
      logbookFilterHandler(dateFilter.dateFrom, dateFilter.dateTo, machineFilter);
      history.push(urlToRedirect);
    }
  };

  const handleFilterInRidesMouseDown = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e.button === 1) {
      handleFilterInRidesClick(e, true);
    }
  };

  return (
    <>
      <TableRow data-test={'wrapped-row'} onClick={toggleOpened}>
        <CfTableCell name="duration">
          <div className={classnames(classes.cellMultipleRowsContainer, classes.withLeftOffset)}>
            <div><FormattedDate value={data.dateFrom} /></div>
            <div className={classes.greyText}>{getDuration(data.duration)}</div>
          </div>
        </CfTableCell>
        <CfTableCell name="machine.name">
          <div className={classes.cellMultipleRowsContainer}>
            {!(!data.machine?.name && !data.machine?.licencePlate) && (
            <>
              <div>{data.machine?.name ?? '-'}</div>
              <div className={classes.greyText}>{data.machine?.licencePlate ?? '-'}</div>
            </>
            )}
            {(!data.machine?.name && !data.machine?.licencePlate) && (
              <div>{data.machine?.gpsUnit ?? '-'}</div>
            )}
          </div>
        </CfTableCell>
        <CfTableCell name="cultivated">
          <div>{data.cultivated ? <span><CfFormattedNumber decimalDigits={2} value={data.cultivated} />{' ha'}</span> : '-' }</div>
        </CfTableCell>
        <CfTableCell name="distance">
          <span><CfFormattedNumber decimalDigits={2} value={data.distance} /> {data.distance >= 0 ? ' km' : ''}</span>
        </CfTableCell>
        <CfTableCell name="time">
          <div className={classes.lastCelContainer}>
            <div className={classes.timeGraph}>
              <div className={classes.times}>{moment(data.dateFrom).format('HH:mm')}{' - '} {moment(data.dateTo).format('HH:mm')}</div>
              <TimeIntervalsBar
                datetimeEnd={moment(data.dateFrom).endOf('day').toISOString()}
                datetimeStart={moment(data.dateFrom).startOf('day').toISOString()}
                intervals={timeIntervals}
            />
            </div>
            <div className={classes.icons}>
              <Tooltip title={<FormattedMessage id="TelematicsAggregations.list.showOnMap" />}>
                <IconButton
                  aria-label="show on map"
                  onClick={handleShowOnMapClick}
                  onMouseDown={handleShowOnMapMouseDown}
                  >
                  <img alt="show on map" src={showOnMapIcon} />
                </IconButton>
              </Tooltip>
              <Tooltip title={<FormattedMessage id="TelematicsAggregations.list.filterInRides" />}>
                <IconButton
                  onClick={handleFilterInRidesClick}
                  onMouseDown={handleFilterInRidesMouseDown}
                  >
                  <FilterListIcon />
                </IconButton>
              </Tooltip>

              <IconButton
                aria-label="Toggle view"
                size="large">
                {opened ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </div>
          </div>

        </CfTableCell>
      </TableRow>
      <TableRow className={classes.collapsedRow}>
        <TableCell className={classes.collapsedCell} colSpan={5}>
          <Collapse in={opened}>
            {data.drives && <MachineRidesList data={data.drives} opened={opened} />}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  openedRows: getTelematicsMachinesOpenedRows(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) => bindActionCreators({
  setOpenedRows: setOpenedMachineRows,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MachineRow);
