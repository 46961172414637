import { createSelector } from 'reselect';

import { ActionsState } from '../../../../reducers/actions.reducer.types';
import { ParcelsState } from '../../../../reducers/parcels.reducer.types';
import { ReportsState } from '../../../../reducers/reports.reducer.types';

export const getZones = (state: ParcelsState | ActionsState | ReportsState) => state.api.zones.items;
export const getIsZonesFetching = (state: ParcelsState | ActionsState) => state.api.zones.isFetching;

export const getZonesStatistics = createSelector(
  getZones,
  (zones) => ({
    items: zones.map(zone => ({
      id: zone.id,
      name: zone.name,
      count: zone.parcelCount,
      totalArea: zone.totalArea,
    })),
    totalArea: zones.reduce((acc, obj) => acc + obj.totalArea, 0),
    count: zones.reduce((acc, obj) => acc + obj.parcelCount, 0),
  }),
);
