const PREFIX = 'AGROEVIDENCE_CATALOGUES';

export const GET_FERTILIZERS_REQUEST = `${PREFIX}/GET_FERTILIZERS_REQUEST`;
export const GET_FERTILIZERS_SUCCESS = `${PREFIX}/GET_FERTILIZERS_SUCCESS`;
export const GET_FERTILIZERS_ERROR = `${PREFIX}/GET_FERTILIZERS_ERROR`;

export const GET_FERTILIZERS_V2_REQUEST = `${PREFIX}/GET_FERTILIZERS_V2_REQUEST`;
export const GET_FERTILIZERS_V2_SUCCESS = `${PREFIX}/GET_FERTILIZERS_V2_SUCCESS`;
export const GET_FERTILIZERS_V2_ERROR = `${PREFIX}/GET_FERTILIZERS_V2_ERROR`;
export const RESET_FERTILIZERS = `${PREFIX}/RESET_FERTILIZERS`;

export const GET_FERTILIZER_V2_REQUEST = `${PREFIX}/GET_FERTILIZER_V2_REQUEST`;
export const GET_FERTILIZER_V2_SUCCESS = `${PREFIX}/GET_FERTILIZER_V2_SUCCESS`;
export const GET_FERTILIZER_V2_ERROR = `${PREFIX}/GET_FERTILIZER_V2_ERROR`;
export const RESET_FERTILIZER = `${PREFIX}/RESET_FERTILIZER`;

export const CREATE_FERTILIZER_V2_REQUEST = `${PREFIX}/CREATE_FERTILIZER_V2_REQUEST`;
export const CREATE_FERTILIZER_V2_SUCCESS = `${PREFIX}/CREATE_FERTILIZER_V2_SUCCESS`;
export const CREATE_FERTILIZER_V2_ERROR = `${PREFIX}/CREATE_FERTILIZER_V2_ERROR`;

export const UPDATE_FERTILIZER_V2_REQUEST = `${PREFIX}/UPDATE_FERTILIZER_V2_REQUEST`;
export const UPDATE_FERTILIZER_V2_SUCCESS = `${PREFIX}/UPDATE_FERTILIZER_V2_SUCCESS`;
export const UPDATE_FERTILIZER_V2_ERROR = `${PREFIX}/UPDATE_FERTILIZER_V2_ERROR`;

export const GET_FERTILIZERS_DOSE_REQUEST = `${PREFIX}/GET_FERTILIZERS_DOSE_REQUEST`;
export const GET_FERTILIZERS_DOSE_SUCCESS = `${PREFIX}/GET_FERTILIZERS_DOSE_SUCCESS`;
export const GET_FERTILIZERS_DOSE_ERROR = `${PREFIX}/GET_FERTILIZERS_DOSE_ERROR`;
export const RESET_FERTILIZERS_DOSE = `${PREFIX}/RESET_FERTILIZERS_DOSE`;
