const PREFIX = 'AGROEVIDENCE_REPORTS';

export const POST_FERTILIZER_USAGE_REPORT_REQUEST = `${PREFIX}/POST_FERTILIZER_USAGE_REPORT_REQUEST `;
export const POST_FERTILIZER_USAGE_REPORT_SUCCESS = `${PREFIX}/POST_FERTILIZER_USAGE_REPORT_SUCCESS`;
export const POST_FERTILIZER_USAGE_REPORT_ERROR = `${PREFIX}/POST_FERTILIZER_USAGE_REPORT_ERROR`;
export const POST_FERTILIZER_USAGE_REPORT_RESET = `${PREFIX}/POST_FERTILIZER_USAGE_REPORT_RESET`;
export const POST_POR_USAGE_REPORT_REQUEST = `${PREFIX}/POST_POR_USGAE_REPORT_REQUEST`;
export const POST_POR_USAGE_REPORT_SUCCESS = `${PREFIX}/POST_POR_USGAE_REPORT_SUCCESS`;
export const POST_POR_USAGE_REPORT_ERROR = `${PREFIX}/POST_POR_USGAE_REPORT_ERROR`;
export const POST_POR_USAGE_REPORT_RESET = `${PREFIX}/POST_POR_USGAE_REPORT_RESET`;
export const GET_POR_HISTORY_REPORT_SUCCESS = `${PREFIX}/GET_POR_HISTORY_REPORT_SUCCESS`;
export const GET_POR_HISTORY_REPORT_REQUEST = `${PREFIX}/GET_POR_HISTORY_REPORT_REQUEST`;
export const GET_POR_HISTORY_REPORT_ERROR = `${PREFIX}/GET_POR_HISTORY_REPORT_ERROR`;
