import React, { FC } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';

import CfAutosuggest from '../../../../../shared/components/common/CfAutosuggest/CfAutosuggest';

import { CropTo } from '../../../../../shared/api/agroevidence/agroevidence.types';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
  },
  suggestionsContainerOpen: {
    position: 'static',
    margin: 0,
    '-webkit-box-shadow': 'none',
    '-moz-box-shadow': 'none',
    'box-shadow': 'none',
  },
  suggestionsContainer: {
    boxShadow: 'none',
    '-webkit-box-shadow': 'none',
    '-moz-box-shadow': 'none',
    height: 'calc(100% - 52px)',
  },
  suggestionsList: {
    maxHeight: '200px',
  },
  suggestionItem: {
    overflow: 'inherit',
  },
  input: {
    padding: '15px 15px 5px 15px',
  },
  positionEnd: {
    right: '8px',
  },
}));

interface CropAutosuggestProps {
  getSuggestions: (params: string) => void,
  isFetching: boolean,
  onSuggestionClear: () => void,
  onSuggestionSelect: (cropToAssign: CropTo) => void,
  suggestions: (CropTo | { id: null; name: string })[]
}

const CropAutosuggest: FC<CropAutosuggestProps> = ({
  getSuggestions,
  onSuggestionClear,
  onSuggestionSelect,
  suggestions = [],
  isFetching,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const getSuggestionValue = (suggestion: CropTo) => suggestion.name;

  return (
    <CfAutosuggest
      alwaysRenderSuggestions={true}
      autoFocus={true}
      clearInputOnAdornmentClick={true}
      clearSuggestions={onSuggestionClear}
      disableUnderline={true}
      focusInputOnSuggestionClick={false}
      getSuggestions={getSuggestions}
      getSuggestionValue={getSuggestionValue}
      isDebounced={true}
      isFetching={isFetching}
      multiSection={false}
      onSuggestionSelected={onSuggestionSelect}
      placeholder={intl.formatMessage({ id: 'CropAutosuggest.enterCropName' })}
      requiredLength={3}
      scrollable={true}
      scrollHeightMax={200}
      suggestions={suggestions}
      testData="crop-selector"
      adornment={
        <IconButton disabled={true} size="large">
          <SearchIcon />
        </IconButton>
        }
      classes={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsContainer: classes.suggestionsContainer,
        suggestionsList: classes.suggestionsList,
        suggestionItem: classes.suggestionItem,
        input: classes.input,
        positionEnd: classes.positionEnd,
      }}
      />
  );
};

export default CropAutosuggest;
