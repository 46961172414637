import React, { FC } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getPrecisionParcelListTextFilter } from '../selectors/precisionParcelList.selectors';

import { NAMESPACE as namespace } from '../reducer/precisionParcelList.reducer';

import CfErrorPage from '../../../shared/components/common/CfErrorPage/CfErrorPage';
import CfTextFilter from '../../../shared/containers/CfTextFilter/CfTextFilter';
import { RsaaApiError, Thunk } from '../../../types';

import PrecisionServicesTable from './PrecisionServicesTable';
import { usePrecisionListStyles } from './styles';

import { PrecisionState } from '../../../reducers/precision.reducer.types';

export interface PrecisionServicesProps {
  error?: RsaaApiError;
  farmId: string;
  langId: string;
  textFilter: string;
}

export const PrecisionServices: FC<PrecisionServicesProps> = ({
  error,
  farmId,
  langId,
  textFilter,
}) => {
  const classes = usePrecisionListStyles();

  return (
    <CfErrorPage error={error}>
      <div className={classes.wrapper}>
        <div>
          <div className={classes.textFilter}>
            <CfTextFilter
              initialValue={textFilter}
              name="precision-list-text-filter"
              namespace={namespace}
              translId="PrecisionListTextFilter.placeholder"
                  />
          </div>
          <PrecisionServicesTable farmId={farmId} langId={langId} />
        </div>
      </div>
    </CfErrorPage>
  );
};

const mapStateToProps = (state: PrecisionState) => ({
  textFilter: getPrecisionParcelListTextFilter(state),
});

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) =>
  bindActionCreators(
    {
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PrecisionServices);
