import React, { ReactNode, CSSProperties } from 'react';

import { FormattedMessage } from 'react-intl';

import TCDoubleLinedHeader from '../components/telematics/shared/TCDoubleLinedHeader';

export type ColumnConfig = {
    id: string;
    primaryLabel: string;
    secondaryLabel?: string;
    sortable?: boolean;
    style?: CSSProperties;
}
export type ColumnData = {
    align: string;
    label: ReactNode;
    sortable: boolean;
    style?: CSSProperties;
}

export const getColDesc = (sortable: boolean, label: ReactNode, style?: CSSProperties): ColumnData => ({
  align: 'inherit',
  sortable,
  label,
  style,
});

export const createColumns = (config: ColumnConfig[]) => config.reduce((acc, col) => {
  acc[col.id] = getColDesc(
    !!col?.sortable,
    col.primaryLabel && col.secondaryLabel ?
      <TCDoubleLinedHeader primaryId={col.primaryLabel} secondaryId={col.secondaryLabel} /> :
      <FormattedMessage id={col.primaryLabel} />,
    col.style,
  );

  return acc;
}, {} as Record<string, ColumnData>);
