import React, { Component } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getIsFetching, getError, getTotalCount } from '../../../../../shared/api/agroevidence/actions/actions.selectors';
import {
  getPage,
  getRowsPerPage,
  getOrder,
  getOrderBy,
  getSelected,
  getSelectedOnPage,
  getProcessedActions,
  getTextFilter,
  getDateFilter,
  getDraftsOnly,
} from '../../selectors/actionList.selectors';

import { fetchActions } from '../../actions/actionList.actions';

import { NAMESPACE as namespace } from '../../reducer/actionList.reducer';

import CfTableBody from '../../../../../shared/components/tables/CfTableBody/CfTableBody';
import CfTableBodyEmpty from '../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty';
import CfTableBodyLoader from '../../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader';
import CfTableWrapper from '../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableFooter from '../../../../../shared/containers/CfTableFooter/CfTableFooter';
import CfTableHead from '../../../../../shared/containers/CfTableHead/CfTableHead';

const getColDesc = (align, sortable, label) => ({
  align,
  sortable,
  label,
});

const columns = {
  'dateStart,id': getColDesc('inherit', true, <FormattedMessage id="common.date" />),
  'actionType.localizedNames.value': getColDesc('inherit', true, <FormattedMessage id="common.action-name" />),
  source: getColDesc('inherit', false, <FormattedMessage id="common.source" />),
  landUse: getColDesc('inherit', false, <FormattedMessage id="common.crop" />),
  localName: getColDesc('inherit', false, <FormattedMessage id="common.parcel" />),
  material: getColDesc('inherit', false, <FormattedMessage id="common.material" />),
  dose: getColDesc('center', false, <FormattedMessage id="common.totalDose" />),
  dosePerHectare: getColDesc('center', false, <FormattedMessage id="common.dosePerHectare" />),
};

class ActionsTable extends Component {
  componentDidMount() {
    this.props.fetchActions();
  }

  componentDidUpdate(prevProps) {
    const oldProps = _.pick(prevProps, ['order', 'orderBy', 'page', 'rowsPerPage', 'textFilter', 'dateFilter', 'draftsOnly']);
    const newProps = _.pick(this.props, ['order', 'orderBy', 'page', 'rowsPerPage', 'textFilter', 'dateFilter', 'draftsOnly']);

    if (!_.isEqual(newProps, oldProps)) {
      this.props.fetchActions();
    }
  }

  render() {
    const {
      actions,
      count,
      isFetching,
      langId,
      ngGoToAction,
      order,
      orderBy,
      page,
      rowsPerPage,
      selected,
      selectedOnPage,
    } = this.props;

    return (
      <CfTableWrapper>
        <CfTableHead
          columns={columns}
          items={actions}
          namespace={namespace}
          order={order}
          orderBy={orderBy}
          selected={selected}
          selectedOnPage={selectedOnPage}
        />
        {isFetching && <CfTableBodyLoader columns={columns} />}

        {actions.length && !isFetching ? (
          actions.map(row => (
            // TODO temporary, fix this (row.isDraft)
            <CfTableBody
              columns={columns}
              hover={!row.isDraft}
              key={row.id}
              namespace={namespace}
              onClick={() => ngGoToAction(row.id, row.route)}
              row={row}
              selected={selected}
            />
          ))
        ) : (
          <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
        )}
        <CfTableFooter count={count} langId={langId} namespace={namespace} page={page} rowsPerPage={rowsPerPage} />
      </CfTableWrapper>
    );
  }
}

ActionsTable.propTypes = {
  langId: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  actions: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  selectedOnPage: PropTypes.array.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  textFilter: PropTypes.string.isRequired,
  fetchActions: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  dateFilter: PropTypes.any,
  ngGoToAction: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  draftsOnly: PropTypes.bool.isRequired,
};

ActionsTable.defaultProps = {
  dateFilter: null,
  history: {},
};

const mapStateToProps = (state, props) => ({
  actions: getProcessedActions(state, props),
  isFetching: getIsFetching(state),
  count: getTotalCount(state),
  error: getError(state),
  page: getPage(state),
  rowsPerPage: getRowsPerPage(state),
  order: getOrder(state),
  orderBy: getOrderBy(state),
  selected: getSelected(state),
  selectedOnPage: getSelectedOnPage(state),
  textFilter: getTextFilter(state),
  dateFilter: getDateFilter(state),
  draftsOnly: getDraftsOnly(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ActionsTable);
