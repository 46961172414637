export enum STATUSES {
    COMPLETE='Complete',
    ERROR='Error',
    PENDING='Pending',
    RUNNING='Running'
  }

export enum EXPORT_TYPES{
    UPLOADER='uploader',
    WRITER='writer'
}

export interface WriterJob {
    exportDate: string;
    jobId: number;
    name: string;
    status: STATUSES ;
  }

export interface UploaderJob extends WriterJob {
    parentId: number;
}

export interface MachineFrom {
    id: number;
    name: string;
}

export interface MachineTo {
    machines: MachineFrom[]
}
