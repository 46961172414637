import React, { ChangeEvent, FC, useState } from 'react';

import { Grid, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';

import CfDialog from '../../../shared/components/common/CfDialog/CfDialog';
import * as validators from '../../../shared/misc/validators';

const useStyles = makeStyles((theme: Theme) => ({
  itemContainer: {
    minWidth: '300px',
    padding: '5px 0px',
  },
  textField: {
    display: 'flex',
    justifyContent: 'left',
    marginBottom: 20,
    color: theme.palette.grey[500],
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '21px',
  },
}
));

interface Props {
  onAccept: (name: string, email: string) => void,
  onClose: () => void,
  opened?: boolean
}

const AgActivationDialog: FC<Props> = ({
  onAccept,
  onClose,
  opened = false,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailFormatError, setEmailFormatError] = useState<boolean>(false);

  const onChangeName = (evt: ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    setName(evt.target.value);
  };

  const onChangeEmail = (evt: ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    setEmailFormatError(false);
    setEmail(evt.target.value);
  };

  const handleAccept = () => {
    const emailNotValid = !!validators.email(email);
    setEmailFormatError(emailNotValid);
    if (emailNotValid) return;
    onAccept(name, email);
  };

  return (
    <CfDialog
      acceptText={<FormattedMessage id="Agi.activationDialog.acceptBtn" />}
      cancelText={<FormattedMessage id="common.cancel" />}
      onAccept={handleAccept}
      onCancel={onClose}
      onClose={onClose}
      opened={opened}
      title={<FormattedMessage id="Agi.activationDialog.title" />}
      >
      <Grid className={classes.itemContainer}>
        <TextField
          className={classes.textField}
          label={<FormattedMessage id="Agi.activationDialog.name" />}
          name="agiName"
          onChange={onChangeName}
          placeholder={intl.formatMessage({ id: 'Agi.activationDialog.name' })}
          value={name}
          variant="standard"
          />
        <TextField
          className={classes.textField}
          error={emailFormatError}
          helperText={emailFormatError ? <FormattedMessage id="validation.email" /> : undefined}
          label={<FormattedMessage id="Agi.activationDialog.email" />}
          name="agiEmail"
          onChange={onChangeEmail}
          placeholder={intl.formatMessage({ id: 'Agi.activationDialog.email' })}
          value={email}
          variant="standard"
          />
      </Grid>
    </CfDialog>
  );
};

export default AgActivationDialog;
