import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './management.constants';

import { RsaaTypes } from '../../api.types';

export const getManagementZones = (parcelId: string) => ({
  [RSAA]: {
    endpoint: `history-potential/${parcelId}?`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.GET_MANAGEMENT_ZONES,
      types.GET_MANAGEMENT_ZONES_SUCCESS,
      types.GET_MANAGEMENT_ZONES_ERROR,
    ] as RsaaTypes,
  },
});

export const activateManagementZones = (parcelId: string) => ({
  [RSAA]: {
    endpoint: `history-potential/${parcelId}?`,
    method: methods.POST,
    module: modules.SENTINEL,
    types: [
      types.ACTIVATE_MANAGEMENT_ZONES,
      types.ACTIVATE_MANAGEMENT_ZONES_SUCCESS,
      types.ACTIVATE_MANAGEMENT_ZONES_ERROR,
    ],
  },
});

export const resetManagementZones = () => ({
  type: types.RESET_MANAGEMENT_ZONES,
});
