import { RsaaApiError } from '../../../types';
import { TABS } from '../../containers/TelematicsTabs/TelematicsTabs';

type Props = {
  driversAggregatedExportError: RsaaApiError;
  exportAggregatedDrives: () => void;
  exportAggregatedMachinesDrives: () => void;
  exportDrives: () => void;
  isExportingDriversAggregated: boolean;
  isExportingMachinesAggregated: boolean;
  machinesAggregatedExportError: RsaaApiError;
  selectedTelematicsTab: TABS;
}

export function useTelematicsExportState({
  driversAggregatedExportError, exportAggregatedDrives, exportAggregatedMachinesDrives,
  exportDrives, isExportingDriversAggregated, isExportingMachinesAggregated,
  machinesAggregatedExportError, selectedTelematicsTab,
}: Props) {
  return {
    tabExportError: selectedTelematicsTab === TABS.DRIVERS
      ? driversAggregatedExportError : machinesAggregatedExportError,
    tabIsExporting: selectedTelematicsTab === TABS.DRIVERS ?
      isExportingDriversAggregated : isExportingMachinesAggregated,
    // eslint-disable-next-line no-nested-ternary
    exportTabDrives: selectedTelematicsTab === TABS.DRIVERS ?
      exportAggregatedDrives : selectedTelematicsTab === TABS.MACHINE ?
        exportAggregatedMachinesDrives : exportDrives,
  };
}
