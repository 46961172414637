import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './actions.constants';

export const getActionByTransactionId = transactionId => {
  const params = {
    store_transaction_id: transactionId,
  };

  return {
    [RSAA]: {
      endpoint: `actions?${queryString.stringify(params)}&`,
      method: methods.GET,
      module: modules.CORE,
      types: [
        types.GET_ACTION_BY_TRANSACTION_ID,
        types.GET_ACTION_BY_TRANSACTION_ID_SUCCESS,
        types.GET_ACTION_BY_TRANSACTION_ID_ERROR,
      ],
    },
  };
};
