export const mapRequestBodyParcelsSubtractionOtherAction = (parcels) =>
  // actions that use Formik
  ({
    restrictions: parcels.map(parcel => ({
      parcelId: parcel.id,
      restrictions: [
        ...mapRestrictions(parcel.subtractableAreas.absolute),
        ...mapRestrictions(parcel.subtractableAreas.boundary),
        ...mapRestrictions(parcel.subtractableAreas.water),
      ],
    })),
    materials: [],
  });

export const mapRequestBodyParcelsSubtraction = (parcels, expenses, targetCrop) =>
  // actions that use reduxForm
  ({
    restrictions: parcels.map(parcel => ({
      parcelId: parcel.id,
      restrictions: [
        ...mapRestrictions(parcel.subtractableAreas),
      ],
    })),
    materials: expenses ? mapMaterials(expenses, targetCrop) : [],
  });

const mapRestrictions = (data) => data.map(({ isMaterial, isUsed, type, value }) =>
  ({ type, value, isMaterial, isUsed }));

const mapMaterials = (expenses, targetCrop) => expenses.map(expens => ({
  plantProtectionId: expens.material.id,
  targetOrganismIds: expens.pests.map(pest => pest.id),
  targetCropId: targetCrop.id,
  driftClass: expens.driftClass,
}));

export const mapRequestBodyParcelSubtractionsGeometry = (parcelId, subtractableAreas) =>
  ({
    parcelId,
    restrictions: [
      ...subtractableAreas.filter(sa => sa.isUsed === true).map(({ type, value }) => ({ type, value })),
    ],
  });
