import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getAction,
  getError,
  getIsFetching,
} from '../../../../../shared/api/agroevidence/actions/actions.selectors';
import { getVariableActionIds as getVariableActionIdsSelector } from '../../../../../shared/api/sentinel/variableApplication/variableApplication.selectors';

import { getActionApi, postActionSplit } from '../../../../../shared/api/agroevidence/actions/actions.api';
import {
  getVariableActionIds,
  resetVariableActionIds,
} from '../../../../../shared/api/sentinel/variableApplication/variableApplication.api';
import { getActionRelatedDrivesApi } from '../../../../../shared/api/telematics/aggregations/aggregations.api';
import CfErrorPage from '../../../../../shared/components/common/CfErrorPage/CfErrorPage';
import CfLoaderPage from '../../../../../shared/components/common/CfLoaderPage/CfLoaderPage';
import { SnackbarContext } from '../../../../../shared/containers/SnackbarProvider/SnackbarProvider';
import Eph from '../../../eph/containers/Eph/Eph';
import { isEph } from '../../../eph/misc/eph.helpers';
import ActionOtherDetail from '../../../others/containers/ActionOtherDetail/ActionOtherDetail';
import { getFormType, isOtherAction } from '../../../others/helpers/others.helpers';
import Vrf from '../../../vrf/containers/Vrf/Vrf';
import { isVrf } from '../../../vrf/helpers/vrf.helpers';
import Vrs from '../../../vrs/containers/Vrs/Vrs';
import { isVrs } from '../../../vrs/helpers/vrs.helpers';
import { getRouting } from '../../misc/action.helpers';

export const ActionDetailContext = React.createContext();

export class ActionDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedItems: {},
      isSplitting: false,
      numberOfCheckedItems: 0,
    };
  }

  handleCheckboxChange = (fieldId, checked) => {
    this.setState(prevState => ({
      checkedItems: {
        ...prevState.checkedItems,
        [fieldId]: checked,
      },
    }));
  };

  handleStartIsSplitting = () => {
    this.setState({ isSplitting: true });
  };

  handleCancelIsSplitting = () => {
    this.setState({ isSplitting: false });
  };

  handleResetState = () => {
    this.setState({
      checkedItems: {},
      isSplitting: false,
      numberOfCheckedItems: 0,
    });
  };

  componentDidMount() {
    this.props.getVariableActionIds().then(() => {
      this.props.getActionApi(this.props.match.params.actionId);
      this.props.getActionRelatedDrivesApi(this.props.match.params.actionId);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const checkedItems = Object.keys(this.state.checkedItems).filter(id => this.state.checkedItems[id]).length;
    if (prevState.numberOfCheckedItems !== checkedItems) {
      this.setState({ numberOfCheckedItems: checkedItems });
    }

    const { action: newAction, variableActionIds: newVariableActionIds } = this.props;

    if (
      prevProps?.action?.parcels?.length !== this.props.action?.parcels?.length ||
      (Array.isArray(newVariableActionIds) &&
      newAction &&
      (
        !prevProps.action ||
        newAction.id !== prevProps.action.id
      ))
    ) {
      if (
        !isEph(newAction, newVariableActionIds) &&
        !isVrf(newAction, newVariableActionIds) &&
        !isVrs(newAction, newVariableActionIds)
      ) {
        const route = getRouting(newAction, newVariableActionIds);
        this.props.ngGoToAction(newAction.id, route);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetVariableActionIds();
  }

  splitActionHandler = async () => {
    const { formatMessage } = this.props.intl;
    try {
      await this.props.postActionSplit({
        actionId: this.props.match.params.actionId,
        parcelsToSplit: Object.keys(this.state.checkedItems).filter(id => this.state.checkedItems[id]),
      });
      this.context({ message: formatMessage({ id: 'splitAction.snackbar.success' }), isSuccess: true });
    } catch (e) {
      this.context({ message: formatMessage({ id: 'splitAction.snackbar.error' }), isError: true });
    } finally {
      this.props.getActionApi(this.props.match.params.actionId);
      this.props.getActionRelatedDrivesApi(this.props.match.params.actionId);
      this.handleResetState();
    }
  };

  render() {
    const {
      action,
      actionError,
      farmId,
      history,
      isFetching,
      langId,
      match,
      ngGoToActions,
      ngGoToTelematicDrive,
      variableActionIds,
    } = this.props;

    if (!Object.keys(action) || isFetching) return <CfLoaderPage />;

    const isEphAction = isEph(action, variableActionIds);
    const isVrfAction = isVrf(action, variableActionIds);
    const isVrsAction = isVrs(action, variableActionIds);
    const isNeitherAction = !isEphAction && !isVrfAction && !isVrsAction;

    return (
      <CfErrorPage
        error={actionError}
        error40xHeadingTranslId={'error.heading.actionDetail'}
        error40xMessageTranslId={'error.message.actionDetail'}
        handle400s
      >
        {/* context to avoid unneccessary props drilling. Add new props here if needed */}
        <ActionDetailContext.Provider value={{
          match,
          ngGoToTelematicDrive,
          farmId,
          langId,
          checkedItems: this.state.checkedItems,
          isSplitting: this.state.isSplitting,
          numberOfCheckedItems: this.state.numberOfCheckedItems,
          handleCheckboxChange: this.handleCheckboxChange,
          handleStartIsSplitting: this.handleStartIsSplitting,
          handleCancelIsSplitting: this.handleCancelIsSplitting,
          splitActionHandler: this.splitActionHandler,
        }}>
          {isEphAction && (
          <Eph
            ngGoToActions={ngGoToActions}
            {...this.props}
            match={match}
            existingAction={{
              ...action,
              isEph: true,
            }}
          />
          )}
          {isVrfAction && (
          <Vrf
            history={history}
            {...this.props}
            match={match}
            existingAction={{
              ...action,
              isVrf: true,
            }}
          />
          )}
          {isVrsAction && (
          <Vrs
            history={history}
            {...this.props}
            match={match}
            existingAction={{
              ...action,
              isVrs: true,
            }}
          />
          )}
          {isNeitherAction && isOtherAction(action) && (
          <ActionOtherDetail
            history={history}
            {...this.props}
            existingAction={action}
            formType={getFormType(action)}
            isExisting={Boolean(action)}
            match={match}
          />
          )}
        </ActionDetailContext.Provider>
      </CfErrorPage>
    );
  }
}

ActionDetail.contextType = SnackbarContext;

ActionDetail.propTypes = {
  getActionApi: PropTypes.func.isRequired,
  match: PropTypes.object,
  action: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  classes: PropTypes.object,
  ngGoToAction: PropTypes.func.isRequired,
  ngGoToActions: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  getVariableActionIds: PropTypes.func.isRequired,
  resetVariableActionIds: PropTypes.func.isRequired,
  variableActionIds: PropTypes.array,
  actionError: PropTypes.object.isRequired,
  getActionRelatedDrivesApi: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

ActionDetail.defaultProps = {
  match: {},
  action: {},
  classes: {},
  variableActionIds: null,
};

const mapStateToProps = state => ({
  action: getAction(state),
  actionError: getError(state),
  isFetching: getIsFetching(state),
  variableActionIds: getVariableActionIdsSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      postActionSplit,
      getActionApi,
      getVariableActionIds,
      resetVariableActionIds,
      getActionRelatedDrivesApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ActionDetail));
