export interface ArrayProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: string | number | any ;
}

export const sortArray = (sortOption: string, sortBy: string, array: ArrayProps[]) =>
  array.sort((a, b) => {
    const A = a[sortBy];
    const B = b[sortBy];
    if (typeof A === 'number' && typeof B === 'number') {
      return sortOption === 'desc' ? (B) - (A) : (A) - (B);
    }
    if (typeof A === 'string' && typeof B === 'string') {
      return sortOption === 'desc' ? (B).localeCompare(A) : (A).localeCompare(B);
    }
    throw new Error('Elemnt in column are not of the same type');
  });

export const paginationArray = (page: number, rowsPerPage: number, array: ArrayProps[]) => {
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  return array.slice(startIndex, endIndex);
};
