import React, { Component, Fragment } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';

import { getMaterialIds } from '../selectors/actions.selectors';

import ListSelectorError from '../../../../shared/components/form/ListSelectorError/ListSelectorError';
import { getDisplayName } from '../../../../shared/hocs/hocHelpers';
import withOpen from '../../../../shared/hocs/withOpen';
import { COLOR_GREY } from '../../../../theme';

const styles = {
  materialsLabel: {
    color: COLOR_GREY[500],
  },
};

const actionExpenseControl = () => WrappedComponent => {
  class ActionExpenseControl extends Component {
    constructor(props) {
      super(props);

      this.state = {
        index: undefined,
        isAdding: true,
        expense: null,
        // switch to rerender list when splice is used, solves redux-form bug
        listRerenderSwitch: false,
      };
    }

    onSuggestionSelected = expense => {
      const { materialIds } = this.props;

      if (!materialIds.includes(expense.material.id)) {
        this.setState({
          index: undefined,
          isAdding: true,
          expense,
        });
        this.props.onOpen();
      }
    };

    handleDialogOpen = (field, index) => {
      this.setState({
        index,
        isAdding: false,
        expense: field,
      });
      this.props.onOpen();
    };

    handleDialogClose = () => {
      this.setState({
        index: undefined,
        isAdding: false,
        expense: null,
      });
      this.props.onClose();
    };

    handleDialogAccept = expense => {
      const { fields } = this.props;
      const { index, isAdding } = this.state;

      this.handleDialogClose();
      if (isAdding) {
        fields.push(expense);
        return;
      }

      this.setState({
        index: undefined,
        listRerenderSwitch: !this.state.listRerenderSwitch,
      });

      fields.remove(index);
      fields.insert(index, expense);
    };

    handleItemRemove = index => {
      this.props.fields.remove(index);
    };

    render() {
      const {
        SelectorProps: { component: Selector, ...restSelectorProps },
        classes,
        fields,
        formName,
        isEditing,
        isOpen,
        maxExpenseCount,
        meta: { error, submitFailed },
        onOpen,
        ...restProps
      } = this.props;

      const { expense, isAdding, listRerenderSwitch } = this.state;

      return (
        <Fragment>
          {isEditing && (maxExpenseCount ? fields.length < maxExpenseCount : true) ? (
            <Fragment>
              <Selector onSuggestionSelected={this.onSuggestionSelected} {...restSelectorProps} />
              {submitFailed && error && <ListSelectorError error={error} />}
            </Fragment>
          ) : (
            <div className={classes.materialsLabel}>
              <FormattedMessage id="common.materials" />:
            </div>
          )}
          <WrappedComponent
            expense={expense}
            fields={fields}
            formName={formName}
            isAdding={isAdding}
            isEditing={isEditing}
            isOpen={isOpen}
            listRerenderSwitch={listRerenderSwitch}
            onAccept={this.handleDialogAccept}
            onClose={this.handleDialogClose}
            onOpen={this.handleDialogOpen}
            onRemove={this.handleItemRemove}
            {...restProps}
          />
        </Fragment>
      );
    }
  }

  const mapStateToProps = (state, props) => ({
    materialIds: getMaterialIds(props.formName, state),
  });

  ActionExpenseControl.propTypes = {
    classes: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
    meta: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    formName: PropTypes.string.isRequired,
    materialIds: PropTypes.array.isRequired,
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    maxExpenseCount: PropTypes.number,
    SelectorProps: PropTypes.shape({
      component: PropTypes.object.isRequired,
      placeholder: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  };

  ActionExpenseControl.defaultProps = {
    maxExpenseCount: null,
    SelectorProps: {
      disabled: false,
    },
  };

  ActionExpenseControl.displayName = `ActionExpenseControl(${getDisplayName(WrappedComponent)})`;

  return compose(
    connect(mapStateToProps),
    withOpen,
    withStyles(styles),
  )(ActionExpenseControl);
};

export default actionExpenseControl;
