import { AnyAction } from 'redux';

import * as types from './machines.constants';

import { MachinesStateApi } from './machines.types';

export const NAMESPACE = 'telematics-machines-api';

const initialState: MachinesStateApi = {
  machineAggregations: null,
  isFetchingMachineAggregations: false,
  machineAggregationsCount: 0,
  driveDetail: null,
  isFetchingDriveDetail: false,
  apiError: null,
  apiErrorTriggeredBy: '',
  exportError: null,
  isExportingData: false,
};

export default () => (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.GET_MACHINE_AGGREGATIONS_REQUEST:
      return {
        ...state,
        isFetchingMachineAggregations: !action.payload.refetch,
        apiError: null,
        apiErrorTriggeredBy: '',
      };

    case types.GET_MACHINE_AGGREGATIONS_SUCCESS:
      return {
        ...state,
        machineAggregations: action.payload,
        machineAggregationsCount: Number(action.meta.headers.get('X-Total-Count')),
        isFetchingMachineAggregations: false,
      };

    case types.GET_MACHINE_AGGREGATIONS_ERROR:
      return {
        ...state,
        isFetchingMachineAggregations: false,
        apiError: action.payload,
        apiErrorTriggeredBy: action.type,
      };
    case types.RESET_API_ERROR:
      return {
        ...state,
        apiError: null,
        apiErrorTriggeredBy: '',
      };

    case types.EXPORT_AGGREGATED_MACHINES_REQUEST:
      return {
        ...state,
        isExportingData: true,
        exportError: null,
      };
    case types.EXPORT_AGGREGATED_MACHINES_SUCCESS:
      return {
        ...state,
        isExportingData: false,
        exportError: null,
      };
    case types.EXPORT_AGGREGATED_MACHINES_ERROR:
      return {
        ...state,
        isExportingData: false,
        exportError: action.payload,
      };
    case types.RESET_EXPORT_ERROR:
      return {
        ...state,
        exportError: null,
      };

    default:
      return state;
  }
};
