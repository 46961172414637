import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { COLOR_FONT } from '../../../../../theme';

const FIELD_MARGIN = 16;

export default makeStyles((theme: Theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    '& label[data-shrink="true"]:not(.Mui-focused), & label[data-shrink="false"]': {
      color: theme.palette.grey[500],
    },
  },
  farmInfo: {
    height: 'fit-content',
    alignSelf: 'center',
    fontSize: 13,
    marginRight: 'auto',
  },
  farmIdLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.grey[500],
    margin: 0,
  },
  farmId: {
    color: COLOR_FONT.main,
    fontSize: 16,
    margin: 0,
  },
  field: {
    marginRight: FIELD_MARGIN,
    '& .MuiInput-root': {
      fontSize: '16px',
    },
  },
}));
