import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Theme } from '@mui/material';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.common.white,
    flexShrink: 0,
    marginRight: theme.spacing(2),
  },
}));

type Props = {
  title: NonNullable<React.ReactNode>;
}

type FabProps = Pick<React.ComponentProps<typeof Fab>, 'onClick'> ;

const AddButton: React.FC<Props & FabProps> = ({
  onClick, title,
}) => {
  const classes = useStyles();

  return (
    <Tooltip title={title}>
      <Fab
        aria-label="add rides telematics"
        className={classes.button}
        color="secondary"
        disabled={false}
        onClick={onClick}
        size="medium"
        >
        <AddIcon />
      </Fab>
    </Tooltip>
  );
};

export default AddButton;
