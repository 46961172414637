import React, { Component } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import CfDatePicker from '../CfDatePicker/CfDatePicker';

const styles = {
  wrapper: {
    position: 'relative',
    fontWeight: 400,
    display: 'flex',
    flexDirection: 'column',
  },
};

class CfDateFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: null,
      error: false,
    };
  }

  componentDidMount() {
    const momentValue = moment(this.props.value);
    if (typeof this.props.value !== 'undefined' && momentValue.isValid() && !momentValue.isSame(this.state.value)) {
      this.setState({
        value: momentValue,
      });
    }
  }

  componentDidUpdate(prevProp) {
    const { value } = this.props;
    const momentValue = moment(value);
    // adding ability to control state value from outside
    if (typeof this.props.value !== 'undefined' && momentValue.isValid() && !momentValue.isSame(this.state.value) && !momentValue.isSame(prevProp.value)) {
      this.setState({
        value: momentValue,
      });
    }
  }

  onInputChange = value => {
    this.setState({
      value,
    });

    if (value === null || (moment.isMoment(value) && value.isValid())) {
      this.setState({
        error: false,
      });
      this.props.onFilterChange(value);
    } else {
      this.setState({
        error: true,
      });
    }
  };

  render() {
    const { label, name, testId } = this.props;

    return (
      <div style={styles.wrapper}>
        <CfDatePicker
          data-test={testId}
          error={this.state.error}
          label={label}
          propagateInvalidDate
          input={{
            value: this.state.value,
            onChange: this.onInputChange,
            name,
          }}
        />
      </div>
    );
  }
}

CfDateFilter.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onFilterChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  testId: PropTypes.string,
};

CfDateFilter.defaultProps = {
  name: 'cf-date-filter',
  label: '',
  value: '',
  testId: 'date-filter',
};

export default injectIntl(CfDateFilter);
