import React, { FC } from 'react';

import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import CfFormattedNumber from '../CfFormattedNumber/CfFormattedNumber';

import { ActionExpenseOverviewTo } from '../../../api/agroevidence/agroevidence.types';

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
  },
  amount: {
    width: '80%',
    textAlign: 'right',
  },
  unit: {
    textAlign: 'left',
    paddingLeft: 3,
    width: '20%',
  },
}));

export interface FormattedAmountProps {
  expense: ActionExpenseOverviewTo,
  param: string,
  perHa: boolean
}

export const FormattedAmount: FC<FormattedAmountProps> = ({
  expense,
  param,
  perHa = false,
}) => {
  const classes = useStyles();
  return (expense && expense.unit ?
    (<span className={classes.row}>
      <span className={classes.amount} style={perHa ? { width: '60%' } : {}}>
        <CfFormattedNumber value={expense[param as keyof ActionExpenseOverviewTo] as number} />
      </span>
      <span className={classes.unit} style={perHa ? { width: '40%' } : {}}>
        <FormattedMessage id={`unit.${expense.unit.toLowerCase()}`} />
        {perHa && '/ha'}
      </span>
    </span>) :
    (<span className={classes.row}>
      <span className={classes.amount}>
        {'-'}
      </span>
    </span>)
  );
};

export default FormattedAmount;
