import React, { FC, useEffect, useState } from 'react';

import { Divider, List } from '@mui/material';
import { makeStyles } from '@mui/styles';

import MainMapTelematicsMachineSelectorGroupItem from './MainMapTelematicsMachineSelectorGroupItem';

import { MachineGroupTo, MachineTo } from '../../../../shared/api/telematics/telematics.types';

const useStyles = makeStyles(() => ({
  list: {
    padding: 0,
  },
}));

interface MachinesByGroup {
    [index: string]: MachineTo[];
  }

interface MainMapTelematicsMachineSelectorGroupProps {
  machinesByGroup: MachinesByGroup,
  machinesGroups: MachineGroupTo[];
  onChangeMachineFilter(items: MachineTo[], checked: boolean): void;
  selectedValues: MachineTo[];
}

const MainMapTelematicsMachineSelectorGroup: FC<MainMapTelematicsMachineSelectorGroupProps> = ({
  machinesByGroup,
  machinesGroups,
  onChangeMachineFilter,
  selectedValues,
}) => {
  const classes = useStyles();

  const [openGroupState, setOpenGroupState] = useState<boolean[]>([]);

  useEffect(() => {
    setOpenGroupState(new Array(machinesGroups.length).fill(false));
  }, [machinesGroups.length]);

  const handleOpenGroup = (position: number) => {
    const updatedOpenGroupState = openGroupState.map((item, index) =>
      (index === position ? !item : false),
    );
    setOpenGroupState(updatedOpenGroupState);
  };

  return (
    <List
      aria-labelledby="machines-filter"
      className={classes.list}
      dense
      >
      <Divider />
      {machinesGroups.map(({ code }, index) => {
        const groupName = machinesGroups.find(item => item.code === code)?.value || '';
        return (
          <MainMapTelematicsMachineSelectorGroupItem
            group={code}
            groupName={groupName}
            handleOpenGroup={() => handleOpenGroup(index)}
            key={code}
            machinesInGroup={machinesByGroup[code]}
            onChangeMachineFilter={onChangeMachineFilter}
            openGroupState={openGroupState[index]}
            selectedValues={selectedValues}
          />);
      })}
    </List>
  );
};

export default MainMapTelematicsMachineSelectorGroup;
