import React, { CSSProperties, FC, ReactNode, useEffect } from 'react';

import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getIsFetching,
  getItems,
  getTotalCount,
} from '../../../shared/api/telematics/drivesImportHistory/drivesImportHistory.selectors';
import { getPage, getRowsPerPage } from '../../selectors/drivesImportHistory.selectors';

import { fetchImportHistory, resetImportHistory } from '../../actions/drivesImportHistory.actions';

import { NAMESPACE as namespace } from '../../reducer/drivesImportHistory.reducer';

import ErrorOutlined from '../../../assets/img/icons/telematics/error-outlined.svg';
import ScheduleOutlined from '../../../assets/img/icons/telematics/schedule-outlined.svg';
import Success from '../../../assets/img/icons/telematics/success.svg';
import { GetImportHistoryApiParams } from '../../../shared/api/telematics/drivesImportHistory/drivesImportHistory.api';
import CfTableBodyEmpty from '../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty';
import CfTableBodyLoader from '../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader';
import CfTableCell from '../../../shared/components/tables/CfTableCell/CfTableCell';
import CfTableWrapper from '../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableFooter from '../../../shared/containers/CfTableFooter/CfTableFooter';
import CfTableHead from '../../../shared/containers/CfTableHead/CfTableHead';
import { getShortDateString } from '../../../shared/misc/timeHelpers';
import { Thunk } from '../../../types';

import { DriveImportTo } from '../../../shared/api/telematics/telematics.types';
import { DrivesImportHistoryReducer } from '../../reducer/drivesImportHistory.types';

const getColDesc = (sortable: boolean, label: ReactNode, style?: CSSProperties, rightPadding?: boolean) => ({
  align: 'inherit',
  sortable,
  label,
  style,
  rightPadding,
});

const columns = {
  status: getColDesc(false, <FormattedMessage id="TelematicsAggregations.drivesImportHistory.table.column.state" />, {
    paddingLeft: 21,
  }),
  started: getColDesc(
    false,
    <FormattedMessage id="TelematicsAggregations.drivesImportHistory.table.column.importDate" />,
    { textAlign: 'left', color: 'black', fontWeight: 500 },
  ),
  startedTime: getColDesc(
    false,
    <FormattedMessage id="TelematicsAggregations.drivesImportHistory.table.column.startTime" />,
    { textAlign: 'left' },
  ),
  finished: getColDesc(
    false,
    <FormattedMessage id="TelematicsAggregations.drivesImportHistory.table.column.endTime" />,
    { textAlign: 'left' },
  ),
  source: getColDesc(
    false,
    <FormattedMessage id="TelematicsAggregations.drivesImportHistory.table.column.dataSource" />,
    { textAlign: 'left' },
  ),
};

const useStyles = makeStyles(() => ({
  start: {
    fontWeight: 500,
  },
  state: {
    paddingLeft: 15,
  },
  paper: {
    marginTop: '10px',
    boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.20)',
  },
  tooltip: {
    maxWidth: 340,
  },
}));

const renderStateIcon = (state?: string) => {
  if (!state) {
    return <></>;
  } else if (state === 'STARTED') {
    return <img alt="started" src={ScheduleOutlined} />;
  } else if (state === 'ERROR') {
    return <img alt="error" src={ErrorOutlined} />;
  } else if (state === 'COMPLETED') {
    return <img alt="success" src={Success} />;
  } else return <img alt="" src={ScheduleOutlined} />;
};

export interface DrivesImportHistoryTableProps {
  count: number;
  fetchImportHistory: (params: GetImportHistoryApiParams) => void;
  isFetching: boolean;
  items: DriveImportTo[];
  langId: string;
  page: number;
  resetImportHistory: () => void;
  rowsPerPage: number;
}

export const DrivesImportHistoryTable: FC<DrivesImportHistoryTableProps> = ({
  count,
  fetchImportHistory,
  isFetching,
  items,
  langId,
  page,
  resetImportHistory,
  rowsPerPage,
}) => {
  const classes = useStyles();

  useEffect(() => {
    fetchImportHistory({ language: langId, page: page + 1, 'per-page': rowsPerPage });
    return () => {
      resetImportHistory();
    };
  }, [page, rowsPerPage, fetchImportHistory, resetImportHistory, langId]);

  const renderDate = (dateStr: string | undefined, format: string) => {
    if (dateStr) {
      return getShortDateString(dateStr, format);
    }
    return undefined;
  };

  return (
    <CfTableWrapper classes={{ paper: classes.paper }} testId="drives-import-history">
      <CfTableHead columns={columns} items={items} langId={langId} namespace={namespace} />
      {isFetching && <CfTableBodyLoader columns={columns} />}

      {items.length ? (
        <TableBody>
          {items.map(item => (
            <TableRow key={item.id}>
              <CfTableCell name={'status'}>
                <span className={classes.state}>
                  {
                    <Tooltip
                      classes={{ tooltip: classes.tooltip }}
                      placement="bottom"
                      // eslint-disable-next-line max-len
                      title={
                        <FormattedMessage
                          id={`TelematicsAggregations.drivesImportHistory.table.state.${item.state?.toLowerCase()}`}
                        />
                      }
                    >
                      {renderStateIcon(item?.state)}
                    </Tooltip>
                  }
                </span>
              </CfTableCell>
              <CfTableCell name={'started'}>
                <span className={classes.start}>{renderDate(item?.started, 'D. M. YYYY') ?? '-'}</span>
              </CfTableCell>
              <CfTableCell name={'startedTime'}>
                <span>{renderDate(item?.started, 'HH:mm:ss') ?? '-'}</span>
              </CfTableCell>
              <CfTableCell name={'finished'}>
                <span>{renderDate(item?.finished, 'HH:mm:ss') ?? '-'}</span>
              </CfTableCell>
              <CfTableCell name={'source'}>
                <span>{item?.source}</span>
              </CfTableCell>
            </TableRow>
          ))}
        </TableBody>
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      )}
      <CfTableFooter count={count} namespace={namespace} page={page} rowsPerPage={rowsPerPage} />
    </CfTableWrapper>
  );
};

const mapStateToProps = (state: DrivesImportHistoryReducer) => ({
  page: getPage(state),
  rowsPerPage: getRowsPerPage(state),
  items: getItems(state),
  isFetching: getIsFetching(state),
  count: getTotalCount(state),
});

const mapDispatchToProps = (dispatch: Thunk<DrivesImportHistoryReducer>) =>
  bindActionCreators(
    {
      fetchImportHistory,
      resetImportHistory,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DrivesImportHistoryTable);
