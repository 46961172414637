const PREFIX = 'AGROEVIDENCE_ZONES';

export const GET_ZONES_REQUEST = `${PREFIX}/GET_ZONES_REQUEST`;
export const GET_ZONES_SUCCESS = `${PREFIX}/GET_ZONES_SUCCESS`;
export const GET_ZONES_ERROR = `${PREFIX}/GET_ZONES_ERROR`;
export const RESET_ZONES = `${PREFIX}/RESET_ZONES`;

export const POST_ZONE_REQUEST = `${PREFIX}/POST_ZONE_REQUEST`;
export const POST_ZONE_SUCCESS = `${PREFIX}/POST_ZONE_SUCCESS`;
export const POST_ZONE_ERROR = `${PREFIX}/POST_ZONE_ERROR`;

export const PATCH_ZONE_REQUEST = `${PREFIX}/PATCH_ZONE_REQUEST`;
export const PATCH_ZONE_SUCCESS = `${PREFIX}/PATCH_ZONE_SUCCESS`;
export const PATCH_ZONE_ERROR = `${PREFIX}/PATCH_ZONE_ERROR`;

export const DELETE_ZONE_REQUEST = `${PREFIX}/DELETE_ZONE_REQUEST`;
export const DELETE_ZONE_SUCCESS = `${PREFIX}/DELETE_ZONE_SUCCESS`;
export const DELETE_ZONE_ERROR = `${PREFIX}/DELETE_ZONE_ERROR`;
