import React, { FC } from 'react';

import { Switch, Route } from 'react-router-dom';

import { CATALOGUES_URLS, SECTIONS } from '../../catalogues.constants';

import FertilizerDetail from '../../components/fertilizers/FertilizerDetail/FertilizerDetail';
import FertilizersList from '../../components/fertilizers/FertilizersList';
import DriversList from '../../components/telematics/drivers/DriversList';
import EquipmentsList from '../../components/telematics/equipments/EquipmentsList';
import MachinesList from '../../components/telematics/machines/MachinesList';
import CataloguesTabs from '../CataloguesTabs/CataloguesTabs';

const Catalogues: FC = (props) => (
  <Switch>
    <Route
      exact
      path={`/farm/:farmId/${CATALOGUES_URLS.fertilizersReact}`}
      render={(routerProps) =>
        <CataloguesTabs activeTab={SECTIONS.FERTILIZERS_REACT} {...routerProps}>
          <FertilizersList {...props} />
        </CataloguesTabs>}
    />
    <Route
      exact
      path={`/farm/:farmId/${CATALOGUES_URLS.drivers}`}
      render={(routerProps) =>
        <CataloguesTabs activeTab={SECTIONS.DRIVERS} {...routerProps}>
          <DriversList {...props} />
        </CataloguesTabs>}
    />
    <Route
      exact
      path={`/farm/:farmId/${CATALOGUES_URLS.machines}`}
      render={(routerProps) =>
        <CataloguesTabs activeTab={SECTIONS.MACHINES} {...routerProps}>
          <MachinesList {...props} />
        </CataloguesTabs>}
    />
    <Route
      exact
      path={`/farm/:farmId/${CATALOGUES_URLS.equipment}`}
      render={(routerProps) =>
        <CataloguesTabs activeTab={SECTIONS.EQUIPMENT} {...routerProps}>
          <EquipmentsList {...props} />
        </CataloguesTabs>}
    />
    <Route
      exact
      path={`/farm/:farmId/${CATALOGUES_URLS.fertilizersReact}/new`}
      render={() => <FertilizerDetail {...props} isNew />}
    />
    <Route
      exact
      path={`/farm/:farmId/${CATALOGUES_URLS.fertilizersReact}/:fertilizerId`}
      render={() => <FertilizerDetail {...props} />}
    />
  </Switch>
);

export default Catalogues;
