import React from 'react';

import PercentIcon from '@mui/icons-material/Percent';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { editBatchBonus } from '../../actions/telematicsAggregations.actions';

import { CfFormikErrors } from '../../../types';
import { BonusField } from '../../components/TelematicsAggregationDetailContent/formComponents';
import { MESSAGES } from '../../components/TelematicsAggregationDetailContent/validators';

import { useCustomStyles } from './styles/useCustomStyles';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fontSize: 16,
    lineHeight: '140%',
    fontWeight: 400,
    fontStyle: 'normal',
    color: theme.palette.grey[400],
  },
}));

const useBonus = () => {
  const classes = useStyles();
  const customClasses = useCustomStyles();
  const dispatch = useDispatch();

  const selectedRides = useSelector((state: TelematicsState) => state.ui.telematicsAggregations.selectedRides);

  const initialValues = {
    bonus: 0,
  };

  const validateContentForm = (values: typeof initialValues) => {
    const errors: CfFormikErrors<typeof initialValues> = {};

    if (typeof values.bonus !== 'number') {
      errors.bonus = MESSAGES.required;
    }

    if (values.bonus < -999 || values.bonus > 999) {
      errors.bonus = MESSAGES.bonus;
    }
    return errors;
  };

  const onSubmit = (values: typeof initialValues) => {
    const payload = { drive: selectedRides, bonus: values.bonus };
    return editBatchBonus(payload)(dispatch);
  };

  const component = (formikProps: FormikProps<typeof initialValues>) => (<BonusField
    customClasses={customClasses}
    disabled={formikProps.isSubmitting}
    endAdornment={<PercentIcon className={classes.icon} />}
    {...formikProps}
  />);

  return {
    initialValues,
    validateContentForm,
    onSubmit,
    component,
  };
};

export default useBonus;
