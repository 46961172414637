import React, { Component } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable';
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  root: {
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
  },
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  editableContent: {
    paddingTop: 2,
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    minWidth: 10,
  },
  nonEditableContent: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  button: {
    marginLeft: 4,
    padding: 6,
    fontSize: 'inherit',
  },
  contentConfirming: {
    color: theme.palette.grey[400],
  },
});

/**
 * Component to use for headings, titles or short text, that user want to edit.
 * This text looks like normal text, but by clicking to it or to edit icon user can easily change it and save new value.
 * Thanks to `react-contenteditable` library, user can edit it directly in `div`.
 * @version 1.0.0
 */
class EditableText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: props.isEditing,
      isConfirming: false,
      editValue: this.props.initialValue,
    };

    this.inputRef = React.createRef();
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.isEditing) {
      this.startEditing();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.initialValue !== this.props.initialValue) {
      this.setState({
        editValue: this.props.initialValue,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onKeyDown = e => {
    switch (e.key) {
      case 'Escape':
        this.cancelEditing();
        break;
      case 'Enter':
        this.inputRef.current.blur();
        break;
      default:
        break;
    }
  };

  startEditing() {
    if (!this.state.isConfirming) {
      this.setState({
        isEditing: true,
      });
      this.focusTimer = setTimeout(() => {
        this.inputRef.current.focus();
      }, 0);
    }
  }

  handleChange = () => {
    this.setState({ editValue: this.inputRef.current.innerText });
  };

  setInitialState = () => {
    this.setState({
      isEditing: false,
      isConfirming: false,
      editValue: this.props.initialValue,
    });
  };

  cancelEditing = () => {
    this.setInitialState();
    this.props.onCancelEditing();
  };

  submitEditing() {
    const newValue = this.state.editValue;
    const { submitOnlyOnChange } = this.props;

    if (this.state.isEditing) {
      const trimmedValue = newValue && typeof newValue === 'string' ? newValue.trim() : '';
      if (trimmedValue) {
        if (submitOnlyOnChange && trimmedValue === this.props.initialValue) {
          this.setState({
            isEditing: false,
          });
        } else {
          this.setState({
            isEditing: false,
            isConfirming: true,
          });
          this.props.onConfirmEditing(trimmedValue).then(() => {
            if (this._isMounted) {
              this.setState({
                isConfirming: false,
              });
            }
          });
        }
      } else {
        this.cancelEditing();
      }
    }
  }

  render() {
    const { classes, labelTestId, maxWidth, truncate } = this.props;
    const { editValue, isConfirming, isEditing } = this.state;
    const isBeingChanged = isEditing || isConfirming;

    return (
      <Grid className={classes.root} onClick={() => this.startEditing()}>
        <ContentEditable
          data-test={labelTestId}
          disabled={!isEditing}
          html={editValue}
          innerRef={this.inputRef}
          onBlur={() => this.submitEditing()}
          onChange={this.handleChange}
          onKeyDown={e => this.onKeyDown(e)}
          spellCheck="false"
          style={{ maxWidth }}
          className={`${isEditing ? classes.editableContent : classes.nonEditableContent} ${
            !isEditing && truncate ? classes.truncate : ''
          } ${isConfirming ? classes.contentConfirming : ''}
          `}
        />
        <Tooltip title={!isBeingChanged ? <FormattedMessage id="common.edit" /> : ''}>
          <span>
            <IconButton
              classes={{ root: classes.button }}
              color="default"
              data-test={'edit-cion'}
              disabled={isBeingChanged}
              onClick={() => this.startEditing()}
              size="large">
              <EditIcon style={{ fontSize: 'inherit' }} />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
    );
  }
}

EditableText.propTypes = {
  /** Initial value. */
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Action to be called when value is changed and confirmed. */
  onConfirmEditing: PropTypes.func.isRequired,
  /** Style classes. */
  classes: PropTypes.object,
  /** Test id. */
  labelTestId: PropTypes.string,
  /** Maximum width of wrapper containing text.
   * If text is longer, user will see it as truncated in non-editable mode. */
  maxWidth: PropTypes.string,
  /** If true, text is set by default as editable. */
  isEditing: PropTypes.bool,
  /** If set to false, confirming action is called even if new value is the same as previous one. */
  submitOnlyOnChange: PropTypes.bool,
  /** Action to be called when editing is cancelled (Esc) or when empty value is left.
   * New value is forgotten and previous value is set instead. */
  onCancelEditing: PropTypes.func,
  truncate: PropTypes.bool,
};

EditableText.defaultProps = {
  initialValue: '',
  classes: {},
  labelTestId: 'editable-label',
  maxWidth: '100%',
  submitOnlyOnChange: true,
  isEditing: false,
  onCancelEditing: () => {},
  truncate: false,
};

export default withStyles(styles)(EditableText);
