import React, { FC } from 'react';

import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import NotSentIcon from '../../../shared/icons/NotSentIcon';

const useStyles = makeStyles({
  tooltip: {
    marginLeft: 8,
  },
  icon: {
    verticalAlign: 'middle',
  },
});

const TelematicsNotSendInfo: FC = () => {
  const classes = useStyles();
  return (
    <Tooltip title={<FormattedMessage id={'TelematicsList.notSent'} />}>
      <span className={classes.tooltip}>
        <NotSentIcon className={classes.icon} />
      </span>
    </Tooltip>
  );
};

export default TelematicsNotSendInfo;
