import React, { FC, Fragment, useEffect, useState } from 'react';

import { Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getMonitoringDataError as getError,
  getMonitoringDataIsFetching,
  getMonitoringCropIntervalsIsFetching,
} from '../../../shared/api/sentinel/monitoring/monitoring.selectors';
import {
  getIndex,
  getDateFrom,
  getDateTo,
  getGraphData,
  getMonitoringData,
  getCropIntervals,
  getMonitoringStatus,
} from '../selectors/monitoring.selectors';

import { setMonitoringIndex, setMonitoringInterval } from '../actions/monitoring.actions';

import * as satelliteProductsTypes from '../../../shared/constants/satelliteProductsTypes.constants';

import { getMonitoringDataApi, getCropIntervalsApi } from '../../../shared/api/sentinel/monitoring/monitoring.api';
import CfErrorPage from '../../../shared/components/common/CfErrorPage/CfErrorPage';
import CfLoader from '../../../shared/components/common/CfLoader/CfLoader';
import CfStatusPanel from '../../../shared/components/common/CfStatusPanel/CfStatusPanel';
import useWidth from '../../../shared/hooks/useWidth';
import SatelliteIcon from '../../../shared/icons/SatelliteIcon';
import { disableHTMLCanvasImageSmoothing, enableHTMLCanvasImageSmoothing } from '../../../shared/misc/helpers';
import { RsaaApiError, Thunk } from '../../../types';
import BioMonitoringGraph from '../components/BioMonitoring/BioMonitoringGraph';
import BioMonitoringGraphInfoDialog from '../components/BioMonitoring/BioMonitoringGraphInfoDialog';
import BioMonitoringHistoryImage from '../components/BioMonitoring/BioMonitoringHistoryImage';
import BioMonitoringHistoryInfoDialog from '../components/BioMonitoring/BioMonitoringHistoryInfoDialog';
import BioMonitoringImagesOverallError from '../components/BioMonitoring/BioMonitoringImagesOverallError';
import BioMonitoringIndexSwitcher from '../components/BioMonitoring/BioMonitoringIndexSwitcher';
import BioMonitoringLegend from '../components/BioMonitoring/BioMonitoringLegend';
import BioMonitoringStatusWrapper from '../components/BioMonitoring/BioMonitoringStatusWrapper';
import BioMonitoringZonesLegend from '../components/BioMonitoring/BioMonitoringZonesLegend';
import CropIntervalSwitcher from '../components/BioMonitoring/CropIntervalSwitcher';
import PrecisionMapModal from '../components/PrecisionMapModal/PrecisionMapModal';
import SectionHeader from '../components/SectionHeader';
import { MONITORING_STATUS } from '../selectors/monitoringStatus';
import BioMonitoringImageErrorService from '../services/BioMonitoringImageError.services';

import { PrecisionState } from '../../../reducers/precision.reducer.types';
import { Crop, Zone } from '../../../shared/api/sentinel/management/management.types';
import { CropIntervals, imgExtent } from '../../../shared/api/sentinel/monitoring/monitoring.types';

const supportedImageStates = [
  MONITORING_STATUS.OK,
  MONITORING_STATUS.NO_ZONES,
  MONITORING_STATUS.NO_ZONES_2020,
  MONITORING_STATUS.LAI_ONLY,
];

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    margin: 0,
    fontSize: '18px',
  },
  headerBar: {
    marginBottom: 5,
  },
  wrapper: {
    marginBottom: '10px',
    height: (isFetching) => (isFetching ? '90%' : 'auto'),
  },
  dialogWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  imagesLegendWrapper: {
    marginTop: 10,
    marginRight: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  legend: {
    fontSize: 13,
  },
  zonesLegend: {
    marginTop: 6,
    marginBottom: 3,
    marginRight: -15,
    fontSize: 13,
  },
  historicalStatusPanel: {
    marginTop: 15,
    marginBottom: 30,
  },
  [theme.breakpoints.down('xs')]: {
    heading: {
      textAlign: 'center',
      marginBottom: 10,
    },
    imagesLegendWrapper: {
      marginTop: -6,
      alignItems: 'center',
    },
  },
}));

export type MapImage = {
  extent: number[],
  url: string
}

export type Geometries = {
  color: string,
  geometry: {
    coordinates: number[][];
    type: string;
  },
  zoneNumber: number
}

export interface MonitoringCropIntervals extends CropIntervals {
  id: string;
}

export interface GraphDataOrMonitoringData {
  crop: Crop;
  dateFrom: string;
  dateTo: string;
  imgExtent: imgExtent;
  imgPath: string;
  indexType: string;
  status: string;
  value: number;
  zones: Zone[];
}

export interface BioMonitoringProps {
  cropIntervals: MonitoringCropIntervals[],
  dateFrom: string,
  dateTo: string,
  error?: RsaaApiError,
  getCropIntervalsApi: (parcelId: string) => void,
  getMonitoringDataApi: (
    parcelId: string,
    index: string,
    dateFrom: string,
    dateTo: string,
    status: string,
    perPage?: number
  ) => void,
  graphData: GraphDataOrMonitoringData[],
  index: string,
  isFetchingCropIntervals: boolean,
  isFetchingData: boolean,
  langId: string,
  monitoringData: GraphDataOrMonitoringData[],
  monitoringStatus?: string,
  ngRedirectToSowing: (parcelId: string[]) => void,
  parcelId: string,
  setMonitoringIndex: (index: string) => void,
  setMonitoringInterval: (dateFrom: string, dateTo: string) => void
}

const BioMonitoring: FC<BioMonitoringProps> = ({
  cropIntervals,
  dateFrom,
  dateTo,
  error,
  getCropIntervalsApi,
  getMonitoringDataApi,
  graphData,
  index,
  isFetchingCropIntervals,
  isFetchingData,
  langId,
  monitoringData,
  monitoringStatus,
  ngRedirectToSowing,
  parcelId,
  setMonitoringIndex,
  setMonitoringInterval,
}) => {
  const isFetching = isFetchingData || isFetchingCropIntervals;

  const classes = useStyles(isFetching);
  const width = useWidth();

  const [geometries, setGeometries] = useState<Geometries[] | null>(null);
  const [mapImage, setMapImage] = useState<MapImage | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [dateModal, setDateModal] = useState({ dateFrom: '', dateTo: '' });

  useEffect(() => {
    // temporarily disable global HTMLCanvas smoothing
    disableHTMLCanvasImageSmoothing();
    if (monitoringStatus === satelliteProductsTypes.ACTIVE ||
    monitoringStatus === satelliteProductsTypes.HISTORICAL) {
      getCropIntervalsApi(parcelId);
    }

    return () => {
      // enable global HTMLCanvas smoothing
      enableHTMLCanvasImageSmoothing();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (monitoringStatus === satelliteProductsTypes.ACTIVE ||
      monitoringStatus === satelliteProductsTypes.HISTORICAL) {
      getCropIntervalsApi(parcelId);
    }
  }, [getCropIntervalsApi, monitoringStatus, parcelId]);

  useEffect(() => {
    if (dateFrom) {
      const status = supportedImageStates.join(',');
      getMonitoringDataApi(parcelId, index, dateFrom, dateTo, status);
    }
  }, [index, dateFrom, parcelId, dateTo, getMonitoringDataApi]);

  useEffect(() => {
    if (cropIntervals.length) {
      setMonitoringInterval(cropIntervals[0].from, cropIntervals[0].to);
    }
  }, [cropIntervals, setMonitoringInterval]);

  const overallImagesError = BioMonitoringImageErrorService.getOverallImagesError(monitoringData, index);
  const cropHarvestedError = BioMonitoringImageErrorService.checkCropHarvestedError(monitoringData);
  const lastImageDateTo = monitoringData?.[0]?.dateTo;

  const setMapForModal = (
    source: Geometries[] | MapImage | null,
    dateFrom: string,
    dateTo: string,
    isImage = false,
  ) => {
    if (isImage) {
      setMapImage(source as MapImage);
      setGeometries(null);
    } else {
      setMapImage(null);
      setGeometries(source as Geometries[]);
    }
    setDateModal({ dateFrom, dateTo });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <CfErrorPage error={error}>
      <BioMonitoringStatusWrapper langId={langId} monitoringStatus={monitoringStatus} width={width}>
        <Fragment>
          {cropHarvestedError && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <CfStatusPanel
                  dense={true}
                  fullWidth={true}
                  horizontal={width !== 'xs'}
                  icon={cropHarvestedError.icon}
                  linkText={<FormattedMessage id="BioMonitoring.harvestCrop" />}
                  onLinkClick={() => ngRedirectToSowing([parcelId])}
                  content={
                    <FormattedMessage
                      id={`BioMonitoring.status.${cropHarvestedError.id}`}
                      values={{
                        b: (chunks: string) => <b>{chunks}</b>,
                      }}
                    />
                  }
                />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={1}>
            <Grid className={classes.headerBar} item xs={12}>
              <Grid alignItems="center" container justifyContent={width !== 'xs' ? 'flex-start' : 'center'}>
                <Grid data-test="bio-monitoring-switchers" item sm={'auto'} xs={12}>
                  <Grid alignItems="center" container justifyContent={'center'} spacing={1}>
                    <Grid item sm={'auto'} xs={12}>
                      <CropIntervalSwitcher
                        cropIntervals={cropIntervals}
                        onMenuItemClick={setMonitoringInterval}
                      />
                    </Grid>
                    <Grid item sm={'auto'} xs={12}>
                      <BioMonitoringIndexSwitcher
                        index={index}
                        setMonitoringIndex={setMonitoringIndex}
                        width={width}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {isFetching ? (
            <CfLoader />
          ) : (
            <Fragment>
              {overallImagesError && (
                <BioMonitoringImagesOverallError
                  lastImageDateTo={lastImageDateTo}
                  overallImagesError={overallImagesError}
                />
              )}
              {!monitoringData.length && (
                <CfStatusPanel
                  fullWidth={true}
                  grey={true}
                  icon={SatelliteIcon}
                  title={<FormattedMessage id={'BioMonitoring.imagesProcessed'} />}
                />
              )}
              {!overallImagesError && !!monitoringData.length && (
                <Grid
                  className={classes.wrapper}
                  container
                  spacing={1}
                >
                  <Grid data-test="bio-monitoring-graph" item xs={12}>
                    <Paper>
                      <SectionHeader
                        dialogHeadingTranslationId={`BioMonitoring.${index}`}
                        headingTranslationId={'BioMonitoring.AverageValues.heading'}
                        infoDialogContent={<BioMonitoringGraphInfoDialog index={index} />}
                        testId="bio-monitoring-graph-header"
                      />
                      <BioMonitoringGraph data={graphData} index={index} isFetching={isFetchingData} />
                    </Paper>
                  </Grid>

                  <Grid data-test="image-history" item xs={12}>
                    <Paper>
                      <Grid container justifyContent="space-between" spacing={0}>
                        <Grid className={classes.dialogWrapper} data-test="bio-monitoring-legend" item xs={12}>
                          <SectionHeader
                            headingTranslationId={'common.history'}
                            infoDialogContent={<BioMonitoringHistoryInfoDialog index={index} />}
                            maxDialogWidth={'md'}
                            testId="bio-monitoring-legend-header"
                          >
                            <div className={classes.imagesLegendWrapper}>
                              <div className={classes.legend}>
                                <BioMonitoringLegend index={index} />
                              </div>
                              <div className={classes.zonesLegend}>
                                <BioMonitoringZonesLegend index={index} />
                              </div>
                            </div>
                          </SectionHeader>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent="flex-start" spacing={0}>
                        {monitoringData.map((item, i) => (
                          <BioMonitoringHistoryImage
                            item={item}
                            key={`${item.dateFrom}-${item.dateTo}`}
                            onMapClick={setMapForModal}
                            order={i}
                            width={width}
                          />
                        ))}
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              )}
            </Fragment>
          )}
        </Fragment>
        {showModal &&
          <PrecisionMapModal
            date={dateModal}
            geometries={geometries}
            index={index}
            isBioMonitoring
            mapImage={mapImage}
            onClose={handleCloseModal}
            parcelId={parcelId}
            showModal={showModal}
          />
        }
      </BioMonitoringStatusWrapper>
    </CfErrorPage>
  );
};

const mapStateToProps = (state: PrecisionState) => ({
  graphData: getGraphData(state),
  monitoringData: getMonitoringData(state),
  isFetchingData: getMonitoringDataIsFetching(state),
  error: getError(state),
  index: getIndex(state),
  dateFrom: getDateFrom(state),
  dateTo: getDateTo(state),
  cropIntervals: getCropIntervals(state),
  monitoringStatus: getMonitoringStatus(state),
  isFetchingCropIntervals: getMonitoringCropIntervalsIsFetching(state),
});

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) =>
  bindActionCreators(
    {
      getCropIntervalsApi,
      getMonitoringDataApi,
      setMonitoringInterval,
      setMonitoringIndex,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BioMonitoring);
