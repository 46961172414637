import React, { Component, Fragment } from 'react';

import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { getLocations, getLocationsPoints } from '../../selectors/locations.selectors';
import { getHoveredFeatureId, isEnlargedVariant, getMapColumns } from '../../selectors/map.selectors';
import { getIsError, getIsModuleAvailable, getIsFetchingStatistics } from '../../selectors/nodes.selectors';

import { fetchGroups } from '../../actions/groups.actions';
import { fetchLocations, fetchLocationsPoints } from '../../actions/locations.actions';
import {
  setMapColumns,
  setEnlargedVariant,
  zoomToFarm,
  storeHoveredFeatureId,
  storeSelectedFeatureId,
} from '../../actions/map.actions';
import { fetchDeviceTypes, fetchNodesStatistics } from '../../actions/node.actions';

import * as services from '../../../shared/constants/services.constants';

import CfErrorPage from '../../../shared/components/common/CfErrorPage/CfErrorPage';
import CfLoader from '../../../shared/components/common/CfLoader/CfLoader';
import CfStatusPanel from '../../../shared/components/common/CfStatusPanel/CfStatusPanel';
import PageHeader from '../../../shared/components/common/PageHeader/PageHeader';
import PageHeading from '../../../shared/components/common/PageHeading/PageHeading';
import ServiceNotPurchased from '../../../shared/components/common/ServiceNotPurchased/ServiceNotPurchased';
import ShowHideMap from '../../../shared/components/common/ShowHideMap/ShowHideMap';
import { links } from '../../../shared/constants/links';
import withWidth from '../../../shared/hocs/withWidth';
import SensorsIcon from '../../../shared/icons/navbar/SensorsIcon';
import NodeLocationsList from '../../components/NodeLocationsList/NodeLocationsList';
import Map from '../Map/Map';
import NodeLocation from '../NodeLocation/NodeLocation';

const styles = theme => ({
  wrapperStyle: {
    height: '100%',
  },
  style: {
    height: '100%',
  },
  bodyWrapper: {
    position: 'relative',
  },
  map: {
    overflow: 'hidden',
    height: '100%',
  },
  noSensors: {
    padding: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
});

export class NodeLocations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sensorsHomePath: `/farm/${props.match.params.farmId}/sensors`,
    };
    this.props.fetchNodesStatistics();
    this.props.setMapColumns(this.getMapColumnsByWidth(props.width, props.isMapEnlargedVariant));
  }

  componentDidUpdate(prevProps) {
    const { isMapEnlargedVariant, isModuleAvailable, width } = this.props;
    if (isModuleAvailable && !prevProps.isModuleAvailable) {
      this.props.fetchDeviceTypes();
      this.props.fetchLocationsPoints();
      this.props.fetchGroups();
    }

    if (prevProps.isMapEnlargedVariant !== this.props.isMapEnlargedVariant) {
      this.props.setMapColumns(this.getMapColumnsByWidth(width, isMapEnlargedVariant));
    }
    if (prevProps.width !== this.props.width) {
      const mapColumnsNew = this.getMapColumnsByWidth(width, isMapEnlargedVariant);
      if (this.props.mapColumns !== mapColumnsNew) {
        this.props.setMapColumns(mapColumnsNew);
      }
    }
  }

  getMapColumnsByWidth = (width, isMapEnlargedVariant) => {
    let mapColumns = 4;
    if (isMapEnlargedVariant) {
      if (width === 'xs') {
        mapColumns = 0;
      } else if (width === 'sm') {
        mapColumns = 5;
      } else if (width === 'md') {
        mapColumns = 7;
      } else {
        mapColumns = 8;
      }
    } else if (width === 'xs' || width === 'sm' || width === 'md') {
      mapColumns = 0;
    }
    return mapColumns;
  };

  resetHoveredAndSelectedFeatures = () => {
    this.props.storeSelectedFeatureId(this.props.match.params.sensorId);
    this.props.storeHoveredFeatureId(null);
  };

  toggleMap = () => {
    let mapColumnsNew = 0;
    const { isMapEnlargedVariant, mapColumns, width } = this.props;
    if (mapColumns === 0) {
      if (width === 'xs' || (!isMapEnlargedVariant && width === 'sm')) {
        mapColumnsNew = 12;
      } else if (!isMapEnlargedVariant && width === 'md') {
        mapColumnsNew = 4;
      } else {
        mapColumnsNew = this.getMapColumnsByWidth(width, isMapEnlargedVariant);
      }
    }
    this.props.setMapColumns(mapColumnsNew);
  };

  renderMap = () => {
    const { classes, config, farmBbox, farmId, history, langId, location, locationsPoints, match } = this.props;
    return (
      <div className={classes.map}>
        <Map
          className={classes.style}
          config={config}
          farmBbox={farmBbox}
          farmId={farmId}
          history={history}
          isList={location.pathname === this.state.sensorsHomePath}
          langId={langId}
          match={match}
          nodeLocations={locationsPoints}
        />
      </div>
    );
  };

  render() {
    const {
      classes,
      error,
      farmBbox,
      farmId,
      hoveredFeatureId,
      isFetchingStatistics,
      isMapEnlargedVariant,
      isModuleAvailable,
      langId,
      locations,
      mapColumns,
      ngGoToNewNotification,
    } = this.props;

    return (
      <CfErrorPage error={error}>
        <div className={classes.wrapperStyle}>
          {isFetchingStatistics && !isModuleAvailable ? (
            <CfLoader />
          ) : (
            <Fragment>
              {isModuleAvailable ? (
                <Grid className={classes.style} container>
                  {mapColumns !== 12 && (
                    <Grid className={classes.bodyWrapper} item xs={12 - mapColumns}>
                      <Scrollbars>
                        <Switch>
                          <Route
                            exact
                            path="/farm/:farmId/sensors"
                            render={routerProps => (
                              <NodeLocationsList
                                farmId={farmId}
                                fetchLocations={this.props.fetchLocations}
                                hoveredFeatureId={hoveredFeatureId}
                                isMapDisplayed={Boolean(mapColumns)}
                                langId={langId}
                                locations={locations}
                                resetHoveredAndSelectedFeatures={this.resetHoveredAndSelectedFeatures}
                                setEnlargedVariant={this.props.setEnlargedVariant}
                                zoomToFarm={() => this.props.zoomToFarm(farmBbox)}
                                {...routerProps}
                              />
                            )}
                          />
                          <Route
                            path="/farm/:farmId/sensors/:sensorId"
                            render={routerProps => (
                              <NodeLocation
                                isMapDisplayed={Boolean(mapColumns)}
                                ngGoToNewNotification={ngGoToNewNotification}
                                {...routerProps}
                              />
                            )}
                          />
                        </Switch>
                      </Scrollbars>
                      {(mapColumns === 0 || (!isMapEnlargedVariant && mapColumns < 12)) && (
                        <ShowHideMap handleClick={() => this.toggleMap()} isMapHidden={!mapColumns} />
                      )}
                    </Grid>
                  )}
                  <Grid
                    item
                    sm={mapColumns || 'auto'}
                    style={{ display: mapColumns !== 0 ? 'block' : 'none', height: '100%' }}
                    xs={12}
                  >
                    {mapColumns === 12 && <ShowHideMap handleClick={() => this.toggleMap()} isMapHidden={false} />}
                    {this.renderMap()}
                  </Grid>
                </Grid>
              ) : (
                <div className={classes.noSensors}>
                  <PageHeader
                    classes={{ header: classes.header }}
                    heading={<PageHeading translationId="common.sensors" />}
                  />
                  <CfStatusPanel
                    customContent={<ServiceNotPurchased serviceId={services.SENSORS} />}
                    icon={SensorsIcon}
                    linkHref={langId === 'cs-CZ' ? links.cs.sensors : links.en.sensors}
                    linkText={<FormattedMessage id="common.findOutMore" />}
                    testId="no-sensors"
                    title={<FormattedMessage id="NodeLocations.notPurchased.title" />}
                    titleWithIcon={true}
                  />
                </div>
              )}
            </Fragment>
          )}
        </div>
      </CfErrorPage>
    );
  }
}

NodeLocations.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  locationsPoints: PropTypes.array.isRequired,
  isModuleAvailable: PropTypes.bool,
  langId: PropTypes.string,
  hoveredFeatureId: PropTypes.string,
  farmId: PropTypes.string,
  farmBbox: PropTypes.any,
  isFetchingStatistics: PropTypes.bool.isRequired,
  error: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  isMapEnlargedVariant: PropTypes.bool.isRequired,
  width: PropTypes.string.isRequired,
  mapColumns: PropTypes.number.isRequired,
  fetchNodesStatistics: PropTypes.func.isRequired,
  fetchLocations: PropTypes.func.isRequired,
  fetchGroups: PropTypes.func.isRequired,
  fetchLocationsPoints: PropTypes.func.isRequired,
  setMapColumns: PropTypes.func.isRequired,
  setEnlargedVariant: PropTypes.func.isRequired,
  zoomToFarm: PropTypes.func.isRequired,
  fetchDeviceTypes: PropTypes.func.isRequired,
  storeHoveredFeatureId: PropTypes.func.isRequired,
  storeSelectedFeatureId: PropTypes.func.isRequired,
  ngGoToNewNotification: PropTypes.func.isRequired,
};

NodeLocations.defaultProps = {
  isModuleAvailable: false,
  langId: '',
  hoveredFeatureId: null,
  farmId: '',
  farmBbox: [],
  initZoomLevel: null,
};

const mapStateToProps = state => ({
  isFetchingStatistics: getIsFetchingStatistics(state),
  error: getIsError(state),
  locations: getLocations(state),
  locationsPoints: getLocationsPoints(state),
  hoveredFeatureId: getHoveredFeatureId(state),
  isModuleAvailable: getIsModuleAvailable(state),
  isMapEnlargedVariant: isEnlargedVariant(state),
  mapColumns: getMapColumns(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchGroups,
      fetchNodesStatistics,
      fetchLocations,
      fetchLocationsPoints,
      setMapColumns,
      setEnlargedVariant,
      zoomToFarm,
      fetchDeviceTypes,
      storeHoveredFeatureId,
      storeSelectedFeatureId,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(compose(withStyles(styles), withWidth())(NodeLocations));
