import React, { FC, ReactNode, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getIsFetchingParcelsLandUse, getParcelsLandUse } from '../../../../../../shared/api/agroevidence/parcels/parcels.selectors';

import { getParcelsLandUseApi, resetParcelsLandUseApi } from '../../../../../../shared/api/agroevidence/parcels/parcels.api';
import CfAutocomplete from '../../../../../../shared/components/common/CfAutocomplete/CfAutocomplete';
import { Thunk } from '../../../../../../types';

import { ParcelsState } from '../../../../../../reducers/parcels.reducer.types';
import { LandUseResponse } from '../../../../../../shared/api/agroevidence/agroevidence.types';

export interface LandUseSelectorProps {
  defaultLandUse?: LandUseResponse[],
  getParcelsLandUseApi: () => void,
  isLandUseFetching: boolean,
  label: ReactNode,
  onChange: (items: LandUseResponse[]) => void,
  resetParcelsLandUseApi: () => void,
  suggestions: LandUseResponse[]
}

const LandUseSelector: FC<LandUseSelectorProps> = ({
  defaultLandUse = [],
  getParcelsLandUseApi,
  isLandUseFetching,
  label,
  onChange,
  suggestions,
  resetParcelsLandUseApi,
}) => {
  useEffect(() => {
    getParcelsLandUseApi();

    return () => {
      resetParcelsLandUseApi();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CfAutocomplete
      defaultValues={defaultLandUse}
      id="landUse-selector"
      isFetching={isLandUseFetching}
      isMultiple={true}
      label={label}
      loadOptions={getParcelsLandUseApi}
      onChange={onChange}
      suggestions={suggestions}
      testId="landUse-filter"
    />
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  suggestions: getParcelsLandUse(state).sort((a, b) => a.name.localeCompare(b.name)),
  isLandUseFetching: getIsFetchingParcelsLandUse(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      getParcelsLandUseApi,
      resetParcelsLandUseApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LandUseSelector);
