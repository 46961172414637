import { SectionListState } from '../catalogues/reducers/sectionList.types';
import { CataloguesTypesState } from '../shared/api/agroevidence/catalogues/cataloguesTypes.types';
import { CataloguesEagriState } from '../shared/api/agroevidence/catalogues/eagri/eagri.types';
import { FertilizersState } from '../shared/api/agroevidence/catalogues/fertilizers/fertilizers.types';
import { TelematicsCataloguesState } from '../shared/api/telematics/catalogues/telematicsCatalogues.types';
import { TelematicsStatusState } from '../shared/api/telematics/sectionStatus/sectionStatus.types';

export interface CataloguesState {
    api: {
        agroevidence: {
            cataloguesTypes: CataloguesTypesState;
            eagri: CataloguesEagriState;
            fertilizers: FertilizersState;
        },
        telematics: TelematicsCataloguesState;
    },
    telematicsStatus: TelematicsStatusState,
    ui: {
        drivers: SectionListState;
        equipment: SectionListState;
        fertilizers: SectionListState;
        machines: SectionListState;
        selectedNamespace: CATALOGUES_NAMESPACES;
    }
}

export enum CATALOGUES_NAMESPACES {
    DRIVERS='drivers',
    EQUIPMENT='equipment',
    FERTILIZERS='fertilizers',
    MACHINES='machines'
}
