import React, { Component } from 'react';

import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import differenceBy from 'lodash/differenceBy';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';

import { updateChemistryEffectiveness } from '../../../shared/actions/actions.actions';

import { EFFECTIVENESS } from '../../services/Effectiveness.services';
import ChemistryEffectivenessDialog from '../ChemistryEffectivenessDialog/ChemistryEffectivenessDialog';

const styles = () => ({
  tooltip: {
    fontSize: 12,
    marginBottom: 4,
  },
  link: {
    '&:hover': {
      textDecoration: 'underline',
      '&:focus': {
        textDecoration: 'underline',
      },
    },
    '&:focus': {
      textDecoration: 'none',
    },
  },
});

class ChemistryEffectiveness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      chemistry: { effectiveness },
      formName,
      index,
      parcels,
    } = this.props;
    if (parcels.length > prevProps.parcels.length && effectiveness && effectiveness.length > 0) {
      const newParcels = differenceBy(parcels, prevProps.parcels, 'id');
      const newEffectiveness = this.getEffectiveness(newParcels);
      this.props.updateChemistryEffectiveness([...effectiveness, ...newEffectiveness], index, formName);
    }
    if (parcels.length < prevProps.parcels.length && effectiveness && effectiveness.length > 0) {
      const removedParcel = differenceBy(prevProps.parcels, parcels, 'id');
      const updatedEffectiveness = effectiveness.filter(item => item.parcelId !== removedParcel[0].id);
      this.props.updateChemistryEffectiveness(updatedEffectiveness, index, formName);
    }
  }

  getEffectiveness = parcels =>
    parcels.map(parcel => ({
      parcelName: parcel.localName,
      parcelId: parcel.id,
      value: EFFECTIVENESS.YES,
    }));

  handleDialogOpen = () => {
    this.setState({
      opened: true,
    });
  };

  handleDialogClose = () => {
    this.setState({
      opened: false,
    });
  };

  handleDialogAccept = values => {
    this.props.updateChemistryEffectiveness(values.effectiveness, this.props.index, this.props.formName);
    this.handleDialogClose();
  };

  renderItem(parcelName, value, index, hasNext) {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    const messageId = `ChemistryEffectiveness.${value.toLowerCase()}`;
    const message = value === EFFECTIVENESS.NOT_SPECIFIED ? '-' : formatMessage({ id: messageId }).toUpperCase();
    return (
      <Tooltip
        key={index}
        placement="bottom"
        title={parcelName}
        classes={{
          tooltip: classes.tooltip,
        }}
      >
        <Grid item>{`${message}${index === 2 && hasNext ? ' ...' : ''}${
          hasNext && index < 2 ? '\u00A0|\u00A0' : ''
        }`}</Grid>
      </Tooltip>
    );
  }

  renderEffectivenessList() {
    const { chemistry, parcels } = this.props;
    // empty when creating an action
    const isChemistryEmpty = !chemistry.effectiveness || chemistry.effectiveness.length === 0;
    const data = isChemistryEmpty ? parcels.slice(0, 3) : chemistry.effectiveness.slice(0, 3);

    return (
      <Grid container>
        {data.map((item, index) => {
          const args = isChemistryEmpty
            ? [item.localName, 'YES', index, index < parcels.length - 1]
            : [item.parcelName, item.value, index, index < chemistry.effectiveness.length - 1];

          return this.renderItem(...args);
        })}
      </Grid>
    );
  }

  render() {
    const { chemistry, classes, index, parcels } = this.props;
    return (
      <div>
        <a className={classes.link} data-test="effectiveness" href="#" onClick={this.handleDialogOpen}>
          {this.renderEffectivenessList()}
        </a>
        <ChemistryEffectivenessDialog
          chemistry={chemistry}
          editing={this.props.editing}
          getEffectiveness={this.getEffectiveness}
          index={index}
          onAccept={this.handleDialogAccept}
          onClose={this.handleDialogClose}
          opened={this.state.opened}
          parcels={parcels}
        />
      </div>
    );
  }
}

ChemistryEffectiveness.propTypes = {
  intl: PropTypes.object.isRequired,
  parcels: PropTypes.array,
  chemistry: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  editing: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  updateChemistryEffectiveness: PropTypes.func.isRequired,
};

ChemistryEffectiveness.defaultProps = {
  editing: false,
  parcels: [],
};

const mapStateToProps = (state, props) => ({
  parcels: formValueSelector(props.formName)(state, 'parcels'),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateChemistryEffectiveness,
    },
    dispatch,
  );

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChemistryEffectiveness)));
