import React, { FC } from 'react';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedDate, FormattedMessage } from 'react-intl';

import CfSwitcher from '../../../../shared/components/common/CfSwitcher/CfSwitcher';
import { MonitoringCropIntervals } from '../../containers/BioMonitoring';

const useStyles = makeStyles((theme: Theme) => ({
  cropName: {
    color: theme.palette.grey[500],
    display: 'inline-block',
  },
  dateInterval: {
    textTransform: 'lowercase',
    marginRight: 6,
  },
}));

export interface CropIntervalSwitcherProps {
  cropIntervals: MonitoringCropIntervals[],
  onMenuItemClick: (dateFrom: string, dateTo: string,) => void
}

const CropIntervalSwitcher: FC<CropIntervalSwitcherProps> = ({
  cropIntervals,
  onMenuItemClick,
}) => {
  const classes = useStyles();
  return (
    <CfSwitcher
      arrowsShift={true}
      getItemId={item => item.id}
      getItemValue={item => item.id}
      items={cropIntervals}
      onMenuItemClick={item => onMenuItemClick(item.from, item.to)}
      testId={'crop-interval-switcher'}
      getItemViewValue={item => (
        <span>
          <span className={classes.dateInterval}>
            <FormattedDate month="short" value={item.from} year="numeric" />
            {' \u2013 '}
            {item.to ? (
              <FormattedDate month="short" value={item.to} year="numeric" />
            ) : (
              <FormattedMessage id="common.now" />
            )}
          </span>{' '}
          <span className={classes.cropName}>{item.crop.name}</span>
        </span>
      )}
    />
  );
};

export default CropIntervalSwitcher;
