import { createSelector } from 'reselect';

import { getPrecisionParcelListOrder, getPrecisionParcelListOrderBy, getPrecisionParcelListPage, getPrecisionParcelListRowsPerPage, getPrecisionParcelListTextFilter } from '../../../../core/precision/selectors/precisionParcelList.selectors';

import { paginationArray, sortArray } from '../../../misc/arraysAction.helpers';
import ErrorService from '../../../services/Error.service';

import { PrecisionState } from '../../../../reducers/precision.reducer.types';
import { ParcelStatus } from '../../agroevidence/agroevidence.types';

export const getPrecisionParcels = (state: PrecisionState) =>
  state.api.precisionParcels.items.filter(item =>
    item.status === ParcelStatus.OPEN && (item.historyPotential || item.biomonitoring || item.soilSamples));

export const getIsFetchingPrecisionParcels = (state: PrecisionState) => state.api.precisionParcels.isFetchingItems;

export const getPrecisionParcelsFiltered = createSelector(
  getPrecisionParcels,
  getPrecisionParcelListTextFilter,
  (parcels, textFilter) => {
    if (textFilter.length === 0) return parcels;
    const textFilterLowerCase = textFilter.toLowerCase();

    const parcelsFiltered = parcels.filter(parcel =>
      parcel.localName.toLowerCase().includes(textFilterLowerCase) ||
      parcel.blockNr.toLowerCase().includes(textFilterLowerCase),
    );
    return parcelsFiltered;
  });

export const getPrecisionParcelsSortedPaginated = createSelector(
  getPrecisionParcelsFiltered,
  getPrecisionParcelListOrder,
  getPrecisionParcelListOrderBy,
  getPrecisionParcelListPage,
  getPrecisionParcelListRowsPerPage,
  (parcelsFiltered, order, orderBy, page, rowsPerPage) => {
    const parcelsSorted = sortArray(order, orderBy, parcelsFiltered);
    return paginationArray(page, rowsPerPage, parcelsSorted);
  });

export const getTotalCount = createSelector(
  getPrecisionParcels,
  getPrecisionParcelListTextFilter,
  getPrecisionParcelsFiltered,
  (parcels, textFilter, parcelsFiltered) => {
    if (textFilter.length === 0) return parcels.length;
    return parcelsFiltered.length;
  });

export const getPrecisionParcel = (state: PrecisionState) => state.api.precisionParcels.item;
export const getIsFetchingPrecisionParcel = (state: PrecisionState) => state.api.precisionParcels.isFetchingItem;

export const getError = (state: PrecisionState) => ErrorService.getResErrorDto(state.api.precisionParcels.error);
