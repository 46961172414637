import React, { FC, useContext, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ImportFilesApiType, ValidateFileApiType } from '../../../shared/api/telematics/drivesImportHistory/drivesImportHistory.api';
import CfDialog from '../../../shared/components/common/CfDialog/CfDialog';
import { SnackbarContext } from '../../../shared/containers/SnackbarProvider/SnackbarProvider';
import { AsyncFn, Thunk } from '../../../types';
import FileUploader from '../FileUploader/FileUploader';

import SuccessfulImport from './SuccessfulImport';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { DriveImportResponseFileTo } from '../../../shared/api/telematics/telematics.types';

const useStyles = makeStyles(() => ({
  dialogContainer: {
    padding: '16px 24px 24px 24px',
  },
  dialogContent: {
    padding: 0,
  },
  dialogActions: {
    padding: 0,
    paddingTop: 48,
  },
  title: {
    padding: 0,
    marginBottom: 24,
  },
  closeIcon: {
    top: 8,
    right: 8,
  },
}));

export type FileLoadItemStatus = 'new' | 'validated' | 'error';

export type FileLoadItem = {
  errorCode?: string,
  file: File,
  status: FileLoadItemStatus,
  uniqueId: string
};
interface Props {
  handleClose: () => void;
  importFilesApi: (params: ImportFilesApiType) => void;
  open: boolean;
  validateFileApi: (params: ValidateFileApiType) => void;
}

const ImportRidesDialog: FC<Props> = ({
  handleClose,
  importFilesApi,
  open,
  validateFileApi,
}) => {
  const classes = useStyles();
  const showSnackbar = useContext(SnackbarContext);

  const [allFiles, setAllFiles] = useState<FileLoadItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleFilesValidationApi = (newFiles: FileLoadItem[]) => {
    setLoading(prev => !prev);

    const validationPromises = newFiles.map((fileItem) => {
      const params: ValidateFileApiType = {
        file: fileItem.file,
      };

      return new Promise<FileLoadItem>((resolve) => {
        (validateFileApi as AsyncFn<ValidateFileApiType>)(params).then((res) => {
          if ('file' in res.payload) {
            const data = res.payload as DriveImportResponseFileTo;

            if (data.error) {
              fileItem.status = 'error';
              fileItem.errorCode = data.error;
            } else {
              fileItem.status = 'validated';
            }
          }
          resolve(fileItem);
        });
      });
    });

    Promise.all(validationPromises)
      .catch(() => {
        showSnackbar({ message: <FormattedMessage id="FileUploader.modal.validation.error" />, isError: true });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFilesImportApi = () => {
    setLoading(prev => !prev);
    const params: ImportFilesApiType = {
      files: allFiles.map(i => i.file),
    };

    (importFilesApi as AsyncFn<ImportFilesApiType>)(params).then((res) => {
      if ('file' in res.payload) {
        const data = res.payload as {file: DriveImportResponseFileTo[]};

        const errorIndexes = data.file
          .map((item, index) => ({ error: item.error, index }))
          .filter(item => !!item.error)
          .map(item => item.index);

        const newFilesCopy = [...allFiles];
        newFilesCopy.forEach((__, i) => {
          if (errorIndexes.includes(i)) {
            newFilesCopy[i].status = 'error';
          }
        });

        const result = newFilesCopy.filter(i => i.status === 'error');
        if (!result.length) {
          setSuccess(true);
        } else {
          setAllFiles(result);
        }
      }
    })
      .catch(() => {
        showSnackbar({ message: <FormattedMessage id="FileUploader.modal.import.error" />, isError: true });
      })
      .finally(() => setLoading(false));
  };

  const containsError = () => !!allFiles.find(f => f.status === 'error');
  const isDisabled = (loading || allFiles.length === 0 || containsError()) && !success;

  return (<CfDialog
    acceptButtonVariant={success ? undefined : 'contained'}
    acceptText={success ? <FormattedMessage id="common.close" /> : <FormattedMessage id="FileUploader.submit" />}
    disabled={isDisabled}
    externalStyle={classes}
    maxWidth="xs"
    onAccept={success ? handleClose : handleFilesImportApi}
    onCloseIcon={handleClose}
    opened={open}
    processing={loading}
    title={<FormattedMessage id="FileUploader.modal.title" />}
    >
    <>
      {!success && <FileUploader
        allFiles={allFiles}
        handleFilesValidationApi={handleFilesValidationApi}
        loading={loading}
        setAllFiles={setAllFiles}
      />}
      {success && <SuccessfulImport />}
    </>
  </CfDialog>);
};

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(ImportRidesDialog);
