import { AnyAction } from 'redux';

import * as types from './monitoring.constants';

import { MonitoringAPI } from './monitoring.types';

const initialState: MonitoringAPI = {
  isFetching: false,
  error: undefined,
  items: [],
  cropIntervals: [],
  cropIntervalsIsFetching: false,
  cropIntervalsError: undefined,
};

export default () => (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.GET_MONITORING_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    case types.GET_MONITORING_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.payload,
      };
    case types.GET_MONITORING_DATA_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.RESET_MONITORING_DATA:
      return {
        ...state,
        isFetching: false,
        error: {},
        items: [],
      };
    case types.GET_MONITORING_CROP_INTERVALS_REQUEST:
      return {
        ...state,
        cropIntervalsIsFetching: true,
        cropIntervalsError: {},
      };
    case types.GET_MONITORING_CROP_INTERVALS_SUCCESS:
      return {
        ...state,
        cropIntervalsIsFetching: false,
        cropIntervals: action.payload,
      };
    case types.GET_MONITORING_CROP_INTERVALS_ERROR:
      return {
        ...state,
        cropIntervalsIsFetching: false,
        cropIntervalsError: action.payload,
      };
    case types.RESET_MONITORING_CROP_INTERVALS:
      return {
        ...state,
        cropIntervalsIsFetching: false,
        cropIntervals: [],
      };

    default:
      return state;
  }
};
