import React, { FC, ReactNode } from 'react';

import { Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  itemDescWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 25,
  },
  itemValueWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    itemValueWrapper: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  rowWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}));

export interface SatelliteInfoDialogItemProps {
  children: ReactNode,
  label: ReactNode,
  testId?: string,
}

const SatelliteInfoDialogItem: FC<SatelliteInfoDialogItemProps> = ({
  children,
  label,
  testId = 'info',
}) => {
  const classes = useStyles();

  return (
    <Grid className={classes.rowWrapper} container data-test={testId}>
      <Grid className={classes.itemDescWrapper} item sm={3} xs={12}>
        {label}
      </Grid>
      <Grid className={classes.itemValueWrapper} item sm={9} xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

export default SatelliteInfoDialogItem;
