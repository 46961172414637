import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './farmUsers.constants';

export const getUsersByFarm = farmId => ({
  [RSAA]: {
    endpoint: `admin/farms/${farmId}/users?`,
    method: methods.GET,
    module: modules.AGROEVIDENCE,
    types: [types.GET_USERS_BY_FARM, types.GET_USERS_BY_FARM_SUCCESS, types.GET_USERS_BY_FARM_ERROR],
  },
});
