import React, { FC, useEffect, useState } from 'react';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedDate } from 'react-intl';

import CfStaticMap from '../../../../shared/components/specific/CfStaticMap/CfStaticMap';
import { Geometries, GraphDataOrMonitoringData, MapImage } from '../../containers/BioMonitoring';

import BioMonitoringImageErrorWrapper from './BioMonitoringImageErrorWrapper';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: '10px 5px 5px 5px',
  },
  dateHeading: {
    textAlign: 'center',
    padding: 2,
    fontSize: 13,
  },
  images: {
    display: 'flex',
    flexDirection: (isMobileView) => (isMobileView ? 'row' : 'column'),
  },
  zonesMap: {
    width: 150,
    height: 150,
    margin: 1,
    backgroundColor: theme.palette.grey[100],
    cursor: 'pointer',
  },
  map: {
    borderRadius: 0,
    '& .ol-viewport': {
      borderRadius: 0,
    },
  },
}));

const getValId = (val = {} as GraphDataOrMonitoringData, zones = true) => {
  const {
    crop: { legislativeCode },
    dateFrom: from,
    dateTo: to,
  } = val;

  return `biomonitoring_${legislativeCode}_${from || ''}_${to || ''}_${zones ? 'zones' : 'index'}`;
};

const getImage = (item: GraphDataOrMonitoringData) => ({
  url: item.imgPath,
  extent: [item.imgExtent?.minX, item.imgExtent?.minY, item.imgExtent?.maxX, item.imgExtent?.maxY],
});

export interface BioMonitoringHistoryImageProps {
  item: GraphDataOrMonitoringData,
  onMapClick: (
    source: Geometries[] | MapImage | null,
    dateFrom: string,
    dateTo: string,
    isImage?: boolean,
  ) => void,
  order: number,
  width: string
}

const BioMonitoringHistoryImage: FC<BioMonitoringHistoryImageProps> = ({
  item,
  onMapClick,
  order = 0,
  width,
}) => {
  const isMobileView = width === 'xs';
  const classes = useStyles(isMobileView);
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setHidden(false);
    }, order * 250);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.wrapper} data-test="history-item">
      <div className={classes.images}>
        <div className={classes.zonesMap} onClick={() => onMapClick(getImage(item), item.dateFrom, item.dateTo, true)}>
          {!hidden && (
          <BioMonitoringImageErrorWrapper item={item}>
            <CfStaticMap
              classes={{ map: classes.map }}
              image={getImage(item)}
              mapId={getValId(item, false)}
              />
          </BioMonitoringImageErrorWrapper>
          )}
        </div>
        <div className={classes.zonesMap}>
          {!hidden && (
          <BioMonitoringImageErrorWrapper isZonesImage={true} item={item}>
            <a
              href="#"
              onClick={() => onMapClick(item.zones, item.dateFrom, item.dateTo)}
            >
              <CfStaticMap
                classes={{ map: classes.map }}
                geometries={item.zones}
                mapId={getValId(item)}
              />
            </a>
          </BioMonitoringImageErrorWrapper>
          )}
        </div>
      </div>
      <div className={classes.dateHeading} data-test="date">
        <FormattedDate day="numeric" month="numeric" value={item.dateFrom} />
        {' \u2013 '}
        <FormattedDate day="numeric" month="numeric" value={item.dateTo} />
      </div>
    </div>
  );
};

export default BioMonitoringHistoryImage;
