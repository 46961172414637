import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';

import { getFertilizers } from '../../../../shared/api/agroevidence/catalogues/fertilizers/fertilizers.selectors';
import { getPlantProtection } from '../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.selectors';
import { getSeeds } from '../../../../shared/api/agroevidence/catalogues/seeds/seeds.selectors';
import { getParcels, getIsFetchingParcels } from '../../../../shared/api/agroevidence/parcels/parcels.selectors';
import { getZones, getIsZonesFetching } from '../../../../shared/api/agroevidence/zones/zones.selectors';
import {
  getVariableExpenseIsError,
  getVariableExpenseSaveIsFetching,
  getError as getVariableParcelsError,
  getVariableExpensesIsError,
  getIsFetching,
  getVariableExpensesIsFetching,
  getCreateVariableExpensesIsFetching,
} from '../../../../shared/api/sentinel/variableApplication/variableApplication.selectors';

export const getParcelsAndZonesSuggestions = (filter, formName) =>
  createSelector(getParcels(`ui.${formName}.suggestions`), getZones, (parcels, zones) =>
    [
      {
        title: 'common.parcels',
        items: filter ? parcels.filter?.(filter) : parcels,
      },
      {
        title: 'common.zones',
        items: filter ? zones.filter?.(filter) : zones,
      },
    ].filter(section => section.items?.length > 0),
  );

export const getParcelsAndZonesSuggestionsAutocomplete = (formName) =>
  createSelector(getParcels(`ui.${formName}.suggestions`), getZones, (parcels = [], zones = []) =>
    [...parcels.map(parcel => ({ ...parcel, title: 'common.parcels' })),
      ...zones.map(zone => ({ ...zone, title: 'common.zones' })),
    ],
  );

export const getParcelsAndZonesFetching = (formName) =>
  createSelector(getIsFetchingParcels(`ui.${formName}.suggestions`), getIsZonesFetching, (parcels, zones) =>
    parcels && zones,
  );

export const getParcelsToAdd = (formName, state) => getParcels(`ui.${formName}.additions`)(state);

export const getChemistryAndFertilizersSuggestions = createSelector(
  getFertilizers,
  getPlantProtection,
  (fertilizers, plantProtection) =>
    [
      {
        title: 'common.fertilizers',
        items: fertilizers,
      },
      {
        title: 'common.chemistries',
        items: plantProtection,
      },
    ].filter(section => section.items.length > 0),
);

export const getSeedsSuggestions = createSelector(getSeeds, seeds =>
  [
    {
      title: 'common.seeds',
      items: seeds,
    },
  ].filter(section => section.items.length > 0),
);

export const getParcelsArea = (formName, state) => {
  const parcels = formValueSelector(formName)(state, 'parcels');
  let parcelsArea = 0;
  if (parcels && parcels.length) {
    parcels.forEach(parcel => {
      let areaToAdd = parcel.actionParcelTotalArea;
      if (parcel.subtractableAreas !== undefined) {
        areaToAdd = parcel.area - parcel.restrictedArea;
      }
      parcelsArea += areaToAdd;
    });
  }
  return parcelsArea;
};

export const getParcelsIds = (formName, state) => {
  const parcels = formValueSelector(formName)(state, 'parcels') || [];
  return parcels.map(parcel => parcel.id);
};

export const getMaterialIds = (formName, state) => {
  const materials = formValueSelector(formName)(state, 'expenses') || [];
  return materials.map(material => material.material.id);
};

export const getExpenses = (formName, state) => formValueSelector(formName)(state, 'expenses') || [];
export const getChemistryEffectiveness = (formName, state) => formValueSelector(formName)(state, 'effectiveness') || [];

// gets the highest drift class from all used chemistries in eph form
// possible drift classes: NONE, 50, 75, 90
// driftClass.substring(6) gets from DRIFT_50 string only the number
export const getDriftClass = (formName, state) => {
  let driftClass = 'DRIFT_90';
  const materials = formValueSelector(formName)(state, 'expenses') || [];
  materials.forEach(material => {
    if (material.material?.materialTypeId === 'CH') {
      if (material.driftClass === 'DRIFT_NONE') {
        driftClass = 'DRIFT_NONE';
      } else if (driftClass !== 'DRIFT_NONE' && material.driftClass.substring(6) < driftClass.substring(6)) {
        driftClass = material.driftClass;
      }
    }
  });
  return driftClass;
};

export const getSubtractableAreas = (formName, state) => formValueSelector(formName)(state, 'subtractableAreas');

export const getSubtractableAreasTotalArea = (formName, state) => formValueSelector(formName)(state, 'restrictedArea');

export const getVAIsError = createSelector(
  getVariableExpenseIsError,
  getVariableParcelsError,
  getVariableExpensesIsError,
  (...errors) => errors.find(err => err.isError === true) || {},
);

export const getVAIsLoading = createSelector(
  getVariableExpenseSaveIsFetching,
  getIsFetching,
  getVariableExpensesIsFetching,
  (isFetchingSave, isFetchingParcels, variableExpensesIsFetching) =>
    isFetchingSave || isFetchingParcels || variableExpensesIsFetching,
);

export const getCreateVAIsLoading = createSelector(
  getCreateVariableExpensesIsFetching,
  isFetchingCreate => isFetchingCreate,
);
