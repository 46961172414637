const PREFIX = 'PRECISION';

export const GET_PRECISION_PARCELS_REQUEST = `${PREFIX}/GET_PRECISION_PARCELS_REQUEST`;
export const GET_PRECISION_PARCELS_SUCCESS = `${PREFIX}/GET_PRECISION_PARCELS_SUCCESS`;
export const GET_PRECISION_PARCELS_ERROR = `${PREFIX}/GET_PRECISION_PARCELS_ERROR`;
export const RESET_PRECISION_PARCELS = `${PREFIX}/RESET_PRECISION_PARCELS`;

export const GET_PRECISION_PARCEL_REQUEST = `${PREFIX}/GET_PRECISION_PARCEL_REQUEST`;
export const GET_PRECISION_PARCEL_SUCCESS = `${PREFIX}/GET_PRECISION_PARCEL_SUCCESS`;
export const GET_PRECISION_PARCEL_ERROR = `${PREFIX}/GET_PRECISION_PARCEL_ERROR`;
export const RESET_PRECISION_PARCEL = `${PREFIX}/RESET_PRECISION_PARCEL`;

export const GET_PRECISION_TASK_DATA_REQUEST = `${PREFIX}/GET_PRECISION_TASK_DATA_REQUEST`;
export const GET_PRECISION_TASK_DATA_SUCCESS = `${PREFIX}/GET_PRECISION_TASK_DATA_SUCCESS`;
export const GET_PRECISION_TASK_DATA_ERROR = `${PREFIX}/GET_PRECISION_TASK_DATA_ERROR`;
export const RESET_PRECISION_TASK_DATA = `${PREFIX}/RESET_TASK_DATA`;

export const GET_PRECISION_TASK_TIMELINE_DATA_REQUEST = `${PREFIX}/GET_PRECISION_TASK_TIMELINE_DATA_REQUEST`;
export const GET_PRECISION_TASK_TIMELINE_DATA_SUCCESS = `${PREFIX}/GET_PRECISION_TASK_TIMELINE_DATA_SUCCESS`;
export const GET_PRECISION_TASK_TIMELINE_DATA_ERROR = `${PREFIX}/GET_PRECISION_TASK_TIMELINE_DATA_ERROR`;
export const RESET_PRECISION_TASK_TIMELINE_DATA = `${PREFIX}/RESET_TASK_TIMELINE_DATA`;

export const SET_OPENED_PRECISION_AS_APPLIED_ROWS = `${PREFIX}/SET_OPENED_PRECISION_AS_APPLIED_ROWS`;
