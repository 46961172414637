import { AnyAction } from 'redux';

import * as types from './management.constants';

import { Crop } from '../../../shared/api/sentinel/management/management.types';

export const setCropLegislativeCode = (crop: Crop) => (
  dispatch: (action: AnyAction) => void,
) =>
  dispatch({
    type: types.SET_CROP_LEGISLATIVE_CODE,
    cropLegislativeCode: crop.legislativeCode,
  });

export const resetCropLegislativeCode = () => (
  dispatch: (action: AnyAction) => void,
) =>
  dispatch({
    type: types.RESET_CROP_LEGISLATIVE_CODE,
  });
