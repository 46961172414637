import React, { FC, useState, useCallback, useEffect, useMemo } from 'react';

import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { StaticDateRangePicker } from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';
import { DateRange } from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box, Button, ButtonGroup, Divider, IconButton, List, ListItem, ListItemButton, ListItemText, Popover, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { debounce } from 'lodash';
import moment, { Moment } from 'moment';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { getNumberOfDays } from '../../../../sensors/actions/node.actions';

import usePopover from '../../../hooks/usePopover';
import useWidth from '../../../hooks/useWidth';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    height: (lightStyle) => (lightStyle ? 28 : 32),
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 14,
    borderRadius: 16,
  },
  dateButtonGroup: {
    background: (lightStyle) => (lightStyle ? theme.palette.common.white : theme.palette.grey[300]),
    padding: 0,
    borderRadius: 50,
  },
  arrowButton: {
    padding: 0,
  },
  dateButton: {
    background: (lightStyle) => (lightStyle ? theme.palette.common.white : theme.palette.grey[300]),
    padding: 0,
    borderRadius: 50,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  dateContent: {
    margin: 'auto 8px',
    paddingTop: 1,
  },
  icon: {
    margin: 'auto 12px auto 6px',
    width: 22,
  },
  popoverPaper: {
    overflow: 'auto',
    marginTop: 7,
  },
  rangesSection: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  notDisplayOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  boxDateRangeSelector: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  buttonGroup: {
    textAlign: 'right',
    padding: '0 16px',
  },
  dateRangePicker: {
    '& .MuiTypography-subtitle1': {
      fontSize: 14,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    '& .PrivatePickersSlideTransition-root': {
      minHeight: 260,
    },
    '& .css-f7iyql': {
      padding: 0,
    },
  },
}));

const rangeShortcuts = {
  today: 'TODAY',
  yesterday: 'YESTERDAY',
  last7Days: 'LAST_7_DAYS',
  lastWeek: 'LAST_WEEK',
  lastMonth: 'LAST_MONTH',
  thisYear: 'THIS_YEAR',
};

type RangeShortcutType = keyof typeof rangeShortcuts;

const rangeShortcutsLabel = [
  {
    range: rangeShortcuts.today,
    label: <FormattedMessage id="common.dateRangeSelector.today" />,
  },
  {
    range: rangeShortcuts.yesterday,
    label: <FormattedMessage id="common.dateRangeSelector.yesterday" />,
  },
  {
    range: rangeShortcuts.last7Days,
    label: <FormattedMessage id="common.dateRangeSelector.last7Days" />,
  },
  {
    range: rangeShortcuts.lastWeek,
    label: <FormattedMessage id="common.dateRangeSelector.lastWeek" />,
  },
  {
    range: rangeShortcuts.lastMonth,
    label: <FormattedMessage id="common.dateRangeSelector.lastMonth" />,
  },
  {
    range: rangeShortcuts.thisYear,
    label: <FormattedMessage id="common.dateRangeSelector.thisYear" />,
  },
];

interface Props {
  dateFrom: string;
  dateTo: string;
  langId?: string;
  lightStyle?: boolean;
  minDate?: string;
  setDate: (dateFrom?: string, dateTo?: string, duration?: number) => void;
}

const DateRangeSelector: FC<Props> = ({
  dateFrom,
  dateTo,
  langId,
  lightStyle = false,
  minDate = '2010-01-01T00:00:00.000Z',
  setDate,
}) => {
  const classes = useStyles(lightStyle);
  const width = useWidth();
  const { anchorEl, handlePopoverClose, handlePopoverOpen, isOpen } = usePopover();
  const [selectedDate, setSelectedDate] = useState<DateRange<Moment>>([null, null]);
  // Use react key attribute to re-mount the StaticDateRangePicker component: https://github.com/mui/mui-x/issues/4542
  const [key, setKey] = useState(0);
  const [rangeDuration, setRangeDuration] = useState<moment.unitOfTime.DurationConstructor>('days');

  const isDesktop = width !== 'sm' && width !== 'xs';

  const numberOfSelectedDays = useMemo(() =>
    getNumberOfDays(dateFrom, dateTo),
  [dateFrom, dateTo]);

  const updateMonths = useCallback((locale: string) => {
    if (locale && locale?.startsWith('cs')) {
      moment.updateLocale('cs', {
        months: [
          'leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec',
        ],
      });
    }
  }, []);

  useEffect(() => {
    if (langId) {
      updateMonths(langId);
    }
  }, [langId, updateMonths]);

  useEffect(() => {
    setSelectedDate([
      moment(dateFrom),
      moment(dateTo),
    ]);
  }, [dateFrom, dateTo]);

  const handleRangeClick = useCallback((range: RangeShortcutType) => {
    switch (range) {
      case rangeShortcuts.today:
        setSelectedDate([
          moment().startOf('day'),
          moment().endOf('day'),
        ]);
        setRangeDuration('days');
        setKey(key + 1);
        break;
      case rangeShortcuts.yesterday:
        setSelectedDate([
          moment().subtract('1', 'day').startOf('day'),
          moment().subtract('1', 'day').endOf('day'),
        ]);
        setRangeDuration('days');
        setKey(key + 1);
        break;
      case rangeShortcuts.last7Days:
        setSelectedDate([
          moment().subtract('6', 'days').startOf('day'),
          moment().endOf('day'),
        ]);
        setRangeDuration('days');
        setKey(key + 1);
        break;
      case rangeShortcuts.lastWeek:
        setSelectedDate([
          moment().subtract(1, 'week').startOf('week'),
          moment().subtract(1, 'week').endOf('week'),
        ]);
        setRangeDuration('week');
        setKey(key + 1);
        break;
      case rangeShortcuts.lastMonth:
        setSelectedDate([
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month'),
        ]);
        setRangeDuration('month');
        setKey(key + 1);
        break;
      case rangeShortcuts.thisYear:
        setSelectedDate([
          moment().startOf('year'),
          moment().endOf('year'),
        ]);
        setRangeDuration('year');
        setKey(key + 1);
        break;
      default:
        break;
    }
  }, [setSelectedDate, key],
  );

  const handleDateSelectCancel = () => {
    setSelectedDate([
      moment(dateFrom),
      moment(dateTo),
    ]);
    handlePopoverClose();
  };

  const setDateFromDateTo = (newDateFrom: Moment, newDateTo: Moment) => {
    const newDateFromISOString = newDateFrom.startOf('day').toISOString();
    const newDateToISOString = newDateTo.endOf('day').toISOString();
    const duration = getNumberOfDays(newDateFromISOString, newDateToISOString);
    setDate(newDateFromISOString, newDateToISOString, duration);
    setSelectedDate([newDateFrom, newDateTo]);
  };

  const handleDateSelectAccept = () => {
    const newDateFrom = selectedDate[0];
    const newDateTo = selectedDate[1] ?? selectedDate[0];
    setDateFromDateTo(newDateFrom as Moment, newDateTo as Moment);
    handlePopoverClose();
  };

  const moveDate = (goForward: boolean, duration: moment.unitOfTime.DurationConstructor) => {
    let newDateFrom: Moment;
    let newDateTo: Moment;
    let count = numberOfSelectedDays;
    if (duration !== 'days') {
      count = 1;
    }
    if (goForward) {
      newDateFrom = moment(dateFrom).add(count, duration).startOf(duration);
      newDateTo = moment(dateTo).add(count, duration).endOf(duration);
    } else {
      newDateFrom = moment(dateFrom).subtract(count, duration).startOf(duration);
      newDateTo = moment(dateTo).subtract(count, duration).endOf(duration);
    }
    setDateFromDateTo(newDateFrom, newDateTo);
  };

  const handleLeftArrowClick = debounce(() => moveDate(false, rangeDuration), 500);
  const handleRightArrowClick = debounce(() => moveDate(true, rangeDuration), 500);
  const backDisabled = moment(dateFrom).isBefore(moment(minDate));
  const forwardDisabled = moment(dateTo).add(1, 'day') > moment().endOf('day');

  return (
    <>
      <ButtonGroup className={classes.dateButtonGroup} color="inherit">
        <IconButton
          className={classes.arrowButton}
          data-test="decrease-day"
          disabled={backDisabled}
          onClick={handleLeftArrowClick}
          size="large">
          <KeyboardArrowLeftIcon />
        </IconButton>
        <IconButton
          className={classes.arrowButton}
          data-test="increase-day"
          disabled={forwardDisabled}
          onClick={handleRightArrowClick}
          size="large">
          <KeyboardArrowRightIcon />
        </IconButton>
        <Button
          className={classes.dateButton}
          onClick={handlePopoverOpen}
          variant="contained"
        >
          <div className={classes.wrapper}>
            <span className={classes.dateContent} data-test={'advanced-calendar'}>
              <FormattedDate day="2-digit" month="2-digit" value={dateFrom} year="numeric" />
              {numberOfSelectedDays > 1 && (
              <>
                {' – '}
                <FormattedDate day="2-digit" month="2-digit" value={dateTo} year="numeric" />
              </>
              )}
            </span>
            <InsertInvitationOutlinedIcon className={classes.icon} data-test={'advanced-calendar-icon'} />
          </div>
        </Button>
      </ButtonGroup>
      <Popover
        anchorEl={anchorEl}
        classes={{ paper: classes.popoverPaper }}
        onClose={handlePopoverClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        >
        <Box
          className={classes.boxDateRangeSelector}
          key={key}
          mb={2}
          mt={2}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <StaticDateRangePicker
              calendars={isDesktop ? 2 : 1}
              className={classes.dateRangePicker}
              disableFuture
              displayStaticWrapperAs="desktop"
              minDate={moment(minDate)}
              renderInput={() => <div />}
              value={selectedDate}
              onChange={(newValue) => {
                setSelectedDate(newValue);
                setRangeDuration('days');
              }}
            />
          </LocalizationProvider>

          {<Divider className={classes.notDisplayOnMobile} flexItem orientation="vertical" />}
          <div className={classes.rangesSection}>
            <List className={classes.notDisplayOnMobile}>
              {rangeShortcutsLabel.map(({ label, range }) => (
                <ListItem disablePadding key={range}>
                  <ListItemButton
                    data-test={range}
                    onClick={() => handleRangeClick(range as RangeShortcutType)}
                    >
                    <ListItemText
                      primary={label}
                      primaryTypographyProps={{
                        fontSize: 14,
                      }} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Box className={classes.buttonGroup}>
              <Button
                color="inherit"
                data-test={'cancel-date'}
                onClick={handleDateSelectCancel}
                sx={{ mr: 1 }}
                variant="contained"
              >
                <FormattedMessage id="common.cancel" />
              </Button>
              <Button
                color="primary"
                data-test={'confirm-date'}
                onClick={handleDateSelectAccept}
                variant="contained"
              >
                <FormattedMessage id="common.select" />
              </Button>
            </Box>
          </div>
        </Box>
      </Popover>
    </>
  );
};

export default DateRangeSelector;
