import React from 'react';

import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import errorPage40x from '../../../../assets/img/error-page-404.svg';
import errorPage500 from '../../../../assets/img/error-page-500.svg';
import errorPage503 from '../../../../assets/img/error-page-503.svg';

const useStyle = makeStyles(theme => ({
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
  },
  image: {
    width: '250px',
    height: 'auto',
  },
  heading: {
    marginBottom: '10px',
  },
  button: {
    marginTop: '40px',
  },
}));

const images = {
  404: errorPage40x,
  500: errorPage500,
  503: errorPage503,
};

const CfErrorPage = ({ children, error, error40xHeadingTranslId, error40xMessageTranslId, handle400s }) => {
  const classes = useStyle();
  const { code } = error;

  const isError40x = /40[0-4]/.test(code);

  return !(error.isError && (error.code === 500 || error.code === 503 || (isError40x && handle400s))) ? (
    children
  ) : (
    <div className={classes.wrapper}>
      <Grid alignItems="center" container direction="column" justifyContent="center" spacing={2}>
        <Grid item>
          <img alt={`Error ${code}`} className={classes.image} src={images[isError40x ? 404 : code]} />
        </Grid>
        <Grid item>
          <h1 className={classes.heading}>
            <FormattedMessage id={isError40x ? error40xHeadingTranslId : `error.heading.${code}`} />
          </h1>
        </Grid>
        {!isError40x &&
          <Grid item style={{ textAlign: 'center' }} xs={8}>
            <FormattedMessage id={`error.message.${code}`} />{' '}
            <a href="mailto:help@cleverfarm.cz">help@cleverfarm.cz</a>
            {'.'}
          </Grid>
        }
        {isError40x &&
          <Grid alignItems="center" container justifyContent="center" spacing={0}>
            <Grid item style={{ textAlign: 'center' }} xs={12}>
              <FormattedMessage id={error40xMessageTranslId} />
            </Grid>
            <Button
              className={classes.button}
              color="primary"
              onClick={() => history.back()}
              size="medium"
              variant="contained"
            >
              <FormattedMessage id="common.back" />
            </Button>
          </Grid>
        }
      </Grid>
    </div>
  );
};

CfErrorPage.propTypes = {
  handle400s: PropTypes.bool,
  error40xHeadingTranslId: PropTypes.string,
  error40xMessageTranslId: PropTypes.string,
  children: PropTypes.node,
  error: PropTypes.object,
};

CfErrorPage.defaultProps = {
  error: {},
  handle400s: false,
  error40xHeadingTranslId: '',
  error40xMessageTranslId: '',
  children: null,
};

export default CfErrorPage;
