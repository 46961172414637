import React, { FC } from 'react';

import { Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import CfFormattedNumber from '../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import SelectionItem from '../../../shared/components/common/SelectionItem/SelectionItem';
import SelectionItemColumn from '../../../shared/components/common/SelectionItemColumn/SelectionItemColumn';

interface Props {
    area?: number;
    blockNr?: string;
    handleRemove?: () => void;
    isEditing: boolean;
    square?: string;
    subjectId?: string;
    subjectName?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
  },
  label: {
    color: theme.palette.grey[500],
  },
  parcel: {
    fontWeight: 'normal',
  },
  lpisName: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    fontWeight: 500,
  },
  farmLabel: {
    color: 'white',
    width: 'fit-content',
    maxWidth: '150px',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '3px',
    padding: '0.1rem 0.2rem',
    margin: 0,
    marginTop: '0.1rem',
    fontSize: 13,
    lineHeight: '15px',
    '& p': {
      margin: 0,
    },
    '& p:first-child': {
      fontWeight: 500,
    },
  },
}));

const LpisBlock: FC<Props> = ({
  area,
  blockNr = '-',
  handleRemove,
  isEditing,
  square,
  subjectId = '',
  subjectName = '-',
}) => {
  const classes = useStyles();

  const lpisName = square ? `${blockNr} (${square})` : blockNr;

  return (
    <div className={classes.container}>
      <div className={classes.label}>
        <FormattedMessage id="common.lpisBlock" />:
      </div>
      <SelectionItem
        editing={isEditing}
        onRemoveItem={handleRemove}
        >
        <Grid container spacing={0}>
          <Grid item sm={6} xs={12}>
            <div className={classes.lpisName}>
              <span>{lpisName}</span>
            </div>
          </Grid>
          <Grid item sm={3} xs={6}>
            <SelectionItemColumn label={<FormattedMessage id="common.farm" />}>
              <div className={classes.farmLabel}>
                <p>{subjectName}</p>
                <p><FormattedMessage id="common.companyId.withValue" values={{ ico: subjectId }} /> </p>
              </div>
            </SelectionItemColumn>
          </Grid>
          <Grid item sm={3} xs={6}>
            <SelectionItemColumn label={<FormattedMessage id="common.area-ha" />}>
              <div>
                {area ? <CfFormattedNumber decimalDigits={2} value={area} /> : <span> - </span>}
              </div>
            </SelectionItemColumn>
          </Grid>
        </Grid>
      </SelectionItem>
    </div>
  );
};

export default LpisBlock;
