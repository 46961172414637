import React from 'react';

import PropTypes from 'prop-types';

import { LANG_KEYS } from '../../../../shared/lang/lang.constants';

import BaseContainer from '../../../../shared/containers/BaseContainer/BaseContainer';
import translationsCZ from '../../lang/locale-cs-CZ.json';
import translationsEL from '../../lang/locale-el-GR.json';
import translationsEN from '../../lang/locale-en-US.json';
import translationsES from '../../lang/locale-es-ES.json';
import translationsRO from '../../lang/locale-ro-RO.json';
import translationsRU from '../../lang/locale-ru-UA.json';
import translationsSR from '../../lang/locale-sr-LATN-RS.json';
import MainMap from '../MainMap/MainMap';

const translations = {
  [LANG_KEYS.CZ]: translationsCZ,
  [LANG_KEYS.EN]: translationsEN,
  [LANG_KEYS.SR]: translationsSR,
  [LANG_KEYS.ES]: translationsES,
  [LANG_KEYS.RU]: translationsRU,
  [LANG_KEYS.EL]: translationsEL,
  [LANG_KEYS.RO]: translationsRO,
};

const MapWrapper = props => (
  <BaseContainer langId={props.langId} translations={translations}>
    <MainMap
      countryCode={props.countryCode}
      farmBbox={props.farmBbox}
      farmId={props.farmId}
      initDateFrom={props.initDateFrom}
      initDateTo={props.initDateTo}
      initDriverFilter={props.initDriverFilter}
      initMachineFilter={props.initMachineFilter}
      langId={props.langId}
      ngRedirectToTelematics={props.ngRedirectToTelematics}
      parcelId={props.parcelId}
    />
  </BaseContainer>
);

MapWrapper.propTypes = {
  langId: PropTypes.string.isRequired,
  farmBbox: PropTypes.object.isRequired,
  farmId: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  parcelId: PropTypes.string,
};

MapWrapper.defaultProps = {
  parcelId: null,
};

export default MapWrapper;
