import * as types from '../actions/map/map.constants';
import * as contexts from '../constants/contexts.constants';

const initialState = {
  main: null,
  style: null,
  ia: null,
  overlay: null,
  layers: null,
  weather: null,
  context: contexts.PREVIEW,
  isFetching: false,
  isInitialized: false,
};

export default () => (
  state = initialState,
  action = {},
) => {
  switch (action.type) {
    case types.STORE_SERVICE_WRAPPER:
      return {
        ...state,
        [action.key]: action.value,
      };
    case types.SET_MAP_CONTEXT:
      return {
        ...state,
        context: action.context,
      };
    case types.SET_MAP_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case types.MAP_INIT_SUCCESS:
      return {
        ...state,
        isInitialized: true,
      };
    case types.RESET_MAP:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
