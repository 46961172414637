import React, { FC } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import Localization from '../../../../shared/services/Localization.service';

type IndexDataMap = {
  CAB: object,
  CW: object,
  LAI: object
}

const indexDataMap = {
  CW: {
    unit: 'cm',
    fractionDigits: 4,
    min: 0.0005,
    max: 0.07,
  },
  CAB: {
    unit: (
      <span>
        μg/cm<sup>2</sup>
      </span>
    ),
    fractionDigits: 0,
    min: 2,
    max: 80,
  },
  LAI: {
    unit: (
      <span>
        m<sup>2</sup>/m<sup>2</sup>
      </span>
    ),
    fractionDigits: 1,
    min: 0.1,
    max: 10,
  },
};

export interface BioMonitoringGraphInfoDialogProps {
  index: string,
}

const BioMonitoringGraphInfoDialog: FC<BioMonitoringGraphInfoDialogProps> = ({
  index,
}) => {
  const intl = useIntl();

  return (
    <p>
      <FormattedMessage
        id={`BioMonitoring.${index}.subheading`}
        values={{
          min: Localization.num2str(
            indexDataMap[index as keyof IndexDataMap].min,
            intl.locale,
            indexDataMap[index as keyof IndexDataMap].fractionDigits,
          ),
          max: Localization.num2str(
            indexDataMap[index as keyof IndexDataMap].max,
            intl.locale,
            indexDataMap[index as keyof IndexDataMap].fractionDigits),
          sup: (chunks: string) => <sup>{chunks}</sup>,
        }}
      />
    </p>
  );
};

export default BioMonitoringGraphInfoDialog;
