import mapValues from 'lodash/mapValues';

import * as types from '../actions/crops/crops.constants';

export default () => (
  state = {
    crops: {},
    order: [],
  },
  action,
) => {
  switch (action.type) {
    case types.STORE_INITIAL_CROPS:
      return {
        ...state,
        crops: action.crops,
        order: action.cropsOrder,
      };
    case types.STORE_RESET_CROPS:
      return {
        ...state,
        crops: {},
        order: [],
      };
    case types.SET_CROP_VISIBILITY:
      return {
        ...state,
        crops: {
          ...state.crops,
          [action.cropId]: {
            ...state.crops[action.cropId],
            visible: action.visible,
          },
        },
      };
    case types.SET_ALL_CROPS_VISIBILITY:
      return {
        ...state,
        crops: mapValues(state.crops, crop => ({ ...crop, visible: action.visible })),
      };
    default:
      return state;
  }
};
