import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import editorUI from '../core/map/reducer/editor.reducer';
import layersUI from '../core/map/reducer/layersUI.reducer';
import mapUI from '../core/map/reducer/map.reducer';
import parcelActivitiesUI from '../core/parcels/detail/activities/reducer/parcelActivities.reducer';
import parcelDetailUI from '../core/parcels/detail/shared/reducer/parcelDetail.reducer';
import parcelListUI from '../core/parcels/list/reducer/parcelList.reducer';
import agroevidenceActionsApi from '../shared/api/agroevidence/actions/actions.reducer';
import cropsApi from '../shared/api/agroevidence/catalogues/crops/crops.reducer';
// TODO po dokonceni prepisu na AGROEVIDENCE prejmenovat parcelsApiNew -> parcelsApi
import parcelsApiNew from '../shared/api/agroevidence/parcels/parcels.reducer';
import sowingPlanApi from '../shared/api/agroevidence/sowingPlan/sowingPlan.reducer';
import zonesApi from '../shared/api/agroevidence/zones/zones.reducer';
import geometryApi from '../shared/api/core/geometry/geometry.reducer';
// TODO po dokonceni prepisu na AGROEVIDENCE odstranit parcelsApi
import parcelsApi from '../shared/api/core/parcels/parcels.reducer';
import authReducer from '../shared/api/gateway/auth/auth.reducer';
import layersApi from '../shared/api/other/layers/layers.reducer';
import precisionParcelsApi from '../shared/api/sentinel/precision/precision.reducer';
import satelliteProductsApi from '../shared/api/sentinel/satelliteProducts/satelliteProducts.reducer';
import variableApplicationApi from '../shared/api/sentinel/variableApplication/variableApplication.reducer';

export default history =>
  combineReducers({
    ui: combineReducers({
      parcelList: parcelListUI(),
      parcelDetail: parcelDetailUI(),
      parcelActivities: parcelActivitiesUI(),
      map: mapUI(),
      editor: editorUI(),
      layers: layersUI(),
      cache: () => (['parcelList', 'parcelActivities']),
    }),
    api: combineReducers({
      parcels: parcelsApi(),
      precisionParcels: precisionParcelsApi(),
      parcelsNew: parcelsApiNew(),
      sowingPlan: sowingPlanApi(),
      agroevidence: combineReducers({
        crops: cropsApi(),
        actions: agroevidenceActionsApi(),
      }),
      zones: zonesApi(),
      satelliteProducts: satelliteProductsApi(),
      geometry: geometryApi(),
      layers: layersApi(),
      variableApplication: variableApplicationApi(),
    }),
    form: formReducer,
    router: connectRouter(history),
    auth: authReducer,
  });
