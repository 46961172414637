import React, { FC, Fragment, ReactNode, useEffect } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { getIsFetchingPrecisionParcels, getTotalCount } from '../../../shared/api/sentinel/precision/precision.selectors';
import { getPrecisionParcelListTextFilter } from '../selectors/precisionParcelList.selectors';

import { fetchPrecisionParcels, resetPrecision } from '../actions/precision.actions';

import CfLoader from '../../../shared/components/common/CfLoader/CfLoader';
import { Thunk } from '../../../types';

import NoPrecision from './NoPrecision';
import PrecisionTabs, { PRECISION_LISTING_TABS } from './PrecisionTabs';

import { PrecisionState } from '../../../reducers/precision.reducer.types';

interface Props extends RouteComponentProps<{farmId: string}> {
  children: ReactNode,
  count: number,
  fetchPrecisionParcels: () => void;
  isFetching: boolean,
  langId: string;
  resetPrecision: () => void;
  tab: PRECISION_LISTING_TABS,
  textFilter: string;
}

const PrecisionFarming: FC<Props> = ({
  children, count, fetchPrecisionParcels, history, isFetching, langId,
  location, match, resetPrecision, tab, textFilter,
}) => {
  useEffect(() => {
    // vital request for "count" value
    fetchPrecisionParcels();

    return () => {
      resetPrecision();
    };
  }, [fetchPrecisionParcels, resetPrecision]);

  const hasPrecision = (count > 0 || (count === 0 && textFilter.length !== 0));

  return (
    <>
      {isFetching && !hasPrecision ? (
        <CfLoader />
      ) : (
        <>
          {hasPrecision ? (
            <PrecisionTabs
              history={history}
              location={location}
              match={match}
              tab={tab}>
              {children}
            </PrecisionTabs>
          ) : (
            <NoPrecision
              langId={langId}
            />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: PrecisionState) => ({
  isFetching: getIsFetchingPrecisionParcels(state),
  textFilter: getPrecisionParcelListTextFilter(state),
  count: getTotalCount(state),
});

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) => bindActionCreators({
  fetchPrecisionParcels,
  resetPrecision,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PrecisionFarming);
