import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './soilSamples.constants';

export const getSoilSamplesDateApi = (parcelId: string, year: string) => ({
  [RSAA]: {
    endpoint: `soil-samples/${parcelId}?year=${year}&`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.GET_SOIL_SAMPLES_DATA_REQUEST,
      types.GET_SOIL_SAMPLES_DATA_SUCCESS,
      types.GET_SOIL_SAMPLES_DATA_ERROR,
    ],
  },
});

export const resetSoilSamplesDate = () => ({
  type: types.RESET_SOIL_SAMPLES_DATA,
});
