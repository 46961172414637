import React, { FC } from 'react';

import { Form, Field, useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';

import CfFormControl from '../../../../shared/components/form/CfFormControl/CfFormControl';
import CfFormikNumericTextField from '../../../../shared/components/form/CfFormikNumericTextField/CfFormikNumericTextField';
import CfFormikTextField from '../../../../shared/components/form/CfFormikTextField/CfFormikTextField';
import TCProductionOperationSelector from '../shared/TCProductionOperationSelector';

import { EquipmentsListFormValues } from './EquipmentsListRow';

const EquipmentsListEditDialogForm: FC = () => {
  const { errors, setFieldValue, values } = useFormikContext<EquipmentsListFormValues>();

  return (
    <Form>
      <CfFormControl>
        <Field
          component={CfFormikTextField}
          disabled
          label={<FormattedMessage id="Catalogues.table.equipments.dialog.name" />}
          name="code"
        />
      </CfFormControl>
      <CfFormControl>
        <Field
          component={CfFormikTextField}
          helperText={!!errors.name && <FormattedMessage id={errors.name} />}
          label={<FormattedMessage id="Catalogues.table.equipments.dialog.name" />}
          name="name"
        />
      </CfFormControl>
      <CfFormControl>
        <Field
          component={CfFormikNumericTextField}
          helperText={!!errors.workingWidth && <FormattedMessage id={errors.workingWidth} />}
          label={<FormattedMessage id="Catalogues.table.equipments.dialog.workingWidth" />}
          name="workingWidth"
          onBlur={() => {
            // CfFormikNumericTextField returns strings instead of numbers
            // @ts-ignore
            if (values.workingWidth === '') return;
            setFieldValue('workingWidth', Number(values.workingWidth));
          }}
    />
      </CfFormControl>
      <TCProductionOperationSelector />
    </Form>

  );
};

export default EquipmentsListEditDialogForm;
