import React from 'react';

import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

interface Props {
    isDisabled: boolean,
    isLoading: boolean,
    onCancel: () => void,
    onConfirm: () => void
}

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '25px',
    marginBottom: '25px',
  },
  button: {
    margin: '10px',
  },

}));

export function ActionButtons({ isDisabled, isLoading, onCancel, onConfirm }: Props) {
  const classes = useStyles();
  return (
    <Grid alignItems="center" className={classes.container} container gap={2} justifyContent="center" spacing={0}>
      <Button
        onClick={onCancel}
        type="reset"
        variant="contained"
        >
        <FormattedMessage id="ActionsButtons.label.cancel" />
      </Button>
      <Button
        color="primary"
        disabled={isLoading || isDisabled}
        onClick={onConfirm}
        variant="contained"
        >
        <FormattedMessage id="ActionsButtons.label.confirm" />
      </Button>
    </Grid>
  );
}
