import * as types from './aggregations.constants';

import ErrorService from '../../../services/Error.service';

import { ActionsState } from '../../../../reducers/actions.reducer.types';
import { TelematicsState } from '../../../../reducers/telematics.reducer.types';
import { KeyType } from '../telematics.types';

export const getDriverAggregations = (state: TelematicsState) => state.api.aggregations.driverAggregations;
export const isFetchingDriverAggregations = (state: TelematicsState) =>
  state.api.aggregations.isFetchingDriverAggregations;

export const getDriverAggregationsCount = (state: TelematicsState) => state.api.aggregations.driverAggregationsCount;

export const getAggregatedDrive = (state: TelematicsState) => state.api.aggregations.driveDetail;

export const isFetchingAggregatedDrive = (state: TelematicsState) => state.api.aggregations.isFetchingDriveDetail;

export const getApiError = (state: TelematicsState) => ErrorService.getResErrorDto(state.api.aggregations.apiError);

export const getAggregatedExportError = (state: TelematicsState) =>
  ErrorService.getResErrorDto(state.api.aggregations.exportError);

export const isExportingAggregatedData = (state: TelematicsState) => state.api.aggregations.isExportingData;

export const getAggregatedDriveKeys = (state: TelematicsState) => state.api.aggregations.driveKeys;
export const isFetchingAggregatedDriveKeys = (state: TelematicsState) => state.api.aggregations.isFetchingDriveKeys;

export const getKeyTypeCombinations =
  (state: TelematicsState) => (key: KeyType) => state.api.aggregations.keyTypes[key];
export const isFetchingKeyTypes = (state: TelematicsState) => state.api.aggregations.isFetchingKeyTypes;
export const getTosStatus = (state: TelematicsState) => state.api.aggregations.tosStatus;

export const isAggregatedDetailError = (state: TelematicsState) =>
  state.api.aggregations.apiErrorTriggeredBy === types.GET_AGGREGATED_DRIVE_ERROR;

export const getWinfasClients = (state: TelematicsState) => state.api.aggregations.winfasClients;

// used in Actions
export const getActionRelatedDrives = (state: ActionsState) =>
  state.api.aggregations.actionRelatedDrives;
export const isFetchingActionRelatedDrives = (state: ActionsState) =>
  state.api.aggregations.isFetchingActionRelatedDrives;
