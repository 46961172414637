import React, { FC, useState } from 'react';

import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { getSectionListAdvancedFilter } from '../../../selectors/sectionList.selectors';

import CfFilter from '../../../../shared/containers/CfFilter/CfFilter';

import IsOrganicSelector from './IsOrganicSelector/IsOrganicSelector';
import NitrogenCategorySelector from './NitrogenCategorySelector/NitrogenCategorySelector';
import SourceSelector from './SourceSelector/SourceSelector';

import { CataloguesState } from '../../../../reducers/catalogues.reducer.types';
import { CatalogueTo, EagriFertilizerNitrogenCategoryTo } from '../../../../shared/api/agroevidence/agroevidence.types';
import { FertilizersAdvancedFilterType, isOrganicType } from '../../../reducers/sectionList.types';

const useStyles = makeStyles({
  container: {
    padding: 20,
    minWidth: 300,
    maxWidth: 600,
  },
  select: {
    marginBottom: 10,
  },
});

export interface Props {
  advancedFilter: FertilizersAdvancedFilterType,
  langId: string;
  namespace: string;
}

export const FertilziersAdvancedFilter: FC<Props> = ({
  advancedFilter,
  langId,
  namespace,
}) => {
  const classes = useStyles();

  const [advancedFilterState, setAdvancedFilterState] = useState<FertilizersAdvancedFilterType>(advancedFilter);

  const handleChangeNitrogenCategory = (items: EagriFertilizerNitrogenCategoryTo[]) => {
    setAdvancedFilterState({ ...advancedFilterState, nitrogenCategory: items });
  };
  const handleChangeSource = (items: CatalogueTo[]) => {
    setAdvancedFilterState({ ...advancedFilterState, source: items });
  };
  const handleChangeIsOrganic = (items: isOrganicType) => {
    setAdvancedFilterState({ ...advancedFilterState, isOrganic: items });
  };

  return (
    <CfFilter
      filterState={advancedFilterState}
      langId={langId}
      namespace={namespace}
      setAdvancedFilterState={setAdvancedFilterState}
    >
      <div className={classes.container}>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <NitrogenCategorySelector
              defaultValues={advancedFilter?.nitrogenCategory}
              isMultiple
              label={<FormattedMessage id="Catalogues.table.fertilizers.column.nitrogenCategory" />}
              langId={langId}
              onChange={handleChangeNitrogenCategory}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <IsOrganicSelector
              defaultIsOrganic={advancedFilter?.isOrganic}
              label={<FormattedMessage id="Catalogues.table.fertilizers.column.isOrganic" />}
              onChange={handleChangeIsOrganic}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <SourceSelector
              defaultSource={advancedFilter?.source}
              label={<FormattedMessage id="Catalogues.table.fertilizers.column.source" />}
              onChange={handleChangeSource}
            />
          </Grid>
        </Grid>
      </div>
    </CfFilter>
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  advancedFilter: getSectionListAdvancedFilter(state),
});

export default connect(mapStateToProps)(FertilziersAdvancedFilter);
