import React, { FC, useState } from 'react';

import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import ActionRestrictionsDetailsDialog from './ActionRestrictionsDetailsDialog';

import { ValidationResponseTo } from '../../../../../shared/api/agroevidence/agroevidence.types';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.light,
  },
  content: {
    flex: '1',
    display: 'flex',
  },
  actions: {
    padding: '16px',
  },
  header: {
    padding: '16px',
    display: 'inline-flex',
    color: '#737373',
  },
}));

export interface ActionRestrictionsInfoProps {
  validationDetails: ValidationResponseTo,
}

export const ActionRestrictionsInfo: FC<ActionRestrictionsInfoProps> = ({
  validationDetails,
}) => {
  const classes = useStyles();
  const [dialogOpened, setDialogOpened] = useState<boolean>(false);
  const hasRestrictions = validationDetails.valid === false;

  const handleDialogOpen = () => setDialogOpened(true);

  const handleDialogBack = () => setDialogOpened(false);

  return hasRestrictions ? (
    <Grid item md={8} sm={10} xs={12}>
      <div id="validation-info">
        <Card className={classes.card}>
          <div className={classes.header}>
            <Icon>warning</Icon>
          </div>
          <CardContent className={classes.content}>
            <Typography component="p">
              <FormattedMessage id="ActionRestrictionsInfo.title" />
            </Typography>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button color="primary" onClick={handleDialogOpen} size="small">
              <FormattedMessage id="common.show" />
            </Button>
          </CardActions>
        </Card>
        <ActionRestrictionsDetailsDialog
          actionRestrictions={validationDetails.restrictions}
          onBack={handleDialogBack}
          opened={dialogOpened}
        />
      </div>
    </Grid>
  ) : null;
};

export default ActionRestrictionsInfo;
