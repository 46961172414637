import React from 'react';

import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const styles = {
  parcelLink: {
    fontWeight: '500',
  },
  paper: {
    padding: 15,
    fontSize: '13px',
  },
  separator: {
    marginRight: 8,
  },
};

function HistoricalParcelsPanel(props) {
  const { classes, goToParcel, historicalParcels } = props;
  return (
    <Paper className={classes.paper}>
      {historicalParcels?.length ? (
        <div>
          {historicalParcels
            .map(parcel => (
              <Link className={classes.parcelLink} key={parcel.id} onClick={() => goToParcel(parcel)} to={'#'}>
                {parcel.blockNumber} {parcel.localName}
              </Link>
            ))
            .reduce((prev, curr) => [
              prev,
              <span className={classes.separator} key={`separator-${curr.key}`}>
                ,
              </span>,
              curr,
            ])}
        </div>
      ) : (
        '-'
      )}
    </Paper>
  );
}

HistoricalParcelsPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  goToParcel: PropTypes.func.isRequired,
  historicalParcels: PropTypes.array,
};

HistoricalParcelsPanel.defaultProps = {
  historicalParcels: null,
};

export default withStyles(styles)(HistoricalParcelsPanel);
