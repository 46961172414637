import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../../api.constants';
import * as types from './farmsAdmin.constants';

import { RsaaMethods, RsaaTypes } from '../../../api.types';

export type UpdateAdminFarmApiParams = {
  farmId: string;
  farmName: string;
}

export const updateAdminFarmApi = (p: UpdateAdminFarmApiParams) => ({
  [RSAA]: {
    endpoint: `admin/farms/${p.farmId}?${queryString.stringify({ farmName: p.farmName })}&`,
    method: methods.PATCH as RsaaMethods,
    body: JSON.stringify({}),
    module: modules.AGROEVIDENCE,
    types: [
      types.UPDATE_ACCOUNT_FARMS_REQUEST,
      types.UPDATE_ACCOUNT_FARMS_SUCCESS,
      types.UPDATE_ACCOUNT_FARMS_ERROR,
    ] as RsaaTypes,
  },
});
