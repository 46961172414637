import React, { FC, useEffect, useContext } from 'react';

import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getIsFetchingMachineCategories, getMachineCategories } from '../../../selectors/telematicsCatalogues.selectors';

import { getMachineCategoriesApi } from '../../../../shared/api/telematics/catalogues/telematicsCatalogues.api';
import CfAutocomplete from '../../../../shared/components/common/CfAutocomplete/CfAutocomplete';
import CfFormControl from '../../../../shared/components/form/CfFormControl/CfFormControl';
import { Thunk } from '../../../../types';
import { CataloguesContext } from '../../../containers/CataloguesWrapper/CataloguesWrapper';

import TCSelectorItem from './TCSelectorItem';

import { CataloguesState } from '../../../../reducers/catalogues.reducer.types';
import { MachineGroupCategoryTo } from '../../../../shared/api/telematics/telematics.types';

interface Props {
    fetchMachineCategories: () => void;
    fieldName?: string;
    groupFieldName?: string;
    isFetching: boolean;
    suggestions: MachineGroupCategoryTo[];
}

export interface RequiredFormValues {
  [machineCategory: string]: {
    code: string;
    name: string;
  }
}

const TCMachineCategorySelector: FC<Props> = ({
  fetchMachineCategories,
  fieldName = 'machineCategory',
  groupFieldName = 'group',
  isFetching,
  suggestions,
}) => {
  const { errors, setValues, values } = useFormikContext<Partial<RequiredFormValues>>();
  const { langId } = useContext(CataloguesContext);
  useEffect(() => {
    fetchMachineCategories();
  }, [langId, fetchMachineCategories]);

  return (
    <CfFormControl>
      <CfAutocomplete
        defaultValues={values[fieldName]}
        error={!!errors[fieldName]}
        helperText={!!errors[fieldName] && <FormattedMessage id={errors[fieldName]} />}
        isFetching={isFetching}
        isMultiple={false}
        label={<FormattedMessage id="Catalogues.table.machines.dialog.machinesCategory" />}
        renderOption={(option: MachineGroupCategoryTo) => <TCSelectorItem firstLine={option.name ?? '-'} />}
        suggestions={suggestions}
        testId="telematics-catalogue-machines-category-selector"
        onChange={(fieldValue) => {
          setValues({
            ...values,
            [fieldName]: fieldValue,
            ...(fieldValue && { [groupFieldName]: fieldValue.group.code }),
          });
        }}
      />
    </CfFormControl>
  );
};

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) => bindActionCreators({
  fetchMachineCategories: getMachineCategoriesApi,
}, dispatch);

const mapStateToProps = (state: CataloguesState) => ({
  isFetching: getIsFetchingMachineCategories(state),
  suggestions: getMachineCategories(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(TCMachineCategorySelector);
