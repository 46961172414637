import React from 'react';

import { FormikProps } from 'formik';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { editBatchProductionOperation } from '../../actions/telematicsAggregations.actions';

import { stringDateToMoment } from '../../../shared/misc/timeHelpers';
import { CfFormikErrors } from '../../../types';
import { ProductionOperationField } from '../../components/TelematicsAggregationDetailContent/formComponents';
import { MESSAGES } from '../../components/TelematicsAggregationDetailContent/validators';

import { useCustomStyles } from './styles/useCustomStyles';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { ProductionOperationTo } from '../../../shared/api/telematics/telematics.types';

const useProductionOperation = () => {
  const intl = useIntl();
  const customClasses = useCustomStyles();
  const dispatch = useDispatch();

  const selectedRides = useSelector((state: TelematicsState) => state.ui.telematicsAggregations.selectedRides);
  const dateFrom = useSelector((state: TelematicsState) => state.ui.telematicsAggregations.dateFilter.dateFrom);
  const dateFromMoment = stringDateToMoment(dateFrom);
  let dateForClassifiers: string;
  if (dateFromMoment) {
    dateForClassifiers = dateFromMoment.toISOString() ?? moment().startOf('day').toISOString();
  }

  const initialValues = {
    productionOperation: undefined,
  };

  const validateContentForm = (values: typeof initialValues) => {
    const errors: CfFormikErrors<typeof initialValues> = {};

    if (!values.productionOperation) {
      errors.productionOperation = MESSAGES.required;
    }
    return errors;
  };

  const onSubmit = (values: typeof initialValues) => {
    const payload = {
      drive: selectedRides,
      productionOperation: values.productionOperation ?
        (values?.productionOperation as ProductionOperationTo)?.id ?? -1 : -1,
    };
    return editBatchProductionOperation(payload)(dispatch);
  };

  const component = (formikProps: FormikProps<typeof initialValues>) => (<ProductionOperationField
    customClasses={customClasses}
    date={dateForClassifiers}
    disabled={formikProps.isSubmitting}
    handleProductionOperationChange={(val) => formikProps.setFieldValue('productionOperation', val)}
    placeholder={intl.formatMessage({ id: 'TelematicsAggregations.list.bulk.secondary.placeholder.prodOperation' })}
    {...formikProps}
/>);

  return {
    initialValues,
    validateContentForm,
    onSubmit,
    component,
  };
};

export default useProductionOperation;
