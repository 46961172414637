import React from 'react';

import { Box } from '@mui/material';
import { Extent } from 'ol/extent';
import { Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import invariant from 'tiny-invariant';

import './AsAppliedDetailMapStyles.css';

import { getLayersConfig } from '../../../map/selectors/layers.selectors';
import { selectAttributeUnit, selectIsFetchingPoints, selectMapData, selectExtent, selectMinMaxValues } from '../../selectors/asAppliedDetail.selectors';

import { storeInitialLayers } from '../../../map/actions/layersUI/layersUI.actions';
import { resetMap, setMapGrabEL, storeServiceWrapper } from '../../../map/actions/map/map.actions';

import * as types from '../../../../shared/api/sentinel/precision/precision.constants';

import { getLpisLayerName } from '../../../../shared/api/api.helpers';
import { fetchLayersConfig, resetLayers } from '../../../../shared/api/other/layers/layers.api';
import { getPrecisionTaskDataTimelineApi } from '../../../../shared/api/sentinel/precision/precision.api';
import SectionWrapper from '../../../../shared/components/specific/SectionWrapper/SectionWrapper';
import withConfig from '../../../../shared/hocs/context/withConfig';
import withFarm from '../../../../shared/hocs/context/withFarm';
import { transformOptions } from '../../../../shared/misc/map.helpers';
import { PRIVATE_LPIS_CONFIG, PUBLIC_LPIS_CONFIG } from '../../../../shared/services/LayersConfig.service';
import { Thunk } from '../../../../types';
import HomeControl from '../../../map/components/HomeControl/HomeControl';
import LayersCropsControl from '../../../map/components/LayersCropsControl/LayersCropsControl';
import EventListener from '../../../map/services/EventListener.service';
import Layers from '../../../map/services/Layers.service';
import MapService from '../../../map/services/Map.service';
import Style from '../../../map/services/Style.service';

import { AttributeControl, initialAttributeCode } from './AttributeControl';
import { AttributeGradient, AttributeInterval, LoadingAttribute, NoAttributeValue } from './AttributeInterval';

import { PrecisionState } from '../../../../reducers/precision.reducer.types';
import { DeprecatedFarmTo } from '../../../../shared/api/agroevidence/agroevidence.types';
import { Layer } from '../../../../shared/api/other/layers/layers.types';

type Props = {
  attributeUnit: ReturnType<typeof selectAttributeUnit>;
  config: {
    api: {
      bing_key: string, geoserverUrl: string; geoserverWorkspaceCore: string; lpisLayerName: string;
    },
    environment: string;
  };
  data?: VectorLayer<VectorSource<Point>>;
  dataInterval?: ReturnType<typeof selectMinMaxValues>;
  extent?: Extent;
  farm: DeprecatedFarmTo;
  fetchLayersConfig: (countryCode: string) => void;
  getPrecisionTaskDataTimelineApi: (taskDataId: string, taskId: string, code: string) => void;
  isFetching: boolean;
  layersConfig: ReturnType<typeof getLayersConfig>;
  onResize?: (map: MapService) => void;
  resetLayers(): void;
  resetMap(): void;
  resetTaskDataTimeline: () => void;
  setMapGrabEL(): void;
  storeInitialLayers(layers: Layer[]): void;
  storeServiceWrapper(key: string, value: unknown): void;
}

const MAP_ID = 'as-applied-detail-map';

const _AsAppliedDetailMap = ({
  attributeUnit,
  config,
  data: pointLayer,
  dataInterval,
  extent,
  farm,
  fetchLayersConfig,
  getPrecisionTaskDataTimelineApi,
  isFetching,
  layersConfig,
  onResize,
  resetLayers,
  resetMap,
  resetTaskDataTimeline,
  setMapGrabEL,
  storeInitialLayers,
  storeServiceWrapper,
}: Props) => {
  const { taskDataId, taskId } = useParams<Record<'taskDataId' | 'taskId', string>>();
  const [map, setMap] = React.useState<MapService | undefined>();
  const initialized = React.useRef(false);

  const zoomToLocation = React.useCallback(() => {
    if (extent) {
      map?.zoomToExtent(extent);
    } else {
      map?.zoomToFarm();
    }
  }, [map, extent]);

  const handleHomeClick = () => {
    invariant(map, 'Map service is not initialized');

    if (!pointLayer) {
      return;
    }

    zoomToLocation();
  };

  React.useEffect(() => {
    getPrecisionTaskDataTimelineApi(taskDataId, taskId, initialAttributeCode);
  }, [getPrecisionTaskDataTimelineApi, taskDataId, taskId]);

  React.useEffect(() => {
    if (!map) {
      return;
    }

    onResize?.(map);
  }, [map, onResize]);

  React.useEffect(() => {
    if (!(map && extent)) {
      return;
    }

    if (initialized.current) {
      return;
    }

    zoomToLocation();
    initialized.current = true;
  }, [map, extent, zoomToLocation]);

  React.useEffect(() => {
    if (!pointLayer || !map) {
      return;
    }

    pointLayer.setMap(map.getMap());

    return () => {
      pointLayer.setMap(null);
    };
  }, [pointLayer, map]);

  // clean old stored values and create new 'map' and 'el' on mount
  React.useEffect(() => {
    if (!map) {
      storeServiceWrapper('main', undefined);
      storeServiceWrapper('el', undefined);
      storeServiceWrapper('layers', undefined);
      storeServiceWrapper('style', undefined);

      resetMap();
      resetLayers();

      setMap(() => new MapService(MAP_ID, farm.id, farm.boundingBox, transformOptions));

      return;
    }

    fetchLayersConfig(farm.customer.countryCode);
    const el = new EventListener(map.getMap());
    storeServiceWrapper('main', map);
    storeServiceWrapper('el', el);

    return () => {
      setMap(undefined);
    };
  }, [
    farm.boundingBox,
    farm.customer.countryCode,
    farm.id,
    fetchLayersConfig,
    map,
    resetLayers,
    resetMap,
    storeServiceWrapper,
  ]);

  React.useEffect(() => resetTaskDataTimeline, [resetTaskDataTimeline]);

  React.useEffect(() => {
    if (!map) {
      return;
    }

    map.zoomToFarm();
  }, [map]);

  React.useEffect(() => {
    if (!layersConfig || !map) {
      return;
    }

    const updatedConfig = { ...config };
    updatedConfig.api.lpisLayerName = getLpisLayerName(farm.customer.countryCode, config.environment);

    const layers = new Layers(map.getMap(), updatedConfig.api, farm.id, map.getFarmExtent());

    // @ts-expect-error err
    const extendedNewLayersConfig = layersConfig.map(c => {
      if (c.layerId === PUBLIC_LPIS_CONFIG.LAYER_ID) {
        return {
          ...c,
          visible: true,
        };
      }
      if (c.layerId === PRIVATE_LPIS_CONFIG.LAYER_ID) {
        return {
          ...c,
          visible: true,
        };
      }
      return c;
    });
    layers.setInitialLayers(extendedNewLayersConfig, storeInitialLayers);

    const style = new Style(
      layers.getParcelLayer(),
      layers.getParcelLabelLayer(),
      farm.customer.countryCode,
    );

    storeServiceWrapper('layers', layers);
    storeServiceWrapper('style', style);

    setMapGrabEL();
  }, [
    config,
    farm.customer.countryCode,
    farm.id,
    layersConfig,
    map,
    setMapGrabEL,
    storeInitialLayers,
    storeServiceWrapper,
  ]);

  let Jsx = <LoadingAttribute />;

  if (!isFetching) {
    if (dataInterval && attributeUnit) {
      Jsx = (
        <AttributeInterval max={dataInterval[1]} min={dataInterval[0]} unit={attributeUnit}>
          <AttributeGradient />
        </AttributeInterval>
      );
    } else {
      Jsx = <NoAttributeValue />;
    }
  }

  return (
    <Box height="100%" id={MAP_ID} position="relative" style={{ backgroundColor: 'grey' }}>
      <HomeControl zoomToHome={handleHomeClick} />
      <SectionWrapper left={14} top={17}>
        <LayersCropsControl withCrops={false} />
      </SectionWrapper>

      <SectionWrapper left={56} top={17}>
        <AttributeControl
          initialAttribute={initialAttributeCode}
          onChange={code => getPrecisionTaskDataTimelineApi(taskDataId, taskId, code)}
        />
      </SectionWrapper>
      <SectionWrapper right={14} top={17}>{Jsx}</SectionWrapper>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) =>
  bindActionCreators({
    fetchLayersConfig,
    getPrecisionTaskDataTimelineApi,
    resetLayers,
    resetMap,
    setMapGrabEL,
    storeInitialLayers,
    storeServiceWrapper,
    resetTaskDataTimeline: () => dispatch({ type: types.RESET_PRECISION_TASK_TIMELINE_DATA }),
  }, dispatch);

const mapStateToProps = (state: PrecisionState) => ({
  layersConfig: getLayersConfig(state),
  data: selectMapData(state),
  dataInterval: selectMinMaxValues(state),
  attributeUnit: selectAttributeUnit(state),
  extent: selectExtent(state),
  isFetching: selectIsFetchingPoints(state),
});

const AsAppliedDetailMap = connect(mapStateToProps, mapDispatchToProps)(withFarm()(withConfig()(_AsAppliedDetailMap)));

export { AsAppliedDetailMap };
