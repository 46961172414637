import { AnyAction } from 'redux';

import * as types from './actions.constants';

export default () => (
  state = {
    isFetching: false,
    isDeleting: false,
    items: [],
    item: {},
    error: {},
    totalCount: 0,
    otherActionTypes: [],
    isFetchingOtherActionTypes: false,
    itemRestrictions: {},
    isFetchingItemRestrictions: false,
    isFetchingActionSplit: false,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case types.GET_ACTIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        items: [],
        error: {},
      };
    case types.GET_ACTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.payload,
        totalCount: Number(action.meta.headers.get('X-Total-Count')),
      };
    case types.GET_ACTIONS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        totalCount: 0,
      };
    case types.RESET_ACTIONS:
      return {
        ...state,
        isFetching: false,
        items: [],
        error: {},
        totalCount: 0,
      };
    case types.DELETE_ACTIONS_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case types.DELETE_ACTIONS_SUCCESS:
      return {
        ...state,
        isDeleting: false,
      };
    case types.DELETE_ACTIONS_ERROR:
      return {
        ...state,
        isDeleting: false,
      };

    case types.GET_ACTION_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: {},
      };

    case types.GET_ACTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        item: action.payload,
      };
    case types.GET_ACTION_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };

    case types.GET_OTHER_ACTION_TYPES_REQUEST:
      return {
        ...state,
        isFetchingOtherActionTypes: true,
      };
    case types.GET_OTHER_ACTION_TYPES_SUCCESS:
      return {
        ...state,
        otherActionTypes: action.payload,
        isFetchingOtherActionTypes: false,
      };
    case types.GET_OTHER_ACTION_TYPES_ERROR:
      return {
        ...state,
        isFetchingOtherActionTypes: false,
      };
    case types.RESET_OTHER_ACTION_TYPES:
      return {
        ...state,
        otherActionTypes: [],
      };

    case types.GET_ACTION_RESTRICTIONS_REQUEST:
      return {
        ...state,
        isFetchingItemRestrictions: true,
      };
    case types.GET_ACTION_RESTRICTIONS_SUCCESS:
      return {
        ...state,
        itemRestrictions: action.payload,
        isFetchingItemRestrictions: false,
      };
    case types.GET_ACTION_RESTRICTIONS_ERROR:
      return {
        ...state,
        isFetchingItemRestrictions: false,
      };
    case types.RESET_ACTION_RESTRICTIONS:
      return {
        ...state,
        itemRestrictions: {},
        isFetchingItemRestrictions: false,
      };
    case types.GET_ACTION_SPLIT_REQUEST:
      return {
        ...state,
        isFetchingActionSplit: true,
      };
    case types.GET_ACTION_SPLIT_SUCCESS:
      return {
        ...state,
        isFetchingActionSplit: false,
      };
    case types.GET_ACTION_SPLIT_ERROR:
      return {
        ...state,
        isFetchingActionSplit: false,
      };

    default:
      return state;
  }
};
