import React from 'react';

export const useLanguageChangeRefresh = (langId: string) => {
  const [init, setInit] = React.useState(false);

  React.useEffect(() => {
    if (init) {
      window.location.reload();
    } else {
      setInit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langId]);
};
