import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { RsaaApiError } from '../../types';

export default class Error {
  static getResErrorDto = (resError: unknown): RsaaApiError => ({
    isError: !isEmpty(resError),
    code: get(resError, 'status', null),
  });
}
