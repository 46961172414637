import React, { Fragment, Component } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, Grid, Paper, TableBody, TableRow } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { withStyles } from '@mui/styles';
import head from 'lodash/head';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updateVariableExpenseZoneDose,
  updateVariableExpenseSumValues,
} from '../../../shared/actions/actions.actions';

import CfFormattedNumber from '../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import CfLoadingPlaceholder from '../../../../../shared/components/common/CfLoadingPlaceholder/CfLoadingPlaceholder';
import SelectionItemColumn from '../../../../../shared/components/common/SelectionItemColumn/SelectionItemColumn';
import SelectionItemHeading from '../../../../../shared/components/common/SelectionItemHeading/SelectionItemHeading';
import CfFormattedField from '../../../../../shared/components/form/CfFormattedField/CfFormattedField';
import CfFormattedTextField from '../../../../../shared/components/form/CfFormattedTextField/CfFormattedTextField';
import CfStaticMap from '../../../../../shared/components/specific/CfStaticMap/CfStaticMap';
import CfTableCell from '../../../../../shared/components/tables/CfTableCell/CfTableCell';
import CfTableWrapper from '../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableHead from '../../../../../shared/containers/CfTableHead/CfTableHead';
import ManagementZoneIcon from '../../../../../shared/icons/ManagementZoneIcon';
import * as validators from '../../../../../shared/misc/validators';
import { zoneTotalDoseChanged } from '../../../shared/misc/action.helpers';

const styles = theme => ({
  paper: {
    margin: '10px 0',
    position: 'relative',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 18px !important',
    backgroundColor: theme.palette.common.white,
  },
  header: {
    backgroundColor: theme.palette.grey[100],
  },
  heading: {
    margin: '2px 0 5px 0',
  },
  removeButton: {
    height: 36,
    width: 36,
    padding: 0,
    marginLeft: 6,
  },
  href: {
    color: '#88B04B',
    '&:focus': {
      outline: 'none',
      color: '#88B04B',
    },
    '&:hover': {
      color: '#88B04B',
    },
  },
  formControl: {
    margin: 0,
  },
  col: {
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  removeButtonDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  map: {
    height: '150px',
    width: '150px',
    margin: '0 15px',
  },
  tdPaddingDense: {
    padding: '15px 20px 15px 6px',
  },
  tdMap: {
    width: '150px',
  },
  zoneAreaIcon: {
    top: '2px',
    position: 'relative',
  },
  zoneArea: {
    marginLeft: 5,
  },
  inputLabel: {
    fontWeight: 'inherit',
  },
  doseHelperText: {
    position: 'absolute',
    top: 25,
  },
  tdValue: {
    margin: '0 10px',
  },
  tdContent: {
    fontSize: '16px',
    width: '105px',
    display: 'flex',
    alignItems: 'center',
  },
  tfLabel: {
    fontSize: 12,
    color: theme.palette.grey[500],
    height: '36px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  tfContent: {
    fontSize: 16,
  },
  tfTotal: {
    padding: '2px 6px 2px 15px',
  },
});

const getColDesc = (label, style) => ({
  align: 'inherit',
  sortable: false,
  label,
  style,
});

class VrsExpenseCard extends Component {
  constructor(props) {
    super(props);

    const {
      expense: { doseUnit },
    } = props;

    this.columns = {
      map: getColDesc(
        <span style={{ paddingLeft: 15 }}>
          <FormattedMessage id="common.map" />
        </span>,
      ),
      zoneArea: getColDesc(
        <Fragment>
          <FormattedMessage id="VariableFertilization.zones-area" /> (ha)
        </Fragment>,
      ),
      zoneQuality: getColDesc(
        <Fragment>
          <FormattedMessage id="VariableFertilization.zones-quality" /> (%)
        </Fragment>,
      ),
      dose: getColDesc(
        <Fragment>
          <FormattedMessage id="common.dose" /> ({doseUnit.id}/ha)
        </Fragment>,
      ),
      totalDose: getColDesc(
        <Fragment>
          <FormattedMessage id="common.totalDose" /> ({doseUnit.id})
        </Fragment>,
      ),
    };
  }

  componentDidUpdate(prevProps) {
    const { isFetching } = this.props;

    if (!isFetching) {
      const {
        expense: {
          variableExpense: { applicationZones: newZones, areaHa },
        },
        formName,
        index: expenseIndex,
      } = this.props;

      const {
        expense: { variableExpense: oldVariableExpense },
      } = prevProps;

      const oldZones = oldVariableExpense?.applicationZones;

      if (newZones?.length && oldZones?.length && zoneTotalDoseChanged(oldZones, newZones)) {
        const sumTotalDose = newZones.reduce((acc, zone) => acc + zone.totalDose, 0);
        const averageDose = sumTotalDose / areaHa;

        this.props.updateVariableExpenseSumValues(null, expenseIndex, formName, sumTotalDose, averageDose);
      }
    }
  }

  onDoseChange = (newValue, areaHa, zoneIndex) => {
    const value = parseFloat(newValue);

    if (value && !isNaN(value)) {
      const { formName, index: expenseIndex } = this.props;

      const totalDose = value * areaHa;
      this.props.updateVariableExpenseZoneDose(null, totalDose, expenseIndex, zoneIndex, formName);
    }
  };

  renderTdZoneArea = zone => {
    const { classes, isFetching } = this.props;
    return (
      <CfTableCell classes={{ root: classes.tdPaddingDense }} name={'zoneArea'}>
        {isFetching ? (
          <CfLoadingPlaceholder width={105} />
        ) : (
          <div className={classes.tdContent}>
            <span className={classes.zoneAreaIcon}>
              <ManagementZoneIcon color={zone.color || '#cccccc'} rx={4} size={16} />
            </span>
            <span className={`${classes.zoneArea}`}>
              <CfFormattedNumber value={zone.areaHa} />
            </span>
          </div>
        )}
      </CfTableCell>
    );
  };

  renderTdDose = (zone, expenseIndex, zoneIndex) => {
    const { classes, isEditing, isFetching } = this.props;
    return (
      <CfTableCell classes={{ root: classes.tdPaddingDense }} name={'dose'}>
        {isFetching ? (
          <CfLoadingPlaceholder width={105} />
        ) : (
          <div className={classes.tdContent}>
            <CfFormattedField
              component={CfFormattedTextField}
              disabled={!isEditing}
              FormHelperTextProps={{ className: classes.doseHelperText }}
              fullWidth
              name={`expenses[${expenseIndex}].variableExpense.applicationZones[${zoneIndex}].doseHa`}
              onBlur={(evt, newValue) => this.onDoseChange(newValue, zone.areaHa, zoneIndex)}
              type="text"
              validate={[validators.required, validators.zeroPositiveNumber]}
              InputLabelProps={{
                style: styles.inputLabel,
              }}
            />
          </div>
        )}
      </CfTableCell>
    );
  };

  renderTdValue = (name, value, width = 105) => {
    const { classes, isFetching } = this.props;
    return (
      <CfTableCell classes={{ root: `${classes.tdValue} ${classes.tdPaddingDense}` }} name={name}>
        {isFetching ? (
          <CfLoadingPlaceholder width={width} />
        ) : (
          <div className={classes.tdContent}>
            <CfFormattedNumber value={value} />
          </div>
        )}
      </CfTableCell>
    );
  };

  renderTdFooter = (name, label, value, width = 105) => {
    const { classes, isFetching } = this.props;

    return (
      <CfTableCell classes={{ root: classes.tdPaddingDense }} name={name}>
        {isFetching ? (
          <CfLoadingPlaceholder width={width} />
        ) : (
          <div>
            <div className={classes.tfLabel}>{label}</div>

            <div className={classes.tfContent}>
              <div className={classes.tdContent}>
                <CfFormattedNumber value={value} />
              </div>
            </div>
          </div>
        )}
      </CfTableCell>
    );
  };

  render() {
    const {
      classes,
      currMapZones,
      expense: { doseUnit, material, variableExpense },
      index,
      isEditing,
      isFetching,
      langId,
      onMapClick,
      onRemove,
    } = this.props;

    const zones = !isFetching ? variableExpense.applicationZones : [{ zoneId: 'a' }, { zoneId: 'b' }, { zoneId: 'c' }];
    return (
      <Paper className={classes.paper} data-test="variable-expense-card">
        <CardContent classes={{ root: `${classes.wrapper} ${classes.header}` }}>
          <Grid alignItems={'center'} container justifyContent={'space-between'} spacing={0}>
            <Grid item sm={11} xs={10}>
              <Grid item xs={12}>
                <SelectionItemHeading classes={{ item: classes.heading }}>
                  <span className={classes.href}>
                    {material?.name} - <FormattedMessage id="VariableFertilization.variable-application" />
                  </span>
                </SelectionItemHeading>
              </Grid>
            </Grid>

            <Grid className={classes.removeButtonDiv} item sm={1} xs={2}>
              <IconButton
                aria-label="Remove item"
                className={classes.removeButton}
                disabled={!isEditing || isFetching}
                onClick={onRemove}
                size="large">
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>

        <CfTableWrapper classes={{ root: classes.table }}>
          <CfTableHead columns={this.columns} langId={langId} />
          <TableBody>
            <TableRow key={head(zones).zoneId}>
              <CfTableCell
                classes={{ root: `${classes.tdMap} ${classes.tdPaddingDense}` }}
                name={'map'}
                rowSpan={zones.length}
              >
                {isFetching ? (
                  <CfLoadingPlaceholder classes={{ placeholderItem: classes.map }} width={150} />
                ) : (
                  <a href="#" onClick={() => onMapClick(currMapZones === zones ? null : zones)}>
                    <CfStaticMap
                      geometries={zones}
                      isSelected={currMapZones === zones}
                      mapId={`expense-card-map_${index}`}
                      classes={{
                        map: classes.map,
                      }}
                    />
                  </a>
                )}
              </CfTableCell>
              {this.renderTdZoneArea(head(zones))}
              {this.renderTdValue('quality', head(zones).quality)}
              {this.renderTdDose(head(zones), index, 0)}
              {this.renderTdValue('totalDose', head(zones).totalDose)}
            </TableRow>

            {zones.slice(1).map((z, zoneIndex) => (
              <TableRow key={z.zoneId}>
                {this.renderTdZoneArea(z)}
                {this.renderTdValue('quality', z.quality)}
                {this.renderTdDose(z, index, zoneIndex + 1)}
                {this.renderTdValue('totalDose', z.totalDose)}
              </TableRow>
            ))}

            <TableRow className={`${classes.tableRow} ${classes.header}`}>
              <CfTableCell classes={{ root: classes.tdPaddingDense }} name={'map'}>
                <SelectionItemColumn classes={{ column: classes.tfTotal }}>
                  <FormattedMessage id="VariableFertilization.total" />
                </SelectionItemColumn>
              </CfTableCell>

              {this.renderTdFooter(
                'zoneArea',
                <span>
                  <FormattedMessage id="VariableFertilization.total-zone-area" /> (ha)
                </span>,
                variableExpense?.areaHa,
              )}

              {/* TODO */}
              {this.renderTdFooter(
                'zoneQuality',
                <span>
                  <FormattedMessage id="VariableFertilization.avg-zone-quality" /> (%)
                </span>,
                variableExpense?.averageQuality,
              )}

              {this.renderTdFooter(
                'dose',
                <span>
                  <FormattedMessage id="common.avg-dose" /> ({doseUnit.id}/ha)
                </span>,
                variableExpense?.doseHa,
              )}

              {this.renderTdFooter(
                'totalDose',
                <span>
                  <FormattedMessage id="common.total-dose-per-parcel" /> ({doseUnit.id})
                </span>,
                variableExpense?.totalDose,
              )}
            </TableRow>
          </TableBody>
        </CfTableWrapper>
      </Paper>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateVariableExpenseZoneDose,
      updateVariableExpenseSumValues,
    },
    dispatch,
  );

VrsExpenseCard.propTypes = {
  classes: PropTypes.object.isRequired,
  expense: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onMapClick: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  formName: PropTypes.string.isRequired,
  updateVariableExpenseZoneDose: PropTypes.func.isRequired,
  updateVariableExpenseSumValues: PropTypes.func.isRequired,
  langId: PropTypes.string.isRequired,
  currMapZones: PropTypes.array,
};

VrsExpenseCard.defaultProps = {
  currMapZones: null,
};

export default compose(connect(null, mapDispatchToProps), withStyles(styles))(VrsExpenseCard);
