import React, { FC } from 'react';

import FabWithOptions from '../../../shared/components/common/FabWithOptions/FabWithOptions';

export interface PrecisionDetailFabButtonProps {
    ngRedirectToVrf: (parcelId: string[]) => void,
    ngRedirectToVrs: (parcelId: string[]) => void,
    parcelId: string
  }

const PrecisionDetailFabButton: FC<PrecisionDetailFabButtonProps> = ({
  ngRedirectToVrf,
  ngRedirectToVrs,
  parcelId,
}) => {
  const fabOptions = [
    {
      id: 'add-var-vert-link',
      translId: 'common.addVrfActivity',
      action: () => ngRedirectToVrf([parcelId]),
    },
    {
      id: 'add-var-seed-link',
      translId: 'common.addVrsActivity',
      action: () => ngRedirectToVrs([parcelId]),
    },
  ];

  return (
    <FabWithOptions
      options={[...fabOptions]}
    />
  );
};

export default PrecisionDetailFabButton;
