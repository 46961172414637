import { AnyAction } from 'redux';

import * as types from '../actions/telematicsDetail.constants';

import { TelematicsDetail } from '../telematics.types';

export default () => (
  state: TelematicsDetail = {
    isFormSubmitting: false,
    formSubmissionResult: null,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case types.SET_FORM_SUBMISSION:
      return {
        ...state,
        isFormSubmitting: action.payload,
      };
    case types.SET_FORM_SUBMISSION_OK:
      return {
        ...state,
        formSubmissionResult: 200,
      };
    case types.SET_FORM_SUBMISSION_ERROR:
      return {
        ...state,
        formSubmissionResult: 500,
      };
    case types.RESET_FORM_SUBMISSION:
      return {
        ...state,
        isFormSubmitting: false,
        formSubmissionResult: null,
      };
    default:
      return state;
  }
};
