import React, { FC, useState, useEffect, useContext } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { getAggregatedDriveKeys, getApiError, isAggregatedDetailError } from '../../../shared/api/telematics/aggregations/aggregations.selectors';

import { TELEMATICS_URLS } from '../../telematics.constants';

import { changeDrivesStateApi, deleteAggregatedDriveApi } from '../../../shared/api/telematics/aggregations/aggregations.api';
import CfErrorPage from '../../../shared/components/common/CfErrorPage/CfErrorPage';
import { SnackbarContext } from '../../../shared/containers/SnackbarProvider/SnackbarProvider';
import { AsyncFn, RsaaApiError, Thunk } from '../../../types';
import EditBox from '../../components/TelematicsAggregationDetailContent/EditBox';
import HeaderWithRelatedRides from '../../components/TelematicsAggregationDetailContent/HeaderWithRelatedRides';
import TelematicsHandworkForm from '../../components/TelematicsHandwork/TelematicsHandworkForm';
import { TABS } from '../TelematicsTabs/TelematicsTabs';

import { useTelematicsAggregationHandworkDetailStyles } from './handworkStyles';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { DriveDetailTo, State, Type, WinfasTo } from '../../../shared/api/telematics/telematics.types';

interface Props {
    apiError?: RsaaApiError;
    changeState: (ids: string[], state: State) => void;
    deleteDrive: (id: number) => void;
    driveDetail?: DriveDetailTo;
    driveId: string;
    driveKeys?: WinfasTo | null;
    farmId: string;
    handleGoBack: () => void;
    isAggregatedDetailError: boolean;
    isFetching: boolean;
    ngGoToAction: (actionId?: string) => void;
}

const TelematicsAggregationDetailLayout: FC<Props> = ({
  apiError,
  changeState,
  deleteDrive,
  driveDetail,
  driveId,
  driveKeys,
  farmId,
  handleGoBack,
  isAggregatedDetailError,
  isFetching,
  ngGoToAction,
}) => {
  // hooks
  const history = useHistory();

  const classes = useTelematicsAggregationHandworkDetailStyles();
  const showSnackbar = useContext(SnackbarContext);

  // state
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [isPostponed, setIsPostponed] = useState<boolean>(false);

  useEffect(() => {
    if (!driveDetail) return;

    if (driveDetail.state === State.DEFERRED) {
      setIsPostponed(true);
    } else {
      setIsPostponed(false);
    }

    if (driveDetail.state === State.APPROVED || driveDetail.state === State.THIRD_PARTY_ACKNOWLEDGED) {
      setIsApproved(true);
    } else {
      setIsApproved(false);
    }
  }, [driveDetail]);

  const handleApproveClick = () => {
    const newState = isApproved ? State.NOT_APPROVED : State.APPROVED;
    (changeState as AsyncFn<string[], State>)([driveId], newState).then(res => {
      if (!res.error) {
        setIsApproved(prevState => !prevState);
      } else {
        showSnackbar({
          message: <FormattedMessage id="TelematicsDetail.editRideError" />,
          isError: true,
        });
      }
    });
  };

  const handlePostponedClick = () => {
    const newState = isPostponed ? State.NOT_APPROVED : State.DEFERRED;
    (changeState as AsyncFn<string[], State>)([driveId], newState).then(res => {
      if (!res.error) {
        setIsPostponed(prevState => !prevState);
      }
    });
  };

  const handleDeleteDrive = () => {
    if (!driveDetail?.id) return;
    (deleteDrive as AsyncFn<number>)(driveDetail.id).then(res => {
      if (!res.error) {
        history.push(`/farm/${farmId}/${TELEMATICS_URLS.drivers}`);
      } else {
        showSnackbar({
          message: <FormattedMessage id="TelematicsDetail.deleteRideError" />,
          isError: true,
        });
      }
    });
  };

  if (!driveDetail && isFetching) {
    return (
      <div className={classes.spinnerWrapper}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  const isManualDrive = driveDetail?.type === Type.MANUAL || driveDetail?.type === Type.HANDWORK;

  return (
    <CfErrorPage error={isAggregatedDetailError ? apiError : undefined} error40xHeadingTranslId="error.heading.telematicsDetail" error40xMessageTranslId="error.message.telematicsDetail" handle400s>
      {driveDetail && (
        <Grid className={classes.wrapper} container>
          <Grid className={classes.bodyWrapper} item xs={12}>
            <div className={classes.body}>
              <Grid className={classes.form} container>
                <Grid item xs={6}>
                  <HeaderWithRelatedRides
                    driveDetail={driveDetail}
                    handleApproveClick={handleApproveClick}
                    handleDeleteDrive={isManualDrive ? handleDeleteDrive : undefined}
                    handleGoBack={handleGoBack}
                    handlePostponedClick={handlePostponedClick}
                    isApproved={isApproved}
                    isEditing={false}
                    isPostponed={isPostponed}
                    ngGoToAction={ngGoToAction}
                />
                  <EditBox
                    disabled={false}
                    headingId="TelematicsAggregations.detail.section.operation"
                    isApproved={false}
                    isEditing={true}
                    showEditButton={false}
                    visible={true}
                  >
                    <TelematicsHandworkForm
                      approvalValidationErrors={{}}
                      driveDetail={driveDetail}
                      driveKeys={driveKeys}
                      formPath={TABS.DRIVERS}
                      handleReset={handleGoBack}
                    />
                  </EditBox>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}
    </CfErrorPage>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  apiError: getApiError(state),
  driveKeys: getAggregatedDriveKeys(state),
  isAggregatedDetailError: isAggregatedDetailError(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) => bindActionCreators({
  changeState: changeDrivesStateApi,
  deleteDrive: deleteAggregatedDriveApi,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TelematicsAggregationDetailLayout);
