import React, { FC, ReactNode, useEffect, useCallback } from 'react';

import { Chip } from '@mui/material';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getEquipments, getIsFetchingEquipments } from '../../../../shared/api/telematics/drives/drives.selectors';
import { getTelematicsListDateFrom, getTelematicsListDateTo } from '../../../selectors/telematicsList.selectors';

import { fetchEquipments } from '../../../actions/telematicsList.actions';

import * as sortOrder from '../../../../shared/constants/sortOrder.constants';

import { resetEquipments } from '../../../../shared/api/telematics/drives/drives.api';
import CfAutocomplete from '../../../../shared/components/common/CfAutocomplete/CfAutocomplete';
import { Thunk } from '../../../../types';
import useAdvancedFilterStyles from '../TelematicsAdvancedFilter.styles';

import { TelematicsState } from '../../../../reducers/telematics.reducer.types';
import { EquipmentTo, EquipmentSortColumn } from '../../../../shared/api/telematics/telematics.types';

interface EquipmentSelectorProps {
  dateFrom: string;
  dateTo: string;
  defaultValues?: EquipmentTo[];
  externalClasses?: Record<string, string>;
  fetchEquipments: (dateFrom: string, dateTo: string, sortCol: string, sortDir: string) => void;
  isFetching: boolean;
  label: ReactNode;
  onChange: (items: EquipmentTo[]) => void;
  options: EquipmentTo[];
  resetEquipments: () => void;
}

const defaultDefaultValues: EquipmentTo[] = [];

const handleGetSelected = (option: EquipmentTo, value?: EquipmentTo | null) =>
  option.code === value?.code;

const handleFilterOptions = createFilterOptions({
  stringify: ({ code, name }) => `${name} ${code}`,
});

const EquipmentSelector: FC<EquipmentSelectorProps> = (
  { defaultValues = defaultDefaultValues,
    externalClasses = {},
    options,
    isFetching,
    label,
    fetchEquipments,
    resetEquipments,
    onChange,
    dateFrom,
    dateTo,
  }) => {
  const classes = useAdvancedFilterStyles();

  useEffect(() => {
    fetchEquipments(dateFrom, dateTo, EquipmentSortColumn.NAME, sortOrder.ASC);
    return () => {
      resetEquipments();
    };
  }, [fetchEquipments, resetEquipments, dateFrom, dateTo]);

  const handleRenderOption = useCallback((option: EquipmentTo) => (
    <div>
      <div>{option.name ?? option.code}</div>
      <div className={classes.subtext}>{option.code}</div>
    </div>
  ), [classes]);

  const handleRenderTags = useCallback((values: EquipmentTo[], getTagProps: AutocompleteRenderGetTagProps) =>
    <>
      {values.map((value, index) => (
        <Chip
          {...getTagProps({ index })}
          key={value.id ?? value.code}
          label={
            <>
              {value.name ?? value.code}
              <span className={classes.subtext}>{value.code}</span>
            </>
          }
        />
      ))}
    </>, [classes]);

  return (
    <CfAutocomplete
      classes={externalClasses}
      defaultValues={defaultValues}
      filterOptions={handleFilterOptions}
      getSelected={handleGetSelected}
      id="equipment-selector"
      isFetching={isFetching}
      isMultiple={true}
      label={label}
      onChange={onChange}
      renderOption={handleRenderOption}
      renderTags={handleRenderTags}
      suggestions={options}
      testId="equipment-filter"
    />
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  options: getEquipments(state),
  isFetching: getIsFetchingEquipments(state),
  dateFrom: getTelematicsListDateFrom(state),
  dateTo: getTelematicsListDateTo(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      fetchEquipments,
      resetEquipments,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentSelector);
