const PREFIX = 'TELEMATICS_AGGREGATIONS';

export const GET_DRIVER_AGGREGATIONS_REQUEST = `${PREFIX}/GET_DRIVER_AGGREGATIONS_REQUEST`;
export const GET_DRIVER_AGGREGATIONS_SUCCESS = `${PREFIX}/GET_DRIVER_AGGREGATIONS_SUCCESS`;
export const GET_DRIVER_AGGREGATIONS_ERROR = `${PREFIX}/GET_DRIVER_AGGREGATIONS_ERROR`;

export const GET_DRIVER_AGGREGATIONS_REQUEST_NOREDUCER = `${PREFIX}/GET_DRIVER_AGGREGATIONS_REQUEST_NOREDUCER`;
export const GET_DRIVER_AGGREGATIONS_SUCCESS_NOREDUCER = `${PREFIX}/GET_DRIVER_AGGREGATIONS_SUCCESS_NOREDUCER`;
export const GET_DRIVER_AGGREGATIONS_ERROR_NOREDUCER = `${PREFIX}/GET_DRIVER_AGGREGATIONS_ERROR_NOREDUCER`;

export const GET_AGGREGATED_DRIVE_REQUEST = `${PREFIX}/GET_AGGREGATED_DRIVE_REQUEST`;
export const GET_AGGREGATED_DRIVE_SUCCESS = `${PREFIX}/GET_AGGREGATED_DRIVE_SUCCESS`;
export const GET_AGGREGATED_DRIVE_ERROR = `${PREFIX}/GET_AGGREGATED_DRIVE_ERROR`;

export const EDIT_AGGREGATED_DRIVE_REQUEST = `${PREFIX}/EDIT_AGGREGATED_DRIVE_REQUEST`;
export const EDIT_AGGREGATED_DRIVE_SUCCESS = `${PREFIX}/EDIT_AGGREGATED_DRIVE_SUCCESS`;
export const EDIT_AGGREGATED_DRIVE_ERROR = `${PREFIX}/EDIT_AGGREGATED_DRIVE_ERROR`;

export const DELETE_AGGREGATED_DRIVE_REQUEST = `${PREFIX}/DELETE_AGGREGATED_DRIVE_REQUEST`;
export const DELETE_AGGREGATED_DRIVE_SUCCESS = `${PREFIX}/DELETE_AGGREGATED_DRIVE_SUCCESS`;
export const DELETE_AGGREGATED_DRIVE_ERROR = `${PREFIX}/DELETE_AGGREGATED_DRIVE_ERROR`;

export const CREATE_AGGREGATED_DRIVE_REQUEST = `${PREFIX}/CREATE_AGGREGATED_DRIVE_REQUEST`;
export const CREATE_AGGREGATED_DRIVE_SUCCESS = `${PREFIX}/CREATE_AGGREGATED_DRIVE_SUCCESS`;
export const CREATE_AGGREGATED_DRIVE_ERROR = `${PREFIX}/CREATE_AGGREGATED_DRIVE_ERROR`;

export const EXPORT_AGGREGATED_DRIVES_REQUEST = `${PREFIX}/EXPORT_AGGREGATED_DRIVES_REQUEST`;
export const EXPORT_AGGREGATED_DRIVES_SUCCESS = `${PREFIX}/EXPORT_AGGREGATED_DRIVES_SUCCESS`;
export const EXPORT_AGGREGATED_DRIVES_ERROR = `${PREFIX}/EXPORT_AGGREGATED_DRIVES_ERROR`;

export const SET_DRIVES_STATE_REQUEST = `${PREFIX}/SET_DRIVES_STATE_REQUEST`;
export const SET_DRIVES_STATE_SUCCESS = `${PREFIX}/SET_DRIVES_STATE_SUCCESS`;
export const SET_DRIVES_STATE_ERROR = `${PREFIX}/SET_DRIVES_STATE_ERROR`;

export const EXPORT_TOS_DATA_REQUEST = `${PREFIX}/EXPORT_TOS_DATA_REQUEST`;
export const EXPORT_TOS_DATA_SUCCESS = `${PREFIX}/EXPORT_TOS_DATA_SUCCESS`;
export const EXPORT_TOS_DATA_ERROR = `${PREFIX}/EXPORT_TOS_DATA_ERROR`;

export const SEND_TO_TOS_REQUEST = `${PREFIX}/SEND_TO_TOS_REQUEST`;
export const SEND_TO_TOS_SUCCESS = `${PREFIX}/SEND_TO_TOS_SUCCESS`;
export const SEND_TO_TOS_ERROR = `${PREFIX}/SEND_TO_TOS_ERROR`;

export const GET_TOS_STATUS_REQUEST = `${PREFIX}/GET_TOS_STATUS_REQUEST`;
export const GET_TOS_STATUS_SUCCESS = `${PREFIX}/GET_TOS_STATUS_SUCCESS`;
export const GET_TOS_STATUS_ERROR = `${PREFIX}/GET_TOS_STATUS_ERROR`;

export const CALCULATE_AREA_REQUEST = `${PREFIX}/CALCULATE_AREA_REQUEST`;
export const CALCULATE_AREA_SUCCESS = `${PREFIX}/CALCULATE_AREA_SUCCESS`;
export const CALCULATE_AREA_ERROR = `${PREFIX}/CALCULATE_AREA_ERROR`;

export const RESET_AGGREGATED_DRIVE = `${PREFIX}/RESET_AGGREGATED_DRIVE`;
export const RESET_API_ERROR = `${PREFIX}/RESET_API_ERROR`;
export const RESET_EXPORT_ERROR = `${PREFIX}/RESET_EXPORT_ERROR`;

export const GET_AGGREGATED_DRIVE_KEYS_REQUEST = `${PREFIX}/GET_AGGREGATED_DRIVE_KEYS_REQUEST`;
export const GET_AGGREGATED_DRIVE_KEYS_SUCCESS = `${PREFIX}/GET_AGGREGATED_DRIVE_KEYS_SUCCESS`;
export const GET_AGGREGATED_DRIVE_KEYS_ERROR = `${PREFIX}/GET_AGGREGATED_DRIVE_KEYS_ERROR`;

export const EDIT_AGGREGATED_DRIVE_KEYS_REQUEST = `${PREFIX}/EDIT_AGGREGATED_DRIVE_KEYS_REQUEST`;
export const EDIT_AGGREGATED_DRIVE_KEYS_SUCCESS = `${PREFIX}/EDIT_AGGREGATED_DRIVE_KEYS_SUCCESS`;
export const EDIT_AGGREGATED_DRIVE_KEYS_ERROR = `${PREFIX}/EDIT_AGGREGATED_DRIVE_KEYS_ERROR`;

export const GET_KEY_COMBINATIONS_REQUEST = `${PREFIX}/GET_KEY_COMBINATIONS_REQUEST`;
export const GET_KEY_COMBINATIONS_SUCCESS = `${PREFIX}/GET_KEY_COMBINATIONS_SUCCESS`;
export const GET_KEY_COMBINATIONS_ERROR = `${PREFIX}/GET_KEY_COMBINATIONS_ERROR`;

export const GET_WINFAS_CLIENT_COMBINATIONS_REQUEST = `${PREFIX}/GET_WINFAS_CLIENT_COMBINATIONS_REQUEST`;
export const GET_WINFAS_CLIENT_COMBINATIONS_SUCCESS = `${PREFIX}/GET_WINFAS_CLIENT_COMBINATIONS_SUCCESS`;
export const GET_WINFAS_CLIENT_COMBINATIONS_ERROR = `${PREFIX}/GET_WINFAS_CLIENT_COMBINATIONS_ERROR`;

export const GET_ACTION_RELATED_DRIVES_REQUEST = `${PREFIX}/GET_ACTION_RELATED_DRIVES_REQUEST`;
export const GET_ACTION_RELATED_DRIVES_SUCCESS = `${PREFIX}/GET_ACTION_RELATED_DRIVES_SUCCESS`;
export const GET_ACTION_RELATED_DRIVES_ERROR = `${PREFIX}/GET_ACTION_RELATED_DRIVES_ERROR`;

export const EDIT_BATCH_DRIVER_REQUEST = `${PREFIX}/EDIT_BATCH_DRIVER_REQUEST`;
export const EDIT_BATCH_DRIVER_SUCCESS = `${PREFIX}/EDIT_BATCH_DRIVER_SUCCESS`;
export const EDIT_BATCH_DRIVER_ERROR = `${PREFIX}/EDIT_BATCH_DRIVER_ERROR`;

export const EDIT_BATCH_EQUIPMENT_REQUEST = `${PREFIX}/EDIT_BATCH_EQUIPMENT_REQUEST`;
export const EDIT_BATCH_EQUIPMENT_SUCCESS = `${PREFIX}/EDIT_BATCH_EQUIPMENT_SUCCESS`;
export const EDIT_BATCH_EQUIPMENT_ERROR = `${PREFIX}/EDIT_BATCH_EQUIPMENT_ERROR`;

export const EDIT_BATCH_PRODUCTION_OPERATION_REQUEST = `${PREFIX}/EDIT_BATCH_PRODUCTION_OPERATION_REQUEST`;
export const EDIT_BATCH_PRODUCTION_OPERATION_SUCCESS = `${PREFIX}/EDIT_BATCH_PRODUCTION_OPERATION_SUCCESS`;
export const EDIT_BATCH_PRODUCTION_OPERATION_ERROR = `${PREFIX}/EDIT_BATCH_PRODUCTION_OPERATION_ERROR`;

export const EDIT_BATCH_BONUS_REQUEST = `${PREFIX}/EDIT_BATCH_BONUS_REQUEST`;
export const EDIT_BATCH_BONUS_SUCCESS = `${PREFIX}/EDIT_BATCH_BONUS_SUCCESS`;
export const EDIT_BATCH_BONUS_ERROR = `${PREFIX}/EDIT_BATCH_BONUS_ERROR`;

export const EDIT_BATCH_SUBJECT_REQUEST = `${PREFIX}/EDIT_BATCH_SUBJECT_REQUEST`;
export const EDIT_BATCH_SUBJECT_SUCCESS = `${PREFIX}/EDIT_BATCH_SUBJECT_SUCCESS`;
export const EDIT_BATCH_SUBJECT_ERROR = `${PREFIX}/EDIT_BATCH_SUBJECT_ERROR`;

export const EDIT_BATCH_CUSTOMER_KEY_REQUEST = `${PREFIX}/EDIT_BATCH_CUSTOMER_KEY_REQUEST`;
export const EDIT_BATCH_CUSTOMER_KEY_SUCCESS = `${PREFIX}/EDIT_BATCH_CUSTOMER_KEY_SUCCESS`;
export const EDIT_BATCH_CUSTOMER_KEY_ERROR = `${PREFIX}/EDIT_BATCH_CUSTOMER_KEY_ERROR`;

export const SEPARATE_DRIVE_REQUEST = `${PREFIX}/SEPARATE_DRIVE_REQUEST`;
export const SEPARATE_DRIVE_SUCCESS = `${PREFIX}/SEPARATE_DRIVE_SUCCESS`;
export const SEPARATE_DRIVE_ERROR = `${PREFIX}/SEPARATE_DRIVE_ERROR`;

export const GET_DRIVE_OVERLAPS_REQUEST = `${PREFIX}/GET_DRIVE_OVERLAPS_REQUEST`;
export const GET_DRIVE_OVERLAPS_SUCCESS = `${PREFIX}/GET_DRIVE_OVERLAPS_SUCCESS`;
export const GET_DRIVE_OVERLAPS_ERROR = `${PREFIX}/GET_DRIVE_OVERLAPS_ERROR`;

export const SEPARATE_INTERVAL_REQUEST = `${PREFIX}/SEPARATE_INTERVAL_REQUEST`;
export const SEPARATE_INTERVAL_SUCCESS = `${PREFIX}/SEPARATE_INTERVAL_SUCCESS`;
export const SEPARATE_INTERVAL_ERROR = `${PREFIX}/SEPARATE_INTERVAL_ERROR`;

export const CREATE_HANDWORK_REQUEST = `${PREFIX}/CREATE_HANDWORK_REQUEST`;
export const CREATE_HANDWORK_SUCCESS = `${PREFIX}/CREATE_HANDWORK_SUCCESS`;
export const CREATE_HANDWORK_ERROR = `${PREFIX}/CREATE_HANDWORK_ERROR`;

export const GET_AGG_OVERLAPS_REQUEST = `${PREFIX}/GET_AGG_OVERLAPS_REQUEST`;
export const GET_AGG_OVERLAPS_SUCCESS = `${PREFIX}/GET_AGG_OVERLAPS_SUCCESS`;
export const GET_AGG_OVERLAPS_ERROR = `${PREFIX}/GET_AGG_OVERLAPS_ERROR`;
