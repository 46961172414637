import React, { useState, FC } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import CfDialog from '../../../shared/components/common/CfDialog/CfDialog';

const useStyles = makeStyles({
  disclaimer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    height: '100%',
    fontSize: 20,
    fontWeight: 500,
    marginLeft: '-26px',
  },
});

interface Props {
    handleDeleteDrive: () => void;
}

const DeleteDriveButtonWithModal: FC<Props> = ({ handleDeleteDrive }) => {
  const classes = useStyles();
  const [opened, setOpened] = useState(false);
  return (
    <>
      <IconButton onClick={() => setOpened(true)}>
        <DeleteIcon />
      </IconButton>
      <CfDialog
        acceptText={<FormattedMessage id="TelematicsAggregations.detail.deleteModalAccept" />}
        cancelText={<FormattedMessage id="common.cancel" />}
        dialogHeight="75px"
        maxWidth="xs"
        onAccept={handleDeleteDrive}
        onCancel={() => setOpened(false)}
        opened={opened}
      >
        <div className={classes.disclaimer}><FormattedMessage id="TelematicsAggregations.detail.deleteModalText" /></div>
      </CfDialog>
    </>
  );
};

export default DeleteDriveButtonWithModal;
