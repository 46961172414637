import React, { Component } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';

import { getFertilizerDose, getIsFetchingFertilizerDose } from '../../../../../shared/api/agroevidence/catalogues/fertilizers/fertilizers.selectors';

import { getFertilizerDoseApi, resetFertilizerDoseApi } from '../../../../../shared/api/agroevidence/catalogues/fertilizers/fertilizers.api';
import CfDialog from '../../../../../shared/components/common/CfDialog/CfDialog';
import CfFormattedNumber from '../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import CfFormControl from '../../../../../shared/components/form/CfFormControl/CfFormControl';
import CfReduxFormSelect from '../../../../../shared/components/form/CfReduxFormSelect/CfReduxFormSelect';
import DoseDosageFields from '../../components/DoseDosageFields/DoseDosageFields';
import incorporationDaysSource from '../../misc/incorporationDay.json';

const styles = theme => ({
  menuItem: {
    fontSize: 16,
  },
  inputLabel: {
    fontWeight: 'normal',
  },
  errorText: {
    position: 'absolute',
    bottom: '-10px',
  },
  unitField: {
    display: 'inherit',
  },
  unitGridItem: {
    marginTop: 'auto',
  },
  nutrients: {
    backgroundColor: theme.palette.grey[100],
    textAlign: 'center',
    width: '100%',
    margin: '10px 0px',
    paddingTop: 10,
    paddingBottom: 10,
  },
  nutrientsSpan: {
    display: 'block',
    color: theme.palette.grey[500],
    fontSize: 14,
  },
  infoContainer: {
    paddingRight: '32px',
  },
  infoText: {
    fontSize: 14,
    paddingLeft: '10px',
    lineHeight: '10px',
  },
  infoIcon: {
    color: '#78D3F0',
    width: '20px',
    height: '20px',
    verticalAlign: 'text-top',
  },
  helperText: {
    position: 'absolute',
    bottom: -16,
  },
  nutrientsTitle: {
    fontWeight: 500,
  },
});

class EphFertilizerDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDosageDisconnectWarning: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { fertilizerDose } = this.props;
    if (fertilizerDose !== prevProps.fertilizerDose) {
      this.props.change('n', fertilizerDose.nitrogenIntake);
      this.props.change('p2o5', fertilizerDose.p2o5Intake);
      this.props.change('k2o', fertilizerDose.k2oIntake);
    }
  }

  onFormSubmit = () => {
    if (!this.props.IsFetchingFertDose) {
      this.onSubmit();
    }
  };

  onSubmit = this.props.handleSubmit(values => {
    this.props.onAccept(values);
  });

  setDosageDisconnectWarning = (bool) => this.setState({ ...this.state, showDosageDisconnectWarning: bool });

  initializeForm = () => {
    const { fertilizer } = this.props;
    this.props.initialize(fertilizer);
    this.props.resetFertilizerDoseApi();
  };

  render() {
    const { incorporationDays } = incorporationDaysSource;
    const { IsFetchingFertDose, classes, formValues } = this.props;
    const { showDosageDisconnectWarning } = this.state;
    const dialogTitle = this.props.fertilizer ? this.props.fertilizer.material.name : '';

    let acceptText = <FormattedMessage id="common.close" />;
    if (this.props.adding) {
      acceptText = <FormattedMessage id="common.add" />;
    } else if (this.props.editing) {
      acceptText = <FormattedMessage id="common.edit" />;
    }

    return (
      <CfDialog
        acceptText={acceptText}
        cancelText={<FormattedMessage id="common.cancel" />}
        onAccept={this.props.editing || this.props.adding ? this.onFormSubmit : this.props.onClose}
        onCancel={this.props.editing || this.props.adding ? this.props.onClose : undefined}
        onClose={this.props.onClose}
        onEnter={() => this.initializeForm()}
        opened={this.props.opened}
        title={dialogTitle}
      >
        <form>
          <DoseDosageFields
            change={this.props.change}
            editing={this.props.editing}
            getFertilizerDoseApi={this.props.getFertilizerDoseApi}
            handleShowWarning={this.setDosageDisconnectWarning}
            material={this.props.fertilizer}
            name="fertilizer"
            parcelsArea={this.props.parcelsArea}
          />
          <CfFormControl>
            <InputLabel className={classes.inputLabel}>
              <FormattedMessage id="SoilIncorporation.incorporation.date" />
            </InputLabel>
            <Field component={CfReduxFormSelect} disabled={!this.props.editing} name="incorporationDay">
              {incorporationDays.map(dat => (
                <MenuItem className={classes.menuItem} key={dat.code} value={dat.code}>
                  <FormattedMessage id={dat.intId} />
                </MenuItem>
              ))}
            </Field>
          </CfFormControl>
          <Grid className={classes.nutrients} container spacing={2}>
            <Grid className={classes.nutrientsTitle} item xs={12}>
              <FormattedMessage id="EphFertilizerDialog.nutrientsSupplyPerHectare" />
            </Grid>
            <Grid item xs={4}>
              <span className={classes.nutrientsSpan}>N</span>
              {IsFetchingFertDose ? (
                <span>...</span>
              ) : (
                <span>
                  {formValues.n !== undefined ? <CfFormattedNumber value={formValues.n} /> : '-'}{' '}
                  <span>
                    <FormattedMessage id={'unit.kg'} />
                    /ha
                  </span>
                </span>
              )}
            </Grid>
            <Grid item xs={4}>
              <span className={classes.nutrientsSpan}>
                P<sub>2</sub>O<sub>5</sub>
              </span>
              {IsFetchingFertDose ? (
                <span>...</span>
              ) : (
                <span>
                  {formValues.p2o5 !== undefined ? <CfFormattedNumber value={formValues.p2o5} /> : '-'}{' '}
                  <span>
                    <FormattedMessage id={'unit.kg'} />
                    /ha
                  </span>
                </span>
              )}
            </Grid>
            <Grid item xs={4}>
              <span className={classes.nutrientsSpan}>
                K<sub>2</sub>O
              </span>
              {IsFetchingFertDose ? (
                <span>...</span>
              ) : (
                <span>
                  {formValues.k2o !== undefined ? <CfFormattedNumber value={formValues.k2o} /> : '-'}{' '}
                  <span>
                    <FormattedMessage id={'unit.kg'} />
                    /ha
                  </span>
                </span>
              )}
            </Grid>
            {showDosageDisconnectWarning && (
              <Grid className={classes.infoContainer} item xs={12}>
                <InfoIcon className={classes.infoIcon} />
                <span className={classes.infoText}>
                  <FormattedMessage id="EphFertilizerDialog.nutrientsSupplyInfo" />
                </span>
              </Grid>
            )}
          </Grid>
        </form>
      </CfDialog>
    );
  }
}

EphFertilizerDialog.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  classes: PropTypes.object,
  change: PropTypes.func,
  formValues: PropTypes.object,
  fertilizer: PropTypes.object,
  adding: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialize: PropTypes.func,
  parcelsArea: PropTypes.number,
  editing: PropTypes.bool,
  getFertilizerDoseApi: PropTypes.func.isRequired,
  resetFertilizerDoseApi: PropTypes.func.isRequired,
  fertilizerDose: PropTypes.object,
  IsFetchingFertDose: PropTypes.bool.isRequired,
};

EphFertilizerDialog.defaultProps = {
  opened: false,
  classes: {},
  change: PropTypes.any,
  fertilizer: null,
  formValues: {},
  adding: true,
  handleSubmit: () => {},
  initialize: () => {},
  parcelsArea: 0,
  editing: false,
  fertilizerDose: {},
};

const mapStateToProps = state => ({
  formValues: getFormValues('fertilizer')(state),
  fertilizerDose: getFertilizerDose(state),
  IsFetchingFertDose: getIsFetchingFertilizerDose(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getFertilizerDoseApi,
      resetFertilizerDoseApi,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'fertilizer',
  })(withStyles(styles)(EphFertilizerDialog)),
);
