export const LANG_KEYS = {
  CZ: 'cs-CZ',
  EN: 'en-US',
  SR: 'sr-LATN-RS',
  ES: 'es-ES',
  RU: 'ru-UA',
  EL: 'el-GR',
  RO: 'ro-RO',
} as const;

export type LANGUAGE_ID = typeof LANG_KEYS[keyof typeof LANG_KEYS]
