import { transform } from 'ol/proj';
import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './nodes.constants';

import NodesApi from './NodesApi.service';

export const getNodes = (params, ignoreUnknownTypes = true) => {
  const p = {
    page: params.page + 1,
    'per-page': params.rowsPerPage,
    'sort-col': params.orderBy,
    'sort-dir': params.order,
  };

  if (params.textFilter) {
    p.search = params.textFilter;
  }
  if (params.status) {
    p.status = params.status.join();
  }
  if (params.deviceType) {
    p['device-type'] = params.deviceType.join();
  }
  if (params.networkType) {
    p['network-type'] = params.networkType.join();
  }
  if (params.countryCode) {
    p.country = params.countryCode;
  }
  if (params.farmIds) {
    p.farmIds = params.farmIds;
  }

  return {
    [RSAA]: {
      endpoint: `nodes?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.IOT,
      types: [
        types.GET_NODES,
        {
          type: types.GET_NODES_SUCCESS,
          payload: (action, state, res) =>
            res
              .json()
              .then(json =>
                NodesApi.filterSupportedDevices(json, ignoreUnknownTypes).map(node =>
                  NodesApi.filterSupportedSensors(node, []),
                ),
              ),
        },
        types.GET_NODES_ERROR,
      ],
    },
  };
};

export const getNode = nodeId => ({
  [RSAA]: {
    endpoint: `nodes/${nodeId}?`,
    method: methods.GET,
    module: modules.IOT,
    types: [types.GET_NODE, types.GET_NODE_SUCCESS, types.GET_NODE_ERROR],
  },
});

export const resetNodes = () => ({
  type: types.RESET_NODES,
});

export const getNodesStatistics = () => ({
  [RSAA]: {
    endpoint: 'nodes/statistics?',
    method: methods.GET,
    module: modules.IOT,
    types: [types.GET_NODES_STATISTICS, types.GET_NODES_STATISTICS_SUCCESS, types.GET_NODES_STATISTICS_ERROR],
  },
});

export const resetNodesStatistics = () => ({
  type: types.RESET_NODES_STATISTICS,
});

export const getDefaultFarm = () => ({
  [RSAA]: {
    endpoint: 'nodes/default-farm?',
    method: methods.GET,
    module: modules.IOT,
    types: [types.GET_NODES_DEFAULT_FARM, types.GET_NODES_DEFAULT_FARM_SUCCESS, types.GET_NODES_DEFAULT_FARM_ERROR],
  },
});

export const resetDefaultFarm = () => ({
  type: types.RESET_NODES_DEFAULT_FARM,
});

export const createNode = values => {
  let query = '';
  const fields = {};
  values.network.fields.forEach(field => {
    fields[field] = values[field];
  });
  const dto = {
    name: values.name,
    externalId: values.external_id,
    networkType: values.network.networkType,
    network: values.network.network,
    deviceType: values.type.name,
    farm: values.farm,
    ...fields,
  };

  if (window.test_mode) {
    query = 'test_mode=true&';
  }

  return {
    [RSAA]: {
      endpoint: `nodes?${query}`,
      method: methods.POST,
      module: modules.IOT,
      body: JSON.stringify(dto),
      types: [types.CREATE_NODE, types.CREATE_NODE_SUCCESS, types.CREATE_NODE_ERROR],
    },
  };
};

export const putNode = (id, name, geometry, status) => {
  const transformedGeometry = {
    ...geometry,
    coordinates: transform(geometry.coordinates, 'EPSG:3857', 'EPSG:4326'),
  };
  const dto = {
    name,
    geometry: transformedGeometry,
    status,
  };

  return {
    [RSAA]: {
      endpoint: `nodes/${id}?`,
      method: methods.PUT,
      module: modules.IOT,
      body: JSON.stringify(dto),
      types: [types.PUT_NODE, types.PUT_NODE_SUCCESS, types.PUT_NODE_ERROR],
    },
  };
};

export const patchNode = dto => {
  let query = '';
  const body = {
    ...dto,
  };
  delete body.id;

  if (window.test_mode) {
    query = 'test_mode=true&';
  }

  return {
    [RSAA]: {
      endpoint: `nodes/${dto.id}?${query}`,
      method: methods.PATCH,
      module: modules.IOT,
      body: JSON.stringify(body),
      types: [types.PATCH_NODE, types.PATCH_NODE_SUCCESS, types.PATCH_NODE_ERROR],
    },
  };
};

export const getNodeTypes = () => ({
  [RSAA]: {
    endpoint: 'nodes/device-types?',
    method: methods.GET,
    module: modules.IOT,
    types: [types.GET_NODE_TYPES, types.GET_NODE_TYPES_SUCCESS, types.GET_NODE_TYPES_ERROR],
  },
});

export const getNetworkTypes = () => ({
  [RSAA]: {
    endpoint: 'nodes/network-types?',
    method: methods.GET,
    module: modules.IOT,
    types: [types.GET_NETWORK_TYPES, types.GET_NETWORK_TYPES_SUCCESS, types.GET_NETWORK_TYPES_ERROR],
  },
});

export const exportNodes = () => ({
  [RSAA]: {
    endpoint: 'nodes/export?',
    method: methods.GET,
    module: modules.IOT,
    types: [
      types.EXPORT_NODES,
      {
        type: types.EXPORT_NODES_SUCCESS,
        payload: (action, state, res) => ({
          body: res.blob(),
          disposition: res.headers.get('Content-Disposition'),
          type: res.headers.get('Content-Type'),
        }),
      },
      types.EXPORT_NODES_ERROR,
    ],
  },
});

export const resetNodeTypes = () => ({
  type: types.RESET_NODE_TYPES,
});

export const resetNetworkTypes = () => ({
  type: types.RESET_NETWORK_TYPES,
});

export const getAdminAvailableBaraniBasesApi = params => {
  const p = {
    search: params.textFilter,
  };

  return {
    [RSAA]: {
      endpoint: `nodes/${params.moduleId}/available-bases?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.IOT,
      types: [
        types.GET_BARANI_BASES_REQUEST,
        types.GET_BARANI_BASES_SUCCESS,
        types.GET_BARANI_BASES_ERROR,
      ],
    },
  };
};

export const resetAdminAvailableBaraniBases = () => ({
  type: types.RESET_BARANI_BASES,
});

export const putAdminBaraniAssignApi = (moduleId, baseId) => ({
  [RSAA]: {
    endpoint: `nodes/${moduleId}/base/${baseId}?`,
    method: methods.PUT,
    module: modules.IOT,
    types: [
      types.PUT_BARANI_ASSIGN_REQUEST,
      types.PUT_BARANI_ASSIGN_SUCCESS,
      types.PUT_BARANI_ASSIGN_ERROR,
    ],
  },
});

export const deleteAdminBaraniUnassignApi = moduleId => ({
  [RSAA]: {
    endpoint: `nodes/${moduleId}/base?`,
    method: methods.DELETE,
    module: modules.IOT,
    types: [
      types.DELETE_BARANI_UNASSIGN_REQUEST,
      types.DELETE_BARANI_UNASSIGN_SUCCESS,
      types.DELETE_BARANI_UNASSIGN_ERROR,
    ],
  },
});
