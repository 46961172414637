import React, { FC } from 'react';

import Grid from '@mui/material/Grid';
import { FormattedDate, FormattedMessage } from 'react-intl';

import CfStatusPanel from '../../../../shared/components/common/CfStatusPanel/CfStatusPanel';
import { OverallImagesError } from '../../services/BioMonitoringImageError.services';

export interface BioMonitoringImagesOverallErrorProps {
  lastImageDateTo: string,
  overallImagesError: OverallImagesError
}

const BioMonitoringImagesOverallError: FC<BioMonitoringImagesOverallErrorProps> = ({
  lastImageDateTo,
  overallImagesError,
}) => {
  const ErrorIcon = overallImagesError ? overallImagesError.icon : null;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <CfStatusPanel
          fullWidth={true}
          grey={true}
          icon={ErrorIcon}
          secondaryContent={
            lastImageDateTo ? (
              <span>
                <FormattedMessage id="BioMonitoring.lastUpdate" />
                <span>{'\u00A0'}</span>
                <FormattedDate day="numeric" month="numeric" value={lastImageDateTo} year="numeric" />
              </span>
            ) : null
          }
          title={
            <FormattedMessage
              id={`BioMonitoringImageError.status.${overallImagesError.overallTranslation ? 'overall.' : ''}${
                overallImagesError.id
              }`}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

export default BioMonitoringImagesOverallError;
