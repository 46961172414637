import React from 'react';

import { TableCell, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { kmgZonesColors } from '../../../services/soilSamlesZonesColors';

const useStyles = makeStyles(() => ({
  cell: {
    width: 90,
    border: '1px solid',
    textAlign: 'center',
  },
  firstCell: {
    width: 120,
    textAlign: 'left',
  },
  text: {
    margin: 0,
    fontWeight: 600,
    fontSize: 13,
    whiteSpace: 'nowrap',
  },
  whiteText: {
    color: '#FFFFFF',
  },
  secondaryText: {
    margin: 0,
    fontWeight: 400,
    fontSize: 10,
  },
}));

const TableHeaderKMG = () => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell classes={{ root: classNames(classes.cell, classes.firstCell) }}>
          <div>
            <p className={classes.text}>
              <FormattedMessage id="SoilSamples.content" />
            </p>
          </div>
        </TableCell>
        {kmgZonesColors.map((item, i) => (
          <TableCell
            classes={{ root: classes.cell }}
            key={i}
            style={{ backgroundColor: `#${item.color}` }}
          >
            <p className={classNames(classes.text, classes.whiteText)}>
              <FormattedMessage id={`SoilSamples.level.${item.zoneLevel}`} />
            </p>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeaderKMG;
