import { AnyAction } from 'redux';

import * as types from './eagri.constants';

const initialState = {
  cropUsage: [],
  isFetchingCropUsage: false,
  harvestProducts: [],
  isFetchingHarvestProducts: false,
  nitrogenCategory: [],
  isFetchingNitrogenCategory: false,
  isFetchingFertilizerRegistrationType: false,
  fertilizerRegistrationType: [],
  fertilizerKind: [],
  isFetchingFertilizerKind: false,
};

export default () => (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.GET_CROP_USAGE_REQUEST:
      return {
        ...state,
        isFetchingCropUsage: true,
      };
    case types.GET_CROP_USAGE_SUCCESS:
      return {
        ...state,
        isFetchingCropUsage: false,
        cropUsage: action.payload,
      };
    case types.GET_CROP_USAGE_ERROR:
      return {
        ...state,
        isFetchingCropUsage: false,
      };
    case types.RESET_CROP_USAGE:
      return {
        ...state,
        cropUsage: [],
      };
    case types.GET_HARVEST_PRODUCTS_REQUEST:
      return {
        ...state,
        isFetchingHarvestProducts: true,
      };
    case types.GET_HARVEST_PRODUCTS_SUCCESS:
      return {
        ...state,
        isFetchingHarvestProducts: false,
        harvestProducts: action.payload,
      };
    case types.GET_HARVEST_PRODUCTS_ERROR:
      return {
        ...state,
        isFetchingHarvestProducts: false,
      };
    case types.RESET_HARVEST_PRODUCTS:
      return {
        ...state,
        harvestProducts: [],
      };
    case types.GET_FERTILIZER_NITROGEN_CATEGORIES_REQUEST:
      return {
        ...state,
        isFetchingNitrogenCategory: true,
      };
    case types.GET_FERTILIZER_NITROGEN_CATEGORIES_SUCCESS:
      return {
        ...state,
        isFetchingNitrogenCategory: false,
        nitrogenCategory: action.payload,
      };
    case types.GET_FERTILIZER_NITROGEN_CATEGORIES_ERROR:
      return {
        ...state,
        isFetchingNitrogenCategory: false,
      };
    case types.RESET_FERTILIZER_NITROGEN_CATEGORIES:
      return {
        ...state,
        nitrogenCategory: [],
      };
    case types.GET_FERTILIZER_REGISTRATION_TYPE_REQUEST:
      return {
        ...state,
        isFetchingFertilizerRegistrationType: true,
      };
    case types.GET_FERTILIZER_REGISTRATION_TYPE_SUCCESS:
      return {
        ...state,
        isFetchingFertilizerRegistrationType: false,
        fertilizerRegistrationType: action.payload,
      };
    case types.GET_FERTILIZER_REGISTRATION_TYPE_ERROR:
      return {
        ...state,
        isFetchingFertilizerRegistrationType: false,
      };
    case types.RESET_FERTILIZER_REGISTRATION_TYPE:
      return {
        ...state,
        fertilizerRegistrationType: [],
      };
    case types.GET_FERTILIZER_KIND_REQUEST:
      return {
        ...state,
        isFetchingFertilizerKind: true,
      };
    case types.GET_FERTILIZER_KIND_SUCCESS:
      return {
        ...state,
        isFetchingFertilizerKind: false,
        fertilizerKind: action.payload,
      };
    case types.GET_FERTILIZER_KIND_ERROR:
      return {
        ...state,
        isFetchingFertilizerKind: false,
      };
    case types.RESET_FERTILIZER_KIND:
      return {
        ...state,
        fertilizerKind: [],
      };

    default:
      return state;
  }
};
