import { toNumber } from 'lodash';

import { OTHER_ACTION_HARVEST_CODE, OTHER_ACTION_MOWING_CODE, OTHER_ACTION_SOWING_CODE } from '../../others/actionOther.constants';

import { getShortDateString } from '../../../../shared/misc/timeHelpers';
import Localization from '../../../../shared/services/Localization.service';
import { EFFECTIVENESS } from '../../eph/services/Effectiveness.services';

export const mapRequestBodyVRSTo = (values, locale, existingAction) => {
  const expense = values.expenses[0]; // VRS should have only one
  const payload = {
    date: getShortDateString(values.actionDate),
    note: values.actionNote,
    parcels: mapParcels(values.parcels),
    seedExpense: {
      actionMaterial: {
        materialId: expense.material.id,
      },
      actionExpense: {
        amount: Localization.str2numNonFixed(expense.variableExpense.totalDose, locale),
        unit: expense.doseUnit.id,
        dosePerHa: Localization.str2numNonFixed(expense.variableExpense.doseHa, locale),
      },
    },
    seedApplicationType: values.isCatchCrop ? 'CATCH_CROP' : 'MAIN_CROP',
  };

  if (existingAction) {
    payload.id = existingAction.id;
    payload.seedExpense.actionMaterial.id = existingAction.seedExpense.actionMaterial.id;
  }

  if (values.cropUseType) {
    payload.cropUseType = values.cropUseType.cropUseType;
  }

  return payload;
};

export const mapRequestBodyEphActionTo = (values, actionId) => {
  const payload = {
    date: getShortDateString(values.actionDate),
    note: values.actionNote,
    parcels: mapParcels(values.parcels),
    targetCropId: values.targetCrop.id,
    isStrawDecay: values.isStrawDecay || false,
    plantProtections: mapPlantProtection(values.expenses, values.parcels),
    fertilizers: mapFertilizers(values.expenses, values.actionDate),
  };

  if (actionId !== undefined) {
    payload.id = actionId;
  }

  return payload;
};

const mapPlantProtection = (expenses, parcels) => {
  const mapPlantProtection = expenses.filter(exp => exp.material.materialTypeId === 'CH').map(exp => ({
    actionMaterial: {
      materialId: exp.material.id,
      driftClass: exp.driftClass,
    },
    actionExpense: {
      amount: exp.dosage,
      unit: exp.doseUnit.id,
      dosePerHa: exp.dose,
      notes: exp.note,
    },
    targetOrganismsIds: exp.pests.map(item => item.id),
    parcelPlantProtectionEffectiveness: exp.effectiveness ?
      exp.effectiveness.map(item => ({
        parcelId: item.parcelId,
        effectiveness: item.value,
      })) :
      parcels.map(item => ({
        parcelId: item.id,
        effectiveness: EFFECTIVENESS.YES,
      })),
  }));
  return mapPlantProtection || [];
};

const mapFertilizers = (expenses, actionDate) => {
  const mapFertilizers = expenses.filter(exp => exp.material.materialTypeId === 'FR').map(exp => {
    const mapExp = {
      actionMaterial: {
        materialId: exp.material.id,
        dateSoilIncorporation: mapDateSoilIncorporation(exp.incorporationDay, actionDate),
      },
      actionExpense: {
        amount: exp.variableExpense ? exp.variableExpense.totalDose : exp.dosage,
        unit: exp.doseUnit.id,
        dosePerHa: exp.variableExpense ? exp.variableExpense.doseHa : exp.dose,
        notes: exp.note,
      },
    };
    return mapExp;
  });
  return mapFertilizers || [];
};

const mapDateSoilIncorporation = (incorporationDay, actionDate) => {
  if (incorporationDay === 'SAME_DAY') {
    return getShortDateString(actionDate);
  } else if (incorporationDay === 'NEXT_DAY') {
    const dayAfterActionDate = actionDate.clone().add(1, 'day');
    return getShortDateString(dayAfterActionDate);
  } else {
    return null;
  }
};

export const createNewVrfTo = (values) => values.expenses.map(expObj => expObj.variableExpense);

export const mapRequestBodyOtherActionTo = (values, locale, existingAction) => {
  const { actionType } = values;

  const isHarvestAction = actionType === OTHER_ACTION_HARVEST_CODE;
  const isMainHarvestProductSelected = values.mainHarvestProductId !== 0;
  const isSecondaryHarvestProductSelected = values.secondaryHarvestProductId !== 0;
  const notCzechFarmHarvestAction = !isMainHarvestProductSelected && !isSecondaryHarvestProductSelected;

  const isMowingAction = actionType === OTHER_ACTION_MOWING_CODE;
  const isSowingAction = actionType === OTHER_ACTION_SOWING_CODE;

  const payload = {
    date: getShortDateString(values.date),
    note: values.description,
    parcels: mapParcels(values.parcels, true),
  };

  if (existingAction) {
    payload.id = existingAction.id;
  }

  if (!isMowingAction && !isHarvestAction && !isSowingAction) {
    payload.actionType = actionType;
  }

  if (isMowingAction) {
    return {
      ...payload,
      revenue: {
        amount: Localization.str2numNonFixed(values.totalGain, locale) || 0,
        productLegislativeCode: values.productId,
        isMainProduct: true,
      },
    };
  }

  if (isHarvestAction) {
    if (isMainHarvestProductSelected && isSecondaryHarvestProductSelected) {
      return {
        ...payload,
        revenues: [
          {
            amount: Localization.str2numNonFixed(values.totalGain, locale) || 0,
            productLegislativeCode: values.mainHarvestProductId,
            isMainProduct: true,
          },
          {
            amount: Localization.str2numNonFixed(values.secondaryTotalGain, locale) || 0,
            productLegislativeCode: values.secondaryHarvestProductId,
            isMainProduct: false,
          },
        ],
      };
    } else if (isMainHarvestProductSelected && !isSecondaryHarvestProductSelected) {
      return {
        ...payload,
        revenues: [
          {
            amount: Localization.str2numNonFixed(values.totalGain, locale) || 0,
            productLegislativeCode: values.mainHarvestProductId,
            isMainProduct: true,
          },
        ],
      };
    } else if (notCzechFarmHarvestAction) {
      return {
        ...payload,
        revenues: [
          {
            amount: Localization.str2numNonFixed(values.totalGain, locale) || 0,
            isMainProduct: true,
          },
        ],
      };
    } else {
      return {
        ...payload,
        revenues: [],
      };
    }
  }

  if (isSowingAction) {
    const res = {
      ...payload,
      seedExpense: {
        actionMaterial: {
          materialId: values.seedId,
        },
      },
      seedApplicationType: values.isCatchCrop ? 'CATCH_CROP' : 'MAIN_CROP',
    };

    if (existingAction && !existingAction.isDraft) {
      res.seedExpense.actionMaterial.id = existingAction.seedExpense.actionMaterial.id;
    }

    if (values.cropUseType !== '') {
      res.cropUseType = values.cropUseType;
    }

    if (values.totalAmount !== '0') {
      res.seedExpense.actionExpense = {
        amount: Localization.str2numNonFixed(values.totalAmount, locale),
        unit: values.unitId,
        dosePerHa: Localization.str2numNonFixed(values.hectarAmount, locale),
      };
    }
    return res;
  }

  return payload;
};

const mapParcels = (parcels, otherAction = false) => parcels.map(parcel => ({
  parcelId: parcel.id,
  restrictions: otherAction ? mapParcelSubtractableAreas(parcel) : mapEPHParcelSubtractableAreas(parcel),
}));

const mapParcelSubtractableAreas = (parcel) => {
  const areas = parcel.subtractableAreas.absolute.filter(sa => sa.isUsed === true).map(sa =>
    ({
      type: sa.type,
      value: sa.value,
    }),
  );
  if (!(toNumber(parcel.subtractableAreas.boundaryChecked) === 0 ||
      parcel.subtractableAreas.boundaryChecked === undefined)) {
    areas.push({
      type: 'Boundary',
      value: toNumber(parcel.subtractableAreas.boundaryChecked),
    });
  }
  if (!(toNumber(parcel.subtractableAreas.waterChecked) === 0 ||
      parcel.subtractableAreas.waterChecked === undefined)) {
    areas.push({
      type: 'Water',
      value: toNumber(parcel.subtractableAreas.waterChecked),
    });
  }
  return areas;
};

const mapEPHParcelSubtractableAreas = (parcel) => {
  const filteredAreas = parcel.subtractableAreas.filter(sa => sa.isUsed === true);

  if (filteredAreas.length === 0) {
    return [];
  }

  return filteredAreas.map(sa => ({
    type: sa.type,
    value: sa.value,
  }));
};
