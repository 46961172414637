import React, { FC, ReactNode, useEffect, useCallback } from 'react';

import { Chip } from '@mui/material';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getMachines, getIsFetchingMachines } from '../../../../shared/api/telematics/drives/drives.selectors';
import { getTelematicsListDateFrom, getTelematicsListDateTo } from '../../../selectors/telematicsList.selectors';

import { fetchMachines } from '../../../actions/telematicsList.actions';

import * as sortOrder from '../../../../shared/constants/sortOrder.constants';

import { resetMachines } from '../../../../shared/api/telematics/drives/drives.api';
import CfAutocomplete from '../../../../shared/components/common/CfAutocomplete/CfAutocomplete';
import { Thunk } from '../../../../types';
import useAdvancedFilterStyles from '../TelematicsAdvancedFilter.styles';

import { TelematicsState } from '../../../../reducers/telematics.reducer.types';
import { MachineTo, MachineSortColumn } from '../../../../shared/api/telematics/telematics.types';

interface MachineSelectorProps {
  dateFrom: string;
  dateTo: string;
  defaultValues?: MachineTo[];
  fetchMachines(dateFrom: string, dateTo: string, sortCol: string, sortDir: string): void;
  isFetching: boolean;
  label: ReactNode;
  onChange(items: MachineTo[]): void;
  options: MachineTo[];
  resetMachines(): void;
}

const defaultDefaultValues: MachineTo[] = [];

const handleGetSelected = (option: MachineTo, value?: MachineTo | null) =>
  option.id === value?.id;

const handleFilterOptions = createFilterOptions({
  stringify: ({ licencePlate, name }) => `${name} ${licencePlate}`,
});

const MachineSelector: FC<MachineSelectorProps> = (
  { defaultValues = defaultDefaultValues,
    options,
    isFetching,
    label,
    fetchMachines,
    resetMachines,
    onChange,
    dateFrom,
    dateTo,
  }) => {
  const classes = useAdvancedFilterStyles();

  useEffect(() => {
    fetchMachines(dateFrom, dateTo, MachineSortColumn.NAME, sortOrder.ASC);
    return () => {
      resetMachines();
    };
  }, [fetchMachines, resetMachines, dateFrom, dateTo]);

  const handleRenderOption = useCallback((option: MachineTo) => (
    <div>
      <div>{option.name ?? option.gpsUnit}</div>
      <div className={classes.subtext}>
        {option.licencePlate ?? option.gpsUnit}{option.validTo &&
        <span> (<FormattedMessage id="TelematicsList.filter.machine.validTo" /><FormattedDate value={option.validTo} />)</span>}
      </div>
    </div>
  ), [classes]);

  const handleRenderTags = useCallback((values: MachineTo[], getTagProps: AutocompleteRenderGetTagProps) =>
    <>
      {values.map((value, index) => (
        <Chip
          {...getTagProps({ index })}
          key={value.id ?? index}
          label={
            <>
              {value.name ?? value.gpsUnit}
              <span className={classes.subtext}>{value.licencePlate ?? value.gpsUnit}</span>
            </>
          }
        />
      ))}
    </>, [classes]);

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      filterOptions={handleFilterOptions}
      getSelected={handleGetSelected}
      id="machine-selector"
      isFetching={isFetching}
      isMultiple={true}
      label={label}
      onChange={onChange}
      renderOption={handleRenderOption}
      renderTags={handleRenderTags}
      suggestions={options}
      testId="machine-filter"
    />
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  options: getMachines(state),
  isFetching: getIsFetchingMachines(state),
  dateFrom: getTelematicsListDateFrom(state),
  dateTo: getTelematicsListDateTo(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      fetchMachines,
      resetMachines,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MachineSelector);
