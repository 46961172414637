import React, { FC } from 'react';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';

import Localization from '../../../../../shared/services/Localization.service';

import { SubtractionResponse } from '../../../../../shared/api/agroevidence/agroevidence.types';

const useStyles = makeStyles((theme: Theme) => ({
  ha: {
    color: theme.palette.text.secondary,
  },
}));

export interface SubtractableAreaProps {
    sa: SubtractionResponse | {area: number, type: string, value: number}
}

const SubtractableArea: FC<SubtractableAreaProps> = ({
  sa,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const localizedRestrictionValue = Localization.num2strNonFixed(sa.value, intl.locale);
  const localizedRestrictionArea = Localization.num2str(sa.area, intl.locale, 2);
  const localizedRestrictionAreaAbsolute = Localization.num2str(sa.value, intl.locale, 2);
  return (
    <span>
      {(sa.type !== 'Absolute') && `${localizedRestrictionValue} m`}
      {(sa.type !== 'Absolute') && (
        <span className={classes.ha}>
          {` (${localizedRestrictionArea} ha)`}
        </span>
      )}
      {(sa.type === 'Absolute') && `${localizedRestrictionAreaAbsolute} ha`}
    </span>
  );
};

export default SubtractableArea;
