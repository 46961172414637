import React, { Component } from 'react';

import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import CfDatePicker from '../../../../../shared/components/common/CfDatePicker/CfDatePicker';
import CfDialog from '../../../../../shared/components/common/CfDialog/CfDialog';
import CfFormattedField from '../../../../../shared/components/form/CfFormattedField/CfFormattedField';
import CfFormattedTextField from '../../../../../shared/components/form/CfFormattedTextField/CfFormattedTextField';
import CfFormControl from '../../../../../shared/components/form/CfFormControl/CfFormControl';
import CfSelector from '../../../../../shared/components/form/CfSelector/CfSelector';
import CfTextField from '../../../../../shared/components/form/CfTextField/CfTextField';
import * as validators from '../../../../../shared/misc/validators';
import UnitService from '../../../../../shared/services/Unit.service';
import TransactionTypeSelector from '../../../shared/components/TransactionTypeSelector/TransactionTypeSelector';
import StoresService from '../../../shared/services/StoresService/StoresService';
import MaterialSelector from '../MaterialSelector/MaterialSelector';

const units = UnitService.getUnits();

const FORM_NAME = 'storeNew';

export class StoreNewDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allowedUnits: units,
    };
  }

  onSubmit = this.props.handleSubmit(values => {
    this.setState({ allowedUnits: units });
    this.props.onAccept(StoresService.getTransactionDto(values));
  });

  onSuggestionSelect = suggestion => {
    this.props.change('expense', suggestion);
    const { type } = suggestion;

    const allowedUnits = StoresService.getAllowedUnits(type);
    this.setState({
      allowedUnits,
    });
    this.props.change(
      'unit',
      allowedUnits.find(unit => unit.id === 'kg'),
    );
  };

  onSuggestionReset = () => {
    this.props.change('expense', null);
    this.props.change('unit', null);
    this.setState({
      allowedUnits: units,
    });
  };

  initialize = () => {
    this.props.initialize({
      expense: null,
      amount: null,
      unit: null,
      date: moment(),
      note: null,
    });
  };

  renderMaterialSelector = field => (
    <MaterialSelector
      onSuggestionReset={this.onSuggestionReset}
      onSuggestionSelect={this.onSuggestionSelect}
      {...field}
    />
  );

  render() {
    const { onClose, opened, transactionType } = this.props;
    const { allowedUnits } = this.state;
    return (
      <CfDialog
        acceptText={<FormattedMessage id="Stores.stock" />}
        cancelText={<FormattedMessage id="common.cancel" />}
        onAccept={this.onSubmit}
        onCancel={onClose}
        onClose={onClose}
        onEnter={this.initialize}
        opened={opened}
        title={<FormattedMessage id="Stores.store-new-material" />}
      >
        <form>
          <CfFormControl>
            <Field component={this.renderMaterialSelector} name="expense" />
          </CfFormControl>
          <CfFormControl>
            <Field component={TransactionTypeSelector} name="type" validate={validators.required} />
          </CfFormControl>
          <CfFormControl>
            <Grid container spacing={2}>
              <Grid item sm={8} xs={6}>
                <CfFormattedField
                  component={CfFormattedTextField}
                  formatDecimal={4}
                  fullWidth
                  label={<FormattedMessage id="common.amount" />}
                  name="amount"
                  normalize={value => value && Math.abs(value)}
                  type={'text'}
                  validate={[validators.required, validators.number]}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{transactionType ? transactionType.sign : ''}</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item sm={4} xs={6}>
                <Field
                  component={CfSelector}
                  items={allowedUnits}
                  label={<FormattedMessage id="common.unit" />}
                  labelPropertyFactory={option => <FormattedMessage id={`unit.${option.id}`} />}
                  name="unit"
                  validate={validators.required}
                />
              </Grid>
            </Grid>
          </CfFormControl>
          <CfFormControl>
            <Field
              component={CfDatePicker}
              label={<FormattedMessage id="Stores.store-date" />}
              name="date"
              propagateInvalidDate
              validate={[validators.required, validators.isValidDate]}
            />
          </CfFormControl>
          <CfFormControl>
            <Field
              component={CfTextField}
              fullWidth
              label={<FormattedMessage id="common.note" />}
              multiline
              name="note"
              type="text"
            />
          </CfFormControl>
        </form>
      </CfDialog>
    );
  }
}

const validate = values => {
  const errors = {};

  if (!values.expense) {
    errors.expense = { _error: true };
  }

  return errors;
};

StoreNewDialog.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  transactionType: PropTypes.object,
};

StoreNewDialog.defaultProps = {
  opened: false,
  transactionType: null,
};

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = state => ({
  transactionType: selector(state, 'type'),
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: FORM_NAME,
    validate,
  })(StoreNewDialog),
);
