import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../../api.constants';
import * as types from './fertilizers.constants';

import { RsaaMethods, RsaaTypes } from '../../../api.types';
import { FertilizerCreateTo } from '../../agroevidence.types';
import { getFertilizersParams } from './fertilizers.types';

export const getFertilizersApi = (params: getFertilizersParams) => ({
  [RSAA]: {
    endpoint: `catalogues/fertilizers?${queryString.stringify(params)}&`,
    method: methods.GET,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_FERTILIZERS_REQUEST,
      types.GET_FERTILIZERS_SUCCESS,
      types.GET_FERTILIZERS_ERROR,
    ] as RsaaTypes,
  },
});

export const getFertilizersV2Api = (params: getFertilizersParams) => ({
  [RSAA]: {
    endpoint: `catalogues/v2/fertilizers?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_FERTILIZERS_V2_REQUEST,
      types.GET_FERTILIZERS_V2_SUCCESS,
      types.GET_FERTILIZERS_V2_ERROR,
    ] as RsaaTypes,
  },
});

export const resetFertilizersApi = () => ({
  type: types.RESET_FERTILIZERS,
});

export const getFertilizerV2Api = (fertilizerId: string) => ({
  [RSAA]: {
    endpoint: `catalogues/v2/fertilizers/${fertilizerId}?`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_FERTILIZER_V2_REQUEST,
      types.GET_FERTILIZER_V2_SUCCESS,
      types.GET_FERTILIZER_V2_ERROR,
    ] as RsaaTypes,
  },
});

export const resetFertilizerApi = () => ({
  type: types.RESET_FERTILIZER,
});

export const updateFertilizerApi = (fertilizerId: string, params: FertilizerCreateTo) => ({
  [RSAA]: {
    endpoint: `catalogues/v2/fertilizers/${fertilizerId}?`,
    method: methods.PUT as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.UPDATE_FERTILIZER_V2_REQUEST,
      types.UPDATE_FERTILIZER_V2_SUCCESS,
      types.UPDATE_FERTILIZER_V2_ERROR,
    ] as RsaaTypes,
  },
});

export const createFertilizerApi = (params: FertilizerCreateTo) => ({
  [RSAA]: {
    endpoint: 'catalogues/v2/fertilizers?',
    method: methods.POST as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.CREATE_FERTILIZER_V2_REQUEST,
      types.CREATE_FERTILIZER_V2_SUCCESS,
      types.CREATE_FERTILIZER_V2_ERROR,
    ] as RsaaTypes,
  },
});

export const getFertilizerDoseApi = (fertId: string, unitId: string, dose: string) => ({
  [RSAA]: {
    endpoint: `catalogues/fertilizers/${fertId}/dose?unitId=${unitId}&dose=${dose}&`,
    method: methods.GET,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_FERTILIZERS_DOSE_REQUEST,
      types.GET_FERTILIZERS_DOSE_SUCCESS,
      types.GET_FERTILIZERS_DOSE_ERROR,
    ] as RsaaTypes,
  },
});

export const resetFertilizerDoseApi = () => ({
  type: types.RESET_FERTILIZERS_DOSE,
});
