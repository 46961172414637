/**
 * REST API service
 */

/* @ngInject */
export default function ApiConfig(
  Restangular,
  BACKEND_OPTIONS,
  RequestInterceptor,
  ResponseErrorInterceptor,
  ResponseSuccessInterceptor
) {
  Restangular
    // we need to have access to headers due to pagination
    .setFullResponse(true)
    .addFullRequestInterceptor(RequestInterceptor.authRequestInterceptor)
    .addFullRequestInterceptor(RequestInterceptor.spinnerStart)
    .addFullRequestInterceptor(RequestInterceptor.catchErrorInterceptorBypass)
    .addFullRequestInterceptor(RequestInterceptor.langParamInterceptor)
    .addRequestInterceptor(RequestInterceptor.jsogEncode)
    .addResponseInterceptor(ResponseSuccessInterceptor.spinnerEnd)
    .addResponseInterceptor(ResponseSuccessInterceptor.logResponse)
    .addResponseInterceptor(ResponseSuccessInterceptor.jsogDecode)
    .addErrorInterceptor(ResponseErrorInterceptor.spinnerEnd)
    .addErrorInterceptor(ResponseErrorInterceptor.handleRestangularErrorResponse);

  const service = {
    getObject,
    getGatewayObject,
    getIotObject,
    getSentinelObject,
    getTelematicsObject,
    getAgroevidenceObject
  };

  return service;

  function getObject() {
    return Restangular.withConfig((RestangularConfigurer) => {
      RestangularConfigurer.setBaseUrl(BACKEND_OPTIONS.apiUrl);
    });
  }

  function getGatewayObject() {
    return Restangular.withConfig((RestangularConfigurer) => {
      RestangularConfigurer.setBaseUrl(BACKEND_OPTIONS.gatewayUrl);
    });
  }

  function getIotObject() {
    return Restangular.withConfig((RestangularConfigurer) => {
      RestangularConfigurer.setBaseUrl(BACKEND_OPTIONS.iotUrl);
    });
  }

  function getSentinelObject() {
    return Restangular.withConfig((RestangularConfigurer) => {
      RestangularConfigurer.setBaseUrl(BACKEND_OPTIONS.sentinelUrl);
    });
  }

  function getTelematicsObject() {
    return Restangular.withConfig((RestangularConfigurer) => {
      RestangularConfigurer.setBaseUrl(BACKEND_OPTIONS.telematicsUrl);
    });
  }

  function getAgroevidenceObject() {
    return Restangular.withConfig((RestangularConfigurer) => {
      RestangularConfigurer.setBaseUrl(BACKEND_OPTIONS.agroevidenceUrl);
    });
  }
}
