import React, { FC } from 'react';

import { FormikProps } from 'formik';
import { FormattedMessage } from 'react-intl';

import CfFormControl from '../../../../shared/components/form/CfFormControl/CfFormControl';
import ProductionOperationSelector from '../../TelematicsDetailSelectors/TelematicsDetailProductionOperationSelector';

import { ProductionOperationTo } from '../../../../shared/api/telematics/telematics.types';

interface Props {
  customClasses?: Record<string, string>;
  date: string;
  disabled?: boolean;
  handleProductionOperationChange: (value: ProductionOperationTo) => void;
  handwork?: boolean;
  placeholder?: string;
}

const ProductionOperation: FC<Props & FormikProps<{productionOperation?: ProductionOperationTo}>> = ({
  customClasses, date, disabled, errors, handleProductionOperationChange, handwork = false, placeholder, values,
}) => (
  <CfFormControl>
    <ProductionOperationSelector
      customClasses={customClasses}
      dateFrom={date}
      dateTo={date}
      defaultValue={values.productionOperation}
      disabled={disabled}
      error={!!errors.productionOperation}
      handwork={handwork}
      helperText={errors.productionOperation ? errors.productionOperation as string : ''}
      label={placeholder ? undefined : <FormattedMessage id="TelematicsList.productionOperation" />}
      onChange={handleProductionOperationChange}
      placeholder={placeholder}
      />
  </CfFormControl>
);

export default ProductionOperation;
