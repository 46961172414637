import React from 'react';

import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(() => ({
  text: {
    fontSize: 14,
  },
}));

const SuccessfulImport: React.FC = () => {
  const classes = useStyles();

  return (<div className={classes.text}>
    <FormattedMessage id="FileUploader.modal.success-1" /> <br />
    <FormattedMessage id="FileUploader.modal.success-2" />
  </div>);
};

export default SuccessfulImport;
