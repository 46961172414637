import React, { FC, useMemo } from 'react';

import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Formik, FormikErrors } from 'formik';
import { FormattedDate } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { editEquipment } from '../../../actions/telematicsCatalogues.actions';

import CfFormattedNumber from '../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import CfTableCell from '../../../../shared/components/tables/CfTableCell/CfTableCell';
import { AsyncFn, Thunk } from '../../../../types';
import TCRow from '../shared/TCRow';

import { COLUMN_NAMES } from './equipment.columns';
import EquipmentsListEditDialogForm from './EquipmentsListEditDialogForm';

import { CataloguesState } from '../../../../reducers/catalogues.reducer.types';
import { EquipmentTo } from '../../../../shared/api/telematics/telematics.types';

interface Props {
    data: EquipmentTo;
    editEquipment: (id: number, data: EquipmentsListFormValues) => void;
    onEditSuccess: () => void;
}

export interface EquipmentsListFormValues {
    name: string;
    productionOperation?: {
        code?: string;
        name?: string;
    };
    workingWidth?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  name: {
    fontWeight: 500,
  },
  operation: {
    paddingRight: theme.spacing(4),
  },
}));

const handleValidate = (values: EquipmentsListFormValues) => {
  const errors: FormikErrors<Partial<EquipmentsListFormValues>> = {};
  const workingWidthAsNumber = Number(values.workingWidth);
  if (!values.name) {
    errors.name = 'validation.required';
  }
  if (!values.workingWidth && typeof values.workingWidth !== 'number') {
    errors.workingWidth = 'validation.required';
  }
  if (workingWidthAsNumber < 0 || workingWidthAsNumber > 50) {
    errors.workingWidth = 'Catalogues.validation.workingWidth';
  }
  if (!values.productionOperation?.code) {
    errors.productionOperation = 'validation.required';
  }
  return errors;
};

const EquipmentsListRow: FC<Props> = ({ data, editEquipment, onEditSuccess }) => {
  let isHistoricalItem = false;

  if (data.validTo) {
    const isEarlierThanToday = new Date().getTime() > new Date(data?.validTo).getTime();
    isHistoricalItem = isEarlierThanToday;
  }

  const classes = useStyles();

  const initialValues = useMemo(() => ({
    code: data.code,
    name: data.name ?? '',
    workingWidth: data.workingWidth,
    productionOperation: {
      code: data.operation?.id ?? '',
      name: data.operation?.name ?? '',
    },
  }), [data]);

  const handleSubmit = async (values: EquipmentsListFormValues) => {
    // todo fix swagger
    if (!data.id) return;
    const res = await (editEquipment as AsyncFn<number, EquipmentsListFormValues>)(data.id, values);
    if (res.error) {
      return Promise.reject({ messageId: 'Catalogues.table.shared.editError' });
    } else {
      return Promise.resolve({ handleSuccessFn: onEditSuccess });
    }
  };

  return (
    <Formik<EquipmentsListFormValues>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={handleValidate}
    >
      <TCRow
        dialogContent={<EquipmentsListEditDialogForm />}
        dialogTitle="Catalogues.table.equipments.dialog.title"
        isHistorical={isHistoricalItem}
    >
        <CfTableCell name={COLUMN_NAMES.CODE}>
          {data.code}
        </CfTableCell>
        <CfTableCell name={COLUMN_NAMES.NAME}>
          <span className={classes.name}>{data.name ?? '-'}</span>
        </CfTableCell>
        <CfTableCell name={COLUMN_NAMES.WORKING_WIDTH}>
          {typeof data.workingWidth === 'number' ? <CfFormattedNumber decimalDigits={2} value={data.workingWidth} /> : '-'}
        </CfTableCell>
        <CfTableCell classes={{ root: classes.operation }} name={COLUMN_NAMES.PRODUCTION_OPERATION}>
          {data.operation?.name ?? '-'}
        </CfTableCell>
        <CfTableCell name={COLUMN_NAMES.VALID_FROM}>
          {data.validFrom ? <FormattedDate value={data.validFrom} /> : '-'}
        </CfTableCell>
        <CfTableCell name={COLUMN_NAMES.VALID_TO}>
          {data.validTo ? <FormattedDate value={data.validTo} /> : '-' }
        </CfTableCell>
      </TCRow>
    </Formik>
  );
};

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) => bindActionCreators({
  editEquipment,
}, dispatch);

export default connect(null, mapDispatchToProps)(EquipmentsListRow);
