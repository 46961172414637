import React, { FC, useMemo } from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import { IconButton, Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getMainMapTelematicsMachinePositions } from '../../../../shared/api/telematics/mainMapTelematics/mainMapTelematics.selectors';
import {
  getMainMapTelematicsDateFrom,
  getMainMapTelematicsDateTo,
  getMainMapTelematicsSelectedMachineGpsUnit,
} from '../../selectors/mainMapTelematics.selectors';

import { setMachineFilter, setSelectedMachineGpsUnit } from '../../actions/mainMapTelematics/mainMapTelematics.actions';

import showOnMapIcon from '../../../../assets/img/icons/telematics/showOnMap.svg';
import HorizontalScrolling from '../../../../shared/components/common/HorizontalScrolling/HorizontalScrolling';
import HorizontalScrollingItem from '../../../../shared/components/common/HorizontalScrollingItem/HorizontalScrollingItem';
import MapBottomPanel from '../../../../shared/components/specific/MapBottomPanel/MapBottomPanel';
import MapBottomPanelHeader from '../../../../shared/components/specific/MapBottomPanelHeader/MapBottomPanelHeader';
import { stringLength } from '../../../../shared/misc/stringLength';
import { isToday } from '../../../../shared/misc/timeHelpers';
import { Thunk } from '../../../../types';

import { MainMapState } from '../../../../reducers/map.reducer.types';
import { PositionDetailTo, MachineTo, PositionMachineTo } from '../../../../shared/api/telematics/telematics.types';

const EQUIPMENT_LIMIT_TO_WRAP = 23;

const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    display: 'flex',
    fontWeight: 500,
    gap: '1rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      gap: '0.5rem',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  headerTitles: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  headerIconButtons: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  headerMachineName: {
    fontWeight: 500,
    width: '100%',
  },
  headerMachineLicencePlate: {
    color: theme.palette.grey[500],
    fontWeight: 400,
  },
  headerMachineButton: {
    fontSize: '11px',
    color: theme.palette.primary.main,
    padding: 0,
    margin: '2px 0px',
  },

  detailWrapper: {
    height: '120px',
    width: '100%',
    padding: '10px 0',
    lineHeight: 1.7,
  },
  detailColumn: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    columnGap: '1.5rem',
    marginRight: '3rem',
  },
  detailLabel: {
    color: theme.palette.grey[500],
  },
  detailValue: {
    fontWeight: 500,
  },
  detailValueSecondary: {
    color: theme.palette.grey[500],
  },
  wrappedDetailValue: {
    display: 'flex',
    flexDirection: 'column',
  },
  tooltip: {
    [theme.breakpoints.up('md')]: {
      margin: '0 !important',
    },
  },
}));

interface TelematicsDetailProps {
  dateFrom: string;
  dateTo: string;
  machinePositions: PositionDetailTo[];
  ngRedirectToTelematics: (dateFrom: string, dateTo: string, machine?: PositionMachineTo) => void;
  selectedMachineGpsUnit?: string;
  setMachineFilter: (machineFilter: MachineTo[]) => void;
  setSelectedMachineGpsUnit: (gpsUnit?: string) => void;
}

const MainMapTelematicsDetail: FC<TelematicsDetailProps> = ({
  dateFrom,
  dateTo,
  machinePositions,
  ngRedirectToTelematics,
  selectedMachineGpsUnit,
  setMachineFilter,
  setSelectedMachineGpsUnit,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setSelectedMachineGpsUnit(undefined);
  };

  const machinePosition = useMemo(() => machinePositions.find(x => x?.machine?.gpsUnit === selectedMachineGpsUnit), [
    machinePositions,
    selectedMachineGpsUnit,
  ]);

  const handleShowDrivingOnMap = () => {
    const selectedMachine = machinePosition?.machine as MachineTo;
    setMachineFilter([selectedMachine]);
  };

  const renderDate = (date?: string): JSX.Element => {
    if (isToday(date)) {
      return (
        <>
          <FormattedMessage id="common.date.today" />{' '}
        </>
      );
    } else {
      return (
        <>
          <FormattedDate value={date} /> {', '}
        </>
      );
    }
  };

  if (!machinePosition) return null;

  const shouldBeWrapped = stringLength(machinePosition.equipment?.name,
    machinePosition.equipment?.code) > EQUIPMENT_LIMIT_TO_WRAP;

  return (
    <div>
      <MapBottomPanel onPanelClose={handleClose}>
        <>
          <MapBottomPanelHeader>
            <div className={classes.headerWrapper}>
              <div className={classes.headerTitles}>
                <div className={classes.headerMachineName}>
                  {machinePosition.machine?.name ?? machinePosition.machine?.gpsUnit}
                </div>
                <div className={classes.headerMachineLicencePlate}>
                  {machinePosition.machine?.licencePlate ?? machinePosition.machine?.gpsUnit}
                </div>
              </div>
              <div className={classes.headerIconButtons}>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={<FormattedMessage id="MainMapTelematicsDetail.showDrivingOnMap" />}
                >
                  <IconButton aria-label="show on map" onClick={handleShowDrivingOnMap}>
                    <img alt="show on map" src={showOnMapIcon} />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={<FormattedMessage id="MainMapTelematicsDetail.showDrives" />}
                >
                  <IconButton onClick={() => ngRedirectToTelematics(dateFrom, dateTo, machinePosition?.machine)}>
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </MapBottomPanelHeader>

          <div className={classes.detailWrapper}>
            <HorizontalScrolling>
              <HorizontalScrollingItem>
                <div className={classes.detailColumn}>
                  <span className={classes.detailLabel}>
                    <FormattedMessage id="MainMapTelematicsDetail.lastPosition" />
                  </span>
                  <span className={classes.detailValue}>
                    {renderDate(machinePosition.time)}
                    <FormattedTime value={machinePosition.time} />
                  </span>
                  <span className={classes.detailLabel}>
                    <FormattedMessage id="MainMapTelematicsDetail.lastMovement" />
                  </span>
                  {machinePosition?.movementTime ? (
                    <span className={classes.detailValue}>
                      {renderDate(machinePosition.movementTime)}
                      <FormattedTime value={machinePosition.movementTime} />
                    </span>
                  ) : (
                    '-'
                  )}
                  <span className={classes.detailLabel}>
                    <FormattedMessage id="MainMapTelematicsDetail.speed" />
                  </span>
                  <span className={classes.detailValue}>
                    <FormattedMessage
                      id="MainMapTelematicsDetail.speed.unit"
                      values={{ speed: machinePosition.speed ?? '-' }}
                    />
                  </span>
                </div>
              </HorizontalScrollingItem>

              <HorizontalScrollingItem>
                <div className={classes.detailColumn}>
                  <>
                    <span className={classes.detailLabel}>
                      <FormattedMessage id="MainMapTelematicsDetail.driver" />
                    </span>
                    <div>
                      <span className={classes.detailValue}>
                        {machinePosition.driver?.name ?? machinePosition.driver?.code ?? '-'}
                      </span>{' '}
                      <span className={classnames(classes.detailValue, classes.detailValueSecondary)}>
                        {machinePosition.driver?.code}
                      </span>
                    </div>
                  </>
                  <>
                    <span className={classes.detailLabel}>
                      <FormattedMessage id="MainMapTelematicsDetail.equipment" />
                    </span>
                    <div className={shouldBeWrapped ? classes.wrappedDetailValue : undefined}>
                      <span className={classes.detailValue}>{machinePosition.equipment?.name ?? '-'}</span>{' '}
                      <span className={classnames(classes.detailValue, classes.detailValueSecondary)}>
                        {machinePosition.equipment?.code}
                      </span>
                    </div>
                  </>
                </div>
              </HorizontalScrollingItem>
            </HorizontalScrolling>
          </div>
        </>
      </MapBottomPanel>
    </div>
  );
};

const mapStateToProps = (state: MainMapState) => ({
  dateFrom: getMainMapTelematicsDateFrom(state),
  dateTo: getMainMapTelematicsDateTo(state),
  selectedMachineGpsUnit: getMainMapTelematicsSelectedMachineGpsUnit(state),
  machinePositions: getMainMapTelematicsMachinePositions(state),
});

const mapDispatchToProps = (dispatch: Thunk<MainMapState>) =>
  bindActionCreators(
    {
      setSelectedMachineGpsUnit,
      setMachineFilter,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MainMapTelematicsDetail);
