import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import reportsUI from '../evidence/reports/reducer/reports.reducer';
// TODO po dokonceni prepisu na AGROEVIDENCE prejmenovat parcelsApiNew -> parcelsApi
import parcelsApiNew from '../shared/api/agroevidence/parcels/parcels.reducer';
import eagriReportApi from '../shared/api/agroevidence/reports/reports.reducer';
import zonesApi from '../shared/api/agroevidence/zones/zones.reducer';
import reportsApi from '../shared/api/core/reports/reports.reducer';
import authReducer from '../shared/api/gateway/auth/auth.reducer';

import porReportHistoryUI from '../evidence/reports/reducer/porReportHistoryTableReducer';

export default history =>
  combineReducers({
    ui: combineReducers({
      reports: reportsUI(),
      porReportHistoryTable: porReportHistoryUI(),
      reportParcels: combineReducers({
        additions: parcelsApiNew('additions'),
        suggestions: parcelsApiNew('suggestions'),
      }),
    }),
    api: combineReducers({
      reports: reportsApi(),
      zones: zonesApi(),
      eagriReport: eagriReportApi(),
    }),
    router: connectRouter(history),
    form: formReducer,
    auth: authReducer,
  });
